import { memo, useCallback, useEffect } from 'react';
import axios from 'axios';
import { createConsumer } from '@rails/actioncable';
import _pick from 'lodash/pick';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import { wsURL } from 'apis';
import { cableMessageTypes, models } from 'helpers/constants';
import useNotification from 'hooks/useNotification';
import useInvoices from 'hooks/useInvoices';
import useVehicles from 'hooks/useVehicles';
import useOrders from 'hooks/useOrders';
import useRates from 'hooks/useRates';
import useRequests from 'hooks/useRequests';
import useError from 'hooks/useError';
import useMap from 'hooks/useMap';

const {
  INVOICE_UPDATED,
  ORDER_CREATED,
  ORDER_STATUS_UPDATED,
  ORDER_CUSROMER_LOCATION_UPDATED,
  ORDERS_STATISTICS_UPDATED,
  ORDER_MILES_UPDATED,
  USER_COORDINATES_UPDATED,
  USER_DUTY_DURATION_UPDATED,
  INVOICES_STATISTICS_UPDATED,
  VEHICLES_STATISTICS_UPDATED,
  RATE_CREATED,
  REQUEST_CREATED,
  REQUEST_UPDATED
} = cableMessageTypes;

const Cable = ({ token }) => {
  const { showNotification } = useNotification();
  const { getOrderForNotification, changeLocalOrderStatus, getOrdersStatistics } = useOrders();
  const { getInvoiceForNotification, getInvoicesStatistics } = useInvoices();
  const { getVehiclesStatistics } = useVehicles();
  const { getRate } = useRates();
  const { getRequest } = useRequests();
  const { setActualDriverData } = useMap();
  const { setError } = useError();

  const handleStatusInvoiceUpdated = useCallback((invoice_id) => 
    getInvoiceForNotification(invoice_id)
    .then(({ payload: { data: { invoice }} }) => showNotification({ body: `Invoice changed | ${invoice.number} | ${_startCase(invoice.status)}`, id: invoice.id, type: models.INVOICES, silent: true }))
    .catch(error => !axios.isCancel(error) && setError(error))
  , [getInvoiceForNotification, showNotification, setError]);
  const handleOrderCreated = useCallback(id => 
    getOrderForNotification(id)
    .then(({ payload: { data: { order }} }) => showNotification({ body: `New | ${order.number}`, id: order.id, type: models.ORDERS, silent: false }))
    .catch(error => setError(error))
  , [getOrderForNotification, showNotification, setError]);
  const handleOrderStatusUpdated = useCallback(({ order_id, status }) => 
    changeLocalOrderStatus({ order_id, status })
    .then(() => 
      getOrderForNotification(order_id)
      .then(({ payload: { data: { order }} }) => showNotification({ body: `Status changed | ${order.number} | ${_startCase(order.status)}`, id: order.id, type: models.ORDERS, silent: true }))
      .catch(error => setError(error))
    )
  , [changeLocalOrderStatus, getOrderForNotification, showNotification, setError]);
  const handleOrderCustomerLocationUpdated = useCallback(id => 
    getOrderForNotification(id)
    .then(({ payload: { data: { order }} }) => showNotification({ body: `Order | ${order.number} | Customer location updated`, id: order.id, type: models.ORDERS, silent: true }))
    .catch(error => setError(error))
  , [getOrderForNotification, showNotification, setError]);
  const handleOrderMilesUpdated = useCallback(id => 
    getOrderForNotification(id)
    .then(({ payload: { data: { order }} }) => showNotification({ body: `Order | ${order.number} | Miles updated`, id: order.id, type: models.ORDERS, silent: true }))
    .catch(error => setError(error))
  , [getOrderForNotification, showNotification, setError]);
  const handleUserCoordinatesUpdated = useCallback(({ user_id, latitude, longitude, heading }) => {
    setActualDriverData({ id: user_id, latitude, longitude, heading });
  }, [setActualDriverData]);
  const handleUserDurationUpdated = useCallback(({ user_id, duty_duration }) => {
    setActualDriverData({ id: user_id, duty_duration });
  }, [setActualDriverData]);
  const received = useCallback(data => {
    switch (data.type) {
      case INVOICE_UPDATED:
        handleStatusInvoiceUpdated(data.invoice_id);
        break;
      case ORDER_CREATED:
        handleOrderCreated(data.order_id);
        break;
      case ORDER_STATUS_UPDATED:
        handleOrderStatusUpdated(_pick(data, ['order_id', 'status']));
        break;
      case ORDER_CUSROMER_LOCATION_UPDATED:
        handleOrderCustomerLocationUpdated(data.order_id);
        break;
      case ORDERS_STATISTICS_UPDATED:
        getOrdersStatistics({}).catch((error) => setError(error));
        break;
      case ORDER_MILES_UPDATED:
        handleOrderMilesUpdated(data.order_id);
        break;
      case USER_COORDINATES_UPDATED:
        handleUserCoordinatesUpdated(_pick(data, ['user_id', 'latitude', 'longitude', 'heading']));
        break;
      case USER_DUTY_DURATION_UPDATED:
        handleUserDurationUpdated(data);
        break;
      case INVOICES_STATISTICS_UPDATED:
        getInvoicesStatistics({}).catch((error) => setError(error));
        break;
      case VEHICLES_STATISTICS_UPDATED:
        getVehiclesStatistics().catch((error) => setError(error));
        break;
      case RATE_CREATED:
        getRate(data.rate_id).catch((error) => setError(error));
        break;
      case REQUEST_CREATED:
        getRequest(data.request_id).catch((error) => setError(error));
        break;
      case REQUEST_UPDATED:
        getRequest(data.request_id).catch((error) => setError(error));
        break;
      default:
        break;
    };
  }, [    
    handleStatusInvoiceUpdated, 
    handleOrderCreated, 
    handleOrderStatusUpdated, 
    handleOrderCustomerLocationUpdated,
    handleUserCoordinatesUpdated,
    handleUserDurationUpdated,
    getOrdersStatistics,
    handleOrderMilesUpdated,
    getInvoicesStatistics,
    getVehiclesStatistics,
    getRate,
    getRequest,
    setError
  ]);
  const rejected = useCallback((cable) => !!cable && cable.disconnect(), []);

  useEffect(() => {
    let cable;
    if (token){
      cable = createConsumer(`${wsURL}?access_token=${token}`);
      cable.subscriptions.create('NotificationsChannel', { received, rejected: () => rejected(cable) });
    }

    return () => {
      !!cable && cable.disconnect();
    };
  }, [token, rejected, received]);

  return null;
};

Cable.propTypes = {
  token: PropTypes.string.isRequired
};

export default memo(Cable);
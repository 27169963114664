import { styled } from '@mui/system';

export const Container = styled('div')({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%', // Aspect ratio: 16:9
});

export const Iframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 0,
  borderRadius: "20px",
});

import { memo, useState } from 'react';
import Box from '@mui/material/Box';
//import Snackbar from '@mui/material/Snackbar';

const DropContainer = ({ onDrop, children, sx, ...otherProps }) => {
  const [isDropFile, setIsDropFile] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    onDrop(e.dataTransfer.files);
    setIsDropFile(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move"
    setIsDropFile(true);
  };

  const handleLeaveDrag = (e) => {
    e.preventDefault();
    setIsDropFile(false);
  };

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleLeaveDrag}
      sx={{
        backgroundColor: isDropFile ? 'action.hover' : 'transparent',
        boxShadow: isDropFile ? '0 0 0 2px #E4E4FD' : 'unset',
        borderRadius: '4px',
        ...sx
      }}
      {...otherProps}
    >
      {children}
     {/* <Snackbar
        open={isDropFile}
        message='Drop files to upload them'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& > div': {
            background: '#1B1636',
          },
          '& .MuiSnackbarContent-message': {
            width: '100%',
            textAlign: 'center',
          }
        }}
      />*/}
    </Box>
  );
};

export default memo(DropContainer);
import React, { useState, useEffect } from 'react';
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { validateField } from "helpers";
import useCustomSelector from 'hooks/useCustomSelector';
import Button from '@mui/material/Button';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import CompaniesAutocomplete from 'components/Companies/Autocomplete/Autocomplete';
import LocationsAutocomplete from 'components/Locations/Autocomplete/Autocomplete';

const InsuranceDialog = ({ open, onClose, onSubmit }) => {
  const locations = useCustomSelector(state => state.locations.list.data);
  const companies = useCustomSelector(state => state.profile.user.companies);
  const { fullName, phone } = useCustomSelector(state => ({
    fullName: `${state.profile.user.first_name} ${state.profile.user.last_name}`,
    phone: state.profile.user.phone
  }));

  const [companyId, setCompanyId] = useState({ value: '', formattedValue: null, error: '' });
  const [companyLocationId, setCompanyLocationId] = useState({ value: '', formattedValue: null, error: '' });
  const [name, setName] = useState({ value: fullName ?? '', error: '' });
  const [clientId, setClientId]  = useState({ value: '', error: '' });
  const [contractorId, setContractorId] = useState({ value: '', error: '' });
  const [locationId, setLocationId] = useState({ value: '', error: '' });
  const [taxId, setTaxId] = useState({ value: '', error: '' });
  const [contactName, setContactName] = useState({ value: '', error: '' });
  const [contactPhone, setContactPhone] = useState({ formattedValue: null, error: '' });
  // eslint-disable-next-line no-unused-vars
  const [providerName, setProviderName] = useState({ value: '', error: '' });
  const [providerPhone, setProviderPhone] = useState({ formattedValue: phone ?? '', error: '' });
  
  useEffect(() => {
    if (companies.length === 1) {
      setCompanyId({ value: companies[0]?.id, formattedValue: companies[0], error: '' });
    }
    if (locations.length === 1 ) {
      setCompanyLocationId({
        value: locations[locations.length - 1]?.id,
        formattedValue: locations[locations.length - 1],
        error: ''
      });
    }
  }, [companies, locations]);
  
  const handleSubmitClick = () => {
    let hasError = false;

    hasError = validateField(companyId.value, setCompanyId, "Company can't be empty") || hasError;
    hasError = validateField(companyLocationId.value, setCompanyLocationId, "Company Location can't be empty") || hasError;
    hasError = validateField(name.value, setName, "Name can't be empty") || hasError;
    hasError = validateField(clientId.value, setClientId, "Client ID can't be empty") || hasError;
    hasError = validateField(contractorId.value, setContractorId, "Contractor ID can't be empty") || hasError;
    hasError = validateField(locationId.value, setLocationId, "Location ID can't be empty") || hasError;
    hasError = validateField(taxId.value, setTaxId, "Tax ID can't be empty") || hasError;
    hasError = validateField(contactName.value, setContactName, "Contact Name can't be empty") || hasError;
    hasError = validateField(contactPhone.formattedValue, setContactPhone, "Contact Phone can't be empty") || hasError;
    hasError = validateField(providerPhone.formattedValue, setProviderPhone, "Provider Phone can't be empty") || hasError;

    if (hasError) return;

    onClose();
  };

  const handleExiting = () => {
    setCompanyId({ value: companies[0]?.id, formattedValue: companies[0], error: '' });
    if (locations.length > 0 && companies.length > 0) {setCompanyLocationId({
        value: locations[locations.length - 1]?.id,
        formattedValue: locations[locations.length - 1],
        error: ''
      });
    }
    setName({ value: fullName ?? '', error: '' });
    setClientId({ value: '', error: '' });
    setContractorId({ value: '', error: '' });
    setLocationId({ value: '', error: '' });
    setTaxId({ value: '', error: '' });
    setContactName({ value: '', error: '' });
    setContactPhone({ formattedValue: null, error: '' });
    setProviderPhone({ formattedValue: phone ?? '', error: '' });
  }

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Add Insurance Credentials"
      PaperProps={{sx: { width: '700px', height: 'auto', py: '0.3rem' } }}
      TransitionProps={{ onExited: handleExiting }}
      content={
      <Box sx={{ display: 'flex', flexDirection: 'column', px: {xs:'5%', lg:'10%'}, py: {xs:'2%', lg:'1%'}, gap: '0.8rem' }}>
        <CompaniesAutocomplete
          value={companyId.formattedValue}
          error={!!companyId.error}
          helperText={companyId.error}
          onChange={({ value, formattedValue, error  }) => setCompanyId({ value, formattedValue, error  })}
        />
        <LocationsAutocomplete
          value={companyLocationId.formattedValue}
          error={!!companyLocationId.error}
          helperText={companyLocationId.error}
          onChange={({ value, formattedValue, error }) => setCompanyLocationId({ value, formattedValue, error })}
        />
        <TextField
          label="Name" 
          type="text"
          value={name.value}
          error={!!name.error}
          helperText={name.error}
          onChange={({ target: { value } }) => setName({ value,  error: "" })}
        />
        <TextField
          label="Client ID" 
          value={clientId.value}
          error={!!clientId.error}
          helperText={clientId.error}
          onChange={({ target: { value } }) => setClientId({ value, error: "" })}
        />
        <TextField
          label="Contractor ID" 
          value={contractorId.value}
          error={!!contractorId.error}
          helperText={contractorId.error}
          onChange={({ target: { value } }) => setContractorId({ value, error: "" })}
        />
        <TextField
          label="Location ID" 
          value={locationId.value}
          error={!!locationId.error}
          helperText={locationId.error}
          onChange={({ target: { value } }) => setLocationId({ value, error: "" })}
        />
        <TextField
          label="Tax ID" 
          value={taxId.value}
          error={!!taxId.error}
          helperText={taxId.error}
          onChange={({ target: { value } }) => setTaxId({ value, error: "" })}
        />
        <TextField
          label="Contact Name" 
          value={contactName.value}
          error={!!contactName.error}
          helperText={contactName.error}
          onChange={({ target: { value } }) => setContactName({ value, error: "" })}
        />
        <PhoneField
          label='Contact Phone'
          formattedValue={contactPhone.formattedValue}
          error={!!contactPhone.error}
          helperText={contactPhone.error}
          onChange={({ formattedValue, error }) => setContactPhone({ formattedValue, error })}
        />
        <PhoneField
          label='Provider Phone'
          formattedValue={providerPhone.formattedValue}
          error={!!providerPhone.error}
          helperText={providerPhone.error}
          onChange={({ formattedValue, error }) => setProviderPhone({  formattedValue, error })}
        />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pt: { xs: '32px', lg: '56px' } }}>
        <Button sx={{ px: '5%', mb: '16px' }} variant="contained" color="primary" onClick={handleSubmitClick}>
          Add Insurance Credentials
        </Button>
      </Box>
    </Box>
    }
    />
  );
};

export default InsuranceDialog;

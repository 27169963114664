import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs }from '@mui/material';
import { Root, StyledTab, NavigationContainer } from './Main.styled';
import Guides from 'components/Resources/Videos/Guides';
import WhatsNew from '../WhatsNew/WhatsNew';
import Head from '../WhatsNew/Head/Head';

const Main = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [tab, setTab] = useState('resources');

  const handleChangeTab = (_, newValue) => setTab(newValue);

  return (
    <Root>
      <Head/>
        <NavigationContainer>
          <Tabs
            variant='fullWidth'
            value={tab}
            onChange={handleChangeTab}
            sx={{ width: '80%' }}
          >
            <StyledTab
              value='resources'
              label="Resources"
            />
            <StyledTab
              value='whats_new'
              label="What's New"
            />
          </Tabs>
        </NavigationContainer>
        {tab === 'resources' && <Guides/>}
        {tab === 'whats_new' && <WhatsNew/>}
    </Root>
  );
};

export default Main;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getProfile as getProfileAction,
  updateProfile as updateProfileAction,
  changePassword as changePasswordAction,
  startDuty as startDutyAction,
  stopDuty as stopDutyAction,
} from 'actions/profile';

const useProfile = () => {
  const dispatch = useDispatch();
  const getProfile = useCallback(() => dispatch(getProfileAction()), [dispatch]);
  const updateProfile = useCallback(user => dispatch(updateProfileAction(user)), [dispatch]);
  const changePassword = useCallback(({ password, current_password }) => dispatch(changePasswordAction({ password, current_password })), [dispatch]);
  const startDuty = useCallback(() => dispatch(startDutyAction()), [dispatch]);
  const stopDuty = useCallback(() => dispatch(stopDutyAction()), [dispatch]);

  return {
    getProfile,
    updateProfile,
    changePassword,
    startDuty,
    stopDuty
  };
};

export default useProfile;
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const Root = styled('div')(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  padding: '75px 0 32px',
  ...theme.scrollbars.default,
}));

export const Message = styled(Typography)({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1.3,
});

export const CardsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  paddingTop: 44,
});
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const Root = styled(props => (
  <ButtonBase component={NavLink} {...props}/>), { shouldForwardProp: (prop) => prop !== 'selected' }
)(({ selected, theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: '8px',
  ...(selected && {
    color: theme.palette.primary.main,
    background: theme.palette.background.selected,
    '& > div >  p': {
      width: 'auto',
      paddingLeft: '8px',
    }
  })
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.accent,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.selected,
    color: '#1B1636',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `2px solid ${theme.palette.background.accent}`,
  },
}));

export const Content = styled(Box)(() => ({
  display: 'flex',
  height: 'inherit',
  width: 'inherit',
  borderRadius: '8px',
  padding: '6px 8px',
  alignItems: 'center'
}));

export const Title = styled(props => (
  <Typography variant='subtitle2' component={'p'} {...props}/>)
)({
  width: 0,
  lineHeight: '18px',
  transition: 'transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0m',
  overflow: 'hidden',
});

import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

export const Root = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'driver' && prop !== 'available' && prop !== 'offline' && prop !== 'selected'
})(({ theme, available = false, driver = false, offline, selected }) => ({
  width: 42,
  height: 42,
  position: 'relative',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  ...(driver && {
    backgroundColor: '#EC7464',
  }),
  ...(available && {
    backgroundColor: '#33763F',
  }),
  ...(offline && {
    backgroundColor: theme.palette.text.secondary,
  }),
  ...(selected && {
    boxShadow: 'inset 0 0 0 4px #fff',
  }),
}));

export const Direction = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'available' }
)(({ available }) => ({
  height: 76,
  width: 118,
  position: 'absolute',
  left: 'calc(50% - 59px)',
  top: 'calc(50% - 38px)',
  zIndex: -1,
  color: '#EC7464',
  ...(available && {
    color: '#33763F',
  }),
}));

export const Wrapper = styled(Box)({
  width: 42,
  height: 42,
  color: '#fff',
  fontSize: 16,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: '50%',
});

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    border: `2px solid ${theme.palette.text.primary}`,
  },
}));
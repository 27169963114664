import { memo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';

const Confirmation = (props) => {
  const { closeConfirmationDialog } = useDialogs();
  const { open, confirmedAction: { id, content } } = useCustomSelector(state => state.dialogs.confirmation);

  return (
    <BaseDialog
      open={open}
      onClose={closeConfirmationDialog}
      PaperProps={{ sx: { width: 360,  borderRadius: '16px' } }}
      {...props}
    >
      <DialogContent>
        {!!content && (
          <Typography variant='subtitle1' align='center' p='16px 0px 8px'>{content}</Typography>
        )}
      </DialogContent>
      <Box
        sx={{
          width: '100%',
          borderTop: '1px solid #F1F0FC',
        }}
      >
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          onClick={() => closeConfirmationDialog({ no: true, yes: false, id: null })}
        >
          No
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'error.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          onClick={() => closeConfirmationDialog({ yes: true, no: false, ...(!!id && { id }) })}
        >
          Yes
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(Confirmation);

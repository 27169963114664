import styled from '@mui/material/styles/styled';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: `${alpha(theme.palette.text.primary, 0.25)}`,
  borderRadius: '50%',
  padding: '4px'
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.text.primary,
  borderRadius: '50%',
}));
import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MoreVert from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
//Local files
import { Root, Header, Title, Actions, Content, StyledMenuItem } from './Main.styled';
import { getFormattedPhone } from 'helpers';
import { reasons } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useTimezones from 'hooks/useTimezones';
import useAccounts from 'hooks/useAccounts';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const Main = ({ id, default: isDefaultAccount, company, name, phone, email, fax, contact_name, contact_phone, contact_email, created_at }) => {
  const navigate = useNavigate();
  const { openDrawer } = useApp();
  const { duplicateAccount } = useAccounts();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { timeZone } = useTimezones(company.id);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleDuplicateClick = () => {
    setMenuAnchor(null);
    setProcessing(true);
    duplicateAccount(id)
    .then(({ payload: { data: { account } } }) => setSuccess('Account successfully duplicated').then(() => navigate(`/accounts/${account.id}`)))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };

  return (
    <Root>
      <Header>
        <Title>{name}</Title>
        <Actions>
          {!isDefaultAccount && <Button variant='outlined' color='tertiary' onClick={() => openDrawer({ reason: reasons.ACCOUNT, edit: true })}>EDIT</Button>}
          <Button color='inherit' disabled={processing} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)} endIcon={<MoreVert />} sx={{ lineHeight: '1.0' }}>
            More
          </Button>
        </Actions>
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{ sx: { mt: '2px', width: '240px' } }}
          keepMounted
        >
          <StyledMenuItem disabled={processing} onClick={handleDuplicateClick}>Duplicate</StyledMenuItem>
        </Menu>
      </Header>
      <Content>
        <Box>
          <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Company</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{company?.name}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Created Date</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{new Date(created_at).toLocaleDateString('en-US', { timeZone })}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Phone</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{getFormattedPhone(phone)}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Email</Typography>
          <Typography variant='subtitle2' sx={{wordBreak: 'break-all'}}>{email || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Fax</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{fax || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Contact Name</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{contact_name || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Contact Phone</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{getFormattedPhone(contact_phone)}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Contact Email</Typography>
          <Typography variant='subtitle2' sx={{wordBreak: 'break-all'}}>{contact_email || '-'}</Typography>
        </Box>
      </Content>
    </Root>
  );
};

export default memo(Main);
import { memo, useCallback, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import _map from 'lodash/map';
// Local Files
import { Root } from './Filter.styled';
import useCategories from 'hooks/useCategories';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';

const Filter = ({ id, model, categoryId, filterCategories, unfilterCategories, disabled }) => {
  const { setError } = useError();
  const { getCategories, clearLocalCategories } = useCategories();
  const categories = useCustomSelector(state => state.categories.all.data);
  
  const fetchCategories = useCallback((id, model) => {
    getCategories({ id, model, offset: 0, category_type: model?.slice(0, model.length - 1) }).catch((error) => setError(error));
  }, [getCategories, setError]);
  
  useEffect(() => {
    fetchCategories(id, model);

    return clearLocalCategories;
  }, [id, model, fetchCategories, clearLocalCategories]);

  return (
    <Root>
      { !!categories.length && _map(categories, ({ id, name }) => 
        <Chip
          key={id}
          label={name}
          selected={categoryId === id}
          color='primary'
          variant={categoryId === id ? 'filled' : 'outlined'}
          onClick={() => filterCategories(id)}
          onDelete={categoryId === id ? unfilterCategories : null}
          disabled={disabled}
        />
      )}
    </Root>
  );
};

export default memo(Filter);
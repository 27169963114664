import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { compensationsReportRoutine } from 'actions';

export const compensationsReport = getThunkActionCreator(
  compensationsReportRoutine,
  async ({ company_id, start_at, end_at, user_ids = null }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/compensations/report`, { access_token, company_id, start_at, end_at, ...(!!user_ids.length && { user_ids }) },  { responseType: "blob" });
  }
);
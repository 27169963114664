import styled from '@mui/material/styles/styled';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';

const hideAppBar = localStorage.getItem('hide_app_bar') === 'true';

const Root = styled(Box)(({ theme }) => ({
  height: hideAppBar ? '100%' : 'calc(100% - 64px)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));
const TabsContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up('md')]: {
    minWidth: '360px',
    borderBottom: 'none',
    borderRight: `2px solid ${theme.palette.divider}`,
  }
}));
const StyledTabs = styled((props) => (
  <Tabs TabIndicatorProps={{ className: 'indicator'}} {...props} />
))({
  '& .indicator': {
    width: 8
  }
});
const StyledTab = styled(({ label, index, ...otherProps }) => (
  <Tab id={`tab-${index}`} aria-controls={`tabpanel-${index}`} label={label} {...otherProps} />
))(({ theme }) => (
  {
    textTransform: 'none',
    padding: '12px',
    [theme.breakpoints.up('md')]: {
      minWidth: '90px',
      minHeight: '80px',
      padding: '12px 24px',
      fontSize: '1.15rem',
      fontWeight: 400,
      color: theme.palette.text.primary,
      '&:hover': { backgroundColor: theme.palette.action.hover },
      '&.Mui-selected': {
        fontWeight: 500,
        backgroundColor: `${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)}`,
        color: theme.palette.text.primary,
        '&:hover': { backgroundColor: `${alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)}` }
      }
    }
  }
));
const StyledTabPanel = styled(Box)(({ theme }) => ({
  flexGrow: 1, 
  overflow: 'auto',
  [theme.breakpoints.up('xs')]: {
    padding: '24px 16px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '32px 32px'
  }
}));

export { Root, TabsContainer, StyledTabs, StyledTab, StyledTabPanel };
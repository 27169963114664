import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useLocations from 'hooks/useLocations';

const LocationsAutocomplete = ({ value, error, helperText, onChange }) => {
  const { getListLocations, clearLocalListLocations } = useLocations();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.locations.list);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (_, formattedValue) => onChange({ value: formattedValue?.id, formattedValue, error: '' });
  const isOptionEqualToValue = (o, v) => o?.id === v?.id;
  const fetchLocations = useCallback((offset, query) => {
    setLoading(true);
    getListLocations({ offset, query })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListLocations, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchLocations(limit + offset, query);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalListLocations().then(() => fetchLocations(0, query)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [query, fetchLocations, clearLocalListLocations]);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      id="locationsList"
      options={data}
      onInputChange={(_, value, reason) => reason !== 'reset' && setQuery(value)}
      getOptionLabel={o => `${o.name} - ${o.company.name}`}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      fullWidth
      renderInput={params => <TextField {...params} label='Location *' error={error} helperText={helperText} />}
    />
  );
};

export default memo(LocationsAutocomplete);
import { memo } from 'react';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText, StyledLink, MoreLink } from 'components/Common/BasePageUi/BasePageUi';
import { getFormattedPhone } from 'helpers';
import useRole from 'hooks/useRole';

const CustomerCard = ({ account, customer, caller_name, caller_phone, member_first_name, member_last_name, member_phone, sx }) => {
  const { isManager, isOwner } = useRole();

  return (
    <Card size='small' sx={sx}>
      <CardHeader>
        <CardTitle>Customer</CardTitle>
        { (isManager || isOwner) && <MoreLink to={`/accounts/${account?.id}`} /> }
      </CardHeader>
      <ItemsContainer>
        <Item halfWidth>
          <ItemLabel>Customer Name</ItemLabel>
          <ItemText>{customer?.name ?? '-'}</ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Account</ItemLabel>
          <ItemText>{account?.name ?? '-'}</ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Customer Email</ItemLabel>
          <ItemText><StyledLink href={`mailto:${customer?.email}`}>{customer?.email ?? '-'}</StyledLink></ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Phone Number</ItemLabel>
          <ItemText><StyledLink href={`tel:${customer?.phone}`}>{getFormattedPhone(customer?.phone) ?? '-'}</StyledLink></ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Caller Name</ItemLabel>
          <ItemText>{caller_name ?? '-'}</ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Caller Phone Number</ItemLabel>
          <ItemText><StyledLink href={`tel:${caller_phone}`}>{getFormattedPhone(caller_phone) ?? '-'}</StyledLink></ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Member Name</ItemLabel>
          <ItemText>{!!member_first_name ? `${member_first_name} ${member_last_name}` : '-'}</ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Member Phone Number</ItemLabel>
          <ItemText><StyledLink href={`tel:${member_phone}`}>{getFormattedPhone(member_phone) ?? '-'}</StyledLink></ItemText>
        </Item>
      </ItemsContainer>
    </Card>
  );
};

export default memo(CustomerCard);
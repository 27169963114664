import { memo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import Map from 'components/Map/Map';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';
import useMap from 'hooks/useMap';

const MapDialog = () => {
  const { closeMapDialog } = useDialogs();
  const { clearLocalMapData, setMapConfigs } = useMap();
  const hideAppBar = localStorage.getItem('hide_app_bar') === 'true';
  const { open, order, isISSC, isUrgently } = useCustomSelector(state => state.dialogs.map);
  
  const handleClose = () => {
    clearLocalMapData();
    setMapConfigs({ localIncident: { value: '', latitude: 0, longitude: 0 } });
    closeMapDialog();
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        sx: {
          width: '100%',
          height: { xs: '100%', md: hideAppBar ? '100%' : 'calc(100% - 68px)'},
          borderRadius: { xs: 0, md: '16px' }
      } }}
      title={<>
        Assign Driver
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      </>}
    >
      <DialogContent sx={{ p: 0 }}>
        <Map isModal {...{ order, isISSC, isUrgently }} />
      </DialogContent>
    </BaseDialog>
  );
};

export default memo(MapDialog);
import styled from '@mui/material/styles/styled';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export const StyledTab = styled(Tab)({
  minWidth: '90px',
  minHeight: 'unset',
});
export const Section = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 32,
  marginBottom: 16
}));
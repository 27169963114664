import BaseStats from 'components/Common/BaseStats/BaseStats';
import BaseStatsCard from 'components/Common/BaseStatsCard/BaseStatsCard';
import { orderStatuses } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';

const { NEW, COMPLETED, REJECTED, EXPIRED } = orderStatuses;

const Stats = ({ onStatuses }) => {
  const { new_orders_count, completed_orders_count, rejected_orders_count, expired_orders_count } = useCustomSelector(state => state.orders.statistics);

  return (
    <BaseStats clear={() => onStatuses([])}>
      <BaseStatsCard onClick={() => onStatuses([NEW])} title='New Orders' firstValue={new_orders_count} firstLabel='Calls' />
      <BaseStatsCard onClick={() => onStatuses([COMPLETED])} title='Completed Orders' firstValue={completed_orders_count} firstLabel='Calls'/>
      <BaseStatsCard onClick={() => onStatuses([REJECTED])} title='Rejected Orders' firstValue={rejected_orders_count} firstLabel='Calls' />
      <BaseStatsCard onClick={() => onStatuses([EXPIRED])} title='Missed Orders' firstValue={expired_orders_count} firstLabel='Calls' />
    </BaseStats>
  );
};

export default Stats;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  createBlob as createBlobAction
} from 'actions/blobs';
import useError from './useError';

const useBlob = () => {
  const { setError } = useError();
  const dispatch = useDispatch();
  const createBlob = useCallback(blob => dispatch(createBlobAction(blob)), [dispatch]);

  const processBlob = ({ file, checksum, type }, callback = null) => {
    const blob = { name: file.name, size: file.size, type: file.type, checksum, file };

    createBlob(blob)
    .then(({ payload: { data: { blob: { id, url, content_type, filename } } } }) => callback({ id, url, content_type, filename }))
    .catch(error => setError(error));
  };

  return { processBlob };
};

export default useBlob;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  openAssignDialog as openAssignDialogAction, 
  closeAssignDialog as closeAssignDialogAction,
  openCancelOrderDialog as openCancelOrderDialogAction,
  closeCancelOrderDialog as closeCancelOrderDialogAction,
  openConfirmationDialog as openConfirmationDialogAction, 
  closeConfirmationDialog as closeConfirmationDialogAction,
  openDocumentsDialog as openDocumentsDialogAction,
  closeDocumentsDialog as closeDocumentsDialogAction,
  openFilePreviewDialog as openFilePreviewDialogAction, 
  closeFilePreviewDialog as closeFilePreviewDialogAction,
  openInvoiceDialog as openInvoiceDialogAction, 
  closeInvoiceDialog as closeInvoiceDialogAction,
  openLocationDialog as openLocationDialogAction,
  closeLocationDialog as closeLocationDialogAction,
  openMapDialog as openMapDialogAction,
  closeMapDialog as closeMapDialogAction,
  openPasswordDialog as openPasswordDialogAction, 
  closePasswordDialog as closePasswordDialogAction,
  openRejectOrderDialog as openRejectOrderDialogAction, 
  closeRejectOrderDialog as closeRejectOrderDialogAction,
  openSignatureDialog as openSignatureDialogAction,
  closeSignatureDialog as closeSignatureDialogAction,
  openTowVehicleDialog as openTowVehicleDialogAction,
  closeTowVehicleDialog as closeTowVehicleDialogAction,
  openTakeOutVehicleDialog as openTakeOutVehicleDialogAction,
  closeTakeOutVehicleDialog as closeTakeOutVehicleDialogAction
} from 'actions/dialogs';

const useDialogs = () => {
  const dispatch = useDispatch();
  const openAssignDialog = useCallback(({ order, isISSC, isUrgently }) => dispatch(openAssignDialogAction({ order, isISSC, isUrgently })), [dispatch]);
  const closeAssignDialog = useCallback(() => dispatch(closeAssignDialogAction()), [dispatch]);
  const openCancelOrderDialog = useCallback(({ id, source }) => dispatch(openCancelOrderDialogAction({ id, source })), [dispatch]);
  const closeCancelOrderDialog = useCallback(() => dispatch(closeCancelOrderDialogAction()), [dispatch]);
  const openConfirmationDialog = useCallback(({ type, content, id, reason }) => dispatch(openConfirmationDialogAction({ type, content, id, reason })), [dispatch]);
  const closeConfirmationDialog = useCallback(({ yes, no, id }) => dispatch(closeConfirmationDialogAction({ yes, no, id })), [dispatch]);
  const openDocumentsDialog = useCallback(({ documents, isOrderDrawer }) => dispatch(openDocumentsDialogAction({ documents, isOrderDrawer })), [dispatch]);
  const closeDocumentsDialog = useCallback(() => dispatch(closeDocumentsDialogAction()), [dispatch]);
  const openFilePreviewDialog = useCallback(({ filename, preview, url, address }) => dispatch(openFilePreviewDialogAction({ filename, preview, url, address })), [dispatch]);
  const closeFilePreviewDialog = useCallback(() => dispatch(closeFilePreviewDialogAction()), [dispatch]);
  const openInvoiceDialog = useCallback(url => dispatch(openInvoiceDialogAction(url)), [dispatch]);
  const closeInvoiceDialog = useCallback(() => dispatch(closeInvoiceDialogAction()), [dispatch]);
  const openLocationDialog = useCallback(company_id => dispatch(openLocationDialogAction(company_id)), [dispatch]);
  const closeLocationDialog = useCallback(() => dispatch(closeLocationDialogAction()), [dispatch]);
  const openMapDialog = useCallback(({ order, isISSC, isUrgently }) => dispatch(openMapDialogAction({ order, isISSC, isUrgently })), [dispatch]);
  const closeMapDialog = useCallback(() => dispatch(closeMapDialogAction()), [dispatch]);
  const openPasswordDialog = useCallback(() => dispatch(openPasswordDialogAction()), [dispatch]);
  const closePasswordDialog = useCallback(() => dispatch(closePasswordDialogAction()), [dispatch]);
  const openRejectOrderDialog = useCallback(({ id, source }) => dispatch(openRejectOrderDialogAction({ id, source })), [dispatch]);
  const closeRejectOrderDialog = useCallback(() => dispatch(closeRejectOrderDialogAction()), [dispatch]);
  const openSignatureDialog = useCallback((id) => dispatch(openSignatureDialogAction(id)), [dispatch]);
  const closeSignatureDialog = useCallback(() => dispatch(closeSignatureDialogAction()), [dispatch]);
  const openTowVehicleDialog = useCallback(() => dispatch(openTowVehicleDialogAction()), [dispatch]);
  const closeTowVehicleDialog = useCallback(() => dispatch(closeTowVehicleDialogAction()), [dispatch]);
  const openTakeOutVehicleDialog = useCallback(() => dispatch(openTakeOutVehicleDialogAction()), [dispatch]);
  const closeTakeOutVehicleDialog = useCallback(() => dispatch(closeTakeOutVehicleDialogAction()), [dispatch]);

  return {
    openAssignDialog,
    closeAssignDialog,
    openCancelOrderDialog,
    closeCancelOrderDialog,
    openConfirmationDialog,
    closeConfirmationDialog,
    openDocumentsDialog,
    closeDocumentsDialog,
    openFilePreviewDialog,
    closeFilePreviewDialog,
    openInvoiceDialog,
    closeInvoiceDialog,
    openLocationDialog,
    closeLocationDialog,
    openMapDialog,
    closeMapDialog,
    openPasswordDialog,
    closePasswordDialog,
    openRejectOrderDialog,
    closeRejectOrderDialog,
    openSignatureDialog,
    closeSignatureDialog,
    openTowVehicleDialog,
    closeTowVehicleDialog,
    openTakeOutVehicleDialog,
    closeTakeOutVehicleDialog
  };
};

export default useDialogs;
// eslint-disable-next-line no-unused-vars
const videos = [
    {
      title: "How to Create and Assign Orders",
      id: "aN0y_hj-DdI",
      description: "Discover effective order creation and assignment techniques."
    },
    {
      title: "How to Set Up a Driver, Vehicle and GPS Tracking",
      id: "Opqxrsh8diE",
      description: "Master the setup of driver, vehicle, and GPS tracking systems."
    },
    {
      title: "How to Receive Your First Order",
      id: "8cSlQiHOHvM",
      description: "Discover the steps to receiving your first order."
    },
    {
      title: "How to Modify an Order",
      id: "mih0I2hVdmQ",
      description: "Learn the process of modifying an order efficiently."
    },
    {
      title: "How to Create a Customer's Account",
      id: "iR3fVjFX3nE",
      description: "Discover the steps to creating a customer's account seamlessly."
    },
    {
      title: "How do I charge customers",
      id: "J_FPM3JXQkE",
      description: "Understand the process of charging customers effectively."
    },
    {
      title: "How to Set Up Order Notifications",
      id: "Qh4zBfHvLAY",
      description: "Master the setup of order notifications with ease."
    },
    {
      title: "How to Modify an Invoice",
      id: "Tu_lqBSzl6A",
      description: "Learn how to modify an invoice efficiently."
    }
  ];
  
  export default videos;
import { memo } from 'react';
// Local files
import BaseModalDetails from 'components/Common/BaseModalDetails/BaseModalDetails';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import Details from 'components/Orders/Details/Details';

const OrderDetails = () => {

  return (
    <BaseModalDetails>
      <ComponentErrorWrapper children={<Details />} />
    </BaseModalDetails>
  );
};

export default memo(OrderDetails);
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setSuccess as setSuccessAction,
  hideSuccess as hideSuccessAction
} from 'actions/success';

const useSuccess = () => {
  const dispatch = useDispatch();
  const setSuccess = useCallback(success => dispatch(setSuccessAction(success)), [dispatch]);
  const hideSuccess = useCallback(() => dispatch(hideSuccessAction()), [dispatch]);

  return { setSuccess, hideSuccess };
};

export default useSuccess;
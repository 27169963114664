import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getInvoicesRoutine, 
  getInvoiceRoutine, 
  getInvoiceForNotificationRoutine,
  createInvoiceSignatureRoutine,
  updateInvoiceRoutine,
  approveInvoiceRoutine,
  unApproveInvoiceRoutine,
  rejectInvoiceRoutine,
  sendInvoiceDetailsRoutine,
  clearLocalInvoicesRoutine,
  clearLocalActiveInvoiceRoutine,
  getInvoicesStatisticsRoutine,
  clearInvoicesStatisticsRoutine
} from 'actions';

const shortAttributes = [
  'id', 
  'number', 
  'status', 
  'created_at',
  'total_amount',
  { order: ['id', { services: ['id', 'name', 'service_type'] }, { account: ['id', 'name', 'account_type'] }] },
  { expenses: [
    'id', 
    { rate: ['id', { charge: ['name', 'charge_type'] }, 'value', 'basic_value_type', 'fee_value_type'] }, 
    { default_charge: ['id', { charge: ['name', 'charge_type'] }, 'basic_rate_type'] }, 
    { service: ['name'] }, 
    'name', 'unit_price', 'quantity', 'hourly_charge_duration', 'updated_at'
  ] },
  { customer_signature: ['id'] },
  { file: ['url'] },
  { recipients: ['id', 'delivery_method', 'name', 'phone', 'email'] }
];
const attributes = [
  ...shortAttributes, 
  { order: [
    'id',
    'authorization_number', 
    'number', 
    'status', 
    'source',
    'keys_availability', 
    'driver_attendance',
    'internal_notes', 
    'dispatcher_notes',
    'police_number',
    'police_code',
    'caller_name',
    'caller_phone',
    'member_first_name',
    'member_last_name',
    'member_phone',
    'digital_dispatch_webhook',
    'deadhead_miles_count', 
    'en_route_miles_count', 
    'towed_miles_count',
    { user: ['id', 'first_name', 'last_name', 'phone', 'longitude', 'latitude'] },
    { dispatcher: ['first_name', 'last_name'] },
    { customer: ['id', 'name', { cards: ['id', 'brand', 'last4'] }, 'email', 'phone'] },
    { company_location: ['id', 'name', 'email', 'phone', 'address', { company: ['id', 'name'] }] },
    { incident_location: ['longitude', 'latitude', 'address'] },
    { destination_location: ['longitude', 'latitude', 'address'] },
    { customer_location: ['longitude', 'latitude', 'address'] },
    { vehicle: [
      'id',
      'status',
      'vin',
      'make',
      'model',
      'year',
      'color',
      'drive_type',
      'license',
      'license_state',
      'odometer'
    ] },
    { truck: ['make', 'model'] }
  ] },
  { payments: ['id', { card: ['id', 'brand', 'last4'] }, 'payer', 'payment_type', 'amount' , 'created_at'] },
  'minimum_amount',
  'paid_amount',
  'notes'
];
const statisticsAttributes = [
  'total_invoices_count',
  'total_invoices_amount',
  'average_invoices_amount',
  'monthly_change',
  'partially_paid_invoices_count',
  'partially_paid_invoices_amount', 
  'approved_invoices_count', 
  'approved_invoices_amount',
  'paid_invoices_count',
  'paid_invoices_amount',
  'over_time_invoices_data'
];

export const getInvoices = getThunkActionCreator(
  getInvoicesRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, query = null, statuses = [] }) => {
    getInvoices.getInvoicesCancel && getInvoices.getInvoicesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/invoices`,
      {
        params: { 
          access_token, 
          limit: 25, 
          offset, 
          orders, 
          ...(!!query && { query }), 
          ...(!!!query && !!statuses.length && { statuses }), 
          attributes: shortAttributes 
        },
        cancelToken: new CancelToken(function executor(cancel) { getInvoices.getInvoicesCancel = cancel })
      }
    );
  }
);
export const getInvoice = getThunkActionCreator(
  getInvoiceRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/invoices/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getInvoice.getInvoiceCancel = cancel })
      }
    );
  }
);
export const getInvoiceForNotification = getThunkActionCreator(
  getInvoiceForNotificationRoutine,
  async (id) => {
    getInvoiceForNotification.getInvoiceForNotificationCancel && getInvoiceForNotification.getInvoiceForNotificationCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/invoices/${id}`, {
      params: { access_token, attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getInvoiceForNotification.getInvoiceForNotificationCancel = cancel })
    });
  }
);
export const createInvoiceSignature = getThunkActionCreator(
  createInvoiceSignatureRoutine,
  async ({ id, blob_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/invoices/${id}/sign`, { access_token, blob_id, attributes });
  }
);
export const updateInvoice = getThunkActionCreator(
  updateInvoiceRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/invoices/${id}`, { access_token, invoice: fields });

    return { response, invoice: { id, ...fields } };
  }
);
export const approveInvoice = getThunkActionCreator(  
  approveInvoiceRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/invoices/${id}/approve`, { access_token });

    return { response, id };
  }
);
export const unApproveInvoice = getThunkActionCreator(  
  unApproveInvoiceRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/invoices/${id}/unapprove`, { access_token });

    return { response, id };
  }
);
export const rejectInvoice = getThunkActionCreator(  
  rejectInvoiceRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/invoices/${id}/reject`, { access_token });

    return { response, id };
  }
);
export const sendInvoiceDetails = getThunkActionCreator(  
  sendInvoiceDetailsRoutine,
  async ({ id, hide_charges = false, recipient_ids, document_ids = [] }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/invoices/${id}/send_details`, { access_token, recipient_ids, hide_charges, ...(!!document_ids.length && { document_ids }) });
  }
);
export const clearLocalInvoices = getThunkActionCreator(clearLocalInvoicesRoutine, async () => {});
export const clearLocalActiveInvoice = getThunkActionCreator(clearLocalActiveInvoiceRoutine, async () => {});
export const getInvoicesStatistics = getThunkActionCreator(
  getInvoicesStatisticsRoutine,
  async ({ filterDate = null, reason = null, status = 'all_statuses', account_id = 'all_accounts' }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.get(`/user/invoices/statistics`,
      {
        params: { 
          access_token, 
          attributes: statisticsAttributes, 
          ...(!!filterDate?.start_at && { start_at: filterDate.start_at, end_at: filterDate.end_at }),
          ...(status !== 'all_statuses' && { status }),
          ...(account_id !== 'all_accounts' && { account_id })
        },
        cancelToken: new CancelToken(function executor(cancel) { getInvoicesStatistics.getInvoicesStatisticsCancel = cancel })
      }
    );

    return { response, reason };
  }
);
export const clearInvoicesStatistics = getThunkActionCreator(clearInvoicesStatisticsRoutine, async () => {});
import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

const smallBreakpoint = 400;

export const Root = styled(ButtonBase,
  { shouldForwardProp: (prop) => prop !== 'selected' }
)(({ theme, selected }) => ({
  flexBasis: 110,
  flexShrink: 0,
  fontSize: theme.typography.pxToRem(24),
  lineHeight: 1,
  fontWeight: 700,
  textAlign: 'center',
  backgroundColor: theme.palette.background.paper,
  boxShadow: `inset 0 0 0 1px #C4C4C4`,
  borderRadius: '8px',
  padding: '16px',
  userSelect: 'none',
  ...(selected && {
    backgroundColor: theme.palette.background.selected,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
  }),
  [theme.breakpoints.down(smallBreakpoint)]: {
    flexBasis: 90,
    padding: '12px',
    fontSize: theme.typography.pxToRem(16),
  }
}));
import { memo } from 'react';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText } from 'components/Common/BasePageUi/BasePageUi';

const MilageInfoCard = ({ deadhead_miles_count, en_route_miles_count, towed_miles_count, sx }) => {

  return (
    <Card sx={sx}>
      <CardHeader>
        <CardTitle>Milage Info</CardTitle>
      </CardHeader>
      <ItemsContainer sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Item>
          <ItemLabel>En route</ItemLabel>
          <ItemText>{en_route_miles_count}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Towed</ItemLabel>
          <ItemText>{towed_miles_count}</ItemText>
        </Item> 
        <Item>
          <ItemLabel>Deadhead</ItemLabel>
          <ItemText>{deadhead_miles_count}</ItemText>
        </Item>
      </ItemsContainer> 
    </Card>
  );
};

export default memo(MilageInfoCard);
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Input = styled('input')({ display: 'none' });

export const DocumentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexWrap: 'wrap', 
  gap: '20px', 
  pt: '16px', 
  maxHeight: 260, 
  overflowY: 'auto',
  ...theme.scrollbars.default
}));
import { memo, useEffect, useCallback, useState } from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';
import _forEach from 'lodash/forEach';
import _uniqBy from 'lodash/uniqBy';
import _includes from 'lodash/includes';
// Local files
import axios from 'axios';
import BaseListItem from 'components/Common/BaseListItem/BaseListItem';
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import DriverListItem from 'components/Users/DriverListItem/DriverListItem';
import { orderStatuses, userRoles } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useUsers from 'hooks/useUsers';
import useMap from 'hooks/useMap';
import useUrl from 'hooks/useUrl';

const { DRIVER, MANAGER, OWNER } = userRoles;
const { NEW, COMPANY_ACCEPTED, MANAGER_ACCEPTED, EN_ROUTE, DROP_OFF, SERVICING, PICK_UP, REJECTED, CANCELED, EXPIRED, COMPLETED } = orderStatuses;

const UsersList = ({ isModal = false, incident_location, fetching, query, onFetching = null, onMarkerClick = null, onAssign = null, isAvailable, setUserConfig}) => {
  const { id: userId } = useParams();
  const { getDirectionOnMap } = useMap();
  const { getUsers, clearLocalUsers, startUserDuty, stopUserDuty } = useUsers();
  const { setError } = useError();
  const { isCompanyRoute, isDriversRoute } = useUrl();
  const { data, pagination } = useCustomSelector(state => state.users.all);
  const { configs: { popup } } = useCustomSelector(state => state.map);
  const [formatedDrivers, setFormatedDrivers] = useState([]);
  const users = _filter(data, (user) => isDriversRoute ? _includes(user.roles, DRIVER) : _includes(user.roles, MANAGER) || _includes(user.roles, OWNER));
  const drivers = useCustomSelector(state =>
    _map(_filter(state.map.points, (point) => 
    point?.properties?.data?.first_name), (point) => 
    ({ id: point.properties?.id, longitude: point?.geometry?.coordinates[0], latitude: point?.geometry?.coordinates[1], ...point?.properties?.data }))
  );

  const handleChangeDutyStatus = (userId, onDuty) => {
    const dutyAction = onDuty ? stopUserDuty : startUserDuty;
    dutyAction(userId)
      .then((data) => {
        setFormatedDrivers((prevDrivers) =>
          prevDrivers.map(driver =>
            driver.id === userId ? { ...driver, duty_duration: !driver.duty_duration } : driver
          )
        );
        setUserConfig(data.payload.data.user)
      })
      .catch(error => {
        setError(error)
      });
  };

  const getDescription = (driver) => {
    if (isModal) {
      return (
        <>
          <Typography variant='body2' color='primary.main'>
            {!!driver.distance ? `${(driver?.distance * 0.000621371192)?.toFixed(1)} miles away 
            ${!!driver?.current_order ? driver?.current_order?.status === DROP_OFF ? 'after' : 'including' : ''} 
            ${!!driver?.current_order ? _startCase(DROP_OFF)?.toLocaleLowerCase() : ''}` : 'No Location Data'}
          </Typography>
          {!!driver?.current_order && !!driver.destinationDistance && <Typography variant='caption'>
            {`${(driver?.destinationDistance * 0.000621371192)?.toFixed(1)} miles from destination`}
          </Typography> }
        </>
      )
    } else {
      return !!driver?.current_order ? 
        `${driver?.current_order?.number}` : 
        `Order Completed at ${new Date(driver?.last_user_orders[0]?.created_at || new Date()).toLocaleDateString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: driver?.last_user_orders[0]?.company_location?.company?.time_zone })}`;
    }
  };
  const fetchUsers = useCallback((offset, query) => {
    onFetching(true);
    getUsers({ offset, query, roles: isDriversRoute ? [DRIVER] : [MANAGER, OWNER] })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => onFetching(false));
  }, [getUsers, setError]); // eslint-disable-line react-hooks/exhaustive-deps
  const sortDrivers = (array) => 
    array.sort((a,b) => {
      if (a?.distance === b?.distance) {
        return 0;
      }
      if (a?.distance === 0) {
        return 1;
      }
      if (b?.distance === 0) {
        return -1;
      }
        return a?.distance < b?.distance ? -1 : 1;
    });
  
  const getDistances = useCallback((drivers, driver) => {
    let distance = 0;

    if (!!driver.longitude) {
      if (driver?.current_order?.status === DROP_OFF || driver?.current_order?.status === REJECTED || driver?.current_order?.status === COMPLETED ||
        driver?.current_order?.status === CANCELED || driver?.current_order?.status === EXPIRED || !!!driver?.current_order){
        getDirectionOnMap({ start: `${driver.longitude},${driver.latitude}`, end: `${incident_location?.longitude || driver.longitude},${incident_location?.latitude || driver.latitude}` })
        .then(({ payload: { data: { routes } } }) => {
          setFormatedDrivers(sortDrivers(_uniqBy([...drivers, {..._find(drivers, (d) => d.id === driver.id), distance: routes[0]?.distance || 0, destinationDistance: routes[0]?.distance || 0}], 'id')));
        })
      } else {
        if (driver?.current_order?.status === EN_ROUTE || driver?.current_order?.status === COMPANY_ACCEPTED || 
          driver?.current_order?.status === MANAGER_ACCEPTED || driver?.current_order?.status === NEW){
          getDirectionOnMap({ start: `${driver.longitude},${driver.latitude}`, end: `${driver.current_order.incident_location?.longitude},${driver.current_order.incident_location?.latitude}` })
          .then(({ payload: { data: { routes } } }) => {
            distance = routes[0]?.distance || 0;
            getDirectionOnMap({ start: `${driver.current_order.incident_location?.longitude},${driver.current_order.incident_location?.latitude}`, end: `${driver.current_order.destination_location?.longitude || incident_location?.longitude || driver.longitude},${driver.current_order.destination_location?.latitude || incident_location?.latitude || driver.latitude}` })
            .then(({ payload: { data: { routes } } }) => {
              distance = distance + (routes[0]?.distance || 0);
              getDirectionOnMap({ start: `${driver.current_order.destination_location?.longitude || driver.longitude},${driver.current_order.destination_location?.latitude || driver.latitude}`, end: `${incident_location?.longitude || driver.longitude},${incident_location?.latitude || driver.latitude}` })
              .then(({ payload: { data: { routes } } }) => { 
                setFormatedDrivers(
                  sortDrivers(_uniqBy([...drivers, 
                    {..._find(drivers, (d) => d.id === driver.id), 
                      distance: distance + (routes[0]?.distance || 0), 
                      destinationDistance: routes[0]?.distance || 0
                    }]
                  , 'id')));
              });
            })
          })
        }
        if (driver?.current_order?.status === PICK_UP || driver?.current_order?.status === SERVICING){
          getDirectionOnMap({ start: `${driver.longitude},${driver.latitude}`, end: `${driver.current_order.destination_location?.longitude || incident_location?.longitude || driver.longitude},${driver.current_order.destination_location?.latitude || incident_location?.latitude || driver.latitude}` })
          .then(({ payload: { data: { routes } } }) => {
            setFormatedDrivers(
              sortDrivers(_uniqBy([...drivers, 
                {..._find(drivers, (d) => d.id === driver.id), 
                  distance: distance + (routes[0]?.distance || 0), 
                  destinationDistance: routes[0]?.distance || 0
                }]
              , 'id')));
          })
        }
      }
    } else {
      setFormatedDrivers(sortDrivers(_uniqBy([...drivers, {..._find(drivers, (d) => d.id === driver.id), distance: 0, destinationDistance: 0 }], 'id')));
    }
  }, [incident_location, getDirectionOnMap]);

  useEffect(() => {
    let timeout = isCompanyRoute && setTimeout(() => clearLocalUsers().then(() => fetchUsers(0, query)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [fetchUsers, clearLocalUsers, query, isCompanyRoute]);
  useEffect(() => {
    isModal ? _forEach(drivers, (driver) => getDistances(drivers, driver)) : setFormatedDrivers(drivers);
  }, [isModal, drivers, getDistances]);
  useEffect(() => {
    return () => {
      setFormatedDrivers([]);
      clearLocalUsers();
    }
  }, [drivers, setFormatedDrivers, clearLocalUsers]);

  return (
    <BaseScrollableContainer {...pagination} onLoad={offset => !fetching && fetchUsers(offset, query)}>
      <List>
        {_map(isCompanyRoute ? users : formatedDrivers, (user) =>
        (isCompanyRoute ? 
          <BaseListItem
            key={user?.id}
            title={`${user?.first_name} ${user?.last_name}`}
            selected={user?.id === userId}
            to={`/company/${isDriversRoute ? DRIVER : MANAGER}s/${user?.id}`}
          /> :
          <DriverListItem
            key={user?.id}
            title={`${user?.first_name} ${user?.last_name}`}
            status={user?.current_order?.status}
            service={user?.current_order?.services[0]?.name}
            lastOrders={user.last_user_orders}
            selected={popup?.activePoint?.id === user?.id}
            description={getDescription(user)}
            onDuty={!!user.duty_duration}
            onClick={(e) => onMarkerClick(e, user?.id, user?.latitude, user?.longitude)}
            onAssign={onAssign}
            isAvailable={isAvailable}
            isNoSignal={!!!user?.longitude}
            isModal={isModal}
            onChangeDutyStatus={() => handleChangeDutyStatus(user?.id, !!user.duty_duration)}
          />
        ))}
      </List>
    </BaseScrollableContainer>
  );
};

UsersList.propTypes = {
  fetching: PropTypes.bool,
  query: PropTypes.string.isRequired,
  role: PropTypes.string,
  onFetching: PropTypes.func  
};

export default memo(UsersList);
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import _startCase from 'lodash/startCase';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText, } from 'components/Common/BasePageUi/BasePageUi';

const CurrentOrderCard = ({ current_order, isCompanyOrder }) => {
  return (
    <Card size='small'>
      <CardHeader>
        <CardTitle>Current Status</CardTitle>
      </CardHeader>
      <ItemsContainer sx={{ flexDirection: 'column' }}>
        <Item>
          <ItemLabel>Order Number</ItemLabel>
          <ItemText component={isCompanyOrder ? NavLink : 'text'} to={isCompanyOrder ? `/orders/${current_order?.id}` : null} sx={{ color: 'text.primary', textDecoration: 'none' }}>{current_order?.number || '-' }</ItemText>
        </Item>
        <Item>
          <ItemLabel>Job Type</ItemLabel>
          <ItemText>{_startCase(current_order?.services[0]?.name) || '-'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Status</ItemLabel>
          <ItemText>{_startCase(current_order?.status) || '-'}</ItemText>
        </Item>
      </ItemsContainer>
    </Card>
  );
};

export default memo(CurrentOrderCard);
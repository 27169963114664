import { memo } from 'react';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import BaseStatus from 'components/Common/BaseStatus/BaseStatus';
import { vehicleStatuses } from 'helpers/constants';

const { STORED, LAID, FOR_SALE } = vehicleStatuses;

const Status = ({ value, size, darkBackground }) => {

  switch (value) {
    case(STORED): {
      return (
        <BaseStatus size={size} color='primary' darkBackground={darkBackground} label={_startCase(vehicleStatuses[value.toUpperCase()])} />
      )
    }
    case(LAID): {
      return (
        <BaseStatus size={size} color='warning' darkBackground={darkBackground} label={_startCase(vehicleStatuses[value.toUpperCase()])} />
      )
    }
    case(FOR_SALE): {
      return (
        <BaseStatus size={size} color='error' darkBackground={darkBackground} label={_startCase(vehicleStatuses[value.toUpperCase()])} />
      )
    }
    default: {
      return null;
    }
  }
};

Status.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  darkBackground: PropTypes.bool
}

export default memo(Status);
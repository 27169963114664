import { memo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

const Other = ({ type, selected, disabled, onClick }) => 
  <ListItem disablePadding>
    <ListItemButton {...{ selected, disabled, onClick }}>
      {type}
    </ListItemButton>
  </ListItem>;

export default memo(Other);
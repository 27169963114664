import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _pick from 'lodash/pick';
// Local files
import { CardsContainer, DetailsContainer, DetailsWrapper } from 'components/Common/BasePageUi/BasePageUi';
import CurrentOrderCard from 'components/Orders/Cards/CurrentOrder/CurrentOrder';
import OrdersHistoryCard from 'components/Orders/Cards/OrdersHistory/OrdersHistory';
import NotesCard from 'components/Notes/Card/Card';
import DocumentsCard from 'components/Documents/Card/Card';
import Placeholder from 'components/Common/Placeholder/Placeholder';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import Main from '../Cards/Main/Main';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useTrucks from 'hooks/useTrucks';
import { models } from 'helpers/constants';

const Details = () => {
  const { id } = useParams();
  const { setFetchingDetails } = useApp();
  const { setError } = useError();
  const { getTruck } = useTrucks();
  const fetchedTruck = useCustomSelector(state => state.trucks.truck);
  const isFetching = useCustomSelector(state => state.app.isFetching);

  const currentOrderData = _pick(fetchedTruck, ['current_order']);
  const mainData = { ..._pick(fetchedTruck, ['id', 'make', 'model', 'year', 'type', 'created_at', 'last_orders'])};
  const ordersHistoryData = _pick(fetchedTruck, ['last_orders']);
  const notesData = { previous_notes: fetchedTruck.notes, id: fetchedTruck.id };
  const documentsData = { id: fetchedTruck.id, model: models.TRUCKS };

  useEffect(() => {
    if (!!id) {
      if (id !== fetchedTruck.id) {
        setFetchingDetails(true);
        getTruck(id)
        .then(() => setFetchingDetails(false))
        .catch(error => setError(error))
      }
    }
  }, [id, fetchedTruck.id, getTruck, setFetchingDetails, setError]);

  if (!id) return <Placeholder model={models.TRUCKS} />;
  if (!!!fetchedTruck.id || isFetching) return <PageLoader />;
  return (
    <DetailsContainer sx={{ pt: '0px !important' }}>
      <DetailsWrapper>
        <Main {...mainData} />
        <CardsContainer>
          <CurrentOrderCard {...currentOrderData} />
          <OrdersHistoryCard {...ordersHistoryData} />
          <NotesCard hideTabs edit disabled={!!!fetchedTruck.nickname} {...notesData} />
          <DocumentsCard {...documentsData} />
        </CardsContainer>
      </DetailsWrapper>
    </DetailsContainer>
  );
};

export default memo(Details);
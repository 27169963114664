import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  createExpense as createExpenseAction,
  updateExpense as updateExpenseAction,
  deleteExpense as deleteExpenseAction,
  suggestInvoiceExpenses as suggestInvoiceExpensesAction
} from 'actions/expenses';

const useExpenses = () => {
  const dispatch = useDispatch();
  const createExpense = useCallback(({ expense, invoice_id }) => dispatch(createExpenseAction({ expense, invoice_id })), [dispatch]);
  const updateExpense = useCallback(expense => dispatch(updateExpenseAction(expense)), [dispatch]);
  const deleteExpense = useCallback(id => dispatch(deleteExpenseAction(id)), [dispatch]);
  const suggestInvoiceExpenses = useCallback(({ service_id, invoice_id, expense_name }) => dispatch(suggestInvoiceExpensesAction({ service_id, invoice_id, expense_name })), [dispatch]);

  return { createExpense, updateExpense, deleteExpense, suggestInvoiceExpenses };
};

export default useExpenses;
import { memo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';

const FilePreview = () => {
  const { closeFilePreviewDialog } = useDialogs();
  const { open, filename, preview, url, address } = useCustomSelector(state => state.dialogs.filePreview);

  const handleDownloadClick = () => window.open(url, '_blank').focus();

  return (
    <BaseDialog
      open={open}
      onClose={closeFilePreviewDialog}
      PaperProps={{ sx: { width: 'auto',  borderRadius: '16px' } }}
      title={filename}
    >
      <DialogContent>
        <img src={preview} alt={filename} />
        { !!address && <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
        <Typography variant='subtitle2' sx={{ mr: '4px' }}>Location:</Typography>
          <Typography variant='subtitle2' color='textSecondary' component='a' target='_blank' href={`https://www.google.com/maps/dir/?api=1&destination=${address}`} noWrap sx={{ textDecoration: 'none' }}>
            {address}
          </Typography>
        </Box>}
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          onClick={closeFilePreviewDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          onClick={handleDownloadClick}
        >
          View Full Size
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(FilePreview);
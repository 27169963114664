import { chargeTypes, rateValueType, shortMonths } from './constants';
import { parse, toSeconds } from 'iso8601-duration';
import _find from 'lodash/find';

export const checkEmptyString = (str) => {
  return !!str.replace(/\s/g, '').length;
};
export const checkValidEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;//eslint-disable-line
  return re.test(String(email).toLowerCase());
};
export const getFormattedDate = (isoDate) => {
  const date = new Date(isoDate);

  return !!isoDate ? `${shortMonths[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}` : '-';
};
export const getFormattedPhone = (phone) => {
  return !!phone ? `(${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 12)}` : '-';
};
export const formatTextWithLinks = (text) => {
  const linkRegex = /(?:https?|ftp):\/\/[^\s]+|www\.[^\s]+/g;
  return text.replace(linkRegex, (url) => {
    const fullURL = url.startsWith('www.') ? `http://${url}` : url;
    return `<a href="${fullURL}" target="_blank">${url}</a>`;
  });
};
export const ucFirst = (str) => !!str && str[0].toUpperCase() + str.slice(1);
export const getRandomString = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  
  return result;
};
export const getMinutesDiff = (date) => Math.ceil(Math.abs(new Date() - new Date(date)) / (1000 * 60 * 60));
export const formatAmPm = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
};
export const detectOS = () => {
  let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};
export const getExpenseTypeLabel = (expense) => {
  return !!expense ? `${expense?.charge?.charge_type === chargeTypes.BASIC && ((expense?.basic_value_type || expense?.basic_rate_type) !== 'fixed') ? '/' : ''}${rateValueType[expense?.charge?.charge_type === chargeTypes.BASIC ? (expense?.basic_value_type || expense?.basic_rate_type) : expense?.fee_value_type]}` : '';
};
export const getExpenseTotal = (expense, totalWithoutFee = 0) => {
  let total = '';

  if (expense?.default_charge?.basic_rate_type === 'fixed' || expense?.rate?.fee_value_type === 'fixed' || expense?.rate?.basic_value_type === 'fixed') {
    total = (expense?.unit_price || expense?.rate?.value || 0) * expense?.quantity;
  } else if (expense?.rate?.fee_value_type === 'percent') {
    total = (expense?.unit_price || expense?.rate?.value || 0) / 100 * totalWithoutFee;
  } else if ((expense?.default_charge?.basic_rate_type === 'per_mile' || expense?.default_charge?.basic_rate_type === 'per_day')
    || (expense?.rate?.basic_value_type === 'per_mile' || expense?.rate?.basic_value_type === 'per_day')) {
    total = (expense?.unit_price || expense?.rate?.value || 0) * expense?.quantity;

  } else if (expense?.default_charge?.basic_rate_type === 'per_hour' || expense?.rate?.basic_value_type === 'per_hour') {
    let calculationElement = getCalculationElement(expense);
    total = (expense?.unit_price || expense?.rate?.value || 0) * (toSeconds(parse(calculationElement))/3600).toFixed(2);
  }
  else if (!!expense?.unit_price) {
    total = expense?.unit_price * expense?.quantity;
  } else {
    total = 0;
  }
  return total;
};
export const parseDispatchText = (text) => text.replace(/=>/g, ': ').replace(/[^\d\s_,.:\-A-Z]/gi, '').split(/,/).map((str) => `${str.replace(':', '')}; `);
export const coequalWidget = ({ size, widget }) => {
  switch (size) {
    case 'large': return { id: widget.i, position_x: widget.x, position_y: widget.y };
    case 'medium': return { id: widget.i, position_x: Math.round(widget.x * 1.3), position_y: widget.y };
    default: break;
  }
};
export const getFormatedHoursMinutes = (duration) => {
  const seconds = toSeconds(parse(duration));
  const hours = ~~(seconds / 3600);
  const minutes = !!hours ? ~~((seconds - (hours * 3600)) / 60) : ~~(seconds / 60);

  return { hours, minutes };
};

export const formatCreationDate = (created_at) => {
  const createdDate = new Date(created_at);
  const currentDate = new Date();
  let date = '';

  if (createdDate <= currentDate) {
    const timeDifference = currentDate - createdDate;
    const isSameDay = createdDate.toDateString() === currentDate.toDateString();

    if (isSameDay) {
      date = `Today, ${createdDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}`;
    } else if (timeDifference <= 172_800_000 && !isSameDay) {
      date = 'Yesterday';
    } else {
      date = createdDate.toLocaleDateString('en-US');
    }
  } else {
    const timeDifference = createdDate - currentDate;
    const isTomorrow = new Date(currentDate.getTime() + 86_400_000).toDateString() === createdDate.toDateString();

    if (isTomorrow) {
      date = `Tomorrow`;
    } else if (timeDifference <= 86_400_000) {
      date = `Today, ${createdDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}`
    } else {
      date = createdDate.toLocaleDateString('en-US');
    }
  }

  return date;
};

export const getRepresentation = (representations, variant) => {
  const foundRepresentation = _find(representations, r => r.name === variant);

  return foundRepresentation?.url ?? null;
};
export const getFormattedStartAt = (startAt) => {
  return new Date(startAt.getFullYear(), startAt.getMonth(), startAt.getDate(), 0, 0 - startAt.getTimezoneOffset(), 0, 0).toISOString();
}
export const getFormattedEndAt = (endAt) => {
  return new Date(endAt.getFullYear(), endAt.getMonth(), endAt.getDate(), 23, 59 - endAt.getTimezoneOffset(), 59, 999).toISOString();
}
export const validateField = (value, setError, errorMessage) => {
  if (!value) {
    setError((prev) => ({ ...prev, error: errorMessage }));
    return true;
  }
  return false;
};
export const validateEmail = (email, setError) => {
  const isValid = checkValidEmail(email);
  if (!isValid) {
    setError((prev) => ({ ...prev, error: "Email is invalid" }));
  }
  return !isValid;
};
export const parseIsoDurationInMiliseconds = duration => {
  const regex = /P(?:([\d.]+)Y)?(?:([\d.]+)M)?(?:([\d.]+)W)?(?:([\d.]+)D)?(?:T(?:([\d.]+)H)?(?:([\d.]+)M)?(?:([\d.]+)S)?)?/;
  const matches = duration.match(regex);

  const years = parseFloat(matches[1] || 0);
  const months = parseFloat(matches[2] || 0);
  const weeks = parseFloat(matches[3] || 0);
  const days = parseFloat(matches[4] || 0);
  const hours = parseFloat(matches[5] || 0);
  const minutes = parseFloat(matches[6] || 0);
  const seconds = parseFloat(matches[7] || 0);

  const totalMilliseconds = (years * 365 * 24 * 60 * 60 * 1000) +
                            (months * 30 * 24 * 60 * 60 * 1000) +
                            (weeks * 7 * 24 * 60 * 60 * 1000) +
                            (days * 24 * 60 * 60 * 1000) +
                            (hours * 60 * 60 * 1000) +
                            (minutes * 60 * 1000) +
                            (seconds * 1000);

  return totalMilliseconds;
};

export  const getDisplayTime = (expense) => {
  let result;

  if (expense?.rate?.basic_value_type === 'per_hour' || expense?.default_charge?.basic_rate_type === 'per_hour') {
    let calculationElement = getCalculationElement(expense);
    let formattedDuration =  getFormatedHoursMinutes(calculationElement);
    result = `${formattedDuration.hours}h ${formattedDuration.minutes}m`;
  } else {
    result = expense?.quantity;
  }
  return result;
}

export const dayDifferenceFromToday = (date) => {
  const createdDate = new Date(date);
  const today = new Date();
  const differenceInTime = today - createdDate;
  return Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
}

export const getCalculationElement = (expense) => {
  let toCalculateWith;

  if (expense?.hourly_charge_duration && expense?.rate?.variant?.minimum_duration) {
    const hourlyCharge = toSeconds(parse(expense.hourly_charge_duration))
    const variantMin = toSeconds(parse(expense.rate.variant.minimum_duration))
    if (hourlyCharge > variantMin) {
      toCalculateWith = expense.hourly_charge_duration;
    } else {
      toCalculateWith = expense.rate.variant.minimum_duration;
    }
  }
  else if (expense?.hourly_charge_duration || expense?.rate?.variant?.minimum_duration) {
    toCalculateWith = expense?.hourly_charge_duration || expense?.rate?.variant?.minimum_duration;
  }

  return toCalculateWith;
}
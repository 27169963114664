import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getWidgets as getWidgetsAction,
  getWidget as getWidgetAction,
  createWidget as createWidgetAction,
  updateWidget as updateWidgetAction,
  updateWidgets as updateWidgetsAction,
  deleteWidget as deleteWidgetAction,
  clearLocalWidgets as clearLocalWidgetsAction,
  clearLocalActiveWidget as clearLocalActiveWidgetAction
} from 'actions/widgets';

const useWidgets = () => {
  const dispatch = useDispatch();
  const getWidgets = useCallback(({ offset, orders }) => dispatch(getWidgetsAction({ offset, orders })), [dispatch]);
  const getWidget = useCallback(id => dispatch(getWidgetAction(id)), [dispatch]);
  const createWidget = useCallback(widget => dispatch(createWidgetAction(widget)), [dispatch]);
  const updateWidget = useCallback(widget => dispatch(updateWidgetAction(widget)), [dispatch]);
  const updateWidgets = useCallback(widgets => dispatch(updateWidgetsAction(widgets)), [dispatch]);
  const deleteWidget = useCallback(id => dispatch(deleteWidgetAction(id)), [dispatch]);
  const clearLocalWidgets = useCallback(() => dispatch(clearLocalWidgetsAction()), [dispatch]);
  const clearLocalActiveWidget = useCallback(() => dispatch(clearLocalActiveWidgetAction()), [dispatch]);

  return {
    getWidgets,
    getWidget,
    createWidget,
    updateWidget,
    updateWidgets,
    deleteWidget,
    clearLocalWidgets,
    clearLocalActiveWidget
  };
};

export default useWidgets;
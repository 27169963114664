import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { updateNotificationRoutine } from 'actions';

export const updateNotification = getThunkActionCreator(
  updateNotificationRoutine,
  async ({ id, notification }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/notifications/${id}`, { access_token, notification });
    
    return { response, notification };
  }
);
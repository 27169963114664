import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _filter from 'lodash/filter';
import { useNavigate } from 'react-router-dom';
//Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import NotesCard from 'components/Notes/Card/Card';
import MapForm from 'components/Map/Form/Form';
import ServiceForm from 'components/Services/Form/Form';
import CustomerForm from 'components/Customers/Form/Form';
import DocumentsCard from 'components/Documents/Card/Card';
import VehicleForm from 'components/Vehicles/Form/Form';
import { reasons, sides, models } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomers from 'hooks/useCustomers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useOrders from 'hooks/useOrders';
import useVehicles from 'hooks/useVehicles';
import useInvoices from 'hooks/useInvoices';

const Drawer = () => {
  const navigate = useNavigate();
  const { closeDrawer } = useApp();
  const { setLocalOrderFields, createOrder, updateOrder, getOrder, clearLocalActiveOrder } = useOrders();
  const { updateInvoice } = useInvoices();
  const { setError } = useError();
  const ref = useRef(null);
  const { setSuccess } = useSuccess();
  const { createCustomer, updateCustomer } = useCustomers();
  const { createVehicle, updateVehicle } = useVehicles();
  const { open, edit, duplicate, towVehicle, onlyVehicle } = useCustomSelector(state => state.app.order);
  const localDocuments = useCustomSelector(state => state.orders.order.localDocuments);
  const uploadedOrder = useCustomSelector(state => state.orders.order);
  const [vehicle, setVehicle] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [notes, setNotes] = useState(null);
  const [service, setService] = useState(null);
  const [map, setMap] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [processingData, setProcessingData] = useState(false);

  const vehicleData = (edit || duplicate) ? {
    ..._pick(uploadedOrder.vehicle, ['id', 'vin', 'make', 'model', 'year', 'color', 'drive_type', 'license', 'license_state']), 
    ..._pick(uploadedOrder, ['driver_attendance', 'keys_availability'])
  } : null;
  const customerData = (edit || duplicate) ? {
    customer: _pick(uploadedOrder.customer, ['id', 'name', 'email', 'phone']),
    account: _pick(uploadedOrder.account, ['id', 'name', 'company']),
    ..._pick(uploadedOrder, ['caller_name', 'caller_phone'])
  } : null;
  const locationsData = (edit || duplicate) ? {
    ..._pick(uploadedOrder, ['incident_location', 'destination_location']) 
  } : null;
  const serviceData = (edit || duplicate) ? {
    ..._pick(uploadedOrder, ['id', 'dispatcher', 'services', 'company_location', 'truck', 'user', 'tow_reason', 'class_type', 'eta', 'police_number', 'police_code', 'authorization_number', 'source', 'scheduled_at']) 
  } : null;
  const notesData = (edit || duplicate) ? {
    ..._pick(uploadedOrder, ['description','internal_notes', 'dispatcher_notes']),
    invoice_notes: uploadedOrder?.invoice?.notes
  } : null;
  const documentsData =  towVehicle ? { id: towVehicle?.order?.id, model: models.ORDERS } : { id: uploadedOrder.id, model: models.ORDERS };
  const towVehicleData =  towVehicle ? {
      ..._pick(towVehicle, ['id', 'vin', 'make', 'model', 'year', 'color', 'drive_type', 'license', 'license_state']), 
      ..._pick(towVehicle.order, ['driver_attendance', 'keys_availability'])
    } : null;
  const towVehicleCustomerData = towVehicle ? {
    customer: _pick(towVehicle?.order?.customer, ['id', 'name', 'email', 'phone']),
    account: _pick(towVehicle?.order?.account, ['id', 'name', 'company']),
    ..._pick(towVehicle?.order, ['caller_name', 'caller_phone'])
  } : null;
  const towVehicleLocationData = towVehicle ? {
    ..._pick(towVehicle?.order, ['company_location']) 
  } : null;
  const towVehicleNotesData = towVehicle ? {
    ..._pick(towVehicle?.order, ['internal_notes', 'dispatcher_notes']),
    invoice_notes: towVehicle?.order?.invoice?.notes
  } : null;

  const handleExiting = () => {
    setVehicle(null);
    setCustomer(null);
    setNotes(null);
    setService(null);
    setMap(null)
    setProcessing(false);
    setLocalOrderFields({ localDocuments: [] });
  };
  const handleSubmitClick = () => {
    setProcessingData(true);
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleClose = useCallback(() => {
    duplicate && clearLocalActiveOrder();
    closeDrawer(reasons.ORDER);
  }, [duplicate, closeDrawer, clearLocalActiveOrder]);
  const invoiceNotesUpdate = useCallback((id, notes) => updateInvoice({ id, notes }).catch((error) => setError(error)), [updateInvoice, setError]);
  const orderCreation = useCallback((order) => {
    createOrder({ order: _omit(order, 'email', 'phone', 'name'), side: sides.USER })
    .then(({ payload: { data: { order: { id: order_id, invoice: { id } } } } }) => { 
      !!order.invoice_notes && invoiceNotesUpdate(id, order.invoice_notes);
      handleClose();    
      navigate(`/orders/${order_id}`);
      setSuccess('Order created')
    })
    .catch(e => setError(e))
    .finally(() => setProcessing(false))
  }, [ createOrder, handleClose, invoiceNotesUpdate, setError, setSuccess]); // eslint-disable-line react-hooks/exhaustive-deps
  const orderUpdate = useCallback((order) => {
    if (!_isEmpty(_omit(order, 'id', 'invoice_notes'))) {
      updateOrder(_omit(order, 'email', 'phone', 'name'))
      .then(() => 
        getOrder({ id: uploadedOrder.id, side: sides.USER })
        .then(() => setSuccess('Order updated').then(handleClose))
        .catch(e => setError(e))
        .finally(() => setProcessing(false))
      )
      .catch(e => setError(e).then(() => setProcessing(false)))
    } else {
      setProcessing(false);
      setSuccess('Order updated').then(handleClose);
    }
  }, [uploadedOrder.id, getOrder, updateOrder, handleClose, setError, setSuccess]);
  const processingOrder = useCallback(({ vehicle, customer, notes, service, map }) => {
    const filteredLocalDocuments = _filter(localDocuments, (d) => d.id === d.file?.id || d._destroy);
    setProcessing(true);

    if (edit) {
      const order = {
        ...customer, 
        ...notes, 
        ...service, 
        ...map,
        ...vehicle,
        ...(!!filteredLocalDocuments.length && { documents_attributes: _map(filteredLocalDocuments, ({ id, category, _destroy }) => _destroy ? { id, _destroy } : { category_id: category.id, file: id }) })
      };

      if (_isEmpty(order)) {
        setProcessing(false);
        handleClose();
      } else {
        if (!!customer?.newCustomer) {
          createCustomer({ customer: customer.newCustomer })
          .then(({ payload: { data: { customer: { id: customer_id } } } }) => { 
            if (!_isEmpty(_omit(vehicle, ['keys_availability', 'driver_attendance']))) {
              updateVehicle(vehicle)
              .then(() => { 
                if (!_isEmpty(_omit(notes, ['internal_notes', 'dispatcher_notes']))) {
                  updateInvoice({ id: uploadedOrder.invoice.id, notes: notes.invoice_notes })
                  .then(() => orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER, customer_id }))
                  .catch(e => setError(e))
                } else {
                  orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER, customer_id });
                }
              })
              .catch(e => setError(e).then(() => setProcessing(false)))
            } else {
              if (!_isEmpty(_omit(notes, ['internal_notes', 'dispatcher_notes']))) { 
                updateInvoice({ id: uploadedOrder.invoice.id, notes: notes.invoice_notes })
                .then(() => orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER, customer_id }))
                .catch(e => setError(e))
              } else {
                orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER, customer_id });
              }
            }
          })
          .catch(e => setError(e).then(() => setProcessing(false)))
        } else if (!!!customer?.customer_id && !_isEmpty(_pick(customer, ['id', 'name', 'email', 'phone']))) {
          updateCustomer(_pick(customer, ['id', 'name', 'email', 'phone']))
          .then(() => {
            if (!_isEmpty(_omit(vehicle, ['keys_availability', 'driver_attendance']))) {
              updateVehicle(vehicle)
              .then(() => {
                if (!_isEmpty(_omit(notes, ['internal_notes', 'dispatcher_notes']))) {
                  updateInvoice({ id: uploadedOrder.invoice.id, notes: notes.invoice_notes })
                  .then(() => orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER }))
                  .catch(e => setError(e))
                } else {
                  orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER });
                }
              })
              .catch(e => setError(e).then(() => setProcessing(false)))
            } else {
              if (!_isEmpty(_omit(notes, ['internal_notes', 'dispatcher_notes'])) && !!uploadedOrder.invoice) {
                updateInvoice({ id: uploadedOrder.invoice.id, notes: notes.invoice_notes })
                .then(() => orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER }))
                .catch(e => setError(e))
              } else {
                orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER });
              }
            }
          })
          .catch(e => setError(e).then(() => setProcessing(false)))
        } else if (!!!customer?.newCustomer && !_isEmpty(_omit(vehicle, ['keys_availability', 'driver_attendance']))) {
          updateVehicle(vehicle)
          .then(() => { 
            if (!_isEmpty(_omit(notes, ['internal_notes', 'dispatcher_notes']))) { 
              updateInvoice({ id: uploadedOrder.invoice.id, notes: notes.invoice_notes })
              .then(() => orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER }))
              .catch(e => setError(e))
            } else {
              orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER });
            }
          })
          .catch(e => setError(e).then(() => setProcessing(false)))
        } else {
          if (!_isEmpty(_omit(notes, ['internal_notes', 'dispatcher_notes']))) { 
            updateInvoice({ id: uploadedOrder.invoice.id, notes: notes.invoice_notes })
            .then(() => orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER }))
            .catch(e => setError(e))
          } else {
            orderUpdate({ ...order, id: uploadedOrder.id, side: sides.USER });
          }
        }
      }
    } else {
      createVehicle(_omit(vehicle, ['keys_availability', 'driver_attendance']))
      .then(({ payload: { data: { vehicle: { id: vehicle_id } } } }) => {
        const order = {
          ...customer, 
          ...notes, 
          ...service, 
          ...map,
          ..._pick(vehicle, ['keys_availability', 'driver_attendance']),
          vehicle_id,
          ...(!!localDocuments.length && { documents_attributes: _map(localDocuments, ({ id, category }) => ({ category_id: category.id, file: id })) })
        };
  
        if (!!customer?.newCustomer) {
          createCustomer({ customer: customer.newCustomer })
          .then(({ payload: { data: { customer: { id: customer_id } } } }) => orderCreation({ ...order, customer_id }))
          .catch(e => setError(e).then(() => setProcessing(false)));
        } else {
          orderCreation(order);
        }
      })
      .catch(e => setError(e).then(() => setProcessing(false)))
    }
  }, [localDocuments, edit, uploadedOrder, createVehicle, createCustomer, updateCustomer, updateVehicle, updateInvoice, orderCreation, orderUpdate, handleClose, setError]);

  useEffect(() => {
    if (processingData && !!vehicle && !!customer && !!notes && !!service && !!map ) {
      setProcessingData(false);
      processingOrder({ vehicle, customer, notes, service, map });
    } else if (setProcessingData && !!vehicle && onlyVehicle ) {
      setProcessingData(false);
      processingOrder({ vehicle: vehicle });
    }
  }, [processingData, vehicle, customer, notes, service, map]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = onlyVehicle ? 'Update Vehicle' : edit ? 'UPDATE ORDER' : 'CREATE ORDER'

  return (
    <BaseDrawer
      grey
      open={open}
      onClose={handleClose}
      disabled={processing}
      paperSxProps={{ width: { xs: '100%', lg: '1100px' } }}
      SlideProps={{ onExited: handleExiting }}
      title={title}
      content={
        <> 
          { processing && 
          <Box sx={{ width: '90%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
            <CircularProgress />
          </Box>} 
          <Box
            sx={{
              display: 'flex',
              flexWrap: { xs: 'wrap', lg: 'nowrap' },
              gap: '28px'
            }}
            ref={ref}
          >
            <Box
              sx={{
                flexShrink: 0,
                width: { xs: '100%', lg: onlyVehicle ? 'calc(100% - 14px)' : 'calc(50% - 14px)' },
                display: { xs: 'contents', lg: 'flex'},
                gap: '24px', flexDirection: 'column',
                '& > *': { width: '100% !important' }
              }}
            >
              <VehicleForm 
                sx={{ order: 1 }}
                getVehicleData={(vehicle) => setVehicle(vehicle)}
                onChangeProcessingData={setProcessingData}
                {...{ edit, duplicate, vehicleData, processingData, towVehicleData }}
              />
              { !onlyVehicle && <CustomerForm
                sx={{ order: { xs: 3, lg: 2 } }}
                getCustomerData={(customer) => setCustomer(customer)}
                processingData={processingData}
                onChangeProcessingData={setProcessingData}
                {...{ edit, duplicate, customerData, processingData, towVehicleCustomerData }}
              /> }
              { !onlyVehicle && <DocumentsCard sx={{ order: { xs: 6, lg: 3 } }} {...documentsData} isCreate={!edit} /> }
            </Box>
            { !onlyVehicle && <Box
              sx={{
                flexShrink: 0,
                width: { xs: '100%', lg: 'calc(50% - 14px)' },
                display: { xs: 'contents', lg: 'flex'},
                gap: '24px', flexDirection: 'column',
                '& > *': { width: '100%' }
            }}>
              <ServiceForm 
                sx={{ order: 1 }}
                getServiceData={(service) => setService(service)}
                onChangeProcessingData={setProcessingData}
                {...{ edit, duplicate, serviceData, processingData }}
              />
              <MapForm 
                sx={{ order: {xs: 4, lg: 2} }} 
                getMapData={(map) => setMap(map)}
                onChangeProcessingData={setProcessingData}
                {...{ edit, duplicate, locationsData, processingData, towVehicleLocationData }}
              />
              <NotesCard 
                sx={{ order: {xs: 5, lg: 3} }} 
                getNotesData={(notes) => setNotes(notes)}
                onChangeProcessingData={setProcessingData}
                {...{ isCreate: !edit, edit, duplicate, notesData, processingData, towVehicleNotesData }}
              />
            </Box> }
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pt: {xs: '32px', lg: '56px'} }}>
            <Button disabled={processing} variant='contained' color='primary' onClick={handleSubmitClick}>{title}</Button>
          </Box>
        </>
      }
    />
  );
};

export default memo(Drawer);
import { combineReducers } from 'redux';
import { clearStoreRoutine } from 'actions';
import { sessionReducer as session } from 'redux-react-session';

// Accounts
import accounts from './accounts/accounts';
// Adds
import adds from './adds/adds';
// App
import app from './app/app';
// Blobs
import blobs from './blobs/blobs';
// Categories
import categories from './categories/categories';
// Changes
import changes from './changes/changes';
// Channel Messages
import channelMessages from './channelMessages/channelMessages';
// Charges
import charges from './charges/charges';
// Companies
import companies from './companies/companies';
// Customers
import customers from './customers/customers';
// Dialogs
import dialogs from './dialogs/dialogs';
// Documents
import documents from './documents/documents';
// Error
import error from './error/error';
// Insurance Accounts
import insuranceAccounts from './insuranceAccounts/insuranceAccounts';
// Invoices
import invoices from './invoices/invoices';
// Jobs
import jobs from './jobs/jobs';
// Locations
import locations from './locations/locations';
// Map
import map from './map/map';
// Orders
import orders from './orders/orders';
// Payments
import payments from './payments/payments';
// Profile
import profile from './profile/profile';
// Requests
import requests from './requests/requests';
// Services
import services from './services/services';
// Sessions
import sessions from './sessions/sessions';
// Success
import success from './success/success';
// Users
import users from './users/users';
// Trucks
import trucks from './trucks/trucks';
// Types
import types from './types/types';
// Vehicles
import vehicles from './vehicles/vehicles';

const appReducer = combineReducers({
  accounts,
  adds,
  app,
  blobs,
  categories,
  changes,
  channelMessages,
  charges,
  companies,
  customers,
  dialogs,
  documents,
  error,
  insuranceAccounts,
  invoices,
  jobs,
  locations,
  map,
  orders,
  payments,
  profile,
  requests,
  services,
  session,
  sessions,
  success,
  users,
  trucks,
  types,
  vehicles
});

const rootReducer = (state, action) => {
  if (clearStoreRoutine.isSuccessAction(action)) {
    const { session } = state;

    state = { session };
  }

  return appReducer(state, action);
};

export default rootReducer;
import { memo, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import _pick from 'lodash/pick';
//Local files
import { Card, CardHeader, CardTitle, ItemText } from 'components/Common/BasePageUi/BasePageUi';
import AddressItem from './AddressItem/AddressItem';
import { mapboxAccessToken, mapboxSearchUrl } from 'apis';
import { orderStatuses } from 'helpers/constants';
import useError from 'hooks/useError';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapCard = ({ 
  address = '',
  latitude = 0,
  longitude = 0,
  status = '',
  incident_location = null,
  destination_location = null,
  customer_location = null,
  firstLabel = 'Incident address', 
  secondaryLabel = 'Destination address', 
  thirdLabel = `Driver's current location`,
  hideMap = false, 
  locationProcessing = false,
  isShowDriver = false,
  isShowSendLocation = false,
  onShowDriver = null,
  onChangeLocation = null,
  onApplyLocation = null,
  onRejectLocation = null,
  sx 
}) => {
  const { setError } = useError();
  const [selected, setSelected] = useState('');
  const [moving, setMoving] = useState(false);
  const [viewState, setViewState] = useState({ latitude: 0, longitude: 0, zoom: 0 });
  const [temporaryCoordinates, setTemporaryCoordinates] = useState({ address: '', longitude: 0, latitude: 0 });

  const isShowDriverLocation = !!latitude && !!incident_location
    && status !== orderStatuses.CANCELED
    && status !== orderStatuses.COMPLETED
    && status !== orderStatuses.REJECTED
    && status !== orderStatuses.EXPIRED
    && status !== orderStatuses.STORED;
  const showApplyOrRejectLocation = !!customer_location && customer_location?.address !== incident_location?.address;
  
  const handleClick = useCallback(({ reason, newViewState }) => {
    if (reason !== selected || viewState.latitude !== newViewState.latitude || viewState.longitude !== newViewState.longitude) {
      setSelected(reason);
      setViewState(prev => ({ ...prev, ...newViewState }));
    }
  }, [selected, viewState]);
  const handleMoveStart = () => setMoving(true);
  const handleMoveEnd = e => {
    setMoving(false);
    if (e.viewState.zoom === 0) {
      e.target.fitBounds([
          [incident_location?.longitude || longitude, incident_location?.latitude || latitude], 
          [destination_location?.longitude || incident_location?.longitude || longitude, destination_location?.latitude || incident_location?.latitude || latitude]
        ], 
        { maxZoom: 14, padding: { top: 54, bottom: 24, left: 24, right: 24 } }
      ) 
    } else {
      setViewState(_pick(e.viewState, ['latitude', 'longitude', 'zoom']));
    }   
  };
  const handleChangeViewState = (reason, latitude, longitude) => {
    !hideMap && handleClick({ reason, newViewState: { latitude, longitude, zoom: 14 } });
    !!onShowDriver && onShowDriver(false);
  };
  const handleChangeLocation = () => {
    const fetchLocation= async (q) => await mapboxSearchUrl.get(`/${q}.json?access_token=${mapboxAccessToken}&autocomplete=true&country=US&language=en`);

    window.navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        fetchLocation(`${coords?.longitude},${coords?.latitude}`)
        .then(({ data: { features } }) => 
          onChangeLocation({ 
            address: features[0]?.place_name, 
            longitude: features[0]?.geometry?.coordinates[0],
            latitude: features[0]?.geometry?.coordinates[1] 
          })
        )
      }, (_) => setError('Please turnon your location and give permissions in browser'))
  };

  useEffect(() => {
    isShowDriver && !hideMap && !!latitude && handleClick({ reason: 'current_location', newViewState: { latitude, longitude, zoom: 14 } });
  }, [isShowDriver, hideMap, latitude, longitude, handleClick]);
  useEffect(() => {
    const fetchOptions = async (q) => await mapboxSearchUrl.get(`/${q}.json?access_token=${mapboxAccessToken}&autocomplete=true&country=US&language=en`);

    if (!!latitude && !!!incident_location) {
      fetchOptions(`${longitude},${latitude}`)
      .then(({ data }) => setTemporaryCoordinates(prev => ({...prev, address: data.features[0]?.place_name })))
    }
    if (!!latitude && !!address) {
      fetchOptions(`${longitude},${latitude}`)
      .then(({ data: addressData }) => 
        fetchOptions(address)
        .then(({ data: coordinatesData  }) => 
          setTemporaryCoordinates({ 
            address: addressData.features[0]?.place_name, 
            longitude: coordinatesData.features[0]?.geometry?.coordinates[0],
            latitude: coordinatesData.features[0]?.geometry?.coordinates[1]
          })
        )
      )
    }
    if (!!!viewState.latitude) {
      setMoving(true);
      setViewState(({ latitude: incident_location?.latitude || latitude, longitude: incident_location?.longitude || longitude, zoom: 0 }));
    }
  }, [viewState.latitude, latitude, longitude, address, incident_location]);

  return (
    <Card sx={{ minHeight: !hideMap && '440px', alignSelf: 'normal', gap: 0, padding: 0, ...sx }}>
      <CardHeader sx={{ padding: '24px 16px 8px', justifyContent: 'space-between' }}>
        <CardTitle>Location</CardTitle>
        {!!onChangeLocation && <Button disabled={locationProcessing} onClick={handleChangeLocation}>{`${isShowSendLocation ? 'FIND MY' : 'UPDATE'} LOCATION`}</Button>}
      </CardHeader>
      { showApplyOrRejectLocation && 
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', ml: 2, mr: 1 }}>
        <ItemText sx={{ textTransform: 'uppercase' }}>Client Suggested Location</ItemText>
        <Box>
          <Button disabled={locationProcessing} onClick={onApplyLocation}>Apply</Button>
          <Button color='error' disabled={locationProcessing} onClick={onRejectLocation}>Reject</Button>
        </Box>
      </Box>}
      <AddressItem 
        hideMap={hideMap}
        selected={selected === 'incident'}
        label={firstLabel}
        iconColor='primary'
        address={incident_location?.address || temporaryCoordinates.address || '-'} 
        navigationCoordinates={`${(!!incident_location || !!latitude) ? `${incident_location?.latitude || latitude},${incident_location?.longitude || longitude}` : temporaryCoordinates.address}`} 
        onChange={() => handleChangeViewState('incident', incident_location?.latitude || latitude, incident_location?.longitude || longitude)}
      />
      { (!!destination_location || !!address) && 
      <AddressItem 
        hideMap={hideMap}
        selected={selected === 'destination'}
        label={secondaryLabel}
        iconColor='secondary'
        address={destination_location?.address || address} 
        navigationCoordinates={`${(!!destination_location || !!temporaryCoordinates.latitude) ? `${destination_location?.latitude || temporaryCoordinates.latitude},${destination_location?.longitude || temporaryCoordinates.longitude}` : (temporaryCoordinates.address || address)}`} 
        onChange={() => handleChangeViewState('destination', destination_location?.latitude || temporaryCoordinates.latitude, destination_location?.longitude || temporaryCoordinates.longitude)}
      /> }
      { isShowDriverLocation && 
      <AddressItem 
        hideMap={hideMap}
        selected={selected === 'current_location'}
        label={thirdLabel}
        iconColor='action'
        address={temporaryCoordinates.address} 
        navigationCoordinates={`${latitude},${longitude}`} 
        onChange={() => handleChangeViewState('current_location', latitude, longitude)}
      /> }
      {!hideMap && (
        <Box sx={{ flexGrow: 1, borderRadius: '0 0 8px 8px', overflow: 'hidden' }} >
          <Box sx={{ width: '100%', height: '100%', background: '#badbad'}}>
            <Map
              mapboxAccessToken={mapboxAccessToken}
              viewState={moving ? null : viewState}
              onMoveStart={handleMoveStart}
              onMoveEnd={handleMoveEnd}
              mapStyle="mapbox://styles/mapbox/streets-v9"
            >
              {(!!incident_location || !!latitude) && 
              <Marker
                color='#2418D3'
                latitude={incident_location?.latitude || latitude}
                longitude={incident_location?.longitude || longitude}
              />}
              {(!!destination_location || !!temporaryCoordinates.latitude) && 
              <Marker
                color='#FA004A'
                latitude={destination_location?.latitude || temporaryCoordinates.latitude}
                longitude={destination_location?.longitude || temporaryCoordinates.longitude}
              />}
              {isShowDriverLocation &&
              <Marker
                color='#E4E4FD'
                latitude={latitude}
                longitude={longitude}
              />}
              <NavigationControl />
            </Map>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default memo(MapCard);
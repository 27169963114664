import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 84,
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 0,
  padding: '8px 10px 8px 2px',
  [theme.breakpoints.up('md')]: {
    width: 'calc(100% - 300px - 16px - 16px - 340px)',//list, margin, DriverInfo
    top: 16,
    right: 340 + 16,//DriverInfo width + margin
    left: 'unset',
    borderRadius: '8px',
  }
}));
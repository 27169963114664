import { memo } from 'react';
import { Marker } from 'react-map-gl';
import PropTypes from 'prop-types';
// Local files
import { Root, Wrapper } from './RouteMarker.styled';
import { ReactComponent as DirectionIcon } from 'assets/icons/destination-location.svg';
import { ReactComponent as IncidentIcon } from 'assets/icons/incident-location.svg';

const RouteMarker = ({ latitude, longitude, end = false }) => {
  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
    >
      <Root>
        <Wrapper>
          {!end ? <IncidentIcon /> : <DirectionIcon />}
        </Wrapper>
      </Root>
    </Marker>
  );
};

RouteMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  end: PropTypes.bool
};

export default memo(RouteMarker);
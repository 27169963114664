import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getListInsuranceAccountsRoutine, 
  clearLocalListInsuranceAccountsRoutine
} from 'actions';

const shortAttributes = ['id', 'name'];

export const getListInsuranceAccounts = getThunkActionCreator(
  getListInsuranceAccountsRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' } }) => {
    getListInsuranceAccounts.getListInsuranceAccountsCancel && getListInsuranceAccounts.getListInsuranceAccountsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/insurance_accounts`,
      {
        params: { access_token, limit: 25, offset, orders, attributes: shortAttributes },
        cancelToken: new CancelToken(function executor(cancel) { getListInsuranceAccounts.getListInsuranceAccountsCancel = cancel })
      }
    );
  }
);
export const clearLocalListInsuranceAccounts = getThunkActionCreator(clearLocalListInsuranceAccountsRoutine, async () => {});
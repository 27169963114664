import { memo } from 'react';
import HideSourceIcon from '@mui/icons-material/HideSource';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const hideAppBar = localStorage.getItem('hide_app_bar') === 'true';

  return (
    <Box
      sx={{
        height: hideAppBar ? '100%' : 'calc(100% - 64px)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
        bgcolor: 'background.paper',
        p: 3,
      }}
    >
      <HideSourceIcon
        sx={{
          width: '100%',
          maxWidth: '260px',
          height: 'auto',
          color: 'background.selected',
        }}
      />
      <Typography
        variant='h4'
        variantMapping={{ h4: 'h1'}}
        sx={{
          mt: 6,
          mb: 2,
          fontWeight: 700
        }}
      >
        Page not found
      </Typography>
      <Box
        sx={{
          width: '100%',
          maxWidth: '300px',
          mx: 'auto',
        }}
      >
        <Button
            component={Link}
            to='/'
            variant='outlined'
            color='primary'
            size='xlarge'
            fullWidth
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

export default memo(NotFound);
import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Root } from './TimeItem.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const TimeItem = ({ label, value, selected = false, disabled, onClick }) => {
  return (
    <Root selected={selected} onClick={() => onClick(value)} disabled={disabled}>
      {label}
    </Root>
  );
};

TimeItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <TimeItem {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
import {
  getListCategoriesRoutine,
  getCategoriesRoutine,
  getCategoryRoutine,
  createCategoryRoutine,
  clearLocalListCategoriesRoutine,
  clearLocalCategoriesRoutine,
  clearLocalActiveCategoryRoutine,
  getDocumentsRoutine,
  updateOrderRoutine,
  createDocumentsRoutine,
  deleteDocumentRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  category: { id: '', name: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListCategoriesRoutine.SUCCESS: {
      const { data: { categories, pagination } } = action.payload;

      return { ...state, list: { data: _uniqBy([...state.list.data, ...categories], 'id'), pagination } };
    }
    case getCategoriesRoutine.SUCCESS: {
      const { data: { categories: data, pagination } } = action.payload;

      return { ...state, all: { data, pagination } };
    }
    case getCategoryRoutine.SUCCESS: {
      const { data: { category } } = action.payload;
      const updatedCategory = { ...state.category, ...category };
      const data = _map(state.all.data, (item) => item.id === category.id ? { ...item, ...category } : item)

      return { ...state, category: updatedCategory, all: { ...state.all, data } };
    }
    case createCategoryRoutine.SUCCESS: {
      const { data: { category } } = action.payload;
      const oldList = state.list.pagination.total_count > 25 ? _dropRight(state.list.data) : state.list.data;
      const dataList = [...[category], ...oldList];
      const oldAll = state.all.pagination.total_count > 25 ? _dropRight(state.all.data) : state.all.data;
      const dataAll = [...[category], ...oldAll];
      const paginationList = { ...state.list.pagination, count: state.list.pagination.count + 1, total_count: state.list.pagination.total_count + 1 };
      const paginationAll = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, list: { data: dataList, pagination: paginationList }, all: { data: dataAll, pagination: paginationAll } };
    }
    case clearLocalListCategoriesRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalCategoriesRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveCategoryRoutine.SUCCESS: {
      return { ...state, category: initialState.category }
    }
    case getDocumentsRoutine.SUCCESS: {
      const { data: { documents } } = action.payload;
      const filteredData = _uniqBy(_filter(_map(documents, ({ category }) => category), ({ id }) => !!!_find(state.all.data, (c) => id === c.id)), 'id');

      return { ...state, ...(!!filteredData.length && { 
        all: { 
          data: [...state.all.data, ...filteredData], 
          pagination: { ...state.all.pagination, count: state.all.pagination.count + filteredData.length, total_count: state.all.pagination.total_count + filteredData.length } 
        } 
      } )};
    }
    case updateOrderRoutine.SUCCESS: {
      const { response: { status }, order } = action.payload;
      const destroyedDocument = _find(order?.localDocuments, (d) => d?._destroy);
      const filteredData = _filter(order?.localDocuments, (d) => !d?._destroy && d?.category?.id === destroyedDocument?.category?.id);

      return { ...state, ...(status === 204 && !!destroyedDocument && filteredData <= 1 && { 
        all: { 
          ...state.all, 
          data: _filter(state.all.data, ({ id }) => id !== destroyedDocument.category?.id),
          pagination: { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 }
        } 
      } )};
    }
    case createDocumentsRoutine.SUCCESS: {
      const { response: { status }, newDocuments } = action.payload;
      const filteredData = _uniqBy(_filter(_map(newDocuments, ({ category }) => category), ({ id }) => !!!_find(state.all.data, (c) => id === c.id)), 'id');

      return { ...state, ...(status === 204 && !!filteredData.length && { 
        all: { 
          data: [...state.all.data, ...filteredData], 
          pagination: { ...state.all.pagination, count: state.all.pagination.count + filteredData.length, total_count: state.all.pagination.total_count + filteredData.length } 
        } 
      } )};
    }
    case deleteDocumentRoutine.SUCCESS: {
      const { response: { status }, documents, id } = action.payload;
      const destroyedDocument = _find(documents, (d) => d?.id === id);
      const filteredData = _filter(documents, (d) => d?.id !== id && d?.category?.id === destroyedDocument?.category?.id);

      return { ...state, ...(status === 204 && filteredData <= 1 && { 
        all: { 
          ...state.all, 
          data: _filter(state.all.data, ({ id }) => id !== destroyedDocument.category?.id),
          pagination: { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 }
        } 
      } )};
    }
    default: {
      return state;
    }
  };
};

export default reducer;
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getListTrucksRoutine,
  getTrucksRoutine,
  getTruckRoutine,
  createTruckRoutine,
  updateTruckRoutine,
  deleteTruckRoutine,
  clearLocalListTrucksRoutine,
  clearLocalTrucksRoutine,
  clearLocalActiveTruckRoutine
} from 'actions';
import _omit from 'lodash/omit';

const listAttributes = ['id', 'make', 'model', 'year'];
const attributes = [
  'id',
  'nickname',
  'make',
  'model',
  'year',
  'vin',
  'license_plate',
  'notes',
  'created_at',
  'urgently_vehicle_type',
  { users: ['id', 'first_name', 'last_name'] },
  { current_order: ['id', 'services', 'number', 'status', { company_location: [{ company: ['id'] }] }] },
  { last_orders: ['id', 'number', 'created_at', { company_location: [{ company: ['id', 'time_zone'] }] }] },
  { current_user: ['id', 'first_name', 'last_name'] },
  { type: ['id', 'name'] }
];

export const getListTrucks = getThunkActionCreator(
  getListTrucksRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' } }) => {
    getListTrucks.getListTrucksCancel && getListTrucks.getListTrucksCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/trucks`,
      {
        params: { access_token, limit: 25, offset, orders, attributes: listAttributes },
        cancelToken: new CancelToken(function executor(cancel) { getListTrucks.getListTrucksCancel = cancel })
      }
    );
  }
);
export const getTrucks = getThunkActionCreator(
  getTrucksRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, query = null, company_ids = [] }) => {
    getTrucks.getTrucksCancel && getTrucks.getTrucksCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/trucks`, {
      params: { access_token, limit, offset, orders, ...(!!query && { query }), ...(!!company_ids.length && { company_ids }), attributes },
      cancelToken: new CancelToken(function executor(cancel) { getTrucks.getTrucksCancel = cancel })
    });
  }
);
export const getTruck = getThunkActionCreator(
  getTruckRoutine,
  async (id) => {
    getTruck.getTruckCancel && getTruck.getTruckCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/trucks/${id}`, {
      params: { access_token, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getTruck.getTruckCancel = cancel })
    });
  }
);
export const createTruck = getThunkActionCreator(
  createTruckRoutine,
  async ({ truck, company_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/companies/${company_id}/trucks`, { access_token, truck, attributes });
  }
);
export const updateTruck = getThunkActionCreator(
  updateTruckRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/trucks/${id}`, { access_token, truck: _omit(fields, ['localUsers', 'current_user']) });

    return { response, truck: { id, ..._omit(fields, ['user_ids']) } };
  }
);
export const deleteTruck = getThunkActionCreator(
  deleteTruckRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/trucks/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalListTrucks = getThunkActionCreator(clearLocalListTrucksRoutine, async () => {});
export const clearLocalTrucks = getThunkActionCreator(clearLocalTrucksRoutine, async () => {});
export const clearLocalActiveTruck = getThunkActionCreator(clearLocalActiveTruckRoutine, async () => {});
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Root } from './BaseScrollableContainer.styled';

const BaseScrollableContainer = ({ sx, children, total_count, count, limit, offset, onLoad }) => {
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    if (scrollTop + clientHeight + 65 >= scrollHeight && total_count > count + offset) {
      onLoad(limit + offset);
    }
  };

  return (
    <Root sx={sx} onScroll={handleScroll}>
      {children}
    </Root>
  );
};

BaseScrollableContainer.propTypes = {
  children: PropTypes.node.isRequired,
  total_count: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired
};

export default memo(BaseScrollableContainer);
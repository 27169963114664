import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useCategories from 'hooks/useCategories';
import { getRandomString } from 'helpers';

const filter = createFilterOptions();

const CategoriesAutocomplete = ({ value, category_type, onChange }) => {
  const { getListCategories, clearLocalListCategories, createCategory } = useCategories();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.categories.list);
  const [loading, setLoading] = useState(false);

  const handleChange = (_, newValue) => { 
    if (!!newValue?.inputValue) {
      createCategory({ name: newValue?.inputValue, category_type })
      .then(({ payload: { data: { category } } }) => onChange(category))
      .catch((error) => setError(error))
    } else {
      onChange(newValue);
    }
  };
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option.name);
    if (inputValue !== '' && !isExisting) filtered.push({ id: getRandomString(24), inputValue, name: `Add "${inputValue}"` });

    return filtered;
  };
  const getOptionLabel = option => {
    if (typeof option === 'string') return option;
    if (option.inputValue) return option.inputValue;
    return option.name;
  };
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchCategories = useCallback((offset, category_type) => {
    setLoading(true);
    getListCategories({ offset, category_type })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListCategories, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchCategories(limit + offset, category_type);
    }
  };

  useEffect(() => {
    clearLocalListCategories().then(() => fetchCategories(0, category_type));
  }, [category_type, fetchCategories, clearLocalListCategories]);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      filterOptions={filterOptions}
      id="categoryList"
      options={data}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      fullWidth
      freeSolo
      renderInput={params => <TextField {...params} margin='dense' label='Select a category' />}
    />
  );
};

export default memo(CategoriesAutocomplete);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { getListTypesRoutine, clearLocalListTypesRoutine } from 'actions';

const listAttributes = ['id', 'name', 'created_at'];

export const getListTypes = getThunkActionCreator(
  getListTypesRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, query = null }) => {
    getListTypes.getListTypesCancel && getListTypes.getListTypesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/types`,
      {
        params: { access_token, limit: 25, offset, orders, ...(query && { query }), attributes: listAttributes },
        cancelToken: new CancelToken(function executor(cancel) { getListTypes.getListTypesCancel = cancel })
      }
    );
  }
);
export const clearLocalListTypes = getThunkActionCreator(clearLocalListTypesRoutine, async () => {});
import { memo } from 'react';
// Local files
import DutyForm from 'components/DutyStatus/Form/Form';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const DutyStatus = () => {
  return <DutyForm />;
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <DutyStatus {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
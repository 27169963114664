import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getDriversList as getDriversListAction,
  getManagersList as getManagersListAction,
  getUsers as getUsersAction,
  getUsersStatistics as getUsersStatisticsAction,
  getUser as getUserAction,
  signUpUser as signUpUserAction,
  createUser as createUserAction,
  updateUser as updateUserAction,
  deleteUser as deleteUserAction,
  changeUserPassword as changeUserPasswordAction,
  clearLocalDriversList as clearLocalDriversListAction,
  clearLocalManagersList as clearLocalManagersListAction,
  clearLocalUsers as clearLocalUsersAction,
  clearLocalUsersStatistics as clearLocalUsersStatisticsAction,
  clearLocalActiveUser as clearLocalActiveUserAction,
  startUserDuty as startUserDutyAction,
  stopUserDuty as stopUserDutyAction
} from 'actions/users';

const useUsers = () => {
  const dispatch = useDispatch();
  const getDriversList = useCallback(({ offset, query, company_id }) => dispatch(getDriversListAction({ offset, query, company_id })), [dispatch]);
  const getManagersList = useCallback(({ offset, query }) => dispatch(getManagersListAction({ offset, query })), [dispatch]);
  const getUsers = useCallback(({ offset, users, query, roles }) => dispatch(getUsersAction({ offset, users, query, roles })), [dispatch]);
  const getUsersStatistics = useCallback(({ offset, company_id, start_at, end_at, user_ids }) => dispatch(getUsersStatisticsAction({ offset, company_id, start_at, end_at, user_ids })), [dispatch]);
  const getUser = useCallback(id => dispatch(getUserAction(id)), [dispatch]);
  const signUpUser = useCallback(user => dispatch(signUpUserAction(user)), [dispatch]);
  const createUser = useCallback(user => dispatch(createUserAction(user)), [dispatch]);
  const updateUser = useCallback(user => dispatch(updateUserAction(user)), [dispatch]);
  const deleteUser = useCallback(id => dispatch(deleteUserAction(id)), [dispatch]);
  const changeUserPassword = useCallback(({ email, password, confirmation_code }) => dispatch(changeUserPasswordAction({ email, password, confirmation_code })), [dispatch]);
  const clearLocalDriversList = useCallback(() => dispatch(clearLocalDriversListAction()), [dispatch]);
  const clearLocalManagersList = useCallback(() => dispatch(clearLocalManagersListAction()), [dispatch]);
  const clearLocalUsers = useCallback(() => dispatch(clearLocalUsersAction()), [dispatch]);
  const clearLocalUsersStatistics = useCallback(() => dispatch(clearLocalUsersStatisticsAction()), [dispatch]);
  const clearLocalActiveUser = useCallback(() => dispatch(clearLocalActiveUserAction()), [dispatch]);
  const startUserDuty = useCallback(id => dispatch(startUserDutyAction(id)), [dispatch]);
  const stopUserDuty = useCallback(id => dispatch(stopUserDutyAction(id)), [dispatch]);

  return {
    getDriversList,
    getManagersList,
    getUsers,
    getUsersStatistics,
    getUser,
    signUpUser,
    createUser,
    updateUser,
    deleteUser,
    changeUserPassword,
    clearLocalDriversList,
    clearLocalManagersList,
    clearLocalUsers,
    clearLocalUsersStatistics,
    clearLocalActiveUser,
    startUserDuty,
    stopUserDuty
  };
};

export default useUsers;
import Box from '@mui/material/Box';

const TabPanel = ({ children, value, index, ...otherProps }) => {
  return ( 
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{ minHeight: 130 }}
      {...otherProps}
    >
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
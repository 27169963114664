import { memo } from 'react';
import { Marker } from 'react-map-gl';
import OrderIcon from '@mui/icons-material/CarCrash';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import { StyledPoint } from './Clusters.styled';
import InnerMarker from 'components/Map/Components/InnerMarker/InnerMarker';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Clusters = ({ clusters, points, activePoint, duration, bearing, onClusterClick, onMarkerClick }) => {
  return (
    <>
    {_map(clusters, c => {
    const [longitude, latitude] = c.geometry.coordinates;
    const { cluster, point_count: pointCount, id, category, data } = c.properties;

    if (cluster) {
      return (
        <Marker
          key={`cluster-${c.id}`}
          latitude={latitude}
          longitude={longitude}
        >
          <StyledPoint
            count={pointCount}
            points={points}
            onClick={() => onClusterClick({ id: c.id, latitude, longitude })}
            style={{ width: '50px', height: '50px', fontSize: '18px'}}
          >
            {pointCount}
          </StyledPoint>
        </Marker>
      );
    }

      return category !== 'driver' && (
        <Marker
          key={id}
          latitude={latitude}
          longitude={longitude}
          onClick={(e) => onMarkerClick(e, id, latitude, longitude)}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <InnerMarker>
            <OrderIcon />
          </InnerMarker>
        </Marker>
      );
      })}

      {_map(points.filter(point => point.properties.category === 'driver'), driver => {
        const [longitude, latitude] = driver.geometry.coordinates;
        const { id, data } = driver.properties;
        const info = data.current_order
          ? (
            data.current_order.status === 'en_route'
              ? (duration ? `ETA ${Math.round(duration)} min until Pick Up` : `On the way to order #${data.current_order.number}`)
              : `Servicing order #${data.current_order.number}`
          )
          : null;

    return (
      <Marker
        key={`driver-${id}`}
        latitude={latitude}
        longitude={longitude}
        rotation={(data.heading ?? 0) - bearing}
        onClick={(e) => onMarkerClick(e, id, latitude, longitude)}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 10 }}
      >
        <InnerMarker
          driver={true}
          available={!data.current_order && !!data.duty_duration}
          angle={data.heading ?? 0}
          bearing={bearing}
          //offline
          selected={activePoint?.id === id}
          info={info}
        >
          <span>{data?.first_name[0] ?? '-'}{data?.last_name[0] ?? '-'}</span>
        </InnerMarker>
      </Marker>
    );
      })}
    </>
  );
};

Clusters.propTypes = {
  clusters: PropTypes.array.isRequired,
  points: PropTypes.array.isRequired,
  activePoint: PropTypes.shape({
    category: PropTypes.string
  }),
  onClusterClick: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Clusters {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
import { memo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { parse } from 'iso8601-duration';
//Local files
import { Root, Item } from './Details.styled';
import RatesTable from 'components/Rates/Table/Table';
import { mileRoundingTypes, timeRoundingTypes, chargeTypes } from 'helpers/constants';
import useVariants from 'hooks/useVariants';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';

const Details = () => {
  const { updateVariant } = useVariants();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { activeVariant } = useCustomSelector(state => state.app.accountServiceData);
  const [enRouteFreeMiles, setEnRouteFreeMiles] = useState({ value: '', error: '' });
  const [towedFreeMiles, setTowedFreeMiles] = useState({ value: '', error: '' });
  const [deadheadFreeMiles, setDeadheadFreeMiles] = useState({ value: '', error: '' });
  const [minimumAmount, setMinimumAmount] = useState({ value: '', error: '' });
  const [minimumDuration, setMinimumDuration] = useState({ hours: { value: '', error: '' }, minutes: { value: '', error: '' } });
  const [mileRoundingType, setMileRoundingType] = useState({ value: '', error: '' });
  const [timeRoundingType, setTimeRoundingType] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);

  const basicRates = _filter(activeVariant?.rates, ({ charge }) => charge?.charge_type === chargeTypes.BASIC);
  const feeRates = _filter(activeVariant?.rates, ({ charge }) => charge?.charge_type === chargeTypes.FEE);
  const isPerTimeRates = _find(activeVariant?.rates, ({ basic_value_type }) => basic_value_type === 'per_hour');

  useEffect(() => {
    if (!!activeVariant) {
      setEnRouteFreeMiles({ value: activeVariant?.en_route_free_miles, error: '' });
      setTowedFreeMiles({ value: activeVariant?.towed_free_miles, error: '' });
      setDeadheadFreeMiles({ value: activeVariant?.deadhead_free_miles, error: '' });
      setMinimumAmount({ value: activeVariant?.minimum_amount, error: '' });
      !!activeVariant.minimum_duration && setMinimumDuration({ 
        hours: { value: parse(activeVariant.minimum_duration)?.hours, error: ''},
        minutes: { value: parse(activeVariant.minimum_duration)?.minutes, error: '' }
      });
      setMileRoundingType({ value: activeVariant?.mile_rounding_type, error: '' });
      setTimeRoundingType({ value: activeVariant?.time_rounding_type, error: '' });
    }
  }, [activeVariant]);

  const handleVariantUpdate = () => {
    let hasError = false;

    if (!!!enRouteFreeMiles.value && enRouteFreeMiles.value !== 0) {
      setEnRouteFreeMiles(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (!!!towedFreeMiles.value && towedFreeMiles.value !== 0) {
      setTowedFreeMiles(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (!!!deadheadFreeMiles.value && deadheadFreeMiles.value !== 0) {
      setDeadheadFreeMiles(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (!!!minimumAmount.value && minimumAmount.value !== 0) {
      setMinimumAmount(prev => ({ ...prev, error: `Amount can't be empty` }));
      hasError = true;
    }
    if (!!minimumDuration.minutes.value && minimumDuration.minutes.value > 59) {
      setMinimumDuration(prev => ({ ...prev, minutes: { ...prev.minutes, error: `Can't be more the 59` } }));
      hasError = true;
    }
    if (!!!mileRoundingType.value) {
      setMileRoundingType(prev => ({ ...prev, error: `Type can't be empty` }));
      hasError = true;
    }
    if (!!!timeRoundingType.value) {
      setTimeRoundingType(prev => ({ ...prev, error: `Type can't be empty` }));
      hasError = true;
    }
    if (hasError) return;

    const processingVariant = {
      id: activeVariant?.id,
      en_route_free_miles: parseInt(enRouteFreeMiles.value), 
      towed_free_miles: parseInt(towedFreeMiles.value), 
      deadhead_free_miles: parseInt(deadheadFreeMiles.value), 
      minimum_amount: parseFloat(typeof minimumAmount.value === 'string' ? minimumAmount.value.replace(',', '.') : minimumAmount.value), 
      minimum_duration: (!!minimumDuration.hours.value || !!minimumDuration.minutes.value) ? 
        `PT${minimumDuration.hours.value}${!!minimumDuration.hours.value ? 'H' : ''}${minimumDuration.minutes.value}${!!minimumDuration.minutes.value ? 'M' : ''}` : null,
      mile_rounding_type: mileRoundingType.value,
      time_rounding_type: timeRoundingType.value
    };

    setProcessing(true);
    if (needToUpdate(activeVariant, processingVariant)) {
      updateVariant(processingVariant)
      .then(() => setSuccess('Service successfully updated'))
      .catch((error) => setError(error))
      .finally(() => setProcessing(false));
    } else {
      setProcessing(false);
    }
  };
  const needToUpdate = (a,b) => 
    a.en_route_free_miles !== b.en_route_free_miles || 
    a.towed_free_miles !== b.towed_free_miles || 
    a.deadhead_free_miles !== b.deadhead_free_miles || 
    a.minimum_amount !== b.minimum_amount || 
    a.minimum_duration !== b.minimum_duration || 
    a.mile_rounding_type !== b.mile_rounding_type ||
    a.time_rounding_type !== b.time_rounding_type;

  return (
    <>
      <Root>
        <Typography variant='title' sx={{ pt: 3 }}>Rates</Typography>
        <RatesTable type={chargeTypes.BASIC} rates={basicRates} />
        <Typography variant='title' sx={{ pt: 4 }}>Additional Info</Typography>
        <Item>
          <Typography variant='body2'>Free Miles</Typography>
          <Box sx={{ width: '40%' }}>
            <TextField
              sx={{ width: '30%' }}
              size='small'
              variant='standard'
              label='En Route'
              value={enRouteFreeMiles.value}
              helperText={enRouteFreeMiles.error}
              error={!!enRouteFreeMiles.error}
              onChange={({ target: { value } }) => setEnRouteFreeMiles({ value, error: '' })}
              onBlur={handleVariantUpdate}
              disabled={processing}
            />
            <TextField
              sx={{ width: '30%', ml: 1 }}
              size='small'
              variant='standard'
              label='Towed'
              value={towedFreeMiles.value}
              helperText={towedFreeMiles.error}
              error={!!towedFreeMiles.error}
              onChange={({ target: { value } }) => setTowedFreeMiles({ value, error: '' })}
              onBlur={handleVariantUpdate}
              disabled={processing}
            />
            <TextField
              sx={{ width: '30%', ml: 1 }}
              size='small'
              variant='standard'
              label='Deadhead'
              value={deadheadFreeMiles.value}
              helperText={deadheadFreeMiles.error}
              error={!!deadheadFreeMiles.error}
              onChange={({ target: { value } }) => setDeadheadFreeMiles({ value, error: '' })}
              onBlur={handleVariantUpdate}
              disabled={processing}
            />
          </Box>
        </Item>
        { isPerTimeRates && <Item>
          <Typography variant='body2'>Minimum Time</Typography>
          <Box sx={{ width: '40%' }}>
            <TextField
              sx={{ width: '30%' }}
              size='small'
              variant='standard'
              label='Hours'
              InputProps={{ type: 'number', inputProps: { min: 0, max: 59 } }}
              value={minimumDuration.hours.value}
              helperText={minimumDuration.hours.error}
              error={!!minimumDuration.hours.error}
              onChange={({ target: { value } }) => setMinimumDuration(prev => ({...prev, hours: { value: parseInt(Math.abs(value)), error: '' } }))}
              onBlur={handleVariantUpdate}
              disabled={processing}
            />
            <TextField
              sx={{ width: '30%', ml: 1 }}
              size='small'
              variant='standard'
              label='Minutes'
              InputProps={{ type: 'number', inputProps: { min: 0, max: 59 } }}
              value={minimumDuration.minutes.value}
              onChange={({ target: { value } }) => setMinimumDuration(prev => ({...prev, minutes: { value: parseInt(Math.abs(value)), error: '' } }))}
              helperText={minimumDuration.minutes.error}
              error={!!minimumDuration.minutes.error}
              onBlur={handleVariantUpdate}
              disabled={processing}
            />
          </Box>
        </Item> }
        <Item>
          <Typography variant='body2'>Minimum Amount</Typography>
          <TextField
            sx={{ width: '140px' }}
            size='small'
            variant='standard'
            placeholder='Value'
            InputProps={{ startAdornment: <Box component='span' sx={{pb: '5px', pr: '1px', userSelect: 'none'}}>$</Box> }}
            value={minimumAmount.value}
            helperText={minimumAmount.error}
            error={!!minimumAmount.error}
            onChange={({ target: { value } }) => setMinimumAmount({ value, error: '' })}
            onBlur={handleVariantUpdate}
            disabled={processing}
          />
        </Item>
        <Item>
          <Typography variant='body2'>Round Up Miles</Typography>
          <TextField
            sx={{ width: '140px' }}
            select
            size='small'
            variant='standard'
            placeholder='Value'
            value={mileRoundingType.value}
            helperText={mileRoundingType.error}
            error={!!mileRoundingType.error}
            onChange={({ target: { value } }) => setMileRoundingType({ value, error: '' })}
            onBlur={handleVariantUpdate}
            disabled={processing}
          >
            {_map(mileRoundingTypes, ({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
        </Item>
        { isPerTimeRates && <Item>
          <Typography variant='body2'>Round Up Time</Typography>
          <TextField
            sx={{ width: '140px' }}
            select
            size='small'
            variant='standard'
            placeholder='Value'
            value={timeRoundingType.value}
            helperText={timeRoundingType.error}
            error={!!timeRoundingType.error}
            onChange={({ target: { value } }) => setTimeRoundingType({ value, error: '' })}
            onBlur={handleVariantUpdate}
            disabled={processing}
          >
            {_map(timeRoundingTypes, ({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
        </Item> }
        <Typography variant='title' sx={{ pt: 4 }}>Fees</Typography>
        <RatesTable type={chargeTypes.FEE} rates={feeRates} />
      </Root>
    </>
  );
};

export default memo(Details);
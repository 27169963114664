import { memo } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
//Local files
import { Root, Icon, Label } from './Main.styled';

const Main = ({ selected, icon, label, onClick }) => {
  return (
    <Root
      component={ButtonBase}
      onClick={onClick}
      sx={{
        color: selected ? 'primary.main' : '#000',
        boxShadow: selected ? '0px 0px 0px 3px #08008F' : '0px 0px 0px 1px #C4C4C4, 0px 3px 7px 0px #0000001F',
      }}
    >
      <Icon>{icon}</Icon>
      <Label>{label}</Label>
    </Root>
  );
}

export default memo(Main);
import { memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Typography from '@mui/material/Typography';
// Local files
import { Root } from './ComponentErrorWrapper.style';

const ErrorFallback = ({ error }) => {
  return (
    <Root role='alert'>
      <Typography variant='subtitle1' sx={{ mb: 1 }} color='error'>Something went wrong:</Typography>
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Please reload page. If it doesn't help contact our support.</Typography>
      <Typography variant='body2' color='textSecondary'>{error.message}</Typography>
    </Root>
  );
};

const ComponentErrorWrapper = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};

export default memo(ComponentErrorWrapper);
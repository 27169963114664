import { memo } from 'react';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText } from 'components/Common/BasePageUi/BasePageUi';

const ReleasedCard = ({ name, address, phone, email, driver_license, license_state, sx  }) => {

  return (
    <Card sx={sx}>
      <CardHeader>
        <CardTitle>Release Information</CardTitle>
      </CardHeader>
      <ItemsContainer>
        <Item>
          <ItemLabel>Release Name</ItemLabel>
          <ItemText>{name ?? '-'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Release Phone</ItemLabel>
          <ItemText>{phone ?? '-'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Release Email</ItemLabel>
          <ItemText>{email ?? '-'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Driver License</ItemLabel>
          <ItemText>{driver_license ?? '-'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>License State</ItemLabel>
          <ItemText>{license_state ?? '-'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Release Address</ItemLabel>
          <ItemText >{address ?? '-'}</ItemText>
        </Item>
      </ItemsContainer>
    </Card>
  );
};

export default memo(ReleasedCard);
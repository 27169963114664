import { memo, useState, useCallback, useEffect, Fragment } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import Popper from '@mui/material/Popper';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import _find from 'lodash/find';
import _map from 'lodash/map';
// Local files
import { Header, PopperPaper, searchFieldSx, StyledToggleButtonGroup } from './GlobalSearch.styled';
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOrders from 'hooks/useOrders';

const GlobalSearch = ({handleOutOfSearch}) => {
  const { getCustomersOrders, getInvoicesOrders, getVehiclesOrders, clearLocalCustomersOrders, clearLocalInvoicesOrders, clearLocalVehiclesOrders } = useOrders();
  const { setError } = useError();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const { 
    customers: { data: customersData, pagination: customersPagination },
    invoices: { data: invoicesData, pagination: invoicesPagination },
    vehicles: { data: vehiclesData, pagination: vehiclesPagination } 
  } = useCustomSelector(state => state.orders)
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputFocused, setInputFocused] = useState(true);
  const [query, setQuery] = useState('');
  const [tabList, setTabList] = useState([]);
  const [tab, setTab] = useState(false);
  const [fetching, setFetching] = useState(false);
  const open = Boolean(anchorEl);
  const hidePopover = customersData.length === 0 && invoicesData.length === 0 && vehiclesData.length === 0;

  const fetchCustomersOrders = useCallback((offset, query) => {
    setFetching(true);
    getCustomersOrders({ offset, customer_query: query })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => setFetching(false));
  },[getCustomersOrders, setError]);

  const fetchInvoicesOrders = useCallback((offset, query) => {
    setFetching(true);  
    getInvoicesOrders({ offset, self_invoice_query: query })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => setFetching(false));
  },[getInvoicesOrders, setError]); 

  const fetchVehiclesOrders = useCallback((offset, query) => {
    setFetching(true);  
    getVehiclesOrders({ offset, vehicle_query: query })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => setFetching(false));
  },[getVehiclesOrders, setError]); 

  const changeHandler = (e) => {
    setAnchorEl(e.currentTarget);
    setQuery(e.target.value);
  }

  const handleClick = () => {
    setQuery('');
    setAnchorEl(null);
    handleOutOfSearch();
  };

  const handleInputBlur = () => {
    setInputFocused(false);
    if (!query && !anchorEl) {
      handleOutOfSearch();
    }
    if (!query && hidePopover) {
      setAnchorEl(null);
      handleOutOfSearch();
    }
  }

  const handlePopoverAwayClick = () => {
    if (!inputFocused) {
      setAnchorEl(null);
      setQuery('');
      handleOutOfSearch();
    }
  }

  const handleChangeTab = (newTab) => {
    if(newTab !== null) {
      setTab(newTab);
    }
  }

  useEffect(() => {
    let timeout;
    if (!!query) timeout = setTimeout(() => clearLocalCustomersOrders().then(() => fetchCustomersOrders(0, query))
                                    .then(() => clearLocalInvoicesOrders().then(() => fetchInvoicesOrders(0, query)))
                                    .then(() => clearLocalVehiclesOrders().then(() => fetchVehiclesOrders(0, query))), !!query ? 500 : 0);
    return () => {
      clearTimeout(timeout);
      clearLocalCustomersOrders();
      clearLocalInvoicesOrders();
      clearLocalVehiclesOrders();
    }
  }, [fetchCustomersOrders, fetchInvoicesOrders, fetchVehiclesOrders, clearLocalCustomersOrders, clearLocalInvoicesOrders, clearLocalVehiclesOrders, query]);

  useEffect(() => {
    const tabs = [];

    if (customersData.length > 0) tabs.push(1);
    if (invoicesData.length > 0) tabs.push(2);
    if (vehiclesData.length > 0) tabs.push(3);

    setTabList(tabs);
    if (!tab) setTab(tabs[0] || null);
  }, [customersData, invoicesData, vehiclesData, tab]);

  return (
    <Fragment>
      <TextField
        variant='outlined'
        placeholder='Search'
        autoFocus
        onBlur={handleInputBlur}
        onFocus={() => setInputFocused(true)}
        sx={searchFieldSx}
        InputProps={{
          endAdornment: <>
            {fetching && <CircularProgress color="inherit" size={20} />}
          </>
        }}
        value={query}
        onChange={changeHandler}
      />
      {!hidePopover &&
        <ClickAwayListener onClickAway={handlePopoverAwayClick}>
          <Popper
            open={open}
            anchorEl={anchorEl}
          >
            <PopperPaper>
              <Header>
                <StyledToggleButtonGroup
                  size='small'
                  exclusive
                  value={tab}
                  onChange={(_, value) => handleChangeTab(value)}
                >
                  <ToggleButton value={1} sx={{ display: !!_find(tabList, t => t === 1) ? 'block' : 'none' }}>Customers Name</ToggleButton>
                  <ToggleButton value={2} sx={{ display: !!_find(tabList, t => t === 2) ? 'block' : 'none' }}>Invoices Number</ToggleButton>
                  <ToggleButton value={3} sx={{ display: !!_find(tabList, t => t === 3) ? 'block' : 'none' }}>Vehicle License Plate/Vin</ToggleButton>
                </StyledToggleButtonGroup>
              </Header>
                {tab === 1 &&
                  <BaseScrollableContainer {...customersPagination} sx={{height: '33%'}} onLoad={offset => !fetching && fetchCustomersOrders(offset, query)}>
                    <List>
                      {_map(customersData, ({ id, number, customer }) => (
                        <ListItemButton
                          key={id}
                          component={NavLink}
                          to={`/orders/${id}`}
                          disabled={isFetching}
                          onClick={handleClick}
                          sx={{
                            py: 1.5,
                            borderBottom: '1px solid',
                            borderColor: 'background.selected'
                          }}
                        >
                          <ListItemText
                            primary={customer?.name}
                            secondary={`Order: ${number}`}
                            primaryTypographyProps={{ variant: 'body1', color: 'text.primary', noWrap: true }}
                            secondaryTypographyProps={{ variant: 'caption', color: 'text.hint', noWrap: true, component: 'div' }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </BaseScrollableContainer>
                }
                {tab === 2 &&
                  <BaseScrollableContainer {...invoicesPagination} onLoad={offset => !fetching && fetchInvoicesOrders(offset, query)}>
                    <List>
                      {_map(invoicesData, ({ id, number, invoice }) => (
                        <ListItemButton
                          key={id}
                          component={NavLink}
                          to={`/orders/${id}`}
                          disabled={isFetching}
                          onClick={handleClick}
                          sx={{
                            py: 1.5,
                            borderBottom: '1px solid',
                            borderColor: 'background.selected'
                          }}
                        >
                          <ListItemText
                            primary={`${!!number ? `${number}` : '-'} / ${!!invoice?.number ? invoice.number : '-'}`}
                            primaryTypographyProps={{ variant: 'body1', color: 'text.primary', noWrap: true }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </BaseScrollableContainer>
                }
                {tab === 3 &&
                  <BaseScrollableContainer {...vehiclesPagination} onLoad={offset => !fetching && fetchVehiclesOrders(offset, query)}>
                    <List>
                      {_map(vehiclesData, ({ id, number, vehicle }) => (
                        <ListItemButton
                          key={id}
                          component={NavLink}
                          to={`/orders/${id}`}
                          disabled={isFetching}
                          onClick={handleClick}
                          sx={{
                            py: 1.5,
                            borderBottom: '1px solid',
                            borderColor: 'background.selected'
                          }}
                        >
                          <ListItemText
                            primary={`${!!vehicle?.license ? vehicle.license : '-' } / ${!!vehicle?.vin ? vehicle?.vin : '-'} `}
                            secondary={`Order: ${number}`}
                            primaryTypographyProps={{ variant: 'body1', color: 'text.primary', noWrap: true }}
                            secondaryTypographyProps={{ variant: 'caption', color: 'text.hint', noWrap: true, component: 'div' }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </BaseScrollableContainer>
                }
              </PopperPaper>
          </Popper>
        </ClickAwayListener>}
  </Fragment>
  );
}

export default memo(GlobalSearch);
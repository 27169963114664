import { memo } from 'react';
// Local files
import BaseItem from '../BaseItem/BaseItem';
import { parseDispatchText } from 'helpers';

const Dispatch = ({ digital_dispatch_webhook }) => {
  return (
    <>
      <BaseItem title='ISSC' content={!!digital_dispatch_webhook ? parseDispatchText(digital_dispatch_webhook) : '-'} />
    </>
  );
};

export default memo(Dispatch);
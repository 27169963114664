import { memo } from 'react';
// Local files
import Details from 'components/Invoices/Details/Details';
import BaseModalDetails from 'components/Common/BaseModalDetails/BaseModalDetails';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';

const InvoiceDetails = () => {

  return (
    <BaseModalDetails>
      <ComponentErrorWrapper children={<Details />} />
    </BaseModalDetails>
  );
};

export default memo(InvoiceDetails);
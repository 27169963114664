import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getListAccounts as getListAccountsAction,
  getAccounts as getAccountsAction,
  getAccount as getAccountAction,
  createAccount as createAccountAction,
  duplicateAccount as duplicateAccountAction,
  updateAccount as updateAccountAction,
  deleteAccount as deleteAccountAction,
  clearLocalListAccounts as clearLocalListAccountsAction,
  clearLocalAccounts as clearLocalAccountsAction,
  clearLocalActiveAccount as clearLocalActiveAccountAction
} from 'actions/accounts';

const useAccounts = () => {
  const dispatch = useDispatch();
  const getListAccounts = useCallback(({ offset, orders }) => dispatch(getListAccountsAction({ offset, orders })), [dispatch]);
  const getAccounts = useCallback(({ offset, orders, query, account_types }) => dispatch(getAccountsAction({ offset, orders, query, account_types })), [dispatch]);
  const getAccount = useCallback(id => dispatch(getAccountAction(id)), [dispatch]);
  const createAccount = useCallback(account => dispatch(createAccountAction(account)), [dispatch]);
  const duplicateAccount = useCallback(id => dispatch(duplicateAccountAction(id)), [dispatch]);
  const updateAccount = useCallback(account => dispatch(updateAccountAction(account)), [dispatch]);
  const deleteAccount = useCallback(id => dispatch(deleteAccountAction(id)), [dispatch]);
  const clearLocalListAccounts = useCallback(() => dispatch(clearLocalListAccountsAction()), [dispatch]);
  const clearLocalAccounts = useCallback(() => dispatch(clearLocalAccountsAction()), [dispatch]);
  const clearLocalActiveAccount = useCallback(() => dispatch(clearLocalActiveAccountAction()), [dispatch]);

  return { 
    getListAccounts, 
    getAccounts,
    getAccount,
    createAccount,
    duplicateAccount,
    updateAccount,
    deleteAccount,
    clearLocalListAccounts,
    clearLocalAccounts,
    clearLocalActiveAccount
  };
};

export default useAccounts;
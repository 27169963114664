import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

export const Root = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
}));
export const Container = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  borderRadius: 8,
  padding: 16
}));
export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: theme.palette.primary.main,
  fontSize: '1rem',
  fontWeight: 700,
  textTransform: 'uppercase'
}));
import { memo } from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Title = styled((props) => (
  <Typography variant='body2' {...props} />
))(() => ({
  flexShrink: 0,
  flexBasis: '30%'
}));

const BaseItem = ({ title, content }) => {
  return (
   <Box sx={{ display: 'flex', margin: '8px 0' }}>
     <Title variant='body2'>{title}</Title>
     <Typography variant='body2' color='textSecondary' sx={{ flexBasis: '70%', ml: 1 }}>{content ?? '-'}</Typography>
   </Box>
  );
};

export default memo(BaseItem);
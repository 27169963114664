import Box from '@mui/material/Box';
import _includes from 'lodash/includes';
import UpIcon from '@mui/icons-material/ArrowUpward';
import DownIcon from '@mui/icons-material/ArrowDownward';
//Local files
import { Root, Title, ValueText, LabelText, Content } from './BaseStatsCard.styled';

const BaseStatsCard = ({ title, content, firstValue, firstLabel, secondValue = null, secondLabel, sx = null, onClick = null }) => {
  const showPercent = _includes(secondLabel?.toLowerCase(), 'month') || _includes(secondLabel?.toLowerCase(), 'response');
  const isPossitive = Math.sign(secondValue) === 1;

  return (
    <Root sx={sx} onClick={onClick}>
      <Title>{title}</Title>
      <Content>
        {!!content && content}
        <Box sx={{ overflow: 'hidden' }}>
          <ValueText>{firstValue}</ValueText>
          <LabelText color={!!sx ? 'inherit' : 'textSecondary'}>{firstLabel}</LabelText>
        </Box>
        {secondValue !== null && (
          <Box sx={{ overflow: 'hidden' }}>
            <ValueText color={showPercent ? isPossitive ? 'success.main' : 'error.main' : 'inherit'}>{showPercent && (isPossitive ? <UpIcon /> : <DownIcon />)}
              {Math.abs(secondValue)}{showPercent ? '%' : ''}
            </ValueText>
            <LabelText color={!!sx ? 'inherit' : 'textSecondary'}>{secondLabel}</LabelText>
          </Box>
        )}
      </Content>
    </Root>
  )
}

export default BaseStatsCard;
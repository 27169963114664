import { ReactComponent as DutyIcon} from 'assets/icons/duty.svg';
import { ReactComponent as DashboardIcon} from 'assets/icons/dashboard.svg';
import { ReactComponent as OrdersIcon} from 'assets/icons/order.svg';
import { ReactComponent as InvoicesIcon} from 'assets/icons/invoice.svg';
import { ReactComponent as StorageIcon} from 'assets/icons/storage.svg';
import { ReactComponent as AccountsIcon} from 'assets/icons/accounts.svg';
import { ReactComponent as CompanyIcon} from 'assets/icons/company.svg';
import { ReactComponent as MapIcon} from 'assets/icons/map.svg';
import SourceIcon from '@mui/icons-material/SourceOutlined';
import { ReactComponent as TowIcon } from 'assets/icons/widget/tow.svg';
import { ReactComponent as OtherIcon } from 'assets/icons/widget/other.svg';
import { ReactComponent as LockoutIcon } from 'assets/icons/widget/lockout.svg';
import { ReactComponent as FuelIcon } from 'assets/icons/widget/fuel-delivery.svg';
import { ReactComponent as TireIcon } from 'assets/icons/widget/tire-change.svg';
import { ReactComponent as BatteryIcon } from 'assets/icons/widget/battery.svg';

export const shortMonths = [
  'Jan', 'Feb', 'Mar',
  'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep',
  'Oct', 'Nov', 'Dec'
];

export const errorTypes = {
  ACCESS_TOKEN_INVALID: 'access_token_invalid',
  ADD_ACCESS_TOKEN_INVALID: 'add_access_token_invalid',
  AUTHORIZE_DATA_INVALID: 'authorize_data_invalid',
  PARAMETER_MISSING: 'parameter_missing'
};

export const errorMessages = {
  ACCESS_TOKEN_INVALID: 'Authorization problems',
  AUTHORIZE_DATA_INVALID: 'Incorrect email or password',
  ACCESS_FORBIDDEN: 'Access forbidden',
  RECORD_NOT_FOUND: 'Record not found',
  TOO_MANY_REQUESTS: 'Too many attempts, please try again later'
};

export const userRoles = {
  DRIVER: 'driver',
  MANAGER: 'manager',
  OWNER: 'owner'
};

export const sides = {
  CUSTOMER: 'customer',
  USER: 'user'
};

export const userFieldsTypes = {
  FULL: 'full',
  PERSONAL_INFO: 'personal_info',
  LOCATION: 'location'
};

export const reasons = {
  ACCOUNT: 'account',
  DRIVER: 'driver',
  MANAGER: 'manager',
  ORDER: 'order',
  TRUCK: 'truck',
  RATE: 'rate',
  VARIANT: 'variant',
  JOB: 'job',
  EXPENSE: 'expense',
  DOCUMENT: 'document',
  PAYMENT: 'payment',
  REQUEST_ADDITIONAL: 'request_additional',
  SEND_INVOICE: 'send_invoice',
  SEND_ORDER_TRACKING: 'send_order_tracking',
  LOCATION: 'location',
  WIDGET: 'widget',
  RELEASE_VEHICLE: 'release_vehicle',
};

export const recoverySteps = {
  REQUEST: 'request',
  RESET: 'reset',
  VERIFICATION: 'verification'
};

export const orderStatuses = {
  NEW: 'new',
  MANAGER_ACCEPTED: 'manager_accepted',
  COMPANY_ACCEPTED: 'company_accepted',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  EN_ROUTE: 'en_route',
  PICK_UP: 'pick_up',
  SERVICING: 'servicing',
  DROP_OFF: 'drop_off',
  COMPLETED: 'completed',
  STORED: 'stored',
  LAID: 'laid',
  FOR_SALE: 'for_sale',
  RELEASED: 'released',
  SCHEDULED: 'scheduled'
};

export const accountTypes = {
  INSURANCE: 'insurance',
  POLICE: 'police', 
  OTHER: 'other'
};

export const receiverTypes = [
  { value: 'owner', label: 'Owner'}, 
  { value: 'lean_holder', label: 'Lean Holder'},
  { value: 'customer', label: 'Customer'}, 
  { value: 'curbside_sos', label: 'Curbside SOS'}
];

export const orderStatusButtonText = {
  new: 'Start',
  scheduled: 'Start',
  company_accepted: 'Start',
  en_route: 'Pick up',
  pick_up: 'Service',
  servicing: 'Drop off',
  drop_off: 'Complete'
};

export const customerStatuses = {
  JUST_RECEIVED: 'just_received',
  REJECTED: 'rejected',
  MANAGER_ACCEPTED: 'manager_accepted',
  EN_ROUTE: 'en_route',
  PICK_UP: 'pick_up',
  SERVICING: 'servicing',
  DROP_OFF: 'drop_off',
  COMPLETED: 'completed'
};

export const invoiceStatuses = {
  NEW: 'new',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  NOT_SUBMITTED: 'not_submitted',
  PARTIALLY_PAID: 'partially_paid',
  PAID: 'paid',
};

export const vehicleStatuses = {
  NEW: 'new',
  STORED: 'stored',
  LAID: 'laid',
  FOR_SALE: 'for_sale',
  RELEASED: 'released',
  TAKEN_OUT: 'taken_out'
}

export const deliveryTypes = {
  EMAIL: 'email',
  SMS: 'sms',
  PUSH: 'push'
};
export const notificationTypes = {
  order_created: 'New Order',
  order_status_updated: 'Order Status Changed'
}

export const pages = [
  { value: 'duty_status', icon: <DutyIcon />, title: 'DUTY STATUS', accessibility: ['driver'] },
  { value: 'dashboard', icon: <DashboardIcon />, title: 'DASHBOARD', accessibility: ['manager', 'owner'] },
  { value: 'orders', icon: <OrdersIcon />, title: 'ORDERS', accessibility: ['driver', 'manager', 'owner'] },
  { value: 'invoices', icon: <InvoicesIcon />, title: 'INVOICES', accessibility: ['manager', 'owner'] },
  { value: 'vehicles', icon: <StorageIcon />, title: 'STORAGE', accessibility: ['manager', 'owner'] },
  { value: 'accounts', icon: <AccountsIcon />, title: 'ACCOUNTS', accessibility: ['manager', 'owner'] },
  { value: 'company', icon: <CompanyIcon />, title: 'COMPANY', accessibility: ['driver', 'manager', 'owner'] },
  { value: 'map', icon: <MapIcon />, title: 'MAP', accessibility: ['manager', 'owner'] },
  { value: 'resources', icon: <SourceIcon />, title: 'RESOURCES', accessibility: ['manager', 'owner'] }
];

export const settings = ['Settings', 'Logout'];

export const cableMessageTypes = {
  INVOICE_CREATED: 'invoice_created',
  INVOICE_UPDATED: 'invoice_updated',
  ORDER_CREATED: 'order_created',
  ORDER_STATUS_UPDATED: 'order_status_updated',
  ORDER_CUSROMER_LOCATION_UPDATED: 'order_customer_location_updated',
  ORDERS_STATISTICS_UPDATED: 'orders_statistics_updated',
  ORDER_MILES_UPDATED: 'order_miles_updated',
  USER_COORDINATES_UPDATED: 'user_coordinates_updated',
  USER_DUTY_DURATION_UPDATED: 'user_duty_duration_updated',
  INVOICES_STATISTICS_UPDATED: 'invoices_statistics_updated',
  VEHICLES_STATISTICS_UPDATED: 'vehicles_statistics_updated',
  RATE_CREATED: 'rate_created',
  REQUEST_CREATED: 'request_created',
  REQUEST_UPDATED: 'request_updated'
};

export const rejectDigitalDispatchDescriptions = [
  { value: 'Traffic', label: 'Traffic' },
  { value: 'Weather', label: 'Weather' },
  { value: 'OutOfArea', label: 'Out of area' },
  { value: 'EquipmentNotAvailable', label: 'Equipment not available' },
  { value: 'RestrictedRoadway', label: 'Restricted roadway' },
  { value: 'Other', label: 'Other' }
];

export const rejectUrgentlyDescriptions = [ 
  { value: 'BUSY', label: 'Busy' },
  { value: 'TOO_FAR_AWAY', label: 'Too far away' },
  { value: 'TOO_FAR_TOW', label: 'Too far tow' },
  { value: 'UNWANTED_ZONE', label: 'Unwanted zone' },
  { value: 'UNWANTED_SERVICE_TYPE', label: 'Unwanted service type' },
  { value: 'UNWANTED_CUSTOMER_VEHICLE', label: 'Unwanted customer vehicle' },
  { value: 'MISSING_REQUIRED_VEHICLE', label: 'Missing required vehicle' },
  { value: 'MISSING_REQUIRED_TOOLS', label: 'Missing required tools' },
  { value: 'PRICE', label: 'Price' },
  { value: 'CANNOT_FIND_CANDIDATES', label: 'Cannot find candidates' },
  { value: 'Other', label: 'Other' }
];

export const cancelUrgentlyReasons = [ 
  { value: 'CUSTOMER_CANCELLED', label: 'Customer cancelled' },
  { value: 'REQUIREMENT_CHANGED', label: 'Requirement changed' },
  { value: 'DIVERTED_TO_OTHER_JOB', label: 'Diverted to other job' },
  { value: 'SPECIALIST_RESOLVED', label: 'Specialist resolved' },
  { value: 'OTHER', label: 'Other' }
];

export const serviceClassTypes = [
  { value: 'flatbed', label: 'FLATBED' },
  { value: 'wheel_lift', label: 'WHEEL LIFT' },
  { value: 'light_duty', label: 'LIGHT' },
  { value: 'medium_duty', label: 'MEDIUM' },
  { value: 'heavy_duty', label: 'HEAVY' },
  { value: 'super_heavy', label: 'SUPER HEAVY' },
  { value: 'service_truck', label: 'SERVICE TRUCK' },
  { value: 'other', label: 'OTHER' }
];

export const serviceTypes = {
  BASIC: 'basic',
  ADDITIONAL: 'additional',
  FEE: 'fee'
};

export const chargeTypes = {
  BASIC: 'basic',
  FEE: 'fee'
};

export const variantTypes = [
  { value: 'flatbed', label: 'FLATBED' },
  { value: 'wheel_lift', label: 'WHEEL LIFT' },
  { value: 'light_duty', label: 'LIGHT' },
  { value: 'medium_duty', label: 'MEDIUM' },
  { value: 'heavy_duty', label: 'HEAVY' },
  { value: 'super_heavy', label: 'SUPER HEAVY' },
  { value: 'service_truck', label: 'SERVICE TRUCK' }
];

export const mileRoundingTypes = [
  { value: 'mile', label: 'Whole number' },
  { value: 'tenth_mile', label: 'Nearest tenths' }
];

export const timeRoundingTypes = [
  { value: 'minute', label: '1 min' },
  { value: 'minutes_5', label: '5 mins' },
  { value: 'minutes_15', label: '15 mins' },
  { value: 'minutes_30', label: '30 mins' },
  { value: 'hour', label: 'Hour' }
];

export const rateValueType = {
  fixed: '',
  percent: '',
  per_mile: 'Per mile',
  per_hour: 'Per hour',
  per_day: 'Per day'
};

export const rateBasicValueTypes = [
  { value: 'fixed', label: '$' },
  { value: 'per_mile', label: 'Per mile' },
  { value: 'per_hour', label: 'Per hour' },
  { value: 'per_day', label: 'Per day' }
];

export const rateFeeValueTypes = [
  { value: 'fixed', label: '$' },
  { value: 'percent', label: '%' }
];

export const categoryTypes = {
  ORDER: 'order',
  ACCOUNT: 'account'
};

export const paymentTypes = [
  { value: 'card', label: 'Credit card' },
  { value: 'cash', label: 'Cash' },
  { value: 'check', label: 'Check' },
  { value: 'purchase_order', label: 'PO' }
];

export const paymentMethods = {
  CASH: 'cash',
  CARD: 'card',
  CHECK: 'check',
  PO: 'purchase_order'
};

export const deliveryMethods = {
  TEXT: 'text',
  EMAIL: 'email'
};

export const models = {
  ACCOUNTS: 'accounts',
  DRIVERS: 'drivers',
  INVOICES: 'invoices',
  MANAGERS: 'managers',
  ORDERS: 'orders',
  VEHICLES: 'vehicles',
  TRUCKS: 'trucks'
};

export const states = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];
export const dateFilter = [
  { start_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0 - new Date().getTimezoneOffset(), 0, 0).toISOString(), end_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59 - new Date().getTimezoneOffset(), 59, 999).toISOString(), label: 'Today' },
  { start_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay() - 6, 0, 0 - new Date().getTimezoneOffset(), 0, 0).toISOString(), end_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59 - new Date().getTimezoneOffset(), 59, 999).toISOString(), label: 'Last 7 days' },
  { start_at: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2, 0, 0 - new Date().getTimezoneOffset(), 0, 0).toISOString(), end_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59 - new Date().getTimezoneOffset(), 59, 999).toISOString(), label: 'Last month' },
  { start_at: new Date(new Date().getFullYear(), new Date().getMonth() - 3, 2, 0, 0 - new Date().getTimezoneOffset(), 0, 0).toISOString(), end_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59 - new Date().getTimezoneOffset(), 59, 999).toISOString(), label: 'Last 3 months' },
  { start_at: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 2, 0, 0 - new Date().getTimezoneOffset(), 0, 0).toISOString(), end_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59 - new Date().getTimezoneOffset(), 59, 999).toISOString(), label: 'Last 6 months' },
  { start_at: new Date(new Date().getFullYear() - 1, new Date().getMonth(), 2, 0, 0 - new Date().getTimezoneOffset(), 0, 0).toISOString(), end_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 23, 59 - new Date().getTimezoneOffset(), 59, 999).toISOString(), label: 'Last year' },
  { start_at: '', end_at: '', label: 'All time' }
];

export const mileageCalculationOptions = [
  { value: 'between_locations', label: 'Distance between locations' },
  { value: 'app_tracking', label: 'App tracking' }
];

export const towReasonTypes = [
  { value: 'flat_tire', label: 'Flat Tire' },
  { value: 'stuck_in_park', label: 'Stuck in Park' },
  { value: 'broken_axle', label: 'Broken Axle' },
  { value: 'power_issues', label: 'Power issues' },
  { value: 'parking_garage', label: 'Parking Garage' },
  { value: 'roll_over', label: 'Roll Over' },
  { value: 'other', label: 'Other' }
];

export const widgetTypes = ['invoice_detail', 'cash_collection', 'driver_commission', 'eta_accuracy', 'unpaid_invoice', 'average_invoice', 'storage_overview', 'invoice_overview', 'call_response_rate'];

export const widgetSizes = {
  invoice_detail: { width: 8, height: 2 }, 
  truck_detail: { width: 8, height: 2 }, 
  storage_detail: { width: 8, height: 2 }, 
  cash_collection: { width: 4, height: 2 }, 
  driver_commission: { width: 4, height: 2 }, 
  eta_accuracy: { width: 4, height: 2 }, 
  unpaid_invoice: { width: 4, height: 1 }, 
  average_invoice: { width: 4, height: 1 }, 
  storage_overview: { width: 4, height: 1 }, 
  invoice_overview: { width: 4, height: 1 }, 
  call_response_rate: { width: 4, height: 1 }, 
  complete_order_data: { width: 4, height: 1 }, 
  driver_summary_report: { width: 4, height: 1 }
};
export const widgetLabels = {
  cash_collection: { title: 'Cash Collection', titleLabel: 'Employee Name', valueLabel: 'Cash Collected' }, 
  driver_commission: { title: 'Driver Compensation', titleLabel: 'Driver Name', valueLabel: 'Payout' }, 
  eta_accuracy: { title: 'ETA Accuracy' },
  unpaid_invoice: { title: 'Unpaid Invoices', firstLabel: 'Unpaid Amount', secondLabel: 'Calls' }, 
  average_invoice: { title: 'Average Invoice', firstLabel: 'Average Amount', secondLabel: 'This month' }, 
  storage_overview: { title: 'Stored Vehicles', firstLabel: 'Stored Amount', secondLabel: 'Number' }, 
  invoice_overview: { title: 'Total Invoices', firstLabel: 'Total Amount', secondLabel: 'Calls' }, 
  call_response_rate: { title: 'Call Response Rate', firstLabel: 'Accepted Calls', secondLabel: 'Call Response' }
};
export const timezones = [
  { value: 'US/Pacific', label: 'PT' },
  { value: 'US/Mountain', label: 'MT' },
  { value: 'US/Central', label: 'CT' },
  { value: 'US/Eastern', label: 'ET' },
  { value: 'US/Hawaii', label: 'HT' },
  { value: 'US/Alaska', label: 'AT' },
];
export const filterTimezone = {
  'America/New_York': 'US/Eastern',
  'America/Detroit': 'US/Eastern',
  'America/Kentucky/Louisville': 'US/Eastern',
  'America/Kentucky/Monticello': 'US/Eastern',
  'America/Indiana/Indianapolis': 'US/Eastern',
  'America/Indiana/Vincennes': 'US/Eastern',
  'America/Indiana/Winamac': 'US/Eastern',
  'America/Indiana/Marengo': 'US/Eastern',
  'America/Indiana/Petersburg': 'US/Eastern',
  'America/Indiana/Vevay': 'US/Eastern',
  'America/Chicago': 'US/Central',
  'America/Indiana/Tell_City': 'US/Central',
  'America/Indiana/Knox': 'US/Central',
  'America/Menominee': 'US/Central',
  'America/North_Dakota/Center': 'US/Central',
  'America/North_Dakota/New_Salem': 'US/Central',
  'America/North_Dakota/Beulah': 'US/Central',
  'America/Denver': 'US/Mountain',
  'America/Boise': 'US/Mountain',
  'America/Phoenix': 'US/Mountain',
  'America/Los_Angeles': 'US/Pacific',
  'America/Anchorage': 'US/Alaska',
  'America/Juneau': 'US/Alaska',
  'America/Sitka': 'US/Alaska',
  'America/Metlakatla': 'US/Alaska',
  'America/Yakutat': 'US/Alaska',
  'America/Nome': 'US/Alaska',
  'America/Adak': 'US/Hawaii',
  'Pacific/Honolulu': 'US/Hawaii',
};
export const orderRequestStatuses = {
  NEW: 'new',
  ANSWERED: 'answered',
  APPROVED: 'approved',
  MORE_INFO: 'more_info_needed',
  DECLINED: 'declined',
  FAILED: 'failed'
};
export const widgetServicesTypes = [
  { type: 'tow', label: 'Tow', icon: <TowIcon /> }, 
  { type: 'lockout', label: 'Lockout', icon: <LockoutIcon /> },
  { type: 'tire', label: 'Tire change', icon: <TireIcon /> },
  { type: 'battery', label: 'Battery', icon: <BatteryIcon /> },
  { type: 'fuel', label: 'Fuel Delivery', icon: <FuelIcon /> },
  { type: '', label: '', icon: <OtherIcon /> }
];
export const widgetOtherServicesTypes = ['Accident', 'Police', 'Abandoned Vehicle', 'Jump Start', 'Winch out', 'Decking', 'Undocking', 'Impound'];
export const orderSources = {
  DIGITAL_DISPATCH: 'digital_dispatch',
  URGENTLY: 'urgently',
  WEB: 'web',
  WIDGET: 'widget'
};
export const urgentlyVehicleTypes = ['flat_bed', 'tow_truck', 'rsa', 'covered_carrier'];

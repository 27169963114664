import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Time } from './ElapsedTime.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const ElapsedTime = ({ startDateIso }) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    const startDate = new Date(startDateIso);

    const getElapsedTime = () => {
      const currentDate = new Date();
      const differenceInMilliseconds = currentDate - startDate;
      const totalSeconds = Math.floor(differenceInMilliseconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}.${formattedSeconds}`;
    };

    const intervalId = setInterval(() => {
      setElapsedTime(getElapsedTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDateIso]);

  return <Time>{elapsedTime}</Time>;
};

ElapsedTime.propTypes = {
  startDateIso: PropTypes.string.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <ElapsedTime {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getRateRoutine,
  createRateRoutine,
  updateRateRoutine,
  deleteRateRoutine,
  clearLocalRatesRoutine,
  clearLocalActiveRateRoutine
} from 'actions';

const attributes = [
  'id',
  { charge: ['id', 'name', 'charge_type', 'mile_type', 'basic_rate_types'] },
  'basic_value_type',
  'fee_value_type',
  'value'
];

export const getRate = getThunkActionCreator(
  getRateRoutine,
  async (id) => {
    getRate.getRateCancel && getRate.getRateCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/rates/${id}`, {
      params: { access_token, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getRate.getRateCancel = cancel })
    });
  }
);
export const createRate = getThunkActionCreator(
  createRateRoutine,
  async ({ rate, variant_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/variants/${variant_id}/rates`, { access_token, rate, attributes });

    return { response, variant_id };
  }
);
export const updateRate = getThunkActionCreator(
  updateRateRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/rates/${id}`, { access_token, rate: fields });

    return { response, rate: { id, ...fields } };
  }
);
export const deleteRate = getThunkActionCreator(
  deleteRateRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/rates/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalRates = getThunkActionCreator(clearLocalRatesRoutine, async () => {});
export const clearLocalActiveRate = getThunkActionCreator(clearLocalActiveRateRoutine, async () => {});
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getListAccountsRoutine, 
  getAccountsRoutine,
  getAccountRoutine,
  createAccountRoutine,
  duplicateAccountRoutine,
  updateAccountRoutine,
  deleteAccountRoutine,
  clearLocalListAccountsRoutine,
  clearLocalAccountsRoutine,
  clearLocalActiveAccountRoutine
} from 'actions';
import _omit from 'lodash/omit';

const shortAttributes = ['id', 'name', { company: ['id', 'name'] }];
const attributes = [
  ...shortAttributes,
  { jobs: ['id', 'name', 'job_type'] },
  { insurance_accounts: ['id', 'name'] },
  'account_type', 'default', 'phone', 'email', 'fax', 
  'contact_name', 'contact_phone', 'contact_email', 
  'billing_email', 'second_billing_email', 'third_billing_email',
  'billing_address', 'physical_address', 'notes', 'created_at'
];

export const getListAccounts = getThunkActionCreator(
  getListAccountsRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' } }) => {
    getListAccounts.getListAccountsCancel && getListAccounts.getListAccountsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/accounts`,
      {
        params: { access_token, limit: 25, offset, orders, attributes: shortAttributes },
        cancelToken: new CancelToken(function executor(cancel) { getListAccounts.getListAccountsCancel = cancel })
      }
    );
  }
);
export const getAccounts = getThunkActionCreator(
  getAccountsRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, query = null, account_types = null }) => {
    getAccounts.getAccountsCancel && getAccounts.getAccountsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/accounts`, {
      params: { access_token, limit, offset, orders, ...(!!query && { query }), ...(!!account_types && { account_types }), attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getAccounts.getAccountsCancel = cancel })
    });
  }
);
export const getAccount = getThunkActionCreator(
  getAccountRoutine,
  async (id) => {
    getAccount.getAccountCancel && getAccount.getAccountCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/accounts/${id}`, {
      params: { access_token, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getAccount.getAccountCancel = cancel })
    });
  }
);
export const createAccount = getThunkActionCreator(
  createAccountRoutine,
  async (account) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/accounts`, { access_token, account, attributes });
  }
);
export const duplicateAccount = getThunkActionCreator(
  duplicateAccountRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/accounts/${id}/duplicate`, { access_token, attributes });
  }
);
export const updateAccount = getThunkActionCreator(
  updateAccountRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/accounts/${id}`, { access_token, account: _omit(fields, ['localInsuranceAccounts', 'localCompany']) });

    return { response, account: { id, ..._omit(fields, ['insurance_account_ids', 'company_id']) } };
  }
);
export const deleteAccount = getThunkActionCreator(
  deleteAccountRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/accounts/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalListAccounts = getThunkActionCreator(clearLocalListAccountsRoutine, async () => {});
export const clearLocalAccounts = getThunkActionCreator(clearLocalAccountsRoutine, async () => {});
export const clearLocalActiveAccount = getThunkActionCreator(clearLocalActiveAccountRoutine, async () => {});
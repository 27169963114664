import { memo, useState, useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
// Local files
import Table from './Table/Table';
import useNotifications from 'hooks/useNotifications';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import { deliveryTypes } from 'helpers/constants';


const Notifications = ({ userNotifications }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { updateNotification } = useNotifications();
  const [notifications, setNotifications] = useState([]);
  const [processing, setProcessing] = useState(false);

  const setFields = useCallback(() => {
    setProcessing(true);
    setNotifications(userNotifications);
    setProcessing(false);
  }, [userNotifications])
  const clearFields = () => {
    setNotifications([]);
    setProcessing(false);
  };

  useEffect(() => {
    setFields();

    return clearFields;
  }, [setFields]);

  const handleUpdateNotification = (notification) => {
    const processableNotification = { id: notification.id, notification };

    setProcessing(true);
    updateNotification(processableNotification)
    .then(() => setSuccess('Notifications successfully updated'))
    .catch(error => setError(error))
    .finally(() => setProcessing(false));
  };
  const handelNotificationsChange = ({ target: { id, checked } }) => {
    const updatedNotifications = _map(notifications, (n) => _includes(id, n.id) ? { 
      ...n, 
      delivery_types: checked ? [...n.delivery_types, ..._filter(Object.values(deliveryTypes), (item) => _includes(id, item))] : _filter(n.delivery_types, (dt) => !_includes(id, dt))  
      } : n
    )
    setNotifications(updatedNotifications);
    handleUpdateNotification(_find(updatedNotifications, (n) => _includes(id, n.id)));
  };

  return (
    <>
      <Typography variant='title'>Notifications Management</Typography>
      <Table onChange={handelNotificationsChange} {...{processing, notifications}} />
    </>
  );
};

export default memo(Notifications);
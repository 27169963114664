import { forwardRef, memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const BaseDialog = ({ open, onClose, hasTransition = true, title, content, actions, children, otherPaperStyle, otherTitleStyle, ...otherProps }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={!!hasTransition ? Transition : undefined}
      PaperProps={{
        sx: {
          minWidth: '300px',
          borderRadius: '16px',
          ...otherPaperStyle
        }
      }}
      {...otherProps}
    >
      {!!title && (<DialogTitle sx={{ pb: 1, textAlign: 'center', ...otherTitleStyle }}>{title}</DialogTitle>)}
      {!!content && (<DialogContent sx={{ py: 1 }}>{content}</DialogContent>)}
      {!!children && children}
      {!!actions && (<DialogActions>{actions}</DialogActions>)}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasTransition: PropTypes.bool,
  title: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node,
  otherPaperStyle: PropTypes.object,
  otherTitleStyle: PropTypes.object,
};

export default memo(BaseDialog);
import { memo } from 'react';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import _startsWith from 'lodash/startsWith';
import Checkbox from '@mui/material/Checkbox';
//Local files
import { Root, Image, DocPlaceholder, Info, DeleteButton, StyledChip } from './FilePreview.styled';
import DocIcon from './DocIcon/DocIcon';
import useDialogs from 'hooks/useDialogs';

const FilePreview = ({ name, label, type, preview, url, address = null, onDelete, isCheckbox = false, checked = false, onCheck = undefined }) => {
  const { openFilePreviewDialog } = useDialogs();
  
  const handleFileClick = () => openFilePreviewDialog({ preview, url, filename: name, address });

  return (
    <Root>
      {_startsWith(type, 'image/') ? (
        <Image sx={{ backgroundImage: `url(${preview})` }} onClick={handleFileClick} />
      ) : (
        <DocPlaceholder onClick={handleFileClick}>
          <DocIcon type={type} />
        </DocPlaceholder>
      )}
      <Info>
        {isCheckbox && <Checkbox
          checked={checked}
          onChange={onCheck}
          sx={{color: 'white'}}
        />}
        <Typography variant='body2' noWrap sx={{ color: '#fff' }}>{name}</Typography>
        <StyledChip label={label} size='small'/>
      </Info>
      {!!onDelete &&
        <DeleteButton aria-label='delete' onClick={onDelete}>
          <ClearIcon sx={{ width: 20 }} />
        </DeleteButton>
      }
    </Root>
  );
};

export default memo(FilePreview);
import { memo, useState } from 'react';
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// Local files
import StepLayout from "../StepLayout/StepLayout";
import { checkValidEmail } from 'helpers';
import { recoverySteps } from 'helpers/constants';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useSessions from 'hooks/useSessions';
import useConfirmations from 'hooks/useConfirmations';

const { VERIFICATION } = recoverySteps;

const RequestStep = ({ changeStep }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { setRecoveryEmail } = useSessions();
  const { sendConfirmationCode } = useConfirmations();
  const [email, setEmail] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);

  const handleSendClick = () => {
    let hasError = false;

    if (!checkValidEmail(email.value)) {
      setEmail(prev => ({ ...prev, error: `Email is invalid` }));
      hasError = true;
    }
    if (hasError) return;

    setLoading(true);
    sendConfirmationCode(email.value)
    .then(() => setRecoveryEmail(email.value).then(() => changeStep(VERIFICATION)).then(() => setSuccess('Code sent')))
    .catch((error) => setError(error))
    .finally(() => setLoading(false));
  }

  const handleKeyDown = ({ key }) => key === 'Enter' && handleSendClick();

  return (
    <StepLayout
      backStep='/sign_in'
      title='Trouble Logging In?'
      description={<>Enter your email and we will send you a<br/> link to get you back into your account</>}
      content={
        <TextField
          fullWidth
          placeholder='Enter email'
          type='email'
          value={email.value}
          onChange={({ target: { value } }) => setEmail({ value, error: '' })}
          error={!!email.error}
          helperText={email.error}
          onKeyDown={handleKeyDown}
        />
      }
      actions={
        <Container maxWidth='xs' sx={{ pt: 2}}>
          <Button variant='contained' color='primary' fullWidth size='xlarge' disabled={loading} onClick={handleSendClick}>Send a code</Button>
        </Container>
      }
      />
  );
};

export default memo(RequestStep);
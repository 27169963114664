import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import { sessionService } from 'redux-react-session';
// Material-UI
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider  } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './index.css';
// Reducers
import reducer from './reducers';
// Components
import ErrorSnackbar from './components/Common/ErrorSnackbar/ErrorSnackbar';
import SuccessSnackbar from 'components/Common/SuccessSnackbar/SuccessSnackbar';
import App from 'components/App/App';

const store = configureStore({ reducer, middleware: [thunk] });
const container = document.getElementById('root');
const root = createRoot(container);
sessionService.initSessionService(store);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorSnackbar />
        <SuccessSnackbar />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async (names) => {
        await Promise.all(names.map(name => caches.delete(name)));
      });
    }
  });
}
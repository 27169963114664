import { memo } from 'react';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import BaseStatus from 'components/Common/BaseStatus/BaseStatus';
import { orderRequestStatuses } from 'helpers/constants';

const { NEW, ANSWERED, APPROVED, MORE_INFO, DECLINED, FAILED } = orderRequestStatuses;

const Status = ({ value, size, darkBackground }) => {

  switch (value) {
    case(NEW): {
      return (
        <BaseStatus size={size} color='primary' darkBackground={darkBackground} label={_startCase(orderRequestStatuses[value.toUpperCase()])} />
      )
    }
    case(MORE_INFO): {
      return (
        <BaseStatus size={size} color='warning' darkBackground={darkBackground} label={_startCase(orderRequestStatuses[value.toUpperCase()])} />
      )
    }
    case(APPROVED): {
      return (
        <BaseStatus size={size} color='success' darkBackground={darkBackground} label={_startCase(orderRequestStatuses[value.toUpperCase()])} />
      )
    }
    case(ANSWERED): {
      return (
        <BaseStatus size={size} color='success' darkBackground={darkBackground} label={_startCase(orderRequestStatuses[value.toUpperCase()])} />
      )
    }
    case(DECLINED): {
      return (
        <BaseStatus size={size} color='error' darkBackground={darkBackground} label={_startCase(orderRequestStatuses[value.toUpperCase()])} />
      )
    }
    case(FAILED): {
      return (
        <BaseStatus size={size} color='error' darkBackground={darkBackground} label={_startCase(orderRequestStatuses[value.toUpperCase()])} />
      )
    }
    default: {
      return null;
    }
  }
};

Status.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  darkBackground: PropTypes.bool
}

export default memo(Status);
import { memo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/MenuRounded';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import { useNavigate, useLocation } from 'react-router-dom';
// Local files
import GlobalSearch from 'components/Common/GlobalSearch/GlobalSearch';
import logo from 'assets/images/roadsider-circle.png';
import Nav from './Nav/Nav';
import { pages, settings } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';
import useSession from 'hooks/useSession';
import useRole from 'hooks/useRole';
import useSessions from 'hooks/useSessions';
import { getRepresentation } from 'helpers';

const AppBarMenu = () => {
  const { setFilter } = useApp();
  const navigate = useNavigate();
  const { deleteActiveSession, clearStore } = useSessions();
  const { setError } = useError();
  const { pathname } = useLocation();
  const { checked, authenticated } = useSession();
  const { isDriver, isManager, isMulti, isOwner } = useRole();
  const { first_name, last_name, photo } = useCustomSelector(state => state.profile.user);
  const [search, setSearch] = useState(false);
  const [navigationAnchor, setNavigationAnchor] = useState(null);
  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const [processing, setProcessing] = useState(false);

  const hideAppBar = localStorage.getItem('hide_app_bar') === 'true';
  const acceessiblePages = _filter(pages, p => isMulti || isManager || isOwner
    ? (_includes(p.accessibility, 'manager') || _includes(p.accessibility, 'owner'))
    : (isDriver ? _includes(p.accessibility, 'driver') : [])
  );
  const searchButton = <IconButton aria-label="Search" sx={{ color: 'tertiary.contrastText' }} onClick={() => setSearch(!search)}>
                        <SearchIcon />
                      </IconButton>;

  const handleSettingsItemClick = (item) => {
    setSettingsAnchor(null);
    item === 'Settings' && handleSettingsClick();
    item === 'Logout' && handleLogoutClick();
  };
  const handleSettingsClick = () => {
    navigate('/settings');
  };
  const handleLogoutClick = () => {
    setProcessing(true);
    deleteActiveSession()
    .then(() => clearStore())
    .catch(error => setError(error).then(() => setProcessing(false)));
  };
  const handleNavigationClick = (item) => {
    let targetUrl = '';

    !!navigationAnchor && setNavigationAnchor(null);

    switch (item) {
      case 'logo':
        targetUrl = (isManager || isOwner) ? '/dashboard' : '/';
        break;
      case 'duty_status':
        targetUrl = '/duty_status';
        break;
      case 'orders':
        targetUrl = '/orders';
        break;
      case 'map':
        targetUrl = '/map';
        break;
      case 'dashboard':
        targetUrl = '/dashboard';
        break;
      case 'invoices':
        targetUrl = '/invoices';
        break;
      case 'company':
        targetUrl = '/company';
        break;
      case 'vehicles':
        targetUrl = '/vehicles';
        break;
      case 'accounts':
        targetUrl = '/accounts';
        break;
      case 'resources':
        targetUrl = '/resources';
        break;
      default:
        break;
    }
    if (!!targetUrl && !_includes(pathname, targetUrl)) {
      navigate(targetUrl);
      setFilter(false);
    }
  };

  if (checked && authenticated && !hideAppBar) {
    return (
      <AppBar
        position='static'
        elevation={0}
        sx={{
          color: 'text.primary',
          bgcolor: '#fff',
          boxShadow: { md: '0px -1px 0px 0px  #F1F0FC inset' }
        }}
      >
        <Toolbar sx={{ height: '64px' }}>
          <Typography
            variant="h6"
            color='primary'
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, gap: '8px', alignItems: 'center', fontWeight: 700, cursor: 'pointer' }}
            onClick={() => handleNavigationClick('logo')}
          >
            <img src={logo} alt='logo' style={{ height: 30, width: 'auto', borderRadius: '50%', overflow: 'hidden' }} />
            <span>ROADSIDER</span>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <IconButton
              size="large"
              edge='start'
              aria-label="profile"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={({ currentTarget }) => setNavigationAnchor(currentTarget)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={navigationAnchor}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={!!navigationAnchor}
              onClose={() => setNavigationAnchor(null)}
              //sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {_map(acceessiblePages, (item, i) => (
                <MenuItem key={i} onClick={() => handleNavigationClick(item.value)}>
                  <Typography textAlign="center">{item.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
            {(isManager || isOwner) && searchButton}
            {(isManager || isOwner) && search && <GlobalSearch handleOutOfSearch={() => setSearch(false)}/>}
          </Box>
          {!search && <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            ROADSIDER
          </Typography>}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
            {_map(acceessiblePages, (item, i) => (
              <Nav
                key={i}
                icon={item.icon}
                title={item.title}
                to={item.value}
                selected={item.value === pathname.split('/')[1]}
                onClick={() => setFilter(false)}
              />
            ))}
            {(isManager || isOwner) && searchButton}
            {(isManager || isOwner) && search && <GlobalSearch handleOutOfSearch={() => setSearch(false)}/>}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={({ currentTarget }) => setSettingsAnchor(currentTarget)} sx={{ p: 0 }}>
                <Avatar
                  alt="user"
                  src={photo?.localPhoto || getRepresentation(photo?.representations, 'tiny')}
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: 'primary.main',
                    bgcolor: 'background.paper',
                    border: !!photo ? 'none' : '1px solid',
                    borderColor: 'primary.main',
                    borderRadius: '12px',
                  }}
                >
                  {first_name[0]?.toUpperCase() ?? ''}{last_name[0]?.toUpperCase() ?? ''}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={settingsAnchor}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={!!settingsAnchor}
              onClose={() => setSettingsAnchor(null)}
            >
              {_map(settings, setting => (
                <MenuItem
                  key={setting}
                  onClick={() => handleSettingsItemClick(setting)}
                  disabled={processing}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
  return null;
};

export default memo(AppBarMenu);
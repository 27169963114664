import styled from '@mui/material/styles/styled';
import {Box, Paper} from '@mui/material';

export const GridContainer = styled(Box)(({ theme }) => ({
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    maxWidth:'950px',
    padding: '0 5%',
    [theme.breakpoints.up('xlg')]: {
        padding: '0',
        maxWidth:'1300px',
    }
}));
export const FirstRow = styled(Box)(({ theme }) => ({
    display:"flex", 
    flexDirection:"column", 
    gap:"1.4rem", 
    [theme.breakpoints.up('xlg')]: {
        flexDirection:"row", 
    }
}));
export const FirstRowItem = styled(Paper)({
    width:"100%", 
    padding:"3rem 2rem", 
    borderRadius:"25px" 
});
export const SecondRow = styled(Box)(({ theme }) => ({
    display:"flex", 
    flexDirection:"column", 
    gap:"1.4rem",
    marginTop:'1.4rem',
    width:"100%", 
    [theme.breakpoints.up('xlg')]: {
    }
}));
export const SecondRowItem = styled(Paper)(({ theme }) => ({
    width:"100%", 
    padding:"3rem 2rem", 
    borderRadius:"25px",
    display:"flex", 
    flexDirection:"column", 
    alignItems:"center",
    [theme.breakpoints.up('xlg')]: {
        flexDirection:"row", 
    }
}));
export const ThirdRow = styled(Box)(({ theme }) => ({
    display:"flex", 
    flexDirection:"column", 
    gap:"1.4rem",
    marginTop:'1.4rem',
    width:"100%", 
    [theme.breakpoints.up('xlg')]: {
        flexDirection:"row", 
    }
}));
export const ThirdRowItem = styled(Paper)(({ theme }) => ({
    width:"100%", 
    padding:"3rem 2rem", 
    borderRadius:"25px",
    display:"flex", 
    flexDirection:"column", 
    alignItems:"center",
    [theme.breakpoints.up('xlg')]: {

    }
}));








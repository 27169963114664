import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)(() => ({
  maxWidth: 700,
  padding: '24px'
}));
export const ServicesContainer = styled(Box)(() => ({
  display: 'flex', 
  flexWrap: 'wrap', 
  justifyContent: 'center', 
  gap: '14px 25px'
}));
export const CustomerContainer = styled(Box)(() => ({
  display: 'flex', 
  flexWrap: 'wrap', 
  gap: '24px', 
  padding: '44px 0 56px'
}));
export const LocationsContainer = styled(Box)(({ theme }) => ({
  display: 'flex', 
  position: 'relative',
  width: '100%',
  gap: '24px', 
  alignItems: 'center', 
  justifyContent: 'space-between',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));
export const LabelContainer = styled(Box)(() => ({
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-between'
}));
export const LabelText = styled(Box)(() => ({
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis'
}));
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  openAppDrawer as openAppDrawerAction,
  closeAppDrawer as closeAppDrawerAction,
  setAppFilter as setAppFilterAction,
  startRemoving as startRemovingAction,
  stopRemoving as stopRemovingAction,
  setAccountServiceData as setAccountServiceDataAction,
  filterExpencesData as filterExpencesDataAction,
  setFetchingDetails as setFetchingDetailsAction
} from 'actions/app';

const useApp = () => {
  const dispatch = useDispatch();
  const openDrawer = useCallback(({ reason, edit, fieldsType, duplicate, towVehicle, onlyVehicle }) => dispatch(openAppDrawerAction({ reason, edit, fieldsType, duplicate, towVehicle, onlyVehicle })), [dispatch]);
  const closeDrawer = useCallback(reason => dispatch(closeAppDrawerAction(reason)), [dispatch]);
  const setFilter = useCallback(open => dispatch(setAppFilterAction(open)), [dispatch]);
  const startRemoving = useCallback(() => dispatch(startRemovingAction()), [dispatch]);
  const stopRemoving = useCallback(() => dispatch(stopRemovingAction()), [dispatch]);
  const setAccountServiceData = useCallback(({ activeVariant, activeRate, newRate }) => dispatch(setAccountServiceDataAction({ activeVariant, activeRate, newRate })), [dispatch]);
  const filterExpencesData = useCallback((expense_id) => dispatch(filterExpencesDataAction(expense_id)), [dispatch]);
  const setFetchingDetails = useCallback((isFetching) => dispatch(setFetchingDetailsAction(isFetching)), [dispatch]);

  return { openDrawer, closeDrawer, setFilter, startRemoving, stopRemoving, setAccountServiceData, filterExpencesData, setFetchingDetails };
};

export default useApp;
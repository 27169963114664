import { memo, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
// Local files
import useTimezones from 'hooks/useTimezones';
import Status from 'components/Requests/Status/Status';
import { orderSources } from 'helpers/constants';
import AnswerRequest from 'components/Dialogs/AnswerRequest/AnswerRequest';

const RequestsTable = ({ requests, disabled, company_location, isShowMore, onNextOffset }) => {
  const { timeZone } = useTimezones(company_location?.company?.id);
  const timeOptions = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true, timeZone };
  const [answerDialog, setAnswerDialog] = useState({ open: false, request_id: '' });

  const handleResponse = (request_id) => setAnswerDialog({ open: true, request_id });

  return (
    <Box sx={{ width: '100%' }}>
      <AnswerRequest {...answerDialog} onClose={() => setAnswerDialog({ open: false, request_id: '' })} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell sx={{ py: 2, width: '40%' }}>Request Name</TableCell>
              <TableCell sx={{ py: 2, width: '40%' }}>Request Time</TableCell>
              <TableCell sx={{ py: 2, width: '5%' }}>Status</TableCell>
              <TableCell sx={{ py: 2, width: '15%' }}>Answer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { _map(requests, (request) => (
              <TableRow key={request.id} sx={{ whiteSpace: 'nowrap' }}>
                <TableCell>{_startCase(request?.channel_message?.service_name || request?.channel_message?.request_type)}</TableCell>
                <TableCell>{new Date(request.created_at).toLocaleDateString('en-US', timeOptions)}</TableCell>
                <TableCell><Status size='small' value={request?.status} /></TableCell>
                <TableCell>
                  {request?.source === orderSources.DIGITAL_DISPATCH && !!!request?.response ? 
                  <Button size='small' disabled={disabled} onClick={() => handleResponse(request.id)} >Answer</Button> : 
                  request?.response}
                </TableCell>
              </TableRow>)
            ) }
            <Divider />
          </TableBody>
        </Table>
      </TableContainer>
      { isShowMore && <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button variant='outlined' fullWidth disabled={disabled} onClick={onNextOffset}>Show more</Button>
      </Box> }
    </Box>
  );
};

export default memo(RequestsTable);
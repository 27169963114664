import { memo } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const Search = ({ value, onChange }) => {
  return (
    <TextField
      variant='outlined'
      placeholder='Search'
      sx={{
        flexGrow: 1,
        '& > div': {
          borderRadius: '12px'
        },
        '& input': {
          height: '24px',
          paddingTop: '8px',
          paddingBottom: '8px'
        }
      }}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    />
  );
};

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(Search);

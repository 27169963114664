import {
  getListInsuranceAccountsRoutine, 
  clearLocalListInsuranceAccountsRoutine
} from 'actions';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListInsuranceAccountsRoutine.SUCCESS: {
      const { data: { insurance_accounts, pagination } } = action.payload;

      return { ...state, list: { data: [...state.list.data, ...insurance_accounts], pagination } };
    }
    case clearLocalListInsuranceAccountsRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
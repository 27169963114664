import {
  getDocumentsRoutine,
  getDocumentRoutine,
  createDocumentsRoutine,
  deleteDocumentRoutine,
  clearLocalDocumentsRoutine,
  clearLocalActiveDocumentRoutine,
  updateOrderRoutine
} from 'actions';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  document: { id: '', category: null, file: null }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getDocumentsRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...documents], pagination } };
    }
    case getDocumentRoutine.SUCCESS: {
      const { data: { document } } = action.payload;

      return { ...state, document };
    }
    case createDocumentsRoutine.SUCCESS: {
      const { response: { status }, newDocuments } = action.payload;
      const data = [...newDocuments, ...state.all.data];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, ...( status === 204 && { all: { data, pagination } } ) };
    }
    case deleteDocumentRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case clearLocalDocumentsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveDocumentRoutine.SUCCESS: {
      return { ...state, document: initialState.document }
    }
    case updateOrderRoutine.SUCCESS: {
      const { response: { status }, order } = action.payload;
      const destroyedDocument = _find(order?.localDocuments, (d) => d?._destroy);

      return { ...state, ...(status === 204 && !!order?.localDocuments?.length && 
        { all: { 
          ...state.all, 
          data: !!destroyedDocument ? _filter(state.all.data, ({ id }) => id !== destroyedDocument?.id) : [...state.all.data, ...order?.localDocuments]
        } }) 
      };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
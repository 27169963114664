import { memo } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BackIcon from "@mui/icons-material/ArrowBackIosRounded";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from 'react-router-dom';

const StepLayout = ({ title, description, content, actions, backStep }) => {
  const navigate = useNavigate();

  return (
    <Container maxWidth='sm' sx={{ minHeight: '400px', height: '100%', pt: 2, }} disableGutters>
      <Box display='flex' gap={2}>
        {!!backStep && (
          <Button
            color='inherit'
            startIcon={<BackIcon/>}
            sx={{ color: 'text.hint' }}
            onClick={typeof backStep === 'string' ? () => navigate(backStep) : backStep}
          >
            Back
          </Button>
        )}
      </Box>
      <Box>
        <Typography
            align='center'
            variant='h6'
            variantMapping={{ h6: 'h1' }}
            pt={{ xs: 10, md: 20 }}
            sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
        >
          {title}
        </Typography>
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            sx={{ wordBreak: 'break-word' }}
        >
          {description}
        </Typography>
      </Box>
      {!!content && (
        <Container maxWidth={'xs'} sx={{ pt: { xs: 3, md: 7 }}}>
          {content}
        </Container>
      )}
      {!!actions && actions}
      </Container>
  );
};

export default memo(StepLayout);
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getUserOrders as getUserOrdersAction,
  getOrdersForDashboard as getOrdersForDashboardAction,
  getOrders as getOrdersAction,
  getCustomersOrders as getCustomersOrdersAction,
  getInvoicesOrders as getInvoicesOrdersAction,
  getVehiclesOrders as getVehiclesOrdersAction,
  getOrder as getOrderAction,
  getOrderForNotification as getOrderForNotificationAction,
  createOrder as createOrderAction,
  updateOrder as updateOrderAction,
  deleteOrder as deleteOrderAction,
  supplementOrder as supplementOrderAction,
  rejectOrder as rejectOrderAction,
  cancelOrder as cancelOrderAction,
  renewOrder as renewOrderAction,
  startOrderMovement as startOrderMovementAcion,
  pickUpOrder as pickUpOrderAction,
  serviceOrder as serviceOrderAction,
  dropOffOrder as dropOffOrderAction,
  completeOrder as completeOrderAction,
  reverseStatus as reverseStatusAction,
  applyCustomerLocation as applyCustomerLocationAction,
  rejectCustomerLocation as rejectCustomerLocationAction,
  sendOrderTracking as sendOrderTrackingAction,
  changeLocalOrderStatus as changeLocalOrderStatusAction,
  clearLocalUserOrders as clearLocalUserOrdersAction,
  clearLocalOrdersWithUser as clearLocalOrdersWithUserAction,
  clearLocalOrders as clearLocalOrdersAction,
  clearLocalCustomersOrders as clearLocalCustomersOrdersAction,
  clearLocalInvoicesOrders as clearLocalInvoicesOrdersAction,
  clearLocalVehiclesOrders as clearLocalVehiclesOrdersAction,
  clearLocalActiveOrder as clearLocalActiveOrderAction,
  setLocalOrderFields as setLocalOrderFieldsAction,
  getOrdersStatistics as getOrdersStatisticsAction,
  clearOrdersStatistics as clearOrdersStatisticsAction
} from 'actions/orders';

const useOrders = () => {
  const dispatch = useDispatch();
  const getUserOrders = useCallback(({ user_id, offset, limit, orders, query, statuses }) => dispatch(getUserOrdersAction({ user_id, offset, limit, orders, query, statuses })), [dispatch]);
  const getOrdersForDashboard = useCallback(({ limit, offset, statuses, with_user, without_user, isCounts, isScheduled }) => dispatch(getOrdersForDashboardAction({ limit, offset, statuses, with_user, without_user, isCounts, isScheduled })), [dispatch]);
  const getOrders = useCallback(({ offset, orders, user_id, query, statuses, limit }) => dispatch(getOrdersAction({ offset, orders, user_id, query, statuses, limit })), [dispatch]);
  const getCustomersOrders = useCallback(({ offset, orders, customer_query, limit }) => dispatch(getCustomersOrdersAction({ offset, orders, customer_query, limit })), [dispatch]);
  const getInvoicesOrders = useCallback(({ offset, orders, self_invoice_query, limit }) => dispatch(getInvoicesOrdersAction({ offset, orders, self_invoice_query, limit })), [dispatch]);
  const getVehiclesOrders = useCallback(({ offset, orders, vehicle_query, limit }) => dispatch(getVehiclesOrdersAction({ offset, orders, vehicle_query, limit })), [dispatch]);
  const getOrder = useCallback(({ id, side, customer_token }) => dispatch(getOrderAction({ id, side, customer_token })), [dispatch]);
  const getOrderForNotification = useCallback(id => dispatch(getOrderForNotificationAction(id)), [dispatch]);
  const createOrder = useCallback(({ order, side, company_id }) => dispatch(createOrderAction({ order, side, company_id })), [dispatch]);
  const updateOrder = useCallback((order) => dispatch(updateOrderAction(order)), [dispatch]);
  const deleteOrder = useCallback(id => dispatch(deleteOrderAction(id)), [dispatch]);
  const supplementOrder = useCallback(({ id, user_id, user, eta, truck_id }) => dispatch(supplementOrderAction({ id, user_id, user, eta, truck_id })), [dispatch]);
  const rejectOrder = useCallback(({ id, reject_description }) => dispatch(rejectOrderAction({ id, reject_description })), [dispatch]);
  const serviceOrder = useCallback(id => dispatch(serviceOrderAction(id)), [dispatch]);
  const startOrderMovement = useCallback(id => dispatch(startOrderMovementAcion(id)), [dispatch]);
  const pickUpOrder = useCallback(id => dispatch(pickUpOrderAction(id)), [dispatch]);
  const cancelOrder = useCallback(({ id, reason }) => dispatch(cancelOrderAction({ id, reason })), [dispatch]);
  const renewOrder = useCallback(id => dispatch(renewOrderAction(id)), [dispatch]);
  const dropOffOrder = useCallback(id => dispatch(dropOffOrderAction(id)), [dispatch]);
  const completeOrder = useCallback(id => dispatch(completeOrderAction(id)), [dispatch]);
  const reverseStatus = useCallback(id => dispatch(reverseStatusAction(id)), [dispatch]);
  const applyCustomerLocation = useCallback(id => dispatch(applyCustomerLocationAction(id)), [dispatch]);
  const rejectCustomerLocation = useCallback(id => dispatch(rejectCustomerLocationAction(id)), [dispatch]);
  const sendOrderTracking = useCallback(({ id, recipient_ids }) => dispatch(sendOrderTrackingAction({ id, recipient_ids })), [dispatch]);
  const changeLocalOrderStatus = useCallback(({ order_id, status, previous_status }) => dispatch(changeLocalOrderStatusAction({ order_id, status, previous_status })), [dispatch]);
  const clearLocalUserOrders = useCallback(() => dispatch(clearLocalUserOrdersAction()), [dispatch]);
  const clearLocalOrdersWithUser = useCallback(() => dispatch(clearLocalOrdersWithUserAction()), [dispatch]);
  const clearLocalOrders = useCallback(() => dispatch(clearLocalOrdersAction()), [dispatch]);
  const clearLocalCustomersOrders = useCallback(() => dispatch(clearLocalCustomersOrdersAction()), [dispatch]);
  const clearLocalInvoicesOrders = useCallback(() => dispatch(clearLocalInvoicesOrdersAction()), [dispatch]);
  const clearLocalVehiclesOrders = useCallback(() => dispatch(clearLocalVehiclesOrdersAction()), [dispatch]);
  const clearLocalActiveOrder = useCallback(() => dispatch(clearLocalActiveOrderAction()), [dispatch]);
  const setLocalOrderFields = useCallback((fields) => dispatch(setLocalOrderFieldsAction(fields)), [dispatch]);
  const getOrdersStatistics = useCallback(({ start_at, end_at }) => dispatch(getOrdersStatisticsAction({ start_at, end_at })), [dispatch]);
  const clearOrdersStatistics = useCallback(() => dispatch(clearOrdersStatisticsAction()), [dispatch]);

  return {
    getUserOrders,
    getOrdersForDashboard,
    getOrders,
    getCustomersOrders,
    getInvoicesOrders,
    getVehiclesOrders,
    getOrder,
    getOrderForNotification,
    createOrder,
    updateOrder,
    deleteOrder,
    supplementOrder,
    rejectOrder,
    cancelOrder,
    renewOrder,
    startOrderMovement,
    pickUpOrder,
    serviceOrder,
    dropOffOrder,
    completeOrder,
    reverseStatus,
    applyCustomerLocation,
    rejectCustomerLocation,
    sendOrderTracking,
    changeLocalOrderStatus,
    clearLocalUserOrders,
    clearLocalOrdersWithUser,
    clearLocalOrders,
    clearLocalCustomersOrders,
    clearLocalInvoicesOrders,
    clearLocalVehiclesOrders,
    clearLocalActiveOrder,
    setLocalOrderFields,
    getOrdersStatistics,
    clearOrdersStatistics
  };
};

export default useOrders;
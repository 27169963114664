import { memo, useEffect, useState } from 'react';
// Local files
import { Root } from './Dashboard.styled';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import Head from 'components/Dashboard/Head/Head';
import Main from 'components/Dashboard/Main/Main';
import Widgets from 'components/Dashboard/Widgets/Widgets';
import useOrders from 'hooks/useOrders';
import useError from 'hooks/useError';
import { orderStatuses } from 'helpers/constants';

const { NEW, COMPANY_ACCEPTED, MANAGER_ACCEPTED, EN_ROUTE, PICK_UP, SERVICING, DROP_OFF, SCHEDULED } = orderStatuses;

const Dashboard = () => {
  const { setError } = useError();
  const { getOrdersForDashboard, clearLocalOrdersWithUser } = useOrders();
  const [readyToShow, setReadyToShow] = useState(false);

  useEffect(() => {
    getOrdersForDashboard({ offset: 0, with_user: true, statuses: [NEW, COMPANY_ACCEPTED, MANAGER_ACCEPTED, EN_ROUTE, PICK_UP, SERVICING, DROP_OFF], isCounts: true })
    .then(() => 
      getOrdersForDashboard({ offset: 0, statuses: [NEW], without_user: true, isCounts: true })
      .then(() => 
        getOrdersForDashboard({ offset: 0, statuses: [SCHEDULED], isScheduled: true, isCounts: true })
        .then(() => setReadyToShow(true))
        .catch((error) => setError(error))
      )
      .catch((error) => setError(error))
    )
    .catch((error) => setError(error));

    return clearLocalOrdersWithUser;
  }, [getOrdersForDashboard, clearLocalOrdersWithUser, setError]);

  return (
    <Root>
      <ComponentErrorWrapper children={<Head />} />
      { readyToShow && <ComponentErrorWrapper children={<Main />} /> }
      { readyToShow && <ComponentErrorWrapper children={<Widgets />} /> }
    </Root>
  );
};

export default memo(Dashboard);
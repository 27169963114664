import { memo, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import _find from 'lodash/find';
//Local files
import { Card, CardHeader, CardTitle, FieldsContainer } from 'components/Common/BasePageUi/BasePageUi';
import AccountsAutocomplete from 'components/Accounts/Autocomplete/Autocomplete';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import useCustomers from 'hooks/useCustomers';
import useError from 'hooks/useError';
import { sides } from 'helpers/constants';

const Form = ({edit, duplicate, customerData, towVehicleCustomerData, processingData, getCustomerData, onChangeProcessingData, sx }) => {
  const { getCustomers, clearLocalCustomers } = useCustomers();
  const { setError } = useError();
  const [account, setAccount] = useState({ value: '', formattedValue: null, error: '' });
  const [customer, setCustomer] = useState({ id: '', name: '', error: '' });
  const [customerPhone, setCustomerPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [customerEmail, setCustomerEmail] = useState({ value: '', error: '' });
  const [callerName, setCallerName] = useState('');
  const [callerPhone, setCallerPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [processing, setProcessing] = useState(false);

  const needToUpdate = (a,b) => 
    a.account.id !== b.account_id || 
    a.customer?.id !== b.customer_id || 
    !!b?.newCustomer || 
    a.caller_name !== b.caller_name || 
    a.caller_phone !== b.caller_phone ||
    (!!a.customer?.id && (a.customer?.name !== b.name || a.customer?.email !== b.email || a.customer?.phone !== b.phone));
  const handleBlur = ({ target: { id, value } }) => {
    if (!!value && (!!!customer.id || 
      (!!customerData?.customer?.id && (customerPhone.value === customerData?.customer?.phone || customerEmail.value === customerData?.customer?.email)))) {
      setProcessing(true);
      getCustomers({ phone_email: customerPhone.value || customerEmail.value, side: sides.USER })
      .then(({ payload: { data: { customers } } }) => {
        const foundedCustomer = _find(customers, (c) => (customerPhone.value === c?.phone || customerEmail.value === c?.email)) || null;
  
        !!foundedCustomer && setCustomer({ id: foundedCustomer?.id || '', name: foundedCustomer?.name || customer.name, error: '' });
        id === 'phone' && setCustomerEmail({ value: foundedCustomer?.email || customerEmail?.value, error: '' });
        id === 'email' && setCustomerPhone({ value: foundedCustomer?.phone || customerPhone?.value, formattedValue: foundedCustomer?.phone || customerPhone?.formattedValue, error: '' });
      })
      .catch((error) => setError(error))
      .finally(() => setProcessing(false))
    }
  };

  useEffect(() => {
    if (processingData) {
      let hasError = false;

      if (!!!account.value) {
        setAccount(prev => ({ ...prev, error: `Account can't be empty` }));
        hasError = true;
      }
      if (!!customer.name && !!!customerPhone.value && !!!customerEmail.value) {
        setCustomerPhone(prev => ({ ...prev, error: `Please fill in the phone or email` }));
        setCustomerEmail(prev => ({ ...prev, error: `Please fill in the phone or email` }));
        hasError = true;
      }
      if ((!!customerPhone.value || !!customerEmail.value) && !!!customer.name) {
        setCustomer(prev => ({ ...prev, error: `Please fill in the name` }));
        hasError = true;
      }
      if (hasError) {
        onChangeProcessingData(false);
        return;
      }
      const processingCustomer = {
        account_id: account.value, 
        ...((!!customer.name || !!customerEmail.value || customerPhone.value) && (!!customer.id ? 
          { ...(customerData?.customer?.id !== customer.id && { customer_id: customer.id }), 
            id: customer.id, 
            name: customer.name, 
            email: customerEmail.value || null, 
            phone: customerPhone.value || null 
          } : { newCustomer: { name: customer.name, email: customerEmail.value || null, phone: customerPhone.value || null } 
        })), 
        caller_name: callerName || null,
        caller_phone: callerPhone.value || null
      };

      getCustomerData(edit ? (needToUpdate(customerData, processingCustomer) ? processingCustomer : {}) : processingCustomer);
    }
  }, [processingData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((edit || duplicate) && !!customerData) {
      setAccount({ value: customerData?.account?.id, formattedValue: customerData?.account, error: '' });
      !!customerData.customer?.id && setCustomer({ id: customerData.customer?.id, name: customerData?.customer?.name, error: '' });
      !!customerData?.customer?.email && setCustomerEmail({ value: customerData?.customer?.email, error: '' });
      !!customerData?.customer?.phone && setCustomerPhone({ value: customerData?.customer?.phone, formattedValue: customerData?.customer?.phone, error: '' });
      !!customerData?.caller_name && setCallerName(customerData.caller_name);
      !!customerData?.caller_phone && setCallerPhone({ value: customerData.caller_phone, formattedValue: customerData.caller_phone, error: '' });
    }
  }, [edit, duplicate, customerData]);
  useEffect(() => {
    if ((!edit && !duplicate) && !!towVehicleCustomerData) {
      setAccount({ value: towVehicleCustomerData?.account?.id, formattedValue: towVehicleCustomerData?.account, error: '' });
      !!towVehicleCustomerData.customer?.id && setCustomer({ id: towVehicleCustomerData.customer?.id, name: towVehicleCustomerData?.customer?.name, error: '' });
      !!towVehicleCustomerData?.customer?.email && setCustomerEmail({ value: towVehicleCustomerData?.customer?.email, error: '' });
      !!towVehicleCustomerData?.customer?.phone && setCustomerPhone({ value: towVehicleCustomerData?.customer?.phone, formattedValue: towVehicleCustomerData?.customer?.phone, error: '' });
      !!towVehicleCustomerData?.caller_name && setCallerName(towVehicleCustomerData.caller_name);
      !!towVehicleCustomerData?.caller_phone && setCallerPhone({ value: towVehicleCustomerData.caller_phone, formattedValue: towVehicleCustomerData.caller_phone, error: '' });
    }
  }, [edit, duplicate, towVehicleCustomerData]);
  useEffect(() => {
    return clearLocalCustomers;
  }, [clearLocalCustomers]);

  return (
    <Card sx={{ gap: '24px', ...sx }}>
      <CardHeader>
        <CardTitle>Customer</CardTitle>
      </CardHeader>
      <FieldsContainer sx={{ pb: '8px' }}>
        <AccountsAutocomplete
          id='accounts'
          value={account.formattedValue}
          error={!!account.error}
          helperText={account.error}
          onChange={({ value, formattedValue, error }) => setAccount({ value, formattedValue, error })}
        />
        <TextField 
          label='Name' 
          value={customer.name}
          error={!!customer.error}
          helperText={customer.error}
          onChange={({ target: { value: name } }) => setCustomer(prev => ({ ...prev, name, error: '' }))} 
          disabled={processing}
        />
        <PhoneField
          id='phone'
          fullWidth
          label='Phone'
          formattedValue={customerPhone.formattedValue}
          error={!!customerPhone.error}
          helperText={customerPhone.error}
          onChange={({ value, formattedValue, error }) => { 
            setCustomerPhone({ value, formattedValue, error });
            setCustomerEmail(prev => ({ ...prev, error: '' }));
          }}
          onBlur={handleBlur}
          disabled={processing}
        />
        <TextField 
          id='email'
          label='Email' 
          value={customerEmail.value} 
          helperText={customerEmail.error} 
          error={!!customerEmail.error} 
          onChange={({ target: { value } }) => { 
            setCustomerEmail({ value, error: '' });
            setCustomerPhone(prev => ({ ...prev, error: '' }));
          }} 
          onBlur={handleBlur}
          disabled={processing}
        />
      </FieldsContainer>
    </Card>
  )
};

export default memo(Form);
import React from 'react';
import { Card, Heading, Description } from './Card.styled';
import Youtube from '../Youtube/Youtube';

const VideoCard = ({ title, id, description }) => {
  return (
    <Card>
      <Youtube title={title} id={id} />
      <Heading>{title}</Heading>  
      <Description>{description}</Description>
    </Card>
  );
};

export default VideoCard;

import { memo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import { reasons } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';

const TowVehicle = (props) => {
  const navigate = useNavigate();
  const { openDrawer } = useApp();
  const { closeTowVehicleDialog } = useDialogs();
  const towVehicle = useCustomSelector(state => state.vehicles.vehicle);
  const { open } = useCustomSelector(state => state.dialogs.towVehicle);

  const handleTowVehicle = () => {
    closeTowVehicleDialog()
    .then(() => openDrawer({ reason: reasons.ORDER, edit: false, towVehicle }).then(() => navigate(`/orders`)));
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeTowVehicleDialog}
      title='Tow Vehicle'
      PaperProps={{ sx: { width: 600,  borderRadius: '16px' } }}
      {...props}
    >
      <DialogContent>
        <Typography variant='body1' align='left' p='16px 0px 8px'>
          Please confirm you'd like to tow this vehicle out. Next, you'll be prompted to fill out job details for the tow.<br/>
          Once created, the job will move to the Active tab of the Dashboard to manage the tow. A separate invoice will be created.
        </Typography>
      </DialogContent>
      <Box
        sx={{
          width: '100%',
          borderTop: '1px solid #F1F0FC',
        }}
      >
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          onClick={closeTowVehicleDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          onClick={handleTowVehicle}
        >
          Tow Vehicle
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(TowVehicle);

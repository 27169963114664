import styled from '@mui/material/styles/styled';

export const Grid = styled('div')(({ theme }) =>({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '2rem',
    marginTop: '3rem',
    padding:" 0 5%",
    [theme.breakpoints.up('xlg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    }
  }));

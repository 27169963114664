import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getJobsRoutine,
  getJobRoutine, 
  createJobRoutine,
  deleteJobRoutine,
  clearLocalJobsRoutine,
  clearLocalActiveJobRoutine 
} from 'actions';

const attributes = [
  'id', 
  { service: ['id', 'name'] }, 
  { variants: 
    ['id', { rates: ['id', { charge: ['id', 'name', 'charge_type', 'mile_type', 'basic_rate_types'] }, 'basic_value_type', 'fee_value_type', 'value'] }, 
    'variant_type', 
    'minimum_amount',
    'mile_rounding_type',
    'time_rounding_type',
    'minimum_duration',
    'en_route_free_miles', 
    'towed_free_miles', 
    'deadhead_free_miles'
    ] 
  }, 
  'job_type'
];

export const getJobs = getThunkActionCreator(
  getJobsRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, job_type, account_id }) => {
    getJobs.getJobsCancel && getJobs.getJobsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/accounts/${account_id}/jobs`,
      {
        params: { access_token, limit: 25, offset, orders, job_type, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getJobs.getJobsCancel = cancel })
      }
    );
  }
);
export const getJob = getThunkActionCreator(
  getJobRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/jobs/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getJob.getJobCancel = cancel })
      }
    );
  }
);
export const createJob = getThunkActionCreator(
  createJobRoutine,
  async ({ job, account_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/accounts/${account_id}/jobs`, { access_token, job, attributes });
  }
);
export const deleteJob = getThunkActionCreator(
  deleteJobRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/jobs/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalJobs = getThunkActionCreator(clearLocalJobsRoutine, async () => {});
export const clearLocalActiveJob = getThunkActionCreator(clearLocalActiveJobRoutine, async () => {});
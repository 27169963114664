import { memo, useState } from 'react';
import Popover from '@mui/material/Popover';
import { useParams } from 'react-router-dom';
import _startCase from 'lodash/startCase';
// Local files
import ServicesAutocomplete from 'components/Services/Autocomplete/Autocomplete';
import useJobs from 'hooks/useJobs';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useApp from 'hooks/useApp';
import { serviceTypes } from 'helpers/constants';

const ServicesList = ({ serviceType, anchorEl, onClose }) => {
  const { id: account_id } = useParams();
  const { createJob } = useJobs();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { setAccountServiceData } = useApp();
  const [processing, setProcessing] = useState(false);

  const handleJobCreate = (service_id) => {
    setProcessing(true);
    createJob({ account_id, job: { service_id, job_type: serviceType } })
    .then(({ payload: { data: { job: { variants } } } }) => 
      setAccountServiceData({ activeVariant: variants[0] })
      .then(() => setSuccess('Service successfully created').then(onClose))
    )
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };

  return (
    <Popover
      id='services-list'
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{ sx: { width: 200, maxHeight: 300, pt: 1 } }}
    >
      <ServicesAutocomplete 
        account
        id='service'
        type={serviceType}
        label={serviceType === serviceTypes.BASIC ? 'Service' : _startCase(serviceTypes.ADDITIONAL)}
        onClick={(id) => !processing && handleJobCreate(id)}
      />
    </Popover>
  );
};

export default memo(ServicesList);
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getVehicles as getVehiclesAction,
  getVehicle as getVehicleAction,
  createVehicle as createVehicleAction,
  updateVehicle as updateVehicleAction,
  storeVehicle as storeVehicleAction,
  takeOutVehicle as takeOutVehicleAction,
  releaseVehicle as releaseVehicleAction,
  clearLocalVehicles as clearLocalVehiclesAction,
  clearLocalActiveVehicle as clearLocalActiveVehicleAction,
  getFromVinVehicleInfo as getFromVinVehicleInfoAction,
  getFromPlateVehicleInfo as getFromPlateVehicleInfoAction,
  getVehiclesStatistics as getVehiclesStatisticsAction,
  clearVehiclesStatistics as clearVehiclesStatisticsAction
} from 'actions/vehicles';

const useVehicles = () => {
  const dispatch = useDispatch();
  const getVehicles = useCallback(({ offset, orders, query, statuses }) => dispatch(getVehiclesAction({ offset, orders, query, statuses })), [dispatch]);
  const getVehicle = useCallback(id => dispatch(getVehicleAction(id)), [dispatch]);
  const createVehicle = useCallback(vehicle => dispatch(createVehicleAction(vehicle)), [dispatch]);
  const updateVehicle = useCallback(vehicle => dispatch(updateVehicleAction(vehicle)), [dispatch]);
  const storeVehicle = useCallback(id => dispatch(storeVehicleAction(id)), [dispatch]);
  const takeOutVehicle = useCallback(id => dispatch(takeOutVehicleAction(id)), [dispatch]);
  const releaseVehicle = useCallback(receiver => dispatch(releaseVehicleAction(receiver)), [dispatch]);
  const clearLocalVehicles = useCallback(() => dispatch(clearLocalVehiclesAction()), [dispatch]);
  const clearLocalActiveVehicle = useCallback(() => dispatch(clearLocalActiveVehicleAction()), [dispatch]);
  const getFromVinVehicleInfo = useCallback(vin => dispatch(getFromVinVehicleInfoAction(vin)), [dispatch]);
  const getFromPlateVehicleInfo = useCallback(({ plate, state }) => dispatch(getFromPlateVehicleInfoAction({ plate, state })), [dispatch]);
  const getVehiclesStatistics = useCallback(() => dispatch(getVehiclesStatisticsAction()), [dispatch]);
  const clearVehiclesStatistics = useCallback(() => dispatch(clearVehiclesStatisticsAction()), [dispatch]);

  return {
    getVehicles,
    getVehicle,
    createVehicle,
    updateVehicle,
    storeVehicle,
    takeOutVehicle,
    releaseVehicle,
    clearLocalVehicles,
    clearLocalActiveVehicle,
    getFromVinVehicleInfo,
    getFromPlateVehicleInfo,
    getVehiclesStatistics,
    clearVehiclesStatistics
  };
};

export default useVehicles;
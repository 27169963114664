import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';

const DocumentsSkeleton = () => { 
  return (
    <>
      <Skeleton variant='rectangular' width={260} height={120} /> 
      <Skeleton variant='rectangular' width={260} height={120} /> 
    </>
  );
};

export default memo(DocumentsSkeleton);
import { orderSources } from "helpers/constants";

const { DIGITAL_DISPATCH, URGENTLY, WEB, WIDGET } = orderSources;

const useOrderSources = (source) => {
  const isISSC = source === DIGITAL_DISPATCH;
  const isUrgently = source === URGENTLY;
  const isWeb = source === WEB;
  const isWidget = source === WIDGET;
  const isWebOrWidget = source === WEB || source === WIDGET;

  return { isISSC, isUrgently, isWeb, isWidget, isWebOrWidget };
};

export default useOrderSources;
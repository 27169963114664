import {
  getAddRoutine,
  clearLocalActiveAddRoutine
} from 'actions';

export const initialState = { add: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getAddRoutine.SUCCESS: {
      const { data: { add } } = action.payload;

      return { ...state, add };
    }
    case clearLocalActiveAddRoutine.SUCCESS: {
      return { ...state, add: null };
    }
    default: {
      return state;
    }
  };
}

export default reducer;
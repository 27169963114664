import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getJobs as getJobsAction,
  getJob as getJobAction,
  createJob as createJobAction,
  deleteJob as deleteJobAction,
  clearLocalJobs as clearLocalJobsAction,
  clearLocalActiveJob as clearLocalActiveJobAction
} from 'actions/jobs';

const useJobs = () => {
  const dispatch = useDispatch();
  const getJobs = useCallback(({ offset, job_type, account_id }) => dispatch(getJobsAction({ offset, job_type, account_id })), [dispatch]);
  const getJob = useCallback(id => dispatch(getJobAction(id)), [dispatch]);
  const createJob = useCallback(({ job, account_id }) => dispatch(createJobAction({ job, account_id })), [dispatch]);
  const deleteJob = useCallback(id => dispatch(deleteJobAction(id)), [dispatch]);
  const clearLocalJobs = useCallback(() => dispatch(clearLocalJobsAction()), [dispatch]);
  const clearLocalActiveJob = useCallback(() => dispatch(clearLocalActiveJobAction()), [dispatch]);

  return {
    getJobs,
    getJob,
    createJob,
    deleteJob,
    clearLocalJobs,
    clearLocalActiveJob
  };
};

export default useJobs;
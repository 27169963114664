import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { createCompanyStripeUrlRoutine, createStripeBillingPortalRoutine, createStripeCheckoutRoutine } from 'actions';

export const createCompanyStripeUrl = getThunkActionCreator(
  createCompanyStripeUrlRoutine,
  async (company_id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/companies/${company_id}/stripe_account_links`, { access_token });
  }
);
export const createStripeBillingPortal = getThunkActionCreator(
  createStripeBillingPortalRoutine,
  async () => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/stripe_billing_portal_sessions`, { access_token });
  }
);
export const createStripeCheckout = getThunkActionCreator(
  createStripeCheckoutRoutine,
  async (company_id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/companies/${company_id}/stripe_checkout_sessions`, { access_token });
  }
);
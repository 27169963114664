import { memo, useRef, useState } from 'react';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import _toUpper from 'lodash/toUpper';
// Local files
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';

const Input = styled('input')({ display: 'none' });

const UserAvatar = ({ src, firstName, lastName, edit = false, disabled, onChange }) => {
  const inputRef = useRef(null);
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const [processing, setProcessing] = useState(false);

  const handleAvatarClick = () => inputRef.current.click();
  const handleFileChange = ({ target: { files } }) => {
    setProcessing(true);
    processFiles(files, ({ file, checksum, localUrl }) => 
      processBlob({ file, checksum }, blob => { 
        onChange({ value: blob.id, formattedValue: localUrl });
        setProcessing(false);
      })
    );
  };
  const handleDeleteFile = () => onChange({ value: '', formattedValue: '' })

  if (edit) {
    return (
      <Box display='flex' justifyContent='flex-start' mb={.5}>
        {processing ? (
          <Box sx={{ width: 116, height: 116, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'action.active' }}>
            <CircularProgress color='inherit' size={100} />
          </Box>
        ) : (
          <>
            <Input
              ref={inputRef}
              accept="image/*"
              id="driver-avatar"
              type="file"
              onChange={handleFileChange}
            />
            <IconButton
              sx={{ width: 116, height: 116 }}
              disabled={disabled || processing}
              onClick={handleAvatarClick}
            >
              <Avatar
                src={src}
                alt={`${firstName ?? ''}${lastName ?? ''}`}
                sx={{ width: 100, height: 100, backgroundColor: 'action.active', fontSize: '48px' }}
              >
                {_toUpper(firstName[0]) ?? ''}{_toUpper(lastName[0]) ?? ''}
              </Avatar>
            </IconButton>
          </>
        )}
        <Box sx={{ pt: 1, pl: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography variant='body2' component={'div'}>Upload new avatar</Typography>
          <Button
            variant='outlined'
            size='small'
            sx={{ mt: .75, mb: 2,  textTransform: 'none' }}
            disabled={disabled || processing}
            onClick={handleAvatarClick}
          >
            Choose file
          </Button>
          {!!src && !processing && (
            <Typography variant='caption' component={'div'} sx={{ display: 'flex', alignItems: 'baseline' }}>
              You can&nbsp;
              <Button
                color='error'
                disabled={disabled || processing}
                onClick={handleDeleteFile}
                sx={{
                  textTransform: 'none', fontSize: 'inherit', lineHeight: 'inherit', minWidth: 'unset', userSelect: 'text', p: 0,
                  '&:hover': { bgcolor: 'transparent', textDecoration: 'underline' }
                }}
              >
                remove
              </Button>
              &nbsp;photo
            </Typography>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Avatar
      alt={`${firstName ?? ''}${lastName ?? ''}`}
      sx={{ width: 100, height: 100 }}
    >
      {_toUpper(firstName[0]) ?? ''}{_toUpper(lastName[0]) ?? ''}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default memo(UserAvatar);
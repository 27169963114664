import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _pick from 'lodash/pick';
import Box from '@mui/material/Box';
// Local Files
import { CardsGrid, DetailsContainer, DetailsWrapper } from 'components/Common/BasePageUi/BasePageUi';
import CustomerCard from 'components/Customers/Card/Card';
import VehicleCard from 'components/Vehicles/Cards/Card/Card';
import DriverCard from 'components/Driver/Card/Card';
import PaymentCard from 'components/Payments/Card/Card';
import PaymentDrawer from 'components/Payments/Drawer/Drawer';
import MapCard from 'components/Map/Card/Card';
import NotesCard from 'components/Notes/Card/Card';
import DocumentsCard from 'components/Documents/Card/Card';
import ReleasedCard from '../Cards/ReleasedCard/ReleasedCard';
import Info from 'components/Orders/Info/Info';
import Main from '../Cards/Main/Main';
import AddInfo from '../AddInfo/AddInfo';
import SendInvoice from 'components/Invoices/Drawers/SendInvoice/SendInvoice';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import Placeholder from 'components/Common/Placeholder/Placeholder';
import useCustomSelector from 'hooks/useCustomSelector';
import useVehicles from 'hooks/useVehicles';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';
import useAdds from 'hooks/useAdds';
import { models, invoiceStatuses, vehicleStatuses } from 'helpers/constants';

const Details = () => {
  const { id } = useParams();
  const { setFetchingDetails } = useApp();
  const { getAdd } = useAdds();
  const { setError } = useError();
  const { getVehicle } = useVehicles();
  const { id: vehicle_id, order, receiver, vin, make, model, year, color, drive_type, license, license_state, status, storage_duration, odometer, stored_at } = useCustomSelector(state => state.vehicles.vehicle);
  const { isFetching, add, isHasAddToken, isShowAddInfo } = useCustomSelector(state => ({
    isFetching: state.app.isFetching,
    add: state.adds.add,
    isHasAddToken: state.vehicles.vehicle.order?.company_location?.company?.add_access_token_available,
    isShowAddInfo: !!state.vehicles.vehicle.vin || !!state.vehicles.vehicle.license
  }));
  const isEditable = order?.invoice?.status === invoiceStatuses.NOT_SUBMITTED || order?.invoice?.status === invoiceStatuses.NEW;
  const mainData = { ..._pick(order.invoice, ['id', 'file', 'expenses', 'payments', 'status', 'total_amount', 'paid_amount', 'created_at']), order, vehicle_status: status, storage_duration, stored_at };
  const customerData = _pick(order, ['customer', 'account', 'caller_name', 'caller_phone', 'member_first_name', 'member_last_name', 'member_phone']);
  const vehicleData = { ..._pick(order, ['keys_availability', 'driver_attendance']), vehicle: { vin, make, model, year, color, drive_type, license, license_state, odometer } };
  const driverData = _pick(order, ['user', 'dispatcher', 'truck', 'incident_location', 'status', 'eta', 'source']);
  const mapData = _pick(order, ['incident_location', 'destination_location', 'status']);
  const notesData = { ..._pick(order, ['internal_notes', 'dispatcher_notes']), order_id: order.id, invoice_notes: order.invoice.notes, invoice_id: order.invoice.id , isEditable };
  const paymentsData = { ..._pick(order.invoice, ['id', 'payments', 'status', 'paid_amount', 'total_amount']), ..._pick(order, ['account', 'customer', 'company_location', 'authorization_number']) };
  const sendInvoiceData = { ..._pick(order.invoice, ['id', 'number', 'recipients', 'total_amount', 'payments', 'created_at']), order };
  const documentsData = { id: order.id, model: models.ORDERS };
  const infoData = _pick(order, ['id', 'company_location', 'digital_dispatch_webhook']);
  const releasedData = _pick(receiver, ['id', 'name', 'email', 'phone', 'driver_license', 'license_state', 'address']);
  const showReleaseCard = status === vehicleStatuses.RELEASED;

  useEffect(() => {
    if (!!id) {
      if (id !== vehicle_id) {
        setFetchingDetails(true);
        getVehicle(id)
        .catch(error => setError(error))
        .finally(() => setFetchingDetails(false))
      }
    }
  }, [id, vehicle_id, getVehicle, setFetchingDetails, getAdd, setError]);

  if (!id) return <Placeholder model={models.VEHICLES} />;
  if (!!!vehicle_id || isFetching) return <PageLoader />;
  return (
    <DetailsContainer>
      <DetailsWrapper>
        <Main {...mainData} />
        <CardsGrid>
          <CustomerCard sx={{ gridArea: { lg: '1 / 1 / 2 / 2' }, maxWidth: 'unset !important' }} {...customerData} />
          <VehicleCard sx={{ gridArea: { lg: '1 / 2 / 2 / 3' }, maxWidth: 'unset !important' }} {...vehicleData} />
          <PaymentCard sx={{ gridArea: { lg: '2 / 1 / 3 / 2', xlg: '1 / 3 / 2 / 4' }, maxWidth: 'unset !important' }} {..._pick(paymentsData, ['payments', 'status', 'company_location'])} />
          <DriverCard sx={{ gridArea: { lg: '2 / 2 / 3 / 3', xlg: '2 / 1 / 3 / 2' }, maxWidth: 'unset !important' }} {...driverData} />
          <NotesCard sx={{ gridArea: { lg: '3 / 1 / 4 / 2', xlg: '3 / 1 / 4 / 2' }, maxWidth: 'unset !important' }} edit {...notesData} />
          <MapCard sx={{ gridArea: { lg: '3 / 2 / 4 / 3', xlg: '2 / 2 / 4 / 4' }, width: '100% !important' }} {...mapData} />  
          <Box sx={{ display: 'grid', gap: '30px', gridArea: { lg: '4 / 1 / 4 / 3', xlg: '4 / 1 / 4 / 4' }, width: '100% !important'}}>
            {showReleaseCard && <ReleasedCard sx={{ width: '100% !important' }} {...releasedData}/>}
            <DocumentsCard sx={{ width: '100% !important' }} {...documentsData} />
            <Info sx={{  width: '100% !important', overflow: 'hidden' }} {...infoData} />
            {isShowAddInfo && <AddInfo sx={{  width: '100% !important', overflow: 'hidden' }} {...add} {...{order, vehicle_id: id, isHasAddToken}}/>}
        </Box>    
        </CardsGrid>
        
      </DetailsWrapper>
      <PaymentDrawer {...paymentsData} />
      <SendInvoice {...sendInvoiceData} />
    </DetailsContainer>
  );
};

export default memo(Details);


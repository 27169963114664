import { memo, useState, useEffect, useCallback} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import _toLower from "lodash/toLower";
import _trim from "lodash/trim";
import { useNavigate } from "react-router-dom";
// Local files
import { ReactComponent as AppleStore } from "assets/icons/apple-store-badge.svg";
import { ReactComponent as GooglePlay } from "assets/icons/google-play-badge.svg";
import { validateField, validateEmail } from "helpers";
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useUsers from 'hooks/useUsers';
import useSessions from "hooks/useSessions";
import PhoneField from 'components/Common/PhoneField/PhoneField';
import BaseAddressAutocomplete from "components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete";
import PasswordField from "../Fields/passwordField"
import { filterTimezone } from "helpers/constants";

const Form = () => {
  const navigate = useNavigate();
  const { createSession } = useSessions();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { signUpUser } = useUsers();
  const [processign, setProcessing] = useState(false);
  const mediaDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [firstName, setFirstName] = useState({ value: "", error: "" });
  const [lastName, setLastName] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: "", error: "" });
  const [companyName, setCompanyName] = useState({ value: "", error: "" });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [address, setAddress] = useState({ value: '', error: '', latitude: 0, longitude: 0, draggedMarker: '',  timezone: '',});

  const updateField = (field, value) => {
    const error = value.length > 1 ? '' : `${field} must be at least 2 characters`;
    switch (field) {
      case 'First name':
        setFirstName({ value, error });
        break;
      case 'Last name':
        setLastName({ value, error });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchTimezone = async () => {
      try {
        const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const response = await fetch(
          `https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${address.longitude},${address.latitude}.json?access_token=${mapboxAccessToken}`
        );
        const data = await response.json();
        const userTimezone = data.features[0]?.properties?.TZID || 'Timezone undefined';
        setAddress((prevAddress) => ({ ...prevAddress, timezone: userTimezone }));
      } catch (error) {
        console.error('Error fetching timezone information:', error);
        setAddress((prevAddress) => ({ ...prevAddress, timezone: 'Timezone undefined' }));
      }
    };

    if (address.latitude !== 0 || address.longitude !== 0) {
      fetchTimezone();
    }
  }, [address.latitude, address.longitude]);
  const createUser = useCallback((user) => {
    const session = {
      email: _trim(_toLower(user.email)),
      password: user.password,
    };
    setProcessing(true);
    signUpUser(user)
    .then(() => createSession(session)
      .then(() => setSuccess('User successfully created')
      .then(() => navigate("/dashboard"))))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false));
  }, [signUpUser, createSession, navigate, setSuccess, setError, setProcessing]); 
  const handleSubmitClick = () => {
    let hasError = false;

    if (firstName.value.length < 2) { setFirstName({ ...firstName, error: "First name must be at least 2 characters" }); hasError = true; }
    if (lastName.value.length < 2) { setLastName({ ...lastName, error: "Last name must be at least 2 characters" }); hasError = true; }
    hasError = validateEmail(email.value, setEmail) || hasError;
    hasError = validateField(password.value, setPassword, "Password can't be empty") || hasError;
    hasError = validateField(confirmPassword.value, setConfirmPassword, "Confirm Password can't be empty") || hasError;
    if (confirmPassword.value !== password.value) { hasError = true }
    hasError = validateField(address.value, setAddress, "Company HQ Address can't be empty") || hasError;
    hasError = validateField(companyName.value, setCompanyName, "Company name can't be empty") || hasError;
    hasError = validateField(phone.value, setPhone, "Phone number can't be empty") || hasError;
   
    if (hasError) return;

    const user = {
      companies_attributes: [
        {
          locations_attributes: [
            {
              name: "Default",
              address: address.value,
            },
          ],
          name: companyName.value,
          time_zone: filterTimezone[address.timezone] || 'US/Pacific',
        },
      ],
      email: email.value,
      password: password.value,
      first_name: firstName.value,
      last_name: lastName.value,
      phone: phone.formattedValue
    };
    createUser(user);
  };

  const handleKeyDown = ({ key }) => key === "Enter" && handleSubmitClick();

  return (
    <Box sx={{ width: "100%" }}>
       <TextField
        fullWidth
        margin="dense"
        placeholder="First name"
        type="text"
        value={firstName.value}
        onChange={({ target: { value } }) => updateField('First name', value)}
        error={!!firstName.error}
        helperText={firstName.error}
        onKeyDown={handleKeyDown}
      />
      <TextField
        fullWidth
        margin="dense"
        placeholder="Last name"
        type="text"
        value={lastName.value}
        onChange={({ target: { value } }) => updateField('Last name', value)}
        error={!!lastName.error}
        helperText={lastName.error}
        onKeyDown={handleKeyDown}
      />
      <TextField
        fullWidth
        margin="dense"
        placeholder="Enter email"
        type="email"
        value={email.value}
        onChange={({ target: { value } }) => setEmail({ value, error: "" })}
        error={!!email.error}
        helperText={email.error}
        onKeyDown={handleKeyDown}
      />
      <PasswordField 
        password={password}
        confirmPassword={confirmPassword}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
      />
      <TextField
        fullWidth
        margin="dense"
        placeholder="Company name"
        type="text"
        value={companyName.value}
        onChange={({ target: { value } }) =>
          setCompanyName({ value, error: "" })
        }
        error={!!companyName.error}
        helperText={companyName.error}
        onKeyDown={handleKeyDown}
      />
      <BaseAddressAutocomplete 
            margin="dense"
            widget ={true}
            placeholder="Company HQ address" 
            value={address.value}
            draggedMarker={address.draggedMarker}
            error={!!address.error}
            helperText={address.error}
            onChange={({ value, error = '', draggedMarker = '', latitude = 0, longitude = 0 }) => {
              setAddress({ value, error, draggedMarker, latitude, longitude, timezone: '' });
            }}/>
      <PhoneField
            fullWidth
            margin="dense"
            placeholder="Phone number"
            formattedValue={phone.formattedValue}
            error={!!phone.error}
            helperText={phone.error}
            onChange={({ value, formattedValue, error }) => setPhone({ value, formattedValue, error })}
            onKeyDown={handleKeyDown}
      />
      <Button
        sx={{ mt: 2, mb: 1 }}
        fullWidth
        size="xlarge"
        variant="contained"
        disabled={processign}
        onClick={handleSubmitClick}
      >
        Create account
      </Button>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <IconButton
          href="https://play.google.com/store/apps/details?id=com.roadsider.app&hl=en_US&gl=US"
          target="_blank"
        >
          <GooglePlay
            style={{ height: mediaDesktop ? 54 : 42, width: "auto" }}
          />
        </IconButton>
        <IconButton
          href="https://apps.apple.com/us/app/roadsider-tools/id1564257240"
          target="_blank"
        >
          <AppleStore
            style={{ height: mediaDesktop ? 54 : 42, width: "auto" }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(Form);

import { memo, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import _toLower from "lodash/toLower";
import _trim from "lodash/trim";
import { useNavigate, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import BaseLink from "@mui/material/Link";
// Local files
import Actions from "../Actions/Actions";
import { ReactComponent as AppleStore } from "assets/icons/apple-store-badge.svg";
import { ReactComponent as GooglePlay } from "assets/icons/google-play-badge.svg";
import { checkValidEmail } from "helpers";
import useError from "hooks/useError";
import useSessions from "hooks/useSessions";

const Form = () => {
  const navigate = useNavigate();
  const { createSession } = useSessions();
  const { setError } = useError();
  const mediaDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [showPasswordSymbols, setShowPasswordSymbols] = useState(false);
  const [processign, setProcessing] = useState(false);

  const handleSubmitClick = () => {
    let hasError = false;

    if (!checkValidEmail(email.value)) {
      setEmail((prev) => ({ ...prev, error: "Email is invalid" }));
      hasError = true;
    }
    if (!password.value) {
      setPassword((prev) => ({ ...prev, error: "Password can't be empty" }));
      hasError = true;
    }
    if (hasError) return;

    const session = {
      email: _trim(_toLower(email.value)),
      password: password.value,
    };

    setProcessing(true);
    createSession(session)
      .then(() => navigate("/"))
      .catch((error) => setError(error).then(() => setProcessing(false)));
  };

  const handleKeyDown = ({ key }) => key === "Enter" && handleSubmitClick();

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        fullWidth
        margin="normal"
        placeholder="Enter email"
        type="email"
        value={email.value}
        onChange={({ target: { value } }) => setEmail({ value, error: "" })}
        error={!!email.error}
        helperText={email.error}
        onKeyDown={handleKeyDown}
      />
      <TextField
        fullWidth
        margin="dense"
        placeholder="Enter password"
        type={showPasswordSymbols ? "text" : "password"}
        value={password.value}
        onChange={({ target: { value } }) => setPassword({ value, error: "" })}
        error={!!password.error}
        helperText={password.error}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="show password"
              color="primary"
              disableRipple
              onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
              sx={{
                position: "absolute",
                right: "8px",
              }}
            >
              {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
          sx: {
            pr: 0,
            "& input": { pr: "48px" },
          },
        }}
      />
      <Actions />
      <Button
        sx={{ mt: 2, mb: 1 }}
        fullWidth
        size="xlarge"
        variant="contained"
        disabled={processign}
        onClick={handleSubmitClick}
      >
        Sign In
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          pt: 1,
          pb: 1,
        }}
      >
        <Typography color="textSecondary" variant="body2">
          Don't have an account yet?
        </Typography>
        <BaseLink
          component={Link}
          to="/sign_up"
          underline="hover"
          variant="subtitle2"
        >
          Join Roadsider
        </BaseLink>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <IconButton
          href="https://play.google.com/store/apps/details?id=com.roadsider.app&hl=en_US&gl=US"
          target="_blank"
        >
          <GooglePlay
            style={{ height: mediaDesktop ? 54 : 42, width: "auto" }}
          />
        </IconButton>
        <IconButton
          href="https://apps.apple.com/us/app/roadsider-tools/id1564257240"
          target="_blank"
        >
          <AppleStore
            style={{ height: mediaDesktop ? 54 : 42, width: "auto" }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(Form);

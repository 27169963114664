import { memo } from 'react';
import Button from '@mui/material/Button';
import _find from 'lodash/find';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText, } from 'components/Common/BasePageUi/BasePageUi';
import useApp from 'hooks/useApp';
import { reasons, invoiceStatuses, paymentTypes, paymentMethods } from 'helpers/constants';
import useRole from 'hooks/useRole';
import useTimezones from 'hooks/useTimezones';

const PaymentCard = ({ payments = [], status = null, company_location, sx }) => {
  const { openDrawer } = useApp();
  const { isManager, isOwner } = useRole();
  const { timeZone } = useTimezones(company_location?.company?.id);
  const type = (payments[payments?.length - 1]?.payment_type === paymentMethods.CASH || payments[payments?.length - 1]?.payment_type === paymentMethods.CHECK) ? 
    { label: 'Date', value: new Date(payments[payments?.length - 1]?.created_at)?.toLocaleDateString('en-US', { timeZone }) || '-' } : 
    payments[payments?.length - 1]?.payment_type === paymentMethods.CARD ?
    { label: 'Card Number', value: `**** **** ${payments[payments?.length - 1]?.card?.last4}` } : payments[payments?.length - 1]?.payment_type === paymentMethods.PO ?
    { label: 'PO Number', value: payments[payments?.length - 1]?.authorization_number } : { label: 'No Title', value: '-' };
  const showViewMore = (isManager || isOwner) && (status === invoiceStatuses.APPROVED || status === invoiceStatuses.PARTIALLY_PAID || status === invoiceStatuses.PAID);

  return (
    <Card size='small' sx={sx}>
      <CardHeader>
        <CardTitle>Payment</CardTitle>
        { showViewMore && <Button onClick={() => openDrawer({ reason: reasons.PAYMENT })}>View More</Button> }
      </CardHeader>
      <ItemsContainer>
        <Item halfWidth>
          <ItemLabel>Type</ItemLabel>
          <ItemText>{ _find(paymentTypes, ({ value }) => value === payments[payments?.length - 1]?.payment_type)?.label || '-' }</ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Name</ItemLabel>
          <ItemText>{payments[payments?.length - 1]?.payer || '-'}</ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>{type?.label}</ItemLabel>
          <ItemText sx={{ wordBreak: 'keep-all'}}>{type?.value}</ItemText>
        </Item>
        <Item halfWidth>
          <ItemLabel>Amount</ItemLabel>
          <ItemText>${payments[payments?.length - 1]?.amount || '-'}</ItemText>
        </Item>
      </ItemsContainer>
    </Card>
  );
};

export default memo(PaymentCard);
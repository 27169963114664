import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import _omit from 'lodash/omit';
import { 
  getListLocationsRoutine, 
  getLocationsRoutine,
  getLocationRoutine,
  createLocationRoutine,
  updateLocationRoutine,
  deleteLocationRoutine,
  clearLocalListLocationsRoutine,
  clearLocalLocationsRoutine,
  clearLocalActiveLocationRoutine
} from 'actions';

const shortAttributes = ['id', 'name', { company: ['id', 'name'] }];
const customerAttributes = ['id', 'address', 'latitude', 'longitude'];
const attributes = [
  ...shortAttributes,
  'email', 'phone', 'address', 'latitude', 'longitude', 'created_at'
];

export const getListLocations = getThunkActionCreator(
  getListLocationsRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, query = null, with_company = true }) => {
    getListLocations.getListLocationsCancel && getListLocations.getListLocationsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/locations`,
      {
        params: { access_token, limit: 25, offset, orders, ...(!!query && { query }), with_company, attributes: shortAttributes },
        cancelToken: new CancelToken(function executor(cancel) { getListLocations.getListLocationsCancel = cancel })
      }
    );
  }
);
export const getLocations = getThunkActionCreator(
  getLocationsRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' } }) => {
    getLocations.getLocationsCancel && getLocations.getLocationsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/locations`, {
      params: { access_token, limit, offset, orders, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getLocations.getLocationsCancel = cancel })
    });
  }
);
export const getLocation = getThunkActionCreator(
  getLocationRoutine,
  async (id) => {
    getLocation.getLocationCancel && getLocation.getLocationCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/locations/${id}`, {
      params: { access_token, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getLocation.getLocationCancel = cancel })
    });
  }
);
export const createLocation = getThunkActionCreator(
  createLocationRoutine,
  async ({ location, side }) => {
    const customer_token = localStorage.getItem('customer_token');
    let access_token = null;

    if (!!!customer_token) {
      access_token = await sessionService.loadSession();
    }

    return await axios.post(`/${side}/locations`, { 
      ...(!!access_token ? { access_token: access_token?.token } : { customer_token }), 
      attributes: !!access_token ? attributes : customerAttributes,
      location
    });
  }
);
export const updateLocation = getThunkActionCreator(
  updateLocationRoutine,
  async ({ id, side, ...fields }) => {
    let access_token = null;
    const customer_token = localStorage.getItem('customer_token');
    
    if (!!!customer_token) {
      access_token = await sessionService.loadSession();
    }

    const response = await axios.patch(`/${side}/locations/${id}`, { 
      ...(!!access_token ? { access_token: access_token?.token } : { customer_token }),
      location: _omit(fields, ['side']) 
    });

    return { response, location: { id, ..._omit(fields, ['side']) } };
  }
);
export const deleteLocation = getThunkActionCreator(
  deleteLocationRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/locations/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalListLocations = getThunkActionCreator(clearLocalListLocationsRoutine, async () => {});
export const clearLocalLocations = getThunkActionCreator(clearLocalLocationsRoutine, async () => {});
export const clearLocalActiveLocation = getThunkActionCreator(clearLocalActiveLocationRoutine, async () => {});
import { memo } from 'react';
// Local files
import BaseItem from '../BaseItem/BaseItem';
import { StyledLink } from 'components/Common/BasePageUi/BasePageUi';
import { getFormattedPhone } from 'helpers';

const Location = ({ name, email, phone, address }) => {
  return (
    <>
      <BaseItem title='Name' content={name} />
      <BaseItem title='Address' content={address} />
      <BaseItem title='Email' content={<StyledLink href={`mailto:${email}`}>{email ?? '-'}</StyledLink>} />
      <BaseItem title='Phone' content={<StyledLink href={`tel:${phone}`}>{getFormattedPhone(phone) ?? '-'}</StyledLink>} />
    </>
  );
};

export default memo(Location);
import styled from '@mui/material/styles/styled';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const searchFieldSx = {
  flexBasis: '180px',
  '& > div': {
    borderRadius: '12px',
    height: '40px',
  }
}

export const PopperPaper = styled(Paper)(({ theme }) => ({
  width: 'min(100%, 400px)',
  maxHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    minHeight: '300px'
  }
}));

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.tertiary.contrastText,
  padding: '6px 4px',
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  gap: '4px',
  backgroundColor: theme.palette.tertiary.contrastText,
  borderRadius: '8px',
  padding: '4px',
  '& .MuiToggleButtonGroup-grouped': {
    color: theme.palette.common.white,
    fontSize: '0.75rem',
    textTransform: 'capitalize',
    borderRadius: '6px',
    border: 'unset',
    padding: '6px 8px',
    margin: 0,
    '& > span': {
      marginLeft: '.3rem'
    },
    '& > .MuiTouchRipple-root': {
      marginLeft: 'unset'
    },
    '&.Mui-disabled': {
      border: 0
    },
    '&:hover': {
      boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, .5)`
    },
    '&.Mui-selected': {
      color: theme.palette.tertiary.contrastText,
      backgroundColor: theme.palette.tertiary.main,
      '&:hover': {
        backgroundColor: theme.palette.tertiary.main,
      }
    },
    '&:not(:first-of-type)': {
      borderRadius: '6px',
    },
    '&:first-of-type': {
      borderRadius: '6px',
    }
  }
}));

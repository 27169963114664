import { memo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NextIcon from '@mui/icons-material/NavigateNextRounded';
import { NavLink } from 'react-router-dom';
import _find from 'lodash/find';
// Local files
import { orderStatuses, orderStatusButtonText, reasons } from 'helpers/constants';
import { START_ORDER_MOVEMENT, PICK_UP_ORDER, SERVICE_ORDER, DPOP_OFF_ORDER, COMPLETE_ORDER } from 'helpers/confirmations';
import Status from 'components/Orders/Status/Status';
import useDialogs from 'hooks/useDialogs';

const { NEW, COMPANY_ACCEPTED, CANCELED, EN_ROUTE, PICK_UP, SERVICING, DROP_OFF, COMPLETED, SCHEDULED } = orderStatuses;

const OrderInfo = ({ id, incident_location, number, dispatcher, company, user, eta, status }) => {
  const { openAssignDialog, openConfirmationDialog, openRejectOrderDialog } = useDialogs();

  const handleAssignClick = () => openAssignDialog({ order: { id, ...(eta && { eta }), status }, isISSC: !!!dispatcher });
  const handleRejectClick = () => openRejectOrderDialog(id);
  const handleChangeStatusClick = () => {
    let content = '';
    let type = '';
    const reason = reasons.ORDER;

    if (!!_find([NEW, COMPANY_ACCEPTED, COMPLETED, CANCELED, SCHEDULED], (s) => s === status)) {
      // кнопка Start; делаем статус en_route
      content = 'Are you sure you want to start this order?';
      type = START_ORDER_MOVEMENT;
    }
    if (status === EN_ROUTE) {
      // кнопка Pick up; делаем статус pick_up
      content = 'Are you sure you want to pick up this order?';
      type = PICK_UP_ORDER;
    }
    if (status === PICK_UP) {
      // Кнопка Service; делаем статус servicing
      content = 'Are you sure you want to start servicing this order?';
      type = SERVICE_ORDER;
    }
    if (status === SERVICING) {
      // Кнопка Drop off; делаем статус drop_off
      content = 'Are you sure you want to start drop off this order?';
      type = DPOP_OFF_ORDER;
    }
    if (status === DROP_OFF) {
      // Кнопка Complete; делаем статус completed
      content = 'Are you sure you want to complete this order?';
      type = COMPLETE_ORDER;
    }

    !!content && !!type && openConfirmationDialog({ id, content, type, reason });
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Box
        component={NavLink}
        to={`/orders/${id}`}
        sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          textDecoration: 'none',
          color: 'text.primary',
          '&:hover': {
            color: 'primary.main'
          }
        }}
      >
        <Typography variant='h6' variantMapping={{ h6: 'div' }} textAlign='center'>{number}</Typography>
        <NextIcon sx={{ ml: .5 }} />
      </Box>
      <Box component={'span'} sx={{ width: '100%', display: 'inline-flex', justifyContent: 'center', textAlign: 'center', pr: 2, pb: 2 }} >
        <Status size='small' value={status} />
      </Box>
      <Box pb={1}>
        <Typography variant='caption' color='textHint' component={'div'} sx={{ lineHeight: '1.1' }}>Driver:</Typography>
        <Typography variant='subtitle2'>{!!user ? `${user.first_name} ${user.last_name}` : '-'}</Typography>
      </Box>
      <Box pb={1}>
        <Typography variant='caption' color='textHint' component={'div'} sx={{ lineHeight: '1.1' }}>Dispatcher:</Typography>
        <Typography variant='subtitle2'>{!!dispatcher ? `${dispatcher.first_name} ${dispatcher.last_name}` : '-'}</Typography>
      </Box>
      <Box pb={1}>
        <Typography variant='caption' color='textHint' component={'div'} sx={{ lineHeight: '1.1' }}>Address:</Typography>
        <Typography variant='caption'>{incident_location?.address}</Typography>
      </Box>
      {!!orderStatusButtonText[status] && !!user &&
          <Button
            color='primary'
            variant='contained'
            onClick={handleChangeStatusClick}
          >
            {orderStatusButtonText[status]}
          </Button>
        }
      {!!company && status === NEW &&
        <Box display='flex' justifyContent='space-between' width={{ xs: '100%', md: 'auto' }}>
          <Button
            size='small'
            variant='text'
            onClick={handleAssignClick}
          >
            Assign driver
          </Button>
          <Button
            color='error'
            size='small'
            variant='text'
            onClick={handleRejectClick}
          >
            Reject
          </Button>
        </Box>
      }
    </Box>
  );
};

export default memo(OrderInfo);
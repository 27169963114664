import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCompanyServices as getCompanyServicesAction,
  getFullListServices as getFullListServicesAction,
  getListServices as getListServicesAction,
  getServices as getServicesAction,
  getService as getServiceAction,
  createService as createServiceAction,
  createCompanyService as createCompanyServiceAction,
  deleteCompanyService as deleteCompanyServiceAction,
  clearLocalListServices as clearLocalListServicesAction,
  clearLocalServices as clearLocalServicesAction,
  clearLocalCompanyServices as clearLocalCompanyServicesAction,
  clearLocalActiveService as clearLocalActiveServiceAction
} from 'actions/services';

const useServices = () => {
  const dispatch = useDispatch();
  const getCompanyServices = useCallback(({ service_type, company_id }) => dispatch(getCompanyServicesAction({ service_type, company_id })), [dispatch]);
  const getFullListServices = useCallback(({ service_type }) => dispatch(getFullListServicesAction({ service_type })), [dispatch]);
  const getListServices = useCallback(({ offset, service_type, query }) => dispatch(getListServicesAction({ offset, service_type, query })), [dispatch]);
  const getServices = useCallback(({ offset, query, side }) => dispatch(getServicesAction({ offset, query, side })), [dispatch]);
  const getService = useCallback(id => dispatch(getServiceAction(id)), [dispatch]);
  const createService = useCallback(service => dispatch(createServiceAction(service)), [dispatch]);
  const createCompanyService = useCallback(({ company_id, id }) => dispatch(createCompanyServiceAction({ company_id, id })), [dispatch]);
  const deleteCompanyService = useCallback(({ company_id, id }) => dispatch(deleteCompanyServiceAction({ company_id, id })), [dispatch]);
  const clearLocalListServices = useCallback(() => dispatch(clearLocalListServicesAction()), [dispatch]);
  const clearLocalServices = useCallback(() => dispatch(clearLocalServicesAction()), [dispatch]);
  const clearLocalCompanyServices = useCallback(() => dispatch(clearLocalCompanyServicesAction()), [dispatch]);
  const clearLocalActiveService = useCallback(() => dispatch(clearLocalActiveServiceAction()), [dispatch]);

  return {
    getCompanyServices,
    getFullListServices,
    getListServices,
    getServices,
    getService,
    createService,
    createCompanyService,
    deleteCompanyService,
    clearLocalListServices,
    clearLocalServices,
    clearLocalCompanyServices,
    clearLocalActiveService
  };
};

export default useServices;
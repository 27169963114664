import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateNotification as updateNotificationAction } from 'actions/notifications';

const useNotifications = () => {
  const dispatch = useDispatch();
  const updateNotification = useCallback(({ id, notification }) => dispatch(updateNotificationAction({ id, notification })), [dispatch]);

  return { updateNotification };
};

export default useNotifications;
import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import _map from 'lodash/map';
// Local files
import useTimezones from 'hooks/useTimezones';

const PaymentsHistoryCard = ({ payments, total_amount, paid_amount, company_location }) => {
  const { timeZone } = useTimezones(company_location?.company?.id);

  return (
    <Card sx={{ p: 3, mb: 3 }}>
      <Typography variant='title' sx={{ pb: 4 }}>Payment History</Typography>
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 700 }}>
        <Box>INVOICE</Box>
        <Box>${total_amount?.toFixed(2)}</Box>
      </Box>
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontSize: '18px' }}>
      { _map(payments, ({ id, payer, created_at, amount }) => 
      <Box key={id} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>{`${payer} - ${new Date(created_at).toLocaleDateString('en-US', { timeZone })}`}</Box>
        <Box>${amount?.toFixed(2)}</Box>
      </Box> )}
      </Box>
      <Divider />
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 700 }}>
        <Box>BALANCE</Box>
        <Box>${(total_amount - paid_amount)?.toFixed(2)}</Box>
      </Box>
    </Card>
  );
};

export default memo(PaymentsHistoryCard);

import {
  setSuccessRoutine,
  hideSuccessRoutine
} from 'actions';

export const initialState = {
  message: '',
  open: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setSuccessRoutine.SUCCESS: {
      const { message } = action.payload;

      return { ...state, message, open: true };
    }
    case hideSuccessRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
};

export default reducer;
import BaseStats from 'components/Common/BaseStats/BaseStats';
import BaseStatsCard from 'components/Common/BaseStatsCard/BaseStatsCard';
import { invoiceStatuses } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';

const { APPROVED, PARTIALLY_PAID, PAID } = invoiceStatuses;

const Stats = ({ onStatuses }) => {
  const {
    total_invoices_count,
    total_invoices_amount,
    partially_paid_invoices_count,
    partially_paid_invoices_amount, 
    approved_invoices_count, 
    approved_invoices_amount,
    paid_invoices_count,
    paid_invoices_amount
  } = useCustomSelector(state => state.invoices.statistics);

  return (
    <BaseStats clear={() => onStatuses([])}>
      <BaseStatsCard onClick={() => onStatuses([])} title='Total Invoices' firstValue={`$${total_invoices_amount}`} firstLabel='Total Amount' secondValue={total_invoices_count} secondLabel='Calls' />
      <BaseStatsCard onClick={() => onStatuses([APPROVED])} title='Submitted Invoices' firstValue={`$${approved_invoices_amount}`} firstLabel='Total Amount' secondValue={approved_invoices_count} secondLabel='Calls' />
      <BaseStatsCard onClick={() => onStatuses([PARTIALLY_PAID])} title='Partially Paid Invoices' firstValue={`$${partially_paid_invoices_amount}`} firstLabel='Total Amount' secondValue={partially_paid_invoices_count} secondLabel='Calls' />
      <BaseStatsCard onClick={() => onStatuses([PAID])} title='Paid Full Invoices' firstValue={`$${paid_invoices_amount}`} firstLabel='Total Amount' secondValue={paid_invoices_count} secondLabel='Calls' />
    </BaseStats>
  );
};

export default Stats;
import { memo, useEffect, useMemo, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CancelIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import _startsWith from 'lodash/startsWith';
// Local files
import ServicesAutocomplete from 'components/Services/Autocomplete/Autocomplete';
import { getExpenseTypeLabel, checkEmptyString, getFormatedHoursMinutes } from 'helpers';
import { reasons } from 'helpers/constants';
import { DELETE_EXPENSE } from 'helpers/confirmations';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';
import useExpenses from 'hooks/useExpenses';
import useDialogs from 'hooks/useDialogs';

const TableRowForm = ({ invoice_id, activeExpense = null, onCancel }) => {
  const { filterExpencesData } = useApp();
  const { setError } = useError();
  const { createExpense, updateExpense, suggestInvoiceExpenses } = useExpenses();
  const { openConfirmationDialog } = useDialogs();
  const [service, setService] = useState({ value: '', formattedValue: null, error: '' });
  const [rateValue, setRateValue] = useState({ value: '', error: '' });
  const [quantity, setQuantity] = useState({ value: 1, error: '' });
  const [hourlyDuration, setHourlyDuration] = useState({ hours: '', minutes: '' });
  const [processing, setProcessing] = useState(false);
  const isNewExpense = useMemo(() => _startsWith(activeExpense?.id, 'new_') || _startsWith(service?.value, 'new_'), [activeExpense?.id, service?.value]);
  const isCustomExpense = useMemo(() => !!!activeExpense?.rate && !!!activeExpense?.default_charge, [activeExpense]);
  const isPerHour = useMemo(() => (activeExpense?.rate?.basic_value_type === 'per_hour' || activeExpense?.default_charge?.basic_rate_type === 'per_hour') && !!activeExpense?.hourly_charge_duration, [activeExpense]);
  const checkIsUnitPrice = useMemo(() => (!!activeExpense?.rate && rateValue.value !== activeExpense?.rate?.value) || !!activeExpense?.default_charge, [activeExpense, rateValue.value]);

  const needToUpdate = (a,b) => 
    (isPerHour && a.hourly_charge_duration !== b.hourly_charge_duration)
    || a.quantity !== b.quantity
    || a.unit_price !== b.unit_price;
  const handleCreateExpense = (expense) => {
    setProcessing(true);
    createExpense({ invoice_id, expense })
    .then(handleCancel)
    .catch((error) => setError(error))
    .finally(() => setProcessing(false));
  };
  const handleUpdateExpense = (expense) => {
    setProcessing(true);
    if (needToUpdate(activeExpense, expense)) {
      updateExpense(expense)
      .then(handleCancel)
      .catch((error) => setError(error))
      .finally(() => setProcessing(false));
    } else { 
      setProcessing(false);
      handleCancel();
    }
  };
  const handleSubmit = () => {
    if (!!activeExpense || _startsWith(service.value, 'new_')) {
      let hasError = false;

      if (!!!rateValue.value) {
        setRateValue(prev => ({ ...prev, error: `Value can't be empty` }));
        hasError = true;
      }
      if (!isPerHour && ((typeof quantity.value === 'string' && !checkEmptyString(quantity.value)) || quantity.value < 0)) {
        setQuantity(prev => ({ ...prev, error: `Value can't be empty` }));
        hasError = true;
      }
      if (hasError) return;

      const expense = {
        ...(isNewExpense && !isCustomExpense && { ...(!!activeExpense?.rate && { rate_id: activeExpense?.rate?.id }), ...(!!activeExpense?.default_charge && { default_charge_id: activeExpense?.default_charge?.id }) }),
        ...(!isNewExpense && { id: activeExpense.id }),
        ...((checkIsUnitPrice || isCustomExpense) && { unit_price: parseFloat(typeof rateValue.value === 'string' ? rateValue.value.replace(',', '.') : rateValue.value) }),
        name: activeExpense?.default_charge?.charge?.name || activeExpense?.rate?.charge?.name || service.formattedValue?.inputValue || activeExpense?.name,
        ...(isPerHour ? { hourly_charge_duration: `PT${hourlyDuration?.hours || 0}H${hourlyDuration.minutes || 0}M`} : { quantity: parseFloat(quantity.value) })
      };

      isNewExpense ? handleCreateExpense(expense) : handleUpdateExpense(expense);
    } else {
      let hasError = false;

      if (!!!service.value) {
        setService(prev => ({ ...prev, error: `Choose service or add custom` }));
        hasError = true;
      }
      if (hasError) return;
      
      suggestInvoiceExpenses({ service_id: service.value, invoice_id, expense_name: service.formattedValue.name })
      .then(onCancel)
      .catch((error) => setError(error))
      .finally(() => setProcessing(false))
    }
  };
  const handleCancel = () => {
    isNewExpense && !!activeExpense && filterExpencesData(activeExpense?.id);
    onCancel();
  };
  const handleDelete = () => {
    openConfirmationDialog({
      id: activeExpense?.id,
      content: 'Are you sure you want to delete this service?',
      type: DELETE_EXPENSE,
      reason: reasons.EXPENSE
    });
    handleCancel();
  };

  useEffect(() => {
    if (!!activeExpense) {
      setRateValue({ value: activeExpense?.unit_price || activeExpense?.rate?.value, error: '' });
      setQuantity({ value: activeExpense?.quantity, error: '' });
      isPerHour && !!activeExpense.hourly_charge_duration && setHourlyDuration({ hours: getFormatedHoursMinutes(activeExpense.hourly_charge_duration)?.hours, minutes: getFormatedHoursMinutes(activeExpense.hourly_charge_duration)?.minutes });
    }
  }, [activeExpense, isPerHour]);

  return (
    <TableRow>
      <TableCell sx={{ width: '45%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          { !!activeExpense && !isNewExpense && <Tooltip arrow title='Delete'>
            <IconButton size='small' aria-label='Delete' sx={{ mr: '6px', ml: '-12px' }} disabled={processing} onClick={handleDelete}>
              <DeleteIcon color='error' />
            </IconButton>
          </Tooltip> }
          {!!!activeExpense ? <ServicesAutocomplete 
            id='service'
            label='Service type'
            size='small'
            expenses
            value={service.formattedValue}
            error={!!service.error}
            helperText={service.error}
            onChange={({ value, formattedValue, error }) => setService({ value, formattedValue, error })}
          /> : activeExpense?.rate?.charge?.name || activeExpense?.default_charge?.charge?.name || activeExpense?.name }
        </Box>
      </TableCell>
      <TableCell align='left' sx={{ width: '25%' }}>
        {(!!activeExpense || isNewExpense) &&
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {activeExpense?.rate?.fee_value_type === 'percent' ? '%' : '$'}
          <TextField
            size='tiny'
            sx={{ width : '80px' }}
            placeholder='Rate'
            value={rateValue.value}
            onChange={({ target: { value } }) => setRateValue({ value, error: '' })}
            error={!!rateValue.error}
            helperText={rateValue.error}
          />
          <TableCell>{getExpenseTypeLabel(activeExpense?.default_charge || activeExpense?.rate)}</TableCell> 
        </Box>}
      </TableCell>
      <TableCell align='left' sx={{ width: '15%' }}>
        { isPerHour ?
        <>
          <TextField
            size='tiny'
            sx={{ width : '45%' }}
            label='H'
            value={hourlyDuration.hours}
            onChange={({ target: { value: hours } }) => setHourlyDuration(prev => ({...prev, hours }))}
          />
          <TextField
            size='tiny'
            sx={{ width : '45%', ml: 1 }}
            label='M'
            value={hourlyDuration.minutes}
            onChange={({ target: { value: minutes } }) => setHourlyDuration(prev => ({...prev, minutes }))}
          />
        </>
         : (!!activeExpense || isNewExpense) && <TextField
          size='tiny'
          placeholder='Qty'
          value={quantity.value}
          onChange={({ target: { value } }) => setQuantity({ value, error: '' })}
          error={!!quantity.error}
          helperText={quantity.error}
        /> }
      </TableCell>
      <TableCell align='right' sx={{ width: '15%' }}>
        <Tooltip arrow title={isNewExpense ? 'Add' : 'Update'}>
          <IconButton size='small' aria-label={isNewExpense ? 'Add' : 'Update'} sx={{ mr: {xs: 0, md: 2}}} disabled={processing} onClick={handleSubmit} >
            <SaveIcon color='success' />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Cancel'>
          <IconButton size='small' aria-label='Cancel' sx={{ mr: '-12px' }} disabled={processing} onClick={handleCancel}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default memo(TableRowForm);
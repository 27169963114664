import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px 8px 16px 8px',
  [theme.breakpoints.up('md')]: {
    padding: '56px 32px 56px 32px'
  },
  height: '100%',
  '& .react-grid-item.react-grid-placeholder ': {
    backgroundColor: '#08008F !important',
    borderRadius: 8
  }
}));
export const Title = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});
export const WidgetConatiner = styled(Box)(() => ({
  padding: '8px 8px 8px 8px'
}));
import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

export const Root = styled(ButtonBase)(({ theme }) => ({
  width: 'auto',
  maxWidth: 130,
  height: 36,
  position: 'absolute',
  bottom: 24,
  left: 8,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  backgroundColor: theme.palette.background.selected,
  borderRadius: 40,
  padding: '10px 12px',
  [theme.breakpoints.up('md')]: {
    bottom: 30,
    right: 50,
    left: 'unset',
  }
}));

export const StyledMenu = styled(Menu)({
  '& ul': {
    width: 140,
    padding: 0,
  }
});

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
}));
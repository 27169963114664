import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  createCardRoutine,
  deleteCardRoutine
} from 'actions';

const attributes = ['id', 'brand', 'last4'];

export const createCard = getThunkActionCreator(
  createCardRoutine,
  async ({ stripe_token, customer_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/customers/${customer_id}/cards`, { access_token, stripe_token, attributes });

    return { response, customer_id };
  }
);
export const deleteCard = getThunkActionCreator(
  deleteCardRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/cards/${id}`, { params: { access_token } });

    return { response, id };
  }
);
import { memo } from 'react';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import BaseStatus from 'components/Common/BaseStatus/BaseStatus';
import { invoiceStatuses } from 'helpers/constants';

const { INVOICED, REJECTED, APPROVED, NOT_SUBMITTED, PARTIALLY_PAID, PAID } = invoiceStatuses;

const Status = ({ value, size, darkBackground }) => {

  switch (value) {
    case(INVOICED): {
      return ( <BaseStatus size={size} color='warning' darkBackground={darkBackground} label={_startCase(invoiceStatuses[value.toUpperCase()])} /> )
    }
    case(NOT_SUBMITTED): {
      return ( <BaseStatus size={size} color='warning' darkBackground={darkBackground} label={_startCase(invoiceStatuses[value.toUpperCase()])} /> )
    }
    case(PARTIALLY_PAID): {
      return ( <BaseStatus size={size} color='warning' darkBackground={darkBackground} label={_startCase(invoiceStatuses[value.toUpperCase()])} /> )
    }
    case(PAID): {
      return ( <BaseStatus size={size} color='success' darkBackground={darkBackground} label={_startCase(invoiceStatuses[value.toUpperCase()])} /> )
    }
    case(APPROVED): {
      return ( <BaseStatus size={size} color='primary' darkBackground={darkBackground} label={_startCase(invoiceStatuses[value.toUpperCase()])} /> )
    }
    case(REJECTED): {
      return ( <BaseStatus size={size} color='error' darkBackground={darkBackground} label={_startCase(invoiceStatuses[value.toUpperCase()])} /> )
    }
    default: {
      return null;
    }
  }
};

Status.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  darkBackground: PropTypes.bool
}

export default memo(Status);
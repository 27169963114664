import { memo } from 'react';
import Button from '@mui/material/Button';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';

const FilterButton = ({ onClick, statusesCount }) => {
  const { open } = useCustomSelector(state => state.app.filter);

  return <Button onClick={onClick}>{`${open ? 'Hide' : 'Show'} filters (${statusesCount} active)`}</Button>;
};

export default memo(FilterButton);
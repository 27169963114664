import { memo } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _sortBy from 'lodash/sortBy';
// Local files
import { deliveryTypes, notificationTypes } from 'helpers/constants';

const ServicesTable = ({ notifications, onChange, processing }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table size="small" sx={{ minWidth: '680px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: 2, width: '50%' }}>Notification Type</TableCell>
              <TableCell sx={{ py: 2, width: '15%' }}>Email</TableCell>
              <TableCell sx={{ py: 2, width: '15%' }}>SMS</TableCell>
              <TableCell sx={{ py: 2, width: '20%' }}>{`Mobile App (Push)`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { _map(_sortBy(notifications, ['notification_type']), (notification) => (
              <TableRow key={notification.id} id={notification.id}>
                <TableCell>{notificationTypes[notification.notification_type]}</TableCell>
                <TableCell><Checkbox id={`${notification.id}_email`} disabled={processing} checked={_includes(notification.delivery_types, deliveryTypes.EMAIL)} onClick={onChange} /></TableCell>
                <TableCell><Checkbox id={`${notification.id}_sms`} disabled={processing} checked={_includes(notification.delivery_types, deliveryTypes.SMS)} onClick={onChange} /></TableCell>
                <TableCell><Checkbox id={`${notification.id}_push`} disabled={processing} checked={_includes(notification.delivery_types, deliveryTypes.PUSH)} onClick={onChange} /></TableCell>
              </TableRow>)
            ) }
            <Divider />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default memo(ServicesTable);
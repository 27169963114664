import { memo } from 'react';
// Local files
import Details from 'components/Vehicles/Details/Details';
import BaseModalDetails from 'components/Common/BaseModalDetails/BaseModalDetails';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';

const VehicleDetails = () => {

  return (
    <BaseModalDetails>
      <ComponentErrorWrapper children={<Details />} />
    </BaseModalDetails>
  )
};

export default memo(VehicleDetails);
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

export const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    paddingBottom: '16px',
  [theme.breakpoints.up('md')]: {
    paddingBottom: '56px',
  }
}));
export const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      minWidth: '320px',
      fontSize: theme.typography.pxToRem(24)
    }
}));
export const NavigationContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
});

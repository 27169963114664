import { memo, useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _intersectionBy from 'lodash/intersectionBy';
//Local files
import BaseDeliveryMethod from './BaseDeliveryMethod/BaseDeliveryMethod';
import { getRandomString } from 'helpers';
import { deliveryMethods, reasons } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useRecipients from 'hooks/useRecipients';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useInvoices from 'hooks/useInvoices';
import useOrders from 'hooks/useOrders';

const { TEXT, EMAIL } = deliveryMethods;

const DeliveryForm = ({ id, processing, onProcessing, title, sx, oldRecipients, hideCharges, customer, model, textChecked, emailChecked, documentIds, onTextChecked, onEmailChecked, onCopyLink = null }) => {
  const { closeDrawer } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createRecipients, updateRecipients, deleteRecipient } = useRecipients();
  const { sendInvoiceDetails, getInvoiceForNotification } = useInvoices();
  const { sendOrderTracking, getOrderForNotification } = useOrders();
  const [recipients, setRecipients] = useState([]); 
  const isOrder = model === 'order';
  const handleTextChecked = ({ target: { checked } }) => {
    onTextChecked(checked);
    setRecipients(
      prev => checked ? 
        [...prev, ...[{ id: getRandomString(24), checked: true, delivery_method: TEXT, phone: { value: customer?.phone || '', formattedValue: customer?.phone || '', error: '' }, name: customer?.name || '' }]] : 
        _filter(prev, (item) => item.delivery_method !== TEXT)
    );
  };
  const handleEmailChecked = ({ target: { checked } }) => {
    onEmailChecked(checked);
    setRecipients(
      prev => checked ? 
      [...prev, ...[{ id: getRandomString(24), checked: true, delivery_method: EMAIL, email: { value: customer?.email || '', error: '' }, name: customer?.name || '' }]] : 
      _filter(prev, (item) => item.delivery_method !== EMAIL)
    );
  };
  const handleAddRecipient = ({ target: { id } }) => 
    setRecipients(prev => [...prev, 
      ...[{ id: getRandomString(24), checked: true, delivery_method: id, ...(id === TEXT ? 
        { phone: { value: '', formattedValue: '', error: '' } } : { email: { value: '', error: '' } }), 
        name: '' }]
      ] 
    );
  const needToUpdate = (a,b) => a.length !== b.length || !_isEqual(a.sort(), b.sort());
  const handleSendDetails = useCallback((newRecipients) => {
    (isOrder ? sendOrderTracking({ id, recipient_ids: _map(newRecipients, ({ id }) => id) }) 
    : sendInvoiceDetails({ id, hide_charges: hideCharges, recipient_ids: _map(newRecipients, ({ id }) => id), document_ids: documentIds }))
    .then(() => setSuccess(`${isOrder ? 'Tracking' : 'Invoice'} successfully send`).then(() => closeDrawer(isOrder ? reasons.SEND_ORDER_TRACKING : reasons.SEND_INVOICE)))
    .catch((error) => setError(error))
    .finally(() => onProcessing(false))
  }, [id, isOrder, documentIds, hideCharges, sendInvoiceDetails, sendOrderTracking, setError, setSuccess, closeDrawer]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (processing) {
      let hasError = false;

      if (!!_find(recipients, (recipient) => recipient.delivery_method === EMAIL && !!!recipient.email.value)) {
        setRecipients(prev => _map(prev, (recipient) => 
          recipient.delivery_method === EMAIL && !!!recipient.email.value ? { ...recipient, email: { ...recipient.email, error: `Email can't be empty` } } : recipient)
        );
        hasError = true;
      }
      if (hasError) onProcessing(false);

      const newRecipients = _map(_filter(recipients, (r) => r.checked), (r) => 
        ({ ...(!!oldRecipients.length && { id: r.id }), 
          ..._pick(r, ['delivery_method', 'name']), 
          ...(r.delivery_method === TEXT ? { phone: r.phone.value } : { email: r.email.value }) 
        })
      );

      if (oldRecipients.length === newRecipients.length) {
        const isEqualRecipientsinIds = _intersectionBy(oldRecipients, newRecipients, 'id');
        if (needToUpdate(oldRecipients, newRecipients) && isEqualRecipientsinIds.length === newRecipients.length){
          updateRecipients(newRecipients)
          .then(() => handleSendDetails(newRecipients))
          .catch((error) => setError(error).then(() => onProcessing(false)))
        }
      } else {
        if (!!newRecipients.length) {
          createRecipients({ id, model, recipients: !!oldRecipients.length ? _filter(newRecipients, (r) => !!!_find(oldRecipients, (or) => r.id === or.id)) : newRecipients})
          .then(() => 
            (isOrder ? getOrderForNotification(id) : getInvoiceForNotification(id))
            .then(({ payload: { data } }) => handleSendDetails(isOrder ? data.order?.recipients : data.invoice?.recipients))
            .catch((error) => setError(error).then(() => onProcessing(false)))
          )
          .catch((error) => setError(error).then(() => onProcessing(false)))
        }
      }
    }
  }, [processing]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!!oldRecipients.length) {
      !!_find(oldRecipients, (r) => r.delivery_method === TEXT) ? onTextChecked(true) : onEmailChecked(true);
      setRecipients(_map(oldRecipients, (r) => ({ 
        ..._pick(r, ['id', 'name', 'delivery_method']),
        checked: true, 
        ...(r.delivery_method === TEXT ? { phone: { value: r.phone, formattedValue: r.phone, error: '' } } : { email: { value: r.email, error: '' } }) 
      })));
    }
  }, [oldRecipients]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const foundedRecipient = _find(recipients, (r) => !r.checked);
    const filteredTextRecipients = _filter(recipients, (r) => r.delivery_method === TEXT);
    const filteredEmailRecipients = _filter(recipients, (r) => r.delivery_method === EMAIL);

    if (!!foundedRecipient) {
      setRecipients(_filter(recipients, (r) => r.id !== foundedRecipient.id));
      filteredTextRecipients.length === 1 && foundedRecipient?.delivery_method === TEXT && onTextChecked(false);
      filteredEmailRecipients.length === 1 && foundedRecipient?.delivery_method === EMAIL && onEmailChecked(false);

      if (!!oldRecipients.length) {
        onProcessing(true);
        deleteRecipient(foundedRecipient.id)
        .catch((error) => setError(error))
        .finally(() => onProcessing(false))
      }
    }
  }, [oldRecipients.length, recipients, deleteRecipient, setError]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    !!!oldRecipients.length && handleTextChecked({ target: { checked: true } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ...sx }}>
      <Typography variant='title' sx={{ fontSize: 18, pb: '8px' }}>{title}</Typography>
      {!!onCopyLink && <Button
        color='primary'
        variant='outlined'
        disabled={processing}
        onClick={onCopyLink}
        sx={{ mt: 1, mb: 2 }}
      >
        Copy tracking link
      </Button>}
      <BaseDeliveryMethod 
        processing={processing} 
        checked={textChecked}
        method={TEXT}
        onCheckoboxChange={handleTextChecked} 
        recipients={_filter(recipients, (item) => item.delivery_method === TEXT)}
        onRecipients={setRecipients} 
        onAddRecipient={handleAddRecipient}
      />
      <BaseDeliveryMethod 
        processing={processing} 
        checked={emailChecked}
        method={EMAIL}
        onCheckoboxChange={handleEmailChecked} 
        recipients={_filter(recipients, (item) => item.delivery_method === EMAIL)}
        onRecipients={setRecipients} 
        onAddRecipient={handleAddRecipient}
      />
    </Box>
  );
};

export default memo(DeliveryForm);
import { memo } from 'react';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';
// Local files
import BaseItem from '../BaseItem/BaseItem';

const AssetDetails = ({ full_name, ...fields }) => {
  return (
    <>
      <BaseItem title={full_name} content={_map(_filter(Object.entries(fields), (f) => !!f[1]), (f) => <Box key={f[0]}>{`${_startCase(f[0])}: ${f[1]}`}</Box>)} />
    </>
  );
};

export default memo(AssetDetails);
import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useCharges from 'hooks/useCharges';
import useSuccess from 'hooks/useSuccess';

const CompanyCharges = ({ company_id }) => {
  const { getFullListCharges, getCompanyCharges, createCompanyCharge, deleteCompanyCharge } = useCharges();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { list, data } = useCustomSelector(state => state.charges.company);
  const [loading, setLoading] = useState(false);

  const handleChange = (_, formattedValue) => {
    setLoading(true);
    (formattedValue.length > data.length ? 
    createCompanyCharge({ id: formattedValue[formattedValue.length - 1]?.id, company_id })
    .then(() => setSuccess('Charge successfully added')) :
    deleteCompanyCharge({ id: _find(data, (item) => !_includes(formattedValue, item))?.id, company_id })
    .then(() => setSuccess('Charge successfully deleted')))
    .catch(e => setError(e))
    .finally(() => setLoading(false))
  };
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchCharges = useCallback((company_id) => {
    setLoading(true);
    getFullListCharges()
    .then(() => 
      getCompanyCharges({ company_id })
      .catch(e => setError(e))
      .finally(() => setLoading(false))
    )
    .catch(e => setError(e).then(() => setLoading(false)));
  }, [getFullListCharges, getCompanyCharges, setError]);

  useEffect(() => {
    fetchCharges(company_id);
  }, [company_id, fetchCharges]); 

  return (
    <Autocomplete
      value={data}
      multiple
      onChange={handleChange}
      id="company-charges-list"
      options={list}
      getOptionLabel={o => o.name}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable
      handleHomeEndKeys
      fullWidth
      loading={loading}
      renderInput={params => <TextField {...params} label='Included Charges' disabled={loading} margin='normal' />}
    />
  );
};

export default memo(CompanyCharges);
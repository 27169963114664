import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setLocalToken as setLocalTokenAction,
  getUserSessions as getUserSessionsAction,
  getSessions as getSessionsAction,
  getSession as getSessionAction,
  createSession as createSessionAction,
  deleteActiveSession as deleteActiveSessionAction,
  deleteSession as deleteSessionAction,
  setRecoveryEmail as setRecoveryEmailAction,
  setConfirmationCode as setConfirmationCodeAction,
  clearLocalRecoveryData as clearLocalRecoveryDataAction,
  clearStore as clearStoreAction 
} from 'actions/sessions';

const useSessions = () => {
  const dispatch = useDispatch();
  const setLocalToken = useCallback(token => dispatch(setLocalTokenAction(token)), [dispatch]);
  const getUserSessions = useCallback(({ offset, user_id }) => dispatch(getUserSessionsAction({ offset, user_id })), [dispatch]);
  const getSessions = useCallback(({ offset }) => dispatch(getSessionsAction({ offset })), [dispatch]);
  const getSession = useCallback(id => dispatch(getSessionAction(id)), [dispatch]);
  const createSession = useCallback(session => dispatch(createSessionAction(session)), [dispatch]);
  const deleteActiveSession = useCallback(() => dispatch(deleteActiveSessionAction()), [dispatch]);
  const deleteSession = useCallback(id => dispatch(deleteSessionAction(id)), [dispatch]);
  const setRecoveryEmail = useCallback((email) => dispatch(setRecoveryEmailAction(email)), [dispatch]);
  const setConfirmationCode = useCallback((code) => dispatch(setConfirmationCodeAction(code)), [dispatch]);
  const clearLocalRecoveryData = useCallback(() => dispatch(clearLocalRecoveryDataAction()), [dispatch]);
  const clearStore = useCallback(() => dispatch(clearStoreAction()), [dispatch]);

  return {
    setLocalToken,
    getUserSessions,
    getSessions,
    getSession,
    createSession,
    deleteActiveSession,
    deleteSession,
    setRecoveryEmail,
    setConfirmationCode,
    clearLocalRecoveryData,
    clearStore
  };
};

export default useSessions;
import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import _startCase from 'lodash/startCase';
import _pick from 'lodash/pick';
import _find from 'lodash/find';
import { useNavigate } from 'react-router-dom';
// Local files
import { Root, Header, AccountName, DecorationContainer, DecorationLeft, DecorationRight, Title, Actions, DecorationCenter, Details, Content, ContentHead, StyledMenuItem } from './Main.styled';
import ServicesTable from 'components/Services/Table/Table';
import Status from '../../Status/Status';
import { orderStatuses, invoiceStatuses, orderStatusButtonText, accountTypes, reasons, vehicleStatuses } from 'helpers/constants';
import { START_ORDER_MOVEMENT, PICK_UP_ORDER, SERVICE_ORDER, DPOP_OFF_ORDER, COMPLETE_ORDER, RENEW_ORDER, REVERSE_STATUS } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useRole from 'hooks/useRole';
import useDialogs from 'hooks/useDialogs';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useVehicles from 'hooks/useVehicles';
import useMap from 'hooks/useMap';
import useOrderSources from 'hooks/useOrderSources';

const { NEW, MANAGER_ACCEPTED, COMPANY_ACCEPTED, REJECTED, CANCELED, EXPIRED, EN_ROUTE, PICK_UP, SERVICING, DROP_OFF, COMPLETED, STORED, SCHEDULED } = orderStatuses;

const Main = ({
  id,
  source,
  account, 
  customer,
  vehicle,
  user,
  authorization_number,
  police_number,
  police_code,
  number, 
  services, 
  status,
  previous_status,
  eta, 
  dispatcher, 
  invoice,
  created_at,
  incident_location,
  isHasChannelMessages,
  trucks,
  users,
  scheduled_at
}) => {
  const navigate = useNavigate();
  const { openAssignDialog, openMapDialog, openConfirmationDialog, openRejectOrderDialog, openCancelOrderDialog } = useDialogs();
  const { setMapConfigs } = useMap();
  const { openDrawer } = useApp();
  const { isManager, isOwner } = useRole();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { storeVehicle } = useVehicles();
  const { isISSC, isWebOrWidget, isUrgently } = useOrderSources(source);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [newExpense, setNewExpense] = useState(false);
  const [processing, setProcessing] = useState(false);
  const timeOptions = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  const scheduledAndTodayDifference = new Date(scheduled_at).getTime() - new Date().getTime();
  const etaValue = scheduledAndTodayDifference > 86_400_000 ? `${(scheduledAndTodayDifference / 86_400_000).toFixed()} day${(scheduledAndTodayDifference > 86_400_000 && scheduledAndTodayDifference < 172_800_000) ? '' : 's'}` : `${new Date(scheduledAndTodayDifference).getUTCHours()} hrs, ${new Date(scheduledAndTodayDifference).getUTCMinutes()} mins`
  const isShowScheduledAtInEta = !!scheduled_at && new Date(scheduled_at).getTime() > new Date().getTime();

  const showChangeStatus = !!user && (isWebOrWidget || ((isISSC || isUrgently) && (status !== MANAGER_ACCEPTED))) && !!orderStatusButtonText[status];
  const showRenewOrder = (isManager || isOwner) && isWebOrWidget && status === CANCELED;
  const showRestartOrder = (isManager || isOwner) && (status === COMPLETED || status === CANCELED);
  const showEdit = (isManager || isOwner)
    && status !== CANCELED
    && status !== COMPLETED
    && status !== REJECTED
    && status !== EXPIRED
    && status !== STORED;
  const showMenu = (isManager || isOwner) && status !== REJECTED;
  const showAddService = (isManager || isOwner) && !!invoice
    && status !== CANCELED
    && status !== REJECTED
    && (invoice?.status === invoiceStatuses.NOT_SUBMITTED || invoice.status === invoiceStatuses.NEW);
  const showAddToStorage = (isManager || isOwner) && vehicle?.status === vehicleStatuses.NEW && status === COMPLETED;
  const showViewInvoice = (isManager || isOwner) && invoice?.status !== invoiceStatuses.NEW;
  const showViewStorage = (isManager || isOwner) && vehicle?.status !== vehicleStatuses.NEW && vehicle?.status !== vehicleStatuses.RELEASED;
  const showSendOrderCustomer = showEdit && !!customer;
  const showPONumber = account?.account_type !== accountTypes.POLICE;
  const showAdditionals = (isISSC || isUrgently) && status !== CANCELED && status !== REJECTED && isHasChannelMessages;
  const servicesData = {..._pick(invoice, ['expenses', 'total_amount', 'paid_amount']), invoice_id: invoice?.id, editExpense: showAddService };

  const handleEditClick = () => openDrawer({ reason: reasons.ORDER, edit: true });
  const handleDuplicateClick = () => openDrawer({ reason: reasons.ORDER, duplicate: true }).then(() => navigate(`/orders`));
  const handleSendOrderCustomer = () => {
    setMenuAnchor(null);
    openDrawer({ reason: reasons.SEND_ORDER_TRACKING });
  };
  const handleRequestAdditional = () => {
    setMenuAnchor(null);
    openDrawer({ reason: reasons.REQUEST_ADDITIONAL });
  };
  const handleRejectClick = () => openRejectOrderDialog({ id, source });
  const handleCancelClick = () => {
    setMenuAnchor(null);
    openCancelOrderDialog({ id, source });
  };
  const handleRenewClick = () => {
    setMenuAnchor(null);
    openConfirmationDialog({ 
      id, 
      content: 'Are you sure you want to renew this order?', 
      type: RENEW_ORDER, 
      reason: reasons.ORDER
    });
  };

  const handleChangeStatusClick = () => {
    let content = '';
    let type = '';
    const reason = reasons.ORDER;

    if (!!_find([NEW, COMPANY_ACCEPTED, COMPLETED, CANCELED, SCHEDULED], (s) => s === status)) {
      // кнопка Start; делаем статус en_route
      content = 'Are you sure you want to start this order?';
      type = START_ORDER_MOVEMENT;
    }
    if (status === EN_ROUTE) {
      // кнопка Pick up; делаем статус pick_up
      content = 'Are you sure you want to pick up this order?';
      type = PICK_UP_ORDER;
    }
    if (status === PICK_UP) {
      // Кнопка Service; делаем статус servicing
      content = 'Are you sure you want to start servicing this order?';
      type = SERVICE_ORDER;
    }
    if (status === SERVICING) {
      // Кнопка Drop off; делаем статус drop_off
      content = 'Are you sure you want to start drop off this order?';
      type = DPOP_OFF_ORDER;
    }
    if (status === DROP_OFF) {
      // Кнопка Complete; делаем статус completed
      content = 'Are you sure you want to complete this order?';
      type = COMPLETE_ORDER;
    }

    !!content && !!type && openConfirmationDialog({ id, content, type, reason });
  };

  const handleReverseStatusClick = () => {
    let content = 'Are you sure you want to reverse the status of this order?'
    let type = REVERSE_STATUS;
    const reason = reasons.ORDER;

    openConfirmationDialog({ id, content, type, reason });
  };

  const handleAddToStorage = () => {
    setProcessing(true);
    setMenuAnchor(null);
    storeVehicle(vehicle.id)
    .then(() => setSuccess('Vehicle successfully stored'))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleAcceptOrder = () => ((!isISSC || !isUrgently) || ((isISSC || isUrgently) && status !== NEW)) ?
    setMapConfigs({ localIncident: { value: incident_location?.address, latitude:incident_location?.latitude, longitude: incident_location?.longitude } })
    .then(() => openMapDialog({ order: { id, eta, status, trucks, users }, isISSC, isUrgently })) : openAssignDialog({ order: { id, eta, status, trucks, users }, isISSC, isUrgently });

  return (
    <Root>
      <Header>
        <Box>
          <Title>{number}</Title>
          <Box sx={{ display: 'flex', gap: '24px', pt: '16px' }}>
            <Status size='large' darkBackground value={status} />
            <AccountName>{account?.name}</AccountName>
          </Box>
        </Box>
        <Box flexGrow={1} />
        <Actions>
          { (isUrgently && status !== NEW) ? null : (!!!user && showEdit && <Button color='inherit' variant='outlined' disabled={processing} onClick={handleAcceptOrder}>{(isISSC || isUrgently) && status === NEW ? 'Accept order' : 'Assign driver'}</Button>) }
          { !!!user && !!!dispatcher && showEdit && <Button color='error' variant='text' disabled={processing} onClick={handleRejectClick}>Reject</Button> }
          { showChangeStatus && <Button color='inherit' variant='outlined' disabled={processing} onClick={handleChangeStatusClick}>{orderStatusButtonText[status]}</Button> }
          { showEdit && <Button color='inherit' variant='text' disabled={processing} onClick={handleEditClick}>Edit</Button> }
          { showMenu && <Button color='inherit' disabled={processing} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)} endIcon={<MoreVertIcon />} sx={{ lineHeight: '1.0' }}>
            More
          </Button> }
        </Actions>
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{ sx: { mt: '2px', width: '240px' } }}
          keepMounted
        >
          { showEdit && <StyledMenuItem disabled={processing} onClick={handleEditClick}>Edit Order</StyledMenuItem> }
          { showViewInvoice && <StyledMenuItem onClick={() => navigate(`/invoices/${invoice?.id}`)}>View Invoice</StyledMenuItem> }
          { (showAddToStorage || showViewStorage) && 
          <StyledMenuItem disabled={processing} onClick={() => (showAddToStorage && handleAddToStorage()) || (showViewStorage && navigate(`/vehicles/${vehicle.id}`))}>
            {showAddToStorage && 'Add vehicle to storage'}
            {showViewStorage && 'View Storage'}
          </StyledMenuItem> }
          { showAdditionals && <StyledMenuItem disabled={processing} onClick={handleRequestAdditional}>Request additionals</StyledMenuItem> }
          {(isManager || isOwner) && <StyledMenuItem disabled={processing} onClick={handleDuplicateClick}>Duplicate</StyledMenuItem>}
          { showSendOrderCustomer && <StyledMenuItem disabled={processing} onClick={handleSendOrderCustomer}>Send Tracking to customer</StyledMenuItem>}
          {showEdit && <StyledMenuItem disabled={processing} onClick={handleCancelClick}>Cancel</StyledMenuItem>}
          { showRenewOrder && <StyledMenuItem disabled={processing} onClick={handleRenewClick}>Renew</StyledMenuItem> }
          { showRestartOrder && <StyledMenuItem disabled={processing} onClick={handleChangeStatusClick}>Restart the order</StyledMenuItem> }
          { !!previous_status && <StyledMenuItem disabled={processing} onClick={handleReverseStatusClick}>Undo last status</StyledMenuItem> }
        </Menu>
      </Header>
      <Details>
        <Box>
          <Typography variant='body2' color='textSecondary'>Created Date</Typography>
          <Typography variant='subtitle2'>{new Date(created_at).toLocaleDateString('en-US')}</Typography>
        </Box>
        {!!scheduled_at && <Box>
          <Typography variant='body2' color='textSecondary'>Scheduled At</Typography>
          <Typography variant='subtitle2'>{new Date(scheduled_at).toLocaleDateString('en-US', timeOptions)}</Typography>
        </Box>}
        <Box>
          <Typography variant='body2' color='textSecondary'>Account</Typography>
          <Typography variant='subtitle2'>{account?.name}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>{showPONumber ? 'PO' : 'Log'} Number</Typography>
          <Typography variant='subtitle2'>{showPONumber ? authorization_number || '-' : police_number || '-'}</Typography>
        </Box>
        { !showPONumber && <Box>
          <Typography variant='body2' color='textSecondary'>Police Code</Typography>
          <Typography variant='subtitle2'>{police_code}</Typography>
        </Box> }
        <Box>
          <Typography variant='body2' color='textSecondary'>Job Type</Typography>
          <Typography variant='subtitle2'>{_startCase(services[0]?.name) || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>ETA</Typography>
          <Typography variant='subtitle2'>{isShowScheduledAtInEta ? etaValue : (eta || '-')}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Dispatcher</Typography>
          <Typography variant='subtitle2'>{dispatcher?.first_name || '-'} {dispatcher?.last_name}</Typography>
        </Box>
      </Details>
      { (isManager || isOwner) && <>
        <DecorationContainer>
          <DecorationLeft />
          <DecorationCenter />
          <DecorationRight />
        </DecorationContainer>
        <Content>
          <ContentHead>
            <Typography variant='title'>Services</Typography>
            { showAddService && <Button startIcon={<AddIcon /> } onClick={() => setNewExpense(true)}>Add service / charge</Button>}
          </ContentHead>
          { !!id && !!invoice && <ServicesTable newExpense={newExpense} onNewExpense={setNewExpense} {...servicesData} /> }
        </Content> 
      </>}
    </Root>
  );
};

Main.propTypes = {
  id: PropTypes.string.isRequired,
  eta: PropTypes.number,
  number: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  user: PropTypes.object,
  dispatcher: PropTypes.object,
  member_phone: PropTypes.string,
  authorization_number: PropTypes.string,
  order: PropTypes.object
};

export default memo(Main);
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export const smallCardWidth = '280px';
export const mediumCardWidth = '590px';

export const DetailsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    background: theme.palette.background.default,
    padding: '32px 20px 80px',
    ...theme.scrollbars.default
  },
  [theme.breakpoints.up('xxl')]: {
   alignItems: 'flex-end',
   paddingRight: 'calc(50vw - 450px - 16px)' //scroll width
  },
}));

export const DetailsWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 900,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('xxl')]: {
    minWidth: 900,
    alignItems: 'center',
  },
}));

export const CardsGrid = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  justifyItems: 'stretch',
  gap: '30px',
  background: theme.palette.background.default,
  padding: '26px 16px 32px',
  [theme.breakpoints.up('md')]: {
    background: 'transparent',
    padding: '36px 0 0',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('xlg')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  }
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  gap: '30px',
  background: theme.palette.background.default,
  padding: '26px 16px 32px',
  [theme.breakpoints.up('md')]: {
    background: 'transparent',
    padding: '36px 0 0',
  }
}));

export const VerticalSlot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '30px',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'column',
    '& > *': {
      flex: '0 0 210px'
    },
  }
}));

export const Card = styled(Paper,
  { shouldForwardProp: (prop) => prop !== 'size' }
)(({ size, theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '24px 16px',
  ...(size === 'small' && {
    flex: `1 0 ${smallCardWidth}`,
    [theme.breakpoints.up('lg')]: {
      maxWidth: smallCardWidth
    }
  }),
  ...(size === 'medium' && {
    flex: '1 1 300px',
    [theme.breakpoints.up('md')]: {
      width: mediumCardWidth
    }
  })
}));

export const CardHeader = styled(Box)({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px'
});

export const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(25),
  fontWeight: 700
}));

export const MoreLink = styled(props => (
  <Button component={NavLink} color='primary' endIcon={<ArrowOutwardIcon />} {...props} >VIEW MORE</Button>
))({ borderRadius: 4 });

export const ItemsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '32px'
});

export const Item = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'halfWidth' }
)(({ halfWidth }) => ({
  width: !!halfWidth ? '100%' : 'auto',
  maxWidth: !!halfWidth ? 'calc(50% - 16px)' : 'unset',
}));

export const ItemLabel = styled(props => (
  <Typography variant='body2' color='textSecondary'  {...props} />
))({
});

export const ItemText = styled(props => (
  <Typography variant='subtitle2'   {...props} />
))({
  wordBreak: 'break-all',
});

export const StyledLink = styled('a')({
  textDecoration: 'none', 
  color: 'inherit'
});

export const FieldsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  gap: '16px',
  '& > *': {
    flexShrink: 0,
    flexBasis: 'calc(50% - 8px)',
    width: 'calc(50% - 8px)',
  }
});

import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import _startCase from 'lodash/startCase';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
//Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import DeliveryForm from 'components/Common/DeliveryForm/DeliveryForm';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import { accountTypes, reasons, paymentTypes } from 'helpers/constants';
import useTimezones from 'hooks/useTimezones';
import FilePreview from 'components/Common/FilePreview/FilePreview';
import { getRepresentation } from 'helpers';

const SendInvoice = ({ id, number, order, total_amount, recipients, payments, created_at }) => {
  const { closeDrawer } = useApp();
  const { open } = useCustomSelector(state => state.app.sendInvoice);
  const documents = useCustomSelector(state => state.documents.all.data);
  const { timeZone } = useTimezones(order?.company_location?.company?.id);
  const [textChecked, setTextChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [hideCharges, setHideCharges] = useState(false);
  const [documentIds, setDocumentIds] = useState([]);
  const [processing, setProcessing] = useState(false);

  const { 
    account,
    customer,
    authorization_number, 
    number: order_number,
    status: job_status,
    services,
    police_number,
    police_code
  } = order;
  const showPONumber = account?.account_type !== accountTypes.POLICE;

  const handleCheckClick = ({ id }) => {
    if (!!_find(documentIds, v => v === id)){
      setDocumentIds(_filter(documentIds, v => v !== id))
    } else {
      setDocumentIds([...documentIds, id])
    }
  };

  return (
    <BaseDrawer
      grey
      open={open}
      disabled={!open}
      onClose={() => closeDrawer(reasons.SEND_INVOICE)}
      title='Send Invoice Details'
      content={
        <>
          <Card sx={{ p: '24px' }}>
            <Typography variant='title'>{`Invoice ${number}`}</Typography>
            <Box sx={{  display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '24px 32px', pt: '24px', pb: '12px' }}>
              <Box>
                <Typography variant='body2' color='textSecondary'>Created Date</Typography>
                <Typography variant='subtitle2'>{new Date(created_at).toLocaleDateString('en-US', { timeZone })}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='textSecondary'>Account</Typography>
                <Typography variant='subtitle2'>{account?.name}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='textSecondary'>{showPONumber ? 'PO' : 'Log'} Number</Typography>
                <Typography variant='subtitle2'>{showPONumber ? authorization_number || '-' : police_number || '-'}</Typography>
              </Box>
              { !showPONumber && <Box>
                <Typography variant='body2' color='textSecondary'>Police Code</Typography>
                <Typography variant='subtitle2'>{police_code}</Typography>
              </Box> }
              <Box>
                <Typography variant='body2' color='textSecondary'>Job Type</Typography>
                <Typography variant='subtitle2'>{_startCase(services[0]?.name) || '-'}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='textSecondary'>Job Status</Typography>
                <Typography variant='subtitle2'>{_startCase(job_status)}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='textSecondary'>Payment Type</Typography>
                <Typography variant='subtitle2'>{_find(paymentTypes, ({ value }) => value === payments[payments?.length - 1]?.payment_type)?.label || '-'}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='textSecondary'>Invoice Amount</Typography>
                <Typography variant='subtitle2'>${total_amount}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='textSecondary'>Order No.</Typography>
                <Typography variant='subtitle2'>{order_number}</Typography>
              </Box>
            </Box>
            <FormControlLabel
              label='Hide charges'
              control={
                <Checkbox
                  checked={hideCharges}
                  onChange={({ target: { checked } }) => setHideCharges(checked)}
                />
              }
              sx={{ pb: '12px' }}
            />
            {!!documents.length && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pt: '12px' }}>
              <Typography variant='title' sx={{ fontSize: 18, pb: '8px' }}>Documents</Typography>
              {_map(documents, ({ id, category, file }) => {
                const checked = !!_find(documentIds, v => v === id);

                return (
                  <FilePreview
                    key={id}
                    isCheckbox
                    type={file?.content_type}
                    preview={file?.localUrl || getRepresentation(file?.representations, 'small')}
                    name={file?.filename}
                    url={file?.url}
                    label={category?.name}
                    checked={checked}
                    onCheck={() => handleCheckClick({ id })}
                  />
                );
              })}
            </Box>}
            <DeliveryForm 
              {...{ id, processing, customer, hideCharges, textChecked, emailChecked, documentIds }}
              title='Invoice Delivery Method' 
              model='invoice'
              oldRecipients={recipients} 
              onProcessing={setProcessing} 
              onTextChecked={setTextChecked}
              onEmailChecked={setEmailChecked}
              sx={{ pt: '12px'}}
            />
          </Card>
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: '40px'}}>
            <Button
              color='primary'
              variant='contained'
              endIcon={<ArrowOutwardIcon />}
              disabled={processing || (!textChecked && !emailChecked)}
              onClick={() => setProcessing(true)}
            >
              SEND
            </Button>
          </Box>
        </>
      }
    />
  );
};

export default memo(SendInvoice);

import { useLocation } from 'react-router-dom';
import _includes from 'lodash/includes';

const useUrl = () => {
  const { pathname } = useLocation();
  const isAccountsRoute = _includes(pathname, 'accounts');
  const isCompanyRoute = _includes(pathname, 'company');
  const isDriversRoute = _includes(pathname, 'drivers');
  const isManagersRoute = _includes(pathname, 'managers');
  const isTrucksRoute = _includes(pathname, 'trucks');
  const isOrdersRoute = _includes(pathname, 'orders');
  const isInvoicesRoute = _includes(pathname, 'invoices');
  const isVehiclesRoute = _includes(pathname, 'vehicles');
  const isCustomerRoute = _includes(pathname, 'order_details');

  return { isAccountsRoute, isCompanyRoute, isDriversRoute, isManagersRoute, isTrucksRoute, isOrdersRoute, isInvoicesRoute, isVehiclesRoute, isCustomerRoute };
};

export default useUrl;
import { memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// Local files
import StepLayout from '../StepLayout/StepLayout';
import { recoverySteps } from 'helpers/constants';
import { checkEmptyString } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useSessions from 'hooks/useSessions';
import useConfirmations from 'hooks/useConfirmations';

const { REQUEST, RESET } = recoverySteps;

const VerificationStep = ({ changeStep }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { setConfirmationCode } = useSessions();
  const { sendConfirmationCode, validateConfirmationCode } = useConfirmations();
  const { recoveryEmail } = useCustomSelector(state => state.sessions);
  const [code, setCode] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);

  const handleResendClick = () => {
    setLoading(true);
    sendConfirmationCode(recoveryEmail)
    .then(() => setSuccess('Code resent'))
    .catch((error) => setError(error))
    .finally(() => setLoading(false));
  }
  const handleConfirmClick = () => {
    let hasError = false;

    if (!checkEmptyString(code.value)) {
      setCode(prev => ({ ...prev, error: `Code can't be empty` }));
      hasError = true;
    }

    if (hasError) return;

    setLoading(true);
    validateConfirmationCode({ email: recoveryEmail, confirmation_code: code.value })
    .then(() => setConfirmationCode(code.value).then(() => changeStep(RESET)).then(() => setSuccess('Code validated')))
    .catch((error) => setError(error))
    .finally(() => setLoading(false));
  }
  const handleKeyDown = ({ key }) => key === 'Enter' && handleConfirmClick();

  return (
    <StepLayout
      backStep={() => changeStep(REQUEST)}
      title='Log In Verification'
      description={<>Please enter 5 digit code sent to {recoveryEmail}</>}
      content={
        <TextField
          fullWidth
          placeholder='Code'
          inputProps={{ maxLength: 5 }}
          value={code.value}
          onChange={({ target: { value } }) => setCode({ value, error: '' })}
          error={!!code.error}
          helperText={code.error}
          onKeyDown={handleKeyDown}
        />
      }
      actions={
        <Container maxWidth='xs' sx={{ pt: .5 }}>
          <Button color='primary' size='xlarge' fullWidth sx={{ mb: .5 }} disabled={loading} onClick={handleResendClick} >Resend code</Button>
          <Button variant='contained' color='primary' size='xlarge' fullWidth disabled={loading} onClick={handleConfirmClick}>Validate a code</Button>
        </Container>
      }
    />
  );
};

export default memo(VerificationStep);
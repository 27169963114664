import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import _startCase from 'lodash/startCase';
//Local files
import { Root, Header, Content, Title } from 'components/Customers/Cards/Main/Main.styled';
import Status from 'components/Orders/Status/Status';

const Main = ({ status, services, eta, customer, created_at }) => {
  const service = _startCase(services[0]?.name);

  return (
    <Root>
      <Header>
        <Title>{`${customer?.name || ''}'s ${service || ''}`}</Title>
        <Status value={status} size='large' darkBackground customer />
      </Header>
      <Content>
        <Box>
           <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Date</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{new Date(created_at).toLocaleDateString('en-US')}</Typography>
        </Box>
        <Box>
           <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Job Type</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{service || '-'}</Typography>
        </Box>
        <Box>
           <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>ETA</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{eta}</Typography>
        </Box>
      </Content>
    </Root>
  );
}

export default memo(Main);
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  width: 200,
  height: 100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  textAlign: 'left',
  background: '#fff',
  borderRadius: '8px',
  padding: '16px',
});

export const Icon = styled(Box)({
  height: '32px',
  '& > svg': {
    height: 'inherit'
  }
})

export const Label = styled(Box)({
  width: '100%',
  fontSize: '20px',
  fontWeight: 700,
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
import {
  getJobsRoutine,
  getJobRoutine,
  createJobRoutine,
  deleteJobRoutine,
  clearLocalJobsRoutine,
  clearLocalActiveJobRoutine,
  createVariantRoutine,
  updateVariantRoutine,
  deleteVariantRoutine,
  createRateRoutine,
  updateRateRoutine,
  deleteRateRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _find from 'lodash/find';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  job: { id: '', service: null, job_type: '', variants: [] }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getJobsRoutine.SUCCESS: {
      const { data: { jobs, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...jobs], pagination } };
    }
    case getJobRoutine.SUCCESS: {
      const { data: { job } } = action.payload;

      return { ...state, job };
    }
    case createJobRoutine.SUCCESS: {
      const { data: { job } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 25 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[job], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination }, job };
    }
    case deleteJobRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case clearLocalJobsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveJobRoutine.SUCCESS: {
      return { ...state, job: initialState.job }
    }
    case createVariantRoutine.SUCCESS: {
      const { data: { variant } } = action.payload;

      return { ...state, job: { ...state.job, variants: [...state.job.variants, ...[variant]] } };
    }
    case updateVariantRoutine.SUCCESS: {
      const { response: { status }, variant } = action.payload;
      const updatedJob = { ...state.job, variants: _map(state.job.variants, (item) => item.id === variant.id ? { ...item, ...variant } : item) };
      const data = _map(state.all.data, (job) => !!_find(job.variants, (item) => item.id === variant.id)
        ? { ...job, variants: _map(job.variants, (item) => item.id === variant.id ? { ...item, ...variant } : item) } : job
      );

      return { ...state, ...(status === 204 && { job: updatedJob }), all: { ...state.all, data } };
    }
    case deleteVariantRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const updatedJob = { ...state.job, variants: _filter(state.job.variants, (variant) => variant.id !== id) };
      const data = _map(state.all.data, (job) => ({ ...job, variants: _filter(state.job.variants, (variant) => variant.id !== id) }))

      return { ...state, ...(status === 204 && { job: updatedJob }), all: { ...state.all, data } };
    }
    case createRateRoutine.SUCCESS: {
      const { response: { data: { rate } }, variant_id } = action.payload;

      return { ...state, job: { 
          ...state.job, 
          variants: _map(state.job.variants, (variant) => variant.id === variant_id ? { ...variant, rates: [...[rate], ...variant.rates] } : variant) 
        } 
      };
    }
    case updateRateRoutine.SUCCESS: {
      const { response: { status }, rate } = action.payload;
      const updatedJob = { ...state.job, variants: _map(state.job.variants, (variant) => ({ ...variant, rates: _map(variant?.rates, (item) => item.id === rate.id ? { ...item, ...rate } : item )})) };
      const data = _map(state.all.data, (job) => !!_find(job.variants, (variant) => !!_find(variant.rates, (item) => item.id === rate.id))
        ? { ...job, variants: _map(job.variants, (variant) => ({ ...variant, rates: _map(variant?.rates, (item) => item.id === rate.id ? { ...item, ...rate } : item) })) } : job
      );

      return { ...state, ...(status === 204 && { job: updatedJob }), all: { ...state.all, data } };
    }
    case deleteRateRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const updatedJob = { ...state.job, variants: _map(state.job.variants, (variant) => ({ ...variant, rates: _filter(variant.rates, (rate) => rate.id !== id) })) };
      const data = _map(state.all.data, (job) => ({ ...job, variants: _map(job.variants, (variant) => ({ ...variant, rates: _filter(variant.rates, (rate) => rate.id !== id) })) }))

      return { ...state, ...(status === 204 && { job: updatedJob }), all: { ...state.all, data } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
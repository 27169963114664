import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import { checkEmptyString } from 'helpers';
import { rejectDigitalDispatchDescriptions, rejectUrgentlyDescriptions } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useOrders from 'hooks/useOrders';
import useDialogs from 'hooks/useDialogs';
import useOrderSources from 'hooks/useOrderSources';

const RejectOrder = (props) => {
  const { closeRejectOrderDialog } = useDialogs();
  const { rejectOrder } = useOrders();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { id, open, source } = useCustomSelector(state => state.dialogs.rejectOrder);
  const { isUrgently } = useOrderSources(source);
  const [description, setDescription] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);
  const rejectDescriptions = isUrgently ? rejectUrgentlyDescriptions : rejectDigitalDispatchDescriptions;
  
  const handleSubmitClick = () => {
    let hasError = false;

    if (!checkEmptyString(description.value)) {
      setDescription(prev => ({ ...prev, error: 'Description can\'t be empty' }));
      hasError = true;
    }
    if (hasError) return;

    setProcessing(true);
    rejectOrder({ id, reject_description: description.value })
    .then(() => setSuccess('Order successfully rejected').then(handleClose))
    .catch(error => setError(error).then(() => setProcessing(false)));
  };
  const handleClose = () => {
    setDescription({ value: '', error: '' });
    setProcessing(false);
    closeRejectOrderDialog();
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title='Reject Order'
      maxWidth='sm'
      fullWidth
      {...props}
    >
      <DialogContent>
        <Box>
          <FormControl variant="standard" fullWidth error={!!description.error}>
            <InputLabel id="description-label">Description</InputLabel>
            <Select
              labelId="description-label"
              id="description"
              value={description.value}
              onChange={({ target: { value } }) => setDescription({ value, error: '' })}
              label="Description"
            >
              {_map(rejectDescriptions, ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
            </Select>
            {!!description.error && <FormHelperText error>{description.error}</FormHelperText>}
          </FormControl>
        </Box>
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleClose}
        >
          Close
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleSubmitClick}
        >
          Submit
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(RejectOrder);
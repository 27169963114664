import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useServices from 'hooks/useServices';
import { serviceTypes } from 'helpers/constants';
import { getRandomString } from 'helpers';

const filter = createFilterOptions();

const ServicesAutocomplete = ({ value, label, margin = 'none', size = 'medium', type = null, error, helperText, onChange, onClick = null, account = false, expenses = false }) => {
  const { getListServices, createService, clearLocalListServices } = useServices();
  const { setError } = useError();
  const { basic, additional, all } = useCustomSelector(state => state.services.list);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const data = useMemo(() => type === serviceTypes.BASIC ? basic.data : type === serviceTypes.ADDITIONAL ? additional.data : all.data, [type, basic.data, additional.data, all.data]);
  const pagination = useMemo(() => type === serviceTypes.BASIC ? basic.pagination : type === serviceTypes.ADDITIONAL ? additional.pagination : all.pagination, [type, basic.pagination, additional.pagination, all.pagination]);
  const multiple = Array.isArray(value);

  const handleChange = (_, formattedValue) => { 
    if ((!!formattedValue?.inputValue || !!formattedValue[0]?.inputValue) && !expenses) {
      createService({ name: formattedValue?.inputValue || formattedValue[0]?.inputValue, service_type: type || serviceTypes.BASIC })
      .then(({ payload: { data: { service } } }) => !!onClick ? onClick(service.id) : onChange({ value: multiple ? [service.id] : service.id, formattedValue:  multiple ? [service] : service, error: '' }))
      .catch((error) => setError(error))
    } else {
      !!onClick ? onClick(formattedValue?.id) :
      onChange({ value: multiple ? _map(formattedValue, (service) => service?.id) : formattedValue?.id || `new_${getRandomString(24)}`, formattedValue, error: '' });
    }
  };
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option.name);

    if (inputValue !== '' && !isExisting && (!!pagination.total_count || pagination.total_count === -1)) filtered.push({ inputValue, name: `Add ${expenses ? 'one time ' :  ''}'${inputValue}'` });

    return filtered;
  };
  const getOptionLabel = option => {
    if (typeof option === 'string') return option;
    if (option.inputValue) return option.inputValue;
    return option.name;
  };
  const isOptionEqualToValue = (o, v) => o?.id === v?.id;
  const fetchServices = useCallback((offset, service_type, query) => {
    setLoading(true);
    getListServices({ offset, service_type, query })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListServices, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchServices(limit + offset, type, query);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => fetchServices(0, type, query), !!query ? 500 : 0);

    return () => { 
      clearLocalListServices();
      clearTimeout(timeout); 
    };
  }, [query, fetchServices, clearLocalListServices, type]);
  useEffect(() => {
    account && data?.length && setOpen(true);
  }, [account, data?.length]);

  return (
    <Autocomplete
      fullWidth
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={value}
      multiple={multiple}
      onChange={handleChange}
      filterOptions={filterOptions}
      id="servicesList"
      options={data}
      loading={loading}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={(_, value, reason) => reason !== 'reset' && setQuery(value)}
      renderOption={(props, option) => <li key={option?.id || option.name} {..._omit(props, ['key'])}>{option.name}</li>}
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      renderInput={params => <TextField {...params} margin={margin} size={size} label={label} error={error} helperText={helperText} />}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    />
  );
};

export default memo(ServicesAutocomplete);
import {
  getUserOrdersRoutine,
  getOrdersForDashboardRoutine,
  getOrdersRoutine,
  getCustomersOrdersRoutine,
  getInvoicesOrdersRoutine,
  getVehiclesOrdersRoutine,
  getOrderRoutine,
  getOrderForNotificationRoutine,
  createOrderRoutine,
  updateOrderRoutine,
  deleteOrderRoutine,
  supplementOrderRoutine,
  rejectOrderRoutine,
  cancelOrderRoutine,
  renewOrderRoutine,
  startOrderMovementRoutine,
  pickUpOrderRoutine,
  serviceOrderRoutine,
  dropOffOrderRoutine,
  completeOrderRoutine,
  reverseStatusRoutine,
  changeLocalOrderStatusRoutine,
  clearLocalUserOrdersRoutine,
  clearLocalOrdersWithUserRoutine,
  clearLocalOrdersRoutine,
  clearLocalCustomersOrdersRoutine,
  clearLocalInvoicesOrdersRoutine,
  clearLocalVehiclesOrdersRoutine,
  clearLocalActiveOrderRoutine,
  setLocalOrderFieldsRoutine,
  getOrdersStatisticsRoutine,
  clearOrdersStatisticsRoutine,
  suggestInvoiceExpensesRoutine,
  filterExpencesDataRoutine,
  createExpenseRoutine,
  updateExpenseRoutine,
  deleteExpenseRoutine,
  getInvoiceForNotificationRoutine,
  updateInvoiceRoutine,
  updateVehicleRoutine,
  createPaymentRoutine,
  createCardRoutine,
  storeVehicleRoutine,
  createRecipientsRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';
import _startsWith from 'lodash/startsWith';
import _omit from 'lodash/omit';
import { orderStatuses, invoiceStatuses, vehicleStatuses, orderSources } from 'helpers/constants';
import { getExpenseTotal, getRandomString } from 'helpers';

const { DIGITAL_DISPATCH, WIDGET } = orderSources;

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  user: { data: [], pagination: { limit: 5, offset: 0, count: 0, total_count: 0 } },
  dashboard: { data: [], pagination: { limit: 4, offset: 0, count: 0, total_count: 0 } },
  customers: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  invoices: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  vehicles: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  statistics: {
    new_orders_count: 0,
    completed_orders_count: 0,
    rejected_orders_count: 0,
    expired_orders_count: 0,
    eta_accuracy: null,
    accepted_orders_count: 0,
    accepted_orders_percent: ''
  },
  order: {
    id: '',
    account: null,
    customer: null,
    vehicle: null,
    company: null,
    dispatcher: null,
    user: null,
    invoice: { id: '', number: '', status: '', expenses: [], payments: [], file: null, total_amount: 0, paid_amount: 0, minimum_amount: 0, },
    number: '',
    notes: '',
    status: '',
    previous_status: '',
    incident_location: null,
    destination_location: null,
    call_back_phone: '',
    required_acknowledge_time: null,
    eta: 0,
    priority: '',
    urgency_level: '',
    service_comments: '',
    equipment_type: '',
    job_offer_amount: 0,
    authorization_number: '',
    services: [],
    truck: null,
    source: '',
    deadhead_miles_count: 0, 
    en_route_miles_count: 0, 
    towed_miles_count: 0,
    customer_token: null,
    recipients: [],
    localDocuments: [],
    scheduled_at: '',
    urgently_partner_name: '',
    urgently_disablement_reason: '',
    urgently_callback: ''
  },
  ordersInProgress: 0,
  ordersWithoutUser: 0,
  ordersScheduled: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getUserOrdersRoutine.SUCCESS: {
      const { data: { orders: data, pagination } } = action.payload;

      return { ...state, user: { data, pagination } };
    }
    case getOrdersForDashboardRoutine.SUCCESS: {
      const { response: { data: { orders, pagination } }, isCounts, isScheduled, with_user, without_user } = action.payload;

      return { 
        ...state, 
        ...(!isCounts && { dashboard: { data: [...state.dashboard.data, ...orders], pagination: { ...state.dashboard.pagination, ...(pagination.limit !== 1 && { ...pagination }) } } }),
        ...(isCounts && { ...(with_user && { ordersInProgress: pagination.total_count }), ...(without_user && { ordersWithoutUser: pagination.total_count }), ...(isScheduled && { ordersScheduled: pagination.total_count }) })
      };
    }
    case getOrdersRoutine.SUCCESS: {
      const { data: { orders, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...orders], pagination } };
    }
    case getCustomersOrdersRoutine.SUCCESS: {
      const { data: { orders, pagination } } = action.payload;

      return { ...state, customers: { data: [...state.customers.data, ...orders], pagination } };
    }
    case getInvoicesOrdersRoutine.SUCCESS: {
      const { data: { orders, pagination } } = action.payload;

      return { ...state, invoices: { data: [...state.invoices.data, ...orders], pagination } };
    }
    case getVehiclesOrdersRoutine.SUCCESS: {
      const { data: { orders, pagination } } = action.payload;

      return { ...state, vehicles: { data: [...state.vehicles.data, ...orders], pagination } };
    }
    case getOrderRoutine.SUCCESS: {
      const { data: { order } } = action.payload;
      return { ...state, order };
    }
    case getOrderForNotificationRoutine.SUCCESS: {
      const { data: { order } } = action.payload;
      const updatedOrder = _mergeWith({ ...state.order }, order, (a, b) => b !== a ? b : a);
      const foundOrder = _find(state.all.data, o => o.id === order.id);
      const foundedDashboardOrder = _find(state.dashboard.data, o => o.id === order.id);
      const isAttentionNeeded = (!!order?.user && foundedDashboardOrder?.user?.id !== order?.user?.id) || order.status === orderStatuses.REJECTED;
      const fromAttentionToInProgress = !!foundedDashboardOrder && isAttentionNeeded;
      const isISSC = order?.source === DIGITAL_DISPATCH;
      const isWidget = order?.source === WIDGET;
      const data = !!foundOrder ? _map(state.all.data, o => o.id === order.id ? { ...o, ...order } : o) : (isISSC || isWidget) ? [...[order], ...state.all.data] : state.all.data;
      const dashboardData = isAttentionNeeded ? _filter(state.dashboard.data, (o) => o.id !== order.id) : !!foundedDashboardOrder ? _map(state.dashboard.data, o => o.id === order.id ? { ...o, ...order } : o) : [...[order], ...state.dashboard.data].slice(0,4);
      const pagination = (!!foundOrder || (!!!foundOrder && (isISSC || isWidget)))
        ? state.all.pagination
        : { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };
      const dashboardPagination = fromAttentionToInProgress
        ? { ...state.dashboard.pagination, count: state.dashboard.pagination.count - 1, total_count: state.dashboard.pagination.total_count - 1 } 
        : !!foundedDashboardOrder ? state.dashboard.pagination :
        { ...state.dashboard.pagination, count: state.dashboard.pagination.count + 1, total_count: state.dashboard.pagination.total_count + 1 };
      const ordersInProgress = fromAttentionToInProgress && order.status !== orderStatuses.REJECTED ? state.ordersInProgress + 1 : state.ordersInProgress;
      const ordersWithoutUser = (state.ordersWithoutUser >= 0 && fromAttentionToInProgress) ? state.ordersWithoutUser - 1 : (order.status !== orderStatuses.REJECTED && !!!order.user) ? state.ordersWithoutUser + 1 : state.ordersWithoutUser;

      return { ...state, 
        ...(state.order.id === order.id && { order: updatedOrder }),
        all: { data, pagination },
        dashboard: {
          data: dashboardData,
          pagination: dashboardPagination
        },
        ordersInProgress,
        ordersWithoutUser
      };
    }
    case createOrderRoutine.SUCCESS: {
      const { data: { order } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[order], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateOrderRoutine.SUCCESS: {
      const { response: { status }, order } = action.payload;
      const updatedOrder = { ...state.order, ..._omit(order, ['localUser']), ...(!!order.localUser && { user: order.localUser, truck: order.localUser?.trucks[0] }) };
      const data = _map(state.all.data, (item) => item.id === order.id ? { ...item, ..._omit(order, ['localUser']), ...(!!order.localUser && { user: order.localUser }) } : item);
      const foundedDashboardOrder = _find(state.dashboard.data, (o) => o.id === order.id);
      const isDashboardOrderInProgress = ((!!order?.user || !!order?.localUser) && !!foundedDashboardOrder?.user);

      return { 
        ...state, 
        ...(status === 204 && state.order.id && { order: updatedOrder }), 
        all: { ...state.all, data },
        ...(isDashboardOrderInProgress && { 
          dashboard: { 
            ...state.dashboard, 
            data: _map(state.dashboard.data, (o) => o.id === order.id ? 
            { ...o, ..._omit(order, ['localUser']), ...(!!order.localUser && { user: order.localUser }) } : o) 
          } 
        }),
        ...(!foundedDashboardOrder?.user && !!order?.localUser && foundedDashboardOrder?.status !== orderStatuses.SCHEDULED && {
          dashboard: { 
            ...state.dashboard, 
            data:_filter(state.dashboard.data, (item) => item.id !== order.id),
            pagination: { ...state.dashboard.pagination, count: state.dashboard.pagination.count - 1, total_count: state.dashboard.pagination.total_count - 1 }
          },
          ordersWithoutUser: state.ordersWithoutUser - 1,
          ordersInProgress: state.ordersInProgress + 1

        }),
        ...(!foundedDashboardOrder?.user && !!order?.localUser && foundedDashboardOrder?.status === orderStatuses.SCHEDULED && {
          dashboard: { 
            ...state.dashboard, 
            data: _map(state.dashboard.data, (o) => o.id === order.id ? 
            { ...o, ..._omit(order, ['localUser']), ...(!!order.localUser && { user: order.localUser }) } : o) 
          } 
        })
      };
    }
    case deleteOrderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case supplementOrderRoutine.SUCCESS: {
      const { response: { status, data }, user, eta } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.MANAGER_ACCEPTED, previous_status: data.order.previous_status, ...(!!user && { user } ), eta };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) }
        })
      };
    }
    case rejectOrderRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.REJECTED, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) },
        })
      };
    }
    case cancelOrderRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.CANCELED, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) },
        })
      };
    }
    case renewOrderRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.EN_ROUTE, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) },
        })
      };
    }
    case startOrderMovementRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.EN_ROUTE, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) }
        })
      };
    }
    case pickUpOrderRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.PICK_UP, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) }
        })
      };
    }
    case serviceOrderRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.SERVICING, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) }
        })
      };
    }
    case dropOffOrderRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.DROP_OFF, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) }
        })
      };
    }
    case completeOrderRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: orderStatuses.COMPLETED, previous_status: data.order.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, order => order.id === processableOrder.id ? processableOrder : order) },
          user: { ...state.user, data: _map(state.user.data, order => order.id === processableOrder.id ? processableOrder : order) }
        })
      };
    }
    case reverseStatusRoutine.SUCCESS: {
      const { response: { status, data } } = action.payload;
      const processableOrder = { ...state.order, status: data.status, previous_status: data.previous_status };

      return {
        ...state,
        ...(status === 204 && {
          order: processableOrder,
          all: { ...state.all, data: _map(state.all.data, o => o.id === processableOrder.id ? processableOrder : o) },
          user: { ...state.user, data: _map(state.user.data, o => o.id === processableOrder.id ? processableOrder : o) }
        })
      };
    }
    case changeLocalOrderStatusRoutine.SUCCESS: {
      const { order_id, status, previous_status } = action.payload;
      const all = !!_find(state.all.data, o => o.id === order_id)
        ? { ...state.all, data: _map(state.all.data, o => o.id === order_id ? { ...o, status, previous_status } : o) }
        : state.all;
      const user = !!_find(state.user.data, o => o.id === order_id)
      ? { ...state.user, data: _map(state.user.data, o => o.id === order_id ? { ...o, status, previous_status } : o) }
      : state.user;
      const order = state.order.id === order_id ? { ...state.order, status, previous_status } : state.order;

      return { ...state, all, user, order };
    }
    case clearLocalUserOrdersRoutine.SUCCESS: {
      return { ...state, user: initialState.user };
    }
    case clearLocalOrdersWithUserRoutine.SUCCESS: {
      return { ...state, dashboard: initialState.dashboard }
    }
    case clearLocalOrdersRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalCustomersOrdersRoutine.SUCCESS: {
      return { ...state, customers: initialState.customers }
    }
    case clearLocalInvoicesOrdersRoutine.SUCCESS: {
      return { ...state, invoices: initialState.invoices }
    }
    case clearLocalVehiclesOrdersRoutine.SUCCESS: {
      return { ...state, vehicles: initialState.vehicles }
    }
    case clearLocalActiveOrderRoutine.SUCCESS: {
      return { ...state, order: initialState.order };
    }
    case setLocalOrderFieldsRoutine.SUCCESS: {
      return { ...state, order: { ...state.order, ...action.payload } }
    }
    case getOrdersStatisticsRoutine.SUCCESS: {
      const { response: { data: { statistics } }, isEta } = action.payload;

      return { ...state, statistics: { ...state.statistics, ...(isEta ? { eta_accuracy: statistics.eta_accuracy } : statistics) } };
    }
    case clearOrdersStatisticsRoutine.SUCCESS: {
      return { ...state, statistics: initialState.statistics }
    }
    case suggestInvoiceExpensesRoutine.SUCCESS: {
      const { response: { data: { expenses } }, expense_name } = action.payload;
      const data = !!expenses.length ? _map(expenses, (expense) => ({ ...expense, id: `new_${expense?.rate?.id || expense?.default_charge?.id}` })) :
      [{ id: `new_${getRandomString(24)}`, rate: null, default_charge: null, name: expense_name, unit_price: null, quantity: 1, hourly_charge_duration: 'PT0H0M' }];

      return { ...state, ...(!!state.order.id && { order: { ...state.order, invoice: { ...state.order.invoice, expenses: [...state.order.invoice?.expenses, ...data] } } }) }
    }
    case filterExpencesDataRoutine.SUCCESS: {
      const { expense_id } = action.payload;
      const foundedExpense = !!_find(state.order.invoice?.expenses, (expense) => expense?.id === expense_id);
      const expenses = _filter(state.order.invoice?.expenses, (expense) => expense?.id !== expense_id);

      return { ...state, ...(foundedExpense && { order: { ...state.order, invoice: { ...state.order.invoice, expenses } } }) }
    }
    case createExpenseRoutine.SUCCESS: {
      const { response: { data: { expense } }, invoice_id } = action.payload;

      return { ...state, 
        ...(state.order.invoice?.id === invoice_id && { 
          order: { 
            ...state.order, 
            invoice: { 
              ...state.order.invoice, 
              expenses: [...state.order.invoice?.expenses, ...[expense]],
              ...(state.order.invoice.minimum_amount < (state.order.invoice.total_amount + getExpenseTotal(expense)) && { total_amount: state.order.invoice.total_amount + getExpenseTotal(expense) })
            } 
          } 
        })
      }
    }
    case updateExpenseRoutine.SUCCESS: {
      const { response: { status }, expense } = action.payload;

      return { ...state, ...(status === 204 && !!_find(state.order.invoice.expenses, (e) => e.id === expense.id) && 
        { order: { ...state.order, invoice: { ...state.order.invoice, expenses: _map(state.order.invoice.expenses, (e) => e.id === expense.id ? { ...e, ...expense } : e) } } }) 
      };
    }
    case deleteExpenseRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && _find(state.order.invoice?.expenses, (expense) => expense?.id === id) && 
          { order: { ...state.order, invoice: { ...state.order.invoice, expenses: _filter(state.order.invoice?.expenses, (expense) => expense?.id !== id) } }
        })
      }
    }
    case getInvoiceForNotificationRoutine.SUCCESS: {
      const { data: { invoice } } = action.payload;
      const filteredInvoice = { 
        ...invoice, 
        expenses: !!_find(state.order.invoice.expenses, (e) => _startsWith(e.id, 'new_')) ? state.order.invoice.expenses : invoice.expenses 
      };

      return { ...state, ...(state.order.invoice?.id === invoice.id && { order: { ...state.order, invoice: { ...state.order.invoice,  ...filteredInvoice } } }) };
    }
    case updateInvoiceRoutine.SUCCESS: {
      const { response: { status }, invoice } = action.payload;

      return { ...state, ...(status === 204 && state.order.invoice.id === invoice.id && { order: { ...state.order, invoice: { ...state.order.invoice, ...invoice } } }) };
    }
    case updateVehicleRoutine.SUCCESS: {
      const { response: { status }, vehicle } = action.payload;

      return { ...state, ...(status === 204 && state.order.vehicle?.id === vehicle.id && { order: { ...state.order, vehicle: { ...state.order.vehicle, ...vehicle } } }) };
    }
    case createPaymentRoutine.SUCCESS: {
      const { response: { data: { payment } }, invoice_id } = action.payload;

      return { ...state, 
        ...(state.order.invoice?.id === invoice_id && { 
          order: { 
            ...state.order, 
            invoice: { 
              ...state.order.invoice, 
              ...(state.order.invoice.status === invoiceStatuses.APPROVED && { status: invoiceStatuses.PARTIALLY_PAID }),
              ...(state.order.invoice.total_amount <= state.order.invoice.paid_amount + payment.amount && { status: invoiceStatuses.PAID }),
              payments: [...state.order.invoice?.payments, ...[payment]],
              paid_amount: state.order.invoice.paid_amount + payment.amount
            } 
          } 
        })
      }
    }
    case createCardRoutine.SUCCESS: {
      const { response: { data: { card } }, customer_id } = action.payload;

      return { ...state, 
        ...(state.order.customer?.id === customer_id && { 
          order: { 
            ...state.order, 
            customer: { 
              ...state.order.customer,
              cards: [...[card], ...state.order.customer?.cards]
            } 
          } 
        })
      }
    }
    case storeVehicleRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && state.order.vehicle?.id === id && { 
          order: { 
            ...state.order, 
            vehicle: { 
              ...state.order.vehicle,
              status: vehicleStatuses.STORED
            } 
          } 
        })
      }
    }
    case createRecipientsRoutine.SUCCESS: {
      const { response: { status }, recipients } = action.payload;

      return { ...state, 
        ...(status === 204 && !!state.order.id && { order: { ...state.order, recipients: [...state.order?.recipients, ...recipients] } 
        })
      }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
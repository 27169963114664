import { memo, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import _pick from 'lodash/pick';
//Local files
import { Root, Message, CardsContainer } from './Customer.styled';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import Main from 'components/Customers/Cards/Main/Main';
import MapCard from 'components/Map/Card/Card';
// import VehicleForm from 'components/Vehicles/Form/Form';
import VehiclesCard from 'components/Vehicles/Cards/Card/Card';
import DriverCard from 'components/Driver/Card/Card';
import useCustomSelector from 'hooks/useCustomSelector';
import useLocations from 'hooks/useLocations';
import useOrders from 'hooks/useOrders';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import { sides } from 'helpers/constants';

const Customer = () => {
  const { clearLocalActiveOrder, updateOrder } = useOrders();
  const { createLocation } = useLocations();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const fetchedOrder = useCustomSelector(state => state.orders.order);
  const [processing, setProcessing] = useState(false);
  // const [editVehicle, setEditVehicle ] = useState(false);

  const mainData = _pick(fetchedOrder, ['id', 'status', 'services', 'eta', 'customer', 'created_at']);
  const driverData = _pick(fetchedOrder, ['id', 'user', 'dispatcher']);
  const vehicleData = _pick(fetchedOrder, ['vehicle', 'keys_availability', 'driver_attendance']);

  const handleLocation = ({ address, longitude, latitude }) => {
    setProcessing(true);
    createLocation({ location: { address, longitude, latitude }, side: sides.CUSTOMER })
    .then(({ payload: { data: { location } } }) => 
      updateOrder({ id: fetchedOrder.id, side: sides.CUSTOMER, customer_location_id: location.id })
      .then(() => setSuccess('Location successfully sent'))
      .catch((error) => setError(error))
      .finally(() => setProcessing(false))
    )
    .catch((error) => setError(error))
  };

  useEffect(() => {
    return () => {
      clearLocalActiveOrder();
      localStorage.removeItem('customer_token');
    };
  }, [clearLocalActiveOrder]);

  if (!!!fetchedOrder.id) return <PageLoader />;
  return (
    <Root>
      <Container maxWidth='sm' sx={{ px: '20px' }}>
        <Message mb='12px'>{`Hi, ${fetchedOrder.customer?.name || ''}!`}</Message>
        <Message>We've received your request! <br/>Here's your order status.</Message>
        <CardsContainer>
          <Main {...mainData} />
          <MapCard
            firstLabel='Pickup Address'
            secondaryLabel='Destination Address'
            incident_location={fetchedOrder.customer_location || fetchedOrder.incident_location}
            destination_location={fetchedOrder.destination_location}
            locationProcessing={processing}
            onChangeLocation={handleLocation}
            isShowSendLocation={!!!fetchedOrder.customer_location}
            sx={{ height: 300 }}
          />
          <DriverCard showDriverCall {...driverData} />
          <VehiclesCard {...vehicleData} />
        </CardsContainer>
      </Container>
    </Root>
  );
}

export default memo(Customer);
import { memo } from 'react';
import Zoom from '@mui/material/Zoom';
//Local files
import { Content, Root, Title, StyledTooltip } from './Nav.styled';

const Nav = ({ icon, title, selected, to, onClick }) => {
  return (
    <Root to={to} selected={selected} onClick={onClick}>
      <StyledTooltip TransitionComponent={Zoom} title={title}>
        <Content>
          {icon}
          <Title>{title}</Title>
        </Content>
      </StyledTooltip>
    </Root>
  );
};

export default memo(Nav);
import { memo, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import BaseFilter from 'components/Common/BaseFilter/BaseFilter';
import Drawer from 'components/Accounts/Drawer/Drawer';
import BaseListPage from 'components/Common/BaseListPage/BaseListPage';
import AccountsList from 'components/Accounts/List/List';
import Details from 'components/Accounts/Details/Details';
import Filter from 'components/Common/Filter/Filter';
import useApp from 'hooks/useApp';
import useAccounts from 'hooks/useAccounts';
import useCustomSelector from 'hooks/useCustomSelector';
import { accountTypes, reasons } from 'helpers/constants';

const Accounts = () => {
  const { openDrawer, setFilter } = useApp();
  const navigate = useNavigate();
  const { clearLocalActiveAccount } = useAccounts();
  const { open } = useCustomSelector(state => state.app.filter);
  const [query, setQuery] = useState('');
  const [types, setTypes] = useState([]);
  const [fetching, setFetching] = useState(false);

  const handleQueryChange = (value) => setQuery(value);
  const handleFilterChange = () => setFilter(!open);
  const handleAddClick = () => { 
    navigate('/accounts'); 
    clearLocalActiveAccount().then(() => openDrawer({ reason: reasons.ACCOUNT, edit: false }));
  };

  useEffect(() => {
    return clearLocalActiveAccount;
  }, [clearLocalActiveAccount]);

  return (
    <>
      <ComponentErrorWrapper children={<Drawer />} />
      <BaseListPage
        renderFilter={
          <Filter
            query={query}
            onQueryChange={handleQueryChange}
            onFilterClick={handleFilterChange}
            onAddClick={handleAddClick}
            filterContent={
              <BaseFilter 
                label='Account Types'
                value={types} 
                onChange={setTypes} 
                data={accountTypes} 
              />
            }
          />
        }
        listLoading={fetching}
        listComponent={
          <ComponentErrorWrapper children={
              <AccountsList
                query={query}
                fetching={fetching}
                types={types}
                onFetching={v => setFetching(v)}
              />
            } 
          />
        }
        renderMainAtDesktop={<Details />}
      />
      <Outlet />
    </>
  );
};

export default memo(Accounts);
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'selected' }
)(({ selected, theme }) => ({
  flex: '0 0 60px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  background: selected ? theme.palette.background.selected : 'transparent',
  padding: '4px 12px ',
  justifyContent: 'space-between'
}))

import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  '& > *': {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '870px',
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(42),
  fontWeight: 700,
}));

export const AmountText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 700,
}));

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  color: '#fff',
  background: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: ' 24px 16px',
  padding: '20px 24px',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px 12px 0 0',
  }
}));

export const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
})

export const Details = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '16px 32px',
  background: '#fff',
  padding: '24px 16px 8px',
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 7px 10px 0px #0000001A',
    padding: '24px 30px 8px',
  }
}))

export const Content = styled(Box)(({ theme }) => ({
  background: '#fff',
  padding: '6px 16px 24px',
  boxShadow: `inset 0px -1px 0px ${theme.palette.divider}`,
  [theme.breakpoints.up('md')]: {
    borderRadius: '0 0 12px 12px',
    boxShadow: '0px 7px 10px 0px #0000001A',
    padding: '6px 24px 24px',
  }
}))

export const ContentHead = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '16px 32px',
})

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.primary.main,
  fontWeight: 500
}));

//Decoration
export const DecorationContainer = styled(Box)(({ theme }) => ({
  height: 40,
  display: 'none',
  position: 'relative',
  background: '#fff',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

export const DecorationLeft = styled(Box)({
  height: 40,
  width: 40,
  borderRadius: '50%',
  overflow: 'hidden',
  background: '#F8F8FD',
  position: 'absolute', left: -20, top: 0,
  boxShadow: 'inset 3px 3px 10px 0px #0000001A',
});

export const DecorationRight = styled(Box)({
  height: 40,
  width: 40,
  borderRadius: '50%',
  overflow: 'hidden',
  background: '#F8F8FD',
  position: 'absolute', right: -20, top: 0,
  boxShadow: 'inset 3px 3px 10px 0px #0000001A',
});

export const DecorationCenter = styled(Box)({
  flexGrow: 1,
  paddingTop: 20,
  borderBottom: '1px dotted #D0CEE4'
});

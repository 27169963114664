import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getRate as getRateAction,
  createRate as createRateAction,
  updateRate as updateRateAction,
  deleteRate as deleteRateAction,
  clearLocalRates as clearLocalRatesAction,
  clearLocalActiveRate as clearLocalActiveRateAction
} from 'actions/rates';

const useRates = () => {
  const dispatch = useDispatch();
  const getRate = useCallback(id => dispatch(getRateAction(id)), [dispatch]);
  const createRate = useCallback(({ rate, variant_id }) => dispatch(createRateAction({ rate, variant_id })), [dispatch]);
  const updateRate = useCallback(rate => dispatch(updateRateAction(rate)), [dispatch]);
  const deleteRate = useCallback(id => dispatch(deleteRateAction(id)), [dispatch]);
  const clearLocalRates = useCallback(() => dispatch(clearLocalRatesAction()), [dispatch]);
  const clearLocalActiveRate = useCallback(() => dispatch(clearLocalActiveRateAction()), [dispatch]);

  return {
    getRate,
    createRate,
    updateRate,
    deleteRate,
    clearLocalRates,
    clearLocalActiveRate
  };
};

export default useRates;
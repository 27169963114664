import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  createVariantRoutine,
  updateVariantRoutine,
  deleteVariantRoutine
} from 'actions';

const attributes = [
  'id', 
  'service', 
  'rates', 
  'variant_type', 
  'mile_rounding_type', 
  'time_rounding_type',
  'minimum_duration',
  'minimum_amount', 
  'en_route_free_miles', 
  'towed_free_miles', 
  'deadhead_free_miles'
];

export const createVariant = getThunkActionCreator(
  createVariantRoutine,
  async ({ variant, job_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/jobs/${job_id}/variants`, { access_token, variant, attributes });
  }
);
export const updateVariant = getThunkActionCreator(
  updateVariantRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/variants/${id}`, { access_token, variant: fields });

    return { response, variant: { id, ...fields } };
  }
);
export const deleteVariant = getThunkActionCreator(
  deleteVariantRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/variants/${id}`, { params: { access_token } });

    return { response, id };
  }
);
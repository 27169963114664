import React, { memo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
// Local files
import { hideSuccess as hideSuccessAction } from 'actions/success';
import useCustomSelector from 'hooks/useCustomSelector';

const SuccessSnackbar = () => {
  const dispatch = useDispatch();
  const hideSuccess = () => dispatch(hideSuccessAction());
  const { open, message } = useCustomSelector(state => state.success);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={hideSuccess}
    >
      <Alert severity="success" onClose={hideSuccess}>{message}</Alert> 
    </Snackbar>
  );
};

export default memo(SuccessSnackbar);
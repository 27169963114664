import { memo, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
// Local files
import { Root, Head } from './SmallWidget.styled';
import BaseStatsCard from 'components/Common/BaseStatsCard/BaseStatsCard';
import useCustomSelector from 'hooks/useCustomSelector';
import useInvoices from 'hooks/useInvoices';
import useOrders from 'hooks/useOrders';
import useVehicles from 'hooks/useVehicles';
import useError from 'hooks/useError';
import { widgetLabels } from 'helpers/constants';

const SmallWidget = ({ widget, onDelete }) => {
  const { getInvoicesStatistics } = useInvoices();
  const { getOrdersStatistics } = useOrders();
  const { getVehiclesStatistics } = useVehicles();
  const { setError } = useError();
  const {
    total_invoices_count,
    total_invoices_amount,
    average_invoices_amount,
    monthly_change,
    approved_invoices_count,
    approved_invoices_amount
  } = useCustomSelector(state => state.invoices.statistics);
  const {
    accepted_orders_count,
    accepted_orders_percent
  } = useCustomSelector(state => state.orders.statistics);
  const {
    stored_vehicles_count,
    stored_vehicles_invoice_amount
  } = useCustomSelector(state => state.vehicles.statistics);
  const isCallReponse = widget.widget_type === 'call_response_rate';
  const isStorageOverview = widget.widget_type === 'storage_overview';
  const isInvoiceWidget = !isCallReponse && !isStorageOverview;

  const firstValue = (type) => {
    switch (type) { 
      case 'unpaid_invoice': return approved_invoices_amount;
      case 'invoice_overview': return total_invoices_amount;
      case 'average_invoice': return average_invoices_amount;
      case 'call_response_rate': return accepted_orders_count;
      case 'storage_overview': return stored_vehicles_invoice_amount;
      default: return null;
    }
  };
  const secondValue = (type) => {
    switch (type) { 
      case 'unpaid_invoice': return approved_invoices_count;
      case 'invoice_overview': return total_invoices_count;
      case 'average_invoice': return monthly_change;
      case 'call_response_rate': return accepted_orders_percent;
      case 'storage_overview': return stored_vehicles_count;
      default: return null;
    }
  };

  useEffect(() => {
    isInvoiceWidget && getInvoicesStatistics({ reason: widget.widget_type }).catch((error) => setError(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    isCallReponse && getOrdersStatistics({}).catch((error) => setError(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    isStorageOverview && getVehiclesStatistics().catch((error) => setError(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Head>
        <IconButton 
          color='inherit'
          size='small' 
          aria-label='menu' 
          onClick={onDelete}
        >
          <Close />
        </IconButton>
      </Head>
      <Root>
        <BaseStatsCard 
          title={widgetLabels[widget.widget_type]?.title} 
          firstValue={`${!isCallReponse ? '$' : ''}${firstValue(widget.widget_type)}`} 
          firstLabel={widgetLabels[widget.widget_type]?.firstLabel}
          secondValue={secondValue(widget.widget_type)} 
          secondLabel={widgetLabels[widget.widget_type]?.secondLabel}
          sx={{ backgroundColor: 'transparent', color: 'white' }}
        />
      </Root>
    </>
  );
};

export default memo(SmallWidget);
import { memo, useState } from 'react';
import _pick from 'lodash/pick';
// Local files
import { Card, CardHeader, CardTitle } from 'components/Common/BasePageUi/BasePageUi';
// this code was causing issue as there is no file related to Logs/Logs
// import Logs from './Logs/Logs';
import Company from './Company/Company';
import Location from './Location/Location';
import Dispatch from './Dispatch/Dispatch';
import BaseAccordion from './BaseAccordion/BaseAccordion';

const Info = ({ id, company_location, digital_dispatch_webhook, sx }) => {
  const [expanded, setExpanded] = useState('log');

  const handleChange = (panel) => (_, isExpanded) => setExpanded(isExpanded ? panel : false);

  return (
    <>
      <Card size='medium' sx={{ alignSelf: 'flex-start', pb: 0, ...sx }}>
        <CardHeader sx={{ justifyContent: 'space-between'}}>
          <CardTitle>Other Info</CardTitle>
        </CardHeader>
        {/*this code was causing issue as there is no file related to Logs/Logs*/}
        {/*<BaseAccordion*/}
        {/*  title='Log'*/}
        {/*  expanded={expanded === 'log'}*/}
        {/*  onChange={handleChange('log')}*/}
        {/*  content={<Logs {...{ id, company_location }} />}*/}
        {/*/>*/}
        <BaseAccordion
          title='Company'
          expanded={expanded === 'company'}
          onChange={handleChange('company')}
          content={<Company {..._pick(company_location?.company, ['name'])} />}
        />
        <BaseAccordion
          title='Location'
          expanded={expanded === 'location'}
          onChange={handleChange('location')}
          content={<Location {..._pick(company_location, ['name', 'email', 'phone', 'address'])} />}
        />
        { !!digital_dispatch_webhook && <BaseAccordion
          title='Technical Info'
          expanded={expanded === 'dispatch'}
          onChange={handleChange('dispatch')}
          content={<Dispatch {...{digital_dispatch_webhook}} />}
        /> }
      </Card>
    </>
  );
};

export default memo(Info);
import styled from '@mui/material/styles/styled';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  marginTop: 4,
  color: '#E4E4FD',
  '&:before': {
    borderBottom: 'none !important'
  },
  '&:after': {
    borderBottom: 'none !important'
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent !important'
  }
}));
export const StyledIcon = styled(ArrowDropDownIcon)({
  color: '#E4E4FD !important'
});
export const StyledMenuItem = styled(MenuItem, { shouldForwardProp: (prop) => prop !== 'selected' })(({ theme, selected }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  justifyContent: 'flex-end',
  backgroundColor: 'transparent !important',
  color: selected ? theme.palette.primary.main : 'inherit'
}));
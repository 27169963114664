import { memo, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
//import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/sortBy';
import _startsWith from 'lodash/startsWith';
// Local files
import TableRowForm from './TableRowForm/TableRowForm';
import {getExpenseTypeLabel, getExpenseTotal, getRandomString, getFormatedHoursMinutes, getDisplayTime} from 'helpers';
import {parse, toSeconds} from "iso8601-duration";

const ServicesTable = ({ expenses, order_id, invoice_id, paid_amount, total_amount, newExpense, onNewExpense, highlightStorage, editExpense }) => {
  const [activeExpense, setActiveExpense] = useState(null);
  const totalWithoutFee = !!expenses.length ? 
  _filter(expenses, (expense) => !!!expense?.rate || (expense?.rate && !!!expense?.rate?.fee_value_type))
  .reduce((sum, a) => sum + getExpenseTotal(a), 0) : 0;
  const newTotalAmount = expenses.reduce((sum, exp) => sum + getExpenseTotal(exp), 0);


  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table size="small" sx={{ minWidth: '680px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: 2, width: '45%' }}>Service</TableCell>
              <TableCell sx={{ py: 2, width: '25%' }}>Rate</TableCell>
              <TableCell sx={{ py: 2, width: '15%' }}>Qty</TableCell>
              <TableCell sx={{ py: 2, width: '15%' }} align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!expenses?.length && _map(_orderBy(expenses, ['created_at', 'asc']), (expense) => {
              if (activeExpense?.id === expense.id || _startsWith(expense.id, 'new_')) 
                return <TableRowForm 
                    key={expense.id} 
                    order_id={order_id} 
                    invoice_id={invoice_id}
                    activeExpense={_startsWith(expense.id, 'new_') ? expense : activeExpense} 
                    onCancel={() => setActiveExpense(null)}
                  />
              return (
                <TableRow
                  key={expense.id}
                  onClick={() => editExpense && setActiveExpense(expense)}
                  selected={!!highlightStorage && expense?.name?.toLowerCase()?.includes('storage')}
                  sx={{ cursor: editExpense ? 'pointer' : 'auto' }}
                >
                  <TableCell sx={{textTransform: 'capitalize'}}>
                    {`${expense?.name || expense?.default_charge?.charge?.name || expense?.rate?.charge?.name}${!!expense?.service?.name ? ' - ' : ''}${expense?.service?.name || ''}`}
                  </TableCell>
                  <TableCell>{expense?.rate?.fee_value_type !== 'percent' && '$'}{expense?.unit_price?.toFixed(2) || expense?.rate?.value?.toFixed(2)}{getExpenseTypeLabel(expense?.default_charge || expense?.rate)}{expense?.rate?.fee_value_type === 'percent' && '%'}</TableCell>
                  <TableCell>
                    {getDisplayTime(expense)}
                  </TableCell>
                  <TableCell align='right'>{`$${getExpenseTotal(expense, totalWithoutFee)?.toFixed(2)}`}</TableCell>
                </TableRow>
              )
            })}
            {newExpense && <TableRowForm key={getRandomString(24)} order_id={order_id} invoice_id={invoice_id} onCancel={() => onNewExpense(false)} />}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 700 }}>
        <Box>TOTAL</Box>
        <Box>${newTotalAmount?.toFixed(2)}</Box>
      </Box>
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>
        <Box>Payments Made</Box>
        <Box>${paid_amount?.toFixed(2)}</Box>
      </Box>
      <Divider />
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 700 }}>
        <Box>BALANCE</Box>
        <Box>${(newTotalAmount - paid_amount)?.toFixed(2)}</Box>
      </Box>
  {/*  pagination
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: '8px' }}>
        <Button variant='outlined' sx={{ minWidth: 120 }}>Show all services</Button>
      </Box>*/}
    </Box>
  );
};

export default memo(ServicesTable);
import {
  getChannelMessagesRoutine,
  getChannelMessageRoutine,
  clearLocalChannelMessagesRoutine
} from 'actions';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _filter from 'lodash/filter';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  requestTypes: [],
  additionalServiceNames: [],
  channelMessage: { id: '', parameters: [], request_type: '', service_category: '', service_name: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getChannelMessagesRoutine.SUCCESS: {
      const { data: { channel_messages, pagination } } = action.payload;
      return { 
        ...state, 
        all: { data: [...state.all.data, ...channel_messages], pagination },
        requestTypes: _uniq(_map(channel_messages, (m) => m.request_type)),
        additionalServiceNames: _filter(_map(channel_messages, (m) => m.service_name), (sn) => !!sn)
      };
    }
    case getChannelMessageRoutine.SUCCESS: {
      const { data: { channelMessage } } = action.payload;

      return { ...state, channelMessage };
    }
    case clearLocalChannelMessagesRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
}

export default reducer;
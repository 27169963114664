import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const Root = styled(Paper)(({ theme }) => ({
  minWidth: 180,
  minHeight: 130,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  overflow: 'hidden',
  boxShadow: '0px 6px 10px 0px #0000001A',
  padding: '12px 12px 18px',
  cursor: 'pointer',
  [theme.breakpoints.up('lg')]: {
    minWidth: 250,
  }
}));

export const Content = styled(Box)(({ theme }) => ({
  minHeight: 40,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  [theme.breakpoints.up('lg')]: {
    minHeight: 64,
  }
}));

export const ValueText = styled(props => (
  <Typography component='div' noWrap {...props} />
))(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 700,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(34),
    fontWeight: 400,
    lineHeight: '1.3',
  }
}));

export const LabelText = styled(props => (
  <Typography align='center' component='div' {...props} />
))(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(10),
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(12),
  }
}));

export const Title = styled(props => (
  <Typography {...props} />
))(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(14),
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(16),
  }
}));
import { memo } from 'react';
import styled from '@mui/material/styles/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

const Root = styled('div')({
  display: 'flex',
  flexGrow: 1,
  overflow: 'hidden'
});
const NavContainer = styled('div')(({ theme }) => ({
  flexShrink: 0,
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  [theme.breakpoints.up('md')]: {
    maxWidth: 360,
    borderRight: '2px solid #F1F0FC'
  }
}));
const Nav = styled('div')({
  width: '100%',
  flexShrink: 0,
});


const BaseListPage = ({ renderFilter, listLoading, listComponent, renderMainAtDesktop, renderMainAtMobile = null, sx = null, navSx = null }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Root sx={sx}>
      <NavContainer sx={navSx}>
        <Nav>{renderFilter}</Nav>
        {listLoading && <LinearProgress />}
        {listComponent}
      </NavContainer>
      {(renderMainAtDesktop || renderMainAtMobile) && (!mediaDesktop ? renderMainAtMobile : renderMainAtDesktop)}
    </Root>
  );
};

BaseListPage.propTypes = {
  renderFilter: PropTypes.node,
  listLoading: PropTypes.bool,
  listComponent: PropTypes.node.isRequired,
  renderMainAtDesktop: PropTypes.node,
  renderMainAtMobile: PropTypes.node
};

export default memo(BaseListPage);
import useCustomSelector from './useCustomSelector';
import _includes from 'lodash/includes';
// Helpers
import { userRoles } from 'helpers/constants';

const { DRIVER, MANAGER, OWNER } = userRoles;

const useRole = () => {
  const roles = useCustomSelector(state => state.profile.user.roles);
  const isDriver = _includes(roles, DRIVER);
  const isManager = _includes(roles, MANAGER);
  const isOwner = _includes(roles, OWNER);
  const isMulti = isDriver && isManager && isOwner;

  return { isDriver, isManager, isMulti, isOwner };
};

export default useRole;
import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
// Local files
import { Container, CurrentDate, Group, Root, RouteInfo, Spacer, Status, StyledButton, Time } from './Form.styled';
import ElapsedTime from './ElapsedTime/ElapsedTime';
import RemainingTime from './RemainingTime/RemainingTime';
import TimeItem from './TimeItem/TimeItem';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useProfile from 'hooks/useProfile';

const date = new Date();
const dateOptions = { weekday: 'long', month: 'long', day: 'numeric' };
const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
const durations = [
  { value: 'PT4H', label: '4h' },
  { value: 'PT6H', label: '6h' },
  { value: 'PT8H', label: '8h' },
  { value: 'PT10H', label: '10h' },
  { value: 'PT12H', label: '12h' },
];

const DutyForm = () => {
  const { setError } = useError();
  const { getProfile, updateProfile, startDuty, stopDuty } = useProfile();
  const user = useCustomSelector(state => state.profile.user);
  const [processing, setProcessing] = useState(false);
  const onDuty = !!user.duty_started_at;
  const startedAt = user.duty_started_at ? new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hourCycle: 'h12' }).format(new Date(user.duty_started_at)) : null;

  const handleShiftDurationChange = v => {
    setProcessing(true);
    updateProfile({ shift_duration: v !== user.shift_duration ? v : null })
    .then(() => getProfile().catch(e => setError(e)))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleSubmit = () => {
    setProcessing(true);

    if (onDuty) {
      stopDuty()
      .catch(e => setError(e))
      .then(() => getProfile().catch(e => setError(e)))
      .finally(() => setProcessing(false));
    } else {
      startDuty()
      .catch(e => setError(e))
      .then(() => getProfile().catch(e => setError(e)))
      .finally(() => setProcessing(false));
    }
  };

  if (!user.id) return null;
  return (
    <Root>
      <Container>
        <Typography variant='subtitle1'>On/Off Duty</Typography>
        <CurrentDate>{formattedDate}</CurrentDate>
        <Status>{onDuty ? 'ON DUTY' : 'OFF DUTY'}</Status>
        {onDuty ? <ElapsedTime startDateIso={user.duty_started_at} /> : <Time>00:00.00</Time>}
        {onDuty && startedAt ?
          <>
            <RouteInfo>Started at {startedAt}</RouteInfo>
            <RemainingTime startDateIso={user.duty_started_at} durationIso={user.shift_duration} />
          </> :
          <Group>
            {_map(durations, ({ value, label }) =>
              <TimeItem
                key={value}
                value={value}
                label={label}
                disabled={processing}
                selected={value === user.shift_duration}
                onClick={v => handleShiftDurationChange(v)}
              />
            )}
          </Group>}
        <StyledButton
          disabled={processing}
          color={onDuty ? 'secondary' : 'primary'}
          onClick={handleSubmit}
        >
          {onDuty ? 'GO OFF DUTY' : 'GO ON DUTY'}
        </StyledButton>
        <Spacer />
      </Container>
    </Root>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <DutyForm {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
import { memo } from 'react';
import _map from 'lodash/map';
import _reverse from 'lodash/reverse';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText, } from 'components/Common/BasePageUi/BasePageUi';

const OrdersHistoryCard = ({ last_all_orders }) => {
  return (
    <Card size='small'>
      <CardHeader>
        <CardTitle>Order History</CardTitle>
      </CardHeader>
      <ItemsContainer sx={{ flexDirection: 'column' }}>
        {!!last_all_orders?.length ? _map(_reverse(last_all_orders), ({ id, number, created_at, company_location }) => 
        <Item key={id} sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
          <ItemLabel>{new Date(created_at).toLocaleDateString('en-US', { timeZone: company_location?.company?.time_zone })}</ItemLabel>
          <ItemText>{number}</ItemText>
        </Item>) : '-'}
      </ItemsContainer>
    </Card>
  );
};

export default memo(OrdersHistoryCard);
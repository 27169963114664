import { memo, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import _isEmpty from 'lodash/isEmpty';
// Local files
import { Card, CardHeader, CardTitle } from 'components/Common/BasePageUi/BasePageUi';
import ParticipantList from './ParticipantList/ParticipantList';
import AssetDetails from './AssetDetails/AssetDetails';
import VehicleInfo from './VehicleInfo/VehicleInfo';
import BaseAccordion from './BaseAccordion/BaseAccordion';
import useAdds from 'hooks/useAdds';
import useError from 'hooks/useError';
import { addConnectButtonUrl } from 'apis';
import { errorTypes } from 'helpers/constants';


const AddInfo = ({ order, vehicle_id = null, isHasAddToken, owners, registrants, lienholder, insurance_company, vehicle_flags, sx, ...fields }) => {
  const { getAdd, clearLocalActiveAdd } = useAdds();
  const { setError } = useError();
  const [expanded, setExpanded] = useState('owners');
  const [processing, setProcessing] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => setExpanded(isExpanded ? panel : false);
  const handleGetOrLoginAddInfo = () => {
    setProcessing(true);
    if (isHasAddToken) {
      getAdd(order?.vehicle?.id || vehicle_id)
      .catch(error => error?.response?.data?.error?.type === errorTypes.ADD_ACCESS_TOKEN_INVALID ? 
      window.open(`${addConnectButtonUrl}&state=company_id%3D${order?.company_location?.company?.id}%26order_id%3D${order?.id}`, '_self') : setError(error))
      .finally(() => setProcessing(false))
    } else {
      setProcessing(false);
      window.open(`${addConnectButtonUrl}&state=company_id%3D${order?.company_location?.company?.id}%26order_id%3D${order?.id}`, '_self');
    }
  };

  useEffect(() => {
    return clearLocalActiveAdd;
  }, [clearLocalActiveAdd]);

  return (
    <>
      <Card size='medium' sx={{ alignSelf: 'flex-start', ...sx }}>
        <CardHeader sx={{ justifyContent: 'space-between'}}>
          <CardTitle>Vehicle Details</CardTitle>
          {_isEmpty(fields) && <LoadingButton
            size="small"
            color="primary"
            onClick={handleGetOrLoginAddInfo}
            loading={processing}
            loadingPosition="start"
            startIcon={isHasAddToken ? <InfoIcon /> : <LoginIcon />}
            variant="contained"
          >
            <span>{isHasAddToken ? 'Get Info' : 'Add Connect'}</span>
          </LoadingButton>}
        </CardHeader>
        {isHasAddToken && !_isEmpty(fields) && <>
          <BaseAccordion
            title='Owners'
            expanded={expanded === 'owners'}
            onChange={handleChange('owners')}
            content={<ParticipantList data={owners} />}
          />
          <BaseAccordion
            title='Registrants'
            expanded={expanded === 'registrants'}
            onChange={handleChange('registrants')}
            content={<ParticipantList data={registrants} />}
          />
          <BaseAccordion
            title='Lienholder'
            expanded={expanded === 'lienholder'}
            onChange={handleChange('lienholder')}
            content={<AssetDetails { ...lienholder} />}
          />
          <BaseAccordion
            title='Insurance Company'
            expanded={expanded === 'insurance_company'}
            onChange={handleChange('insurance_company')}
            content={<AssetDetails {...insurance_company} />}
          />
          <BaseAccordion
            title='Vehicle Flags'
            expanded={expanded === 'vehicle_flags'}
            onChange={handleChange('vehicle_flags')}
            content={<ParticipantList data={vehicle_flags} />}
          />
          <BaseAccordion
            title='Vehicle Info'
            expanded={expanded === 'vehicle_info'}
            onChange={handleChange('vehicle_info')}
            content={<VehicleInfo {...fields} />}
          />
        </>}
      </Card>
    </>
  );
};

export default memo(AddInfo);
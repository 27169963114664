import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import _isEqual from 'lodash/isEqual';
//Local files
import BaseAddressAutocomplete from 'components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete';
import InsuranceAccountsAutocomplete from 'components/InsuranceAccounts/Autocomplete/Autocomplete';
import CompaniesAutocomplete from 'components/Companies/Autocomplete/Autocomplete';
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import { FieldsContainer } from 'components/Common/BasePageUi/BasePageUi';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import { accountTypes, reasons } from 'helpers/constants';
import { DELETE_ACCOUNT } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useAccounts from 'hooks/useAccounts';
import useDialogs from 'hooks/useDialogs';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const Drawer = () => {
  const { closeDrawer } = useApp();
  const { openConfirmationDialog } = useDialogs();
  const { createAccount, updateAccount } = useAccounts();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open, edit } = useCustomSelector(state => state.app.account);
  const uploadedAccount = useCustomSelector(state => state.accounts.account);
  const companies = useCustomSelector(state => state.profile.user.companies);
  const [name, setName] = useState({ value: '', error: '' });
  const [insuranceAccounts, setInsuranceAccounts] = useState({ value: [], formattedValue: [], error: '' });
  const [companyId, setCompanyId] = useState({ value: '', formattedValue: null, error: '' });
  const [accountType, setAccountType] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [fax, setFax] = useState({ value: '', formattedValue: '', error: '' });
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [contactEmail, setContactEmail] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [secondBillingEmail, setSecondBillingEmail] = useState('');
  const [thirdBillingEmail, setThirdBillingEmail] = useState('');
  const [billingAddress, setBillingAddress] = useState({ value: '', error: '', latitude: 0, longitude: 0 });
  const [processing, setProcessing] = useState(false);

  const needToUpdate = (a,b) => 
    a.name !== b.name || 
    !_isEqual(_map(a.insurance_accounts, ({ id }) => id).sort(), b.insurance_account_ids.sort()) ||
    a.company_id !== b.company?.id || 
    a.account_type !== b.account_type || 
    a.email !== b.email || 
    a.phone !== b.phone || 
    a.fax !== b.fax || 
    a.contact_name !== b.contact_name || 
    a.contact_phone !== b.contact_phone || 
    a.contact_email !== b.contact_email || 
    a.billing_email !== b.billing_email || 
    a.second_billing_email !== b.second_billing_email || 
    a.third_billing_email !== b.third_billing_email || 
    a.billing_address !== b.billing_address;
  
  const handleDelete = () => {
    openConfirmationDialog({
      id: uploadedAccount.id,
      content: 'Are you sure you want to delete this account?',
      reason: reasons.ACCOUNT,
      type: DELETE_ACCOUNT
    });
  };
  const handleClose = () => closeDrawer(reasons.ACCOUNT); 
  const handleExiting = () => {
    setName({ value: '', error: '' });
    setInsuranceAccounts({ value: [], formattedValue: [], error: '' });
    setCompanyId({ value: '', formattedValue: null, error: '' });
    setAccountType({ value: '', error: '' });
    setEmail({ value: '', error: '' });
    setPhone({ value: '', formattedValue: '', error: '' });
    setFax('');
    setContactName('');
    setContactPhone({ value: '', formattedValue: '', error: '' });
    setContactEmail('');
    setBillingEmail('');
    setSecondBillingEmail('');
    setThirdBillingEmail('');
    setBillingAddress({ value: '', error: '', latitude: 0, longitude: 0 });
  };
  const handleSubmitClick = () => {
    let hasError = false;

    if (!!!name.value) {
      setName(prev => ({ ...prev, error: `Name can't be empty` }));
      hasError = true;
    }
    if (!!!accountType.value) {
      setAccountType(prev => ({ ...prev, error: `Type can't be empty` }));
      hasError = true;
    }
    if (!!!companyId.value) {
      setCompanyId(prev => ({ ...prev, error: 'Company can\'t be empty' }));
      hasError = true;
    }
    if (hasError) return;

    const processingAccount = {
      ...(edit && { id: uploadedAccount.id }),
      name: name.value,
      insurance_account_ids: insuranceAccounts.value,
      localInsuranceAccounts: insuranceAccounts.formattedValue,
      company_id: companyId.value,
      localCompany: companyId.formattedValue,
      account_type: accountType.value,
      email: email.value || null,
      phone: phone.value || null,
      fax: fax.value || null,
      contact_name: contactName || null,
      contact_phone: contactPhone.value || null,
      contact_email: contactEmail || null,
      billing_email: billingEmail || null,
      second_billing_email: secondBillingEmail || null,
      third_billing_email: thirdBillingEmail || null,
      billing_address: billingAddress.value || null
    };

    setProcessing(true);
    
    if (edit) {
      if (needToUpdate(uploadedAccount, processingAccount)) {
        updateAccount(processingAccount)
        .then(() => setSuccess('Account updated').then(handleClose))
        .catch(e => setError(e))
        .finally(() => setProcessing(false))
      } else {
        setProcessing(false);
        handleClose();
      }
    } else {
      createAccount(processingAccount)
      .then(() => setSuccess('Account created').then(handleClose))
      .catch(e => setError(e))
      .finally(() => setProcessing(false))
    }
  };

  const handleEntering = () => {
    if (edit && !!uploadedAccount.id) {
      setName({ value: uploadedAccount.name, error: '' });
      setInsuranceAccounts({ value: _map(uploadedAccount.insurance_accounts, ({ id }) => id), formattedValue: uploadedAccount.insurance_accounts, error: '' });
      setCompanyId({ value: uploadedAccount.company.id, formattedValue: uploadedAccount.company, error: '' });
      setAccountType({ value: uploadedAccount.account_type, error: '' });
      setEmail({ value: uploadedAccount.email || '', error: '' });
      setPhone({ value: uploadedAccount.phone || '', formattedValue: uploadedAccount.phone || '', error: '' });
      setFax({ value: uploadedAccount.fax || '', formattedValue: uploadedAccount.fax || '', error: '' });
      setContactName(uploadedAccount.contact_name || '');
      setContactPhone({ value: uploadedAccount.contact_phone || '', formattedValue: uploadedAccount.contact_phone || '', error: '' });
      setContactEmail(uploadedAccount.contact_email || '');
      setBillingEmail(uploadedAccount.billing_email || '');
      setSecondBillingEmail(uploadedAccount.second_billing_email || '');
      setThirdBillingEmail(uploadedAccount.third_billing_email || '');
      !!uploadedAccount.billing_address && setBillingAddress({
        value: uploadedAccount.billing_address,
        error: '',
        latitude: 0, 
        longitude: 0
      });
    } else {
      companies.length === 1 && setCompanyId({ value: companies[0]?.id, formattedValue: companies[0], error: '' });
    }
  };

  return (
    <BaseDrawer
      grey
      open={open}
      onClose={handleClose}
      disabled={processing}
      paperSxProps={{ width: { xs: '100%', lg: '640px' } }}
      SlideProps={{ onEntering: handleEntering, onExited: handleExiting }}
      title={`${edit ? 'UPDATE' : 'CREATE'} ACCOUNT`}
      content={
      <>
        <FieldsContainer sx={{ pb: 2, pt: 2 }}>
          <CompaniesAutocomplete
            fullWidth
            value={companyId.formattedValue}
            error={!!companyId.error}
            helperText={companyId.error}
            onChange={({ value, formattedValue }) => setCompanyId({ value, formattedValue })}
          />
          <InsuranceAccountsAutocomplete
            id='accounts'
            value={insuranceAccounts.formattedValue}
            error={!!insuranceAccounts.error}
            helperText={insuranceAccounts.error}
            onChange={({ value, formattedValue, error }) => setInsuranceAccounts({ value, formattedValue, error })}
          />
          <TextField 
            fullWidth
            label='Account type' 
            value={accountType.value} 
            select
            helperText={accountType.error} 
            error={!!accountType.error} 
            onChange={({ target: { value } }) => setAccountType({ value, error: '' })} 
          >
            {_map(accountTypes, (type) => <MenuItem key={type} value={type}>{_startCase(type)}</MenuItem>)}
          </TextField>
          <TextField 
            label='Name' 
            value={name.value} 
            helperText={name.error} 
            error={!!name.error} 
            onChange={({ target: { value } }) => setName({ value, error: '' })} 
          />
          <TextField 
            label='Email' 
            value={email.value} 
            helperText={email.error} 
            error={!!email.error} 
            onChange={({ target: { value } }) => setEmail({ value, error: '' })} 
          />
          <PhoneField
            fullWidth
            label='Phone'
            formattedValue={phone.formattedValue}
            error={!!phone.error}
            helperText={phone.error}
            onChange={({ value, formattedValue, error }) => setPhone({ value, formattedValue, error })}
          />
          <PhoneField
            fullWidth
            label='Fax'
            formattedValue={fax.formattedValue}
            error={!!fax.error}
            helperText={fax.error}
            onChange={({ value, formattedValue, error }) => setFax({ value, formattedValue, error })}
          />
          <TextField 
            label='Contact Name' 
            value={contactName} 
            onChange={({ target: { value } }) => setContactName(value)} 
          />
          <PhoneField
            fullWidth
            label='Contact Phone'
            formattedValue={contactPhone.formattedValue}
            error={!!contactPhone.error}
            helperText={contactPhone.error}
            onChange={({ value, formattedValue, error }) => setContactPhone({ value, formattedValue, error })}
          />
          <TextField 
            label='Contact Email' 
            value={contactEmail} 
            onChange={({ target: { value } }) => setContactEmail(value)} 
          />
          <TextField 
            label='To' 
            value={billingEmail} 
            onChange={({ target: { value } }) => setBillingEmail(value)} 
          />
          <TextField 
            label='Cc' 
            value={secondBillingEmail} 
            onChange={({ target: { value } }) => setSecondBillingEmail(value)} 
          />
          <TextField 
            label='Bcc' 
            value={thirdBillingEmail} 
            onChange={({ target: { value } }) => setThirdBillingEmail(value)} 
          />
          <BaseAddressAutocomplete
            id='billing-address'
            label='Billing address'
            value={billingAddress.value}
            error={!!billingAddress.error}
            helperText={billingAddress.error}
            onChange={({ value, error = '', latitude = 0, longitude = 0 }) => setBillingAddress({ value, error, latitude, longitude })}
          />
        </FieldsContainer>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: edit ? 'space-between' : 'center', pt: {xs: '32px', lg: '56px'} }}>
          { edit && <Button disabled={processing} variant='outlined' color='error' onClick={handleDelete}>DELETE ACCOUNT</Button> }
          <Button disabled={processing} variant='contained' color='primary' onClick={handleSubmitClick}>{edit ? 'UPDATE' : 'CREATE'} ACCOUNT</Button>
        </Box>
        </>
      }
    />
  );
};

export default memo(Drawer);
import styled from '@mui/material/styles/styled';
import MuiContainer from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const smallBreakpoint = 400;

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  ...theme.scrollbars.paper,
}));

export const Container = styled((props) => (
  <MuiContainer maxWidth='false' {...props} />
))(({ theme }) => ({
  height: '100%',
  maxWidth: 362 + 48,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 8,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 362 + 32,
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.text.primary,
  lineHeight: 1,
  fontWeight: 700,
  [theme.breakpoints.down(smallBreakpoint)]: {
    fontSize: theme.typography.pxToRem(13)
  }
}));

export const CurrentDate = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.text.hint,
  lineHeight: 1,
  fontWeight: 500,
  marginTop: 8,
  [theme.breakpoints.down(smallBreakpoint)]: {
    fontSize: theme.typography.pxToRem(15)
  }
}));

export const Status = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(42),
  color: theme.palette.text.secondary,
  lineHeight: 1,
  fontWeight: 700,
  marginTop: 82,
  [theme.breakpoints.down(smallBreakpoint)]: {
    fontSize: theme.typography.pxToRem(32),
    marginTop: 32
  }
}));

export const Time = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(72),
  color: theme.palette.text.primary,
  lineHeight: 1,
  fontWeight: 700,
  marginTop: 16,
  [theme.breakpoints.down(smallBreakpoint)]: {
    fontSize: theme.typography.pxToRem(60),
    marginTop: 8
  }
}));

export const RouteInfo = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.text.secondary,
  lineHeight: 1,
  fontWeight: 500,
  marginTop: 16,
  [theme.breakpoints.down(smallBreakpoint)]: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 10
  }
}));

export const Group = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: 60,
  [theme.breakpoints.down(smallBreakpoint)]: {
    justifyContent: 'center',
    gap: '8px',
    marginTop: 24
  }
}));

export const StyledButton = styled((props) => (
  <Button variant='contained' fullWidth {...props} />)
)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 800,
  borderRadius: '8px',
  padding: '12px 16px',
  marginTop: 42,
  [theme.breakpoints.down(smallBreakpoint)]: {
    fontSize: theme.typography.pxToRem(20),
    marginTop: 20
  }
}));

export const Spacer = styled('div')({
  flex: '0 0 32px',
  width: '100%',
})
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _pick from 'lodash/pick';
// Local files
import { CardsGrid, DetailsContainer, DetailsWrapper } from 'components/Common/BasePageUi/BasePageUi';
import CustomerCard from 'components/Customers/Card/Card';
import VehicleCard from 'components/Vehicles/Cards/Card/Card';
import DriverCard from 'components/Driver/Card/Card';
import PaymentCard from 'components/Payments/Card/Card';
import PaymentDrawer from 'components/Payments/Drawer/Drawer';
import MapCard from 'components/Map/Card/Card';
import NotesCard from 'components/Notes/Card/Card';
import DocumentsCard from 'components/Documents/Card/Card';
import Main from '../Cards/Main/Main';
import MilageInfoCard from '../Cards/MilageInfo/Card';
import Info from '../Info/Info';
import AddInfo from 'components/Vehicles/AddInfo/AddInfo';
import SendTracking from '../Drawers/SendTracking/SendTracking';
import RequestAdditional from '../Drawers/RequestAdditional/RequestAdditional';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import Placeholder from 'components/Common/Placeholder/Placeholder';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useRole from 'hooks/useRole';
import useOrders from 'hooks/useOrders';
import useChannelMessages from 'hooks/useChannelMessages';
import useApp from 'hooks/useApp';
import {models, invoiceStatuses, sides, reasons, orderStatuses } from 'helpers/constants';

const Details = () => {
  const { id } = useParams();
  const { openDrawer, setFetchingDetails } = useApp();
  const { setError } = useError();
  const { isManager, isOwner } = useRole();
  const { getOrder, applyCustomerLocation, rejectCustomerLocation, setLocalOrderFields } = useOrders();
  const { getChannelMessages, clearLocalChannelMessages } = useChannelMessages();
  const { fetchedOrder, isFetching, isHasChannelMessages, add, isHasAddToken, isShowAddInfo } = useCustomSelector(state => ({
    isFetching: state.app.isFetching,
    fetchedOrder: state.orders.order,
    isHasChannelMessages: !!state.channelMessages.all.data.length,
    add: state.adds.add,
    isHasAddToken: state.orders.order.company_location?.company?.add_access_token_available,
    isShowAddInfo: !!state.orders.order?.vehicle?.vin || !!state.orders.order?.vehicle?.license
  }));
  const [isShowDriver, setShowDriver] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  const isEditable = fetchedOrder?.invoice?.status === invoiceStatuses.NOT_SUBMITTED || fetchedOrder?.invoice?.status === invoiceStatuses.NEW;
  const mainData = { ..._pick(fetchedOrder, ['id', 'source', 'account', 'customer', 'vehicle', 'invoice', 'authorization_number', 'police_number', 'police_code', 'user', 'number', 'services', 'status', 'previous_status', 'eta', 'dispatcher', 'created_at', 'incident_location', 'trucks', 'users', 'company_location', 'scheduled_at']), isHasChannelMessages };
  const orderStatus = mainData.status;
  const customerData = _pick(fetchedOrder, ['customer', 'account', 'caller_name', 'caller_phone', 'member_first_name', 'member_last_name', 'member_phone']);
  const vehicleData = _pick(fetchedOrder, ['vehicle', 'keys_availability', 'driver_attendance']);
  const vehicleStatus = vehicleData.vehicle?.status;
  const invoiceStatus = mainData.invoice?.status
  const driverData = _pick(fetchedOrder, ['id', 'user', 'dispatcher', 'truck', 'incident_location', 'status', 'eta', 'source', 'trucks', 'users']);
  const mapData = { ..._pick(fetchedOrder, ['incident_location', 'destination_location', 'customer_location', 'status']), ..._pick(fetchedOrder?.user, ['latitude', 'longitude']), isShowDriver };
  const notesData = { ..._pick(fetchedOrder, ['description','internal_notes', 'dispatcher_notes', 'scheduled_at', 'urgently_partner_name', 'urgently_disablement_reason', 'urgently_callback']), order_id: fetchedOrder.id, invoice_notes: fetchedOrder?.invoice?.notes, invoice_id: fetchedOrder?.invoice?.id, isEditable };
  const paymentsData = { ..._pick(fetchedOrder?.invoice, ['id', 'payments', 'status', 'paid_amount', 'total_amount']), ..._pick(fetchedOrder, ['account', 'customer', 'company_location', 'authorization_number']) };
  const documentsData = { id: fetchedOrder.id, model: models.ORDERS };
  const infoData = _pick(fetchedOrder, ['id', 'company_location', 'digital_dispatch_webhook']);
  const milageInfoData = _pick(fetchedOrder, ['deadhead_miles_count', 'en_route_miles_count', 'towed_miles_count']);
  const sendOrderTracking = _pick(fetchedOrder, ['id', 'account', 'authorization_number', 'customer', 'customer_token', 'number', 'status', 'company_location', 'police_number', 'police_code', 'recipients', 'created_at']);
  const requestAdditional = _pick(fetchedOrder, ['id', 'number', 'company_location']);
  const { NEW, EN_ROUTE, PICK_UP, SERVICING, DROP_OFF, COMPLETED, STORED, SCHEDULED } = orderStatuses;

  const showEdit = (isManager || isOwner)
    && (['new', 'en_route', 'pick_up', 'drop_off', 'servicing', 'completed', 'stored'].includes(orderStatus)
      || vehicleStatus === STORED || orderStatus === SCHEDULED || invoiceStatus === invoiceStatuses.NOT_SUBMITTED || invoiceStatus === invoiceStatuses.APPROVED)
    && invoiceStatus !== invoiceStatuses.PAID ;

  const handleApplyLocation = () => {
    setProcessing(true);
    applyCustomerLocation(id)
    .then(() => setLocalOrderFields({ incident_location: fetchedOrder.customer_location }))
    .catch(error => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleRejectLocation = () => {
    setProcessing(true);
    rejectCustomerLocation(id)
    .then(() => setLocalOrderFields({ customer_location: null }))
    .catch(error => setError(error))
    .finally(() => setProcessing(false))
  };

  useEffect(() => {
    if (!!id) {
      if (id !== fetchedOrder.id) {
        setFetchingDetails(true);
        getOrder({ id, side: sides.USER })
        .then(({ payload: { data: { order: { source } } } }) => 
          clearLocalChannelMessages()
          .then(() => {
            if ( source === 'digital_dispatch') {
              getChannelMessages({ order_id: id })
              .catch(error => setError(error).then(() => setFetchingDetails(false)))
              .finally(() => setFetchingDetails(false))
            }
          })
        )
        .catch(error => setError(error).then(() => setFetchingDetails(false)))
        .finally(() => setFetchingDetails(false))
      }
    }

    return () => setShowDriver(false);
  }, [id, fetchedOrder.id, getOrder, getChannelMessages, clearLocalChannelMessages, setFetchingDetails, setError]);

  const handleVehicleEditClick = () => openDrawer({ reason: reasons.ORDER, edit: true, onlyVehicle: true });

  if (!!!id) return <Placeholder model={models.ORDERS} />;
  if (!!!fetchedOrder.id || isFetching) return <PageLoader />;
  return (
    <DetailsContainer>
      <DetailsWrapper>
        <Main {...mainData} />
        <CardsGrid>
          <CustomerCard sx={{ gridArea: { lg: '1 / 1 / 2 / 2' }, maxWidth: 'unset !important' }} {...customerData} />
          <VehicleCard sx={{ gridArea: { lg: '1 / 2 / 2 / 3' }, maxWidth: 'unset !important' }} {...vehicleData} {...(showEdit ? { onEditClick: handleVehicleEditClick } : {})} />
          <PaymentCard sx={{ gridArea: { lg: '2 / 1 / 3 / 2', xlg: '1 / 3 / 2 / 4' }, maxWidth: 'unset !important' }} {..._pick(paymentsData, ['payments', 'status', 'company_location'])} />
          { (isManager || isOwner) && <DriverCard sx={{ gridArea: { lg: '2 / 2 / 3 / 3', xlg: '2 / 1 / 3 / 2' }, maxWidth: 'unset !important' }} {...driverData} onShowDriver={setShowDriver} /> }
          { (isManager || isOwner) && <MilageInfoCard sx={{ gridArea: { lg: '3 / 1 / 4 / 2', xlg: '3 / 1 / 4 / 2' }, maxWidth: 'unset !important' }} {...milageInfoData} /> }
          <NotesCard sx={{ gridArea: { lg: '3 / 2 / 4 / 3', xlg: '4 / 1 / 5 / 2' }, maxWidth: 'unset !important' }} edit {...notesData} />
          <MapCard sx={{ gridArea: { lg: '4 / 1 / 5 / 2', xlg: '2 / 2 / 5 / 4' }, width: '100% !important' }} {...mapData} locationProcessing={processing} onShowDriver={setShowDriver} onApplyLocation={handleApplyLocation} onRejectLocation={handleRejectLocation} />
          <DocumentsCard sx={{ gridArea: { lg: '4 / 2 / 5 / 3', xlg: '5 / 1 / 6 / 4' }, width: '100% !important' }} {...documentsData} />
          { (isManager || isOwner) && <Info sx={{ gridArea: { lg: '5 / 1 / 5 / 2', xlg: '6 / 1 / 7 / 4' }, width: '100% !important', overflow: 'hidden' }} {...infoData} /> }
          { (isManager || isOwner) && isShowAddInfo && <AddInfo sx={{ gridArea: { lg: '6 / 2 / 6 / 2', xlg: '7 / 1 / 7 / 4' }, width: '100% !important', overflow: 'hidden' }} {...add} {...{order: fetchedOrder, isHasAddToken}} /> }
        </CardsGrid>
      </DetailsWrapper>
      <PaymentDrawer {...paymentsData} />
      <SendTracking {...sendOrderTracking} />
      { (isManager || isOwner) && <RequestAdditional {...requestAdditional} /> }
    </DetailsContainer>
  );
};

export default memo(Details);
import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import _startCase from 'lodash/startCase';
import { useParams } from 'react-router-dom';
// Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import { reasons, urgentlyVehicleTypes } from 'helpers/constants';
import { checkEmptyString } from 'helpers';
import CompaniesAutocomplete from 'components/Companies/Autocomplete/Autocomplete';
import UsersAutocomplete from 'components/Users/Autocomplete/Autocomplete';
import TypesAutocomplete from 'components/Types/Autocomplete/Autocomplete';
import { DELETE_TRUCK } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useTrucks from 'hooks/useTrucks';
import useDialogs from 'hooks/useDialogs';
import { FieldsContainer } from 'components/Common/BasePageUi/BasePageUi';
import useVehicles from 'hooks/useVehicles';

const { TRUCK } = reasons;
const currentYear = new Date().getFullYear();

const TrucksDrawer = () => {
  const { id } = useParams();
  const { closeDrawer } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createTruck, updateTruck } = useTrucks();
  const { getFromVinVehicleInfo } = useVehicles();
  const { openConfirmationDialog } = useDialogs();
  const { open, edit } = useCustomSelector(state => state.app.truck);
  const uploadedTruck = useCustomSelector(state => state.trucks.truck);
  const [companyId, setCompanyId] = useState({ value: '', formattedValue: null, error: '' });
  const [userIds, setUserIds] = useState({ value: [], formattedValue: [], error: '' });
  const [vin, setVin] = useState({ value: '', error: '' });
  const [nickname, setNickname] = useState({ value: '', error: '' });
  const [typeId, setTypeId] = useState({ value: '', formattedValue: null, error: '' });
  const [make, setMake] = useState({ value: '', error: '' });
  const [model, setModel] = useState({ value: '', error: '' });
  const [year, setYear] = useState({ value: '', error: '' });
  const [licensePlate, setLicensePlate] = useState({ value: '', error: '' });
  const [currentUserId, setCurrentUserId] = useState({ value: '', formattedValue: null, error: '' });
  const [urgentlyVehicleType, setUrgentlyVehicleType] = useState('');
  const [processing, setProcessing] = useState(false);

  const handleBlur = () => vin.value?.length === 17 && 
  getFromVinVehicleInfo(vin.value)
  .then(({ payload: { data: { Results } } }) => { 
    setMake({ value: Results[0]?.Make, error: '' });
    setModel({ value: Results[0]?.Model, error: '' });
    setYear({ value: Results[0]?.ModelYear, error: '' });
  });
  const handleDelete = () => {
    openConfirmationDialog({
      id: uploadedTruck.id,
      content: 'Are you sure you want to delete this truck?',
      type: DELETE_TRUCK,
      reason: reasons.TRUCK
    });
  };
  const handleSave = () => {
    let hasError = false;

    if (!!!userIds.value.length) {
      setUserIds(prev => ({ ...prev, error: 'Driver can\'t be empty' }));
      hasError = true;
    }
    if (!edit && !!!companyId.value) {
      setCompanyId(prev => ({ ...prev, error: 'Company can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(nickname.value)) {
      setNickname(prev => ({ ...prev, error: 'Nickname can\'t be empty' }));
      hasError = true;
    }
    if (!!!typeId.value) {
      setTypeId(prev => ({ ...prev, error: 'Type can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(make.value)) {
      setMake(prev => ({ ...prev, error: 'Car make can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(model.value)) {
      setModel(prev => ({ ...prev, error: 'Car model can\'t be empty' }));
      hasError = true;
    }
    if (typeof year.value === 'string' && !checkEmptyString(year.value)) {
      setYear(prev => ({ ...prev, error: 'Car year can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(licensePlate.value)) {
      setLicensePlate(prev => ({ ...prev, error: 'License plate can\'t be empty' }));
      hasError = true;
    }
    if (hasError) return;

    const processableTruck = {
      ...(edit && { id: uploadedTruck.id }),
      user_ids: userIds.value,
      localUsers: userIds.formattedValue,
      vin: vin.value || null, 
      nickname: nickname.value,
      type_id: typeId.value,
      current_user_id: currentUserId.value || null,
      current_user: currentUserId.formattedValue,
      make: make.value,
      model: model.value,
      year: year.value,
      license_plate: licensePlate.value,
      urgently_vehicle_type: urgentlyVehicleType || null
    };

    setProcessing(true);
    if (edit) {
      if (needToUpdate(uploadedTruck, processableTruck)) {
        updateTruck(processableTruck)
        .then(() => setSuccess('Truck successfully updated').then(() => closeDrawer(TRUCK)))
        .catch(error => setError(error))
        .finally(() => setProcessing(false));
      } else {
        closeDrawer(TRUCK);
      }
    } else {
      createTruck({ truck: processableTruck, company_id: companyId.value })
      .then(() => setSuccess('Truck successfully created').then(() => closeDrawer(TRUCK)))
      .catch(error => setError(error).then(() => setProcessing(false)))
      .finally(() => setProcessing(false));
    }
  };
  const handleEntering = () => {
    if (edit && !!id) {
      setUserIds({ value: _map(uploadedTruck.users, ({ id }) => id), formattedValue: uploadedTruck.users, error: '' });
      setVin({ value: uploadedTruck.vin || '', error: '' });
      setNickname({ value: uploadedTruck.nickname || '', error: '' });
      setTypeId({ value: uploadedTruck.type?.id || '', formattedValue: uploadedTruck.type, error: '' });
      setMake({ value: uploadedTruck.make, error: '' });
      setModel({ value: uploadedTruck.model, error: '' });
      setYear({ value: uploadedTruck.year, error: '' });
      setLicensePlate({ value: uploadedTruck.license_plate, error: '' });
      setCurrentUserId({ value: !!uploadedTruck?.current_user?.id ? uploadedTruck.current_user.id : '', formattedValue: uploadedTruck.current_user, error: '' });
      setUrgentlyVehicleType(uploadedTruck.urgently_vehicle_type || '');
    }
  };
  const handleExiting = () => {
    setCompanyId({ value: '', formattedValue: null, error: '' });
    setUserIds({ value: [], formattedValue: [], error: '' });
    setVin({ value: '', error: '' });
    setNickname({ value: '', error: '' });
    setTypeId({ value: '', formattedValue: null, error: '' });
    setMake({ value: '', error: '' });
    setModel({ value: '', error: '' });
    setYear({ value: '', error: '' });
    setLicensePlate({ value: '', error: '' });
    setCurrentUserId({ value: '', formattedValue: null, error: '' });
    setUrgentlyVehicleType('');
    setProcessing(false);
  };
  const needToUpdate = (a, b) => 
    !_isEqual(_map(a.users, ({ id }) => id).sort(), b.user_ids.sort())
    || a.type?.id !== b.type_id
    || a.vin !== b.vin
    || a.nickname !== b.nickname
    || a.make !== b.make
    || a.model !== b.model
    || a.year !== b.year
    || (a.current_user?.id ?? null) !== b.current_user_id
    || a.license_plate !== b.license_plate
    || a.urgently_vehicle_type !== b.urgently_vehicle_type

  return (
    <BaseDrawer
      open={open}
      edit={edit}
      onClose={() => closeDrawer(TRUCK)}
      headContent={<Typography variant='title' sx={{color: 'action.active'}}>{edit ? 'Edit' : 'Create'} Truck</Typography>}
      disabled={processing}
      onDelete={handleDelete}
      onSave={handleSave}
      SlideProps={{ onEntering: handleEntering, onExited: handleExiting }}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          { !edit && <CompaniesAutocomplete
            fullWidth
            margin='dense'
            value={companyId.formattedValue}
            error={!!companyId.error}
            helperText={companyId.error}
            onChange={({ value, formattedValue, error }) => setCompanyId({ value, formattedValue, error })}
          /> }
          <FieldsContainer>
            <TextField
              fullWidth
              margin='dense'
              label='Nickname'
              value={nickname.value}
              error={!!nickname.error}
              helperText={nickname.error}
              onChange={({ target: { value } }) => setNickname({ value, error: '' })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='Vin'
              value={vin.value}
              error={!!vin.error}
              helperText={vin.error}
              onChange={({ target: { value } }) => setVin({ value, error: '' })}
              inputProps={{ maxLength: 17 }} 
              onBlur={handleBlur}
            />
            <UsersAutocomplete
              id='driver'
              role='driver'
              fullWidth
              multiple
              margin='dense'
              label='Select drivers'
              value={userIds.formattedValue}
              error={!!userIds.error}
              helperText={userIds.error}
              onChange={({ value, formattedValue, error }) => {
                setUserIds({ value, formattedValue, error });
                if (!!currentUserId.value && !_find(formattedValue, u => u.id === currentUserId.value)){
                  setCurrentUserId({ value: '', formattedValue: null, error: '' });
                }
              }}
            />
            <TypesAutocomplete
              id='types'
              value={typeId.formattedValue}
              error={!!typeId.error}
              helperText={typeId.error}
              onChange={({ value, formattedValue, error }) => setTypeId({ value, formattedValue, error })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='Make'
              value={make.value}
              error={!!make.error}
              helperText={make.error}
              onChange={({ target: { value } }) => setMake({ value, error: '' })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='Model'
              value={model.value}
              error={!!model.error}
              helperText={model.error}
              onChange={({ target: { value } }) => setModel({ value, error: '' })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='Year'
              value={year.value}
              error={!!year.error}
              helperText={year.error}
              type='number'
              inputProps={{ min: 1900, max: currentYear }}
              onChange={({ target: { value } }) => setYear({ value, error: '' })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='License plate'
              value={licensePlate.value}
              error={!!licensePlate.error}
              helperText={licensePlate.error}
              onChange={({ target: { value } }) => setLicensePlate({ value, error: '' })}
            />
            <TextField
              fullWidth
              select
              margin='dense'
              label='Current user'
              value={currentUserId.value}
              helperText={currentUserId.error}
              error={!!currentUserId.error}
              InputProps={{
                endAdornment: (
                  currentUserId.value && (
                    <IconButton
                      size='small'
                      onClick={() => setCurrentUserId({ value: '', formattedValue: null, error: '' })}
                      style={{ marginRight: 15 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )
                ),
              }}
              onChange={({ target: { value } }) => {
                setCurrentUserId({ 
                  value, 
                  formattedValue: _pick(_find(userIds.formattedValue, u => u.id === value), ['id', 'first_name', 'last_name']), 
                  error: '' 
                })
              }}
              disabled={userIds.formattedValue.length === 0}
            >
              {_map(userIds.formattedValue, ({ id, first_name, last_name }) => (
                <MenuItem key={id} value={id}>{`${first_name} ${last_name}`}</MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              margin='dense'
              label='Urgently vehicle type'
              value={urgentlyVehicleType}
              onChange={({ target: { value } }) => {setUrgentlyVehicleType(value)}}
            >
              {_map(urgentlyVehicleTypes, (t) => (
                <MenuItem key={t} value={t}>{_startCase(t)}</MenuItem>
              ))}
            </TextField>
          </FieldsContainer>
        </Box>
      }
    />
  );
};

export default memo(TrucksDrawer);
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled('div')({
  width: '100%',
  maxWidth: '100%',
});

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  color: '#fff',
  background: theme.palette.text.primary,
  borderRadius: '12px 12px 0 0',
  overflow: 'hidden',
  padding: '24px',
}));

export const Content = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '16px 24px',
  background: '#fff',
  borderRadius: '0 0 12px 12px',
  boxShadow: '0px 7px 10px 0px #0000001A',
  padding: '16px 24px',
  '& > *': {
    flexBasis: 'calc(33% - 16px)',
    flexShrink: 0,
  },
});

export const Title = styled((props) => (
  <Typography {...props} />
))(({ theme }) => ({
  width: '100%',
  fontSize: theme.typography.pxToRem(42),
  lineHeight: 1.3,
  fontWeight: 700,
  wordBreak: 'break-all',
}));
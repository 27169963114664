import { createTheme } from '@mui/material/styles';

//app breakpoints
// in Box: xs: 'none', md: 'flex'
// other: const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

//Roboto 400;500;700  normal: 500, bold: 700

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xlg: 1320,
      xl: 1536,
      xxl: 1700,
    },
  },
  shadows: [
    'none',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)'
  ],
  typography: {
    fontFamily: [
      '"Roboto"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    subtitle1: {
      fontWeight: 500
    },
    subtitle2: {
      fontWeight: 700
    },
    title: {
      fontSize: '25px',
      fontWeight: 700
    },
    subtitle: {
      fontSize: '18px',
      fontWeight: 700
    },
  },
  palette: {
    primary: {
      main: '#08008F',
    },
    secondary: {
      main: '#FA004A'
    },
    tertiary: {
      main: '#FFFFFF',
      contrastText: '#1B1636', //dark purple
    },
    error: {
      main: '#E6324F' //red
    },
    warning: {
      main:  '#EC7464' //red?
    },
    success: {
      main: '#6ACF99' //green
    },
    background: {
      default: '#F8F8FD',
      selected: '#F1F0FB', //light lilac
      accent: '#E4E4FD' //lilac
    },
    text: {
      primary: '#1B1636', //dark purple
      secondary: '#6C6A7E', //dark grey
      hint: '#9F9DAB' //grey
    },
    divider: 'rgba(140, 140, 255, .12)',
    action: {
      active:  'rgba(80, 80, 160, .54)',
      hover:  'rgba(140, 140, 255, .04)',
      selected: 'rgba(80, 80, 160, .08)',
    },
  },
  scrollbars: {
    default: {
      '&::-webkit-scrollbar': {
        width: 10,
        height: 10,
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        border: '2px solid #F8F8FD',
        borderRadius: 16
      }
    },
    paper: {
      '&::-webkit-scrollbar': {
        width: 10,
        height: 10,
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        border: '2px solid #fff',
        borderRadius: 16
      }
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: 'div',
          subtitle1: 'p',
          subtitle2: 'p',
        }
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { size: 'tiny' },
          style: {
            '& input': {
              padding: '5px 12px',
            },
            '& fieldset': {
              borderRadius: '10px',
            },
            '& .MuiSelect-select': {
              padding: '5px 12px',
            }
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        },
        notchedOutline: {
          borderWidth: '1px',
          borderColor: 'rgba(140, 140, 255, .24)'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: '"Roboto"'
        }
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: '1px solid rgba(140, 140, 255, .24)',
            borderRadius: '12px',
            '&:hover': {
              borderColor: '#1C1638'
            }
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 4
        },
        text: {
          borderRadius: 4
        },
        outlined: {
          borderRadius: 4
        }
      },
      variants: [
        {
          props: { size: 'xlarge' },
          style: {
            fontSize: '1rem',
            lineHeight: '1.6',
            fontWeight: 600,
            padding: '16px 24px',
          }
        },
        {
          props: { variant: 'contained', color: 'tertiary' },
          style: {
            color: '#1B1636', //text primary
            background: '#fff',
            borderRadius: 4,
          }
        },
      ]
    },
    MuiMenuItem: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            textTransform: 'uppercase',
            fontSize: '0.875rem',
            lineHeight: '1.43',
            fontWeight: 500,
            color: '#08008F'
          }
        }
      ]
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 30
        }
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: 10,
          }
        },
        {
          props: { size: 'large' },
          style: {
            fontWeight: 500,
            padding: '4px 0px',
          }
        }
      ]
    },
    MuiTableCell: {
      variants: [
        {
          props: { variant: 'tiny' },
          style: {
            border: 'none',
            padding: '6px 0',
          }
        }
      ]
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '12px',
            border: '2px solid #F1F0FC'
          }
        }
      ]
    }
  }
});

export default theme;
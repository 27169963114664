import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// Local files
import Form from "components/SignUp/Form/Form";

const SignUp = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        bgcolor: "background.paper",
      }}
    >
      <Container maxWidth="xs" sx={{ my: "auto", mx: "auto"}}>
        <Typography
          sx={{
            fontSize: "2.25rem",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Join Roadsider
        </Typography>
        <Form />
      </Container>
    </Box>
  );
};

export default memo(SignUp);

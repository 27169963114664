import { memo } from 'react';
//Local files
import { Card } from 'components/Common/BasePageUi/BasePageUi';
import Notifications from '../Notifications';

const NotificationsCard = ({ sx, userNotifications }) => {

  return (
    <Card size='large' sx={{ alignSelf: 'flex-start', ...sx }}>
      <Notifications {...{ userNotifications }} />
    </Card>
  );
};

export default memo(NotificationsCard);
import { memo, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import _omit from 'lodash/omit';
// Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import BaseFilter from 'components/Common/BaseFilter/BaseFilter';
import BaseListPage from 'components/Common/BaseListPage/BaseListPage';
import VehiclesList from 'components/Vehicles/List/List';
import Details from 'components/Vehicles/Details/Details';
import Filter from 'components/Common/Filter/Filter';
import ReleaseVehicleDrawer from 'components/Vehicles/Drawers/ReleaseVehicle/ReleaseVehicle'
import Stats from 'components/Vehicles/Stats/Stats';
import useVehicles from 'hooks/useVehicles';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import { vehicleStatuses } from 'helpers/constants';

const Vehicles = () => {
  const { setFilter } = useApp();
  const { getVehiclesStatistics, clearVehiclesStatistics, clearLocalActiveVehicle } = useVehicles();
  const { setError } = useError();
  const { open } = useCustomSelector(state => state.app.filter);
  const { over_time_vehicles_data } = useCustomSelector(state => state.vehicles.statistics);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [query, setQuery] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleQueryChange = (value) => setQuery(value);
  const handleFilterChange = () => { 
    setFilter(!open);
    setStatuses([]);
  };
  const fetchStatistics = useCallback(() => {
    setProcessing(true);
    getVehiclesStatistics()
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  }, [getVehiclesStatistics, setError]);

  useEffect(() => {
    mediaDesktop && fetchStatistics();

    return () => {
      clearVehiclesStatistics();
      clearLocalActiveVehicle();
    };
  }, [mediaDesktop, fetchStatistics, clearVehiclesStatistics, clearLocalActiveVehicle]);

  return (
    <>
      { mediaDesktop && !!over_time_vehicles_data.length && <ComponentErrorWrapper children={<Stats onStatuses={setStatuses} />} /> }
      <BaseListPage
        renderFilter={
          <Filter
            query={query}
            onQueryChange={handleQueryChange}
            onFilterClick={handleFilterChange}
            filterContent={
              <BaseFilter 
                label='Statuses'
                value={statuses} 
                onChange={setStatuses} 
                data={_omit(vehicleStatuses, ['NEW'])} 
              />
            }
          />
        }
        listLoading={fetching || processing}
        listComponent={
          <ComponentErrorWrapper 
            children={
              <VehiclesList
                query={query}
                statuses={statuses}
                fetching={fetching}
                onFetching={v => setFetching(v)}
              />
            } 
          />
        }
        renderMainAtDesktop={<ComponentErrorWrapper children={<Details />} />}
      />
      <ComponentErrorWrapper children={<ReleaseVehicleDrawer />} />
      <Outlet />
    </>
  );
};

export default memo(Vehicles);
import { memo } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
// Local files
import PhoneField from 'components/Common/PhoneField/PhoneField';
import { deliveryMethods } from 'helpers/constants';

const RecipientTextForm = ({ recipient, onRecientsChange }) => {

  const handleChecked = ({ target: { checked } }) => 
    onRecientsChange(prev => _map(prev, (item) => item.id === recipient.id ? { ...item, checked } : item));
  const handlePhone = ({ value, formattedValue, error }) =>
    onRecientsChange(prev => _map(prev, (item) => item.id === recipient.id ? { ...item, phone: { value, formattedValue, error } } : item));
  const handleEmail = ({ target: { value } }) => 
    onRecientsChange(prev => _map(prev, (item) => item.id === recipient.id ? { ...item, email: { value, error: '' } } : item));
  const handleName = ({ target: { value: name } }) => 
    onRecientsChange(prev => _map(prev, (item) => item.id === recipient.id ? { ...item, name } : item));

  return (
    <Box sx={{ width: '100%', display: 'flex', gap: '16px', alignItems: 'center', pr: 2 }}>
      <Checkbox
        checked={recipient.checked}
        onChange={handleChecked}
      />
      {recipient.delivery_method === deliveryMethods.TEXT ? <PhoneField
        label='Phone number'
        formattedValue={recipient.phone.formattedValue}
        error={!!recipient.phone.error}
        helperText={recipient.phone.error}
        onChange={handlePhone}
      /> : 
      <TextField 
        label='Email' 
        value={recipient.email.value} 
        helperText={recipient.email.error} 
        error={!!recipient.email.error} 
        onChange={handleEmail} 
      />}
      <TextField
        label='Name (Optional)'
        value={recipient.name} 
        onChange={handleName} 
      />
    </Box>
  )
}

export default memo(RecipientTextForm);

import { memo } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PropTypes from 'prop-types';
// Local files
import { Wrapper } from './Search.styled';
import BaseAddressAutocomplete from 'components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Search = ({ value, error, onChange }) => {
  return (
    <Wrapper>
      <LocationOnIcon color='error' sx={{ width: 32, height: 32, mr: 1 }} />
      <BaseAddressAutocomplete
        id='incident-location'
        margin='dense'
        label='Incident address'
        value={value}
        error={!!error}
        helperText={error}
        onChange={({ value, error = '', latitude = 0, longitude = 0 }) => onChange({ value, error, latitude, longitude })}
        inputSx={{ width: '620px' }}
      />
    </Wrapper>
  );
};

Search.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Search {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const ListWrapper = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'isModal' }
)(({ theme, isModal }) => ({
  width: 'calc(100% - 16px)',
  height: 'calc(40% - 8px)',
  position: 'absolute',
  top: 8,
  left: 8,
  right: 8,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  ...(isModal && {
    top: 84 + 8,//search, margin
    height: 'calc(40% - 84px - 8px)',
  }),
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    right: 'unset',
    width: 300,
    maxWidth: 300,
    height: '80%',
    borderRadius: '0 0 8px 8px',
  }
}));

export const ListHeader = styled(Box)({});
import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import _startCase from 'lodash/startCase';
import { useNavigate } from 'react-router-dom';
import _find from 'lodash/find';
//Local files
import { Root, Header, AmountText, DecorationContainer, DecorationLeft, DecorationRight, Title, Actions, DecorationCenter, Details, Content, ContentHead, StyledMenuItem } from './Main.styled';
import ServicesTable from 'components/Services/Table/Table';
import Status from '../../Status/Status';
import { invoiceStatuses, orderStatuses, accountTypes, reasons, vehicleStatuses, paymentTypes } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useDialogs from 'hooks/useDialogs';
import useRole from 'hooks/useRole';
import useInvoices from 'hooks/useInvoices';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useVehicles from 'hooks/useVehicles';
import useTimezones from 'hooks/useTimezones';

const { NEW, NOT_SUBMITTED, APPROVED, PAID, PARTIALLY_PAID, REJECTED } = invoiceStatuses;

const Main = ({ id, file, customer_signature, number, expenses, payments, order, status, total_amount, paid_amount, created_at }) => {
  const navigate = useNavigate();
  const { openDrawer } = useApp();
  const { openInvoiceDialog, openSignatureDialog } = useDialogs();
  const { approveInvoice, unApproveInvoice, rejectInvoice } = useInvoices();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { storeVehicle } = useVehicles();
  const { isManager, isOwner } = useRole();
  const { timeZone } = useTimezones(order?.company_location?.company?.id);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [newExpense, setNewExpense] = useState(false);
  const [processing, setProcessing] = useState(false);

  const { 
    account,
    authorization_number, 
    number: order_number,
    status: job_status,
    services,
    vehicle,
    police_number,
    police_code
  } = order;
  const showAddService = (isManager || isOwner)
    && job_status !== orderStatuses.CANCELED
    && job_status !== orderStatuses.REJECTED
    && (status === NOT_SUBMITTED || status === NEW);
  const showApprove = (isManager || isOwner) && status === NOT_SUBMITTED;
  const showUnApprove = (isManager || isOwner) && status !== NOT_SUBMITTED && status !== REJECTED && status !== PARTIALLY_PAID && status !== PAID;
  const showAddToStorage = (isManager || isOwner) && vehicle?.status === vehicleStatuses.NEW && status !== REJECTED;
  const showViewStorage = (isManager || isOwner) && vehicle?.status !== vehicleStatuses.NEW && vehicle?.status !== vehicleStatuses.RELEASED;
  const showPONumber = account?.account_type !== accountTypes.POLICE;
  const servicesData = { invoice_id: id, expenses, total_amount, paid_amount, editExpense: showAddService };
  const showReject = status !== PAID && status !== REJECTED;
  const showSendDetails = !!file && status !== REJECTED;
  const showSignature = !!file && !!!customer_signature && status !== REJECTED;
  const showChargeNow = (isManager || isOwner) && status !== NOT_SUBMITTED && status !== REJECTED && status !== PAID;

  const handleApproveUnApprove = () => {
    setProcessing(true);
    setMenuAnchor(null);
    (status === NOT_SUBMITTED ? approveInvoice(id) : unApproveInvoice(id))
    .then(() => setSuccess(`Invoice successfully ${status === APPROVED ? 'un' : ''}aproved`))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleChargeNow = () => openDrawer({ reason: reasons.PAYMENT });
  const handleSendInvoice = () => { 
    setMenuAnchor(null);
    openDrawer({ reason: reasons.SEND_INVOICE }) 
  };
  const handleAddToStorage = () => {
    setProcessing(true);
    setMenuAnchor(null);
    storeVehicle(vehicle.id)
    .then(() => setSuccess('Vehicle successfully stored'))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleReject = () => {
    setProcessing(true);
    setMenuAnchor(null);
    rejectInvoice(id)
    .then(() => setSuccess('Invoice successfully rejected'))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleCustomerSignature = () => {
    setMenuAnchor(null);
    openSignatureDialog(id);
  };

  return (
    <Root>
      <Header>
        <Box>
          <Title>{number}</Title>
          <Box sx={{ display: 'flex', gap: '24px', pt: '16px' }}>
            <AmountText>${total_amount?.toFixed(2)}</AmountText>
            <Status size='large' darkBackground value={status} />
          </Box>
        </Box>
        <Box flexGrow={1} />
        <Actions>
          { (isManager || isOwner) && !!file && <Button color='inherit' variant='outlined' disabled={processing} endIcon={<ArrowOutwardIcon />} onClick={() => openInvoiceDialog(file.url)}><span>View Invoice</span></Button> }
          { showApprove && <Button color='tertiary' variant='contained' disabled={processing} onClick={handleApproveUnApprove}>APPROVE</Button> }
          { showChargeNow && <Button color='tertiary' variant='contained' disabled={processing} onClick={handleChargeNow}>Charge Now</Button> }
          <Button color='inherit' disabled={processing} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)} endIcon={<MoreVertIcon />} sx={{ lineHeight: '1.0' }}>
            More
          </Button>
        </Actions>
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{ sx: { mt: '2px', width: '220px' } }}
          keepMounted
        >
          <StyledMenuItem onClick={() => navigate(`/orders/${order.id}`)}>View Order</StyledMenuItem>
          { (showAddToStorage || showViewStorage) && 
          <StyledMenuItem disabled={processing} onClick={() => (showAddToStorage && handleAddToStorage()) || (showViewStorage && navigate(`/vehicles/${vehicle.id}`))}>
            {showAddToStorage && 'Add vehicle to storage'}
            {showViewStorage && 'View Storage'}
          </StyledMenuItem> }
          { showSendDetails && <StyledMenuItem disabled={processing} onClick={handleSendInvoice}>{account?.account_type === accountTypes.POLICE ? 'Email' : 'Send'} invoice details</StyledMenuItem>}
          { showReject && <StyledMenuItem disabled={processing} onClick={handleReject}>Mark as rejected</StyledMenuItem> }
          { showUnApprove && <StyledMenuItem disabled={processing} onClick={handleApproveUnApprove}>UNAPPROVE</StyledMenuItem> }
          { showSignature && <StyledMenuItem disabled={processing} onClick={handleCustomerSignature}>Add Customer Signature</StyledMenuItem> }
        </Menu>
      </Header>
      <Details>
        <Box>
          <Typography variant='body2' color='textSecondary'>Created Date</Typography>
          <Typography variant='subtitle2'>{new Date(created_at).toLocaleDateString('en-US', { timeZone })}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Account</Typography>
          <Typography variant='subtitle2'>{account?.name}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>{showPONumber? 'PO' : 'Log'} Number</Typography>
          <Typography variant='subtitle2'>{showPONumber ? authorization_number || '-' : police_number || '-'}</Typography>
        </Box>
        { !showPONumber && <Box>
          <Typography variant='body2' color='textSecondary'>Police Code</Typography>
          <Typography variant='subtitle2'>{police_code}</Typography>
        </Box> }
        <Box>
          <Typography variant='body2' color='textSecondary'>Job Type</Typography>
          <Typography variant='subtitle2'>{_startCase(services[0]?.name) || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Job Status</Typography>
          <Typography variant='subtitle2'>{_startCase(job_status)}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Payment Type</Typography>
          <Typography variant='subtitle2'>{_find(paymentTypes, ({ value }) => value === payments[payments?.length - 1]?.payment_type)?.label || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Invoice Amount</Typography>
          <Typography variant='subtitle2'>${total_amount?.toFixed(2)}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Order No.</Typography>
          <Typography variant='subtitle2'>{order_number}</Typography>
        </Box>
      </Details>
      <DecorationContainer>
        <DecorationLeft />
        <DecorationCenter />
        <DecorationRight />
      </DecorationContainer>
      <Content>
        <ContentHead>
          <Typography variant='title'>Services</Typography>
          { showAddService && <Button startIcon={<AddIcon /> } onClick={() => setNewExpense(true)}>Add service / charge</Button>}
        </ContentHead>
        { !!id && <ServicesTable newExpense={newExpense} onNewExpense={setNewExpense} {...servicesData} /> }
      </Content>
    </Root>
  );
};

export default memo(Main);
import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import Tabs from '@mui/material/Tabs';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import { NavLink, useNavigate } from 'react-router-dom';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
// Local files
import { Root, StyledTab, StyledTableCell, NavigationContainer } from './Main.styled';
import { StyledSelect, StyledIcon, StyledMenuItem } from 'components/Dashboard/Common/StyledSelect.styled';
import Status from 'components/Orders/Status/Status';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOrders from 'hooks/useOrders';
import useDialogs from 'hooks/useDialogs';
import useMap from 'hooks/useMap';
import useSuccess from 'hooks/useSuccess';
import { orderStatuses, orderSources, reasons, sides } from 'helpers/constants';

const { NEW, COMPANY_ACCEPTED, MANAGER_ACCEPTED, EN_ROUTE, PICK_UP, SERVICING, DROP_OFF, SCHEDULED } = orderStatuses;
const { DIGITAL_DISPATCH, URGENTLY } = orderSources;

const Main = () => {
  const navigate = useNavigate();
  const { openDrawer } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { openAssignDialog, openRejectOrderDialog, openMapDialog } = useDialogs();
  const { getOrdersForDashboard, clearLocalOrdersWithUser, updateOrder } = useOrders();
  const { setMapConfigs } = useMap();
  const { dashboard: { data, pagination: { limit, offset, total_count } }, ordersInProgress, ordersWithoutUser, ordersScheduled } = useCustomSelector(state => state.orders);
  const [tab, setTab] = useState(ordersWithoutUser ? 'attention' : 'in_progress');
  const [eta, setEta] = useState({ value: '', orderId: '' });
  const [processing, setProcessing] = useState(false);
  const statuses = useMemo(() => tab === 'scheduled' ? [SCHEDULED] : tab === 'attention' ? [NEW] : [NEW, COMPANY_ACCEPTED, MANAGER_ACCEPTED, EN_ROUTE, PICK_UP, SERVICING, DROP_OFF], [tab]);

  const handleRejectClick = ({ id, source }) => openRejectOrderDialog({ id, source });
  const handleChangeTab = (_, newValue) => setTab(newValue);
  const fetchOrders = useCallback((limit = 4, offset, statuses = null) => {
    setProcessing(true);
    getOrdersForDashboard({ limit, offset, statuses, with_user: statuses?.length > 1, without_user: statuses?.length === 1 && statuses[0] !== SCHEDULED })
    .catch(error => setError(error))
    .finally(() => setProcessing(false));
  }, [getOrdersForDashboard, setError]);
  const handleBlur = (id, orderEta) => {
    setProcessing(true);
    if(eta.value !== orderEta) {
      updateOrder({ id, side: sides.USER, eta: eta.value })
      .then(() => setSuccess('Eta successfully changed').then(() => setEta({ value: '', orderId: '' })))
      .catch(error => setError(error))
      .finally(() => setProcessing(false))
    } else {
      setEta({ value: '', orderId: '' });
      setProcessing(false);
    }
  };
  const handleAssignDriver = (order_id, eta, isISSC, isUrgently, value, latitude, longitude, status, trucks, users) => (isISSC || isUrgently) ? 
  setMapConfigs({ localIncident: { value, latitude, longitude } })
  .then(() => openMapDialog({ order: { id: order_id, eta, status, trucks, users }, isISSC, isUrgently })) : openAssignDialog({ order: { id: order_id, eta, status, trucks, users }, isISSC, isUrgently });
  const handleAcceptOrder = (order_id, eta, isISSC, isUrgently, status, trucks, users) => openAssignDialog({ order: { id: order_id, eta, status, trucks, users }, isISSC, isUrgently });
  const handleAddClick = () => { 
    navigate('/orders'); 
    openDrawer({ reason: reasons.ORDER, edit: false });
  };

  useEffect(() => {
    clearLocalOrdersWithUser().then(() => fetchOrders(4, 0, statuses));
  }, [fetchOrders, clearLocalOrdersWithUser, statuses]);
  useEffect(() => {
    data.length < 4 && total_count > limit && fetchOrders(1, 4, statuses);
  }, [fetchOrders, limit, total_count, data.length, statuses]);

  return (
    <Root>
      <NavigationContainer>
        <Tabs
          variant='fullWidth'
          value={tab}
          onChange={handleChangeTab}
          sx={{ width: '80%' }}
        >
          {!!ordersWithoutUser && <StyledTab 
            disabled={processing} 
            value='attention' 
            label={<span>Attention Needed&nbsp; <Chip label={ordersWithoutUser} variant='badge' color='error' size='small'/></span>} 
          /> }
          <StyledTab disabled={processing} value='in_progress' label={`In Progress (${ordersInProgress})`} />
          {!!ordersScheduled && <StyledTab disabled={processing} value='scheduled' label={`Scheduled (${ordersScheduled})`} />}
        </Tabs>
        <Box>
          <Button component={NavLink} color='primary' endIcon={<ArrowOutwardIcon />} to='/orders'>VIEW ALL ORDERS</Button>
          <IconButton onClick={handleAddClick}><Add color='primary' /></IconButton>
        </Box>
      </NavigationContainer>
      { !!data.length && <TableContainer sx={{ mt: 2, borderRadius: 2 }}>
        <Table size='medium'>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ py: 2, width: '10%', fontSize: '14px', fontWeight: 400 }}>Order #</StyledTableCell>
              <StyledTableCell sx={{ py: 2, width: '10%', fontSize: '14px', fontWeight: 400 }}>Account</StyledTableCell>
              {tab === 'in_progress' && <StyledTableCell sx={{ py: 2, width: '10%', fontSize: '14px', fontWeight: 400 }}>PO number</StyledTableCell>}
              <StyledTableCell sx={{ py: 2, width: tab === 'in_progress' ? '15%' : '20%', fontSize: '14px', fontWeight: 400 }}>Job</StyledTableCell>
              <StyledTableCell sx={{ py: 2, width: '5%', fontSize: '14px', fontWeight: 400 }}>Status</StyledTableCell>
              <StyledTableCell sx={{ py: 2, width: '15%', fontSize: '14px', fontWeight: 400 }}>Driver</StyledTableCell>
              <StyledTableCell sx={{ py: 2, width: '10%', fontSize: '14px', fontWeight: 400 }}>ETA</StyledTableCell>
              <StyledTableCell sx={{ py: 2, width: tab === 'in_progress' ? '25%' : '30%', fontSize: '14px', fontWeight: 400 }}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: 'white' }}>
            {_map(data, ({ id, number, account, source, services, status, user, eta: orderEta, incident_location, authorization_number, trucks, users, scheduled_at }) => {
              const scheduledAndTodayDifference = new Date(scheduled_at).getTime() - new Date().getTime();
              const etaValue = scheduledAndTodayDifference > 86_400_000 ? `${(scheduledAndTodayDifference / 86_400_000).toFixed()} day${(scheduledAndTodayDifference > 86_400_000 &&  scheduledAndTodayDifference < 172_800_000) ? '' : 's'}` : `${new Date(new Date().setTime(scheduledAndTodayDifference)).getHours()} hrs, ${new Date(new Date().setTime(scheduledAndTodayDifference)).getMinutes()} mins`
              const isShowScheduledAtInEta = !!scheduled_at && new Date(scheduled_at).getTime() > new Date().getTime();

              return (
                <TableRow key={id}>
                  <StyledTableCell sx={{ py: 2, backgroundColor: '#1B1636', color: 'white' }}>{number}</StyledTableCell>
                  <StyledTableCell sx={{ py: 2, backgroundColor: '#1B1636', color: 'white' }}>{account?.name || '-'}</StyledTableCell>
                  {tab === 'in_progress' && <StyledTableCell sx={{ py: 2, backgroundColor: '#1B1636', color: 'white' }}>{authorization_number || '-'}</StyledTableCell> }
                  <StyledTableCell sx={{ py: 2, backgroundColor: '#1B1636', color: 'white' }}>{_startCase(services[0]?.name) || '-'}</StyledTableCell>
                  <StyledTableCell sx={{ py: 2, backgroundColor: '#1B1636', color: 'white' }}><Status size='medium' value={status} /></StyledTableCell>
                  <StyledTableCell sx={{ py: 2, fontSize: '14px', fontWeight: 700 }}>
                    {(!!user || (source !== DIGITAL_DISPATCH && source !== URGENTLY)) ? 
                    <StyledSelect 
                      variant='standard' 
                      value={user || { first_name: 'Assign', last_name: 'Driver' }}
                      label='Assign Driver'
                      IconComponent={StyledIcon}
                      renderValue={(selected) => `${selected?.first_name} ${selected?.last_name}`}
                      sx={{ color: 'black', fontSize: '14px', fontWeight: 700 }}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
                      open={false}
                      onOpen={() => handleAssignDriver(id, orderEta, source === DIGITAL_DISPATCH, source === URGENTLY, incident_location.address, incident_location.latitude, incident_location.longitude, status, trucks, users)}
                    >
                      <StyledMenuItem value={user}>
                        {`${user?.first_name} ${user?.last_name}`}
                      </StyledMenuItem>
                    </StyledSelect>
                    : `${user?.first_name || '-'} ${user?.last_name || ''}`}
                  </StyledTableCell>
                  <StyledTableCell sx={{ py: 2, fontSize: '14px', fontWeight: 700 }} onClick={() => setEta({ value: orderEta, orderId: id })}>
                    { eta.orderId === id ? 
                    <TextField 
                      fullWidth
                      sx={{ width: 48 }} 
                      size='small'
                      value={eta.value} 
                      onChange={({ target: { value } }) => setEta(prev => ({ ...prev, value }))}
                      onBlur={() => handleBlur(id, orderEta)}
                    /> : isShowScheduledAtInEta ? etaValue : orderEta }
                  </StyledTableCell>
                  <StyledTableCell sx={{ py: 2, display: 'flex', justifyContent: 'flex-end', alignContent: 'center'}}>
                    { !!!user && (source === DIGITAL_DISPATCH || source === URGENTLY) && <Box sx={{ pr: 4 }}>
                      <Button color='error' variant='text' disabled={processing} onClick={() => handleRejectClick({ id, source })}>Reject</Button> 
                      <Button color='primary' variant='text' disabled={processing} onClick={() => handleAcceptOrder(id, orderEta, source === DIGITAL_DISPATCH, source === URGENTLY, status, trucks, users)}>Confirm</Button>
                    </Box> }
                    <IconButton color='inherit' disabled={processing} onClick={() => navigate(`/orders/${id}`)}>
                      <ArrowIcon color='action' />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>}
      { !!data.length && data.length < total_count &&
      <Button color='primary' variant='outlined' disabled={processing} onClick={() => fetchOrders(4, limit + offset, statuses)} sx={{ width: '50%', mt: 4 }}>Show more</Button> }
    </Root>
  );
};

export default memo(Main);
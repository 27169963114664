import { memo, useState } from 'react';
//Local files
import { Root, StyledMenu, StyledMenuItem } from './Settings.styled';
import { ReactComponent as SettingsIcon } from 'assets/icons/tune-vert.svg';

const MapSettings = ({ mapStyle, onMapStyleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleItemClick = (value) => {
    onMapStyleChange(value);
    handleMenuClose();
  };

  return (
    <>
      <Root onClick={handleMenuOpen} style={{ zIndex: 20 }}>
        <SettingsIcon />
        <span>Map Settings</span>
      </Root>
      <StyledMenu
        id='map-settings-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleMenuClose}
      >
        <StyledMenuItem
          selected={mapStyle === 'mapbox://styles/mapbox/satellite-v9'}
          onClick={() => handleItemClick('mapbox://styles/mapbox/satellite-v9')}
        >
          Satellite
        </StyledMenuItem>
        <StyledMenuItem
          selected={mapStyle === 'mapbox://styles/mapbox/streets-v9'}
          onClick={() => handleItemClick('mapbox://styles/mapbox/streets-v9')}
        >
          Vector
        </StyledMenuItem>
      </StyledMenu>
    </>
  )
}

export default memo(MapSettings);
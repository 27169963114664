import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Head = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%'
}));
export const Canvas = styled('canvas')({
  maxHeight: 280,
  marginTop: 8
});
import React, { memo } from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
// Local files
import { hideError as hideErrorAction } from 'actions/error';
import useCustomSelector from 'hooks/useCustomSelector';

const ErrorSnackbar = () => {
  const dispatch = useDispatch();
  const hideError = () => dispatch(hideErrorAction());
  const { open, message } = useCustomSelector(state => state.error);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={hideError}
    >
      <Alert severity="error" onClose={hideError}>{message}</Alert>
    </Snackbar>
  );
};

export default memo(ErrorSnackbar);
import { FileChecksum } from 'helpers/FileChecksum';
import { getCompressedImage } from 'helpers/ImageCompressor';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import useError from './useError';

const useChecksum = () => {
  const { setError } = useError();
  const error = (err) => setError(err);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const processFiles = (files, callback) => {
    _forEach(files, processableFile => {
      if (_includes(processableFile.type, 'image/')) {
        getCompressedImage(processableFile, compressedImage => {
          const uploadedFile = new File([compressedImage], processableFile.name, { type: processableFile.type });
          
          toBase64(uploadedFile)
          .then(localUrl => {
            FileChecksum.create(uploadedFile, (err, checksum) => !!err ? error(err) : callback({ file: uploadedFile, checksum, localUrl }));
          }, error => setError(error));
        });
      } else {
        FileChecksum.create(processableFile, (err, checksum) => !!err ? error(err) : callback({ file: processableFile, checksum, localUrl: null }));
      }
    });
  };

  return { processFiles };
};

export default useChecksum;
import { memo } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const BaseListItem = ({ 
  title, description, currentUser = null, content,
  withAvatar = false, avatarSrc = null, avatarAlt = 'photo',
  selected, to, sx, textSx, disabled, createdAt = null,
  onClick = null,
  scheduledAt = null
}) => {

  return (
    <ListItemButton
      component={!!onClick ? 'button': NavLink}
      to={!!!onClick ? to : ''}
      selected={selected}
      disabled={disabled}
      onClick={onClick}
      sx={{
        py: 1.5,
        borderBottom: '1px solid',
        borderColor: 'background.selected',
        ...sx
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        {!!withAvatar && (
          <ListItemAvatar sx={{ minWidth: '60px' }}>
            <Avatar
              alt={avatarAlt}
              src={avatarSrc}
              sx={{
                height: '50px',
                width: '50px',
                bgcolor: 'action.active',
                color: 'rgba(255,255,255,.8)',
              }}
            />
          </ListItemAvatar>
        )}
        <ListItemText
          sx={textSx}
          primary={title}
          secondary={description}
          primaryTypographyProps={{ variant: 'body1', color: 'text.primary', noWrap: true }}
          secondaryTypographyProps={{ variant: 'caption', color: 'text.hint', noWrap: true, component: 'div' }}
        />
        {!!currentUser && <Typography variant='caption' color='text.hint' sx={{ wordBreak: 'break-all' }}>{currentUser}</Typography>}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '4px' }}>
          {content}
          <Typography variant='caption' color='text.hint' sx={{ wordBreak: 'break-all' }}>{scheduledAt || createdAt}</Typography>
        </Box>
      </Box>
    </ListItemButton>
  );
};

BaseListItem.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  description: PropTypes.node,
  currentUser: PropTypes.string,
  content: PropTypes.node,
  selected: PropTypes.bool,
  to: PropTypes.string,
  withAvatar: PropTypes.bool,
  avatarSrc: PropTypes.string,
  avatarAlt: PropTypes.string,
  sx: PropTypes.object,
  textSx: PropTypes.object
};

export default memo(BaseListItem);
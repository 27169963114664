import { memo, useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _trim from 'lodash/trim';
// Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import Password from 'components/Dialogs/Password/Password';
import { checkEmptyString, checkValidEmail } from 'helpers';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';
import useProfile from 'hooks/useProfile';

const General = () => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { openPasswordDialog } = useDialogs();
  const { updateProfile } = useProfile();
  const { user } = useCustomSelector(state => state.profile);
  const [firstName, setFirstName] = useState({ value: '', error: '' });
  const [lastName, setLastName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);

  const setFields = useCallback(() => {
    setProcessing(true);
    setFirstName({ value: user.first_name, error: '' });
    setLastName({ value: user.last_name, error: '' });
    setEmail({ value: user.email, error: '' });
    setProcessing(false);
  }, [user])
  const clearFields = () => {
    setFirstName({ value: '', error: '' });
    setLastName({ value: '', error: '' });
    setEmail({ value: '', error: '' });
    setProcessing(false);
  }

  useEffect(() => {
    setFields();

    return clearFields;
  }, [setFields]);

  const handleSave = () => {
    let hasError = false;

    if (!checkEmptyString(firstName.value)) {
      setFirstName(prev => ({ ...prev, error: 'First Name can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(lastName.value)) {
      setLastName(prev => ({ ...prev, error: 'Last Name can\'t be empty' }));
      hasError = true;
    }
    if (!checkValidEmail(email.value)) {
      setEmail(prev => ({ ...prev, error: 'Email is invalid' }));
      hasError = true;
    }
    if (hasError) return;

    const processableUser = {
      first_name: firstName.value,
      last_name: lastName.value,
      email: _trim(email.value)
    };

    setProcessing(true);
    if (needToUpdate(user, processableUser)) {
      updateProfile(processableUser)
      .then(() => setSuccess('User successfully updated'))
      .catch(error => setError(error))
      .finally(() => setProcessing(false));
    } else {
      setProcessing(false);
      setSuccess(`There's nothing to change`);
    }
  };
  const needToUpdate = (a, b) => a.email !== b.email || a.first_name !== b.first_name || a.last_name !== b.last_name;

  return (
    <>
      <ComponentErrorWrapper children={<Password />} />
      <Typography variant='title'>Main</Typography>
      <Box
        maxWidth={{ xs: 'unset', md: '616px' }}
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', mb: 4, mt: 1 }}
      >
        <TextField
          fullWidth
          sx={{ maxWidth: {md: '300px'} }}
          margin='dense'
          label='First Name'
          value={firstName.value}
          error={!!firstName.error}
          helperText={firstName.error}
          onChange={({ target: { value } }) => setFirstName({ value, error: '' })}
        />
        <TextField
          fullWidth
          sx={{ maxWidth: {md: '300px'} }}
          margin='dense'
          label='Last Name'
          value={lastName.value}
          error={!!lastName.error}
          helperText={lastName.error}
          onChange={({ target: { value } }) => setLastName({ value, error: '' })}
        />
      </Box>
      <Typography variant='title' mb={1}>Access</Typography>
      <Box maxWidth={{ xs: 'unset', md: '300px' }} sx={{ mt: 1 }}>
        <TextField
          fullWidth
          margin='dense'
          label='Email'
          type='email'
          value={email.value}
          error={!!email.error}
          helperText={email.error}
          onChange={({ target: { value } }) => setEmail({ value, error: '' })}
        />
      </Box>
      <Typography variant='caption' color='textSecondary' component={'div'} mb={1} mt={2}>
        If you want to change your password, open the form:
      </Typography>
      <Button variant='outlined' color='primary' disabled={processing} onClick={openPasswordDialog} sx={{ textTransform: 'none', borderRadius: '8px', mb: 4 }}>Change current password</Button>
      <Box p={{ xs: '24px 0', md: '24px 0', width: '100%' }} maxWidth={{ xs: 'unset', md: '300px' }}>
        <Button fullWidth color='primary' variant='contained' size='xlarge' disabled={processing} onClick={handleSave}>Save</Button>
      </Box>
    </>
  );
}

export default memo(General);
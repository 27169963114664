import { memo, useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
// Local files
import PageLoader from 'components/Common/PageLoader/PageLoader';
import useCustomSelector from 'hooks/useCustomSelector';
import useSession from 'hooks/useSession';
import useSessions from 'hooks/useSessions';

const CheckAuth = ({ children }) => {
  const location = useLocation();
  const { authenticated, checked } = useSession();
  const { setLocalToken } = useSessions();
  const profileFetched = useCustomSelector(state => !!state.profile.user.id);
  const [storageChecked, setStorageChecked] = useState(false);
  const localStorageToken = localStorage.getItem('access_token');

  useEffect(() => {
    if (!!localStorageToken) {
      setLocalToken(localStorageToken)
      .then(() => {
        localStorage.removeItem('access_token');
        setStorageChecked(true);
      });
    } else {
      setStorageChecked(true);
    }
    localStorage.removeItem('customer_token');
  }, [localStorageToken, setLocalToken]);

  if (checked && storageChecked) {
    return authenticated
    ? (profileFetched ? children : <PageLoader />)
    : <Navigate to='/sign_in' state={{ from: location }} replace />;
  }
  return <PageLoader />;
};

export default memo(CheckAuth);
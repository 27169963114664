import { memo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
// Local files
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';

const Invoice = (props) => {
  const { closeInvoiceDialog } = useDialogs();
  const { open, url } = useCustomSelector(state => state.dialogs.invoice);

  const handleDownloadClick = () => {
    const win = window.open(url, '_blank');
      
    win.focus();
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeInvoiceDialog}
      title='Invoice'
      maxWidth='md'
      fullWidth
      {...props}
    >
      <DialogContent>
        <Box sx={{ height: '75vh' }}>
          <iframe
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '90%', border: 0 }}
            src={`${url}#zoom=FitW`}
            title='Invoice'
          />
        </Box>
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          onClick={closeInvoiceDialog}
        >
          Close
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          onClick={handleDownloadClick}
        >
          Download
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(Invoice);
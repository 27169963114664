import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Root = styled(Box)(() => ({
  width: '100%',
  maxWidth: '100%'
}));
export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  color: '#fff',
  background: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: ' 24px 16px',
  padding: '20px 24px',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px 12px 0 0',
  }
}));
export const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  fontSize: theme.typography.pxToRem(42),
  fontWeight: 700,
}));
export const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
});
export const Details = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '16px 32px',
  background: '#fff',
  padding: '24px 16px 8px',
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 7px 10px 0px #0000001A',
    padding: '24px 30px 8px',
  }
}));
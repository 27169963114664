import { memo } from 'react';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';

const FilterContent = ({ children }) => {
  const { open } = useCustomSelector(state => state.app.filter);

  return (
    <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
      <Box sx={{ p: '16px 0px 4px 0px' }}>{children}</Box>
    </Collapse>
  );
};

export default memo(FilterContent);
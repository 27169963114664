import {
  setErrorRoutine,
  hideErrorRoutine
} from 'actions';
import _get from 'lodash/get';
import { errorTypes, errorMessages } from 'helpers/constants';

const { ACCESS_TOKEN_INVALID, AUTHORIZE_DATA_INVALID, ACCESS_FORBIDDEN, RECORD_NOT_FOUND, TOO_MANY_REQUESTS } = errorMessages;
const { AUTHORIZE_DATA_INVALID: AUTHORIZE_DATA_INVALID_TYPE } = errorTypes;

export const initialState = {
  message: '',
  type: '',
  open: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setErrorRoutine.SUCCESS: {
      const { error } = action.payload;

      const type = _get(error, 'response.data.error.type') || '';
      const status = _get(error, 'response.status') || null;
      const defaultMessage = typeof error === 'string' ? error : 'Failed to load data';
      let customErrorText = false;
      let message = '';

      if (_get(error, 'response.data.error.message')) {
        message = error.response.data.error.message;
        customErrorText = true;
      }
      if (status === 401) {
        message = ACCESS_TOKEN_INVALID;
        customErrorText = true;
      }
      if (status === 403) {
        message = ACCESS_FORBIDDEN;
        customErrorText = true;
      }
      if (status === 404) {
        message = RECORD_NOT_FOUND;
        customErrorText = true;
      }
      if (status === 429) {
        message = TOO_MANY_REQUESTS;
        customErrorText = true;
      }
      if (type === AUTHORIZE_DATA_INVALID_TYPE) {
        message = AUTHORIZE_DATA_INVALID;
        customErrorText = true;
      }

      return { ...state, type, message: customErrorText ? message : defaultMessage, open: true };
    }
    case hideErrorRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
};

export default reducer;
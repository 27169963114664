import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _startCase from 'lodash/startCase';
//Local files
import { Root, Header, Title, Actions, Details } from './Main.styled';
import { getFormattedPhone } from 'helpers';
import { userFieldsTypes } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useTimezones from 'hooks/useTimezones';

const Main = ({ first_name, last_name, phone, email, role, created_at, last_all_orders, address, second_address, city, zip, state }) => {
  const { openDrawer } = useApp();
  const { timeZone } = useTimezones(last_all_orders[0]?.company_location?.company?.id);

  return (
    <Root>
      <Header>
        <Title>{first_name} {last_name}</Title>
        <Actions>
          <Button variant='outlined' color='tertiary' onClick={() => openDrawer({ reason: role, edit: true, fieldsType: userFieldsTypes.FULL })}>
            EDIT
          </Button>
        </Actions>
      </Header>
      <Details>
        <Box>
          <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Created Date</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{new Date(created_at).toLocaleDateString('en-US', { timeZone })}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Phone</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{getFormattedPhone(phone)}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Email</Typography>
          <Typography variant='subtitle2' sx={{wordBreak: 'break-all'}}>{email || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Role</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{_startCase(role) || '-'}</Typography>
        </Box>
        { role !== 'driver' && !!address && <Box>
          <Typography variant='body2' color='textSecondary'>Address</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'break-spaces' }}>{address}</Typography>
        </Box> }
        { !!second_address && <Box>
          <Typography variant='body2' color='textSecondary'>Second Address</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'break-spaces'}}>{second_address}</Typography>
        </Box> }
        { !!city && <Box>
          <Typography variant='body2' color='textSecondary'>City</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{city}</Typography>
        </Box> }
        { !!zip && <Box>
          <Typography variant='body2' color='textSecondary'>Zip</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{zip}</Typography>
        </Box> }
        { !!state && <Box>
          <Typography variant='body2' color='textSecondary'>State</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{state}</Typography>
        </Box> }
      </Details>
    </Root>
  );
};

export default memo(Main);
import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import _find from 'lodash/find';
import _map from 'lodash/map';
//Local files
import { Root, ServicesContainer, CustomerContainer, LocationsContainer, LabelContainer, LabelText } from './Form.styled';
import BaseAddressAutocomplete from 'components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import MainServiceCard from './Cards/Main/Main';
import OtherServiceCard from './Cards/Other/Other';
import useLocations from 'hooks/useLocations';
import useCustomers from 'hooks/useCustomers';
import useServices from 'hooks/useServices';
import useOrders from 'hooks/useOrders';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import { sides, widgetOtherServicesTypes, widgetServicesTypes, serviceClassTypes } from 'helpers/constants';

const inputStyles = {
  maxWidth: { xs: 'unset', sm: 'calc(50% - 12px)' },
  width: '100%',
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    background: '#fff',
    borderRadius: '8px',
    border: '1px solid #E6E6E6',
    boxShadow: '0px 1px 7px 0px #0000000F',
    marginTop: '12px',
    paddingRight: '14px'
  }
};
const textAreaStyles = {
  width: '100%',
  '& fieldset': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    width: '100%',
    background: '#fff',
    borderRadius: '8px',
    border: '1px solid #E6E6E6',
    boxShadow: '0px 1px 7px 0px #0000000F'
  }
};

const Form = () => {
  const { company_id } = useParams();
  const { createOrder } = useOrders();
  const { createCustomer, getCustomers } = useCustomers();
  const { createLocation } = useLocations();
  const { getServices } = useServices();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const [query, setQuery] = useState('tow');
  const [internalNotes, setInternalNotes] = useState({ otherService: '', notes: '' });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [customer, setCustomer] = useState({ customer_token: '', name: '', error: '' });
  const [incident, setIncident] = useState({ value: '', error: '', latitude: 0, longitude: 0 });
  const [destination, setDestination] = useState({ value: '', error: '', latitude: 0, longitude: 0 });
  const [classType, setClassType] = useState({ value: 'light_duty', error: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [fetching, setFetching] = useState(false);

  const handleOtherClick = (event) => setAnchorEl(event.currentTarget);
  const handleOtherService = (otherService) => {
    setAnchorEl(null);
    setInternalNotes(prev => ({ ...prev, otherService }));
    setQuery('');
  };
  const handleClose = () => setAnchorEl(null);
  const handleClear = () => {
    setQuery('tow');
    setInternalNotes({ otherService: '', notes: '' });
    setPhone({ value: '', formattedValue: '', error: '' });
    setCustomer({ customer_token: '', name: '', error: '' });
    setIncident({ value: '', error: '', latitude: 0, longitude: 0 });
    setDestination({ value: '', error: '', latitude: 0, longitude: 0 });
    setClassType({ value: 'light_duty', error: '' });
    setProcessing(false);
    localStorage.removeItem('customer_token');
  };
  const handleBlur = ({ target: { value } }) => {
    if (!!value) {
      setFetching(true);
      getCustomers({ phone_email: phone.value, side: sides.CUSTOMER })
      .then(({ payload: { data: { customers } } }) => {
        const foundedCustomer = _find(customers, (c) => phone.value === c?.phone) || null;
        
        if (!!foundedCustomer){
          setCustomer({ customer_token: foundedCustomer?.customer_token, name: foundedCustomer?.name || customer.name, error: '' });
          localStorage.setItem('customer_token', foundedCustomer?.customer_token);  
        }
      })
      .catch((error) => setError(error))
      .finally(() => setFetching(false))
    }
  };
  const handleServiceSet = (location) => {
    if (!!query) {
      getServices({ offset: 0, query, side: sides.CUSTOMER })
      .then(({ payload: { data: { services } } }) => handleLocationsCreate(location, services))
      .catch((error) => setError(error).then(() => setProcessing(false)))
    } else {
      handleLocationsCreate(location, null);
    }
  };
  const handleLocationsCreate = (location, services = null) => {
    createLocation({ location: location?.incident_location, side: sides.CUSTOMER })
    .then(({ payload: { data: { location: incident_location } } }) => 
      !!location.destination_location?.address ? 
      createLocation({ location: location?.destination_location, side: sides.CUSTOMER })
      .then(({ payload: { data: { location: destination_location } } }) => handleOrderCreate(incident_location, destination_location, services))
      .catch((error) => setError(error).then(() => setProcessing(false))) : handleOrderCreate(incident_location, null, services)
    )
    .catch((error) => setError(error).then(() => setProcessing(false)))
  };
  const handleOrderCreate = (incident_location, destination_location, services) => {
    createOrder({ 
      side: sides.CUSTOMER, 
      order: { 
        incident_location_id: incident_location.id, 
        destination_location_id: destination_location?.id || null,
        ...(!!query && { service_ids: [services[services.length - 1]?.id] }),
        ...((!!internalNotes.notes || !!internalNotes.otherService) && { 
          internal_notes: `${internalNotes.notes}${!!internalNotes.otherService ? '\n' : ''}${internalNotes.otherService}` 
        }),
        class_type: classType.value || null
      }, 
      company_id 
    })
    .then(() => setSuccess('Request successfully sent'))
    .catch((error) => setError(error))
    .finally(handleClear)
  };
  const handleRequest = () => {
    let hasError = false;

    if (!!!incident.value) {
      setIncident(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (!!!phone.value) {
      setPhone(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (hasError) return;

    const incident_location = { address: incident.value, longitude: incident.longitude, latitude: incident.latitude };
    const destination_location = { address: destination.value, longitude: destination.longitude, latitude: destination.latitude };

    setProcessing(true);
    if (!!customer.customer_token){
      handleServiceSet({ incident_location, destination_location });
    } else {
      createCustomer({ customer: { name: customer.name, phone: phone.value }, side: sides.CUSTOMER })
      .then(({ payload: { data: { customer: { customer_token } } } }) => {
        localStorage.setItem('customer_token', customer_token);    
  
        handleServiceSet({ incident_location, destination_location });
      })
      .catch((error) => setError(error).then(() => setProcessing(false)))
    }
  };

  return (
    <Root>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ sx: { mt: 1, maxWidth: 300, padding: 0, } }}
      >
        <List disablePadding>
          { _map(widgetOtherServicesTypes, (type) =>
          <OtherServiceCard 
            key={type}
            type={type}
            selected={internalNotes === type}
            onClick={() => handleOtherService(type)}
            disabled={processing || fetching}
          />)}
        </List>
      </Popover>
      <ServicesContainer>
        { _map(widgetServicesTypes, ({ type, label, icon }) => 
          <MainServiceCard
            key={type}
            icon={icon}
            label={type === '' ? 
            <LabelContainer>
              <LabelText>Other</LabelText>
              <KeyboardArrowDownIcon />
            </LabelContainer> : label}
            onClick={(e) => type === '' ? handleOtherClick(e) : setQuery(type)}
            selected={query === type}
            disabled={processing || fetching}
          />
        )}
      </ServicesContainer>
      <CustomerContainer>
        {query === 'tow' && <TextField 
          label='Class Type' 
          size='small'
          value={classType.value} 
          InputLabelProps={{ shrink: true }}
          sx={{ width: '100%', backgroundColor: 'white' }}
          select
          helperText={classType.error} 
          error={!!classType.error} 
          onChange={({ target: { value } }) => setClassType({ value, error: '' })} 
          disabled={processing || fetching}
        >
          { _map(serviceClassTypes, ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>) }
        </TextField>}
        <TextField
          label='Name'
          size='small'
          InputLabelProps={{ shrink: true }}
          sx={inputStyles}
          value={customer.name}
          error={!!customer.error}
          helperText={customer.error}
          onChange={({ target: { value: name } }) => setCustomer(prev => ({ ...prev, name, error: '' }))} 
          disabled={processing || fetching}
        />
        <PhoneField
          label='Phone Number'
          size='small'
          InputLabelProps={{ shrink: true }}
          sx={inputStyles}
          formattedValue={phone.formattedValue}
          error={!!phone.error}
          helperText={phone.error}
          onChange={({ value, formattedValue, error }) => setPhone({ value, formattedValue, error })}
          disabled={processing || fetching}
          onBlur={handleBlur}
        />
        <LocationsContainer>
          <BaseAddressAutocomplete
            id='user-location'
            label='Your Location'
            size='small'
            value={incident.value}
            error={!!incident.error}
            helperText={incident.error}
            onChange={({ value, error, latitude, longitude }) => setIncident({ value, error, latitude, longitude })}
            InputLabelProps={{ shrink: true }}
            sx={{ ...inputStyles, maxWidth: '100%' }}
            inputSx={{ width: '100%' }}
            otherInputProps={{ sx: { paddingRight: '0px !important' } }}
            widget
          />
          <BaseAddressAutocomplete
            id='destination-location'
            label='Vehichle drop off location'
            size='small'
            value={destination.value}
            error={!!destination.error}
            helperText={destination.error}
            onChange={({ value, error, latitude, longitude }) => setDestination({ value, error, latitude, longitude })}
            InputLabelProps={{ shrink: true }}
            sx={{ ...inputStyles, maxWidth: '100%' }}
            inputSx={{ width: '100%' }}
            otherInputProps={{ sx: { paddingRight: '0px !important' } }}
            widget
          />

        </LocationsContainer>
          <TextField
            label='Notes'
            size='small'
            sx={textAreaStyles}
            multiline
            minRows={4}
            maxRows={6}
            value={internalNotes.notes}
            onChange={({ target: { value: notes } }) => setInternalNotes(prev => ({ ...prev, notes }))} 
            disabled={processing || fetching}
          />
      </CustomerContainer>
      <Button 
        variant='contained' 
        color='primary' 
        sx={{ display: 'flex', margin: '0 auto' }} 
        disabled={processing || fetching || !!!phone.value || !!phone.error} 
        onClick={handleRequest}
        endIcon={processing ? <CircularProgress color='primary' size={20} /> : null}
      >
        Request Service
      </Button>
    </Root>
  );
}

export default memo(Form);
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  position: 'relative',
  width: 260,
  height: 170,
});

export const Info = styled(Box)({
  position: 'absolute',
  bottom: 0, right: 0, left: 0,
  height: 45,
  background: 'rgba(0,0,0,.6)',
  display: 'flex',
  gap: '4px',
  padding: 12,
});

export const StyledChip = styled(Chip)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.main,
  background: theme.palette.background.selected,
}))

export const Image = styled(Box)({
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  cursor: 'pointer',
});

export const DocPlaceholder = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#f4f4f8',
  cursor: 'pointer',
  paddingBottom: 45,
  '& > *': {
    maxHeight: 36,
  }
}));

export const DeleteButton = styled(IconButton)({
  width: 30,
  height: 30,
  position: 'absolute',
  right: 8, top: 8,
  color: '#fff',
  background: 'rgba(0,0,0,.6) !important',
});

import { memo, useEffect, useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useAccounts from 'hooks/useAccounts';

const AccountsAutocomplete = ({ value, error, helperText, onChange }) => {
  const { getListAccounts, clearLocalListAccounts } = useAccounts();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.accounts.list);
  const [loading, setLoading] = useState(false);

  const handleChange = (_, formattedValue) => onChange({ value: formattedValue.id, formattedValue, error: '' });;
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchAccounts = useCallback(offset => {
    setLoading(true);
    getListAccounts({ offset })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListAccounts, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchAccounts(limit + offset);
    }
  };

  useEffect(() => {
    fetchAccounts(0);

    return clearLocalListAccounts;
  }, [fetchAccounts, clearLocalListAccounts]);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      id="accountsList"
      options={data}
      getOptionLabel={o => `${o.name} - ${o.company?.name}`}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      fullWidth
      renderInput={params => <TextField {...params} label='Account *' error={error} helperText={helperText} />}
    />
  );
};

export default memo(AccountsAutocomplete);
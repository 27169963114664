import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useCompanies from 'hooks/useCompanies';

const CompaniesAutocomplete = ({ multiple = false, fullWidth = false, margin = 'none', sx, value, error, helperText, onChange }) => {
  const { setError } = useError();
  const { getCompanies, clearLocalCompanies } = useCompanies();
  const { data, pagination } = useCustomSelector(state => state.companies);
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCompanies = useCallback((offset, query) => {
    setLoading(true);
    getCompanies({ offset, query })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => setLoading(false))
  }, [getCompanies, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, offsetHeight } }) => {
    if (scrollHeight === scrollTop + offsetHeight + 100) {
      const { limit, offset, count, total_count } = pagination;

      total_count > count + offset && fetchCompanies(limit + offset);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalCompanies().then(() => fetchCompanies(0, query)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [query, fetchCompanies, clearLocalCompanies]);

  return (
    <Autocomplete
      disablePortal
      multiple={multiple}
      fullWidth={fullWidth}
      sx={sx}
      id='companies'
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={o => o.name}
      options={data}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={(_, formattedValue) => onChange({ value: multiple ? _map(formattedValue, ({ id }) => id) : formattedValue?.id, formattedValue, error: '' })}
      loading={loading}
      onInputChange={(_, value, reason) => reason !== 'reset' && setQuery(value)}
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      renderInput={params =>
        <TextField
          {...params}
          margin={margin}
          label={`Select compan${ multiple ? 'ies' : 'y'}`}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>
              {loading && <CircularProgress color="inherit" size={20} />}
              {params.InputProps.endAdornment}
            </>
          }}
        />
      }
    />
  );
};

CompaniesAutocomplete.propTypes = {
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  onChange: PropTypes.func.isRequired
};

export default memo(CompaniesAutocomplete);
import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
// Local files
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import { checkEmptyString } from 'helpers';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useRequests from 'hooks/useRequests';

const AnswerRequest = ({ open, request_id, onClose }) => {
  const { answerRequest } = useRequests();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const [response, setResponse] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);

  const handleExited = () => setResponse({ value: '', error: '' });
  const handleAssignClick = () => {
    let hasError = false;

    if (!checkEmptyString(response.value)) {
      setResponse(prev => ({ ...prev, error: 'Response can\'t be empty' }));
      hasError = true;
    }
    if (hasError) return;

    setProcessing(true);
    answerRequest({ request_id, response: response.value })
    .then(() => setSuccess('Request answer successfully sent').then(onClose))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 360,  borderRadius: '16px' } }}
      title='Request answer'
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogContent>
        <TextField
          fullWidth
          margin='dense'
          label='Response'
          value={response.value}
          error={!!response.error}
          helperText={response.error}
          inputProps={{ maxLength: 500 }} 
          multiline
          minRows={4}
          maxRows={4}
          onChange={({ target: { value } }) => setResponse({ value, error: '' })}
        />
      </DialogContent>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleAssignClick}
        >
          Send
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(AnswerRequest);
import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useCharges from 'hooks/useCharges';

const filter = createFilterOptions();

const ChargesAutocomplete = ({ disabled = false, charge_type, value, error, helperText, onChange, FormHelperTextProps }) => {
  const { getListCharges, createCharge, clearLocalListCharges } = useCharges();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.charges.list);
  const [loading, setLoading] = useState(false);

  const handleChange = (_, formattedValue) => { 
    if (!!formattedValue?.inputValue) {
      createCharge({ name: formattedValue?.inputValue, charge_type })
      .then(({ payload: { data: { charge  } } }) => onChange({ value: charge.id, formattedValue: charge, error: '' }))
      .catch((error) => setError(error))
    } else {
      onChange({ value: formattedValue.id, formattedValue, error: '' });
    }
  };
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option.name);
    if (inputValue !== '' && !isExisting) filtered.push({ inputValue, name: `Add "${inputValue}"` });

    return filtered;
  };
  const getOptionLabel = option => {
    if (typeof option === 'string') return option;
    if (option.inputValue) return option.inputValue;
    return option.name;
  };
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchCharges = useCallback((offset, charge_type) => {
    setLoading(true);
    clearLocalListCharges()
    .then(() => 
      getListCharges({ offset, charge_type })
      .catch(e => setError(e))
      .finally(() => setLoading(false))
    )
  }, [getListCharges, clearLocalListCharges, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchCharges(limit + offset, charge_type);
    }
  };

  useEffect(() => {
    fetchCharges(0, charge_type);
  }, [charge_type, fetchCharges]);

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      onChange={handleChange}
      filterOptions={filterOptions}
      id="chargesList"
      options={data}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      fullWidth
      renderInput={params => <TextField {...params} size='small' label='Charge' error={error} helperText={helperText} FormHelperTextProps={FormHelperTextProps} />}
    />
  );
};

export default memo(ChargesAutocomplete);
import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
//Local files
import { Card, CardHeader, CardTitle } from 'components/Common/BasePageUi/BasePageUi';


const DigitalBillingCard = ({ billing_email, second_billing_email, third_billing_email, insurance_accounts }) => {

  return (
    <Card size='small' sx={{ alignSelf: 'flex-start' }}>
      <CardHeader>
        <CardTitle>Digital Billing</CardTitle>
      </CardHeader>
      <Box>
        <Typography variant='body2' color='textSecondary' mb={.5}>To:</Typography>
        <Typography variant='subtitle2' mb={2} sx={{wordBreak: 'break-all'}}>{billing_email || '-'}</Typography>
        <Typography variant='body2' color='textSecondary' mb={.5}>Cc:</Typography>
        <Typography variant='subtitle2' mb={2} sx={{wordBreak: 'break-all'}}>{second_billing_email || '-'}</Typography>
        <Typography variant='body2' color='textSecondary' mb={.5}>Bcc:</Typography>
        <Typography variant='subtitle2' sx={{wordBreak: 'break-all'}}>{third_billing_email || '-'}</Typography>
        <Typography variant='body2' color='textSecondary' mb={.5}>Insurance Accounts:</Typography>
        {_map(insurance_accounts, (item) => <Typography key={item.id} variant='subtitle2' sx={{wordBreak: 'break-all'}}>{item.name}</Typography>)}
      </Box>
    </Card>
  );
};

export default memo(DigitalBillingCard);
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  marginTop: 8,
  marginBottom: 8,
  gap: 8,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: 16
  },
}));
import {
  getListTrucksRoutine,
  getTrucksRoutine,
  getTruckRoutine,
  createTruckRoutine,
  updateTruckRoutine,
  deleteTruckRoutine,
  clearLocalListTrucksRoutine,
  clearLocalTrucksRoutine,
  clearLocalActiveTruckRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  truck: {
    id: '',
    nickname: null,
    type: null,
    current_order: null,
    current_user: null,
    urgently_vehicle_type: '',
    last_orders: [],
    users: [],
    make: '',
    model: '',
    year: 0,
    license_plate: '',
    created_at: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListTrucksRoutine.SUCCESS: {
      const { data: { trucks, pagination } } = action.payload;

      return { ...state, list: { data: _uniqBy([...state.all.data, ...trucks], 'id'), pagination } };
    }
    case getTrucksRoutine.SUCCESS: {
      const { data: { trucks, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...trucks], pagination } };
    }
    case getTruckRoutine.SUCCESS: {
      const { data: { truck } } = action.payload;

      return { ...state, truck };
    }
    case createTruckRoutine.SUCCESS: {
      const { data: { truck } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[truck], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateTruckRoutine.SUCCESS: {
      const { response: { status }, truck } = action.payload;
      const updatedTruck = { ...state.truck, ...truck, users: truck.localUsers };
      const data = _map(state.all.data, (item) => item.id === truck.id
        ? { ...item, ...truck, users: truck.localUsers }
        : item
      );

      return { ...state, ...(status === 204 && { truck: updatedTruck }), all: { ...state.all, data } };
    }
    case deleteTruckRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case clearLocalListTrucksRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalTrucksRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveTruckRoutine.SUCCESS: {
      return { ...state, truck: initialState.truck }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
import { memo, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
//Local files
import { Card, CardHeader, CardTitle } from 'components/Common/BasePageUi/BasePageUi';
import { StyledTab } from './Card.styled';
import Field from './Field/Field';
import TabPanel from './TabPanel/TabPanel';
import useUrl from 'hooks/useUrl';
import useAccounts from 'hooks/useAccounts';
import useOrders from 'hooks/useOrders';
import useInvoices from 'hooks/useInvoices';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useUsers from 'hooks/useUsers';
import useTrucks from 'hooks/useTrucks';
import useCustomSelector from 'hooks/useCustomSelector';
import { sides } from 'helpers/constants';

const NotesCard = ({ 
  description = null, 
  internal_notes = null, 
  dispatcher_notes = null,
  scheduled_at = null, 
  urgently_partner_name = null, 
  urgently_disablement_reason = null, 
  urgently_callback = null,
  invoice_notes = null,
  previous_notes = null,
  notesData = null,
  towVehicleNotesData = null,
  id = null,
  order_id = null,
  invoice_id = null,
  edit = false,
  duplicate = false,
  disabled = false,
  isCreate = false, 
  hideTabs = false, 
  processingData = false,
  isEditable = true,
  onChangeProcessingData, 
  getNotesData, sx 
}) => {
  const { isAccountsRoute, isOrdersRoute, isInvoicesRoute, isVehiclesRoute, isDriversRoute, isManagersRoute } = useUrl();
  const { updateAccount } = useAccounts();
  const { updateOrder } = useOrders();
  const { updateInvoice } = useInvoices();
  const { updateUser } = useUsers()
  const { updateTruck } = useTrucks()
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open } = useCustomSelector(state => state.app.order);
  const [jobDescription, setJobDescription] = useState('');
  const [internalNotes, setInternalNotes] = useState('');
  const [dispatcherNotes, setDispatcherNotes] = useState('');
  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [notes, setNotes] = useState('');
  const [tab, setTab] = useState(0);
  const [processing, setProcessing] = useState(false);

  const needToUpdate = (a,b) => a.internal_notes !== b.internal_notes || a.dispatcher_notes !== b.dispatcher_notes || a.description !== b.description ;
  const needToUpdateInvoicesNotes = (a,b) => a.invoice_notes !== b.invoice_notes;
  const needToUpdateOrder = useCallback(() => (!!internalNotes || !!dispatcherNotes || !!jobDescription) && (internal_notes !== internalNotes || dispatcher_notes !== dispatcherNotes || description !== jobDescription)
  , [internal_notes, internalNotes, dispatcher_notes, dispatcherNotes, description, jobDescription]);
  const needToUpdateInvoice = useCallback(() => !!invoiceNotes && invoiceNotes !== invoice_notes, [invoiceNotes, invoice_notes]);
  const handleUpdateModel = () => (isDriversRoute || isManagersRoute) ? updateUser({ id, notes }) : isAccountsRoute ? updateAccount({ id, notes }) : updateTruck({ id, notes });
  const handleUpdateNotes = () => {
    if (!open) {
      if (isOrdersRoute || isInvoicesRoute || isVehiclesRoute) {
        if (needToUpdateOrder()) {
          setProcessing(true);
          updateOrder({ id: order_id, side: sides.USER, internal_notes: internalNotes, dispatcher_notes: dispatcherNotes, description: jobDescription })
          .then(() => setSuccess('Order notes updated'))
          .catch((error) => setError(error))
          .finally(() => setProcessing(false))
        }
        if (needToUpdateInvoice()) {
          setProcessing(true);
          updateInvoice({ id: invoice_id, notes: invoiceNotes })
          .then(() => setSuccess('Invoice notes updated'))
          .catch((error) => setError(error))
          .finally(() => setProcessing(false))
        }
      } else {
        if (notes !== previous_notes){
          setProcessing(true);
          handleUpdateModel()
          .then(() => setSuccess('Notes updated'))
          .catch((error) => setError(error))
          .finally(() => setProcessing(false))
        }
      }
    }
  };

  useEffect(() => {
    if (processingData) {
      const processingNotes = {
        description: jobDescription,
        internal_notes: internalNotes,
        dispatcher_notes: dispatcherNotes,
        invoice_notes: invoiceNotes
      };

      getNotesData(edit ? 
        (needToUpdate(notesData, processingNotes) && needToUpdateInvoicesNotes(notesData, processingNotes) ? 
          processingNotes : needToUpdate(notesData, processingNotes) ? _omit(processingNotes, ['invoice_notes']) :
          needToUpdateInvoicesNotes(notesData, processingNotes) ? _pick(processingNotes, ['invoice_notes']) : {}
        ) 
        : processingNotes
      );
    }
  }, [processingData, edit, notesData, jobDescription, internalNotes, dispatcherNotes, invoiceNotes, notes, getNotesData, onChangeProcessingData]);
  useEffect(() => {
    if (edit || duplicate) {
      if (isOrdersRoute || isInvoicesRoute || isVehiclesRoute) {
        setJobDescription(notesData?.description || description)
        setInternalNotes(notesData?.internal_notes || internal_notes);
        setDispatcherNotes(notesData?.dispatcher_notes || dispatcher_notes);
        setInvoiceNotes(notesData?.invoice_notes || invoice_notes);
      } else {
        setNotes(previous_notes);
      }
    }
  }, [edit, duplicate, isOrdersRoute, isInvoicesRoute, isVehiclesRoute, notesData, description, internal_notes, dispatcher_notes, invoice_notes, previous_notes]);
  useEffect(() => {
    if ((!edit && !duplicate) && towVehicleNotesData) {
      if (isOrdersRoute || isInvoicesRoute || isVehiclesRoute) {
        setJobDescription(towVehicleNotesData?.description || description)
        setInternalNotes(towVehicleNotesData?.internal_notes || internal_notes);
        setDispatcherNotes(towVehicleNotesData?.dispatcher_notes || dispatcher_notes);
        setInvoiceNotes(towVehicleNotesData?.invoice_notes || invoice_notes);
      } else {
        setNotes(previous_notes);
      }
    }
}, [edit, duplicate, isOrdersRoute, isInvoicesRoute, isVehiclesRoute, towVehicleNotesData, description, internal_notes, dispatcher_notes, invoice_notes, previous_notes]);

  if (hideTabs) {
    return (
      <Card size='small' sx={sx}>
        <CardHeader sx={{ gap: '4px' }}>
          <CardTitle>Notes</CardTitle>
        </CardHeader>
        <Field
          edit={isCreate}
          value={notes}
          disabled={disabled}
          onChange={({ target: { value }}) => setNotes(value)}
          onUpdate={handleUpdateNotes}
        />
      </Card>
    );
  }

  return (
    <Card sx={sx}>
      <CardHeader>
        <CardTitle>Notes</CardTitle>
      </CardHeader>
      <Tabs
        sx={{ minHeight: 32 }}
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
      >
        <StyledTab label='Job Description' disabled={processing} />
        <StyledTab label='Internal' disabled={processing} />
        <StyledTab label='Urgently' disabled={processing} />
        <StyledTab label='Dispatcher' disabled={processing} />
        <StyledTab label='Invoice' disabled={processing} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Field
          edit={isCreate}
          value={jobDescription}
          onChange={({ target: { value }}) => setJobDescription(value)}
          onUpdate={handleUpdateNotes}
          isEditable={false}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Field
          edit={isCreate}
          value={internalNotes}
          onChange={({ target: { value }}) => setInternalNotes(value)}
          onUpdate={handleUpdateNotes}
          isEditable={isEditable}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Box sx={{ maxHeight: '180px', overflowY: 'auto' }}>
          <Typography variant='body2' color='textSecondary'>{`Scheduled at: ${scheduled_at || '-'}`}</Typography>
          <Typography variant='body2' color='textSecondary'>{`Urgently partner name: ${urgently_partner_name || '-'}`}</Typography>
          <Typography variant='body2' color='textSecondary'>{`Urgently disablement reason: ${urgently_disablement_reason || '-'}`}</Typography>
          <Typography variant='body2' color='textSecondary'>{`Urgently callback: ${urgently_callback || '-'}`}</Typography>
        </Box> 
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Field
          edit={isCreate}
          value={dispatcherNotes}
          onChange={({ target: { value }}) => setDispatcherNotes(value)}
          onUpdate={handleUpdateNotes}
          isEditable={isEditable}
        />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <Typography variant='body2' color='textSecondary'>Visible to a customer</Typography>
        <Field
          edit={isCreate}
          value={invoiceNotes}
          onChange={({ target: { value }}) => setInvoiceNotes(value)}
          onUpdate={handleUpdateNotes}
          isEditable={isEditable}
        />
      </TabPanel>
    </Card>
  );
};

export default memo(NotesCard);
import { memo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import _map from 'lodash/map';
// Local files
import useApp from 'hooks/useApp';
import useAccounts from 'hooks/useAccounts';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useOrders from 'hooks/useOrders';
import useTrucks from 'hooks/useTrucks';
import useRates from 'hooks/useRates';
import useVariants from 'hooks/useVariants';
import useJobs from 'hooks/useJobs';
import useExpenses from 'hooks/useExpenses';
import useUrl from 'hooks/useUrl';
import useUsers from 'hooks/useUsers';
import useSessions from 'hooks/useSessions';
import useDocuments from 'hooks/useDocuments';
import useLocations from 'hooks/useLocations';
import useWidgets from 'hooks/useWidgets';
import {
  DELETE_ACCOUNT,
  DELETE_USER,
  DELETE_TRUCK,
  DELETE_ORDER,
  DELETE_SESSION,
  DELETE_RATE,
  DELETE_VARIANT,
  DELETE_JOB,
  DELETE_EXPENSE,
  DELETE_DOCUMENT,
  DELETE_LOCATION,
  DELETE_WIDGET,
  SUPPLEMENT_ORDER,
  START_ORDER_MOVEMENT,
  PICK_UP_ORDER,
  SERVICE_ORDER,
  DPOP_OFF_ORDER,
  COMPLETE_ORDER,
  RENEW_ORDER,
  REVERSE_STATUS
} from 'helpers/confirmations';
import { reasons, orderStatuses, sides } from 'helpers/constants';

const { ACCOUNT, DRIVER, MANAGER, ORDER, TRUCK, RATE, VARIANT, JOB, EXPENSE, DOCUMENT, LOCATION, WIDGET } = reasons;

const Confirmation = () => {
  const navigate = useNavigate();
  const { closeDrawer, startRemoving, stopRemoving, setAccountServiceData } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const {
    deleteOrder,
    updateOrder,
    supplementOrder,
    startOrderMovement,
    pickUpOrder,
    serviceOrder,
    dropOffOrder,
    completeOrder,
    reverseStatus,
    renewOrder,
    changeLocalOrderStatus
  } = useOrders();
  const { deleteAccount, clearLocalActiveAccount } = useAccounts();
  const { deleteTruck, clearLocalActiveTruck } = useTrucks();
  const { deleteRate } = useRates();
  const { deleteVariant } = useVariants();
  const { deleteJob, clearLocalActiveJob } = useJobs();
  const { deleteExpense } = useExpenses();
  const { isOrdersRoute, isInvoicesRoute, isVehiclesRoute } = useUrl();
  const { deleteUser, clearLocalActiveUser } = useUsers();
  const { getUserSessions, deleteSession } = useSessions();
  const { deleteDocument } = useDocuments();
  const { deleteLocation, clearLocalActiveLocation } = useLocations();
  const { deleteWidget } = useWidgets();
  const { open, confirmedAction: { id, type, yes }, reason } = useCustomSelector(state => state.dialogs.confirmation);
  const { pagination: { total_count, offset, count } } = useCustomSelector(state => state.sessions.user);
  const { id: user_id } = useCustomSelector(state => state.users.user);
  const invoice = useCustomSelector(state => state.invoices.invoice);
  const vehicle = useCustomSelector(state => state.vehicles.vehicle);
  const order = useCustomSelector(state => state.orders.order);
  const documents = useCustomSelector(state => state.documents.all.data);
  const order_id = invoice?.order?.id || vehicle?.order?.id || order.id;

  const handleRemoveAccount = useCallback(({ id, reason }) => {
    startRemoving()
    .then(() =>
      deleteAccount(id)
      .then(() =>
        setSuccess('Account successfully deleted')
        .then(() => closeDrawer(reason).then(() => clearLocalActiveAccount().then(() => navigate('/accounts'))))
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteAccount, clearLocalActiveAccount, setError, setSuccess, closeDrawer]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleRemoveUser = useCallback(({ id, reason }) => {
    startRemoving()
    .then(() =>
      deleteUser(id)
      .then(() =>
        setSuccess('User successfully deleted')
        .then(() => 
          closeDrawer(reason)
          .then(() => clearLocalActiveUser().then(() => navigate(reason === DRIVER ? '/company/drivers' : '/company/managers')))
        )
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteUser, clearLocalActiveUser, setError, setSuccess, closeDrawer]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleRemoveTruck = useCallback(({ id, reason }) => {
    startRemoving()
    .then(() =>
      deleteTruck(id)
      .then(() =>
        setSuccess('Truck successfully deleted')
        .then(() => 
          closeDrawer(reason)
          .then(() => clearLocalActiveTruck().then(() => navigate('/company/trucks')))
        )
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteTruck, clearLocalActiveTruck, setError, setSuccess, closeDrawer]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleRemoveOrder = useCallback(({ id, reason }) => {
    startRemoving()
    .then(() =>
      deleteOrder(id)
      .then(() =>
        setSuccess('Order successfully deleted')
        .then(() => 
          closeDrawer(reason)
          .then(() => navigate('/orders'))
        )
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteOrder, setError, setSuccess, closeDrawer]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleRemoveRate = useCallback((id) => {
    startRemoving()
    .then(() =>
      deleteRate(id)
      .then(() => setSuccess('Rate successfully deleted'))
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteRate, setError, setSuccess]);
  const handleRemoveVariant = useCallback((id) => {
    startRemoving()
    .then(() =>
      deleteVariant(id)
      .then(() => setSuccess('Variant successfully deleted'))
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteVariant, setError, setSuccess]);
  const handleRemoveJob = useCallback((id) => {
    startRemoving()
    .then(() =>
      deleteJob(id)
      .then(() => 
        clearLocalActiveJob()
        .then(() => setAccountServiceData({}).then(() => setSuccess('Service successfully deleted')))
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteJob, clearLocalActiveJob, setAccountServiceData, setError, setSuccess]);
  const handleRemoveExpense = useCallback((id) => {
    startRemoving()
    .then(() =>
      deleteExpense(id)
      .then(() => setSuccess('Expense  successfully deleted'))
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteExpense, setError, setSuccess]);
  const handleRemoveDocument = useCallback((id) => {
    // TODO вызывается почему-то дважды
    startRemoving()
    .then(() => {
      if (isOrdersRoute || isInvoicesRoute || isVehiclesRoute) {
        updateOrder({ 
          id: order_id, 
          side: sides.USER,
          documents_attributes: [{ id, _destroy: true }], 
          localDocuments: _map(documents, (d) => d.id === id ? { ...d, _destroy: true } : d) 
        })
        .then(() => setSuccess('Document successfully deleted'))
        .catch(e => setError(e))
        .finally(() => stopRemoving())
      } else {
        deleteDocument({ id, documents })
        .then(() => setSuccess('Document successfully deleted'))
        .catch(e => setError(e))
        .finally(() => stopRemoving());
      }
    });
  }, [order_id, documents, isOrdersRoute, isInvoicesRoute, startRemoving, stopRemoving, updateOrder, deleteDocument, setError, setSuccess]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleRemoveLocation = useCallback(({ id, reason }) => {
    startRemoving()
    .then(() =>
      deleteLocation(id)
      .then(() =>
        setSuccess('Location successfully deleted')
        .then(() => closeDrawer(reason).then(clearLocalActiveLocation))
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteLocation, clearLocalActiveLocation, closeDrawer, setError, setSuccess]);
  const handleRemoveWidget = useCallback((id) => {
    startRemoving()
    .then(() =>
      deleteWidget(id)
      .then(() => setSuccess('Widget successfully deleted'))
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, deleteWidget, setError, setSuccess]);
  const handleRemoveSession = useCallback(({ id }) => {
    deleteSession(id)
    .then(() => {
      if (total_count > count + offset) {
        getUserSessions({ offset: offset + 9, limit: 1, user_id })
        .catch((error) => setError(error));
      }
      setSuccess('Session successfully terminated');
    })
    .catch((error) => setError(error))
  }, [user_id, deleteSession, getUserSessions, setError, setSuccess]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleSupplementOrder = useCallback((order_id) => {
    startRemoving()
    .then(() =>
      supplementOrder(order_id)
      .then(() => setSuccess('Order successfully supplemented'))
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, supplementOrder, setError, setSuccess]);
  const handleStartOrderMovement = useCallback((order_id) => {
    startRemoving()
    .then(() =>
      startOrderMovement(order_id)
      .then((res) => {
        changeLocalOrderStatus({ order_id, status: orderStatuses.EN_ROUTE, previous_status: res.payload.response.data.order.previous_status })
        .then(() => setSuccess('Order movement successfully started'))
      })
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, startOrderMovement, changeLocalOrderStatus, setError, setSuccess]);
  const handlePickUpOrder = useCallback((order_id) => {
    startRemoving()
    .then(() =>
      pickUpOrder(order_id)
      .then((res) =>
        changeLocalOrderStatus({ order_id, status: orderStatuses.PICK_UP, previous_status: res.payload.response.data.order.previous_status })
        .then(() => setSuccess('Order pick up successfully started'))
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, pickUpOrder, changeLocalOrderStatus, setError, setSuccess]);
  const handleServiceOrder = useCallback((order_id) => {
    startRemoving()
    .then(() =>
      serviceOrder(order_id)
      .then((res) => {
        changeLocalOrderStatus({
          order_id,
          status: orderStatuses.SERVICING,
          previous_status: res.payload.response.data.order.previous_status
        })
          .then(() => setSuccess('Order servicing successfully started'))
      })
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, serviceOrder, changeLocalOrderStatus, setError, setSuccess]);
  const handleDropOffOrder = useCallback(order_id => {
    startRemoving()
    .then(() => {
      stopRemoving()
      dropOffOrder(order_id)
      .then((res) => {
        changeLocalOrderStatus({ order_id, status: orderStatuses.DROP_OFF, previous_status: res.payload.response.data.order.previous_status })
        .then(() => setSuccess('Order drop off successfully started'))
      })

      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    });
  }, [startRemoving, stopRemoving, dropOffOrder, changeLocalOrderStatus, setError, setSuccess]);
  const handleCompleteOrder = useCallback((order_id) => {
    startRemoving()
    .then(() =>
      completeOrder(order_id)
      .then((res) => {
        changeLocalOrderStatus({ order_id, status: orderStatuses.COMPLETED, previous_status: res.payload.response.data.order.previous_status })
        .then(() => setSuccess('Order successfully finished'))
      })
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, completeOrder, changeLocalOrderStatus, setError, setSuccess]);
  const handleReverseStatus = useCallback((order_id) => {
    startRemoving()
      .then(() =>
        reverseStatus(order_id)
          .then((res) => {
            const data = res.payload.response.data;
            changeLocalOrderStatus({ order_id, status: data.status, previous_status: data.previous_status })
              .then(() => setSuccess('Order successfully finished'))
          })
          .catch((error) => setError(error))
          .finally(() => stopRemoving())
      );
  }, [startRemoving, stopRemoving, completeOrder, changeLocalOrderStatus, setError, setSuccess]);
  const handleRenewOrder = useCallback((order_id) => {
    startRemoving()
    .then(() =>
      renewOrder(order_id)
      .then((res) =>
        changeLocalOrderStatus({ order_id, status: orderStatuses.NEW, previous_status: res.payload.response.data.order.previous_status })
        .then(() => setSuccess('Order successfully renewed'))
      )
      .catch((error) => setError(error))
      .finally(() => stopRemoving())
    );
  }, [startRemoving, stopRemoving, renewOrder, changeLocalOrderStatus, setError, setSuccess]);

  useEffect(() => {
    !open && type === DELETE_ACCOUNT && reason === ACCOUNT && yes && handleRemoveAccount({ id, reason });
    !open && type === DELETE_USER && (reason === DRIVER || reason === MANAGER) && yes && handleRemoveUser({ id, reason });
    !open && type === DELETE_TRUCK && reason === TRUCK && yes && handleRemoveTruck({ id, reason });
    !open && type === DELETE_ORDER && reason === ORDER && yes && handleRemoveOrder({ id, reason });
    !open && type === DELETE_SESSION && yes && handleRemoveSession({ id });
    !open && type === DELETE_RATE && reason === RATE && yes && handleRemoveRate(id);
    !open && type === DELETE_VARIANT && reason === VARIANT && yes && handleRemoveVariant(id);
    !open && type === DELETE_JOB && reason === JOB && yes && handleRemoveJob(id);
    !open && type === DELETE_EXPENSE && reason === EXPENSE && yes && handleRemoveExpense(id);
    !open && type === DELETE_DOCUMENT && reason === DOCUMENT && yes && handleRemoveDocument(id);
    !open && type === DELETE_LOCATION && reason === LOCATION && yes && handleRemoveLocation({ id, reason });
    !open && type === DELETE_WIDGET && reason === WIDGET && yes && handleRemoveWidget(id)
    !open && type === SUPPLEMENT_ORDER && reason === ORDER && yes && handleSupplementOrder(id);
    !open && type === START_ORDER_MOVEMENT && reason === ORDER && yes && handleStartOrderMovement(id);
    !open && type === PICK_UP_ORDER && reason === ORDER && yes && handlePickUpOrder(id);
    !open && type === SERVICE_ORDER && reason === ORDER && yes && handleServiceOrder(id);
    !open && type === DPOP_OFF_ORDER && reason === ORDER && yes && handleDropOffOrder(id);
    !open && type === COMPLETE_ORDER && reason === ORDER && yes && handleCompleteOrder(id);
    !open && type === REVERSE_STATUS && reason === ORDER && yes && handleReverseStatus(id);
    !open && type === RENEW_ORDER && reason === ORDER && yes && handleRenewOrder(id);
  }, [
    open,
    yes,
    id,
    type,
    reason,
    handleRemoveAccount,
    handleRemoveUser,
    handleRemoveTruck,
    handleRemoveOrder,
    handleRemoveSession,
    handleRemoveRate,
    handleRemoveVariant,
    handleRemoveJob,
    handleRemoveExpense,
    handleRemoveDocument,
    handleRemoveLocation,
    handleRemoveWidget,
    handleSupplementOrder,
    handleStartOrderMovement,
    handlePickUpOrder,
    handleServiceOrder,
    handleDropOffOrder,
    handleCompleteOrder,
    handleReverseStatus,
    handleRenewOrder
  ]);

  return null;
};

export default memo(Confirmation);
import { memo } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
// Local files
import { Root, StyledFormControl } from './Filters.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const MapFilter = ({ filtersRef, filters, onChange }) => {
  return (
    <Root ref={filtersRef} style={{ zIndex: 20 }}>
      <FormGroup>
        <StyledFormControl
          control={<Switch checked={filters.showTraffic} onChange={() => onChange({ showTraffic: !filters.showTraffic })} />}
          labelPlacement="start"
          label="Display Traffic"
        />
        <StyledFormControl
          control={<Switch checked={filters.autoZoom} onChange={() => onChange({ autoZoom: !filters.autoZoom })} />}
          labelPlacement="start"
          label="Auto-Zoom on Clicked Driver"
        />
        <StyledFormControl
          control={<Switch checked={filters.showNew} onChange={() => onChange({ showNew: !filters.showNew, ...filters.showActive && !filters.showNew && { showActive: false } })} />}
          labelPlacement="start"
          label="Show New Orders Only"
        />
        <StyledFormControl
          control={<Switch checked={filters.showActive} onChange={() => onChange({ showActive: !filters.showActive, ...filters.showNew && !filters.showActive && { showNew: false } })} />}
          labelPlacement="start"
          label="Show Active Orders"
        />
      </FormGroup>
    </Root>
  );
};

MapFilter.propTypes = {
  filersRef: PropTypes.func,
  filters: PropTypes.shape({
    showTraffic: PropTypes.bool.isRequired,
    autoZoom: PropTypes.bool.isRequired,
    showNew: PropTypes.bool.isRequired,
    showActive: PropTypes.bool.isRequired
  }),
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <MapFilter {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
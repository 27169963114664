import { memo } from 'react';
import { Navigate } from 'react-router-dom';
// Local files
import useRole from 'hooks/useRole';
import useSession from 'hooks/useSession';

const CheckSession = ({ children }) => {
  const { authenticated, checked } = useSession();
  const { isDriver, isManager, isOwner } = useRole();
  const redirectUrl = isDriver ? '/orders' : '/dashboard';

  if (checked) {
    return !authenticated
      ? children
      : (isDriver || isManager || isOwner ? <Navigate to={redirectUrl} replace /> : null);
  }
  return null;
};

export default memo(CheckSession);
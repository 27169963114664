import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import { checkEmptyString } from 'helpers';
import { cancelUrgentlyReasons, orderStatuses } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useOrders from 'hooks/useOrders';
import useDialogs from 'hooks/useDialogs';
import useOrderSources from 'hooks/useOrderSources';

const CancelOrder = (props) => {
  const { closeCancelOrderDialog } = useDialogs();
  const { cancelOrder, changeLocalOrderStatus } = useOrders();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { id, open, source } = useCustomSelector(state => state.dialogs.cancelOrder);
  const { isUrgently } = useOrderSources(source);
  const [reason, setReason] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);

  const handleSubmitClick = () => {
    let hasError = false;

    if (isUrgently && !checkEmptyString(reason.value)) {
      setReason(prev => ({ ...prev, error: 'Reason can\'t be empty' }));
      hasError = true;
    }
    if (hasError) return;

    setProcessing(true);
    cancelOrder({ id, ...(isUrgently && { reason: reason.value }) })
    .then(() => 
    changeLocalOrderStatus({ id, status: orderStatuses.CANCELED })
    .then(() => {
      closeCancelOrderDialog();
      setReason({ value: '', error: '' });
      setSuccess('Order successfully canceled')
    })
    )
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleClose = () => {
    setReason({ value: '', error: '' });
    setProcessing(false);
    closeCancelOrderDialog();
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
      {...props}
    >
      <DialogContent>
        <Typography variant='subtitle1' align='center' p='16px 0px 8px'>Are you sure you want to cancel the order?</Typography>
        {isUrgently && <Box>
          <FormControl variant="standard" fullWidth error={!!reason.error}>
            <InputLabel id="description-label">Reason</InputLabel>
            <Select
              labelId="description-label"
              id="reason"
              value={reason.value}
              onChange={({ target: { value } }) => setReason({ value, error: '' })}
              label="Reason"
            >
              {_map(cancelUrgentlyReasons, ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
            </Select>
            {!!reason.error && <FormHelperText error>{reason.error}</FormHelperText>}
          </FormControl>
        </Box>}
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleClose}
        >
          No
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleSubmitClick}
        >
          Yes
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(CancelOrder);
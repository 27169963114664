import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  flexGrow: 1,
  width: '100%',
  overflow: 'auto',
});

export const Item = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '2px 8px 8px 0',
});

import {
  getVehiclesRoutine,
  getVehicleRoutine,
  createVehicleRoutine,
  updateVehicleRoutine,
  releaseVehicleRoutine,
  takeOutVehicleRoutine,
  clearLocalVehiclesRoutine,
  clearLocalActiveVehicleRoutine,
  getVehiclesStatisticsRoutine,
  clearVehiclesStatisticsRoutine,
  suggestInvoiceExpensesRoutine,
  filterExpencesDataRoutine,
  createExpenseRoutine,
  updateExpenseRoutine,
  deleteExpenseRoutine,
  getInvoiceRoutine,
  updateInvoiceRoutine,
  createPaymentRoutine,
  createCardRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _startsWith from 'lodash/startsWith';
import { getRandomString, getExpenseTotal } from 'helpers';
import { invoiceStatuses } from 'helpers/constants';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  statistics: {
    stored_vehicles_count: 0,
    laid_vehicles_count: 0,
    released_vehicles_count: 0,
    over_time_vehicles_count: 0,
    over_time_vehicles_data: [],
    stored_vehicles_invoice_amount: 0
  },
  vehicle: {
    id: '',
    vin: '',
    make: '',
    model: '',
    year: '',
    color: '',
    license: '',
    license_state: '',
    storage_duration: 0,
    stored_at: '',
    created_at: '',
    order: {
      id: '',
      account: null,
      customer: null,
      vehicle: null,
      company: null,
      dispatcher: null,
      user: null,
      invoice: { id: '', number: '', status: '', expenses: [], payments: [], file: null, total_amount: 0, paid_amount: 0, minimum_amount: 0 },
      number: '',
      notes: '',
      status: '',
      incident_location: null,
      destination_location: null,
      eta: 0,
      priority: '',
      urgency_level: '',
      service_comments: '',
      equipment_type: '',
      authorization_number: '',
      services: [],
      truck: null
    },
    receiver: {
      id: '',
      receiver_type:'',
      name: '',
      email: '',
      phone: '',
      address:'',
      license_state: '',
      driver_license:'',
    }
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getVehiclesRoutine.SUCCESS: {
      const { data: { vehicles, pagination } } = action.payload;

      return { ...state, all: { data:[...state.all.data, ...vehicles], pagination } };
    }
    case getVehicleRoutine.SUCCESS: {
      const { data: { vehicle } } = action.payload;

      return { ...state, vehicle };
    }
    case createVehicleRoutine.SUCCESS: {
      const { data: { vehicle } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[vehicle], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateVehicleRoutine.SUCCESS: {
      const { response: { status }, vehicle } = action.payload;

      return { ...state, ...(status === 204 && state.vehicle?.id === vehicle.id && { vehicle: { ...state.vehicle, ...vehicle } }) };
    }
    case releaseVehicleRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case takeOutVehicleRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case clearLocalVehiclesRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveVehicleRoutine.SUCCESS: {
      return { ...state, vehicle: initialState.vehicle }
    }
    case getVehiclesStatisticsRoutine.SUCCESS: {
      const { data: { statistics } } = action.payload;

      return { ...state, statistics };
    }
    case clearVehiclesStatisticsRoutine.SUCCESS: {
      return { ...state, statistics: initialState.statistics }
    }
    case suggestInvoiceExpensesRoutine.SUCCESS: {
      const { response: { data: { expenses } }, expense_name } = action.payload;
      const data = !!expenses.length ? _map(expenses, (expense) => ({ ...expense, id: `new_${expense?.rate?.id || expense?.default_charge?.id}` })) :
      [{ id: `new_${getRandomString(24)}`, rate: null, default_charge: null, name: expense_name, unit_price: null, quantity: 1, hourly_charge_duration: 'PT0H0M' }];

      return { ...state, ...(!!state.vehicle.id && { vehicle: { ...state.vehicle, order: { ...state.vehicle.order, invoice: { ...state.vehicle.order.invoice, expenses: [...state.vehicle.order.invoice?.expenses, ...data] } } } }) }
    }
    case filterExpencesDataRoutine.SUCCESS: {
      const { expense_id } = action.payload;
      const foundedExpense = !!_find(state.vehicle.order.invoice?.expenses, (expense) => expense?.id === expense_id);
      const expenses = _filter(state.vehicle.order.invoice?.expenses, (expense) => expense?.id !== expense_id);

      return { ...state, ...(foundedExpense && { vehicle: { ...state.vehicle, order: { ...state.vehicle.order, invoice: { ...state.vehicle.order.invoice, expenses } } } }) }
    }
    case createExpenseRoutine.SUCCESS: {
      const { response: { data: { expense } }, invoice_id } = action.payload;

      return { ...state, 
        ...(state.vehicle.order.invoice?.id === invoice_id && { 
          vehicle: { 
            ...state.vehicle,
            order: { 
              ...state.vehicle.order, 
              invoice: { 
                ...state.vehicle.order.invoice, 
                expenses: [...state.vehicle.order.invoice?.expenses, ...[expense]],
                ...(state.vehicle.order.invoice.minimum_amount < (state.vehicle.order.invoice.total_amount + getExpenseTotal(expense)) && { total_amount: state.vehicle.order.invoice.total_amount + getExpenseTotal(expense) })
              } 
            } 
          }
        })
      }
    }
    case updateExpenseRoutine.SUCCESS: {
      const { response: { status }, expense } = action.payload;

      return { ...state, ...(status === 204 && !!_find(state.vehicle.order.invoice.expenses, (e) => e.id === expense.id) && { 
        vehicle: { 
          ...state.vehicle, 
          order: { 
            ...state.vehicle.order,
            invoice: { ...state.vehicle.order.invoice, expenses: _map(state.vehicle.order.invoice.expenses, (e) => e.id === expense.id ? { ...e, ...expense } : e) } 
          } 
        } 
      })};
    }
    case deleteExpenseRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && _find(state.vehicle.order.invoice?.expenses, (expense) => expense?.id === id) && { 
          vehicle: {
            ...state.vehicle,
            order: { 
              ...state.vehicle.order, 
              invoice: { ...state.vehicle.order.invoice, expenses: _filter(state.vehicle.order.invoice?.expenses, (expense) => expense?.id !== id) } 
            }
        }})
      }
    }
    case getInvoiceRoutine.SUCCESS: {
      const { data: { invoice } } = action.payload;
      const filteredInvoice = { 
        ...invoice, 
        expenses: !!_find(state.vehicle.order.invoice.expenses, (e) => _startsWith(e.id, 'new_')) ? state.vehicle.order.invoice.expenses : invoice.expenses 
      };

      return { ...state, ...(state.vehicle.order.invoice?.id === invoice.id && 
        { vehicle: { ...state.vehicle, order: { ...state.vehicle.order, invoice: { ...state.vehicle.order.invoice,  ...filteredInvoice } } } }) };
    }
    case updateInvoiceRoutine.SUCCESS: {
      const { response: { status }, invoice } = action.payload;

      return { ...state, 
        ...(status === 204 && state.vehicle.order.invoice.id === invoice.id && { 
          vehicle: {
            ...state.vehicle,
            order: { ...state.vehicle.order, invoice: { ...state.vehicle.order.invoice, ...invoice } } 
          } 
        }) 
      };
    }
    case createPaymentRoutine.SUCCESS: {
      const { response: { data: { payment } }, invoice_id } = action.payload;

      return { ...state, 
        ...(state.vehicle.order.invoice?.id === invoice_id && { 
          vehicle: {
            ...state.vehicle,
            order: { 
              ...state.vehicle.order, 
              invoice: { 
                ...state.vehicle.order.invoice, 
                ...(state.vehicle.order.invoice.status === invoiceStatuses.APPROVED && { status: invoiceStatuses.PARTIALLY_PAID }),
                ...(state.vehicle.order.invoice.total_amount <= state.vehicle.order.invoice.paid_amount + payment.amount && { status: invoiceStatuses.PAID }),
                payments: [...state.vehicle.order.invoice?.payments, ...[payment]],
                paid_amount: state.vehicle.order.invoice.paid_amount + payment.amount
              } 
            } 
        }})
      }
    }
    case createCardRoutine.SUCCESS: {
      const { response: { data: { card } }, customer_id } = action.payload;

      return { ...state, 
        ...(state.vehicle.order.customer?.id === customer_id && { 
          vehicle: {
            ...state.vehicle,
            order: { 
              ...state.vehicle.order, 
              customer: { 
                ...state.vehicle.order.customer,
                cards: [...[card], ...state.vehicle.order.customer?.cards]
              } 
            } 
          }
        })
      }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
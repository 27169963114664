import { memo } from 'react';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import BaseStatus from 'components/Common/BaseStatus/BaseStatus';
import { orderStatuses } from 'helpers/constants';

const Status = ({ value, size, darkBackground, customer = false }) => {
  const color = {
    available: 'success',
    new: 'success', 
    scheduled: 'success', 
    'manager_accepted': 'warning', 
    'company_accepted': 'success', 
    canceled: 'error', 
    expired: 'warning', 
    'en_route': 'warning', 
    'pick_up': 'warning', 
    servicing: 'warning', 
    'drop_off': 'warning', 
    completed: 'primary', 
    rejected: 'error', 
    stored: 'primary', 
    laid: 'warning', 
    'for_sale': 'error', 
    released: 'success', 
    'no_job': 'success'
  };
  if (!value) return null;
  return (
    <BaseStatus
      size={size}
      color={color[value]}
      darkBackground={darkBackground}
      label={customer ? 'Just Received' : (value === 'no_job' ? _startCase('no_job') : _startCase(orderStatuses[value.toUpperCase()]))}
    />
  );
};

Status.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  darkBackground: PropTypes.bool
};

export default memo(Status);
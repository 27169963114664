import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import _map from 'lodash/map';
import _intersectionBy from 'lodash/intersectionBy';
// Local files
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import { checkEmptyString } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useOrders from 'hooks/useOrders';
import useDialogs from 'hooks/useDialogs';
import useMap from 'hooks/useMap';
import { sides, orderStatuses } from 'helpers/constants';

const Assign = (props) => {
  const { openMapDialog, closeAssignDialog, closeMapDialog } = useDialogs();
  const { supplementOrder, updateOrder } = useOrders();
  const { setMapConfigs } = useMap();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open, order, isISSC, isUrgently } = useCustomSelector(state => state.dialogs.assign);
  const [truck, setTruck] = useState({ value: '', error: '' });
  const [eta, setEta] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);
  const handleEntering = () => setEta({ value: isUrgently ? order?.eta ?? 10 : !!order?.eta ? (order?.eta > 60 ? 60 : order?.eta) : 60, error: '' });
  const handleExited = () => {
    setEta({ value: '', error: '' });
    setTruck({ value: '', error: '' });
  }
  const handleAssignClick = () => {
    let hasError = false;

    if ((isISSC || isUrgently) && typeof eta.value === 'string' && !checkEmptyString(eta.value)) {
      setEta(prev => ({ ...prev, error: 'Eta can\'t be empty' }));
      hasError = true;
    }
    if (isUrgently && (eta.value < 10 || eta.value > 180)) {
      setEta(prev => ({ ...prev, error: 'Eta must be no less than 10 and no more than 180' }));
      hasError = true;
    }
    if (isUrgently && !checkEmptyString(truck.value)) {
      setTruck(prev => ({ ...prev, error: 'Truck can\'t be empty' }));
      hasError = true;
    }
    if (hasError) return;

    setProcessing(true);
    ((isISSC || isUrgently) && order?.status === orderStatuses.NEW ? supplementOrder({ id: order?.id, user_id: order?.driver?.id || null, user: order?.driver || null, eta: eta.value, ...(isUrgently && {truck_id: truck.value}) }) : 
    updateOrder({ 
      id: order?.id, 
      side: sides.USER, 
      user_id: order?.driver?.id, 
      localUser: order?.driver, 
      eta: eta.value,
      ...(!!order?.driver?.trucks?.length && { truck_id: order?.driver?.trucks[0]?.id }) 
    }))
    .then(() => setSuccess(`${order?.driver?.id ? 'Driver' : 'Order'} successfully ${order?.driver?.id ? 'assigned' : 'accepted'}`).then(() => closeAssignDialog().then(closeMapDialog)))
    .catch(error => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleMapDialog = () => 
    closeAssignDialog()
    .then(() => 
      setMapConfigs({ localIncident: { value: order?.incident_location?.address, latitude: order?.incident_location?.latitude, longitude: order?.incident_location?.longitude } })
      .then(() => openMapDialog({ order: { id: order?.id, eta: eta.value, status: order?.status, trucks: order?.trucks, users: order?.users }, isISSC, isUrgently }))
    );

  return (
    <BaseDialog
      open={open}
      onClose={closeAssignDialog}
      PaperProps={{ sx: { width: 360,  borderRadius: '16px' } }}
      title={(isISSC || isUrgently) && order?.status === orderStatuses.NEW ? 'Accept order' : 'Assign Driver'}
      TransitionProps={{ onEntering: handleEntering, onExited: handleExited }}
      {...props}
    >
      <DialogContent>
        <Box>
          <TextField
            fullWidth
            margin='dense'
            label='Eta (minutes)'
            value={eta.value}
            error={!!eta.error}
            helperText={eta.error}
            type='number'
            inputProps={{ min: isUrgently ? 10 : 1,  ...(isUrgently ? {max: 180} : !!order?.eta && { max: order?.eta}) }}
            onChange={({ target: { value } }) => setEta({ value, error: !!order?.eta && (value < 1 || value > order?.eta) ? 'Eta is invalid' : '' })}
          />
          <TextField 
            id='driver'
            fullWidth
            select
            margin='dense'
            label='Select a driver'
            value={`${order?.driver?.first_name || ''} ${order?.driver?.last_name || ''}`}
            SelectProps={{ open: false, onOpen: handleMapDialog }}
          >
            <MenuItem value={`${order?.driver?.first_name} ${order?.driver?.last_name}`}>{`${order?.driver?.first_name} ${order?.driver?.last_name}`}</MenuItem>
          </TextField>
          {isUrgently && <TextField 
            id='truck'
            fullWidth
            select
            margin='dense'
            label='Select a truck'
            value={truck.value}
            onChange={({ target: { value } }) => setTruck({ value, error: '' })}
            error={!!truck.error}
            helperText={truck.error}
          >
            {_map(_intersectionBy(order?.trucks, order?.driver?.trucks, 'id'), ({ id, make, model }) => <MenuItem key={id} value={id}>{`${make} ${model}`}</MenuItem>)}
          </TextField>}
        </Box>
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={closeAssignDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleAssignClick}
        >
          {(isISSC || isUrgently) && order?.status === orderStatuses.NEW ? 'Accept' : 'Assign'}
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(Assign);
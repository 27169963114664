import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

export const Header = styled(props => (
  <Box component={NavLink} {...props} />
))(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  }
}));

export const Chips = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  paddingTop: '6px'
}));

export const Route = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '16px 0'
}));

export const Group = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

export const IconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: 16,
  height: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.text.primary,
  borderRadius: '50%',
  '& svg': {
    width: 10,
    height: 10,
  }
}));

export const AddressDivider = styled(Box)(() => ({
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  '&::after': {
    content: '""',
    display: 'block',
    width: 0,
    height: 16,
    borderRight: '1px dashed #C4C4C4',
    marginLeft: '-1px',
  }
}));

export const Footer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '4px',
  padding: '16px 0',
});
import styled from '@mui/material/styles/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  maxHeight: 220,
  position: 'absolute',
  bottom: 24,
  right: 50,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  [theme.breakpoints.up('md')]: {
    bottom: 74,
    right: 50,
  }
}));

export const StyledFormControl = styled(FormControlLabel)(({ theme }) => ({
  width: 'auto',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 12px',
  margin: 0,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiFormControlLabel-label': {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  [theme.breakpoints.up('md')]: {
    width: '270px',
  }
}));
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  position: 'relative',
});

export const Content = styled(Box)(({ theme }) => ({
  minHeight: 200,
  display: 'flex',
  alignItems: 'align',
  justifyContent: 'space-between',
  gap: '16px',
  background: '#fff',
  boxShadow: '0px -1px 0px 0px  #F1F0FC inset',
  padding: '24px 24px 40px',
  [theme.breakpoints.up('xl')]: {
    justifyContent: 'space-evenly',
  }
}));

export const StyledButton = styled(IconButton,
  { shouldForwardProp: (prop) => prop !== 'expanded' }
)(({ theme, expanded }) => ({
  zIndex: 1,
  background: `${theme.palette.background.paper} !important`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 4px 20px 0px #00000030',
  position: 'absolute',
  transition: '.25s ease-in-out',
  ...(expanded ? {
    width: 44,
    height: 44,
    top: '140px',
    left: 'calc(50% - 22px)',
  } : {
    width: 30,
    height: 30,
    top: '-12px',
    left: 'calc(50% - 15px)',
  })
}));
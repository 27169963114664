import { memo, useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// Local files
import Avatar from 'components/Users/Avatar/Avatar';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import BaseDatePicker from 'components/Common/BaseDatePicker/BaseDatePicker';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';
import useProfile from 'hooks/useProfile';
import { getRepresentation } from 'helpers';

const Personal = () => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { updateProfile } = useProfile();
  const { user } = useCustomSelector(state => state.profile);
  const [photo, setPhoto] = useState({ value: '', formattedValue: '' });
  const [birthAt, setBirthAt] = useState({ value: '', formattedValue: null, error: '' });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [secondPhone, setSecondPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [emergencyPhone, setEmergencyPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [secondEmergencyPhone, setSecondEmergencyPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [processing, setProcessing] = useState(false);

  const setFields = useCallback(() => {
    setProcessing(true);
    !!user.photo && setPhoto({ value: user.photo.id, formattedValue: user.photo?.localPhoto || getRepresentation(user?.photo?.representations, 'tiny') });
    !!user.phone && setPhone({ value: user.phone, formattedValue: user.phone, error: '' });
    !!user.second_phone && setSecondPhone({ value: user.phone, formattedValue: user.second_phone, error: '' });
    !!user.emergency_phone && setEmergencyPhone({ value: user.phone, formattedValue: user.emergency_phone, error: '' });
    !!user.second_emergency_phone && setSecondEmergencyPhone({ value: user.phone, formattedValue: user.second_emergency_phone, error: '' });
    !!user.birth_at && setBirthAt({ value: user.birth_at, formattedValue: new Date(user.birth_at), error: '' });
    setProcessing(false);
  }, [user])
  const clearFields = () => {
    setPhoto({ value: '', formattedValue: '' });
    setPhone({ value: '', formattedValue: '', error: '' });
    setSecondPhone({ value: '', formattedValue: '', error: '' });
    setEmergencyPhone({ value: '', formattedValue: '', error: '' });
    setSecondEmergencyPhone({ value: '', formattedValue: '', error: '' });
    setBirthAt({ value: '', formattedValue: null, error: '' });
    setProcessing(false);
  }

  useEffect(() => {
    setFields();

    return clearFields;
  }, [setFields]);

  const handleSave = () => {
    let hasError = false;

    if (!!phone.error) hasError = true;
    if (!!secondPhone.error) hasError = true;
    if (!!emergencyPhone.error) hasError = true;
    if (!!secondEmergencyPhone.error) hasError = true;
    if (!!birthAt.error) hasError = true;
    if (hasError) return;

    const processableUser = {
      ...(!!photo.value ? {
        photo: photo.value,
        localPhoto: photo.formattedValue
      } : { photo: null }),
      ...(!!phone.value && { phone: phone.value }),
      ...(!!secondPhone.value && { second_phone: secondPhone.value }),
      ...(!!emergencyPhone.value && { emergency_phone: emergencyPhone.value }),
      ...(!!secondEmergencyPhone.value && { second_emergency_phone: secondEmergencyPhone.value }),
      ...(!!birthAt.value && { birth_at: birthAt.value })
    };

    setProcessing(true);
    if (needToUpdate(user, processableUser)) {
      updateProfile(processableUser)
      .then(() => setSuccess('User successfully updated'))
      .catch(error => setError(error))
      .finally(() => setProcessing(false));
    } else {
      setProcessing(false);
      setSuccess(`There's nothing to change`);
    }
  };
  const needToUpdate = (a, b) => {
    return (!!b.phone && a.phone !== b.phone)
      || (!!b.second_phone && a.second_phone !== b.second_phone)
      || (!!b.emergency_phone && a.emergency_phone !== b.emergency_phone)
      || (!!b.second_emergency_phone && a.second_emergency_phone !== b.second_emergency_phone)
      || (a.photo?.id !== b.photo)
  };

  return (
    <>
      <Avatar
        edit
        src={photo.formattedValue}
        firstName={user.first_name}
        lastName={user.last_name}
        disabled={processing}
        onChange={({ value, formattedValue }) => setPhoto(prev => ({ ...prev, value, formattedValue }))}
      />
      <Box maxWidth={{ xs: 'unset', md: '300px' }} mb={4}>
        <BaseDatePicker
          fullWidth
          margin='dense'
          label='Birth Date'
          maxDate={new Date()}
          value={birthAt.formattedValue}
          error={!!birthAt.error}
          helperText={birthAt.error}
          onChange={({ value, formattedValue, error }) => setBirthAt({ value, formattedValue, error })}
        />
      </Box>
      <Typography variant='title'>Contacts</Typography>
      <Box
        mt={1} mb={4}
        maxWidth={{ xs: 'unset', md: '616px' }}
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
      >
        <PhoneField
          fullWidth
          sx={{ maxWidth: {md: '300px'} }}
          margin='dense'
          label='Phone'
          formattedValue={phone.formattedValue}
          error={!!phone.error}
          helperText={phone.error}
          onChange={({ value, formattedValue, error }) => setPhone({ value, formattedValue, error })}
        />
        <PhoneField
          fullWidth
          sx={{ maxWidth: {md: '300px'} }}
          margin='dense'
          label='Phone #2'
          formattedValue={secondPhone.formattedValue}
          error={!!secondPhone.error}
          helperText={secondPhone.error}
          onChange={({ value, formattedValue, error }) => setSecondPhone({ value, formattedValue, error })}
        />
        <PhoneField
          fullWidth
          sx={{ maxWidth: {md: '300px'} }}
          margin='dense'
          label='Emergency Phone'
          formattedValue={emergencyPhone.formattedValue}
          error={!!emergencyPhone.error}
          helperText={emergencyPhone.error}
          onChange={({ value, formattedValue, error }) => setEmergencyPhone({ value, formattedValue, error })}
        />
        <PhoneField
          fullWidth
          sx={{ maxWidth: {md: '300px'} }}
          margin='dense'
          label='Emergency Phone #2'
          formattedValue={secondEmergencyPhone.formattedValue}
          error={!!secondEmergencyPhone.error}
          helperText={secondEmergencyPhone.error}
          onChange={({ value, formattedValue, error }) => setSecondEmergencyPhone({ value, formattedValue, error })}
        />
      </Box>
      <Box p={{ xs: '24px 0', md: '24px 0', width: '100%' }} maxWidth={{ xs: 'unset', md: '300px' }}>
        <Button fullWidth color='primary' variant='contained' size='xlarge' disabled={processing} onClick={handleSave}>Save</Button>
      </Box>
    </>
  );
};

export default memo(Personal);
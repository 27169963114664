import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from './BaseAccordion.styled';

const BaseAccordion = ({ title, expanded, content, ...otherProps }) => {
  return (
    <StyledAccordion expanded={expanded} {...otherProps} >
      <StyledAccordionSummary>{title}</StyledAccordionSummary>
      <StyledAccordionDetails>{content}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

BaseAccordion.propTypes = {
  expanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired
};

export default memo(BaseAccordion);
import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Duration } from './RemainingTime.styled';
import { parseIsoDurationInMiliseconds, getFormatedHoursMinutes } from 'helpers';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const formatRemainingTime = endDate => {
  const currentDate = new Date();
  const differenceInMilliseconds = endDate - currentDate;
  const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

  if (differenceInMinutes <= 0) {
    return 'Time is up!';
  } else if (differenceInMinutes >= 60) {
    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;
    
    return `${hours} hr${hours > 1 ? 's' : ''} and ${minutes} min${minutes > 1 ? 's' : ''}`;
  } else {
    return `${Math.floor(differenceInMinutes)} min${differenceInMinutes > 1 ? 's' : ''}`;
  }
};

const RemainingTime = ({ startDateIso = null, durationIso = null }) => {
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const startDate = new Date(startDateIso);
    const durationMilliseconds = parseIsoDurationInMiliseconds(durationIso ?? 'PT0H');
    const endDate = new Date(startDate.getTime() + durationMilliseconds);

    const updateRemainingTime = () => {
      setRemainingTime(formatRemainingTime(endDate));
    };

    const currentDate = new Date();
    const secondsUntilNextMinute = 60 - currentDate.getSeconds() - 12;

    const initialTimeout = setTimeout(() => {
      updateRemainingTime(); // Обновляем сразу в начале следующей минуты
      // Устанавливаем интервал для обновления каждую минуту
      const intervalId = setInterval(updateRemainingTime, 60000);
      // Очистка интервала при размонтировании компонента
      return () => clearInterval(intervalId);
    }, secondsUntilNextMinute * 1000);

    updateRemainingTime();
    
    return () => clearTimeout(initialTimeout);
  }, [startDateIso, durationIso]);

  if (!startDateIso || !durationIso) return null;
  return <Duration>{getFormatedHoursMinutes(durationIso).hours}h Shift Ending at {remainingTime}</Duration>;
};

RemainingTime.propTypes = {
  startDateIso: PropTypes.string,
  durationIso: PropTypes.string
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <RemainingTime {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
import { memo, useMemo, useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _startCase from 'lodash/startCase';
import _includes from 'lodash/includes';
//Local files
import { FieldsContainer } from 'components/Common/BasePageUi/BasePageUi';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useRequests from 'hooks/useRequests';
import useCustomSelector from 'hooks/useCustomSelector';

const Form = ({ id, processing, disabled, onProcessing }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createRequest } = useRequests();
  const { channelMessages, requestTypes, additionalServiceNames } = useCustomSelector(state => ({
    channelMessages: state.channelMessages.all.data,
    requestTypes: state.channelMessages.requestTypes,
    additionalServiceNames: state.channelMessages.additionalServiceNames
  }));
  const [requestType, setRequestType] = useState({ value: 'AdditionalService', error: '' });
  const [serviceName, setServiceName] = useState({ value: '', error: '' });
  const [reason, setReason] = useState({ value: '', error: '' });
  const [quantity, setQuantity] = useState({ value: 1, error: '' });
  const [amount, setAmount] = useState({ value: 0, error: '' });

  const isShowServiceName = useMemo(() => requestType.value === 'AdditionalService', [requestType.value]);
  const isEtaRequestType = useMemo(() => requestType.value === 'ExtendETA', [requestType.value]);
  const isShowParameters = useMemo(() => requestType.value === 'AdditionalService' || isEtaRequestType, [requestType.value, isEtaRequestType]);
  const isQuantityInMinutes = useMemo(() => 
    serviceName.value === 'Access' || 
    serviceName.value === 'WaitTime' || 
    serviceName.value === 'GlassCleanUp' ||
    serviceName.value === 'Labor' ||
    serviceName.value === 'PushPullOut' ||
    serviceName.value === 'SceneCleanUp' ||
    serviceName.value === 'Winching'
  , [serviceName.value]);
  const isShowQuantity = useMemo(() => 
    isShowParameters && 
    serviceName.value !== 'AdditionalFuelToCustomer' && 
    serviceName.value !== 'Tolls' &&
    !_includes(_startCase(serviceName.value), 'fee') &&
    serviceName.value !== 'SpeedyDryFluidCleanup' &&
    serviceName.value !== 'Flat' &&
    serviceName.value !== 'Fuel' &&
    serviceName.value !== 'Fump' &&
    serviceName.value !== 'LockOut' &&
    serviceName.value !== 'LockSmith' &&
    serviceName.value !== 'Maintenance' &&
    serviceName.value !== 'Tow'
  , [isShowParameters, serviceName.value]);
  const isShowAmount = useMemo(() => 
    serviceName.value === 'OtherSpecialEquipment' || 
    serviceName.value === 'Access' || 
    serviceName.value === 'AdditionalFuelToCustomer' ||
    serviceName.value === 'Tolls' ||
    _includes(_startCase(serviceName.value), 'fee') ||
    serviceName.value === 'ExtraPerson' ||
    serviceName.value === 'PushPullOut' ||
    serviceName.value === 'SpeedyDryFluidCleanup'
  , [serviceName.value]);
  const channel = useMemo(() => _find(channelMessages, (c) => _startCase(c.request_type) === _startCase(requestType.value) && _startCase(c.service_name || '') === _startCase(serviceName.value)), [channelMessages, requestType.value, serviceName.value])
  const variants = useMemo(() => _find(channel?.parameters, (p) => p.parameter_type === 'string')?.variants, [channel?.parameters]);
  const clearFields = useCallback(() => {
    setRequestType({ value: 'AdditionalService', error: '' });
    setServiceName({ value: '', error: '' });
    setReason({ value: '', error: '' });
    setQuantity({ value: 1, error: '' });
    setAmount({ value: 0, error: '' });
  }, []);

  useEffect(() => {
    if (processing) {
      let hasError = false;

      if (isShowServiceName && !!!serviceName.value) {
        setServiceName(prev => ({ ...prev, error: `Can't be empty` }));
        hasError = true;
      }
      if (!!!reason.value) {
        setReason(prev => ({ ...prev, error: `Can't be empty` }));
        hasError = true;
      }
      if (isShowQuantity && (!!!quantity.value || quantity.value <= 0 || quantity.value >= 1000)) {
        setQuantity(prev => ({ ...prev, error: `Wrong value` }));
        hasError = true;
      }
      if (isShowAmount && !!!amount.value) {
        setAmount(prev => ({ ...prev, error: `Can't be empty` }));
        hasError = true;
      }
      if (hasError) {
        onProcessing(false);
        return;
      }

      const request = {
        channel_message_id: channel?.id,
        parameter_values_attributes: _map(channel?.parameters, (p) => 
        ({ 
          parameter_id: p.id, 
          parameter_valuable_type: _includes(p.parameter_type, 'string') ? 'string_parameter_value' : 'number_parameter_value', 
          parameter_valuable_attributes: { value: p.parameter_type === 'string' ? reason.value : p.parameter_type === 'decimal' ? parseFloat(amount.value) : parseInt(quantity.value) }  
        }))
      };

      createRequest({ order_id: id, request })
      .then(() => setSuccess('Request created').then(clearFields))
      .catch((error) => setError(error))
      .finally(() => onProcessing(false))
    }
  }, [processing]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    return clearFields;
  }, [clearFields])

  return (
    <FieldsContainer sx={{ mt: 2 }}>
      <TextField 
        label='Request Type' 
        value={requestType.value} 
        select
        helperText={requestType.error} 
        error={!!requestType.error} 
        onChange={({ target: { value } }) => setRequestType({ value, error: '' })} 
        disabled={disabled}
      >
        { _map(requestTypes, (item) => <MenuItem key={item} value={item}>{_startCase(item)}</MenuItem>) }
      </TextField>
      { isShowServiceName &&
        <TextField 
          label='Service Name' 
          value={serviceName.value} 
          select
          helperText={serviceName.error} 
          error={!!serviceName.error} 
          onChange={({ target: { value } }) => setServiceName({ value, error: '' })} 
          sx={{ width: '50%' }}
          SelectProps={{ MenuProps: { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, sx: { height: '40%' } } }}
          disabled={disabled}
        >
          { _map(additionalServiceNames, (item) => <MenuItem key={item} value={item}>{_startCase(item)}</MenuItem>) }
        </TextField> }
      <TextField 
        label='Reason'
        select={!!variants?.length}
        inputProps={{ maxLength: 100 }} 
        value={reason.value} 
        helperText={reason.error} 
        error={!!reason.error} 
        onChange={({ target: { value } }) => setReason({ value, error: '' })}
        disabled={disabled}
      >
        { !!variants?.length && _map(variants, (item) => <MenuItem key={item} value={item}>{_startCase(item)}</MenuItem>) }
      </TextField>
      { isShowParameters && <>
        { isShowQuantity && <TextField 
          label={ isQuantityInMinutes ? 'Time (minutes)' : isEtaRequestType ? 'Eta (minutes)' : 'Quantity'} 
          value={quantity.value} 
          helperText={quantity.error} 
          error={!!quantity.error} 
          onChange={({ target: { value } }) => setQuantity({ value, error: '' })} 
          InputProps={{ type: 'number', inputProps: { min: 1 } }}
          disabled={disabled}
        /> }
        { isShowAmount && <TextField 
          label='Amount of charge' 
          value={amount.value} 
          helperText={amount.error} 
          error={!!amount.error} 
          onChange={({ target: { value } }) => setAmount({ value, error: '' })} 
          InputProps={{ type: 'number', inputProps: { min: 1 } }}
          disabled={disabled}
        /> }
      </> }
    </FieldsContainer>
  )
};

export default memo(Form);
import { memo } from 'react';
// Local files
import BaseItem from '../BaseItem/BaseItem';

const Company = ({ name }) => {
  return (
    <>
      <BaseItem title='Name' content={name} />
    </>
  );
};

export default memo(Company);
import { memo, useEffect, useRef, useState } from 'react';
//import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Map, { Source, Layer, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import useSupercluster from 'use-supercluster';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _find from 'lodash/find';
// Local files
import { ListWrapper, ListHeader } from './Map.styled';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import BaseFilter from 'components/Common/BaseFilter/BaseFilter';
import List from 'components/Users/List/List';
import Filter from 'components/Common/Filter/Filter';
import DriverInfo from 'components/Map/Components/DriverInfo/DriverInfo';
import RouteMarker from 'components/Map/Components/RouteMarker/RouteMarker';
import MapSettings from './Settings/Settings';
import MapFilters from './Filters/Filters';
import Search from './Search/Search';
import Popup from './Popup/Popup';
import Clusters from './Clusters/Clusters';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useMap from 'hooks/useMap';
import useDialogs from 'hooks/useDialogs';
import { mapboxAccessToken } from 'apis';
import { orderStatuses } from 'helpers/constants';
import useUsers from "../../hooks/useUsers";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const firstRouteLayer = {
  id: 'firstRoute',
  type: 'line',
  layout: { 'line-join': 'round', 'line-cap': 'round' },
  paint: { 'line-color': 'rgba(159, 157, 169, 1)', 'line-width': 5 }
};
const secondRouteLayer = {
  id: 'secondRoute',
  type: 'line',
  layout: { 'line-join': 'round', 'line-cap': 'round' },
  paint: { 'line-color': 'rgba(28, 23, 55, 1)', 'line-width': 5 }
};
const trafficLayer = {
  id: 'traffic',
  type: 'line',
  source: 'traffic',
  'source-layer': 'traffic',
  layout: { 'line-join': 'round', 'line-cap': 'round', visibility: 'none' },
  paint: {
    'line-color': [
      'match',
      ['get', 'congestion'],
      'low', '#28a745',
      'moderate', '#ffc107',
      'heavy', '#dc3545',
      'severe', '#8b0000',
      /* other */ '#000000'
    ],
    'line-width': 2
  }
};
const calculateBoundingBox = (route1, route2) => {
  const allCoordinates = [...route1.coordinates, ...route2.coordinates];

  let minLng = Number.POSITIVE_INFINITY;
  let minLat = Number.POSITIVE_INFINITY;
  let maxLng = Number.NEGATIVE_INFINITY;
  let maxLat = Number.NEGATIVE_INFINITY;

  allCoordinates.forEach(([lng, lat]) => {
    if (lng < minLng) minLng = lng;
    if (lat < minLat) minLat = lat;
    if (lng > maxLng) maxLng = lng;
    if (lat > maxLat) maxLat = lat;
  });

  return [[minLng, minLat], [maxLng, maxLat]];
};

const AppMap = ({ isModal = false, order, isISSC, isUrgently }) => {
  const mapRef = useRef();
  const mapFiltersRef = useRef();
  const driversListRef = useRef();
  const { setFilter } = useApp();
  const { openAssignDialog, closeMapDialog } = useDialogs();
  const { getMapDrivers, getUrgentlyMapDrivers, getMapOrders, clearLocalMapData, setMapConfigs, setActualDriverData } = useMap();
  const { setError } = useError();
  const { open } = useCustomSelector(state => state.app.filter);
  const { points, configs: { viewState, bounds, popup, localIncident } } = useCustomSelector(state => state.map);
  const [incidentAddress, setIncidentAddress] = useState({ value: '', error: '', latitude: 0, longitude: 0 });
  const [moving, setMoving] = useState(false);
  const [bearing, setBearing] = useState(0);
  const [newOrders, setNewOrders] = useState(null);
  const [startRouteCoordinates, setStartRouteCoordinates] = useState(null);
  const [endRouteCoordinates, setEndRouteCoordinates] = useState(null);
  const [firstRoute, setFirstRoute] = useState(null);
  const [firstDuration, setFirstDuration] = useState(null);
  const [secondRoute, setSecondRoute] = useState(null);
  const [secondDuration, setSecondDuration] = useState(null);
  const [query, setQuery] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [withoutOrder, setWithoutOrder] = useState(false);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v9');
  const [mapFilters, setMapFilters] = useState({ showTraffic: false, autoZoom: false, showNew: false, showActive: false });
  const { startUserDuty, stopUserDuty } = useUsers();
  const { latitudeGt: latitude_gt, latitudeLt: latitude_lt, longitudeGt: longitude_gt, longitudeLt: longitude_lt } = bounds;

  const orderPoints = points.filter(point => point.properties.category !== 'driver');

  const { clusters, supercluster } = useSupercluster({
    points: orderPoints,
    bounds: [bounds.longitudeGt, bounds.latitudeGt, bounds.longitudeLt, bounds.latitudeLt],
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const handleAssign = () => {
    if (!!order?.id) {
      openAssignDialog({
        order: {
          id: order?.id,
          eta: order?.eta,
          status: order?.status,
          driver: { id: popup.activePoint.id, ..._pick(popup, ['latitude', 'longitude']), ...popup.activePoint.data },
          trucks: order?.trucks,
          users: order?.users
        },
        isISSC,
        isUrgently
      });
    } else {
      closeMapDialog();
    }
  };
  const handleFilterChange = () => setFilter(!open);
  const handleQueryChange = (value) => setQuery(value);
  const handleMoveStart = () => setMoving(true);
  const handleMoveEnd = ({ target, viewState }) => {
    const { _ne, _sw } = target.getBounds();

    setMoving(false);
    !!newOrders?.length && setNewOrders([]);
    setMapConfigs({
      bounds: { latitudeGt: _sw.lat, latitudeLt: _ne.lat, longitudeGt: _sw.lng, longitudeLt: _ne.lng },
      viewState: _pick(viewState, ['latitude', 'longitude', 'zoom'])
    });
  };
  const handleMapLoaded = () => {
    const map = mapRef.current.getMap();

    map.addSource('traffic', {
      type: 'vector',
      url: 'mapbox://mapbox.mapbox-traffic-v1',
    });
    map.addLayer(trafficLayer);
  }
  const handleClusterClick = ({ id, latitude, longitude }) => {
    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(id), 20);
    const pointsForPopup = supercluster.getLeaves(id, Infinity);
    const dataForPopup = _map(pointsForPopup, p => _pick(p.properties, ['id', 'data', 'category']));
    setMapConfigs({
      viewState: { ...viewState, zoom: expansionZoom },
      popup: { open: true, cluster: true, latitude, longitude, data: dataForPopup, activePoint: dataForPopup[0] }
    });
    mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
  };
  const handleMarkerClick = (event, { id, latitude, longitude }) => {
    const isSwitch = event.target?.type === "checkbox";
    if (isSwitch) return;

    const foundedPoint = _find(points, p => p.properties.id === id);
    const { category, data } = !!foundedPoint ? foundedPoint.properties : null;
    if (!!data) {
      if (mapFilters.autoZoom) {
        setMapConfigs({
          viewState: { ...viewState, latitude, longitude },
          popup: { ...popup, cluster: false, open: true, type: category, latitude, longitude, activePoint: { id, category, data: { latitude, longitude, ...data } } }
        });
        mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
      } else {
        setMapConfigs({
          popup: { ...popup, cluster: false, open: true, type: category, latitude, longitude, activePoint: { id, category, data: { latitude, longitude, ...data } } }
        });
      }
    }
  };
  const handlePopupClose = () => {
    setMapConfigs({ popup: { open: false, latitude: null, longitude: null, cluster: false, data: [], activePoint: null } })
    .then(() => {
      setStartRouteCoordinates(null);
      setFirstRoute(null);
      setFirstDuration(null);
      setSecondRoute(null);
      setSecondDuration(null);
      setEndRouteCoordinates(null);
    });
  };
  const handlePopupItemClick = activePoint => { setMapConfigs({ popup: { ...popup, activePoint } })};
  const handleSearchChange = ({ value, error, latitude, longitude }) => {
    setIncidentAddress({ value, error, latitude, longitude });
    if (latitude && isModal) {
      mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
    }
  };
  const handleMapFiltersChange = v => {
    setMapFilters(prev => ({ ...prev, ...v }));
    if (v.showTraffic !== undefined) {
      const map = mapRef.current.getMap();

      if (v.showTraffic) {
        map.setLayoutProperty('traffic', 'visibility', 'visible');
      } else {
        map.setLayoutProperty('traffic', 'visibility', 'none');
      }
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() =>
      clearLocalMapData()
      .then(() => {
        if (isUrgently) {
          getUrgentlyMapDrivers({drivers: order?.users})
        } else {
          getMapDrivers({ query, current_order_statuses: statuses, without_current_order: withoutOrder })
          .catch(error => !axios.isCancel(error) && setError(error))
        }
      }
    ), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [query, statuses, withoutOrder, getMapDrivers, clearLocalMapData, setError]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (newOrders !== null && !newOrders.length) {
      if (mapFilters.showNew || mapFilters.showActive) {
        clearLocalMapData('order')
        .then(() =>
          getMapOrders({
            latitude_gt,
            latitude_lt,
            longitude_gt,
            longitude_lt,
            ...mapFilters.showNew && { statuses: ['new'] },
            ...mapFilters.showActive && { statuses: ['manager_accepted', 'en_route', 'pick_up', 'drop_off'] }
          })
          .catch(error => !axios.isCancel(error) && setError(error))
        );
      } else {
        getMapOrders({
          latitude_gt,
          latitude_lt,
          longitude_gt,
          longitude_lt,
          ...mapFilters.showNew && { statuses: ['new'] },
          ...mapFilters.showActive && { statuses: ['manager_accepted', 'en_route', 'pick_up', 'drop_off'] }
        })
        .catch(error => !axios.isCancel(error) && setError(error));
      }
    }
  }, [latitude_gt, latitude_lt, longitude_gt, longitude_lt, newOrders, getMapOrders, setError, clearLocalMapData, mapFilters.showNew, mapFilters.showActive]);
  useEffect(() => {
    getMapOrders({ statuses: ['new'] })
    .then(({ payload: { orders } }) => {
      if ((!!orders.length && !!orders[0]?.incident_location?.address) || !!localIncident.latitude) {
        setNewOrders(orders);
        setMapConfigs({
          viewState: {
            ...viewState,
            latitude: localIncident.latitude || orders[0]?.incident_location?.latitude,
            longitude: localIncident.longitude || orders[0]?.incident_location?.longitude
          }
        });
        mapRef.current?.flyTo({ center: [localIncident.longitude || orders[0]?.incident_location?.longitude, localIncident.latitude || orders[0]?.incident_location?.latitude], duration: 3000 });
      } else {
        setNewOrders([]);
      }
    })
    .catch(error => !axios.isCancel(error) && setError(error))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isModal && localIncident?.value) {
      setIncidentAddress({
        value: localIncident?.value,
        error: '',
        ...(!!localIncident.latitude && { latitude: localIncident.latitude }),
        ...(!!localIncident.longitude && { longitude: localIncident.longitude })
      });
    }
  }, [isModal, localIncident]);
  useEffect(() => {
    const getRoutes = async ({ status, incident, user, destination }) => {
      if (status === 'en_route') {
        const fromUserToIncidentQuery = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${user.longitude},${user.latitude};${incident.longitude},${incident.latitude}?geometries=geojson&access_token=${mapboxAccessToken}`);

        if (fromUserToIncidentQuery.ok) {
          const fromUserToIncidentJson = await fromUserToIncidentQuery.json();
          const fromUserToIncidentData = fromUserToIncidentJson?.routes[0]?.geometry ?? null;
          const fromUserToIncidentDuration = fromUserToIncidentJson?.routes[0]?.duration ?? null;

          if (fromUserToIncidentData && fromUserToIncidentDuration) {
            setEndRouteCoordinates({ longitude: incident.longitude, latitude: incident.latitude });
            setSecondDuration(fromUserToIncidentDuration / 60);
            setSecondRoute(fromUserToIncidentData);

            if (mapFilters.autoZoom) {
              const bounds = calculateBoundingBox(fromUserToIncidentData, { coordinates: [user.longitude, user.latitude] });

              mapRef.current.fitBounds(bounds, { padding: getPadding() });
            }
          }
        }
      } else {
        const fromIncidentToUserQuery = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${incident.longitude},${incident.latitude};${user.longitude},${user.latitude}?geometries=geojson&access_token=${mapboxAccessToken}`);
        const fromUserToDestinationQuery = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${user.longitude},${user.latitude};${destination.longitude},${destination.latitude}?geometries=geojson&access_token=${mapboxAccessToken}`);

        if (fromIncidentToUserQuery.ok && fromUserToDestinationQuery.ok) {
          const fromIncidentToUserJson = await fromIncidentToUserQuery.json();
          const fromUserToDestinationJson = await fromUserToDestinationQuery.json();
          const fromIncidentToUserData = fromIncidentToUserJson?.routes[0]?.geometry ?? null;
          const fromIncidentToUserDuration = fromIncidentToUserJson?.routes[0]?.duration ?? null;
          const fromUserToDestinationData = fromUserToDestinationJson?.routes[0]?.geometry ?? null;
          const fromUserToDestinationDuration = fromUserToDestinationJson?.routes[0]?.duration ?? null;

          if (fromIncidentToUserData && fromIncidentToUserDuration && fromUserToDestinationData && fromUserToDestinationDuration) {
            setStartRouteCoordinates({ longitude: incident.longitude, latitude: incident.latitude });
            setFirstDuration(fromIncidentToUserDuration / 60);
            setFirstRoute(fromIncidentToUserData);
            setEndRouteCoordinates({ longitude: destination.longitude, latitude: destination.latitude });
            setSecondDuration(fromUserToDestinationDuration / 60);
            setSecondRoute(fromUserToDestinationData);

            if (mapFilters.autoZoom) {
              const bounds = calculateBoundingBox(fromIncidentToUserData, fromUserToDestinationData);

              mapRef.current.fitBounds(bounds, { padding: getPadding() });
            }
          }
        }
      }
    };
    const allowedStatuses = ['en_route', 'servicing'];

    if (mapRef.current && popup.activePoint?.category === 'driver' && popup.activePoint?.data?.current_order?.incident_location && popup.activePoint?.data?.current_order?.destination_location && allowedStatuses.includes(popup.activePoint?.data?.current_order?.status)) {
      const status = popup.activePoint.data.current_order.status;
      const incident = popup.activePoint.data.current_order.incident_location;
      const user = { latitude: popup.activePoint.data.latitude, longitude: popup.activePoint.data.longitude };
      const destination = popup.activePoint.data.current_order.destination_location;
      getRoutes({ status, incident, user, destination });
    }

    return () => {
      setStartRouteCoordinates(null);
      setEndRouteCoordinates(null);
      setFirstRoute(null);
      setFirstDuration(null);
      setSecondRoute(null);
      setSecondDuration(null);
    };
  }, [popup.activePoint, mapFilters.autoZoom]);

  const setUserConfig = (res) => {
    setActualDriverData({
      id: res.id,
      latitude: res.latitude,
      longitude: res.longitude,
      duty_duration: res.duty_duration
    })
  };

  const handleChangeDutyStatus = (userId, onDuty) => {
    const dutyAction = onDuty ? stopUserDuty : startUserDuty;
    dutyAction(userId)
      .then((data) => {
        setUserConfig(data.payload.data.user)
      })
      .catch(error => {
        setError(error)
      });
  };

  const getPadding = () => {
    if (driversListRef.current && mapFiltersRef.current) {
      const driverListWidth = driversListRef.current.getBoundingClientRect().width + 30;
      const detailPageWidth = mapFiltersRef.current.getBoundingClientRect().width + 100;

      return { bottom: 50, left: driverListWidth, right: detailPageWidth, top: 50 };
    }
  }

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={mapboxAccessToken}
      viewState={moving ? null : viewState}
      onMoveStart={handleMoveStart}
      onMoveEnd={handleMoveEnd}
      onRotate={({ viewState }) => setBearing(viewState.bearing)}
      onLoad={handleMapLoaded}
      mapStyle={mapStyle}
    >
      {startRouteCoordinates && <RouteMarker {...startRouteCoordinates} />}
      {firstDuration &&
        <Source type="geojson" data={firstRoute}>
          <Layer {...firstRouteLayer} />
        </Source>
      }
      {secondRoute &&
        <Source type="geojson" data={secondRoute}>
          <Layer {...secondRouteLayer} />
        </Source>
      }
      {endRouteCoordinates && <RouteMarker {...endRouteCoordinates} end />}
      <ListWrapper ref={driversListRef} isModal={isModal} style={{ zIndex: 20 }}>
        <ListHeader>
          {!isUrgently &&
            <Filter
              filterTypeButton='icon-button'
              query={query}
              onQueryChange={handleQueryChange}
              onFilterClick={handleFilterChange}
              filterContent={
                <>
                  <BaseFilter
                    label='Statuses'
                    value={statuses}
                    onChange={setStatuses}
                    data={_pick(orderStatuses, ['MANAGER_ACCEPTED', 'COMPANY_ACCEPTED', 'EN_ROUTE', 'PICK_UP', 'SERVICING', 'DROP_OFF'])}
                  />
                  <FormControlLabel
                    sx={{ mt: 1 }}
                    control={<Switch color="primary" />}
                    label='Available drivers only'
                    labelPlacement='end'
                    componentsProps={{ typography: { fontWeight: 500, fontSize: '0.875rem', textTransform: 'uppercase', color: 'primary.main' } }}
                    onChange={({ target: { checked } }) => setWithoutOrder(checked)}
                    value={withoutOrder}
                  />
                </>
              }
            />
          }
        </ListHeader>
        {/*{listLoading && <LinearProgress />}*/}
        <ComponentErrorWrapper
          children={
            <List
              onMarkerClick={(e, id, latitude, longitude) => handleMarkerClick(e, { id, latitude, longitude })}
              onAssign={handleAssign}
              incident_location={localIncident}
              isAvailable={true}
              {...{query, isModal, withoutOrder}}
              setUserConfig={setUserConfig}
            />
          }
        />
      </ListWrapper>
      <MapFilters filtersRef={mapFiltersRef} filters={mapFilters} onChange={handleMapFiltersChange} />
      <MapSettings mapStyle={mapStyle} onMapStyleChange={setMapStyle} />
      <NavigationControl position='bottom-right' />
      <DriverInfo
        {...popup}
        duration={firstDuration || secondDuration ? Math.round(secondDuration || 0) : null}
        onClose={handlePopupClose}
        onChangeDutyStatus={handleChangeDutyStatus}
      />
      <Clusters
        clusters={clusters}
        points={points}
        activePoint={popup?.activePoint}
        duration={secondDuration}
        bearing={bearing}
        onClusterClick={handleClusterClick}
        onMarkerClick={(e, id, latitude, longitude) => handleMarkerClick(e, { id, latitude, longitude })}
      />
      {isModal &&
        <Search
          {...incidentAddress}
          onChange={handleSearchChange}
        />
      }
      <Popup
        {...popup}
        onClose={handlePopupClose}
        onItemClick={handlePopupItemClick}
      />
    </Map>
  );
};

export default memo(AppMap);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getCompanyChargesRoutine,
  getFullListChargesRoutine,
  getListChargesRoutine,
  getDefaultChargesRoutine,
  getChargeRoutine, 
  createChargeRoutine,
  createCompanyChargeRoutine,
  deleteCompanyChargeRoutine,
  clearLocalListChargesRoutine,
  clearLocalCompanyChargesRoutine,
  clearLocalActiveChargeRoutine 
} from 'actions';
import _get from 'lodash/get';

const attributes = ['id', 'name', 'charge_type', 'mile_type', 'basic_rate_types'];

export const getCompanyCharges = getThunkActionCreator(
  getCompanyChargesRoutine,
  async ({ orders = { created_at: 'desc' }, company_id }) => {
    getCompanyCharges.getCompanyChargesCancel && getCompanyCharges.getCompanyChargesCancel();
    const { token: access_token } = await sessionService.loadSession();
    let offset = 0;
    let chargesReady = false;
    let charges = [];
    let cancelRequest = function executor(c) { getCompanyCharges.getCompanyChargesCancel = c };

    while (!chargesReady) {
      getCompanyCharges.getCompanyChargesCancel && getCompanyCharges.getCompanyChargesCancel();
      const response = await axios.get(`/user/companies/${company_id}/charges`,
        {
          params: { access_token, limit: 25, offset, orders, attributes },
          cancelToken: new CancelToken(cancelRequest)
        }
      );
      const pagination = _get(response, 'data.pagination');
      const uploadedCharges = _get(response, 'data.charges');

      if (pagination.count + pagination.offset < pagination.total_count) {
        charges = [...charges, ...uploadedCharges];
        offset += 25;
      } else {
        charges = [...charges, ...uploadedCharges];
        chargesReady = true;
      }
    }
    return { charges };
  }
);
export const getFullListCharges = getThunkActionCreator(
  getFullListChargesRoutine,
  async () => {
    getFullListCharges.getFullListChargesCancel && getFullListCharges.getFullListChargesCancel();
    const { token: access_token } = await sessionService.loadSession();
    let offset = 0;
    let chargesReady = false;
    let charges = [];
    let cancelRequest = function executor(c) { getFullListCharges.getFullListChargesCancel = c };

    while (!chargesReady) {
      getFullListCharges.getFullListChargesCancel && getFullListCharges.getFullListChargesCancel();
      const response = await axios.get(`/user/charges`,
        {
          params: { access_token, limit: 25, offset, orders: { created_at: 'desc' }, attributes },
          cancelToken: new CancelToken(cancelRequest)
        }
      );
      const pagination = _get(response, 'data.pagination');
      const uploadedCharges = _get(response, 'data.charges');

      if (pagination.count + pagination.offset < pagination.total_count) {
        charges = [...charges, ...uploadedCharges];
        offset += 25;
      } else {
        charges = [...charges, ...uploadedCharges];
        chargesReady = true;
      }
    }
    return { charges };
  }
);
export const getListCharges = getThunkActionCreator(
  getListChargesRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, charge_type }) => {
    getListCharges.getListChargesCancel && getListCharges.getListChargesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/charges`,
      {
        params: { access_token, limit: 25, offset, orders, charge_type, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getListCharges.getListChargesCancel = cancel })
      }
    );
  }
);
export const getDefaultCharges = getThunkActionCreator(
  getDefaultChargesRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, service_id, order_id, expense_name = null }) => {
    getDefaultCharges.getDefaultChargesCancel && getDefaultCharges.getDefaultChargesCancel();
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.get(`/user/services/${service_id}/default_charges`, {
      params: { access_token, limit, offset, orders, order_id, attributes: ['id', { charge: ['name', 'charge_type'] }, 'basic_rate_type'] },
      cancelToken: new CancelToken(function executor(cancel) { getDefaultCharges.getDefaultChargesCancel = cancel })
    });

    return { response, expense_name };
  }
);
export const getCharge = getThunkActionCreator(
  getChargeRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/charges/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCharge.getChargeCancel = cancel })
      }
    );
  }
);
export const createCharge = getThunkActionCreator(
  createChargeRoutine,
  async (charge) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/charges`, { access_token, charge, attributes });
  }
);
export const createCompanyCharge = getThunkActionCreator(
  createCompanyChargeRoutine,
  async ({ company_id, id }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/companies/${company_id}/charges/${id}`, { access_token });

    return { response, id };
  }
);
export const deleteCompanyCharge = getThunkActionCreator(
  deleteCompanyChargeRoutine,
  async ({ company_id, id }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/companies/${company_id}/charges/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalListCharges = getThunkActionCreator(clearLocalListChargesRoutine, async () => {});
export const clearLocalCompanyCharges = getThunkActionCreator(clearLocalCompanyChargesRoutine, async () => {});
export const clearLocalActiveCharge = getThunkActionCreator(clearLocalActiveChargeRoutine, async () => {});
import { memo } from 'react';
import Box from '@mui/material/Box';
// Local files
import Map from 'components/Map/Map';

const MapPage = () => {
  const hideAppBar = localStorage.getItem('hide_app_bar') === 'true';

  return (
    <Box
      sx={{
        width: '100%',
        height: hideAppBar ? '100%' : 'calc(100% - 64px)',
        '& button[aria-label="Close popup"]': {
          minWidth: '32px', height: '32px'
        }
      }}
    >
      <Map />
    </Box>
  );
};

export default memo(MapPage);
import React, { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useRole from 'hooks/useRole';
import { formatTextWithLinks } from 'helpers/index'

const Field = ({ edit: defaultEdit, disabled = false, value, onChange, onUpdate, isEditable = true }) => {
  const { isManager, isOwner } = useRole();
  const [edit, setEdit] = useState(defaultEdit);

  const handleBlur = () => { 
    if (value?.length > 2) {
      setEdit(false);
      !defaultEdit && onUpdate();
    } else return;
  };

  const formattedText = formatTextWithLinks(value || '-');

  return (
    <>
      {!edit ? (
        <>
          <Typography
            variant='body1'
            sx={{
              maxHeight: 168,
              overflow: 'auto',
              lineHeight: '24px',
              fontStyle: 'italic',
              cursor: 'pointer',
              mt: '16px',
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
            }}
            onClick={() => (isManager || isOwner) && isEditable && !disabled && setEdit(true)}
          >
            {React.createElement('div', { dangerouslySetInnerHTML: { __html: formattedText } })}
          </Typography>
        </>
      ) : (
        <TextField
          sx={{ mt: '8px' }}
          multiline
          minRows={4}
          maxRows={6}
          fullWidth
          placeholder='Add Notes here.'
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
        />
      )}
    </>
  );
};

export default memo(Field);
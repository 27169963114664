//Local files
import { Direction, Root, StyledTooltip, Wrapper } from './InnerMarker.styled';
import { ReactComponent as DirectionIcon } from 'assets/icons/marker-direction.svg';
import { ReactComponent as OfflineIcon } from 'assets/icons/offline.svg';


const InnerMarker = ({ driver, available, offline, selected, info, children }) => {
  return (
    <Root driver={driver} available={available} offline={offline} selected={selected}>
      {driver && !offline &&
        <Direction available={available} sx={{ transform: `rotate(-90deg)` }}>
          <DirectionIcon />
        </Direction>
      }
       <StyledTooltip
         arrow
         placement='right'
         title={offline ? <><OfflineIcon /><span>Offline</span></> : info}
       >
         <Wrapper>
           {children}
         </Wrapper>
       </StyledTooltip>
    </Root>
  );
};

export default InnerMarker;
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
// Local files
import { Information, Root } from './Head.styled';

const Head = () => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Root>
      <Information>
        <Typography variant={mediaDesktop ? 'h4' : 'body1'} sx={{ mt: 1 }}>📌 Resources</Typography>
      </Information>
    </Root>
  );
};

export default Head;
import {
  setLocalTokenRoutine,
  getUserSessionsRoutine,
  getSessionsRoutine,
  getSessionRoutine,
  createSessionRoutine,
  deleteActiveSessionRoutine,
  deleteSessionRoutine,
  setRecoveryEmailRoutine,
  setConfirmationCodeRoutine,
  clearLocalRecoveryDataRoutine
} from 'actions';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export const initialState = {
  user: { data: [], pagination: { limit: 10, offset: 0, count: 0, total_count: 0 } },
  own: { data: [], pagination: { limit: 10, offset: 0, count: 0, total_count: 0 } },
  uploaded: { id: '', ip: '', location: '', active_at: '' },
  session: { id: '', platform_type: '', access_token: '' },
  recoveryEmail: '',
  confirmationCode: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setLocalTokenRoutine.SUCCESS: {
      return state;
    }
    case getUserSessionsRoutine.SUCCESS: {
      const { data: { sessions, pagination } } = action.payload;
      const user = pagination.limit === 1
        ? { data: [...state.user.data, ...sessions], pagination: { ...state.user.pagination, count: state.user.pagination.count + 1 } }
        : { data: sessions, pagination };

      return { ...state, user };
    }
    case getSessionsRoutine.SUCCESS: {
      const { data: { sessions, pagination } } = action.payload;

      return { ...state, own: { data: [...state.own.data, ...sessions], pagination } };
    }
    case getSessionRoutine.SUCCESS: {
      const { data: { session } } = action.payload;

      return { ...state, uploaded: { ...state.uploaded, ...session } };
    }
    case createSessionRoutine.SUCCESS: {
      const { data: { session } } = action.payload;

      return { ...state, session: { ...state.session, ...session } };
    }
    case deleteActiveSessionRoutine.SUCCESS: {
      const { status } = action.payload;

      return { ...state, ...(status === 204 && { session: initialState.session }) };
    }
    case deleteSessionRoutine.SUCCESS: {
      let data = [];
      let pagination = {};
      const { response: { status }, id } = action.payload;
      const foundedUserSession = _find(state.user.data, (item) => item.id === id);
      const foundedOwnSession = _find(state.own.data, (item) => item.id === id);
      
      if (!!foundedUserSession) {
        data = _filter(state.user.data, (item) => item.id !== id);
        pagination = { ...state.user.pagination, count: state.user.pagination.count - 1, total_count: state.user.pagination.total_count - 1 };
      }
      if (!!foundedOwnSession) {
        data = _filter(state.own.data, (item) => item.id !== id);
        pagination = { ...state.own.pagination, count: state.own.pagination.count - 1, total_count: state.own.pagination.total_count - 1 };
      }

      return {
        ...state,
        ...(status === 204 && !!foundedUserSession && { user: { data, pagination } }),
        ...(status === 204 && !!foundedOwnSession && { own: { data, pagination } })
      };
    }
    case setRecoveryEmailRoutine.SUCCESS: {
      return { ...state, recoveryEmail: action.payload };
    }
    case setConfirmationCodeRoutine.SUCCESS: {
      return { ...state, confirmationCode: action.payload };
    }
    case clearLocalRecoveryDataRoutine.SUCCESS: {
      return {
        ...state,
        recoveryEmail: initialState.recoveryEmail,
        confirmationCode: initialState.confirmationCode
      };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
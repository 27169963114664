import { memo, useState } from 'react';
import Box from '@mui/material/Box';
// Local files
import RequestStep from 'components/PasswordRecovery/RequestStep/RequestStep';
import ResetStep from 'components/PasswordRecovery/ResetStep/ResetStep';
import VerificationStep from 'components/PasswordRecovery/VerificationStep/VerificationStep';
import { recoverySteps } from 'helpers/constants';

const { REQUEST, VERIFICATION, RESET } = recoverySteps;

const Recovery = () => {
  const [step, setStep] = useState(REQUEST);

  const handleChangeStep = (value) => setStep(value);

  return (
    <Box sx={{ width: '100%', height: '100%', bgcolor: 'background.paper' }}>
      {step === REQUEST && <RequestStep changeStep={handleChangeStep} />}
      {step === VERIFICATION && <VerificationStep changeStep={handleChangeStep} />}
      {step === RESET && <ResetStep changeStep={handleChangeStep} />}
    </Box>
  );
};

export default memo(Recovery);
import { useCallback } from 'react';
// Local files
import sound from 'assets/notification.mp3';
import { detectOS } from 'helpers';
import useSuccess from 'hooks/useSuccess';

const audio = new Audio(sound);

const useNotification = () => {
  const { setSuccess } = useSuccess();
  const handleNotificationShow = () => detectOS() === 'Mac OS' && audio.play().catch((error) => console.log(error));

  const sendNotification = useCallback((body, id, type, silent = true) => {
    const notification = new Notification('Order', { body, silent });

    setTimeout(() => notification.close(), 5000);

    notification.onclick = (event) => { 
      event.preventDefault();
      window.open(`/${type}/${id}`, '_self');
    };
    if (!silent) {
      notification.onshow = handleNotificationShow;
    }
  }, []);
  const showNotification = useCallback(({ body, id, type, silent = true }) => {
    if(!("Notification" in window)) {
      setSuccess(body);
    } 
    else if(Notification.permission === 'granted') {
      sendNotification(body, id, type, silent);
    }
    else if(Notification.permission !== 'denied') {
       Notification.requestPermission((permission)=> {
          if (permission === "granted") {
            sendNotification(body, id, type, silent)
          } else {
            setSuccess(body);
          }
       })
    } else {
      setSuccess(body);
    }
  }, [sendNotification, setSuccess]);

  return { showNotification };
};

export default useNotification;
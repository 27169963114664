import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BaseLink from '@mui/material/Link';
import { Link } from 'react-router-dom';

const Actions = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', pt: 1, pb: 2}}>
      <Typography color='textSecondary' variant='body2'>Forgot your password?</Typography>
        <BaseLink
            component={Link}
            to='/recovery'
            underline="hover"
            variant='subtitle2'
        >
            Password Recovery
        </BaseLink>
    </Box>
  );
};

export default memo(Actions);
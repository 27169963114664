import { memo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import _pick from 'lodash/pick';
//Local files
import { Card, CardHeader, CardTitle } from 'components/Common/BasePageUi/BasePageUi';
import BaseAddressAutocomplete from 'components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete';
import { mapboxAccessToken } from 'apis';
import useMap from 'hooks/useMap';
import useLocations from 'hooks/useLocations';
import { sides } from 'helpers/constants';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapCard = ({  
  edit,
  duplicate,
  locationsData,
  getMapData,
  processingData,
  towVehicleLocationData,
  onChangeProcessingData,
  sx 
}) => {
  const { setMapConfigs } = useMap();
  const { createLocation } = useLocations();
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [moving, setMoving] = useState(false);
  const [incidentLocation, setIncidentLocation] = useState({ value: '', error: '', latitude: 0, longitude: 0, draggedMarker: '' });
  const [destinationLocation, setDestinationLocation] = useState({ value: '', error: '', latitude: 0, longitude: 0, draggedMarker: '' });
  const [viewState, setViewState] = useState({ latitude: incidentLocation?.latitude, longitude: incidentLocation?.longitude, zoom: 14 });

  const handleMoveStart = () => setMoving(true);
  const handleMoveEnd = e => {
    setMoving(false);
    setViewState(_pick(e.viewState, ['latitude', 'longitude', 'zoom']));
  };
  const needToUpdate = (a,b) => a?.address !== b?.address;

  useEffect(() => {
    if (processingData) {
      let hasError = false;

      if (!!!incidentLocation.value) {
        setIncidentLocation(prev => ({ ...prev, error: 'Incident address can\'t be empty' }));
      }
      if (hasError) {
        onChangeProcessingData(false);
        return;
      }

      const processingLocations = {
        incident_location: { address: incidentLocation.value, latitude: incidentLocation.latitude, longitude: incidentLocation.longitude },
        ...(!!destinationLocation.latitude && {
          destination_location: { address: destinationLocation.value, latitude: destinationLocation.latitude, longitude: destinationLocation.longitude }
        })
      };

      if (!!processingLocations.incident_location.address || !!processingLocations?.destination_location?.address) {
        if (edit) {
          if (needToUpdate(locationsData.incident_location, processingLocations.incident_location) && needToUpdate(locationsData.destination_location, processingLocations.destination_location)) {
            createLocation({ location: processingLocations.incident_location, side: sides.USER })
            .then(({ payload: { data: { location: incident_location } } }) => 
              createLocation({ location: processingLocations.destination_location, side: sides.USER })
              .then(({ payload: { data: { location: destination_location } } }) => 
              getMapData({ incident_location_id: incident_location.id, destination_location_id: destination_location.id }))
            )
          } else if (needToUpdate(locationsData.incident_location, processingLocations.incident_location)) {
            createLocation({ location: processingLocations.incident_location, side: sides.USER })
            .then(({ payload: { data: { location: incident_location } } }) => getMapData({ incident_location_id: incident_location.id }))
          } else if (needToUpdate(locationsData.destination_location, processingLocations.destination_location)) {
            createLocation({ location: processingLocations.destination_location, side: sides.USER })
            .then(({ payload: { data: { location: destination_location } } }) => getMapData({ destination_location_id: destination_location.id }))
          } else {
            getMapData({ });
          }
        } else {
          createLocation({ location: processingLocations.incident_location, side: sides.USER })
          .then(({ payload: { data: { location: incident_location } } }) => 
            !!processingLocations.destination_location?.address ? 
            createLocation({ location: processingLocations.destination_location, side: sides.USER })
            .then(({ payload: { data: { location: destination_location } } }) => 
              getMapData({ incident_location_id: incident_location.id, destination_location_id: destination_location.id })
            ) : getMapData({ incident_location_id: incident_location.id })
          )
        }
      }
    }
  }, [processingData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((edit || duplicate) && !!locationsData) {
      setViewState({ latitude: locationsData.incident_location?.latitude || 0, longitude: locationsData.incident_location?.longitude || 0, zoom: 14 });
      !!locationsData.incident_location && setIncidentLocation({
        value: locationsData.incident_location?.address,
        error: '',
        draggedMarker: '',
        latitude: locationsData.incident_location?.latitude || 0,
        longitude: locationsData.incident_location?.longitude || 0
      });
      !!locationsData.destination_location && setDestinationLocation({
        value: locationsData.destination_location?.address,
        error: '',
        draggedMarker: '',
        latitude: locationsData.destination_location?.latitude || 0,
        longitude: locationsData.destination_location?.longitude || 0
      });
      setMapConfigs({ localIncident: { value: locationsData.incident_location?.address, latitude: locationsData.incident_location?.latitude, longitude: locationsData.incident_location?.longitude } })
    }
  }, [edit, duplicate, locationsData, setMapConfigs]);
  useEffect(() => {
    if ((!edit && !duplicate) && !!towVehicleLocationData) {
      setViewState({ latitude: towVehicleLocationData.company_location?.latitude || 0, longitude: towVehicleLocationData.company_location?.longitude || 0, zoom: 14 });
      !!towVehicleLocationData.company_location && setIncidentLocation({
        value: towVehicleLocationData.company_location?.address,
        error: '',
        draggedMarker: '',
        latitude: towVehicleLocationData.company_location?.latitude || 0,
        longitude: towVehicleLocationData.company_location?.longitude || 0
      });
      setMapConfigs({ localIncident: { value: towVehicleLocationData.company_location?.address, latitude: towVehicleLocationData.company_location?.latitude, longitude: towVehicleLocationData.company_location?.longitude } })
    }
  }, [edit, duplicate, towVehicleLocationData, setMapConfigs]);

  return (
    <Card sx={{ padding: '20px 16px', ...sx }}>
      <CardHeader sx={{ pb: '8px', justifyContent: 'space-between' }}>
        <CardTitle>Location</CardTitle>
        {/* <Button size='small' color='primary'>Request customer location</Button> */}
      </CardHeader>
      <Box sx={{ padding: '4px 0 12px'}}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: '20px' }}>
          <LocationOnOutlinedIcon color='primary' sx={{ mr: '6px' }} />
          <BaseAddressAutocomplete
            id='incident-location'
            margin='dense'
            label='Incident address *'
            value={incidentLocation.value}
            draggedMarker={incidentLocation.draggedMarker}
            error={!!incidentLocation.error}
            helperText={incidentLocation.error}
            onChange={({ value, error = '', draggedMarker = '', latitude = 0, longitude = 0 }) => { 
              !!latitude && setViewState({ latitude, longitude, zoom: 14 });
              setIncidentLocation({ value, error, draggedMarker, latitude, longitude });
              !!latitude && setMapConfigs({ localIncident: { value, latitude, longitude } });
            }}
            inputSx={{ width: `${mediaDesktop ? '400' : '280'}px !important` }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
          <LocationOnIcon color='error' sx={{ mr: '6px' }} />
          <BaseAddressAutocomplete
            id='destination-location'
            margin='dense'
            label='Destination address'
            value={destinationLocation.value}
            draggedMarker={destinationLocation.draggedMarker}
            error={!!destinationLocation.error}
            helperText={destinationLocation.error}
            onChange={({ value, error = '', draggedMarker = '', latitude = 0, longitude = 0 }) => {
              !!latitude && setViewState({ latitude, longitude, zoom: 14 });
              setDestinationLocation({ value, error, draggedMarker, latitude, longitude })}
            }
            inputSx={{ width: `${mediaDesktop ? '400' : '320'}px !important` }}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', height: '300px', borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ width: '100%', height: '100%', background: '#badbad'}}>
          <Map
            mapboxAccessToken={mapboxAccessToken}
            viewState={moving ? null : viewState}
            onMoveStart={handleMoveStart}
            onMoveEnd={handleMoveEnd}
            mapStyle="mapbox://styles/mapbox/streets-v9"
          >
            {!!incidentLocation.latitude && <Marker
              color='#2418D3'
              latitude={incidentLocation.latitude}
              longitude={incidentLocation.longitude}
              draggable
              onDragEnd={({ lngLat }) => setIncidentLocation(prev => ({...prev, draggedMarker: `${lngLat.lng},${lngLat.lat}` }))}
            />}
            {!!destinationLocation.latitude && (
              <Marker
                color='#FA004A'
                latitude={destinationLocation.latitude}
                longitude={destinationLocation.longitude}
                draggable
                onDragEnd={({ lngLat }) => setDestinationLocation(prev => ({...prev, draggedMarker: `${lngLat.lng},${lngLat.lat}` }))}
              />
            )}
            <NavigationControl />
          </Map>
        </Box>
      </Box>
    </Card>
  )
};

export default memo(MapCard);
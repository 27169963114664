import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import head from 'assets/images/dashboard-head.png';

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: 'auto',
  background: `url(${head}) no-repeat center center`,
  backgroundColor: '#1B1636',
  backgroundSize: 'cover',
  color: 'white',
  padding: '24px 24px 24px 24px',
  [theme.breakpoints.up('md')]: {
    padding: '48px 48px 48px 48px'
  }
}));
export const Information = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '50%'
});
export const Stats = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column'
});
import styled from '@mui/material/styles/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.background.selected}`,
  paddingLeft: 12,
  paddingRight: 12,
}));

export const Header = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px'
});

export const Title = styled((props) => (
  <Typography noWrap {...props} />
))(() => ({
  fontSize: '1.125rem',
  fontWeight: 700,
}));

export const Content = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
});

export const Chips = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
});

export const OrderItem = styled('div')(({ theme }) => ({
  width: '100%',
  fontSize: '.75rem',
  color: theme.palette.text.secondary,
}));
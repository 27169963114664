import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCompanies as getCompaniesAction,
  updateCompany as updateCompanyAction,
  clearLocalCompanies as clearLocalCompaniesAction,
} from 'actions/companies';

const useCompanies = () => {
  const dispatch = useDispatch();
  const getCompanies = useCallback(({ offset, query }) => dispatch(getCompaniesAction({ offset, query })), [dispatch]);
  const updateCompany = useCallback(company => dispatch(updateCompanyAction(company)), [dispatch]);
  const clearLocalCompanies = useCallback(() => dispatch(clearLocalCompaniesAction()), [dispatch]);

  return {
    getCompanies,
    updateCompany,
    clearLocalCompanies
  };
};

export default useCompanies;
import { memo, useEffect, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CancelIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Check';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
//Local files
import ChargesAutocomplete from 'components/Charges/Autocomplete/Autocomplete';
import { rateBasicValueTypes, rateFeeValueTypes, reasons } from 'helpers/constants';
import { DELETE_RATE } from 'helpers/confirmations';
import useRates from 'hooks/useRates';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useDialogs from 'hooks/useDialogs';
import useCustomSelector from 'hooks/useCustomSelector';
import useChargeTypes from 'hooks/useChargeTypes';

const Form = ({ activeRate = null, chargeType, onCancel }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createRate, updateRate } = useRates();
  const { openConfirmationDialog } = useDialogs();
  const { isBasic, isFee } = useChargeTypes(chargeType);
  const { activeVariant } = useCustomSelector(state => state.app.accountServiceData);
  const [charge, setCharge] = useState({ value: '', formattedValue: null, error: '' });
  const [rateValueType, setRateValueType] = useState({ value: '', error: '' });
  const [rateValue, setRateValue] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);
  const filteredBasicTypes = useMemo(() => !!charge.formattedValue ? 
    _filter(rateBasicValueTypes, ({ value }) => !!_find(charge.formattedValue?.basic_rate_types, (c) => c === value)) 
  : rateBasicValueTypes, [charge.formattedValue]);

  useEffect(() => {
    if (!!activeRate) {
      setCharge({ value: activeRate?.charge?.id, formattedValue: activeRate?.charge, error: '' });
      setRateValueType({ value: isBasic ? activeRate?.basic_value_type : activeRate?.fee_value_type, error: '' });
      setRateValue({ value: activeRate?.value, error: '' });
    }

    return () => {
      setCharge({ value: '', formattedValue: null, error: '' });
      setRateValueType({ value: '', error: '' });
      setRateValue({ value: '', error: '' });
    }
  }, [isBasic, activeRate]);

  const handleSubmit = () => {
    let hasError = false;

    if (!!!charge.value) {
      setCharge(prev => ({ ...prev, error: `Please select charge` }));
      hasError = true;
    }
    if (!!!rateValueType.value) {
      setRateValueType(prev => ({ ...prev, error: `Type can't be empty` }));
      hasError = true;
    }
    if (!!!rateValue.value) {
      setRateValue(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (hasError) return;

    const processingRate = {
      ...(!!activeRate && { id: activeRate.id }),
      ...(!!!activeRate && { charge_id: charge.value }), 
      ...(isBasic && { basic_value_type: rateValueType.value }), 
      ...(isFee && { fee_value_type: rateValueType.value }), 
      value: parseFloat(typeof rateValue.value === 'string' ? rateValue.value.replace(',', '.') : rateValue.value)
    };

    setProcessing(true);
    if (!!activeRate) {
      if (needToUpdate(activeRate, processingRate)) {
        updateRate(processingRate)
        .then(({ payload: { rate } }) => setSuccess('Rate successfully updated').then(() => onCancel(rate)))
        .catch((error) => setError(error))
        .finally(() => setProcessing(false));
      } else {
        setProcessing(false);
        onCancel();
      }
    } else {
      createRate({ variant_id: activeVariant?.id, rate: processingRate })
      .then(() => setSuccess('Rate successfully created').then(onCancel))
      .catch((error) => setError(error))
      .finally(() => setProcessing(false));
    }
  };
  const needToUpdate = (a,b) => 
    (isBasic ? a.basic_value_type !== b.basic_value_type : a.fee_value_type !== b.fee_value_type) || 
    a.value !== b.value;
  const handleDelete = () => {
    openConfirmationDialog({
      id: activeRate?.id,
      content: 'Are you sure you want to delete this rate?',
      type: DELETE_RATE,
      reason: reasons.RATE
    });
    onCancel();
  };

  return (
    <TableRow>
      <TableCell variant='tiny' sx={{ width: 240 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          { !!activeRate && <Tooltip arrow title='Delete'>
            <IconButton size='small' aria-label='delete' sx={{ mr: '6px' }} disabled={processing} onClick={handleDelete}>
              <DeleteIcon color='error' />
            </IconButton>
          </Tooltip> }
          <ChargesAutocomplete 
            id='charge' 
            disabled={!!activeRate}
            charge_type={chargeType}
            value={charge.formattedValue}
            error={!!charge.error}
            helperText={charge.error}
            FormHelperTextProps={{ sx: { mx: 0, fontSize: '8px' } }}
            onChange={({ value, formattedValue, error }) => setCharge({ value, formattedValue, error })}
          />
        </Box>
      </TableCell>
      <TableCell variant='tiny' align='right' sx={{ width: 180, px: '10px' }}>
        <TextField
          select
          size='tiny'
          sx={{ width : '80px' }}
          placeholder='Type'
          value={rateValueType.value}
          error={!!rateValueType.error}
          helperText={rateValueType.error}
          FormHelperTextProps={{ sx: { mx: 0, fontSize: '8px' } }}
          onChange={({ target: { value } }) => setRateValueType({ value, error: '' })}
        >
          { _map(isBasic ? filteredBasicTypes : rateFeeValueTypes, ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>) }
        </TextField>
      </TableCell>
      <TableCell variant='tiny' align='left' sx={{ width: 80 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <TextField
            size='tiny'
            sx={{ width : '60px' }}
            placeholder='Value'
            value={rateValue.value}
            error={!!rateValue.error}
            helperText={rateValue.error}
            FormHelperTextProps={{ sx: { mx: 0, fontSize: '8px', whiteSpace: 'nowrap' } }}
            onChange={({ target: { value } }) => setRateValue({ value, error: '' })}
          />
          <Tooltip arrow title='Save'>
            <IconButton size='small' aria-label='save' sx={{ mx: {xs: 0, md: 1}}} disabled={processing} onClick={handleSubmit}>
              <SaveIcon color='success' />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='Cancel'>
            <IconButton size='small' aria-label='Cancel' disabled={processing} onClick={onCancel}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default memo(Form);
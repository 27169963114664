import { memo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';
import _map from 'lodash/map';

const BaseFilter = ({ data, value, onChange, label }) => {

  return (
    <FormControl fullWidth>
      <InputLabel id="statuses-multiselect-label">{label}</InputLabel>
      <Select
        labelId="statuses-multiselect-label"
        id="statuses-multiselect"
        multiple
        label={label}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {_map(selected, s => <Chip key={s}  sx={{ textTransform: 'capitalize' }} label={s.replace(/_/g, ' ')} />)}
          </Box>
        )}
        endAdornment={
          <IconButton sx={{ right: 10 }} onClick={() => onChange([])}>
            <CloseRounded fontSize='small' />
          </IconButton>
        }
      >
        {_map(data, item => (
          <MenuItem key={item} value={item} sx={{ textTransform: 'capitalize' }}>{item.replace(/_/g, ' ')}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(BaseFilter);

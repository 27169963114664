import {
  getProfileRoutine,
  updateProfileRoutine,
  startDutyRoutine,
  stopDutyRoutine,
  updateCompanyRoutine,
  createLocationRoutine,
  updateLocationRoutine,
  deleteLocationRoutine,
  createWidgetRoutine,
  deleteWidgetRoutine,
  updateWidgetsRoutine
} from 'actions';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';

export const initialState = {
  user: {
    id: '',
    companies: [],
    address: null,
    latitude: null,
    longitude: null,
    previous_names: '',
    photo: null,
    roles: [],
    first_name: '',
    last_name: '',
    birth_at: '',
    email: '',
    phone: '',
    second_phone: '',
    emergency_phone: '',
    second_emergency_phone: '',
    shift_duration: null,
    duty_duration: null,
    duty_started_at: null,
    notifications: [],
    widgets: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getProfileRoutine.SUCCESS: {
      const { data: { user } } = action.payload;

      return { ...state, user: { ...state.user, ...user } };
    }
    case updateProfileRoutine.SUCCESS: {
      const { response: { status }, user } = action.payload;
      const updatedUser = { ...state.user, ...user, ...(!!user.photo && { photo: { id: user.photo, localPhoto: user.localPhoto } }) };

      return { ...state, ...(status === 204 && { user: updatedUser }) };
    }
    case startDutyRoutine.SUCCESS: {
      return state;
    }
    case stopDutyRoutine.SUCCESS: {
      return state;
    }
    case updateCompanyRoutine.SUCCESS: {
      const { response: { status }, company } = action.payload;
      const companies = _map(state.user.companies, (item) => item.id === company.id ? { ...item, ...company } : item);

      return { ...state, ...(status === 204 && { user: { ...state.user, companies } }) };
    }
    case createLocationRoutine.SUCCESS: {
      const { data: { location } } = action.payload;

      return { ...state, ...(!!location.company && { user: { ...state.user, companies: _map(state.user.companies, (c) => c.id === location.company?.id ? { ...c, locations: [...c.locations, ...[location]] } : c) } }) };
    }
    case updateLocationRoutine.SUCCESS: {
      const { response: { status }, location } = action.payload;

      return { ...state, ...(status === 204 && { user: { ...state.user, companies: _map(state.user.companies, (c) => c.id === location.company_id ? 
        { ...c, locations: _map(c.locations, (l) => l.id === location.id ? { ...l, ...location } : l) } : c) } }) 
      };
    }
    case deleteLocationRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, ...(status === 204 && { user: { ...state.user, companies: _map(state.user.companies, (c) => !!_find(c.locations, (l) => l.id === id) ? 
        { ...c, locations: _filter(c.locations, (l) => l.id !== id) } : c) } }) 
      };
    }
    case createWidgetRoutine.SUCCESS: {
      const { data: { widget } } = action.payload;

      return { ...state, user: { ...state.user, widgets: [...state.user.widgets, ...[widget]] } };
    }
    case deleteWidgetRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, ...(status === 204 && { user: { ...state.user, widgets: _filter(state.user.widgets, (w) => w.id !== id) } }) };
    }
    case updateWidgetsRoutine.SUCCESS: {
      const { response: { status }, widgets } = action.payload;

      return { ...state, ...(status === 204 && { user: { 
        ...state.user, 
        widgets: _map(state.user.widgets, (w) => !!_find(widgets, (widget) => widget.id === w.id) ? { ...w,  ..._find(widgets, (widget) => widget.id === w.id)} : w) 
      } }) };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
import { forwardRef, memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/ArrowBackIosRounded';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const BaseModalDetails = ({ children }) => {
  const navigate = useNavigate();
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  const handleBack = () => navigate(-1)

  if (!mediaDesktop) {
    return (
      <Dialog
        open={true}
        onClose={() => {}}
        TransitionComponent={Transition}
        fullScreen
        keepMounted
        PaperProps={{ sx: { bgcolor: { xs: 'text.primary', md: '#fff' } } }}
      >
        <DialogTitle sx={{ display: 'flex', gap: '16px', p: '8px 16px', bgcolor: 'text.primary',}}>
          <Button color='inherit' startIcon={<BackIcon />} sx={{ ml: '-4px', color: '#fff'}} onClick={handleBack}>Back</Button>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          {children}
        </DialogContent>
      </Dialog>
    )
  }

  return null;
};

export default memo(BaseModalDetails);
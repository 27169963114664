import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _pick from 'lodash/pick';
// Local Files
import { CardsContainer, DetailsContainer, DetailsWrapper } from 'components/Common/BasePageUi/BasePageUi';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import MapCard from 'components/Map/Card/Card';
import DocumentsCard from 'components/Documents/Card/Card';
import NotesCard from 'components/Notes/Card/Card';
import Main from '../Cards/Main/Main';
import DigitalBillingCard from '../Cards/DigitalBilling/DigitalBilling';
import ServicesCard from '../Cards/Services/Services';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import Placeholder from 'components/Common/Placeholder/Placeholder';
import useCustomSelector from 'hooks/useCustomSelector';
import useAccounts from 'hooks/useAccounts';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';
import { models } from 'helpers/constants';

const Details = () => {
  const { id } = useParams();
  const { setFetchingDetails, setAccountServiceData } = useApp();
  const { setError } = useError();
  const { getAccount } = useAccounts();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const fetchedAccount = useCustomSelector(state => state.accounts.account);

  const mainData = _pick(fetchedAccount, ['id', 'default', 'name', 'phone', 'company', 'email', 'fax', 'contact_name', 'contact_phone', 'contact_email', 'created_at']);
  const billingData = _pick(fetchedAccount, ['billing_email', 'second_billing_email', 'third_billing_email', 'insurance_accounts']);
  const notesData = { previous_notes: fetchedAccount.notes, id: fetchedAccount.id };
  const documentsData = { id: fetchedAccount.id, model: models.ACCOUNTS };

  useEffect(() => {
    if (!!id && id !== fetchedAccount.id) {
      setFetchingDetails(true);
      getAccount(id)
      .catch(error => setError(error))
      .finally(() => setFetchingDetails(false))
    }

    return () => setAccountServiceData({ activeVariant: null });
  }, [id, fetchedAccount.id, getAccount, setError, setFetchingDetails, setAccountServiceData]);

  if (!id) return <Placeholder model={models.ACCOUNTS} />;
  if (!!!fetchedAccount.id || isFetching) return <PageLoader />;
  return (
    <DetailsContainer>
      <DetailsWrapper>
        <Main {...mainData} />
        <CardsContainer>
          <DigitalBillingCard {...billingData} />
          <MapCard
            hideMap
            address={fetchedAccount.billing_address}
            secondaryLabel='Billing Address'
            sx={{ flex: '1 1 280px' }}
          />
          <NotesCard sx={{ maxWidth: { xs: 'unset', xlg: '280px' } }} hideTabs edit {...notesData} />
          <DocumentsCard sx={{ flex: '1 1 100%' }} {...documentsData} />
          <ComponentErrorWrapper children={<ServicesCard />} />
        </CardsContainer>
      </DetailsWrapper>
    </DetailsContainer>
  );
};

export default memo(Details);
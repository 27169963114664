import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getInvoices as getInvoicesAction,
  getInvoice as getInvoiceAction,
  getInvoiceForNotification as getInvoiceForNotificationAction,
  createInvoiceSignature as createInvoiceSignatureAction,
  updateInvoice as updateInvoiceAction,
  approveInvoice as approveInvoiceAction,
  unApproveInvoice as unApproveInvoiceAction,
  rejectInvoice as rejectInvoiceAction,
  sendInvoiceDetails as sendInvoiceDetailsAction,
  clearLocalInvoices as clearLocalInvoicesAction,
  clearLocalActiveInvoice as clearLocalActiveInvoiceAction,
  getInvoicesStatistics as getInvoicesStatisticsAction,
  clearInvoicesStatistics as clearInvoicesStatisticsAction
} from 'actions/invoices';

const useInvoices = () => {
  const dispatch = useDispatch();
  const getInvoices = useCallback(({ offset, orders, query, statuses }) => dispatch(getInvoicesAction({ offset, orders, query, statuses })), [dispatch]);
  const getInvoice = useCallback(id => dispatch(getInvoiceAction(id)), [dispatch]);
  const getInvoiceForNotification = useCallback(id => dispatch(getInvoiceForNotificationAction(id)), [dispatch]);
  const createInvoiceSignature = useCallback(({ id, blob_id }) => dispatch(createInvoiceSignatureAction({ id, blob_id })), [dispatch]);
  const updateInvoice = useCallback(invoice => dispatch(updateInvoiceAction(invoice)), [dispatch]);
  const approveInvoice = useCallback(id => dispatch(approveInvoiceAction(id)), [dispatch]);
  const unApproveInvoice = useCallback(id => dispatch(unApproveInvoiceAction(id)), [dispatch]);
  const rejectInvoice = useCallback(id => dispatch(rejectInvoiceAction(id)), [dispatch]);
  const sendInvoiceDetails = useCallback(({ id, hide_charges, recipient_ids, document_ids }) => dispatch(sendInvoiceDetailsAction({ id, hide_charges, recipient_ids, document_ids })), [dispatch]);
  const clearLocalInvoices = useCallback(() => dispatch(clearLocalInvoicesAction()), [dispatch]);
  const clearLocalActiveInvoice = useCallback(() => dispatch(clearLocalActiveInvoiceAction()), [dispatch]);
  const getInvoicesStatistics = useCallback(({ filterDate, reason, status, account_id }) => dispatch(getInvoicesStatisticsAction({ filterDate, reason, status, account_id })), [dispatch]);
  const clearInvoicesStatistics = useCallback(() => dispatch(clearInvoicesStatisticsAction()), [dispatch]);

  return {
    getInvoices,
    getInvoice,
    getInvoiceForNotification,
    createInvoiceSignature,
    updateInvoice,
    approveInvoice,
    unApproveInvoice,
    rejectInvoice,
    sendInvoiceDetails,
    clearLocalInvoices,
    clearLocalActiveInvoice,
    getInvoicesStatistics,
    clearInvoicesStatistics
  };
};

export default useInvoices;
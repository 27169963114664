import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'chart.js/auto';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import Check from '@mui/icons-material/Check';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import _map from 'lodash/map';
// Local files
import { Root, Head, Filter, Canvas, ListColumns, StyledList, StyledListItem, DateRange } from './MediumWidget.styled';
import { StyledSelect, StyledMenuItem } from 'components/Dashboard/Common/StyledSelect.styled';
import { getFormattedStartAt, getFormattedEndAt } from 'helpers';
import { widgetLabels, dateFilter } from 'helpers/constants';
import UsersAutocomplete from 'components/Users/Autocomplete/Autocomplete';
import useCustomSelector from 'hooks/useCustomSelector';
import usePayments from 'hooks/usePayments';
import useUsers from 'hooks/useUsers';
import useOrders from 'hooks/useOrders';
import useError from 'hooks/useError';

const today = new Date();
const dayMonthAgo = new Date((new Date()).setMonth(new Date().getMonth() - 1));

const MediumWidget = ({ widget, onMenuAnchor, setCompensationsReportParams }) => {
  const theme = useTheme();
  const { getUsersStatistics, clearLocalUsersStatistics } = useUsers();
  const { getPayments, clearLocalPayments } = usePayments();
  const { getOrdersStatistics } = useOrders();
  const { setError } = useError();
  const { data: usersStatistics, pagination: usersPagination } = useCustomSelector(state => state.users.statistics);
  const { data: paymentsStatistics, pagination: paymentsPagination } = useCustomSelector(state => state.payments.all);
  const companies = useCustomSelector(state => state.profile.user.companies);
  const [filterDate, setFilterDate] = useState({ name: '', value: dateFilter[dateFilter.length - 1] });
  const [userIds, setUserIds] = useState({ value: [], formattedValue: [], error: '' });
  const [chart, setChart] = useState(null);
  const [dateRange, setDateRange] = useState({
    value: { startDate: dayMonthAgo, endDate: today, key: 'selection' },
    formattedValue: { startAt: getFormattedStartAt(dayMonthAgo), endAt: getFormattedEndAt(today) },
    label: `${dayMonthAgo.getMonth()+1}/${dayMonthAgo.getDate()}/${dayMonthAgo.getFullYear()} - ${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`
  });
  const [companyId, setCompanyId] = useState(companies[0]?.id);
  const [processing, setProcessing] = useState(false);
  const isCashCollection = useMemo(() => widget.widget_type === 'cash_collection', [widget.widget_type]);
  const isDriversCommissions = useMemo(() => widget.widget_type === 'driver_commission', [widget.widget_type]);
  const isEta = useMemo(() => widget.widget_type === 'eta_accuracy', [widget.widget_type]);
  const data = useMemo(() => isDriversCommissions ? usersStatistics : paymentsStatistics, [isDriversCommissions, usersStatistics, paymentsStatistics]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));
  const renderPie = useCallback((eta_accuracy) => {
    const canvas = document.getElementById('statistics_orders');
    const ctx = canvas?.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: [
          'On time',
          'Within 5 minutes',
          'Within 15 minutes',
          '30+ minutes late',
          'No Data'
        ],
        datasets: [{
          label: 'ETA Accuracy',
          data: [eta_accuracy?.on_time, eta_accuracy?.within_5_minutes, eta_accuracy?.within_15_minutes, eta_accuracy?.late_30_plus_minutes, eta_accuracy?.without_data],
          backgroundColor: [
            'rgb(125, 205, 86)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(255, 125, 86)',
            'rgb(230, 50, 79)'
          ]
        }]
      }
    });

    setChart(chart);
    chart.render();
  }, []);
  const fetchUsersStatistics = useCallback((offset, date, user_ids) => {
    const { startAt, endAt } = date;
    setCompensationsReportParams({ company_id: companyId, start_at: startAt, end_at: endAt, user_ids });
    setProcessing(true);
    getUsersStatistics({ offset, company_id: companyId, start_at: startAt, end_at: endAt, user_ids })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => setProcessing(false))
    
  }, [getUsersStatistics, setCompensationsReportParams, companyId, setError]);
  const fetchPayments = useCallback((offset, filterDate) => {
    const { value: { start_at: created_at_gt, end_at: created_at_lt  }, name } = filterDate;
    
    if ((!!created_at_gt && name === 'cash_collection') || !!!created_at_gt){
      setProcessing(true);
      getPayments({ offset, created_at_gt, created_at_lt })
      .catch((error) => setError(error))
      .finally(() => setProcessing(false))
    }
  }, [getPayments, setError]);
  const fetchOrdersStatistics = useCallback(() => {
    const { value: { start_at, end_at }, name } = filterDate;

    if ((!!start_at && name === 'eta_accuracy') || !!!start_at){
      setProcessing(true);
      getOrdersStatistics({ start_at, end_at })
      .then(({ payload: { response: { data: { statistics : { eta_accuracy } } } } }) => {
        if(!!!chart){
          renderPie(eta_accuracy);
        } else {
          const data = [eta_accuracy?.on_time, eta_accuracy?.within_5_minutes, eta_accuracy?.within_15_minutes, eta_accuracy?.late_30_plus_minutes, eta_accuracy?.without_data]
          chart.data.datasets.forEach((dataset) => dataset.data.length = 0);
          chart.data.datasets.forEach((dataset) => data.forEach((item) => dataset.data.push(item)));
          chart.update();
        }
      })
      .finally(() => setProcessing(false))
    }
  }, [filterDate, getOrdersStatistics, renderPie, chart]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = (isCashCollection ? paymentsPagination : usersPagination);

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !processing) {
      isCashCollection ? fetchPayments(limit + offset, filterDate) : fetchUsersStatistics(limit + offset, dateRange.formattedValue, userIds.value);
    }
  };

  useEffect(() => {
    isCashCollection && clearLocalPayments().then(() => fetchPayments(0, filterDate));
  }, [filterDate, isCashCollection, fetchPayments, clearLocalPayments]);
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isDriversCommissions) {  
        clearLocalUsersStatistics().then(() => fetchUsersStatistics(0, dateRange.formattedValue, userIds.value));
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [dateRange, userIds.value, isDriversCommissions, fetchUsersStatistics, clearLocalUsersStatistics]);
  useEffect(() => {
    isEta && fetchOrdersStatistics();
  }, [fetchOrdersStatistics, isEta]);
  useEffect(() => {
    return () => setChart(null);
  }, []);

  return (
    <Root>
      <Head>
        <Typography variant='h5' sx={{ fontWeight: 700 }}>{widgetLabels[widget.widget_type]?.title}</Typography>
        <IconButton 
          color='primary' 
          size='small' 
          sx={{flexShrink: 0}} 
          aria-label='menu' 
          onClick={({ currentTarget }) => onMenuAnchor(currentTarget)}
          disabled={processing}
        >
          <MenuIcon />
        </IconButton>
      </Head>
      <Filter>
        {isDriversCommissions ?
          <Box style={{display: 'flex', flexDirection: 'column'}}>
            <Button 
              aria-describedby={id} 
              variant="outlined" 
              sx={{borderRadius: '8px', borderColor: '#e3e3ff', color: '#1B1636', '&:hover': { borderColor: '#1B1636' }}} 
              onClick={handleClick}
            >
              {dateRange.label}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <DateRange
                onChange={({ selection: { startDate, endDate, key } }) => {
                  setDateRange({ 
                    value: { startDate, endDate, key },
                    formattedValue: {
                      startAt: getFormattedStartAt(startDate),
                      endAt: getFormattedEndAt(endDate)
                    },
                    label: `${startDate.getMonth()+1}/${startDate.getDate()}/${startDate.getFullYear()} - ${endDate.getMonth()+1}/${endDate.getDate()}/${endDate.getFullYear()}`
                  });
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={lessThanMedium ? 1 : 2}
                ranges={[dateRange.value]}
                direction={lessThanMedium ? "vertical" : "horizontal"}
              />;
            </Popover>
            {companies.length > 1 && <TextField
              fullWidth
              select
              margin='dense'
              size='small'
              label='Select company'
              SelectProps={{
                MenuProps: {
                  PaperProps: { variant: 'outlined', sx: { marginTop: '4px', boxShadow: 'none !important' } }
                }
              }}
              sx={{ flexShrink: 0, width: 260 }}
              value={companyId}
              onChange={({ target: { value } }) => setCompanyId(value)}
            >
              {_map(companies, c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
            </TextField>}
            <UsersAutocomplete
              id='driver'
              size='small'
              role='driver'
              companyId={companyId}
              multiple
              margin='dense'
              label='Select drivers'
              value={userIds.formattedValue}
              error={!!userIds.error}
              helperText={userIds.error}
              onChange={({ value, formattedValue, error }) => setUserIds({ value, formattedValue, error })}
            />
          </Box> :
          <StyledSelect 
            name={widget.widget_type}
            variant='standard' 
            value={filterDate.value} 
            onChange={({ target: { name, value } }) => setFilterDate({ name, value })}
            disabled={processing}
            IconComponent={DownIcon}
            renderValue={(selected) => selected.label}
            sx={{ color: '#000000', backgroundColor: '#EBEBEB', borderRadius: 20, pl: 2, pt: '4px', pb: '4px' }}
          >
            { _map(dateFilter, (item) => <StyledMenuItem key={item.label} value={item}>
              { filterDate?.value?.label === item.label && <ListItemIcon>
                <Check color='primary' sx={{ width: 18, height: 'auto' }} />
              </ListItemIcon> }
              {item.label}
            </StyledMenuItem> )}
          </StyledSelect>}
      </Filter>
      {isEta ? <Canvas id='statistics_orders'>Your browser does not support the HTML5 canvas tag</Canvas> : <>
        <ListColumns>
          <Typography variant='body2' color='text.secondary'>{widgetLabels[widget.widget_type]?.titleLabel}</Typography>
          <Typography variant='body2' color='text.secondary'>{widgetLabels[widget.widget_type]?.valueLabel}</Typography>
        </ListColumns>
        <StyledList onScroll={handleScroll}>
          {_map(data, (item) => (
            <StyledListItem key={item.id}>
              <Typography variant='body1' sx={{ fontWeight: 700 }} noWrap>{`${item?.user?.first_name || item?.first_name || '-'} ${item?.user?.last_name || item?.last_name || ''}`}</Typography>
              <Typography variant='body1' sx={{ fontWeight: 700 }}>${item?.amount || item?.payout?.toFixed(2)}</Typography>
            </StyledListItem>
          ))}
        </StyledList>
      </>}
    </Root>
    
  );
};

export default memo(MediumWidget);
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { compensationsReport as compensationsReportAction } from 'actions/compensations';

const useCompensations = () => {
  const dispatch = useDispatch();
  const compensationsReport = useCallback(({ company_id, start_at, end_at, user_ids }) => dispatch(compensationsReportAction({ company_id, start_at, end_at, user_ids })), [dispatch]);

  return { compensationsReport };
};

export default useCompensations;
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getCompanyServicesRoutine,
  getFullListServicesRoutine,
  getListServicesRoutine,
  getServicesRoutine, 
  getServiceRoutine, 
  createServiceRoutine,
  createCompanyServiceRoutine,
  deleteCompanyServiceRoutine,
  clearLocalListServicesRoutine,
  clearLocalServicesRoutine,
  clearLocalCompanyServicesRoutine,
  clearLocalActiveServiceRoutine 
} from 'actions';
import _get from 'lodash/get';

const attributes = ['id', 'name', 'service_type'];

export const getCompanyServices = getThunkActionCreator(
  getCompanyServicesRoutine,
  async ({ orders = { created_at: 'desc' }, service_type = null, company_id }) => {
    getCompanyServices.getCompanyServicesCancel && getCompanyServices.getCompanyServicesCancel();
    const { token: access_token } = await sessionService.loadSession();
    let offset = 0;
    let servicesReady = false;
    let services = [];
    let cancelRequest = function executor(c) { getCompanyServices.getCompanyServicesCancel = c };

    while (!servicesReady) {
      getCompanyServices.getCompanyServicesCancel && getCompanyServices.getCompanyServicesCancel();
      const response = await axios.get(`/user/companies/${company_id}/services`,
        {
          params: { access_token, limit: 25, offset, orders, ...(!!service_type && { service_type }), attributes },
          cancelToken: new CancelToken(cancelRequest)
        }
      );
      const pagination = _get(response, 'data.pagination');
      const uploadedServices = _get(response, 'data.services');

      if (pagination.count + pagination.offset < pagination.total_count) {
        services = [...services, ...uploadedServices];
        offset += 25;
      } else {
        services = [...services, ...uploadedServices];
        servicesReady = true;
      }
    }
    return { services, service_type };
  }
);
export const getFullListServices = getThunkActionCreator(
  getFullListServicesRoutine,
  async ({ orders = { created_at: 'desc' }, service_type = null }) => {
    getFullListServices.getFullListServicesCancel && getFullListServices.getFullListServicesCancel();
    const { token: access_token } = await sessionService.loadSession();
    let offset = 0;
    let servicesReady = false;
    let services = [];
    let cancelRequest = function executor(c) { getFullListServices.getFullListServicesCancel = c };

    while (!servicesReady) {
      getFullListServices.getFullListServicesCancel && getFullListServices.getFullListServicesCancel();
      const response = await axios.get(`/user/services`,
        {
          params: { access_token, limit: 25, offset, orders, ...(!!service_type && { service_type }), attributes },
          cancelToken: new CancelToken(cancelRequest)
        }
      );
      const pagination = _get(response, 'data.pagination');
      const uploadedServices = _get(response, 'data.services');

      if (pagination.count + pagination.offset < pagination.total_count) {
        services = [...services, ...uploadedServices];
        offset += 25;
      } else {
        services = [...services, ...uploadedServices];
        servicesReady = true;
      }
    }
    return { services, service_type };
  }
);
export const getListServices = getThunkActionCreator(
  getListServicesRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, service_type = null, query = null }) => {
    getListServices.getListServicesCancel && getListServices.getListServicesCancel();
    const { token: access_token } = await sessionService.loadSession();
    const response =  await axios.get(`/user/services`,
      {
        params: { access_token, limit: 25, offset, orders, ...(!!service_type && { service_type }), ...(!!query && { query }), attributes },
        cancelToken: new CancelToken(function executor(cancel) { getListServices.getListServicesCancel = cancel })
      }
    );

    return { response, service_type };
  }
);
export const getServices = getThunkActionCreator(
  getServicesRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, query = null, side }) => {
    getServices.getServicesCancel && getServices.getServicesCancel();
    let access_token = null;
    const customer_token = localStorage.getItem('customer_token');

    if (!!!customer_token) {
      access_token = await sessionService.loadSession();
    }

    return await axios.get(`/${side}/services`,
      {
        params: { ...(!!access_token ? { access_token: access_token?.token } : { customer_token }), limit: 25, offset, orders, ...(query && { query }), attributes },
        cancelToken: new CancelToken(function executor(cancel) { getServices.getServicesCancel = cancel })
      }
    );
  }
);
export const getService = getThunkActionCreator(
  getServiceRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/services/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getService.getServiceCancel = cancel })
      }
    );
  }
);
export const createService = getThunkActionCreator(
  createServiceRoutine,
  async (service) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/services`, { access_token, service, attributes });
  }
);
export const createCompanyService = getThunkActionCreator(
  createCompanyServiceRoutine,
  async ({ company_id, id }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/companies/${company_id}/services/${id}`, { access_token });

    return { response, id };
  }
);
export const deleteCompanyService = getThunkActionCreator(
  deleteCompanyServiceRoutine,
  async ({ company_id, id }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/companies/${company_id}/services/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalListServices = getThunkActionCreator(clearLocalListServicesRoutine, async () => {});
export const clearLocalServices = getThunkActionCreator(clearLocalServicesRoutine, async () => {});
export const clearLocalCompanyServices = getThunkActionCreator(clearLocalCompanyServicesRoutine, async () => {});
export const clearLocalActiveService = getThunkActionCreator(clearLocalActiveServiceRoutine, async () => {});
import { memo, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import BaseListPage from 'components/Common/BaseListPage/BaseListPage';
import List from 'components/Users/List/List';
import Details from 'components/Users/Details/Details';
import Drawer from 'components/Users/Drawer/Drawer';
import Filter from 'components/Common/Filter/Filter';
import { reasons, userFieldsTypes } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useUsers from 'hooks/useUsers';

const Drivers = () => {
  const navigate = useNavigate();
  const { openDrawer } = useApp();
  const { clearLocalActiveUser } = useUsers();
  const [query, setQuery] = useState('');
  const [fetching, setFetching] = useState(false);

  const handleQueryChange = (value) => setQuery(value);
  const handleAddClick = () => { 
    navigate('/company/drivers'); 
    clearLocalActiveUser().then(() => openDrawer({ reason: reasons.DRIVER, edit: false, fieldsType: userFieldsTypes.FULL }));
  };

  useEffect(() => {
    return clearLocalActiveUser;
  }, [clearLocalActiveUser]);

  return (
    <>
      <BaseListPage
        renderFilter={
          <Filter
            query={query}
            onQueryChange={handleQueryChange}
            onAddClick={handleAddClick}
          />
        }
        listLoading={fetching}
        listComponent={
          <ComponentErrorWrapper 
            children={
              <List
                fetching={fetching}
                query={query}
                onFetching={v => setFetching(v)}
              />
            } 
          />
        }
        renderMainAtDesktop={<ComponentErrorWrapper children={<Details />} />}
      />
      <ComponentErrorWrapper children={<Drawer />} />
      <Outlet />
    </>
  );
};

export default memo(Drivers);
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';

export const Card = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    borderRadius:'25px'
  });  
export const Heading = styled('h2')({
    margin: '1.5rem 0 0 0',
  });
export const Description = styled('p')({
    margin:'0.3rem 0 0 0',
  });
import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  createRecipientsRoutine,
  updateRecipientsRoutine,
  deleteRecipientRoutine
} from 'actions';

const attributes = ['id', 'delivery_method', 'name', 'phone', 'email'];

export const createRecipients = getThunkActionCreator(
  createRecipientsRoutine,
  async ({ id, model, recipients }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/${model}s/${id}/recipients/batch`, { access_token, recipients, attributes });

    return { response, recipients };
  }
);
export const updateRecipients = getThunkActionCreator(
  updateRecipientsRoutine,
  async (recipients) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/recipients/batch`, { access_token, recipients });

    return { response, recipients };
  }
);
export const deleteRecipient = getThunkActionCreator(
  deleteRecipientRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/recipients/${id}`, { params: { access_token } });

    return { response, id };
  }
);
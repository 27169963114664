import { memo, useEffect, useCallback, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet } from 'react-router-dom';
import _filter from 'lodash/filter';
// Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import BaseFilter from 'components/Common/BaseFilter/BaseFilter';
import BaseListPage from 'components/Common/BaseListPage/BaseListPage';
import InvoicesList from 'components/Invoices/List/List';
import Details from 'components/Invoices/Details/Details';
import Filter from 'components/Common/Filter/Filter';
import Stats from 'components/Invoices/Stats/Stats';
import useInvoices from 'hooks/useInvoices';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';
import useProfile from 'hooks/useProfile';
import { invoiceStatuses } from 'helpers/constants';

const Invoices = () => {
  const { setFilter } = useApp();
  const { clearLocalActiveInvoice } = useInvoices();
  const { updateProfile } = useProfile();
  const { getInvoicesStatistics, clearInvoicesStatistics } = useInvoices();
  const { setError } = useError();
  const { open } = useCustomSelector(state => state.app.filter);
  const filteredStatuses = useCustomSelector(state => state.profile.user.filtered_invoice_statuses);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [query, setQuery] = useState('');
  const [statuses, setStatuses] = useState(filteredStatuses);
  const [fetching, setFetching] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleQueryChange = (value) => setQuery(value);
  const handleFilterChange = () => { 
    setFilter(!open);
    setStatuses([]);
  };
  const handleChangeStatuses = (value) => {
    setProcessing(true);
    updateProfile({ filtered_invoice_statuses: value })
    .then(() => setStatuses(value))
    .catch(error => setError(error))
    .finally(() => setProcessing(false));
  };
  const fetchStatistics = useCallback(() => {
    setProcessing(true);
    getInvoicesStatistics({})
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  }, [getInvoicesStatistics, setError]);

  useEffect(() => {
    mediaDesktop && fetchStatistics();

    return () => {
      clearLocalActiveInvoice();
      clearInvoicesStatistics()
    };
  }, [mediaDesktop, fetchStatistics, clearInvoicesStatistics, clearLocalActiveInvoice]);

  return (
    <>
      {mediaDesktop && <ComponentErrorWrapper children={<Stats onStatuses={setStatuses} />} /> }
      <BaseListPage
        renderFilter={
          <Filter
            query={query}
            onQueryChange={handleQueryChange}
            onFilterClick={handleFilterChange}
            statusesCount={filteredStatuses.length} 
            filterContent={
              <BaseFilter 
                label='Statuses'
                value={filteredStatuses} 
                onChange={handleChangeStatuses} 
                data={_filter(invoiceStatuses, (s) => s !== invoiceStatuses.NEW)} 
              />
            }
          />
        }
        listLoading={fetching || processing}
        listComponent={
          <ComponentErrorWrapper 
            children={
              <InvoicesList
                query={query}
                statuses={statuses}
                fetching={fetching}
                onFetching={v => setFetching(v)}
              />
            } 
          />
        }
        renderMainAtDesktop={<ComponentErrorWrapper children={<Details />} />}
      />
      <Outlet />
    </>
  );
};

export default memo(Invoices);
import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useTypes from 'hooks/useTypes';

const TypesAutocomplete = ({ value, onChange, error, helperText }) => {
  const { getListTypes, clearLocalListTypes } = useTypes();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.types.list);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (_, formattedValue) => onChange({ value: formattedValue.id, formattedValue, error: '' });
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchTypes = useCallback((offset, query) => {
    setLoading(true);
    getListTypes({ offset, query })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListTypes, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchTypes(limit + offset, query);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalListTypes().then(() => fetchTypes(0, query)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [fetchTypes, clearLocalListTypes, query]); 

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      id="typesList"
      options={data}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={o => o.name}
      onInputChange={(_, value, reason) => reason !== 'reset' && setQuery(value)}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      fullWidth
      freeSolo
      renderInput={params => <TextField {...params} margin='dense' label='Select a type' error={error} helperText={helperText} />}
    />
  );
};

export default memo(TypesAutocomplete);
import { memo } from 'react';
//Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import Form from './Form/Form';
import HistoryCard from './HistoryCard/HistoryCard';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import { reasons, invoiceStatuses } from 'helpers/constants';

const Drawer = ({ id, payments, account, customer, status, paid_amount, total_amount, authorization_number, company_location }) => {
  const { closeDrawer } = useApp();
  const { open } = useCustomSelector(state => state.app.payment);
  const showForm = status === invoiceStatuses.APPROVED || status === invoiceStatuses.PARTIALLY_PAID;
  const showPaymentsHistory = status === invoiceStatuses.PAID;
  const paymentsData = { invoice_id: id, payments, account, customer, status, paid_amount, total_amount, authorization_number, company_location };
  const paymentsHistoryData = { payments, paid_amount, total_amount, company_location };

  return (
    <BaseDrawer
      grey
      open={open}
      disabled={!open}
      onClose={() => closeDrawer(reasons.PAYMENT)}
      title='Payment Details'
      content={
        <>
          { showForm && <Form {...paymentsData} />}
          { showPaymentsHistory && <HistoryCard {...paymentsHistoryData} /> }
        </>
      }
    />
  );
};

export default memo(Drawer);

import { memo, useEffect, useCallback } from 'react';
import axios from 'axios';
import _map from 'lodash/map';
import List from '@mui/material/List';
import { useParams } from 'react-router-dom';
// Local files
import BaseListItem from 'components/Common/BaseListItem/BaseListItem';
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import useCustomSelector from 'hooks/useCustomSelector';
import useAccounts from 'hooks/useAccounts';
import useError from 'hooks/useError';

const AccountsList = ({ query, fetching, onFetching, types }) => {
  const { id: accountId } = useParams();
  const { getAccounts, clearLocalAccounts } = useAccounts();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const { data, pagination } = useCustomSelector(state => state.accounts.all)
  const { setError } = useError();

  const fetchAccounts = useCallback((offset, query, types) => {
    onFetching(true);
    getAccounts({ offset, query, account_types: types })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => onFetching(false));
  }, [getAccounts, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalAccounts().then(() => fetchAccounts(0, query, types)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [fetchAccounts, clearLocalAccounts, query, types]);

  return (
    <BaseScrollableContainer {...pagination} onLoad={offset => !fetching && fetchAccounts(offset, query, types)}>
      <List>
        {_map(data, ({ id, name, company }) => (
          <BaseListItem
            key={id}
            title={`${name} - ${company?.name}`}
            selected={id === accountId}
            to={`/accounts/${id}`}
            disabled={isFetching}
          />
        ))}
      </List>
    </BaseScrollableContainer>
  );
};

export default memo(AccountsList);
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useServices from 'hooks/useServices';
import { serviceTypes } from 'helpers/constants';

const CompanyServices = ({ company_id, type = null, label= '', onServicesReady = null, onChargesReady = null }) => {
  const { getFullListServices, getCompanyServices, createCompanyService, deleteCompanyService } = useServices();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { list, data } = useCustomSelector(state => state.services.company);
  const [loading, setLoading] = useState(false);
  const values = useMemo(() => type === serviceTypes.BASIC ? { options: list.basic, value: data.basic } : { options: list.additional, value: data.additional }, [list, data, type]);

  const handleChange = (_, formattedValue) => {
    setLoading(true);
    (formattedValue.length > values.value.length ? 
    createCompanyService({ id: formattedValue[formattedValue.length - 1]?.id, company_id })
    .then(() => setSuccess('Service successfully added')) :
    deleteCompanyService({ id: _find(values.value, (item) => !_includes(formattedValue, item))?.id, company_id })
    .then(() => setSuccess('Service successfully deleted')))
    .catch(e => setError(e))
    .finally(() => setLoading(false))
  };
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchServices = useCallback((service_type, company_id) => {
    setLoading(true);
    getFullListServices({ service_type })
    .then(() => 
      getCompanyServices({ service_type, company_id })
      .then(() => {
        !!onServicesReady && onServicesReady(true);
        !!onChargesReady && onChargesReady(true);
      })
      .catch(e => setError(e))
      .finally(() => setLoading(false))
    )
    .catch(e => setError(e).then(() => setLoading(false)));
  }, [getFullListServices, getCompanyServices, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchServices(type, company_id);
  }, [type, company_id, fetchServices]); 

  return (
    <Autocomplete
      value={values.value}
      multiple
      onChange={handleChange}
      id="company-services-list"
      options={values.options}
      getOptionLabel={o => o.name}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable
      handleHomeEndKeys
      fullWidth
      loading={loading}
      renderInput={params => <TextField {...params} label={label} disabled={loading} margin='normal' />}
    />
  );
};

export default memo(CompanyServices);
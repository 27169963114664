import Chart from 'chart.js/auto';
// Local files
import { Canvas } from './Stats.styled';
import BaseStats from 'components/Common/BaseStats/BaseStats';
import BaseStatsCard from 'components/Common/BaseStatsCard/BaseStatsCard';
import useCustomSelector from 'hooks/useCustomSelector';
import { useCallback, useEffect } from 'react';
import { vehicleStatuses } from 'helpers/constants';

const { STORED, RELEASED, LAID } = vehicleStatuses;

const Stats = ({ onStatuses }) => {
  const { 
    stored_vehicles_count, 
    laid_vehicles_count, 
    released_vehicles_count, 
    over_time_vehicles_count, 
    over_time_vehicles_data 
  } = useCustomSelector(state => state.vehicles.statistics);

  const chart = useCallback((ctx) => new Chart(ctx, {
    type: 'line',
    data: {
       labels: over_time_vehicles_data,
       datasets: [{
          label: '',
          yAxisID: 'yAxis',
          xAxisID: 'xAxis',
          data: over_time_vehicles_data,
          fill: false,
          borderColor: '#512DA8',
          borderWidth: 3,
          pointRadius: 0
       }]
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins:{
        legend: {
          display: false
        }
      },
      scales: {
        yAxis: {
          display: false,
          grid: { display: false, drawTicks: false }
          
        },
        xAxis: {
          display: false,
          grid: { display: false, drawTicks: false }
        }
      }
    }
 }), [over_time_vehicles_data]);

  useEffect(() => {
    const canvas = document.getElementById('statistics_graph');
    const ctx = canvas?.getContext('2d');

    chart(ctx);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseStats clear={() => onStatuses([])}>
      <BaseStatsCard onClick={() => onStatuses([STORED])} title='Stored Vehicles' firstValue={stored_vehicles_count} firstLabel='Vehicles' />
      <BaseStatsCard onClick={() => onStatuses([LAID])} title='Liened Vehicles' firstValue={laid_vehicles_count} firstLabel='Vehicles' />
      <BaseStatsCard onClick={() => onStatuses([RELEASED])} title='Released Vehicles' firstValue={released_vehicles_count} firstLabel='Vehicles'  />
      <BaseStatsCard
        title='Storage Over Time'
        firstValue={over_time_vehicles_count}
        firstLabel='Vehicles'
        content={<Canvas id='statistics_graph'>Your browser does not support the HTML5 canvas tag</Canvas>}
      />
    </BaseStats>
  );
};

export default Stats;
import {
  getInvoicesRoutine,
  getInvoiceRoutine,
  getInvoiceForNotificationRoutine,
  updateInvoiceRoutine,
  approveInvoiceRoutine,
  unApproveInvoiceRoutine,
  rejectInvoiceRoutine,
  clearLocalInvoicesRoutine,
  clearLocalActiveInvoiceRoutine,
  getInvoicesStatisticsRoutine,
  clearInvoicesStatisticsRoutine,
  suggestInvoiceExpensesRoutine,
  filterExpencesDataRoutine,
  createExpenseRoutine,
  updateExpenseRoutine,
  deleteExpenseRoutine,
  createPaymentRoutine,
  createRecipientsRoutine,
  updateRecipientsRoutine,
  deleteRecipientRoutine,
  createCardRoutine,
  storeVehicleRoutine
} from 'actions';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _mergeWith from 'lodash/mergeWith';
import _startsWith from 'lodash/startsWith';
import { getRandomString, getExpenseTotal } from 'helpers';
import { invoiceStatuses, vehicleStatuses } from 'helpers/constants';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  statistics: {
    total_invoices_count: 0,
    total_invoices_amount: 0,
    average_invoices_amount: 0,
    monthly_change: 0,
    partially_paid_invoices_count: 0,
    partially_paid_invoices_amount: 0, 
    approved_invoices_count: 0, 
    approved_invoices_amount: 0,
    paid_invoices_count: 0,
    paid_invoices_amount: 0
  },
  invoice: { 
    id: '', 
    number: '', 
    status: '', 
    order: {
      id: '',
      account: null,
      customer: null,
      vehicle: null,
      company: null,
      dispatcher: null,
      user: null,
      number: '',
      notes: '',
      status: '',
      incident_location: null,
      destination_location: null,
      call_back_phone: '',
      required_acknowledge_time: null,
      eta: 0,
      priority: '',
      urgency_level: '',
      service_comments: '',
      equipment_type: '',
      price: 0,
      job_offer_amount: 0,
      authorization_number: '',
      services: [],
      recipients: [],
      truck: null
    }, 
    expenses: [],
    payments: [],
    total_amount: 0,
    paid_amount: 0,
    minimum_amount: 0,
    file: null 
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getInvoicesRoutine.SUCCESS: {
      const { data: { invoices, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...invoices], pagination } };
    }
    case getInvoiceRoutine.SUCCESS: {
      const { data: { invoice } } = action.payload;

      return { ...state, invoice };
    }
    case getInvoiceForNotificationRoutine.SUCCESS: {
      const { data: { invoice } } = action.payload;
      const updatedInvoice = _mergeWith({ ...state.invoice }, _omit(invoice, ['order']), (a, b) => b !== a ? b : a);
      const filteredInvoice = { 
        ...updatedInvoice, 
        expenses: !!_find(state.invoice.expenses, (e) => _startsWith(e.id, 'new_')) ? state.invoice.expenses : updatedInvoice.expenses 
      };
      const foundInvoice = _find(state.all.data, i => i.id === invoice.id);
      const data = !!foundInvoice ? _map(state.all.data, i => i.id === invoice.id ? { ...i, ...invoice } : i) : 
        invoice.status !== invoiceStatuses.NEW ? [invoice, ...state.all.data] : state.all.data;
      const pagination = !!foundInvoice
        ? state.all.pagination : invoice.status !== invoiceStatuses.NEW ? 
        { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } : state.all.pagination;

      return { ...state, ...(state.invoice.id === invoice.id && { invoice: filteredInvoice }), all: { data, pagination } };
    }
    case updateInvoiceRoutine.SUCCESS: {
      const { response: { status }, invoice } = action.payload;
      const updatedInvoice = { ...state.invoice, ...invoice };
      const data = _map(state.all.data, (item) => item.id === invoice.id ? { ...item, ...invoice } : item);

      return { ...state, ...(status === 204 && { invoice: updatedInvoice }), all: { ...state.all, data } };
    }
    case approveInvoiceRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && { 
          all: { ...state.all, data: _map(state.all.data, (item) => item.id === id ? { ...item, status: invoiceStatuses.APPROVED } : item) },
          ...(state.invoice.id === id && { invoice: { ...state.invoice, status: invoiceStatuses.APPROVED } })
        }) 
      };
    }
    case unApproveInvoiceRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && { 
          all: { ...state.all, data: _map(state.all.data, (item) => item.id === id ? { ...item, status: invoiceStatuses.NOT_SUBMITTED } : item) },
          ...(state.invoice.id === id && { invoice: { ...state.invoice, status: invoiceStatuses.NOT_SUBMITTED } })
        }) 
      };
    }
    case rejectInvoiceRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && { 
          all: { ...state.all, data: _map(state.all.data, (item) => item.id === id ? { ...item, status: invoiceStatuses.REJECTED } : item) },
          ...(state.invoice.id === id && { invoice: { ...state.invoice, status: invoiceStatuses.REJECTED } })
        }) 
      };
    }
    case clearLocalInvoicesRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveInvoiceRoutine.SUCCESS: {
      return { ...state, invoice: initialState.invoice }
    }
    case getInvoicesStatisticsRoutine.SUCCESS: {
      const { response: { data: { statistics } }, reason } = action.payload;

      return { ...state, 
        ...(!!!reason && { statistics }),
        ...(reason === 'invoice_overview' && { statistics: { ...state.statistics, total_invoices_count: statistics.total_invoices_count, total_invoices_amount: statistics.total_invoices_amount } }),
        ...(reason === 'average_invoice' && { statistics: { ...state.statistics, average_invoices_amount: statistics.average_invoices_amount, monthly_change: statistics.monthly_change } }),
        ...(reason === 'unpaid_invoice' && { statistics: { ...state.statistics, approved_invoices_count: statistics.approved_invoices_count, approved_invoices_amount: statistics.approved_invoices_amount } }),
      };
    }
    case clearInvoicesStatisticsRoutine.SUCCESS: {
      return { ...state, statistics: initialState.statistics }
    }
    case suggestInvoiceExpensesRoutine.SUCCESS: {
      const { response: { data: { expenses } }, expense_name } = action.payload;
      const data = !!expenses.length ? _map(expenses, (expense) => ({ ...expense, id: `new_${expense?.rate?.id || expense?.default_charge?.id}` })) :
      [{ id: `new_${getRandomString(24)}`, rate: null, default_charge: null, name: expense_name, unit_price: null, quantity: 1, hourly_charge_duration: 'PT0H0M' }];
      
      return { ...state, ...(!!state.invoice.id && { invoice: { ...state.invoice, expenses: [...state.invoice?.expenses, ...data] } }) }
    }
    case filterExpencesDataRoutine.SUCCESS: {
      const { expense_id } = action.payload;
      const foundedExpense = !!_find(state.invoice?.expenses, (expense) => expense?.id === expense_id);
      const expenses = _filter(state.invoice?.expenses, (expense) => expense?.id !== expense_id);

      return { ...state, ...(foundedExpense && { invoice: { ...state.invoice, expenses } }) }
    }
    case createExpenseRoutine.SUCCESS: {
      const { response: { data: { expense } }, invoice_id } = action.payload;

      return { ...state, 
        ...(state.invoice.id === invoice_id && { 
          invoice: { 
            ...state.invoice, 
            expenses: [...state.invoice?.expenses, ...[expense]],
            total_amount: state.invoice.total_amount + getExpenseTotal(expense)
          } 
        })
      }
    }
    case updateExpenseRoutine.SUCCESS: {
      const { response: { status }, expense } = action.payload;

      return { ...state, ...(status === 204 && !!_find(state.invoice.expenses, (e) => e.id === expense.id) &&
        { invoice: { ...state.invoice, expenses: _map(state.invoice.expenses, (e) => e.id === expense.id ? { ...e, ...expense } : e) } })
      };
    }
    case deleteExpenseRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && _find(state.invoice?.expenses, (expense) => expense?.id === id) &&
          { invoice: { ...state.invoice, expenses: _filter(state.invoice?.expenses, (expense) => expense?.id !== id) }
        })
      }
    }
    case createPaymentRoutine.SUCCESS: {
      const { response: { data: { payment } }, invoice_id } = action.payload;
      const updatedInvoice = { 
        ...state.invoice, 
        ...(state.invoice.status === invoiceStatuses.APPROVED && { status: invoiceStatuses.PARTIALLY_PAID }),
        ...(state.invoice.total_amount <= state.invoice.paid_amount + payment.amount && { status: invoiceStatuses.PAID }),
        payments: [...state.invoice?.payments, ...[payment]],
        paid_amount: state.invoice.paid_amount + payment.amount
      } 

      return { ...state, 
        ...(state.invoice.id === invoice_id && { 
          invoice: updatedInvoice,
          all: { ...state.all, data: _map(state.all.data, (i) => i.id === invoice_id ? updatedInvoice : i) }
        })
      }
    }
    case createRecipientsRoutine.SUCCESS: {
      const { response: { status }, recipients } = action.payload;

      return { ...state, 
        ...(status === 204 && !!state.invoice.id && { invoice: { ...state.invoice, recipients: [...state.invoice?.recipients, ...recipients] } 
        })
      }
    }
    case updateRecipientsRoutine.SUCCESS: {
      const { response: { status }, recipients } = action.payload;

      return { ...state, 
        ...(status === 204 && { invoice: { ...state.invoice, recipients } 
        })
      }
    }
    case deleteRecipientRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && _find(state.invoice?.recipients, (recipient) => recipient?.id === id) && 
          { invoice: { ...state.invoice, recipients: _filter(state.invoice?.recipients, (recipient) => recipient?.id !== id) } 
        })
      }
    }
    case createCardRoutine.SUCCESS: {
      const { response: { data: { card } }, customer_id } = action.payload;

      return { ...state, 
        ...(state.invoice.order.customer?.id === customer_id && { 
          invoice: {
            ...state.invoice,
            order: { 
              ...state.invoice.order, 
              customer: { 
                ...state.invoice.order.customer,
                cards: [...[card], ...state.invoice.order.customer?.cards]
              } 
            } 
          }
        })
      }
    }
    case storeVehicleRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, 
        ...(status === 204 && state.invoice.order.vehicle?.id === id && { 
          invoice: {
            ...state.invoice,
            order: { 
              ...state.invoice.order, 
              vehicle: { 
                ...state.invoice.order.vehicle,
                status: vehicleStatuses.STORED
              } 
            } 
          }
        })
      }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
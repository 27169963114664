import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
//Local files
import { Card, CardHeader, CardTitle } from 'components/Common/BasePageUi/BasePageUi';
import { DocumentsContainer, Input } from './Card.styled';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import Skeleton from '../Skeleton/Skeleton';
import DropContainer from 'components/Common/DropContainer/DropContainer';
import FilePreview from 'components/Common/FilePreview/FilePreview';
import EmptyPlaceholder from './EmptyPlaceholder/EmptyPlaceholder';
import Filter from './Filter/Filter';
import useDocuments from 'hooks/useDocuments';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useDialogs from 'hooks/useDialogs';
import useOrders from 'hooks/useOrders';
import useRole from 'hooks/useRole';
import { DELETE_DOCUMENT } from 'helpers/confirmations';
import { reasons } from 'helpers/constants';
import { getRepresentation } from 'helpers';

const DocumentsCard = ({ isCreate = false, id, model, sx }) => {
  const inputRef = useRef(null);
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { getDocuments, clearLocalDocuments } = useDocuments();
  const { openDocumentsDialog, openConfirmationDialog } = useDialogs();
  const { setLocalOrderFields } = useOrders();
  const { isManager, isOwner } = useRole();
  const { setError } = useError();
  const { open: isOrderDrawer } = useCustomSelector(state => state.app.order);
  const data = useCustomSelector(state => state.documents.all.data);
  const localDocuments = useCustomSelector(state => state.orders.order.localDocuments);
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const [categoryId, setCategoryId] = useState('');
  const [files, setFiles] = useState([]);
  const [processableFiles, setProcessableFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const documents = useMemo(() => isOrderDrawer ? localDocuments : data, [isOrderDrawer, localDocuments, data]);
  const isCanEdit = isManager || isOwner || model === 'orders';

  const fetchDocuments = useCallback((id, model, offset) => {
    setProcessing(true);
    getDocuments({ id, model, offset, category_ids: [categoryId] })
    .then(({ payload: { data: { documents } } }) => isOrderDrawer && setLocalOrderFields({ localDocuments: documents }))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  }, [categoryId, isOrderDrawer, getDocuments, setLocalOrderFields, setError]);
  const handleFilter = (id) => setCategoryId(prev => !!prev ? '' : id);
  const handleUnfilter = () => setCategoryId('');
  const handleUploadClick = () => inputRef.current.click();
  const handleFilesChange = (file) => { 
    setProcessing(true);
    processFiles([file], ({ file, checksum, localUrl }) => 
      processBlob({ file, checksum }, blob => 
        setFiles(prev => [...prev, { id: blob.id, filename: blob.filename, content_type: blob.content_type, localUrl, category: '' }])
      )
    );
  };
  const handleDeleteDocument = (id) => {
    isOrderDrawer ? setLocalOrderFields({ localDocuments: _map(localDocuments, (d) => d.id === id ? { ...d, _destroy: true } : d) }) :
    openConfirmationDialog({
      id,
      content: 'Are you sure you want to delete this document?',
      type: DELETE_DOCUMENT,
      reason: reasons.DOCUMENT
    });
  };

  useEffect(() => {
    !!id && !isCreate && !isFetching && clearLocalDocuments().then(() => fetchDocuments(id, model, 0));
  }, [id, model, isCreate, isFetching, fetchDocuments, clearLocalDocuments]);
  useEffect(() => {
    if (!!processableFiles.length) _forEach(processableFiles, (file) => handleFilesChange(file));

  }, [processableFiles]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!!processableFiles.length && files.length === processableFiles.length) {
      setProcessing(false);
      openDocumentsDialog({ documents: files, isOrderDrawer })
      .then(() => {
        setProcessableFiles([]);
        setFiles([]);
      })
    } 
  }, [processableFiles, openDocumentsDialog, files, isOrderDrawer]);

  return (
    <>
      <Card size='medium' sx={{ alignSelf: 'flex-start', pb: 0, ...sx }}>
        <CardHeader sx={{ justifyContent: 'space-between'}}>
          <CardTitle>Documents</CardTitle>
          { isCanEdit && <Button color='primary' startIcon={<AddIcon />} disabled={processing} onClick={handleUploadClick}>UPLOAD</Button> }
        </CardHeader>
        {!!id && !isOrderDrawer && 
        <ComponentErrorWrapper 
          children={
            <Filter 
              id={id}
              categoryId={categoryId} 
              disabled={processing} 
              model={model}
              filterCategories={handleFilter} 
              unfilterCategories={handleUnfilter} 
            />
          } 
        />
        }
        <Input
          ref={inputRef}
          accept="image/*,.pdf"
          id="documents"
          type="file"
          multiple
          onClick={(event) => event.target.value = ''}
          onChange={({ target: { files } }) => setProcessableFiles(files)}
        />
        <DropContainer sx={{ flexGrow: 1 }} onDrop={(files) => isCanEdit && setProcessableFiles(files)}>
          {!!!documents?.length && !processing ? <EmptyPlaceholder />
          : (
            <DocumentsContainer>
              { processing && <Skeleton /> }
              {_map(documents, ({ id, category, file, _destroy, location }) => !_destroy && (
                <FilePreview
                  key={id}
                  type={file?.content_type}
                  preview={file?.localUrl || getRepresentation(file?.representations, 'small')}
                  onDelete={() => isCanEdit && handleDeleteDocument(id)}
                  name={file?.filename}
                  url={file?.url}
                  label={category?.name}
                  address={location?.address}
                />
              ))}
            </DocumentsContainer>
          )}
        </DropContainer>
      </Card>
    </>
  );
};

export default memo(DocumentsCard);
import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//Local files
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';

const EmptyPlaceholder = () => {
  return (
    <Box sx={{
      width: '100%',
      minHeight: 140,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '16px',
    }}>
      <FolderIcon style={{ color: '#9F9DAB' }} />
      <Typography variant='body2' align='center' color='textSecondary'>
        No documents yet.<br/>
        Drag and drop here to add them.
      </Typography>
    </Box>
  );
};

export default memo(EmptyPlaceholder);
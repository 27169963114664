import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  openAppDrawerRoutine,
  closeAppDrawerRoutine,
  setAppFilterRoutine,
  startRemovingRoutine,
  stopRemovingRoutine,
  setAccountServiceDataRoutine,
  filterExpencesDataRoutine,
  setFetchingDetailsRoutine
} from 'actions';

export const openAppDrawer = getThunkActionCreator(openAppDrawerRoutine, async ({ reason, edit = false, fieldsType = null, duplicate = false, towVehicle = null, onlyVehicle= false }) => ({ reason, edit, fieldsType, duplicate, towVehicle, onlyVehicle }));
export const closeAppDrawer = getThunkActionCreator(closeAppDrawerRoutine, async (reason) => ({ reason }));

export const setAppFilter = getThunkActionCreator(setAppFilterRoutine, async (open) => ({ open }));

export const startRemoving = getThunkActionCreator(startRemovingRoutine, async () => {});
export const stopRemoving = getThunkActionCreator(stopRemovingRoutine, async () => {});

export const setAccountServiceData = getThunkActionCreator(setAccountServiceDataRoutine, async ({ activeVariant = null, activeRate = null, newRate = false }) => ({ activeVariant, activeRate, newRate }));
export const filterExpencesData = getThunkActionCreator(filterExpencesDataRoutine, async (expense_id = null) => ({ expense_id }));

export const setFetchingDetails = getThunkActionCreator(setFetchingDetailsRoutine, async (isFetching) => ({ isFetching }));
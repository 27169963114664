import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getCustomersRoutine, 
  getCustomerRoutine, 
  createCustomerRoutine,
  updateCustomerRoutine,
  clearLocalCustomersRoutine,
  clearLocalActiveCustomerRoutine 
} from 'actions';

const attributes = ['id', 'name', 'email', 'phone', 'customer_token'];

export const getCustomers = getThunkActionCreator(
  getCustomersRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, phone_email = null, side }) => {
    getCustomers.getCustomersCancel && getCustomers.getCustomersCancel();
    let access_token = null;

    if (side === 'user') {
      access_token = await sessionService.loadSession();
    }

    return await axios.get(`/${side}/customers`,
      {
        params: { ...(!!access_token && { access_token: access_token?.token }), limit: 25, offset, orders, ...(phone_email && { phone_email }), attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCustomers.getCustomersCancel = cancel })
      }
    );
  }
);
export const getCustomer = getThunkActionCreator(
  getCustomerRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/customers/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCustomer.getCustomerCancel = cancel })
      }
    );
  }
);
export const createCustomer = getThunkActionCreator(
  createCustomerRoutine,
  async ({ customer, side = 'user' }) => {
    let access_token = null;

    if (side === 'user') {
      access_token = await sessionService.loadSession();
    }

    return await axios.post(`/${side}/customers`, { 
      ...(!!access_token && { access_token: access_token?.token }), 
      attributes: ['id', 'customer_token'],
      customer
    });
  }
);
export const updateCustomer = getThunkActionCreator(
  updateCustomerRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    
    return await axios.patch(`/user/customers/${id}`, { access_token, customer: fields });
  }
);
export const clearLocalCustomers = getThunkActionCreator(clearLocalCustomersRoutine, async () => {});
export const clearLocalActiveCustomer = getThunkActionCreator(clearLocalActiveCustomerRoutine, async () => {});
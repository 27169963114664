import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const StyledPoint = styled(Box, { shouldForwardProp: prop => prop !== 'count' && prop !== 'points' })(({ count, points }) => ({
  minWidth: '32px',
  minHeight: '32px',
  width: `${10 + (count / points.length) * 20}px`,
  height: `${10 + (count / points.length) * 20}px`,
  color: '#fff',
  fontWeight: 500,
  background: '#2418D3',
  borderRadius: '50%',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import _omit from 'lodash/omit';
import {
  getProfileRoutine,
  updateProfileRoutine,
  changePasswordRoutine,
  startDutyRoutine,
  stopDutyRoutine
} from 'actions';

const attributes = [
  'id',
  'previous_names',
  'roles',
  'first_name',
  'last_name',
  'birth_at',
  'email',
  'phone',
  'second_phone',
  'emergency_phone',
  'second_emergency_phone',
  'notifications',
  'filtered_order_statuses',
  'filtered_invoice_statuses',
  'shift_duration',
  'duty_duration',
  'duty_started_at',
  { companies: [
    'id', 'name', 'commission_value', 'trucks_count', 'mileage_calculation', 'time_zone',
    'stripe_account_id', 'stripe_charges_enabled', 'subscription_expired_at',  
    { locations: ['id', 'name', 'email', 'phone', 'address'] }] 
  },
  { widgets: ['id', 'position_x', 'position_y', 'width', 'height', 'widget_type'] },
  { photo: ['id', 'representations']}
];

export const getProfile = getThunkActionCreator(
  getProfileRoutine,
  async () => {
    getProfile.getProfileCancel && getProfile.getProfileCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/profile`, {
      params: { access_token, attributes },
      cancelToken: new CancelToken(function executor(c) { getProfile.getProfileCancel = c })
    });
  }
);
export const updateProfile = getThunkActionCreator(
  updateProfileRoutine,
  async (user) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/profile`, { access_token, user: _omit(user, ['localPhoto']) });
    
    return { response, user }
  }
);
export const changePassword = getThunkActionCreator(
  changePasswordRoutine,
  async ({ password, current_password }) => {
    const currentSession = await sessionService.loadSession();
    
    await axios.post(`/user/profile/change_password`, { access_token: currentSession.token, current_password, password });
  }
);
export const startDuty = getThunkActionCreator(
  startDutyRoutine,
  async () => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post('/user/profile/start_duty', { access_token });
  }
);
export const stopDuty = getThunkActionCreator(
  stopDutyRoutine,
  async () => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post('/user/profile/stop_duty', { access_token });
  }
);
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getListCategories as getListCategoriesAction,
  getCategories as getCategoriesAction,
  getCategory as getCategoryAction,
  createCategory as createCategoryAction,
  clearLocalListCategories as clearLocalListCategoriesAction,
  clearLocalCategories as clearLocalCategoriesAction,
  clearLocalActiveCategory as clearLocalActiveCategoryAction
} from 'actions/categories';

const useCategories = () => {
  const dispatch = useDispatch();
  const getListCategories = useCallback(({ offset, orders, category_type }) => dispatch(getListCategoriesAction({ offset, orders, category_type })), [dispatch]);
  const getCategories = useCallback(({ id, model, offset, orders, query, category_type }) => dispatch(getCategoriesAction({ id, model, offset, orders, query, category_type })), [dispatch]);
  const getCategory = useCallback(id => dispatch(getCategoryAction(id)), [dispatch]);
  const createCategory = useCallback(category => dispatch(createCategoryAction(category)), [dispatch]);
  const clearLocalListCategories = useCallback(() => dispatch(clearLocalListCategoriesAction()), [dispatch]);
  const clearLocalCategories = useCallback(() => dispatch(clearLocalCategoriesAction()), [dispatch]);
  const clearLocalActiveCategory = useCallback(() => dispatch(clearLocalActiveCategoryAction()), [dispatch]);

  return {
    getListCategories,
    getCategories,
    getCategory,
    createCategory,
    clearLocalListCategories,
    clearLocalCategories,
    clearLocalActiveCategory
  };
};

export default useCategories;
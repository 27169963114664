import {
  getListTypesRoutine,
  clearLocalListTypesRoutine
} from 'actions';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListTypesRoutine.SUCCESS: {
      const { data: { types, pagination } } = action.payload;

      return { ...state, list: { data: [...state.list.data, ...types], pagination } };
    }
    case clearLocalListTypesRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
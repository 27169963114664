import axios, { decodeVinUrl, CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {  } from 'actions';
import { 
  getVehiclesRoutine, 
  getVehicleRoutine, 
  createVehicleRoutine, 
  updateVehicleRoutine, 
  storeVehicleRoutine,
  takeOutVehicleRoutine,
  releaseVehicleRoutine, 
  clearLocalVehiclesRoutine,
  clearLocalActiveVehicleRoutine,
  getFromVinVehicleInfoRoutine,
  getFromPlateVehicleInfoRoutine,
  getVehiclesStatisticsRoutine,
  clearVehiclesStatisticsRoutine
} from 'actions';

const shortAttributes = [
  'id', 
  'make',
  'model',
  'year',
  'status', 
  'created_at',
  { order: ['number'] },
  { receiver: ['id', 'receiver_type', 'name', 'address', 'phone', 'email', 'driver_license', 'license_state'] }
];
const plateAttributes = [
  'make',
  'model',
  'year',
  'vin',
  'color',
  'drive_type'
];

const attributes = [
  ...shortAttributes,
  'vin',
  'color',
  'drive_type',
  'license',
  'license_state',
  'storage_duration',
  'stored_at',
  'odometer',
  { order: [
    'id', 
    'number',
    'status',
    'number',
    'source',
    'class_type',
    'internal_notes',
    'dispatcher_notes',
    'caller_name',
    'caller_phone',
    'member_first_name',
    'member_last_name',
    'member_phone',
    'authorization_number',
    'keys_availability', 
    'driver_attendance', 
    'insurance_company',
    'eta',
    'priority',
    'urgency_level',
    'tow_reason',
    'service_comments',
    'equipment_type',
    'police_number',
    'police_code',
    'digital_dispatch_webhook',
    'created_at',
    { services: ['id', 'name', 'service_type'] }, 
    { account: ['id', 'name', 'account_type', { company: ['id', 'name'] }] },
    { dispatcher: ['id', 'first_name', 'last_name'] },
    { user: ['id', 'first_name', 'last_name', 'phone', 'longitude', 'latitude'] },
    { truck: ['id', 'make', 'model', 'year'] },
    { customer: ['id', 'name', 'email', 'phone', { cards: ['id', 'brand', 'last4'] }] },
    { company_location: ['id', 'name', 'email', 'phone', 'address', 'longitude', 'latitude', { company: ['id', 'name', 'add_access_token_available'] }] },
    { incident_location: ['longitude', 'latitude', 'address'] },
    { destination_location: ['longitude', 'latitude', 'address'] },
    { invoice: [
      'id', 
      'number',
      'total_amount', 
      'paid_amount',
      'minimum_amount',
      'status',
      { payments: ['id', { card: ['id', 'brand', 'last4'] }, 'payer', 'payment_type', 'amount', 'created_at'] },
      { file: ['url'] }, 
      { expenses: [
        'id', 
        { rate: ['id', { charge: ['id', 'name', 'charge_type'] }, 'value', 'basic_value_type', 'fee_value_type'] }, 
        { default_charge: ['id', { charge: ['name', 'charge_type'] }, 'basic_rate_type'] }, 
        { service: ['name'] },
        'name', 
        'unit_price', 
        'quantity', 
        'hourly_charge_duration'] 
      }, 
      { recipients: ['id', 'delivery_method', 'name', 'phone', 'email'] },
      'notes',
      'created_at'] 
    }
  ] }
];
const statisticsAttributes = [
  'stored_vehicles_count',
  'laid_vehicles_count',
  'released_vehicles_count',
  'over_time_vehicles_count',
  'over_time_vehicles_data',
  'stored_vehicles_invoice_amount'
];

export const getVehicles = getThunkActionCreator(
  getVehiclesRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, query = null, statuses = ['stored', 'laid', 'for_sale'] }) => {
    getVehicles.getVehiclesCancel && getVehicles.getVehiclesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/vehicles`,
      {
        params: { access_token, limit: 25, offset, orders, ...(query && { query }), ...(!!!query && !!statuses.length && { statuses }), attributes: shortAttributes },
        cancelToken: new CancelToken(function executor(cancel) { getVehicles.getVehiclesCancel = cancel })
      }
    );
  }
);
export const getVehicle = getThunkActionCreator(
  getVehicleRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/vehicles/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getVehicle.getVehicleCancel = cancel })
      }
    );
  }
);
export const createVehicle = getThunkActionCreator(
  createVehicleRoutine,
  async (vehicle) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/vehicles`, { access_token, vehicle, attributes });
  }
);
export const updateVehicle = getThunkActionCreator(
  updateVehicleRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/vehicles/${id}`, { access_token, vehicle: fields });

    return { response, vehicle: { id, ...fields } };
  }
);
export const storeVehicle = getThunkActionCreator(
  storeVehicleRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/vehicles/${id}/store`, { access_token, attributes });

    return { response, id };
  }
);
export const takeOutVehicle = getThunkActionCreator(
  takeOutVehicleRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/vehicles/${id}/take_out`, { access_token, attributes });

    return { response, id };
  }
);
export const releaseVehicle = getThunkActionCreator(
  releaseVehicleRoutine,
  async ({id, ...fields}) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/vehicles/${id}/release`, { access_token, attributes, receiver: fields});

    return { response, receiver: { id, ...fields }};
  }
);
export const clearLocalVehicles = getThunkActionCreator(clearLocalVehiclesRoutine, async () => {});
export const clearLocalActiveVehicle = getThunkActionCreator(clearLocalActiveVehicleRoutine, async () => {});
export const getFromVinVehicleInfo = getThunkActionCreator(getFromVinVehicleInfoRoutine, async (vin) => await axios.get(`${decodeVinUrl}/decodevinvalues/${vin}?format=json`));
export const getFromPlateVehicleInfo = getThunkActionCreator(
  getFromPlateVehicleInfoRoutine,
  async ({ plate, state }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/platetovins`, { access_token, plate, state, attributes: plateAttributes });
  }
);
export const getVehiclesStatistics = getThunkActionCreator(
  getVehiclesStatisticsRoutine,
  async () => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/vehicles/statistics`,
      {
        params: { access_token, attributes: statisticsAttributes },
        cancelToken: new CancelToken(function executor(cancel) { getVehiclesStatistics.getVehiclesStatisticsCancel = cancel })
      }
    );
  }
);
export const clearVehiclesStatistics = getThunkActionCreator(clearVehiclesStatisticsRoutine, async () => {});
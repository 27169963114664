import styled from '@mui/material/styles/styled';
import Tabs from '@mui/material/Tabs';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#fff',
  [theme.breakpoints.up('md')]: {
    maxWidth: 360,
    borderRight: '2px solid #F1F0FC',
    padding: '4px 16px 4px 16px'
  }
}));

export { StyledTabs };
import { memo } from 'react';
import { Box } from '@mui/material';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';
// Local files
import BaseItem from '../BaseItem/BaseItem';

const ParticipantList = ({ data }) => {

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      { _map(data, ({ full_name, ...fields }) => 
      <BaseItem 
        key={full_name} 
        title={full_name} 
        content={_map(_filter(Object.entries(fields), (f) => !!f[1]), (f) => <Box key={f[0]}>{`${_startCase(f[0])}: ${f[1]}`}</Box>)}
      />)}
    </Box>
  );
};

export default memo(ParticipantList);
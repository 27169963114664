import {
  openAssignDialogRoutine, closeAssignDialogRoutine,
  openCancelOrderDialogRoutine, closeCancelOrderDialogRoutine,
  openConfirmationDialogRoutine, closeConfirmationDialogRoutine,
  openDocumentsDialogRoutine, closeDocumentsDialogRoutine,
  openFilePreviewDialogRoutine, closeFilePreviewDialogRoutine,
  openInvoiceDialogRoutine, closeInvoiceDialogRoutine,
  openLocationDialogRoutine, closeLocationDialogRoutine,
  openMapDialogRoutine, closeMapDialogRoutine,
  openPasswordDialogRoutine, closePasswordDialogRoutine,
  openRejectOrderDialogRoutine, closeRejectOrderDialogRoutine,
  openSignatureDialogRoutine, closeSignatureDialogRoutine,
  openTowVehicleDialogRoutine, closeTowVehicleDialogRoutine,
  openTakeOutVehicleDialogRoutine, closeTakeOutVehicleDialogRoutine,
  stopRemovingRoutine
} from 'actions';

export const initialState = {
  assign: { open: false, order: null, isISSC: false, isUrgently: false },
  cancelOrder: { open: false, source: '', id: '' },
  confirmation: {
    open: false,
    confirmedAction: { type: null, id: null, yes: false, no: false, content: '' },
    reason: ''
  },
  documents: { open: false, documents: [], isOrderDrawer: false },
  filePreview: { open: false, filename: '', preview: '', url: '', address: null },
  invoice: { open: false, url: '' },
  location: { open: false, company_id: '' },
  map: { open: false, order: null, isISSC: false, isUrgently: false},
  password: { open: false },
  rejectOrder: { open: false, source: '', id: '' },
  signature: { open: false, id: '' },
  towVehicle: { open: false },
  takeOutVehicle: { open: false },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    /***
     * Assign
     ***/
    case openAssignDialogRoutine.SUCCESS: {
      const { order, isISSC, isUrgently } = action.payload;

      return { ...state, assign: { open: true, order, isISSC, isUrgently } };
    }
    case closeAssignDialogRoutine.SUCCESS: {
      return { ...state, assign: initialState.assign };
    }
    /***
     * Cancel order
     ***/
    case openCancelOrderDialogRoutine.SUCCESS: {
      const { id, source } = action.payload;

      return { ...state, cancelOrder: { open: true, id, source } };
    }
    case closeCancelOrderDialogRoutine.SUCCESS: {
      return { ...state, cancelOrder: initialState.cancelOrder };
    }
    /***
     * Confirmation
     ***/
    case openConfirmationDialogRoutine.SUCCESS: {
      const { id, content, type, reason } = action.payload;

      return { ...state, confirmation: { open: true, confirmedAction: { ...state.confirmation.confirmedAction, type, content, id }, reason } };
    }
    case closeConfirmationDialogRoutine.SUCCESS: {
      const { yes, no, id } = action.payload;

      return { ...state, confirmation: { ...state.confirmation, open: false, confirmedAction: { ...state.confirmation.confirmedAction, yes, no, id } } };
    }
    /***
     * Documents
     ***/
    case openDocumentsDialogRoutine.SUCCESS: {
      const { documents, isOrderDrawer } = action.payload;

      return { ...state, documents: { open: true, documents, isOrderDrawer } };
    }
    case closeDocumentsDialogRoutine.SUCCESS: {
      return { ...state, documents: initialState.documents };
    }
    /***
 * File Preview
 ***/
    case openFilePreviewDialogRoutine.SUCCESS: {
      const { filename, preview, url, address } = action.payload;

      return { ...state, filePreview: { open: true, filename, preview, url, address } };
    }
    case closeFilePreviewDialogRoutine.SUCCESS: {
      return { ...state, filePreview: initialState.filePreview };
    }
    /***
     * Invoice
     ***/
    case openInvoiceDialogRoutine.SUCCESS: {
      const { url } = action.payload;

      return { ...state, invoice: { open: true, url } };
    }
    case closeInvoiceDialogRoutine.SUCCESS: {
      return { ...state, invoice: initialState.invoice };
    }
    /***
     * Location
     ***/
    case openLocationDialogRoutine.SUCCESS: {
      const { company_id } = action.payload;

      return { ...state, location: { open: true, company_id } };
    }
    case closeLocationDialogRoutine.SUCCESS: {
      return { ...state, location: initialState.location };
    }
    /***
     * Map
     ***/
    case openMapDialogRoutine.SUCCESS: {
      const { order, isISSC, isUrgently } = action.payload;

      return { ...state, map: { open: true, order, isISSC, isUrgently } };
    }
    case closeMapDialogRoutine.SUCCESS: {
      return { ...state, map: initialState.map };
    }
    /***
     * Change profile password
     ***/
    case openPasswordDialogRoutine.SUCCESS: {
      return { ...state, password: { open: true } };
    }
    case closePasswordDialogRoutine.SUCCESS: {
      return { ...state, password: initialState.password };
    }
    /***
     * Reject order
     ***/
    case openRejectOrderDialogRoutine.SUCCESS: {
      const { id, source } = action.payload;

      return { ...state, rejectOrder: { open: true, id, source } };
    }
    case closeRejectOrderDialogRoutine.SUCCESS: {
      return { ...state, rejectOrder: initialState.rejectOrder };
    }
    /***
     * Signature
     ***/
    case openSignatureDialogRoutine.SUCCESS: {
      const { id } = action.payload;

      return { ...state, signature: { open: true, id } };
    }
    case closeSignatureDialogRoutine.SUCCESS: {
      return { ...state, signature: initialState.signature };
    }
    /***
     * Tow Vehicle
     ***/
    case openTowVehicleDialogRoutine.SUCCESS: {
      return { ...state, towVehicle: { open: true } };
    }
    case closeTowVehicleDialogRoutine.SUCCESS: {
      return { ...state, towVehicle: initialState.towVehicle };
    }
    /***
     * Take Out Vehicle
     ***/
    case openTakeOutVehicleDialogRoutine.SUCCESS: {
      return { ...state, takeOutVehicle: { open: true } };
    }
    case closeTakeOutVehicleDialogRoutine.SUCCESS: {
      return { ...state, takeOutVehicle: initialState.takeOutVehicle };
    }
    /***
     * Removing
     ***/
    case stopRemovingRoutine.SUCCESS: {
      return { ...state, confirmation: { ...state.confirmation, reason: '' } };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
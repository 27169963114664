import { memo } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const StyledItem = ({ children, dense = false, onClick, ...otherProps }) => {
  return (
    <ListItemButton
      disableGutters
      sx={{ justifyContent: dense ? 'flex-start' : 'center', borderRadius: '4px', px: .5, width: 'inherit', overflow: 'hidden' }}
      onClick={onClick}
      {...otherProps}
    >
      <Typography variant={dense ? 'caption' : 'body2'} noWrap>
        {children}
      </Typography>
    </ListItemButton>
  );
};

StyledItem.propTypes = {
  children: PropTypes.node.isRequired,
  dense: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default memo(StyledItem);
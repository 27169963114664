import { memo } from 'react';
import Button from '@mui/material/Button';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText } from 'components/Common/BasePageUi/BasePageUi';

const VehiclesCard = ({ vehicle, keys_availability = null, driver_attendance = null, sx, onEditClick }) => {

  return (
    <Card sx={sx}>
      <CardHeader>
        <CardTitle>Vehicle</CardTitle>
        {onEditClick && <Button onClick={onEditClick}>EDIT</Button>}
      </CardHeader>
      <ItemsContainer>
        <Item>
          <ItemLabel>Make</ItemLabel>
          <ItemText>{vehicle?.make}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Model</ItemLabel>
          <ItemText >{vehicle?.model}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Year</ItemLabel>
          <ItemText>{vehicle?.year}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Color</ItemLabel>
          <ItemText>{vehicle?.color}</ItemText>
        </Item>
        <Item>
          <ItemLabel>VIN</ItemLabel>
          <ItemText>{vehicle?.vin}</ItemText>
        </Item>
        <Item>
          <ItemLabel>License</ItemLabel>
          <ItemText>{vehicle?.license}</ItemText>
        </Item>
        <Item>
          <ItemLabel>License State</ItemLabel>
          <ItemText>{vehicle?.license_state}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Drive Type</ItemLabel>
          <ItemText>{vehicle?.drive_type?.toUpperCase() ?? '-'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Attended</ItemLabel>
          <ItemText>{driver_attendance ? 'Yes' : 'No'}</ItemText>
        </Item>
        <Item>
          <ItemLabel>Keys</ItemLabel>
          <ItemText>{keys_availability ? 'Yes' : 'No'}</ItemText>
        </Item>
        {!!vehicle?.odometer && <Item>
          <ItemLabel>Odometer</ItemLabel>
          <ItemText>{vehicle?.odometer}</ItemText>
        </Item>}
      </ItemsContainer>
    </Card>
  );
};

export default memo(VehiclesCard);
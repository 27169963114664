import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  openAssignDialogRoutine, closeAssignDialogRoutine,
  openCancelOrderDialogRoutine, closeCancelOrderDialogRoutine,
  openConfirmationDialogRoutine, closeConfirmationDialogRoutine,
  openDocumentsDialogRoutine, closeDocumentsDialogRoutine,
  openFilePreviewDialogRoutine, closeFilePreviewDialogRoutine,
  openInvoiceDialogRoutine, closeInvoiceDialogRoutine,
  openLocationDialogRoutine, closeLocationDialogRoutine,
  openMapDialogRoutine, closeMapDialogRoutine,
  openPasswordDialogRoutine, closePasswordDialogRoutine,
  openRejectOrderDialogRoutine, closeRejectOrderDialogRoutine,
  openSignatureDialogRoutine, closeSignatureDialogRoutine,
  openTowVehicleDialogRoutine, closeTowVehicleDialogRoutine,
  openTakeOutVehicleDialogRoutine, closeTakeOutVehicleDialogRoutine
} from '.';

export const openAssignDialog = getThunkActionCreator(openAssignDialogRoutine, async ({ order, isISSC, isUrgently }) => ({ order, isISSC, isUrgently }));
export const closeAssignDialog = getThunkActionCreator(closeAssignDialogRoutine, async () => {});

export const openCancelOrderDialog = getThunkActionCreator(openCancelOrderDialogRoutine, async ({ id, source = null }) => ({ id, source }));
export const closeCancelOrderDialog = getThunkActionCreator(closeCancelOrderDialogRoutine, async () => {});

export const openConfirmationDialog = getThunkActionCreator(openConfirmationDialogRoutine, async ({ type, content, id, reason }) => ({ type, content, id, reason }));
export const closeConfirmationDialog = getThunkActionCreator(closeConfirmationDialogRoutine, async ({ yes, no, id = null }) => ({ yes, no, id }));

export const openDocumentsDialog = getThunkActionCreator(openDocumentsDialogRoutine, async ({ documents, isOrderDrawer }) => ({ documents, isOrderDrawer }));
export const closeDocumentsDialog = getThunkActionCreator(closeDocumentsDialogRoutine, async () => {});

export const openFilePreviewDialog = getThunkActionCreator(openFilePreviewDialogRoutine, async ({ filename, preview, url, address }) => ({ filename, preview, url, address }));
export const closeFilePreviewDialog = getThunkActionCreator(closeFilePreviewDialogRoutine, async () => {});

export const openInvoiceDialog = getThunkActionCreator(openInvoiceDialogRoutine, async (url) => ({ url }));
export const closeInvoiceDialog = getThunkActionCreator(closeInvoiceDialogRoutine, async () => {});

export const openLocationDialog = getThunkActionCreator(openLocationDialogRoutine, async (company_id) => ({ company_id }));
export const closeLocationDialog = getThunkActionCreator(closeLocationDialogRoutine, async () => {});

export const openMapDialog = getThunkActionCreator(openMapDialogRoutine, async ({ order, isISSC, isUrgently }) => ({ order, isISSC, isUrgently }));
export const closeMapDialog = getThunkActionCreator(closeMapDialogRoutine, async () => {});

export const openPasswordDialog = getThunkActionCreator(openPasswordDialogRoutine, async () => {});
export const closePasswordDialog = getThunkActionCreator(closePasswordDialogRoutine, async () => {});

export const openRejectOrderDialog = getThunkActionCreator(openRejectOrderDialogRoutine, async ({ id, source = null }) => ({ id, source }));
export const closeRejectOrderDialog = getThunkActionCreator(closeRejectOrderDialogRoutine, async () => {});

export const openSignatureDialog = getThunkActionCreator(openSignatureDialogRoutine, async (id) => ({ id }));
export const closeSignatureDialog = getThunkActionCreator(closeSignatureDialogRoutine, async () => {});

export const openTowVehicleDialog = getThunkActionCreator(openTowVehicleDialogRoutine, async () => {});
export const closeTowVehicleDialog = getThunkActionCreator(closeTowVehicleDialogRoutine, async () => {});

export const openTakeOutVehicleDialog = getThunkActionCreator(openTakeOutVehicleDialogRoutine, async () => {});
export const closeTakeOutVehicleDialog = getThunkActionCreator(closeTakeOutVehicleDialogRoutine, async () => {});
import styled from '@mui/material/styles/styled';

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  flexGrow: 1,
  overflowY: 'auto',
  backgroundColor: 'transparent'
});

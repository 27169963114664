import { memo, useCallback, useEffect, useState } from 'react';
import { List, useAutocomplete, Box, TextField, Typography, MenuItem, CircularProgress } from '@mui/material';
import _map from 'lodash/map';
// Local files
import { mapboxAccessToken, mapboxSearchUrl } from 'apis';

const BaseAddressAutocomplete = ({ id = '', margin = 'none', label, value, onChange, error = '', draggedMarker = '', inputSx = null, widget = false, otherInputProps = null, ...otherProps }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [position, setPosition] = useState(null);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-roadsider',
    options,
    getOptionLabel: (option) => option.label,
  });

  const handleGetLocation = useCallback(() => 
    !!!position && window.navigator.geolocation.getCurrentPosition(({ coords }) => 
    setPosition(`${coords.longitude},${coords.latitude}`), (_) => setPosition(null))
  , [position]);
  const handleInputChange = ({ target: { value } }) => { 
    onChange({ value });
    setQuery(value);
  };
  const handleOptionSelect = useCallback((option) => {
    onChange({ 
      value: option.label,
      formattedValue: option.label,
      longitude: option.longitude,
      latitude: option.latitude,
      error: ''
    });
    setOptions([]);
    setQuery('');
    setPosition(null);
  }, [onChange]);
  useEffect(() => {
    const fetchOptions = async (q = null) => await mapboxSearchUrl.get(`/${q || draggedMarker || position}.json?access_token=${mapboxAccessToken}&proximity=${[position]}&autocomplete=true&country=US&language=en`);
    let timeout = setTimeout(() => {
      if(!!query || !!draggedMarker || (widget && !!position)) {
        setLoading(true);
        fetchOptions(query)
        .then(({ data }) => {
          if (!!data.features?.length) {
            if (!!query) {
              setOptions(_map(data.features, feature =>
              ({
                id: feature.id,
                label: feature.place_name,
                longitude: feature.geometry.coordinates[0],
                latitude: feature.geometry.coordinates[1]
              })))
            }
            if (!!draggedMarker || (widget && !!position && !!!query)) {
              handleOptionSelect(
              _map(data.features, feature =>
                ({
                  id: feature.id,
                  label: feature.place_name,
                  longitude: feature.geometry.coordinates[0],
                  latitude: feature.geometry.coordinates[1]
                }))[0]
              )
            }
          }
        })
        .finally(() => setLoading(false))
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [query, draggedMarker, widget, position, handleOptionSelect]);
  useEffect(() => {
    return () => setPosition(null);
  }, []);

  return (
    <Box {...getRootProps} sx={{ width: '100%' }}>
      <TextField
        {...getInputProps()}
        id={id}
        fullWidth
        margin={margin}
        label={label}
        InputProps={{
          endAdornment: <>
            {loading && <CircularProgress color="inherit" size={20} />}
          </>,
          ...otherInputProps
        }}
        onChange={handleInputChange}
        value={value}
        error={!!error}
        helperText={error}
        onClick={handleGetLocation}
        {...otherProps}
      />
      {groupedOptions.length > 0 ? (
        <Box 
          sx={{ position: widget ? 'inherit' : 'absolute', backgroundColor: '#fff', boxShadow: '0 1px 5px 0 rgba(127, 131, 227, 0.21)', zIndex: 1350, borderRadius: '8px', ...inputSx }}
        >
          <List {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <MenuItem
                {...getOptionProps({ option, index })} 
                key={option.id} 
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionSelect(option);
                }}>
                  <Typography noWrap align='left'>{option.label}</Typography>
              </MenuItem>
            ))}
          </List>
        </Box>
      ) : null}
    </Box>
  );
};

export default memo(BaseAddressAutocomplete);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getWidgetsRoutine,
  getWidgetRoutine,
  createWidgetRoutine,
  updateWidgetRoutine,
  updateWidgetsRoutine,
  deleteWidgetRoutine,
  clearLocalWidgetsRoutine,
  clearLocalActiveWidgetRoutine
} from 'actions';

const attributes = ['id', 'position_x', 'position_y', 'width', 'height', 'widget_type', 'created_at'];

export const getWidgets = getThunkActionCreator(
  getWidgetsRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' } }) => {
    getWidgets.getWidgetsCancel && getWidgets.getWidgetsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/widgets`, {
      params: { access_token, limit, offset, orders, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getWidgets.getWidgetsCancel = cancel })
    });
  }
);
export const getWidget = getThunkActionCreator(
  getWidgetRoutine,
  async (id) => {
    getWidget.getWidgetCancel && getWidget.getWidgetCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/widgets/${id}`, {
      params: { access_token, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getWidget.getWidgetCancel = cancel })
    });
  }
);
export const createWidget = getThunkActionCreator(
  createWidgetRoutine,
  async (widget) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/widgets`, { access_token, widget, attributes });
  }
);
export const updateWidget = getThunkActionCreator(
  updateWidgetRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/widgets/${id}`, { access_token, widget: fields });

    return { response, widget: { id, ...fields } };
  }
);
export const updateWidgets = getThunkActionCreator(
  updateWidgetsRoutine,
  async (widgets) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/widgets/batch`, { access_token, widgets });

    return { response, widgets };
  }
);
export const deleteWidget = getThunkActionCreator(
  deleteWidgetRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/widgets/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalWidgets = getThunkActionCreator(clearLocalWidgetsRoutine, async () => {});
export const clearLocalActiveWidget = getThunkActionCreator(clearLocalActiveWidgetRoutine, async () => {});
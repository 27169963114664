import { memo, useEffect, useCallback } from 'react';
import _map from 'lodash/map';
import List from '@mui/material/List';
import { useParams } from 'react-router-dom';
// Local files
import axios from 'axios';
import BaseListItem from 'components/Common/BaseListItem/BaseListItem';
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useTrucks from 'hooks/useTrucks';

const TruckList = ({ companyId, fetching, query, onFetching }) => {
  const { id: truckId } = useParams();
  const { getTrucks, clearLocalTrucks } = useTrucks();
  const { data, pagination } = useCustomSelector(state => state.trucks.all)
  const { setError } = useError();

  const fetchTrucks = useCallback((offset, query, companyId) => {
    onFetching(true);
    getTrucks({ offset, query, company_ids: !!companyId ? [companyId] : [] })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => onFetching(false));
  }, [getTrucks, setError]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalTrucks().then(() => fetchTrucks(0, query, companyId)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [fetchTrucks, clearLocalTrucks, query, companyId]);

  return (
    <BaseScrollableContainer {...pagination} onLoad={offset => !fetching && fetchTrucks(offset, query)}>
      <List>
        {_map(data, ({ id, make, model, year, current_user }) => (
          <BaseListItem
            key={id}
            title={`${make} ${model}`}
            description={year.toString()}
            currentUser={!!current_user ? `${current_user?.first_name || ''}  ${current_user?.last_name || ''}` : null}
            selected={id === truckId}
            to={`/company/trucks/${id}`}
          />
        ))}
      </List>
    </BaseScrollableContainer>
  );
};

export default memo(TruckList);
import { memo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import _startCase from 'lodash/startCase';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
// Local files
import { Root, Container, StyledButtonBase } from './Widget.styled';
import SmallWidget from './SmallWidget/SmallWidget';
import MediumWidget from './MediumWidget/MediumWidget';
import LargeWidget from './LargeWidget/LargeWidget';
import useWidgets from 'hooks/useWidgets';
import useDialogs from 'hooks/useDialogs';
import useCompensations from 'hooks/useCompensations';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import { widgetTypes, widgetSizes, reasons } from 'helpers/constants';
import { DELETE_WIDGET } from 'helpers/confirmations';

const Widget = ({ widget, widgets, maxPosition, edit, onEdit }) => {
  const { createWidget } = useWidgets();
  const { compensationsReport } = useCompensations();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { openConfirmationDialog } = useDialogs();
  const [ menuAnchor, setMenuAnchor ] = useState(null);
  const [ compensationsReportParams, setCompensationsReportParams ] = useState(null);
  const [ popoverAnchor, setPopoverAnchor ] = useState(null);
  const [processing, setProcessing] = useState(false);
  const isAdd = widget.i === 'add';
  const handleClose = () => setPopoverAnchor(null);
  const handleCreateWidget = (widget_type) => {
    const position_x = (maxPosition.x + widgetSizes[widget_type]?.width <= 12 && !maxPosition.overlapWidget) ? maxPosition.x : 0;
    const position_y = position_x !== 0 ? maxPosition.y : (position_x + maxPosition.hMax?.x) >= widgetSizes[widget_type]?.width ? maxPosition.y + maxPosition.hMin?.h : maxPosition.y + maxPosition.hMax?.h;
    const newWidget = {
      widget_type,
      position_x,
      position_y,
      ...widgetSizes[widget_type]
    };

    setPopoverAnchor(null);
    setProcessing(true);
    createWidget(newWidget)
    .then(() => setSuccess('Widget created').then(onEdit))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleDownloadReport = () => {
    const { company_id, start_at, end_at, user_ids } = compensationsReportParams;
    setProcessing(true)
    setMenuAnchor(null);
    setSuccess('Generating “Driver Commision“ report');
    compensationsReport({ company_id, start_at, end_at, user_ids })
    .then(({ payload : { data } }) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
    })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleDelete = () => {
    openConfirmationDialog({
      id: widget.i,
      content: 'Are you sure you want to delete this widget?',
      reason: reasons.WIDGET,
      type: DELETE_WIDGET
    });
  };

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ sx: { mt: '2px', width: '220px' } }}
        keepMounted
      >
        {widget?.widget_type === 'driver_commission' && <MenuItem color='primary' disabled={processing} onClick={handleDownloadReport}>Download Report | PDF</MenuItem>}
        <MenuItem color='primary' disabled={processing} onClick={handleDelete}>Delete widget</MenuItem>
      </Menu>
      <Popover
        id='widgets-list'
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{ sx: { width: 200, maxHeight: 300 } }}
      >
        <TextField 
          fullWidth
          label='Choose widget type' 
          select
          defaultValue=''
          onChange={({ target: { value } }) => handleCreateWidget(value)} 
        >
          {_map(_filter(widgetTypes, (w) => !!!_find(widgets, ({ widget_type }) => w === widget_type)), (w) => <MenuItem key={w} color='primary' value={w} disabled={processing}>{_startCase(w)}</MenuItem>)}
        </TextField>
      </Popover>
      <Root>
        <Container 
          sx={{ 
            backgroundColor: isAdd ? 'transparent' : widget.h === 1 ? '#1B1636' : 'white', 
            border: isAdd ? '2px dashed #08008F' : 'none',
            color: isAdd ? '#08008F' : widget.h === 1 ? 'white' : 'inherit',
            ...(isAdd && !edit && { display: 'none' })
          }}
        >
          {isAdd ? <StyledButtonBase disabled={processing} onClick={({ currentTarget }) => setPopoverAnchor(currentTarget)}>+ {widget.i} widget</StyledButtonBase> :
          widget.h === 1 ? <SmallWidget widget={widget} onDelete={handleDelete} /> : (widget.h === 2 && (widget.w === 3 || widget.w === 4)) ? 
          <MediumWidget widget={widget} setCompensationsReportParams={setCompensationsReportParams} onMenuAnchor={setMenuAnchor} /> : <LargeWidget widget={widget} onMenuAnchor={setMenuAnchor} />}
        </Container>
      </Root>
    </>
  );
};

export default memo(Widget);
import React from 'react';
import { Container, Iframe} from './Youtube.styled';

const Youtube = ({ title, id }) => {
  const src = `https://www.youtube.com/embed/${id}`;
  
  return (
    <Container>
      <Iframe
        id="youtube-iframe"
        src={src}
        title={title}
        allowFullScreen
        mozAllowFullScreen
        msAllowFullScreen
        oAllowFullScreen
        webkitAllowFullScreen
      ></Iframe>
    </Container>
  );
};

export default Youtube;

import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  setLocalTokenRoutine,
  getUserSessionsRoutine,
  getSessionsRoutine,
  getSessionRoutine,
  createSessionRoutine,
  deleteActiveSessionRoutine,
  deleteSessionRoutine,
  setRecoveryEmailRoutine,
  setConfirmationCodeRoutine,
  clearLocalRecoveryDataRoutine,
  clearStoreRoutine
} from 'actions';

const attributes = [
  'id',
  'ip',
  'platform_type',
  'location',
  'active_at'
];

export const setLocalToken = getThunkActionCreator(setLocalTokenRoutine,
  async (token) => {
    await sessionService.saveSession({ token });
    
    return true;
  }
);
export const getUserSessions = getThunkActionCreator(
  getUserSessionsRoutine,
  async ({ user_id, limit = 10, offset = 0, orders = { created_at: 'desc' } }) => {
    getUserSessions.getUserSessionsCancel && getUserSessions.getUserSessionsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/users/${user_id}/sessions`, {
      params: { access_token, limit, offset, orders, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getUserSessions.getUserSessionsCancel = cancel })
    });
  }
);
export const getSessions = getThunkActionCreator(
  getSessionsRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'asc' } }) => {
    getSessions.getSessionsCancel && getSessions.getSessionsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/sessions`, {
      params: { access_token, limit, offset, orders, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getSessions.getSessionsCancel = cancel })
    });
  }
);
export const getSession = getThunkActionCreator(
  getSessionRoutine,
  async (id) => {
    getSession.getSessionCancel && getSession.getSessionCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/sessions/${id}`, {
      params: { access_token, attributes },
      cancelToken: new CancelToken(function executor(cancel) { getSession.getSessionCancel = cancel })
    });
  }
);
export const createSession = ({ email, password }) => async (dispatch) => {
  dispatch(createSessionRoutine.request({ email, password }));
  try {
    const response = await axios.post(`/user/sessions`, {
      session: { platform_type: 'web' },
      email,
      password,
      attributes: ['id', 'access_token']
    });
    await sessionService.saveSession({ token: response.data.session.access_token })
    .then(() => dispatch(createSessionRoutine.success(response)));
  } catch (error) {
    dispatch(createSessionRoutine.failure(error));

    throw error;
  }
};
export const deleteActiveSession = () => async (dispatch) => {
  dispatch(deleteActiveSessionRoutine.request());
  try {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/sessions`, { params: { access_token } });
    await sessionService.deleteSession()
    .then(() => dispatch(deleteActiveSessionRoutine.success(response)));
  } catch (error) {
    dispatch(deleteActiveSessionRoutine.failure(error));
  }
};
export const deleteSession = getThunkActionCreator(
  deleteSessionRoutine,
  async(id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/sessions/${id}`, { params: { access_token }});

    return { response, id };
  }
);
export const setRecoveryEmail = getThunkActionCreator(setRecoveryEmailRoutine, async (email) => email);
export const setConfirmationCode = getThunkActionCreator(setConfirmationCodeRoutine, async (code) => code);
export const clearLocalRecoveryData = getThunkActionCreator(clearLocalRecoveryDataRoutine, async () => {});
export const clearStore = getThunkActionCreator(clearStoreRoutine, async () => {});
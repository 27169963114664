import { memo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const Location = ({ location, onDelete, onUpdate }) => {

  return (
    <>
      <Accordion sx={{ backgroundColor: '#F8F8FD' }}>
        <AccordionSummary
          id={`location-${location.id}`}
          expandIcon={<ExpandMoreIcon />}
        >
          {location.name}
        </AccordionSummary>
        <AccordionDetails>
          {location.address} <br/>
          {`Email: ${location.email || '-'}`} <br/>
          {`Phone: ${location.phone || '-'}`}
        </AccordionDetails>
        <AccordionActions>
          <Button fullWidth color='error' variant='text' size='small' onClick={onDelete}>Delete</Button>
          <Button fullWidth color='primary' variant='outlined' size='small' onClick={onUpdate}>Update</Button>
        </AccordionActions>
      </Accordion>
    </>
  );
};

export default memo(Location);
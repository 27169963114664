import {
  openAppDrawerRoutine,
  closeAppDrawerRoutine,
  setAppFilterRoutine,
  startRemovingRoutine,
  stopRemovingRoutine,
  setAccountServiceDataRoutine,
  setFetchingDetailsRoutine,
  getRateRoutine,
  createRateRoutine,
  deleteRateRoutine,
  updateVariantRoutine
} from 'actions';
import { reasons } from 'helpers/constants';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import _find from 'lodash/find';

const { ACCOUNT, DRIVER, MANAGER, LOCATION, ORDER, TRUCK, PAYMENT, REQUEST_ADDITIONAL, SEND_INVOICE, RELEASE_VEHICLE, SEND_ORDER_TRACKING } = reasons;

export const initialState = {
  accountServiceData: { activeVariant: null, activeRate: null, newRate: false },
  account: { open: false, edit: false },
  filter: { open: false },
  location: { open: false, edit: false },
  order: { open: false, edit: false, duplicate: false, towVehicle: null },
  user: { open: false, edit: false, reason: '', fieldsType: '' },
  truck: { open: false, edit: false },
  payment: { open: false },
  requestAdditional: { open: false },
  sendInvoice: { open: false },
  releaseVehicle : {open: false},
  sendOrderTracking: { open: false },
  removing: false,
  isFetching: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case openAppDrawerRoutine.SUCCESS: {
      const { reason, edit, fieldsType, duplicate, towVehicle, onlyVehicle } = action.payload;

      return {
        ...state, 
        ...(reason === ACCOUNT && { account: { open: true, edit } }),
        ...(reason === LOCATION && { location: { open: true, edit } }),
        ...(reason === ORDER && { order: { open: true, edit, duplicate, towVehicle, onlyVehicle } }),
        ...(reason === TRUCK && { truck: { open: true, edit } }),
        ...(reason === PAYMENT && { payment: { open: true } }),
        ...(reason === REQUEST_ADDITIONAL && { requestAdditional: { open: true } }),
        ...(reason === SEND_INVOICE && { sendInvoice: { open: true } }),
        ...(reason === RELEASE_VEHICLE && { releaseVehicle : { open: true } }),
        ...(reason === SEND_ORDER_TRACKING && { sendOrderTracking: { open: true } }),
        ...((reason === DRIVER || reason === MANAGER) && { user: { open: true, edit, reason, fieldsType } })
      };
    }
    case closeAppDrawerRoutine.SUCCESS: {
      const { reason } = action.payload;

      return {
        ...state,
        ...(reason === ACCOUNT && { account: initialState.account }),
        ...(reason === LOCATION && { location: initialState.location }),
        ...(reason === ORDER && { order: initialState.order }),
        ...(reason === TRUCK && { truck: initialState.truck }),
        ...(reason === PAYMENT && { payment: initialState.payment }),
        ...(reason === REQUEST_ADDITIONAL && { requestAdditional: initialState.requestAdditional }),
        ...(reason === SEND_INVOICE && { sendInvoice: initialState.sendInvoice }),
        ...(reason === RELEASE_VEHICLE && { releaseVehicle: initialState.releaseVehicle }),
        ...(reason === SEND_ORDER_TRACKING && { sendOrderTracking: initialState.sendOrderTracking }),
        ...((reason === DRIVER || reason === MANAGER) && { user: initialState.user })
      };
    }
    case setAppFilterRoutine.SUCCESS: {
      const { open } = action.payload;

      return { ...state, filter: { open } };
    }
    case startRemovingRoutine.SUCCESS: {
      return { ...state, removing: true };
    }
    case stopRemovingRoutine.SUCCESS: {
      return { ...state, removing: false };
    }
    case setAccountServiceDataRoutine.SUCCESS: {
      const { activeVariant, activeRate, newRate } = action.payload;

      return { ...state, accountServiceData: { 
        ...state.accountServiceData, 
        ...(!_isEqual(state.accountServiceData.activeVariant, activeVariant) && { activeVariant }), 
        ...(!_isEqual(state.accountServiceData.activeRate, activeRate) && { activeRate }),
        ...(!_isEqual(state.accountServiceData.newRate, newRate) && { newRate }),
        } 
      };
    }
    case setFetchingDetailsRoutine.SUCCESS: {
      const { isFetching } = action.payload;

      return { ...state, isFetching };
    }
    case getRateRoutine.SUCCESS: {
      const { data: { rate } } = action.payload;

      return { ...state, accountServiceData: { 
        ...state.accountServiceData, 
        ...(!!state.accountServiceData.activeVariant && { activeVariant: { 
          ...state.accountServiceData.activeVariant, 
          ...(!!!_find(state.accountServiceData.activeVariant?.rates, (r) => r.id === rate.id) && {
            rates: [...[rate], ...state.accountServiceData.activeVariant?.rates] 
          })
        } }) 
      } };
    }
    case createRateRoutine.SUCCESS: {
      const { response: { data: { rate } }, variant_id } = action.payload;

      return { ...state, accountServiceData: { 
        ...state.accountServiceData, 
        ...(state.accountServiceData.activeVariant === variant_id && { 
          activeVariant: { ...state.accountServiceData.activeVariant, rates: [...[rate], ...state.accountServiceData.activeVariant?.rates] } 
        }) 
      } };
    }
    case deleteRateRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, ...(status === 204 && !!state.accountServiceData.activeVariant && { 
        accountServiceData: { 
          ...state.accountServiceData,
          activeVariant: { ...state.accountServiceData.activeVariant, rates: _filter(state.accountServiceData.activeVariant?.rates, (r) => r?.id !== id) } 
        } 
      })};
    }
    case updateVariantRoutine.SUCCESS: {
      const { response: { status }, variant } = action.payload;

      return { ...state, ...(status === 204 && !!state.accountServiceData.activeVariant && { 
        accountServiceData: { 
          ...state.accountServiceData,
          activeVariant: { ...state.accountServiceData.activeVariant, ...variant } 
        } 
      })};
    }
    default: {
      return state;
    }
  };
};

export default reducer;
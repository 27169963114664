import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import _map from 'lodash/map';
//Local files
import { stripeKey } from 'apis';
import { paymentTypes, paymentMethods, reasons } from 'helpers/constants';
import StripeForm from 'components/Payments/StripeForm/StripeForm';
import useApp from 'hooks/useApp';
import usePayments from 'hooks/usePayments';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useCards from 'hooks/useCards';
import useTimezones from 'hooks/useTimezones';

const stripePromise = loadStripe(stripeKey);
const { CARD, PO } = paymentMethods;

const Form = ({ invoice_id, account, customer, payments, total_amount, paid_amount, authorization_number, company_location }) => {
  const { closeDrawer } = useApp();
  const { createPayment } = usePayments();
  const { createCard } = useCards();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { timeZone } = useTimezones(company_location?.company?.id);
  const [paymentMethod, setPaymentMethod] = useState({ value: '', error: '' });
  const [amount, setAmount] = useState({ value: '', error: '' });
  const [name, setName] = useState({ value: '', error: '' });
  const [authorizationNumber, setAuthorizationNumber] = useState({ value: '', error: '' });
  const [card, setCard] = useState({ value: '', error: '' });
  const [stripeToken, setStripeToken] = useState('');
  const [processing, setProcessing] = useState(false);
  const isHasCard = !!customer?.cards?.length;
  const isSavedCard = useMemo(() => card.value === customer?.cards[0]?.id, [card, customer?.cards]);
  const showName = useMemo(() => !!paymentMethod.value && paymentMethod.value !== PO, [paymentMethod.value]);
  const showCard = useMemo(() => !!customer && paymentMethod.value === CARD, [customer, paymentMethod.value]);
  const showAuthNumber = useMemo(() => paymentMethod.value === PO, [paymentMethod.value])
  
  const handleCreatePayment = useCallback((payment) => {
    createPayment({ invoice_id, payment })
    .then(() => setSuccess('Payment created').then(() => closeDrawer(reasons.PAYMENT)))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  }, [invoice_id, createPayment, setError, setSuccess, closeDrawer]);
  const handleSubmitClick = () => {
    let hasError = false;

    if (!!!paymentMethod.value) {
      setPaymentMethod(prev => ({ ...prev, error: `Please choose payment method` }));
      hasError = true;
    }
    if (!!!amount.value || (paid_amount + parseFloat(amount.value) > total_amount)) {
      setAmount(prev => ({ ...prev, error: `Wrong value` }));
      hasError = true;
    }
    if (!!!card.value && showCard) {
      setCard(prev => ({ ...prev, error: `Card can't be empty` }));
      hasError = true;
    }
    if (!!!name.value && showName) {
      setName(prev => ({ ...prev, error: `Name can't be empty` }));
      hasError = true;
    }
    if (showAuthNumber && !!!authorizationNumber.value) {
      setAuthorizationNumber(prev => ({ ...prev, error: `PO number can't be empty` }));
      hasError = true;
    }
    if (hasError) return;

    const payment = {
      ...(isSavedCard && { card_id: customer?.cards[0]?.id }),
      payment_type: isSavedCard ? CARD : paymentMethod.value,
      ...(paymentMethod.value !== CARD && { payer: paymentMethod.value !== PO ? name.value : account?.name }),
      ...(showAuthNumber && { authorization_number: authorizationNumber.value }),
      amount: parseFloat(typeof amount.value === 'string' ? amount.value.replace(',', '.') : amount.value)
    };

    setProcessing(true);
    (paymentMethod.value !== CARD || isSavedCard) && handleCreatePayment(payment);
  };

  useEffect(() => {
    if (!!stripeToken) {
      createCard({ customer_id: customer?.id, stripe_token: stripeToken })
      .then(({ payload: { response: { data: { card: { id: card_id } } } } }) => { 
        setStripeToken('');
        handleCreatePayment({ card_id, payment_type: CARD, amount: parseFloat(typeof amount.value === 'string' ? amount.value.replace(',', '.') : amount.value) })
      })
      .catch((error) => setError(error).then(() => setProcessing(false)))
    }
  }, [amount, stripeToken, customer?.id, createCard, handleCreatePayment, setError]);
  useEffect(() => {
    showName && setName({ value: customer?.name, error: '' });
    showAuthNumber && !!authorization_number && setAuthorizationNumber({ value: authorization_number, error: '' });
  }, [customer?.name, showName, showAuthNumber, authorization_number]);

  return (
    <Card sx={{ p: 3, mb: 3 }}>
      <Typography variant='title' sx={{ pb: 4 }}>Charge</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <TextField
          select
          label='Payment Method'
          value={paymentMethod.value} 
          helperText={paymentMethod.error} 
          error={!!paymentMethod.error} 
          onChange={({ target: { value } }) => setPaymentMethod({ value, error: '' })} 
          sx={{ width: 'calc(65% - 8px)' }}
        >
          { _map(isHasCard ? [...[{ value: customer?.cards[0]?.id, label: `**** **** **** ${customer?.cards[0]?.last4}` }], ...paymentTypes] : paymentTypes, ({ value, label }) => 
            <MenuItem key={value} value={value}>{label}</MenuItem>
          )}
        </TextField>
        <TextField 
          label='Amount' 
          value={amount.value} 
          helperText={amount.error} 
          error={!!amount.error} 
          onChange={({ target: { value } }) => setAmount({ value, error: paid_amount + parseFloat(value) > total_amount ? 'Over the balance' : '' })} 
          sx={{ width: 'calc(35% - 8px)' }}
        />
      </Box>
      <Box sx={{ pt: 4 }}>
        { showAuthNumber && 
        <TextField 
          fullWidth
          label='PO number' 
          value={authorizationNumber.value} 
          helperText={authorizationNumber.error} 
          error={!!authorizationNumber.error} 
          onChange={({ target: { value } }) => setAuthorizationNumber({ value, error: '' })} 
          sx={{ mb: 4 }}
        /> }
        { showName && 
        <TextField 
          fullWidth
          label='Name' 
          value={name.value} 
          helperText={name.error} 
          error={!!name.error} 
          onChange={({ target: { value } }) => setName({ value, error: '' })} 
          sx={{ mb: 4 }}
        /> }
        { showCard && <Elements stripe={stripePromise}>
          <StripeForm
            card={card}
            name={name.value}
            processing={processing}
            changeCard={({ value, error }) => setCard({ value, error: !!error ? error.message : '' })}
            changeStripeToken={setStripeToken}
          />
        </Elements> }
      </Box>
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 700 }}>
        <Box>INVOICE</Box>
        <Box>${total_amount?.toFixed(2)}</Box>
      </Box>
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontSize: '18px' }}>
        { _map(payments, ({ id, payer, created_at, amount }) => 
        <Box key={id} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box>{`${payer} - ${new Date(created_at).toLocaleDateString('en-US', { timeZone })}`}</Box>
          <Box>${amount?.toFixed(2)}</Box>
        </Box> )}
      </Box>
      <Divider />
      <Box sx={{ width: '100%', p: '6px 16px', display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 700 }}>
        <Box>BALANCE</Box>
        <Box>${(total_amount - paid_amount)?.toFixed(2)}</Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: '40px'}}>
        <Button color='primary' variant='contained' disabled={processing} onClick={handleSubmitClick}>Charge now</Button>
      </Box>
    </Card>
  );
};

export default memo(Form);

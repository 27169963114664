import { memo, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import BaseListPage from 'components/Common/BaseListPage/BaseListPage';
import List from 'components/Trucks/List/List';
import Details from 'components/Trucks/Details/Details';
import Drawer from 'components/Trucks/Drawer/Drawer';
import Filter from 'components/Common/Filter/Filter';
import CompaniesAutocomplete from 'components/Companies/Autocomplete/Autocomplete';
import { reasons } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useTrucks from 'hooks/useTrucks';
import useRole from 'hooks/useRole';

const Trucks = () => {
  const navigate = useNavigate();
  const { openDrawer, setFilter } = useApp();
  const { clearLocalActiveTruck } = useTrucks();
  const { isManager, isOwner } = useRole();
  const { open } = useCustomSelector(state => state.app.filter);
  const [query, setQuery] = useState('');
  const [companyId, setCompanyId] = useState({ value: null, formattedValue: null, error: '' });
  const [fetching, setFetching] = useState(false);

  const handleAddClick = () => { 
    navigate('/company/trucks'); 
    clearLocalActiveTruck().then(() => openDrawer({ reason: reasons.TRUCK, edit: false }));
  };
  const handleQueryChange = (value) => setQuery(value);
  const handleFilterChange = () => {
    open && setCompanyId({ value: null, formattedValue: null, error: '' });
    setFilter(!open);
  };

  useEffect(() => {
    return clearLocalActiveTruck;
  }, [clearLocalActiveTruck]);

  return (
    <>
      <BaseListPage
        renderFilter={
          <Filter
            query={query}
            onQueryChange={handleQueryChange}
            onAddClick={(isManager || isOwner) ? handleAddClick : null}
            onFilterClick={handleFilterChange}
            filterContent={
              <CompaniesAutocomplete
                fullWidth
                margin='dense'
                value={companyId.formattedValue}
                error={!!companyId.error}
                helperText={companyId.error}
                onChange={({ value, formattedValue }) => setCompanyId({ value, formattedValue })}
              />
            }
          />
        }
        listLoading={fetching}
        listComponent={
          <ComponentErrorWrapper 
            children={
              <List
                fetching={fetching}
                query={query}
                onFetching={v => setFetching(v)}
                companyId={companyId.value}
              />
            } 
          />
        }
        renderMainAtDesktop={<ComponentErrorWrapper children={<Details />} />}
      />
      <ComponentErrorWrapper children={<Drawer />} />
      <Outlet />
    </>
  );
};

export default memo(Trucks);

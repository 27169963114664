import { memo, useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _includes from 'lodash/includes';
//Local files
import { Card, CardHeader, CardTitle, FieldsContainer } from 'components/Common/BasePageUi/BasePageUi';
import useVehicles from 'hooks/useVehicles';
import useError from 'hooks/useError';
import { states } from 'helpers/constants';

const Form = ({ edit, duplicate, vehicleData, processingData, towVehicleData, getVehicleData, onChangeProcessingData, onConfirmClick, sx }) => {
  const { getFromVinVehicleInfo, getFromPlateVehicleInfo } = useVehicles();
  const { setError } = useError();
  const [vin, setVin] = useState({ value: '', error: '' });
  const [make, setMake] = useState({ value: '', error: '' });
  const [model, setModel] = useState({ value: '', error: '' });
  const [year, setYear] = useState({ value: '', error: '' });
  const [color, setColor] = useState({ value: '', error: '' });
  const [license, setLicense] = useState({ value: '', error: '' });
  const [state, setState] = useState({ value: 'CA', error: '' });
  const [driveType, setDriveType] = useState('fwd');
  const [keysAvailability, setKeysAvailability] = useState(false);
  const [driverAttendance, setDriverAttendance] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleBlur = () => vin.value?.length === 17 && 
  getFromVinVehicleInfo(vin.value)
  .then(({ payload: { data: { Results } } }) => { 
    setMake({ value: Results[0]?.Make || make.value, error: '' });
    setModel({ value: Results[0]?.Model || model.value, error: '' });
    setYear({ value: Results[0]?.ModelYear || year.value, error: '' });
    setDriveType(
      (_includes(Results[0]?.DriveType, '4') || _includes(Results[0]?.DriveType?.toLowerCase(), 'awd') || _includes(Results[0]?.DriveType?.toLowerCase(), 'all')) ? 'awd' :
      (_includes(Results[0]?.DriveType?.toLowerCase(), 'fwd') || _includes(Results[0]?.DriveType?.toLowerCase(), 'front')) ? 'fwd' :
      (_includes(Results[0]?.DriveType?.toLowerCase(), 'rwd') || _includes(Results[0]?.DriveType?.toLowerCase(), 'rear')) ? 'rwd' : ''
    );
  });
  const needToUpdateVehicle = (a,b) => !!a.id && (
    a.vin !== b.vin ||
    a.make !== b.make || 
    a.model !== b.model || 
    a.year !== b.year || 
    a.color !== b.color || 
    a.state !== b.state ||
    a.drive_type !== b.drive_type ||  
    a.license_state !== b.license_state ||  
    a.license !== b.license);
  const needToUpdateDriverInfo = (a,b) =>
    a.keys_availability !== b.keys_availability ||
    a.driver_attendance !== b.driver_attendance;
  const handleGetInfo = () => {
    if (!!license.value) {
      setProcessing(true);
      getFromPlateVehicleInfo({ plate: license.value, state: state.value })
      .then(({ payload: { data: { platetovin } } }) => {
        !!platetovin.vin && setVin({ value: platetovin.vin, error: '' });
        !!platetovin.make && setMake({ value: platetovin.make, error: '' });
        !!platetovin.model && setModel({ value: platetovin.model, error: '' });
        !!platetovin.year && setYear({ value: platetovin.year, error: '' });
        !!platetovin.color && setColor({ value: platetovin.color, error: '' });
        !!platetovin.drive_type && setDriveType(platetovin.drive_type === '4WD' ? 'awd' : platetovin.drive_type?.toLowerCase());
      })
      .catch(e => setError(e))
      .finally(() => setProcessing(false))
    } else {
      setLicense(prev => ({ ...prev, error: `Can't be empty` }));
    }
  };

  useEffect(() => {
    if (processingData) {
      let hasError = false;

      if (!!year.value && (year.value < 1900 || (year.value > new Date().getFullYear() + 2))) {
        setYear(prev => ({ ...prev, error: `Wrong year value` }));
        hasError = true;
      }
      if (hasError) {
        onChangeProcessingData(false);
        return;
      }

      const processingVehicle = {
        ...(edit && { id: vehicleData.id }),
        vin: vin.value || null, 
        make: make.value || null, 
        model: model.value || null, 
        year: year.value || null, 
        color: color.value || null, 
        license: license.value || null, 
        license_state: state.value || null,
        drive_type: driveType  || null,
        keys_availability: keysAvailability?.toString() === 'true',
        driver_attendance: driverAttendance?.toString() === 'true'
      };

      getVehicleData(edit ? 
        (needToUpdateVehicle(vehicleData, processingVehicle) && needToUpdateDriverInfo(vehicleData, processingVehicle) ? 
          processingVehicle : needToUpdateVehicle(vehicleData, processingVehicle) ? 
          _omit(processingVehicle, ['keys_availability', 'driver_attendance']) : 
          needToUpdateDriverInfo(vehicleData, processingVehicle) ?
          _pick(processingVehicle, ['keys_availability', 'driver_attendance']) : {}
        ) 
      : processingVehicle);
    }
  }, [processingData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((edit || duplicate) && !!vehicleData) {
      !!vehicleData.vin && setVin({ value: vehicleData.vin, error: '' });
      !!vehicleData.make && setMake({ value: vehicleData.make, error: '' });
      !!vehicleData.model && setModel({ value: vehicleData.model, error: '' });
      !!vehicleData.year && setYear({ value: vehicleData.year, error: '' });
      !!vehicleData.color && setColor({ value: vehicleData.color, error: '' });
      !!vehicleData.license && setLicense({ value: vehicleData.license, error: '' });
      !!vehicleData.license_state && setState({ value: vehicleData.license_state?.toUpperCase(), error: '' });
      !!vehicleData.drive_type && setDriveType(vehicleData.drive_type);
      !!vehicleData.keys_availability && setKeysAvailability(vehicleData.keys_availability);
      !!vehicleData.driver_attendance && setDriverAttendance(vehicleData.driver_attendance);
    }
  }, [edit, duplicate, vehicleData]);

  useEffect(() => {
    if ((!edit && !duplicate) && !!towVehicleData) {
      !!towVehicleData.vin && setVin({ value: towVehicleData.vin, error: '' });
      !!towVehicleData.make && setMake({ value: towVehicleData.make, error: '' });
      !!towVehicleData.model && setModel({ value: towVehicleData.model, error: '' });
      !!towVehicleData.year && setYear({ value: towVehicleData.year, error: '' });
      !!towVehicleData.color && setColor({ value: towVehicleData.color, error: '' });
      !!towVehicleData.license && setLicense({ value: towVehicleData.license, error: '' });
      !!towVehicleData.license_state && setState({ value: towVehicleData.license_state?.toUpperCase(), error: '' });
      !!towVehicleData.drive_type && setDriveType(towVehicleData.drive_type);
      !!towVehicleData.keys_availability && setKeysAvailability(towVehicleData.keys_availability);
      !!towVehicleData.driver_attendance && setDriverAttendance(towVehicleData.driver_attendance);
    }
  }, [edit, duplicate, towVehicleData]);

  return (
    <Card sx={{ gap: '24px', ...sx }}>
      <CardHeader>
        <CardTitle>Vehicle</CardTitle>
        {onConfirmClick && <Button disabled={processing} onClick={onConfirmClick}>CONFIRM</Button>}
      </CardHeader>
      <FieldsContainer>
        <TextField 
          label='License' 
          value={license.value} 
          helperText={license.error} 
          error={!!license.error} 
          onChange={({ target: { value } }) => setLicense({ value, error: '' })} 
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField 
            label='State' 
            value={state.value} 
            select
            helperText={state.error} 
            error={!!state.error} 
            onChange={({ target: { value } }) => setState({ value, error: '' })} 
            sx={{ width: '50%' }}
            SelectProps={{ MenuProps: { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, sx: { height: '40%' } } }}
          >
            { _map(states, (state) => <MenuItem key={state} value={state}>{state}</MenuItem>) }
          </TextField>
          <Button disabled={processing} onClick={handleGetInfo}>Lookup</Button>
        </Box>
        <TextField 
          label='Year' 
          value={year.value} 
          helperText={year.error} 
          error={!!year.error} 
          onChange={({ target: { value } }) => setYear({ value, error: '' })} 
          InputProps={{ type: 'number', inputProps: { min: 0 } }}
        />
        <TextField 
          label='Make' 
          value={make.value} 
          helperText={make.error} 
          error={!!make.error} 
          onChange={({ target: { value } }) => setMake({ value, error: '' })} 
        />
        <TextField 
          label='Model' 
          value={model.value} 
          helperText={model.error} 
          error={!!model.error} 
          onChange={({ target: { value } }) => setModel({ value, error: '' })} 
        />
        <TextField 
          label='Color' 
          value={color.value} 
          helperText={color.error} 
          error={!!color.error} 
          onChange={({ target: { value } }) => setColor({ value, error: '' })} 
        />
        <TextField 
          label='VIN' 
          sx={{ flexBasis: '100% !important' }} 
          inputProps={{ maxLength: 17 }} 
          value={vin.value?.toUpperCase()} 
          helperText={vin.error} 
          error={!!vin.error} 
          onChange={({ target: { value } }) => setVin({ value, error: '' })}
          onBlur={handleBlur}
        />
      </FieldsContainer>
      <Box>
        <Typography variant='subtitle'>Driver Info</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: '12px' }}>
          <FormControl>
            <FormLabel id="drive-type-label">Drive Type</FormLabel>
            <RadioGroup
              aria-labelledby="drive-type-label"
              name="driver-type"
              value={driveType}
              onChange={({ target: { value } }) => setDriveType(value)}
            >
              <FormControlLabel value="fwd" control={<Radio />} label="FWD" />
              <FormControlLabel value="rwd" control={<Radio />} label="RWD" />
              <FormControlLabel value="awd" control={<Radio />} label="AWD" />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel id="keys-label">Keys</FormLabel>
            <RadioGroup
              aria-labelledby="keys-label"
              name="keys"
              value={keysAvailability}
              onChange={({ target: { value } }) => setKeysAvailability(value)}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel id="attended-label">Attended</FormLabel>
            <RadioGroup
              aria-labelledby="attended-label"
              name="attended"
              value={driverAttendance}
              onChange={({ target: { value } }) => setDriverAttendance(value)}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Card>
  )
};

export default memo(Form);
import { memo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
// Local files
import { Root } from './AddressItem.styled';

const AddressItem = ({ hideMap, selected, iconColor, label, address, navigationCoordinates, onChange }) => {
  return (
    <Root
      sx={{ cursor: !hideMap ? 'pointer' : undefined }}
      selected={selected}
      onClick={onChange}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LocationOnOutlinedIcon color={iconColor} sx={{ mr: '4px' }} />
        {!!address && <Box>
          <Typography variant='caption' color='textSecondary' >{label}</Typography>
          <Typography variant='subtitle2'>{address || '-' }</Typography>
        </Box>}
        {!!!address && label === `Driver's current location` && <Typography variant='subtitle2'>{label}</Typography>}
      </Box>
      <Button href={`https://www.google.com/maps/dir/?api=1&destination=${navigationCoordinates}`} onClick={(e) => e.stopPropagation()}>Navigate</Button>
    </Root>
  );
};

export default memo(AddressItem);
import {
  getRequestsRoutine,
  getRequestRoutine,
  createRequestRoutine,
  answerRequestRoutine,
  clearLocalRequestsRoutine
} from 'actions';
import _map from 'lodash/map';
import _find from 'lodash/find';

export const initialState = {
  all: { data: [], pagination: { limit: 10, offset: 0, count: 0, total_count: 0 } },
  request: {
    id: '',
    channel_message: null,
    parameter_values: [],
    source: '',
    status: '',
    text: '',
    response: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getRequestsRoutine.SUCCESS: {
      const { data: { requests, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...requests], pagination } };
    }
    case getRequestRoutine.SUCCESS: {
      const { data: { request } } = action.payload;
      const foundedRequest = _find(state.all.data, (r) => r.id === request.id);
      const data = !!foundedRequest ? _map(state.all.data, (item) => item.id === request.id ? { ...item, ...request } : item) : [...[request], ...state.all.data];
      const pagination = !!foundedRequest ? state.all.pagination : { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { ...state.all, data, pagination } };
    }
    case createRequestRoutine.SUCCESS: {
      const { data: { request } } = action.payload;
      const data = [...[request], ...state.all.data];
      const pagination = { ...state.all.pagination, total_count: state.all.pagination.total_count + 1, count: state.all.pagination.count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case answerRequestRoutine.SUCCESS: {
      const { response: { status } } = action.payload;

      return { ...state, ...(status === 204 && { all: { data: state.all.data, pagination: state.all.pagination } }) };
    }
    case clearLocalRequestsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
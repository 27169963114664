import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getPayments as getPaymentsAction,
  createPayment as createPaymentAction,
  deletePayment as deletePaymentAction,
  clearLocalPayments as clearLocalPaymentsAction
} from 'actions/payments';

const usePayments = () => {
  const dispatch = useDispatch();
  const getPayments = useCallback(({ offset, created_at_gt, created_at_lt }) => dispatch(getPaymentsAction({ offset, created_at_gt, created_at_lt })), [dispatch]);
  const createPayment = useCallback(({ payment, invoice_id }) => dispatch(createPaymentAction({ payment, invoice_id })), [dispatch]);
  const deletePayment = useCallback(id => dispatch(deletePaymentAction(id)), [dispatch]);
  const clearLocalPayments = useCallback(() => dispatch(clearLocalPaymentsAction()), [dispatch]);

  return { getPayments, createPayment, deletePayment, clearLocalPayments };
};

export default usePayments;
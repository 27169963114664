import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getAddRoutine, 
  createAddRoutine,
  clearLocalActiveAddRoutine 
} from 'actions';

export const getAdd = getThunkActionCreator(
  getAddRoutine,
  async (vehicle_id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/vehicles/${vehicle_id}/add`,
      {
        params: { access_token },
        cancelToken: new CancelToken(function executor(cancel) { getAdd.getAddCancel = cancel })
      }
    );
  }
);
export const createAdd = getThunkActionCreator(
  createAddRoutine,
  async ({ company_id, code }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/companies/${company_id}/add`, { access_token, code });
  }
);
export const clearLocalActiveAdd = getThunkActionCreator(clearLocalActiveAddRoutine, async () => {});
import { memo, useState, useMemo, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import _map from 'lodash/map';
import _find from 'lodash/find';
// Local files
import { StyledTab, Section } from './Company.styled';
import CompanyServices from 'components/Services/CompanyServices/CompanyServices';
import CompanyCharges from 'components/Charges/CompanyCharges/CompanyCharges';
import Location from './Location/Location';
import LocationDialog from 'components/Dialogs/Location/Location';
import useCustomSelector from 'hooks/useCustomSelector';
import { mileageCalculationOptions, reasons, timezones } from 'helpers/constants';
import { DELETE_LOCATION } from 'helpers/confirmations';
import useCompanies from 'hooks/useCompanies';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useStripes from 'hooks/useStripes';
import useLocations from 'hooks/useLocations';
import useDialogs from 'hooks/useDialogs';
import useServices from 'hooks/useServices';
import useCharges from 'hooks/useCharges';
import useRole from 'hooks/useRole';

const Company = () => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { updateCompany } = useCompanies();
  const { createCompanyStripeUrl, createStripeBillingPortal, createStripeCheckout } = useStripes();
  const { getLocation } = useLocations();
  const { openConfirmationDialog, openLocationDialog } = useDialogs();
  const { clearLocalCompanyServices } = useServices();
  const { clearLocalCompanyCharges } = useCharges();
  const { isOwner } = useRole();
  const companies = useCustomSelector(state => state.profile.user.companies);
  const [company, setCompany] = useState(companies[0]);
  const [mileageCalculation, setMileageCalculation] = useState(companies[0]?.mileage_calculation);
  const [commission, setComission] = useState({ value: companies[0]?.commission_value, error: '' });
  const [timeZone, setTimeZone] = useState(companies[0]?.time_zone)
  const [servicesReady, setServicesReady] = useState(false);
  const [chargesReady, setChargesReady] = useState(false);
  const [processing, setProcessing] = useState(false);

  const showConnectStripe = useMemo(() => !!company.stripe_account_id && !company.stripe_charges_enabled, [company]);
  const subscription = useMemo(() => !!company.subscription_expired_at, [company]);
  const widgetCode = `<iframe\n  src='${window.location.origin}/c/${company.id}/widget'\n  title='Roadsider'\n  height='100%'\n  width='100%'\n  style={{ border: 'none' }}\n/>`;

  const handleChangeTab = (_, newValue) => {
    setCompany(newValue);
    setServicesReady(false);
    setChargesReady(false);
    setMileageCalculation(newValue?.mileage_calculation);
    setComission({ value: newValue?.commission_value, error: '' });
    setTimeZone(newValue?.time_zone);
    clearLocalCompanyServices();
    clearLocalCompanyCharges();
  };
  const handleSave = () => {
    const processableCompany = {
      id: company.id,
      mileage_calculation: mileageCalculation,
      commission_value: parseInt(commission.value),
      time_zone: timeZone
    };

    setProcessing(true);
    if (needToUpdate(company, processableCompany)) {
      updateCompany(processableCompany)
      .then(() => setSuccess('Company settings successfully updated'))
      .catch(error => setError(error))
      .finally(() => setProcessing(false));
    } else {
      setSuccess(`There's nothing to change`);
      setProcessing(false);
    }
  };
  const needToUpdate = (a, b) => a.mileage_calculation !== b.mileage_calculation || a.commission_value !== b.commission_value || a.time_zone !== b.time_zone;
  const handleConnect = () => {
    setProcessing(true);
    createCompanyStripeUrl(company.id)
    .then(({ payload: { data: { company: { url } } } }) => window.open(url, '_self'))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleUpdateLocation = (id) => {
    setProcessing(true);
    getLocation(id)
    .then(() => openLocationDialog(company.id))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const handleDeleteLocation = (id) => 
    openConfirmationDialog({
      id,
      content: 'Are you sure you want to delete this location?',
      type: DELETE_LOCATION,
      reason: reasons.LOCATION
    });
  const handleCreateUrl = () => {
    setProcessing(true);
    (!!subscription ? createStripeBillingPortal() : createStripeCheckout(company.id))
    .then(({ payload: { data } }) => window.open(!!subscription ? data?.stripe_billing_portal_session?.url : data?.stripe_checkout_session?.url, '_self'))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false));
  };
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(widgetCode);
    setSuccess('Copied');
  };

  useEffect(() => {
    const foundedCompany = _find(companies, (c) => c.id === company?.id);
    
    !!foundedCompany && setCompany(foundedCompany);
  }, [companies, company?.id]);

  return (
    <>
      <LocationDialog />
      <Tabs
        variant='fullWidth'
        value={company}
        onChange={handleChangeTab}
      >
        { _map(companies, (c) => <StyledTab key={c.id} value={c} label={c.name} disabled={processing || !chargesReady} />) }
      </Tabs>
      <Section>
        <Typography variant='title' mb={1}>Locations</Typography>
        <Box maxWidth={{ xs: 'unset', md: '360px' }} sx={{ mt: 1 }}>
          { _map(company.locations, (l) => <Location key={l.id} location={l} onDelete={() => handleDeleteLocation(l.id)} onUpdate={() => handleUpdateLocation(l.id)} />) }
          <Button fullWidth color='primary' variant='text' size='small' onClick={() => openLocationDialog(company.id)} sx={{ mt: 1 }}>+ Add Location</Button>
        </Box>
        <Box maxWidth={{ xs: 'unset', md: '360px' }} sx={{ mt: 1 }}>
          <TextField 
            id='timezone'
            fullWidth
            select
            margin='dense'
            label='Default Time Zone'
            value={timeZone}
            onChange={({ target: { value } }) => setTimeZone(value)}
            onBlur={handleSave}
          >
            { _map(timezones, (item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>) }
          </TextField>
        </Box>
      </Section>
      <Section>
        <Typography variant='title' mb={1}>Driver Commissions</Typography>
        <Box maxWidth={{ xs: 'unset', md: '360px' }} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            margin='dense'
            disabled={processing || !chargesReady}
            label='Commission (%)'
            type='number'
            value={commission.value}
            onChange={({ target: { value } }) => setComission({ value, error: (value > 100 || value <= 0) ? 'Wrong value' : '' })}
            error={!!commission.error}
            helperText={commission.error}
            inputProps={{ min: 0, max: 100 }}
            onBlur={handleSave}
            sx={{ width: 120 }}
          />
          <CompanyServices 
            id='service'
            type='basic'
            label='Included Company services'
            company_id={company.id}
            onServicesReady={setServicesReady}
          />
          { servicesReady && <CompanyServices 
            id='additionals'
            type='additional'
            label='Included Company additionals'
            company_id={company.id}
            onChargesReady={setChargesReady}
          /> }
          { chargesReady && <CompanyCharges 
            id='charges'
            company_id={company.id}
          /> }
        </Box>
      </Section>
      <Section>
        <Typography variant='title' mb={1}>Milage Calculations</Typography>
        <Box maxWidth={{ xs: 'unset', md: '360px' }} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            margin='dense'
            disabled={processing || !chargesReady}
            label='Order milage calculation'
            select
            value={mileageCalculation}
            onChange={({ target: { value } }) => setMileageCalculation(value)}
            onBlur={handleSave}
          >
            { _map(mileageCalculationOptions, (o) => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>) }
          </TextField>
        </Box>
      </Section>
      <Section>
        <Typography variant='title' mb={1}>Stripe connection</Typography>
        <Box maxWidth={{ xs: 'unset', md: '360px' }} sx={{ mt: 1 }}>
          <Button fullWidth color='primary' variant={showConnectStripe ? 'text' : 'outlined'} size='large' disabled={processing || !chargesReady} onClick={handleConnect}>
            {showConnectStripe ? 'Connect' : 'Stripe Dashboard'}
          </Button>
        </Box>
      </Section>
      <Section>
        <Typography variant='title' mb={1}>Website widget code</Typography>
        <TextField
          inputProps={{ readOnly: true }}
          multiline
          value={widgetCode}
          sx={{ fontStyle: 'italic' }}
        />
        <Button sx={{ mt: .5, alignSelf: 'flex-start' }} onClick={handleCopyUrl}>Copy widget code</Button>
      </Section>
      {isOwner && <Section>
        <Typography variant='title' mb={1}>Roadsider Subscription</Typography>
        <Box maxWidth={{ xs: 'unset', md: '360px' }} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', mt: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
            <Typography variant='subtitle1'>Number of trucks:</Typography>
            <Typography variant='subtitle1' ml={1}>{company?.trucks_count || '0'}</Typography>
          </Box>
          <Button color='primary' variant={subscription ? 'text' : 'outlined'} size='medium' disabled={processing || !chargesReady} onClick={handleCreateUrl}>
            {subscription ? 'Manage' : 'Create subscription'}
          </Button>
        </Box>
      </Section>}
    </>
  );
};

export default memo(Company);
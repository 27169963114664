import { memo } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
//Local files
import RecipientForm from '../RecipientForm/RecipientForm';

const BaseDeliveryMethod = ({ processing, checked, method, onCheckoboxChange, recipients, onRecipients, onAddRecipient }) => {

  return (
    <>
      <FormControlLabel
        label={_startCase(method)}
        control={
          <Checkbox
            checked={checked}
            onChange={onCheckoboxChange}
          />
        }
      />
      <Collapse in={checked} sx={{ width: '100%' }}>
        <Box sx={{ display: 'contents', '& > *': {ml: '24px', mb: '12px'} }}>
          { _map(recipients, (recipient) => 
          <RecipientForm key={recipient?.id} recipient={recipient} onRecientsChange={onRecipients} />) }
          <Button 
            id={method} 
            startIcon={<AddIcon id={method} onClick={({ target: { parentElement: { id } } }) => onAddRecipient({ target: { id } })} />} 
            disabled={processing} 
            onClick={onAddRecipient}
          >
            Add recipient
          </Button>
        </Box>
      </Collapse>
    </>
  );
};

export default memo(BaseDeliveryMethod);
import {
  getCompanyChargesRoutine,
  getFullListChargesRoutine,
  getListChargesRoutine,
  getChargeRoutine,
  createChargeRoutine,
  createCompanyChargeRoutine,
  deleteCompanyChargeRoutine,
  clearLocalListChargesRoutine,
  clearLocalCompanyChargesRoutine,
  clearLocalActiveChargeRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _uniqBy from 'lodash/uniqBy';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  company: { list: [], data: [] },
  charge: { id: '', name: '', charge_type: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getCompanyChargesRoutine.SUCCESS: {
      const { charges: data } = action.payload;

      return { ...state, company: { ...state.company, data } };
    }
    case getFullListChargesRoutine.SUCCESS: {
      const { charges: list } = action.payload;

      return { ...state, company: { ...state.company, list } };
    }
    case getListChargesRoutine.SUCCESS: {
      const { data: { charges, pagination } } = action.payload;

      return { ...state, list: { data: _uniqBy([...state.list.data, ...charges], 'id'), pagination } };
    }
    case getChargeRoutine.SUCCESS: {
      const { data: { charge } } = action.payload;
      const updatedCharge = { ...state.charge, ...charge };

      return { ...state, charge: updatedCharge };
    }
    case createChargeRoutine.SUCCESS: {
      const { data: { charge } } = action.payload;
      const oldAllData = state.list.pagination.total_count > 25 ? _dropRight(state.list.data) : state.list.data;
      const data = [...[charge], ...oldAllData];
      const pagination = { ...state.list.pagination, count: state.list.pagination.count + 1, total_count: state.list.pagination.total_count + 1 };

      return { ...state, list: { data, pagination } };
    }
    case createCompanyChargeRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const newCharge = _find(state.company.list, (s) => s.id === id);

      return { ...state, ...(status === 204 && { company: { ...state.company, data: [...[newCharge], ...state.company.data] } }) };
    }
    case deleteCompanyChargeRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const deletedCharge = _find(state.company.list, (s) => s.id === id);

      return { ...state, ...(status === 204 && {
        company: { ...state.company, data: _filter(state.company.data, (c) => c.id !== deletedCharge.id) }
      }) };
    }
    case clearLocalListChargesRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalCompanyChargesRoutine.SUCCESS: {
      return { ...state, company: initialState.company }
    }
    case clearLocalActiveChargeRoutine.SUCCESS: {
      return { ...state, charge: initialState.charge }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
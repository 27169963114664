import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _pick from 'lodash/pick';
// Local Files
import { CardsGrid, DetailsContainer, DetailsWrapper } from 'components/Common/BasePageUi/BasePageUi';
import CustomerCard from 'components/Customers/Card/Card';
import VehicleCard from 'components/Vehicles/Cards/Card/Card';
import DriverCard from 'components/Driver/Card/Card';
import PaymentCard from 'components/Payments/Card/Card';
import PaymentDrawer from 'components/Payments/Drawer/Drawer';
import MapCard from 'components/Map/Card/Card';
import NotesCard from 'components/Notes/Card/Card';
import DocumentsCard from 'components/Documents/Card/Card';
import Info from 'components/Orders/Info/Info';
import MilageInfoCard from 'components/Orders/Cards/MilageInfo/Card';
import Main from '../Cards/Main/Main';
import SendInvoice from 'components/Invoices/Drawers/SendInvoice/SendInvoice';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import Placeholder from 'components/Common/Placeholder/Placeholder';
import useCustomSelector from 'hooks/useCustomSelector';
import useInvoices from 'hooks/useInvoices';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';
import { models, invoiceStatuses } from 'helpers/constants';

const Details = () => {
  const { id } = useParams();
  const { setFetchingDetails } = useApp();
  const { setError } = useError();
  const { getInvoice, clearLocalActiveInvoice } = useInvoices();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const { id: invoice_id, order, notes } = useCustomSelector(state => state.invoices.invoice);
  const invoice = useCustomSelector(state => state.invoices.invoice);
  
  const isEditable = invoice?.status === invoiceStatuses.NOT_SUBMITTED || invoice?.status === invoiceStatuses.NEW;
  const mainData = _pick(invoice, ['id', 'file', 'customer_signature', 'number', 'expenses', 'payments', 'order', 'status', 'total_amount', 'paid_amount', 'created_at']);
  const customerData = _pick(order, ['customer', 'account', 'caller_name', 'caller_phone', 'member_first_name', 'member_last_name', 'member_phone']);
  const vehicleData = _pick(order, ['vehicle', 'keys_availability', 'driver_attendance']);
  const driverData = _pick(order, ['id', 'user', 'dispatcher', 'truck', 'incident_location', 'status', 'eta', 'source']);
  const mapData = _pick(order, ['incident_location', 'destination_location', 'status']);
  const notesData = { ..._pick(order, ['internal_notes', 'dispatcher_notes']), order_id: invoice.order.id, invoice_notes: notes, invoice_id: invoice.id, isEditable };
  const paymentsData = { ..._pick(invoice, ['id', 'payments', 'status', 'paid_amount', 'total_amount']), ..._pick(order, ['account', 'customer', 'company_location', 'authorization_number']) };
  const sendInvoiceData = _pick(invoice, ['id', 'order', 'number', 'recipients', 'total_amount', 'payments', 'created_at']);
  const documentsData = { id: order.id, model: models.ORDERS };
  const infoData = _pick(order, ['id', 'company_location', 'digital_dispatch_webhook']);
  const milageInfoData = _pick(order, ['deadhead_miles_count', 'en_route_miles_count', 'towed_miles_count']);

  useEffect(() => {
    if (!!id) {
      if (id !== invoice_id) {
        setFetchingDetails(true);
        clearLocalActiveInvoice()
        .then(() => 
          getInvoice(id)
          .then(() => setFetchingDetails(false))
          .catch(error => setError(error))
        )
      }
    }
  }, [id, invoice_id, getInvoice, clearLocalActiveInvoice, setFetchingDetails, setError]);

  if (!id) return <Placeholder model={models.INVOICES} />;
  if (!!!invoice_id || isFetching) return <PageLoader />;
  return (
    <DetailsContainer>
      <DetailsWrapper>
        <Main {...mainData} />
        <CardsGrid>
          <CustomerCard sx={{ gridArea: { lg: '1 / 1 / 2 / 2' }, maxWidth: 'unset !important' }} {...customerData} />
          <VehicleCard sx={{ gridArea: { lg: '1 / 2 / 2 / 3' }, maxWidth: 'unset !important' }} {...vehicleData} />
          <PaymentCard sx={{ gridArea: { lg: '2 / 1 / 3 / 2', xlg: '1 / 3 / 2 / 4' }, maxWidth: 'unset !important' }} {..._pick(paymentsData, ['payments', 'status', 'company_location'])} />
          <DriverCard sx={{ gridArea: { lg: '2 / 2 / 3 / 3', xlg: '2 / 1 / 3 / 2' }, maxWidth: 'unset !important' }} {...driverData} />
          <MilageInfoCard sx={{ gridArea: { lg: '3 / 1 / 4 / 2', xlg: '3 / 1 / 4 / 2' }, maxWidth: 'unset !important' }} {...milageInfoData} />
          <NotesCard sx={{ gridArea: { lg: '3 / 2 / 4 / 3', xlg: '4 / 1 / 5 / 2' }, maxWidth: 'unset !important' }} edit {...notesData} />
          <MapCard sx={{ gridArea: { lg: '4 / 1 / 5 / 2', xlg: '2 / 2 / 5 / 4' }, width: '100% !important' }} {...mapData} />
          <DocumentsCard sx={{ gridArea: { lg: '4 / 2 / 5 / 3', xlg: '5 / 1 / 6 / 4' }, width: '100% !important' }} {...documentsData} />
          <Info sx={{ gridArea: { lg: '5 / 1 / 5 / 2', xlg: '6 / 1 / 7 / 4' }, width: '100% !important', overflow: 'hidden' }} {...infoData} />
        </CardsGrid>
      </DetailsWrapper>
      <PaymentDrawer {...paymentsData} />
      <SendInvoice {...sendInvoiceData} />
    </DetailsContainer>
  );
};

export default memo(Details);
import { memo, useState} from 'react';
import {TextField, MenuItem, FormControlLabel, Checkbox, Typography} from '@mui/material/';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card'; 
import Box from '@mui/material/Box';
import _map from 'lodash/map';
//Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import BaseAddressAutocomplete from 'components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useVehicles from 'hooks/useVehicles';
import { reasons, states, receiverTypes,} from 'helpers/constants';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const ReleaseVehicle = () => {
  const { closeDrawer } = useApp();
  const { releaseVehicle, clearLocalActiveVehicle } = useVehicles();
  const uploadedVehicle = useCustomSelector(state => state.vehicles.vehicle);
  const { open} = useCustomSelector(state => state.app.releaseVehicle);
  const [receiverType, setReceiverType] = useState({ value: '', error: '' });
  const [name, setName] = useState({ value: '', error: '' });
  const [address, setAddress] = useState({ value: '', error: '' });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [licenseState, setLicenseState] = useState({ value: '', error: '' });
  const [driverLicense, setDriverLicense] = useState({ value: '', error: '' });
  const [updateCustomer, setUpdateCustomer] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { setError } = useError();
  const { setSuccess } = useSuccess();

  const handleClose = () => closeDrawer(reasons.RELEASE_VEHICLE); 

  const handleExiting = () => {
    setReceiverType({ value: '', error: '' });
    setName({ value: '', error: '' });
    setAddress({ value: '', error: '' });
    setPhone({ value: '', formattedValue: '', error: '' });
    setEmail({ value: '', error: '' });
    setLicenseState({ value: '', error: '' });
    setDriverLicense({ value: '', error: '' });
  };

  const handleSubmitClick = () => {
    let hasError = false;

    if (!!!receiverType.value) {
      setReceiverType(prev => ({ ...prev, error: `Receiver can't be empty` }));
      hasError = true;
    }
    if (name.value.length < 2) {
      setName(prev => ({ ...prev, error: `Name should be more than 2 symbols` }));
      hasError = true;
    }
    if (!name.value) {
      setName(prev => ({ ...prev, error: `Name can't be empty` }));
      hasError = true;
    }
    if (hasError) return;

    const processingReleasedVehicle = {
      id: uploadedVehicle?.id,
      receiver_type: receiverType.value,
      name: name.value,
      update_customer: updateCustomer,
      ...(!!address.value && { address: address.value }),
      ...(!!phone.value && { phone: phone.value }),
      ...(!!email.value && { email: email.value }),
      ...(!!licenseState.value && { license_state: licenseState.value }),
      ...(!!driverLicense.value && { driver_license: driverLicense.value })
    };

    setProcessing(true);
    releaseVehicle(processingReleasedVehicle) 
    .then(() => setSuccess('Vehicle successfully released').then(() => {
      clearLocalActiveVehicle();
      handleClose();
    }))    
    .catch((e) => setError(e))
    .finally(() => setProcessing(false));
  };

  return (
    <BaseDrawer
      grey
      open={open}
      onClose={handleClose}
      disabled={processing}
      title='RELEASE VEHICLE'
      SlideProps={{ onExited: handleExiting }}
      content={
        <>
          <Card sx={{ p: '24px' }}>
          <Typography variant='title' sx={{ p: '0px 0 8px'  }}>Release Information</Typography>
          <TextField 
              fullWidth
              margin='dense'
              label='Release to' 
              value={receiverType.value} 
              select
              helperText={receiverType.error} 
              error={!!receiverType.error} 
              onChange={({ target: { value } }) => setReceiverType({ value, error: '' })} 
              SelectProps={{ MenuProps: { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, sx: { height: '40%' } } }}
            >
              { _map(receiverTypes, (type) => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>) }
            </TextField>
          <TextField
              fullWidth
              margin='dense'
              label='Release Name'
              value={name.value}
              error={!!name.error}
              helperText={name.error}
              onChange={({ target: { value } }) => setName({ value, error: '' })}
            />
            <BaseAddressAutocomplete
              id='user-location'
              margin='dense'
              label='Release Address'
              value={address.value}
              error={!!address.error}
              helperText={address.error}
              onChange={({ value, error = '' }) => setAddress({ value, error })}
            />
            <PhoneField
            fullWidth
            margin='dense'
            label='Release Phone'
            formattedValue={phone.formattedValue}
            error={!!phone.error}
            helperText={phone.error}
            onChange={({ value, formattedValue, error }) => setPhone({ value, formattedValue, error })}
          />
            <TextField
              fullWidth
              margin='dense'
              label='Release Email'
              type='email'
              value={email.value}
              error={!!email.error}
              helperText={email.error}
              onChange={({ target: { value } }) => setEmail({ value, error: '' })}
            />
          <TextField 
              fullWidth
              margin='dense'
              label='License State' 
              value={licenseState.value} 
              select
              helperText={licenseState.error} 
              error={!!licenseState.error} 
              onChange={({ target: { value } }) => setLicenseState({ value, error: '' })} 
              SelectProps={{ MenuProps: { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, sx: { height: '40%' } } }}
            >
              { _map(states, (state) => <MenuItem key={state} value={state}>{state}</MenuItem>) }
            </TextField>
            <TextField 
                fullWidth
                margin='dense'
                label='Driver License' 
                value={driverLicense.value} 
                helperText={driverLicense.error} 
                error={!!driverLicense.error} 
                onChange={({ target: { value } }) => setDriverLicense({ value, error: '' })} 
            />
            <FormControlLabel
        label='Replace Customer Info on Invoice'
        control={
          <Checkbox
          checked={updateCustomer}
          onChange={({ target: { checked } }) => setUpdateCustomer(checked)}
          />
        }
      />
          </Card>
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: '40px'}}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmitClick}
            >
              Release Vehicle
            </Button>
          </Box>
        </>
      }
    />
  );
};

export default memo(ReleaseVehicle);
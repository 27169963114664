import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getListTrucks as getListTrucksAction,
  getTrucks as getTrucksAction,
  getTruck as getTruckAction,
  createTruck as createTruckAction,
  updateTruck as updateTruckAction,
  deleteTruck as deleteTruckAction,
  clearLocalListTrucks as clearLocalListTrucksAction,
  clearLocalTrucks as clearLocalTrucksAction,
  clearLocalActiveTruck as clearLocalActiveTruckAction
} from 'actions/trucks';

const useTrucks = () => {
  const dispatch = useDispatch();
  const getListTrucks = useCallback(({ offset }) => dispatch(getListTrucksAction({ offset })), [dispatch]);
  const getTrucks = useCallback(({ offset, orders, query, company_ids }) => dispatch(getTrucksAction({ offset, orders, query, company_ids })), [dispatch]);
  const getTruck = useCallback(id => dispatch(getTruckAction(id)), [dispatch]);
  const createTruck = useCallback(truck => dispatch(createTruckAction(truck)), [dispatch]);
  const updateTruck = useCallback(truck => dispatch(updateTruckAction(truck)), [dispatch]);
  const deleteTruck = useCallback(id => dispatch(deleteTruckAction(id)), [dispatch]);
  const clearLocalListTrucks = useCallback(() => dispatch(clearLocalListTrucksAction()), [dispatch]);
  const clearLocalTrucks = useCallback(() => dispatch(clearLocalTrucksAction()), [dispatch]);
  const clearLocalActiveTruck = useCallback(() => dispatch(clearLocalActiveTruckAction()), [dispatch]);

  return {
    getListTrucks,
    getTrucks,
    getTruck,
    createTruck,
    updateTruck,
    deleteTruck,
    clearLocalListTrucks,
    clearLocalTrucks,
    clearLocalActiveTruck
  };
};

export default useTrucks;
import {
  getDriversListRoutine,
  getManagersListRoutine,
  getUsersRoutine,
  getUsersStatisticsRoutine,
  getUserRoutine,
  createUserRoutine,
  updateUserRoutine,
  deleteUserRoutine,
  setActualDriverDataRoutine,
  clearLocalDriversListRoutine,
  clearLocalManagersListRoutine,
  clearLocalUsersRoutine,
  clearLocalUsersStatisticsRoutine,
  clearLocalActiveUserRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

export const initialState = {
  drivers: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  managers: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  statistics: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  user: {
    id: '',
    first_name: '',
    last_name: '',
    photo: null,
    previous_names: '',
    roles: [],
    birth_at: '',
    email: '',
    phone: '',
    second_phone: '',
    emergency_phone: '',
    second_emergency_phone: '',
    address: '',
    latitude: 0,
    longitude: 0,
    companies: [],
    notifications: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getDriversListRoutine.SUCCESS: {
      const { data: { users, pagination } } = action.payload;

      return { ...state, drivers: { ...state.drivers, data: _uniqBy([...state.drivers.data, ...users], 'id'), pagination } };
    }
    case getManagersListRoutine.SUCCESS: {
      const { data: { users, pagination } } = action.payload;

      return { ...state, managers: { ...state.managers, data: _uniqBy([...state.managers.data, ...users], 'id'), pagination } };
    }
    case getUsersRoutine.SUCCESS: {
      const { data: { users, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...users], pagination } };
    }
    case getUsersStatisticsRoutine.SUCCESS: {
      const { data: { users, pagination } } = action.payload;

      return { ...state, statistics: { data: [...state.statistics.data, ...users], pagination } };
    }
    case getUserRoutine.SUCCESS: {
      const { data: { user } } = action.payload;

      return { ...state, user };
    }
    case createUserRoutine.SUCCESS: {
      const { data: { user } } = action.payload;
      const oldData = (state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data);
      const data = [...[user], ...oldData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateUserRoutine.SUCCESS: {
      const { response: { status }, user } = action.payload;
      const updatedUser = { ...state.user, ...user, ...(!!user.photo && { photo: { id: user.photo, localPhoto: user.localPhoto } }) };
      const data = _map(state.all.data, (item) => item.id === user.id
        ? { ...item, ...user, ...(!!user.photo && { photo: { id: user.photo, localPhoto: user.localPhoto } }) }
        : item
      );

      return {
        ...state,
        ...(status === 204 && { user: updatedUser }),
        ...(status === 204 && { all: { ...state.all, data } })
      };
    }
    case deleteUserRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case clearLocalDriversListRoutine.SUCCESS: {
      return { ...state, drivers: initialState.drivers }
    }
    case clearLocalManagersListRoutine.SUCCESS: {
      return { ...state, managers: initialState.managers }
    }
    case clearLocalUsersRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalUsersStatisticsRoutine.SUCCESS: {
      return { ...state, statistics: initialState.statistics }
    }
    case setActualDriverDataRoutine.SUCCESS: {
      const { id, latitude, longitude } = action.payload;

      return { ...state, ...(state.user.id === id && !_isUndefined(latitude) && !_isUndefined(longitude) && { user: { ...state.user, latitude, longitude } }) };
    }
    case clearLocalActiveUserRoutine.SUCCESS: {
      return { ...state, user: initialState.user }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
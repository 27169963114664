import { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import FilterIcon from '@mui/icons-material/FilterListRounded';
import Add from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// Local files
import { Header, Root } from './Filter.styled';
import Search from 'components/Common/Search/Search';
import FilterButton from '../FilterButton/FilterButton';
import FilterContent from '../FilterContent/FilterContent';

const Filter = ({ query, filterContent, onQueryChange, onAddClick = null, onFilterClick, filterTypeButton = 'text', statusesCount = 0 }) => {
  return (
    <Root>
      <Header>
        <Search value={query} onChange={onQueryChange} />
        {!!onAddClick && <IconButton variant='outlined' onClick={onAddClick}><Add /></IconButton>}
        {(!!filterContent && filterTypeButton === 'icon-button') && <IconButton variant='outlined' onClick={onFilterClick}><Badge variant='dot' color='primary' invisible={!statusesCount}><FilterIcon /></Badge></IconButton>}
      </Header>
      <FilterContent>{filterContent}</FilterContent>
      {(!!filterContent && filterTypeButton === 'text') && <Box sx={{ mt: 1 }}><FilterButton onClick={onFilterClick} {...{statusesCount}} /></Box>}
    </Root>
  );
};

Filter.propTypes = {
  query: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onAddClick: PropTypes.oneOfType([ PropTypes.func, PropTypes.bool ]),
};

export default memo(Filter);
import {
  getMapDriversRoutine,
  getMapOrdersRoutine,
  changeLocalOrderStatusRoutine,
  setActualDriverDataRoutine,
  getUrgentlyMapDriversRoutine,
  clearLocalMapDataRoutine,
  supplementOrderRoutine,
  rejectOrderRoutine,
  startOrderMovementRoutine,
  pickUpOrderRoutine,
  dropOffOrderRoutine,
  completeOrderRoutine,
  setMapConfigsRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import { orderStatuses } from 'helpers/constants';

export const initialState = {
  points: [],
  configs: {
    viewState: { latitude: 37.751002, longitude: -122.443905, zoom: 10 },
    bounds: { latitudeGt: 37.695332, latitudeLt: 37.806630, longitudeGt: -122.588101, longitudeLt: -122.299710 },
    popup: { open: false, latitude: null, longitude: null, cluster: false, data: [], activePoint: null },
    localIncident: { value: '', latitude: 0, longitude: 0, }
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getMapDriversRoutine.SUCCESS: {
      const { drivers } = action.payload;
      const points = _map(drivers, ({ id, longitude, latitude, ...data }) => ({
        type: 'Feature',
        properties: { id, cluster: false, category: 'driver', data },
        geometry: { type: 'Point', coordinates: [longitude, latitude] }
      }));

      return { ...state, points: _uniqBy([...state.points, ...points], 'properties.id') };
    }
    case getUrgentlyMapDriversRoutine.SUCCESS: {
      const { drivers } = action.payload;
      const points = _map(drivers, ({ id, longitude, latitude, ...data }) => ({
        type: 'Feature',
        properties: { id, cluster: false, category: 'driver', data },
        geometry: { type: 'Point', coordinates: [longitude, latitude] }
      }));
      return { ...state, points: _uniqBy([...state.points, ...points], 'properties.id') };
    }
    case getMapOrdersRoutine.SUCCESS: {
      const { orders } = action.payload;
      const points = _map(orders, ({ id, incident_location, ...data }) => ({
        type: 'Feature',
        properties: { id, cluster: false, category: 'order', data },
        geometry: { type: 'Point', coordinates: [incident_location?.longitude, incident_location?.latitude] }
      }));

      return { ...state, points: _uniqBy([...state.points, ...points], 'properties.id') };
    }
    case changeLocalOrderStatusRoutine.SUCCESS: {
      const { order_id, status } = action.payload;

      const points = _map(state.points, (point) => (point?.properties?.id === order_id || point.properties.data?.current_order?.id === order_id) ?
        {
          ...point,
          properties: {
            ...point.properties,
            data: {
              ...point.properties.data,
              ...(point.properties?.category === 'order' && { status }),
              ...(point.properties?.category === 'driver' && { current_order: { ...point.properties.data?.current_order, status } })
            }
          }
        } : point
      );

      return {
        ...state,
        points: _uniqBy([...points, ...state.points], 'properties.id'),
        configs: {
          ...state.configs,
          ...(state.configs.popup.open && state.configs.popup.activePoint?.id === order_id && {
            popup: {
              ...state.configs.popup,
              activePoint: {
                ...state.configs.popup.activePoint,
                data: {
                  ...state.configs.popup.activePoint?.data,
                  ...(state.configs.popup.activePoint.category === 'order' && { status }),
                  ...(state.configs.popup.activePoint.category === 'driver' && { current_order: { ...state.configs.popup.activePoint?.data?.current_order, status } })
                }
              }
            }
          })
        }
      };
    }
    case setActualDriverDataRoutine.SUCCESS: {
      const { id, latitude, longitude, heading, duty_duration } = action.payload;
      const foundedPoint = _find(state.points, p => p.properties.id === id);
      const updatedPoint = !!foundedPoint ? {
        ...foundedPoint,
        properties: {
          ...foundedPoint.properties,
          data: { ...foundedPoint.properties.data, ...!_isUndefined(heading) && { heading }, ...!_isUndefined(duty_duration) && { duty_duration } }
        },
        geometry: { ...foundedPoint.geometry, ...!_isUndefined(latitude) && !_isUndefined(longitude) && { coordinates: [longitude, latitude] } }
      } : null;

      return {
        ...state,
        ...(!!foundedPoint && {
          points: _map(state.points, p => p.properties.id === foundedPoint.properties.id ? updatedPoint : p)
        }),
        ...state.configs.popup.activePoint?.id === id && {
          configs: {
            ...state.configs,
            popup: {
              ...state.configs.popup,
              activePoint: {
                ...state.configs.popup.activePoint,
                data: {
                  ...state.configs.popup.activePoint.data,
                  ...!_isUndefined(latitude) && { latitude },
                  ...!_isUndefined(longitude) && { longitude },
                  ...!_isUndefined(duty_duration) && { duty_duration }
                }
              }
            }
          }
        }
      };
    }
    case clearLocalMapDataRoutine.SUCCESS: {
      const { type } = action.payload;

      if (type) {
        return { ...state, points: _filter(state.points, p => p.properties.category !== type) };
      }
      return { ...state, points: initialState.points };
    }
    case supplementOrderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundedPoint = _find(state.points, p => p.properties.id === id);
      const foundedDataItem = _find(state.configs.popup.data, d => d.id === id);
      const updatedPoints = _map(state.points, p => p.properties.id === id
        ? { ...p, properties: { ...p.properties, data: { ...p.properties.data, status: orderStatuses.MANAGER_ACCEPTED } } }
        : p
      );
      const updatedConfigs = {
        ...state.configs,
        popup: {
          ...state.configs.popup,
          data: _map(state.configs.popup.data, d => d.id === id ? { ...d, data: { ...d.data, status: orderStatuses.MANAGER_ACCEPTED } } : d),
          ...(state.configs.popup.activePoint?.id === id && { activePoint: { ...state.configs.popup.activePoint, data: { ...state.configs.popup.activePoint.data, status: orderStatuses.MANAGER_ACCEPTED } } })
        }
      };

      return {
        ...state,
        ...(status === 204 && {
          ...(!!foundedPoint && { points: updatedPoints }),
          ...(!!foundedDataItem && { configs: updatedConfigs })
        })
      };
    }
    case rejectOrderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundedPoint = _find(state.points, p => p.properties.id === id);
      const foundedDataItem = _find(state.configs.popup.data, d => d.id === id);
      const updatedPoints = _map(state.points, p => p.properties.id === id
        ? { ...p, properties: { ...p.properties, data: { ...p.properties.data, status: orderStatuses.REJECTED } } }
        : p
      );
      const updatedConfigs = {
        ...state.configs,
        popup: {
          ...state.configs.popup,
          data: _map(state.configs.popup.data, d => d.id === id ? { ...d, data: { ...d.data, status: orderStatuses.REJECTED } } : d),
          ...(state.configs.popup.activePoint?.id === id && { activePoint: { ...state.configs.popup.activePoint, data: { ...state.configs.popup.activePoint.data, status: orderStatuses.REJECTED } } })
        }
      };

      return {
        ...state,
        ...(status === 204 && {
          ...(!!foundedPoint && { points: updatedPoints }),
          ...(!!foundedDataItem && { configs: updatedConfigs })
        })
      };
    }
    case startOrderMovementRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundedPoint = _find(state.points, p => p.properties.id === id);
      const foundedDataItem = _find(state.configs.popup.data, d => d.id === id);
      const updatedPoints = _map(state.points, p => p.properties.id === id
        ? { ...p, properties: { ...p.properties, data: { ...p.properties.data, status: orderStatuses.EN_ROUTE } } }
        : p
      );
      const updatedConfigs = {
        ...state.configs,
        popup: {
          ...state.configs.popup,
          data: _map(state.configs.popup.data, d => d.id === id ? { ...d, data: { ...d.data, status: orderStatuses.EN_ROUTE } } : d),
          ...(state.configs.popup.activePoint?.id === id && { activePoint: { ...state.configs.popup.activePoint, data: { ...state.configs.popup.activePoint.data, status: orderStatuses.EN_ROUTE } } })
        }
      };

      return {
        ...state,
        ...(status === 204 && {
          ...(!!foundedPoint && { points: updatedPoints }),
          ...(!!foundedDataItem && { configs: updatedConfigs })
        })
      };
    }
    case pickUpOrderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundedPoint = _find(state.points, p => p.properties.id === id);
      const foundedDataItem = _find(state.configs.popup.data, d => d.id === id);
      const updatedPoints = _map(state.points, p => p.properties.id === id
        ? { ...p, properties: { ...p.properties, data: { ...p.properties.data, status: orderStatuses.PICK_UP } } }
        : p
      );
      const updatedConfigs = {
        ...state.configs,
        popup: {
          ...state.configs.popup,
          data: _map(state.configs.popup.data, d => d.id === id ? { ...d, data: { ...d.data, status: orderStatuses.PICK_UP } } : d),
          ...(state.configs.popup.activePoint?.id === id && { activePoint: { ...state.configs.popup.activePoint, data: { ...state.configs.popup.activePoint.data, status: orderStatuses.PICK_UP } } })
        }
      };

      return {
        ...state,
        ...(status === 204 && {
          ...(!!foundedPoint && { points: updatedPoints }),
          ...(!!foundedDataItem && { configs: updatedConfigs })
        })
      };
    }
    case dropOffOrderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundedPoint = _find(state.points, p => p.properties.id === id);
      const foundedDataItem = _find(state.configs.popup.data, d => d.id === id);
      const updatedPoints = _map(state.points, p => p.properties.id === id
        ? { ...p, properties: { ...p.properties, data: { ...p.properties.data, status: orderStatuses.DROP_OFF } } }
        : p
      );
      const updatedConfigs = {
        ...state.configs,
        popup: {
          ...state.configs.popup,
          data: _map(state.configs.popup.data, d => d.id === id ? { ...d, data: { ...d.data, status: orderStatuses.DROP_OFF } } : d),
          ...(state.configs.popup.activePoint?.id === id && { activePoint: { ...state.configs.popup.activePoint, data: { ...state.configs.popup.activePoint.data, status: orderStatuses.DROP_OFF } } })
        }
      };

      return {
        ...state,
        ...(status === 204 && {
          ...(!!foundedPoint && { points: updatedPoints }),
          ...(!!foundedDataItem && { configs: updatedConfigs })
        })
      };
    }
    case completeOrderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundedPoint = _find(state.points, p => p.properties.id === id);
      const foundedDataItem = _find(state.configs.popup.data, d => d.id === id);
      const updatedPoints = _map(state.points, p => p.properties.id === id
        ? { ...p, properties: { ...p.properties, data: { ...p.properties.data, status: orderStatuses.COMPLETED } } }
        : p
      );
      const updatedConfigs = {
        ...state.configs,
        popup: {
          ...state.configs.popup,
          data: _map(state.configs.popup.data, d => d.id === id ? { ...d, data: { ...d.data, status: orderStatuses.COMPLETED } } : d),
          ...(state.configs.popup.activePoint?.id === id && { activePoint: { ...state.configs.popup.activePoint, data: { ...state.configs.popup.activePoint.data, status: orderStatuses.COMPLETED } } })
        }
      };

      return {
        ...state,
        ...(status === 204 && {
          ...(!!foundedPoint && { points: updatedPoints }),
          ...(!!foundedDataItem && { configs: updatedConfigs })
        })
      };
    }
    case setMapConfigsRoutine.SUCCESS: {
      const { viewState, bounds, popup, localIncident } = action.payload;

      return {
        ...state,
        configs: {
          ...state.configs,
          ...(viewState && { viewState }),
          ...(bounds && { bounds }),
          ...(popup && { popup }),
          ...(localIncident &&  { localIncident })
        }
      };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
import _find from 'lodash/find';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import defaultDoc from 'assets/images/file/default.png';
import CategoriesAutocomplete from 'components/Categories/Autocomplete/Autocomplete';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useOrders from 'hooks/useOrders';
import useDialogs from 'hooks/useDialogs';
import useUrl from 'hooks/useUrl';
import useDocuments from 'hooks/useDocuments';
import { sides } from 'helpers/constants';
import { mapboxAccessToken, mapboxSearchUrl } from 'apis';
import useLocations from 'hooks/useLocations';

const Documents = ({ id, model }) => {
  const { closeDocumentsDialog } = useDialogs();
  const { updateOrder, setLocalOrderFields } = useOrders();
  const { createDocuments, getDocuments, clearLocalDocuments } = useDocuments();
  const { createLocation } = useLocations();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { isOrdersRoute, isInvoicesRoute, isVehiclesRoute } = useUrl();
  const localDocuments = useCustomSelector(state => state.orders.order.localDocuments);
  const { open, documents, isOrderDrawer } = useCustomSelector(state => state.dialogs.documents);
  const [files, setFiles] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const category_type = model?.slice(0, model.length - 1);
  const disabled = !!_find(files, (file) => !!!file.category)

  const handleEntering = () => { 
    handleChangeLocation();
    setFiles(documents);
  };
  const handleExited = () => setFiles([]);
  const handleChangeLocation = () => {
    const fetchLocation= async (q) => await mapboxSearchUrl.get(`/${q}.json?access_token=${mapboxAccessToken}&autocomplete=true&country=US&language=en`);

    window.navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setProcessing(true);
        fetchLocation(`${coords?.longitude},${coords?.latitude}`)
        .then(({ data: { features } }) =>  
          features.length !== 0 ? createLocation({ location: { 
            address: features[0]?.place_name, 
            longitude: features[0]?.geometry?.coordinates[0],
            latitude: features[0]?.geometry?.coordinates[1] 
          }, side: sides.USER })
          .then(({ payload: { data: { location } } }) => setLocationId(location.id))
          .catch((error) => setError(error))
          .finally(() => setProcessing(false)) : setProcessing(false)
        )
      }, (_) => setError('Please turnon your location and give permissions in browser'))
  };
  const handleChangeCategory = (value, id) => setFiles(prev => _map(prev, (file) => file.id === id ? { ...file, category: value } : file));
  const fetchDocuments = (id, model, offset) => {
    setProcessing(true);
    getDocuments({ id, model, offset })
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  };
  const uploadDocuments = (newDocuments) => 
    (isOrdersRoute || isInvoicesRoute || isVehiclesRoute) ? updateOrder({ 
      id, 
      side: sides.USER,
      documents_attributes: _map(newDocuments, ({ id, category }) => ({ file: id, category_id: category.id, location_id: locationId })),
      localDocuments: newDocuments
    }) : createDocuments({ id, model, documents: _map(newDocuments, ({ id, category }) => ({ file: id, category_id: category.id, location_id: locationId })), newDocuments });
  const handleSubmit = () => {
    const newDocuments = _map(files, ({ id, filename, localUrl, content_type, category }) => ({ id, file: { id, filename, localUrl, content_type }, category }));

    if (isOrderDrawer) {
      setLocalOrderFields({ localDocuments: newDocuments.concat(localDocuments) }).then(closeDocumentsDialog)
    } else {
      setProcessing(true);
      uploadDocuments(newDocuments)
      .then(() => 
        setSuccess('Documents successfully uploaded')
        .then(() => closeDocumentsDialog().then(() => clearLocalDocuments().then(() => fetchDocuments(id, model, 0))))
      )
      .catch(error => setError(error))
      .finally(() => setProcessing(false))
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeDocumentsDialog}
      PaperProps={{ sx: { width: 640,  borderRadius: '16px' } }}
      title='Documents'
      TransitionProps={{ onEntering: handleEntering, onExited: handleExited }}
    >
      <DialogContent>
        {_map(files, ({ id, filename, localUrl, category }) => <Box key={id} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '40%', display: 'flex', alignItems: 'center'  }}>
            <img
              style={{ flexShrink: 0, height: 32, width: 'auto', objectFit: 'cover', borderRadius: '2px' }}
              src={localUrl ?? defaultDoc}
              alt='preview'
              data-private
            />
            <Typography noWrap align='left' sx={{ textAlign: 'center', pl: 1 }}>{filename}</Typography>
          </Box>
          <CategoriesAutocomplete
            id='category'
            category_type={category_type}
            value={category}
            onChange={(value) => handleChangeCategory(value, id)}
          />
        </Box> )}
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={closeDocumentsDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: disabled ? 'text.hint' : 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          disabled={processing || disabled}
          onClick={handleSubmit}
        >
          Upload
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(Documents);
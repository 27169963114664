import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

export const Container = styled(Box)(({ theme }) => ({
  height: 800,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    height: 590,
    flexDirection: 'row',
  }
}));

export const ListContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '100%',
  height: 300,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  borderBottom: `1px solid ${theme.palette.background.selected}`,
  padding: '16px 0 0px',
  [theme.breakpoints.up('lg')]: {
    width: 290,
    minWidth: 290,
    height: '100%',
    borderBottom: 'unset',
    borderRight: `1px solid ${theme.palette.background.selected}`,
    padding: '24px 0 32px',
  }
}));

export const ListHeader = styled(Box)({
  padding: '0px 32px 8px 32px',
});

export const DetailsContainer = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  paddingBottom: 32,
  '& > *': {
    paddingLeft: 24,
    paddingRight: 24,
  }
});

export const StyledTab = styled(Tab)({
  minWidth: '90px',
  minHeight: 'unset',
})

export const AddButton = styled(props => (
  <Button startIcon={<AddIcon />} {...props} />
))({
  marginTop: 20,
  marginLeft: '-8px'
});

export const DetailsPlaceholder = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '18px',
});


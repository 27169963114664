import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getListInsuranceAccounts as getListInsuranceAccountsAction,
  clearLocalListInsuranceAccounts as clearLocalListInsuranceAccountsAction
} from 'actions/insuranceAccounts';

const useInsuranceAccounts = () => {
  const dispatch = useDispatch();
  const getListInsuranceAccounts = useCallback(({ offset, orders }) => dispatch(getListInsuranceAccountsAction({ offset, orders })), [dispatch]);
  const clearLocalListInsuranceAccounts = useCallback(() => dispatch(clearLocalListInsuranceAccountsAction()), [dispatch]);

  return { 
    getListInsuranceAccounts, 
    clearLocalListInsuranceAccounts
  };
};

export default useInsuranceAccounts;
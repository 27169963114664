import {
  getListAccountsRoutine, 
  getAccountsRoutine,
  getAccountRoutine,
  createAccountRoutine,
  duplicateAccountRoutine,
  updateAccountRoutine,
  deleteAccountRoutine,
  clearLocalListAccountsRoutine,
  clearLocalAccountsRoutine,
  clearLocalActiveAccountRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  account: { 
    id: '', 
    name: '', 
    phone: '', 
    email: '', 
    fax: '', 
    contact_name: '', 
    contact_phone: '', 
    contact_email: '', 
    billing_email: '', 
    second_billing_email: '', 
    third_billing_email: '',
    billing_address: '', 
    physical_address: '', 
    notes: '', 
    created_at: '',
    jobs: [],
    insurance_accounts: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListAccountsRoutine.SUCCESS: {
      const { data: { accounts, pagination } } = action.payload;

      return { ...state, list: { data: [...state.list.data, ...accounts], pagination } };
    }
    case getAccountsRoutine.SUCCESS: {
      const { data: { accounts, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...accounts], pagination } };
    }
    case getAccountRoutine.SUCCESS: {
      const { data: { account } } = action.payload;

      return { ...state, account };
    }
    case createAccountRoutine.SUCCESS: {
      const { data: { account } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[account], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case duplicateAccountRoutine.SUCCESS: {
      const { data: { account } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[account], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateAccountRoutine.SUCCESS: {
      const { response: { status }, account } = action.payload;
      const updatedAccount = { ...state.account, ...account, company: account.localCompany, insurance_accounts: account.localInsuranceAccounts };
      const data = _map(state.all.data, (item) => item.id === account.id ? 
      { ...item, ...account, company: account.localCompany, insurance_accounts: account.localInsuranceAccounts } : item);

      return { ...state, ...(status === 204 && { account: updatedAccount }), all: { ...state.all, data } };
    }
    case deleteAccountRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case clearLocalListAccountsRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalAccountsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveAccountRoutine.SUCCESS: {
      return { ...state, account: initialState.account }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
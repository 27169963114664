import { memo, useCallback, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// Local files
import { Root } from './StripeForm.styled';
import useError from 'hooks/useError';

const StripeForm = ({ name, card, processing, changeCard, changeStripeToken }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setError } = useError();

  const handleCreateCard = useCallback(() => {
    stripe.createToken(elements.getElement(CardElement), { name, address_country: 'US' }).then(({ token, error }) => {
      if (!!error) {
        setError(error?.message)
      } else {
        changeStripeToken(token.id);
      }
    }) 
  }, [name, elements, stripe, changeStripeToken, setError]);

  useEffect(() => {
    processing && !!card.value && handleCreateCard();
  }, [processing, card.value, handleCreateCard]);

  return (
    <Root>
      <FormControl fullWidth error={!!card.error} data-private>
        <CardElement
          id="card"
          options={{ value: card.value }}
          onChange={changeCard}
        />
        {!!card.error && <FormHelperText id="card-text">{card.error}</FormHelperText>}
      </FormControl>
    </Root>
  );
};

export default memo(StripeForm);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getDocumentsRoutine, 
  getDocumentRoutine, 
  createDocumentsRoutine,
  deleteDocumentRoutine,
  clearLocalDocumentsRoutine,
  clearLocalActiveDocumentRoutine } from 'actions';

const attributes = ['id', { category: ['id', 'name', 'category_type'] }, { location: ['longitude', 'latitude', 'address'] }, { file: ['content_type', 'filename', 'representations', 'url'] }];

export const getDocuments = getThunkActionCreator(
  getDocumentsRoutine,
  async ({ id, model = 'orders', offset = 0, orders = { created_at: 'desc' }, query = null, category_ids = [] }) => {
    getDocuments.getDocumentsCancel && getDocuments.getDocumentsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/${model}/${id}/documents`,
      {
        params: { access_token, limit: 25, offset, orders, ...(query && { query }), ...(!!category_ids.length && { category_ids }), attributes },
        cancelToken: new CancelToken(function executor(cancel) { getDocuments.getDocumentsCancel = cancel })
      }
    );
  }
);
export const getDocument = getThunkActionCreator(
  getDocumentRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/documents/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getDocument.getDocumentCancel = cancel })
      }
    );
  }
);
export const createDocuments = getThunkActionCreator(
  createDocumentsRoutine,
  async ({ id, model, documents, newDocuments }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/${model}/${id}/documents/batch`, { access_token, documents, attributes });

    return { response, newDocuments };
  }
);
export const deleteDocument = getThunkActionCreator(
  deleteDocumentRoutine,
  async ({ id, documents }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/documents/${id}`, { params: { access_token }});

    return { response, id, documents };
  }
);
export const clearLocalDocuments = getThunkActionCreator(clearLocalDocumentsRoutine, async () => {});
export const clearLocalActiveDocument = getThunkActionCreator(clearLocalActiveDocumentRoutine, async () => {});
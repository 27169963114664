import styled from '@mui/material/styles/styled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowIcon from '@mui/icons-material/ArrowForwardIosSharp';

export const StyledAccordion = styled((props) => (
  <Accordion
    disableGutters
    elevation={0}
    square {...props}
  />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:first-of-type': {
    borderTop: 0
  },
  '&:before': {
    display: 'none'
  },
}));
export const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .Mui-expanded': {
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.action.hover
}));
import { memo, useEffect, useCallback } from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
// Local files
import BaseListItem from 'components/Common/BaseListItem/BaseListItem';
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import Status from 'components/Invoices/Status/Status';
import useCustomSelector from 'hooks/useCustomSelector';
import useInvoices from 'hooks/useInvoices';
import useError from 'hooks/useError';
import { formatCreationDate } from 'helpers';

const InvoicesList = ({ query, fetching, statuses, onFetching }) => {
  const { id: invoiceId } = useParams();
  const { getInvoices, clearLocalInvoices } = useInvoices();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const { data, pagination } = useCustomSelector(state => state.invoices.all)
  const { setError } = useError();

  const fetchInvoices = useCallback((offset, query, statuses) => {
    onFetching(true);
    getInvoices({ offset, query, statuses })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => onFetching(false));
  }, [getInvoices, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalInvoices().then(() => fetchInvoices(0, query, statuses)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [fetchInvoices, clearLocalInvoices, query, statuses]);

  return (
    <BaseScrollableContainer {...pagination} onLoad={offset => !fetching && fetchInvoices(offset, query, statuses)}>
      <List>
        {_map(data, ({ id, number, status, order, total_amount, created_at }) => (
          <BaseListItem
            key={id}
            title={number}
            description={`${order?.account?.name} — ${_startCase(order.services[0]?.name)}`}
            selected={id === invoiceId}
            to={`/invoices/${id}`}
            disabled={isFetching}
            sx={{ justifyContent: 'space-between'}}
            textSx={{ flexGrow: 0 }}
            content={
              <>
                <Status size='small' value={status} />
                <Typography variant='body2'>${parseInt(total_amount)}</Typography>
              </>
            }
            createdAt={formatCreationDate(created_at)}
          />
        ))}
      </List>
    </BaseScrollableContainer>
  );
};

export default memo(InvoicesList);
import {
  getChangesRoutine,
  getChangeRoutine,
  clearLocalChangesRoutine
} from 'actions';

export const initialState = {
  all: { data: [], pagination: { limit: 10, offset: 0, count: 0, total_count: 0 } },
  change: {
    id: '',
    user: {
      id: '',
      first_name: '',
      last_name: '',
      roles: []
    },
    change_type: '',
    comment: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getChangesRoutine.SUCCESS: {
      const { data: { changes, pagination } } = action.payload;
      return { ...state, all: { data: [...state.all.data, ...changes], pagination } };
    }
    case getChangeRoutine.SUCCESS: {
      const { data: { change } } = action.payload;

      return { ...state, change };
    }
    case clearLocalChangesRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
}

export default reducer;
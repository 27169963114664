import { memo } from 'react';
import styled from '@mui/material/styles/styled';
import Chip from '@mui/material/Chip';

export const StyledChip = styled(Chip,
  { shouldForwardProp: (prop) => prop !== 'darkBackground' }
)(({ color, darkBackground }) => ({
  height: 'unset',
  whiteSpace: 'nowrap',
  '& > *': {
    paddingLeft: 6,
    paddingRight: 6,
  },
  ...(darkBackground && color === 'primary' && {
    color: '#fff',
    borderColor: '#fff',
  }),
}))

const BaseStatus = ({ label, size, color, darkBackground }) => {
  return (
    <StyledChip
      variant='outlined'
      size={size}
      color={color}
      darkBackground={darkBackground}
      label={label}
    />
  )
}

export default memo(BaseStatus);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  getListCategoriesRoutine,
  getCategoriesRoutine, 
  getCategoryRoutine, 
  createCategoryRoutine,
  clearLocalListCategoriesRoutine,
  clearLocalCategoriesRoutine,
  clearLocalActiveCategoryRoutine } from 'actions';

const attributes = ['id', 'name', 'category_type'];

export const getListCategories = getThunkActionCreator(
  getListCategoriesRoutine,
  async ({ offset = 0, orders = { created_at: 'desc' }, category_type }) => {
    getListCategories.getListCategoriesCancel && getListCategories.getListCategoriesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/categories`,
      {
        params: { access_token, limit: 25, offset, orders, category_type, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getListCategories.getListCategoriesCancel = cancel })
      }
    );
  }
);
export const getCategories = getThunkActionCreator(
  getCategoriesRoutine,
  async ({ id, model, offset = 0, orders = { created_at: 'desc' }, query = null, category_type }) => {
    getCategories.getCategoriesCancel && getCategories.getCategoriesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/${model}/${id}/categories`,
      {
        params: { access_token, limit: 25, offset, orders, ...(query && { query }), category_type, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCategories.getCategoriesCancel = cancel })
      }
    );
  }
);
export const getCategory = getThunkActionCreator(
  getCategoryRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/categories/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCategory.getCategoryCancel = cancel })
      }
    );
  }
);
export const createCategory = getThunkActionCreator(
  createCategoryRoutine,
  async (category) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/categories`, { access_token, category, attributes });
  }
);
export const clearLocalListCategories = getThunkActionCreator(clearLocalListCategoriesRoutine, async () => {});
export const clearLocalCategories = getThunkActionCreator(clearLocalCategoriesRoutine, async () => {});
export const clearLocalActiveCategory = getThunkActionCreator(clearLocalActiveCategoryRoutine, async () => {});
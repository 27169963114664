import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getPaymentsRoutine,
  createPaymentRoutine,
  deletePaymentRoutine,
  clearLocalPaymentsRoutine
} from 'actions';

const attributes = ['id', { card: ['id', 'brand', 'last4'] }, 'payment_type', 'payer', 'amount', 'created_at'];

export const getPayments = getThunkActionCreator(
  getPaymentsRoutine,
  async ({ offset = 0, created_at_gt = null, created_at_lt = null }) => {
    getPayments.getPaymentsCancel && getPayments.getPaymentsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/payments`, {
      params: { 
        access_token, 
        offset, 
        limit: 25, 
        orders: { created_at: 'desc' }, 
        attributes: ['id', { user: ['first_name', 'last_name'] }, 'amount'], 
        ...(!!created_at_gt && { created_at_gt, created_at_lt }) 
      },
      cancelToken: new CancelToken(function executor(cancel) { getPayments.getPaymentsCancel = cancel })
    });
  }
);
export const createPayment = getThunkActionCreator(
  createPaymentRoutine,
  async ({ payment, invoice_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/invoices/${invoice_id}/payments`, { access_token, payment, attributes });

    return { response, invoice_id }
  }
);
export const deletePayment = getThunkActionCreator(
  deletePaymentRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/payments/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const clearLocalPayments = getThunkActionCreator(clearLocalPaymentsRoutine, async () => {});
import { memo } from 'react';
import List from '@mui/material/List';
import PropTypes from 'prop-types';

const StyledList = ({ children, ...otherProps }) => {
  return (
    <List
      sx={{ flex: '0 0 100px', p: '0 10px 0 0 ', overflow: 'hidden', maxHeight: '360px', overflowY: 'auto' }}
      {...otherProps}
    >
      {children}
    </List>
  );
};

StyledList.propTypes = {
  children: PropTypes.node.isRequired
};

export default memo(StyledList);
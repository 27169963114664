import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Local files
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';
import useProfile from 'hooks/useProfile';

const Password = ({ ...otherProps }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { closePasswordDialog } = useDialogs();
  const { changePassword } = useProfile();
  const { open } = useCustomSelector(state => state.dialogs.password);
  const [oldPassword, setOldPassword] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [repeatPassword, setRepeatPassword] = useState({ value: '', error: '' });
  const [showPasswordSymbols, setShowPasswordSymbols] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleExited = () => {
    setPassword({ value: '', error: '' });
    setRepeatPassword({ value: '', error: '' });
    setShowPasswordSymbols(false);
    setProcessing(false);
  };
  const handleSaveClick = () => {
    let hasError = false;

    if (oldPassword.value.length < 6) {
      setOldPassword(prev => ({ ...prev, error: 'Old password is too short' }));
      hasError = true;
    }
    if (password.value.length < 6) {
      setPassword(prev => ({ ...prev, error: 'Password is too short' }));
      hasError = true;
    }
    if (repeatPassword.value.length < 6) {
      setRepeatPassword(prev => ({ ...prev, error: 'Password is too short' }));
      hasError = true;
    }
    if (!!password.value && !!repeatPassword.value && password.value !== repeatPassword.value) {
      setPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      setRepeatPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      hasError = true;
    }
    if (hasError) return;

    setProcessing(true);
    changePassword({ current_password: oldPassword.value, password: password.value  })
    .then(() => setSuccess(`Password successfully changed`).then(() => closePasswordDialog()))
    .catch(error => setError(error))
    .finally(() => setProcessing(false));
  };

  return (
    <BaseDialog
      open={open}
      onClose={closePasswordDialog}
      hasTransition={false}
      TransitionProps={{ onExited: handleExited }}
      title='Change current password'
      otherPaperStyle={{ maxWidth: '380px' }}
      otherTitleStyle={{ textAlign: 'center' }}
      {...otherProps}
    >
      <DialogContent>
      <TextField
          fullWidth
          margin='dense'
          label='Enter your old password'
          type={showPasswordSymbols ? 'text' : 'password'}
          value={oldPassword.value}
          onChange={({ target: { value } }) => setOldPassword({ value, error: '' })}
          error={!!oldPassword.error}
          helperText={oldPassword.error}
          autoComplete='old-password'
          InputProps={{
            sx: { pr: 0, '& input': { pr: '48px' } },
            endAdornment: (
              <IconButton
                aria-label="show password"
                disableRipple
                onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                sx={{ position: 'absolute', right: '8px' }}
              >
                {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            )
          }}
        />
        <TextField
          fullWidth
          margin='dense'
          label='Enter new password'
          type={showPasswordSymbols ? 'text' : 'password'}
          value={password.value}
          onChange={({ target: { value } }) => { setPassword({ value, error: '' }); setRepeatPassword(prev => ({ ...prev, error: '' })) }}
          error={!!password.error}
          helperText={password.error}
          autoComplete='new-password'
          InputProps={{
            sx: { pr: 0, '& input': { pr: '48px' } },
            endAdornment: (
              <IconButton
                aria-label="show password"
                disableRipple
                onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                sx={{ position: 'absolute', right: '8px' }}
              >
                {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            )
          }}
        />
        <TextField
          fullWidth
          margin='dense'
          label='Repeat new password'
          type={showPasswordSymbols ? 'text' : 'password'}
          value={repeatPassword.value}
          onChange={({ target: { value } }) => { setRepeatPassword({ value, error: '' }); setPassword(prev => ({ ...prev, error: '' })) }}
          error={!!repeatPassword.error}
          helperText={repeatPassword.error}
          autoComplete='new-password'
          InputProps={{
            sx: { pr: 0, '& input': { pr: '48px' } },
            endAdornment: (
              <IconButton
                aria-label="show password"
                disableRipple
                onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                sx={{ position: 'absolute', right: '8px' }}
              >
                {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            )
          }}
        />
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={closePasswordDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleSaveClick}
        >
          Save
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(Password);
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 

export const Root = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  padding: 8,
  maxHeight: 440,
  overflow: 'hidden'
}));
export const Head = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%'
}));
export const Filter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  width: '100%',
  marginTop: 8
}));
export const Canvas = styled('canvas')({
  maxWidth: 280,
  maxHeight: 280,
  marginTop: 8
});
export const ListColumns = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  marginTop: 16,
  paddingLeft: 8,
  paddingRight: 8
}));
export const StyledList = styled(List)(({ theme }) => ({
  width: '100%', 
  overflowY: 'auto', 
  padding: 8,
  backgroundColor: theme.palette.background.paper,
  ...theme.scrollbars.default
}));
export const StyledListItem = styled(Box)(() => ({
  width: '100%', 
  display: 'flex', 
  overflow: 'hidden',
  justifyContent: 'space-between',
  paddingTop: '2px'
}));
export const DateRange = styled(DateRangePicker)(({ theme }) => ({
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
  [theme.breakpoints.down("md")]: {
    '.rdrDefinedRangesWrapper': {
      display: 'none'
    }
  },
}));
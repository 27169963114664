import { memo, useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _lowerCase from 'lodash/lowerCase';
import _startsWith from 'lodash/startsWith';
//Local files
import { Card, CardHeader, CardTitle, FieldsContainer } from 'components/Common/BasePageUi/BasePageUi';
import BaseDatePicker from 'components/Common/BaseDatePicker/BaseDatePicker';
import LocationsAutocomplete from 'components/Locations/Autocomplete/Autocomplete';
import UsersAutocomplete from 'components/Users/Autocomplete/Autocomplete';
import ServicesAutocomplete from '../Autocomplete/Autocomplete';
import TrucksAutocomplete from 'components/Trucks/Autocomplete/Autocomplete';
import { serviceClassTypes, serviceTypes, towReasonTypes } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';
import useOrderSources from 'hooks/useOrderSources';

const ServiceCard = ({ edit, duplicate, serviceData, processingData, getServiceData, onChangeProcessingData, sx }) => {
  const { openMapDialog } = useDialogs();
  const { isISSC, isUrgently } = useOrderSources(serviceData?.source);
  const locations = useCustomSelector(state => state.locations.list.data);
  const { open } = useCustomSelector(state => state.dialogs.map);
  const { configs: { popup } } = useCustomSelector(state => state.map);
  const me = useCustomSelector(state => state.profile.user);
  const [basic, setBasic] = useState({ value: '', formattedValue: null, error: '' });
  const [additional, setAdditional] = useState({ value: [], formattedValue: [], error: '' });
  const [userId, setUserId] = useState({ value: '', formattedValue: null, error: '' });
  const [dispatcherId, setDispatcherId] = useState({ value: '', formattedValue: null, error: '' });
  const [truck, setTruck] = useState({ value: '', formattedValue: null, error: '' });
  const [towReason, setTowReason] = useState({ value: '', error: '' });
  const [classType, setClassType] = useState({ value: '', error: '' });
  const [eta, setEta] = useState({ value: '', error: '' });
  const [companyLocationId, setCompanyLocationId] = useState({ value: '', formattedValue: null, error: '' });
  const [policeFields, setPoliceFields] = useState(false);
  const [policeNumber, setPoliceNumber] = useState('');
  const [policeCode, setPoliceCode] = useState('');
  const [authorizationNumber, setAuthorizationNumber] = useState('');
  const [serviceTime, setServiceTime] = useState('asap');
  const [sheduledAt, setSheduledAt] = useState({ value: '', formattedValue: null, error: '' });

  const needToUpdate = (a,b) => 
    (!!a.user && a.user?.id !== b.user_id) || 
    (!!a.dispatcher && a.dispatcher?.id !== b.dispatcher_id) || 
    (!!a.truck && a.truck?.id !== b.truck_id) || 
    a.tow_reason !== b.tow_reason || 
    a.class_type !== b.class_type || 
    a.eta !== b.eta || 
    a.company_location?.id !== b.company_location_id || 
    a.police_number !== b.police_number || 
    a.police_code !== b.police_code ||
    a.authorization_number !== b.authorization_number ||
    a.scheduled_at !== b.scheduled_at;
  const handleMapDialog = () => openMapDialog({});

  useEffect(() => {
    if (processingData) {
      let hasError = false;

      if (!!!companyLocationId.value) {
        setCompanyLocationId(prev => ({ ...prev, error: `Please select location` }));
        hasError = true;
      }
      if (hasError) {
        onChangeProcessingData(false);
        return;
      }

      const processingService = {
        ...(!edit && { service_ids: [...basic.value && [basic.value], ...additional.value] }),
        company_location_id: companyLocationId.value,
        dispatcher_id: dispatcherId.value,
        user_id: userId.value || null,
        truck_id: truck.value || null,
        tow_reason: towReason.value || null,
        class_type: classType.value || null,
        eta: eta.value || null,
        police_number: policeNumber || null,
        police_code: policeCode || null,
        authorization_number: authorizationNumber || null,
        scheduled_at: sheduledAt.value || null
      };

      getServiceData(edit ? (needToUpdate(serviceData, processingService) ? processingService : {}) : processingService);
    }
  }, [processingData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((edit || duplicate) && !!serviceData) {
      if (!!serviceData.services) {
        const basicService = _find(serviceData.services, ({ service_type }) => service_type === serviceTypes.BASIC);
        const additionalServices = _filter(serviceData.services, ({ service_type }) => service_type === serviceTypes.ADDITIONAL);

        setBasic({ value: basicService?.id, formattedValue: basicService, error: '' });
        !!additionalServices?.length && setAdditional({ value: _map(additionalServices, ({ id }) => id), formattedValue: additionalServices, error: '' });
      }
      if (!!serviceData.scheduled_at) {
        setServiceTime('scheduled');
        setSheduledAt({ value: serviceData.scheduled_at, formattedValue: new Date(serviceData.scheduled_at), error: '' });
      }
      !!serviceData.user && setUserId({ value: serviceData.user?.id, formattedValue: serviceData.user, error: '' });
      setDispatcherId({ value: serviceData.dispatcher?.id || me?.id, formattedValue: serviceData.dispatcher || me, error: '' });
      !!serviceData.truck && setTruck({ value: serviceData.truck?.id, formattedValue: serviceData.truck, error: '' });
      !!serviceData.company_location && !!!companyLocationId.value && companyLocationId.value !== undefined && setCompanyLocationId({ value: serviceData.company_location?.id, formattedValue: serviceData.company_location, error: '' });
      !!serviceData.tow_reason && setTowReason({ value: serviceData.tow_reason, error: '' });
      !!serviceData.class_type && setClassType({ value: serviceData.class_type, error: '' });
      !!serviceData.police_code && setPoliceCode(serviceData.police_code);
      !!serviceData.police_number && setPoliceNumber(serviceData.police_number);
      !!serviceData.authorization_number && setAuthorizationNumber(serviceData.authorization_number);
      !!serviceData.eta && setEta({ value: serviceData.eta, error: '' });
    } else {
      !!locations.length && !!!companyLocationId.value && companyLocationId.value !== undefined && 
        setCompanyLocationId({ value: locations[locations.length - 1]?.id, formattedValue: locations[locations.length - 1], error: '' });
      !!me && setDispatcherId({ value: me?.id, formattedValue: me, error: '' });
    }
  }, [edit, me, duplicate, serviceData, locations, companyLocationId.value]);
  useEffect(() => {
    if (!!userId.value && userId.value !== serviceData?.user?.id && !!userId.formattedValue?.trucks.length) {
      setTruck({ value: userId.formattedValue?.trucks[0]?.id || '', formattedValue: userId.formattedValue?.trucks[0] || null , error: '' });
      (!edit && !duplicate) && setClassType({ 
        value: _find(serviceClassTypes, ({ value }) => 
          _startsWith(_lowerCase(userId.formattedValue?.trucks[0]?.type?.name), _lowerCase(value)))?.value  || '', 
        error: '' 
      });
    }
  }, [userId, serviceData, edit, duplicate]);
  useEffect(() => {
    if (!open && !!popup.activePoint) {
      setUserId({ value: popup.activePoint?.id, formattedValue: { id: popup.activePoint?.id, ...popup.activePoint?.data}, error: '' });
    }
  }, [open, popup.activePoint]);

  return (
    <Card sx={{ gap: '24px', ...sx }}>
      <CardHeader>
        <CardTitle>Service</CardTitle>
      </CardHeader>
      <FieldsContainer>
        { !edit && <ServicesAutocomplete 
          id='service'
          type='basic'
          label='Service'
          value={basic.formattedValue}
          error={!!basic.error}
          helperText={basic.error}
          onChange={({ value, formattedValue, error }) => setBasic({ value, formattedValue, error })}
        /> }
        <UsersAutocomplete
          id='dispatcher'
          role='manager'
          fullWidth
          label='Dispatcher'
          value={dispatcherId.formattedValue}
          error={!!dispatcherId.error}
          helperText={dispatcherId.error}
          onChange={({ value, formattedValue, error }) => setDispatcherId({ value, formattedValue, error })}
        />
        <TextField 
          id='driver'
          select
          label='Driver Assigned'
          defaultValue=''
          value={userId.formattedValue || ''}
          error={!!userId.error}
          helperText={userId.error}
          SelectProps={{ open: false, onOpen: handleMapDialog, renderValue: (selected) => `${selected.first_name} ${selected.last_name}` }}
          disabled={(!!!serviceData?.user && isISSC) || isUrgently}
        >
          <MenuItem key={userId.formattedValue?.id} value={userId.formattedValue}>{`${userId.formattedValue?.first_name} ${userId.formattedValue?.last_name}`}</MenuItem>
        </TextField>
        <TrucksAutocomplete
          id='trucks'
          value={truck.formattedValue}
          error={!!truck.error}
          helperText={truck.error}
          onChange={({ value, formattedValue, error }) => setTruck({ value, formattedValue, error })}
        />
        <TextField 
          label='Tow Reason' 
          value={towReason.value || ''} 
          select
          helperText={towReason.error} 
          error={!!towReason.error} 
          onChange={({ target: { value } }) => setTowReason({ value, error: '' })} 
        >
          { _map(towReasonTypes, ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>) }
        </TextField>
        <TextField 
          label='Class Type' 
          value={classType.value} 
          select
          helperText={classType.error} 
          error={!!classType.error} 
          onChange={({ target: { value } }) => setClassType({ value, error: '' })} 
        >
          { _map(serviceClassTypes, ({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>) }
        </TextField>
        <TextField
          fullWidth
          label='Eta (minutes)'
          value={eta.value}
          error={!!eta.error}
          helperText={eta.error}
          type='number'
          inputProps={{ min: isUrgently ? 10 : 1, max: isUrgently ? 180 : 500 }}
          onChange={({ target: { value } }) => setEta({ value, error: isUrgently ?  ((value < 10 || value > 180) ? 'Eta is invalid' : '') : ((value < 1 || value > 500) ? 'Eta is invalid' : '') })}
        />
      </FieldsContainer>
      { !edit && <ServicesAutocomplete 
        id='additionals'
        type='additional'
        label='Additionals'
        value={additional.formattedValue}
        error={!!additional.error}
        helperText={additional.error}
        onChange={({ value, formattedValue, error }) => setAdditional({ value, formattedValue, error })}
      /> }
      <LocationsAutocomplete
        value={companyLocationId.formattedValue}
        error={!!companyLocationId.error}
        helperText={companyLocationId.error}
        onChange={({ value, formattedValue, error }) => setCompanyLocationId({ value, formattedValue, error })}
      />
      <FormControlLabel
        label='Police Call'
        control={
          <Checkbox
            checked={policeFields}
            onChange={({ target: { checked } }) => setPoliceFields(checked)}
          />
        }
      />
      <FieldsContainer>
        {policeFields && <>
          <TextField 
            label='Police Log Number' 
            value={policeNumber || ''}
            onChange={({ target: { value } }) => setPoliceNumber(value)}
          />
          <TextField 
            label='Police Code' 
            value={policeCode || ''}
            onChange={({ target: { value } }) => setPoliceCode(value)}
          />
        </>}
        <TextField 
          label='PO Number' 
          value={authorizationNumber || ''}
          onChange={({ target: { value } }) => setAuthorizationNumber(value)}
        />
      </FieldsContainer>
      <FormControl>
        <FormLabel id="service-time-label">Service Time</FormLabel>
        <RadioGroup
          aria-labelledby="service-time-label"
          name="service-time"
          value={serviceTime}
          onChange={({ target: { value } }) => setServiceTime(value)}
        >
          <FormControlLabel value="scheduled" control={<Radio />} label="Scheduled" />
          <FormControlLabel value="asap" control={<Radio />} label="ASAP" />
        </RadioGroup>
      </FormControl>
      {serviceTime === 'scheduled' && <FieldsContainer>
        <BaseDatePicker
          fullWidth
          label='Date'
          type='date'
          disabled={isUrgently}
          minDate={new Date()}
          value={sheduledAt.formattedValue}
          error={!!sheduledAt.error}
          helperText={sheduledAt.error}
          onChange={({ value, formattedValue, error }) => setSheduledAt({ value, formattedValue, error })}
        />
        <BaseDatePicker
          fullWidth
          label='Time'
          type='time'
          disabled={isUrgently}
          minTime={new Date(new Date().getTime() + (10 * 60000))}
          value={sheduledAt.formattedValue}
          error={!!sheduledAt.error}
          helperText={sheduledAt.error}
          onChange={({ value, formattedValue, error }) => setSheduledAt({ value, formattedValue, error })}
        />
      </FieldsContainer>}
    </Card> 
  )
};

export default memo(ServiceCard);
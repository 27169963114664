import { memo, useEffect, useCallback } from 'react';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _map from 'lodash/map';
// Local files
import BaseListItem from 'components/Common/BaseListItem/BaseListItem';
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import Status from '../Status/Status';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useVehicles from 'hooks/useVehicles';

const VehicleList = ({ query, statuses, fetching, onFetching }) => {
  const { id: vehicleId } = useParams();
  const { getVehicles, clearLocalVehicles } = useVehicles();
  const { setError } = useError();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const { data, pagination } = useCustomSelector(state => state.vehicles.all);

  const fetchVehicles = useCallback((offset, query, statuses) => {
    onFetching(true);
    getVehicles({ offset, query, ...(!!statuses.length && { statuses }) })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => onFetching(false));
  }, [getVehicles, setError]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalVehicles().then(() => fetchVehicles(0, query, statuses)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [fetchVehicles, clearLocalVehicles, query, statuses]);

  return (
    <BaseScrollableContainer {...pagination} onLoad={offset => !fetching && fetchVehicles(offset, query, statuses)}>
      <List>
        {_map(data, ({ id, order, make, model, year, status }) => (
          <BaseListItem
            key={id}
            title={`${order?.number}`}
            description={`${year || ''} ${make || ''} ${model || ''}`}
            content={<Status value={status} />}
            selected={id === vehicleId}
            to={`/vehicles/${id}`}
            disabled={isFetching}
          />
        ))}
      </List>
    </BaseScrollableContainer>
  );
};

VehicleList.propTypes = {
  fetching: PropTypes.bool.isRequired,
  onFetching: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired
};

export default memo(VehicleList);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getChannelMessagesRoutine,
  getChannelMessageRoutine,
  clearLocalChannelMessagesRoutine
} from 'actions';

const attributes = ['id', 'parameters', 'request_type', 'service_category', 'service_name', 'updated_at'];

export const getChannelMessages = getThunkActionCreator(
  getChannelMessagesRoutine,
  async ({ order_id, offset = 0 }) => {
    getChannelMessages.getChannelMessagesCancel && getChannelMessages.getChannelMessagesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/orders/${order_id}/channel_messages`,
      {
        params: { access_token, limit: 25, offset, orders: { updated_at: 'desc' }, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getChannelMessages.getChannelMessagesCancel = cancel })
      }
    );
  }
);
export const getChannelMessage = getThunkActionCreator(
  getChannelMessageRoutine,
  async (id) => {
    getChannelMessage.getChannelMessageCancel && getChannelMessage.getChannelMessageCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/channel_messages/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getChannelMessage.getChannelMessageCancel = cancel })
      }
    );
  }
);
export const clearLocalChannelMessages = getThunkActionCreator(clearLocalChannelMessagesRoutine, async () => {});
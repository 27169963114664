import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCustomers as getCustomersAction,
  getCustomer as getCustomerAction,
  createCustomer as createCustomerAction,
  updateCustomer as updateCustomerAction,
  clearLocalCustomers as clearLocalCustomersAction,
  clearLocalActiveCustomer as clearLocalActiveCustomerAction
} from 'actions/customers';

const useCustomers = () => {
  const dispatch = useDispatch();
  const getCustomers = useCallback(({ offset, orders, phone_email, side }) => dispatch(getCustomersAction({ offset, orders, phone_email, side })), [dispatch]);
  const getCustomer = useCallback(id => dispatch(getCustomerAction(id)), [dispatch]);
  const createCustomer = useCallback(({ customer, side }) => dispatch(createCustomerAction({ customer, side })), [dispatch]);
  const updateCustomer = useCallback((customer) => dispatch(updateCustomerAction(customer)), [dispatch]);
  const clearLocalCustomers = useCallback(() => dispatch(clearLocalCustomersAction()), [dispatch]);
  const clearLocalActiveCustomer = useCallback(() => dispatch(clearLocalActiveCustomerAction()), [dispatch]);

  return {
    getCustomers,
    getCustomer,
    createCustomer,
    updateCustomer,
    clearLocalCustomers,
    clearLocalActiveCustomer
  };
};

export default useCustomers;
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

export const Root = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  paddingTop: 24,
});

export const StyledTab = styled(Tab)({
  minWidth: '90px',
  minHeight: 'unset',
})


import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getListTypes as getListTypesAction, clearLocalListTypes as clearLocalListTypesAction } from 'actions/types';

const useTypes = () => {
  const dispatch = useDispatch();
  const getListTypes = useCallback(({ offset, query }) => dispatch(getListTypesAction({ offset, query })), [dispatch]);
  const clearLocalListTypes = useCallback(() => dispatch(clearLocalListTypesAction()), [dispatch]);

  return { getListTypes, clearLocalListTypes };
};

export default useTypes;
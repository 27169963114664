import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Collapse } from '@mui/material';
import ArrowIcon from '@mui/icons-material/ArrowBackIosNew';
//Local files
import { Root, Content, StyledButton } from './BaseStats.styled';

const BaseStats = ({ children, clear }) => {
  const [expanded, setExpanded] = useState(false);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  const handleChange= () => {
    setExpanded(!expanded);
    clear();
  };

  if (mediaDesktop) {
    return (
      <Root>
        <Collapse in={expanded}>
          <Content>
            {children}
          </Content>
        </Collapse>
        <StyledButton
          expanded={expanded}
          onClick={handleChange}
        >
          <ArrowIcon sx={{ width: '16px', transform: expanded ? 'rotate(90deg)' : 'rotate(270deg)' }} />
        </StyledButton>
      </Root>
    );
  }

  return null;
};


export default memo(BaseStats);
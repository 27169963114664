import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { sessionService as ss } from 'redux-react-session';
import _includes from 'lodash/includes';
// Local files
import { clearStore as clearStoreAction } from 'actions/sessions';
import {
  setError as setErrorAction,
  hideError as hideErrorAction
} from 'actions/error';
import { errorTypes } from 'helpers/constants';

const { ACCESS_TOKEN_INVALID, PARAMETER_MISSING } = errorTypes;

const useError = () => {
  const dispatch = useDispatch();
  const clearStore = useCallback(() => dispatch(clearStoreAction()), [dispatch]);
  const showError = useCallback(error => dispatch(setErrorAction(error)), [dispatch]);
  const hideError = useCallback(() => dispatch(hideErrorAction()), [dispatch]);

  const deleteSession = useCallback(() =>
    ss.loadSession().then(() => clearStore().then(() => ss.deleteSession().then(() => ss.deleteUser())))
  , [clearStore]);
  const setError = useCallback(error => new Promise(resolve => {
    const type = error?.response?.data?.error?.type;
    const isWrongToken = type === ACCESS_TOKEN_INVALID || (type === PARAMETER_MISSING && _includes(error?.response?.data?.error?.message, 'access_token'));

    if  (isWrongToken) {
      resolve(deleteSession());
    } else {
      resolve(showError(error));
    }
  }), [deleteSession, showError]);

  return { setError, hideError };
};

export default useError;
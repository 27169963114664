import { memo } from 'react';
import { Popup as MapboxPopup } from 'react-map-gl';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import StyledItem from 'components/Map/Components/StyledItem/StyledItem';
import StyledList from 'components/Map/Components/StyledList/StyledList';
import OrderInfo from 'components/Map/OrderInfo/OrderInfo';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Popup = ({ open, latitude, longitude, cluster, data, activePoint, onClose, onItemClick }) => {
  const handleItemClick = item => {
    if (item.id !== activePoint.id) {
      onItemClick(item);
    }
  };

  if (!open || activePoint.category !== 'order') return null;
  return (
    <MapboxPopup
      latitude={latitude}
      longitude={longitude}
      anchor='top'
      style={{ maxWidth: 'unset', width: cluster ? 'calc(10px + 100px + 190px + 10px)' : 'calc(240px + 20px)' }}
      closeOnMove={false}
      closeOnClick={false}
      onClose={onClose}
    >
      <Box sx={{ width: '100%' }}>
        {cluster &&
          <StyledList>
            {_map(data, d =>
              <StyledItem
                key={d.id}
                selected={d.id === activePoint.id}
                onClick={() => handleItemClick(d)}
              >
                {d.category === 'order' ? d.data.number : `${d.data.first_name} ${d.data.last_name}`}
              </StyledItem>
            )}
          </StyledList>
        }
        <Box sx={{ p: '15px 5px 10px' }}>
          {activePoint.category === 'order' &&
            <OrderInfo
              id={activePoint.id}
              {...activePoint.data}
            />
          }
        </Box>
      </Box>
    </MapboxPopup>
  );
};

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  cluster: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  activePoint: PropTypes.shape({
    id: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  }),
  onClose: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Popup {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
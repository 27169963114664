import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getChannelMessages as getChannelMessagesAction,
  getChannelMessage as getChannelMessageAction,
  clearLocalChannelMessages as clearLocalChannelMessagesAction
} from 'actions/channelMessages';

const useChannelMessages = () => {
  const dispatch = useDispatch();
  const getChannelMessages = useCallback(({ offset, order_id }) => dispatch(getChannelMessagesAction({ offset, order_id })), [dispatch]);
  const getChannelMessage = useCallback(id => dispatch(getChannelMessageAction(id)), [dispatch]);
  const clearLocalChannelMessages = useCallback(() => dispatch(clearLocalChannelMessagesAction()), [dispatch]);

  return {
    getChannelMessages,
    getChannelMessage,
    clearLocalChannelMessages
  };
};

export default useChannelMessages;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getMapDrivers as getMapDriversAction,
  getMapOrders as getMapOrdersAction,
  setActualDriverData as setActualDriverDataAction,
  getUrgentlyMapDrivers as getUrgentlyMapDriversAction,
  getDirectionOnMap as getDirectionOnMapAction,
  clearLocalMapData as clearLocalMapDataAction,
  setMapConfigs as setMapConfigsAction
} from 'actions/map';

const useMap = () => {
  const dispatch = useDispatch();
  const getMapDrivers = useCallback(({ query, current_order_statuses, without_current_order }) => dispatch(getMapDriversAction({ query, current_order_statuses, without_current_order })), [dispatch]);
  const getUrgentlyMapDrivers = useCallback(({ drivers }) => dispatch(getUrgentlyMapDriversAction({ drivers })), [dispatch]);
  const getMapOrders = useCallback(({ latitude_gt, latitude_lt, longitude_gt, longitude_lt, query, statuses }) => dispatch(getMapOrdersAction({ latitude_gt, latitude_lt, longitude_gt, longitude_lt, query, statuses })), [dispatch]);
  const setActualDriverData = useCallback(({ id, latitude, longitude, heading, duty_duration }) => dispatch(setActualDriverDataAction({ id, latitude, longitude, heading, duty_duration })), [dispatch]);
  const getDirectionOnMap = useCallback(({ start, end }) => dispatch(getDirectionOnMapAction({ start, end })), [dispatch]);
  const clearLocalMapData = useCallback(type => dispatch(clearLocalMapDataAction(type)), [dispatch]);
  const setMapConfigs = useCallback(({ bounds, viewState, popup, localIncident }) => dispatch(setMapConfigsAction({ bounds, viewState, popup, localIncident })), [dispatch]);

  return { 
    getMapDrivers, 
    getUrgentlyMapDrivers,
    getMapOrders,
    setActualDriverData,
    getDirectionOnMap,
    clearLocalMapData,
    setMapConfigs
  };
};

export default useMap;
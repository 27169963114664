import { memo } from 'react';
import Typography from '@mui/material/Typography';
import IncidentIcon from '@mui/icons-material/PriorityHigh';
import DestinationIcon from '@mui/icons-material/CheckRounded';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import _startCase from 'lodash/startCase';
// Local files
import { AddressDivider, Chips, Footer, Group, Header, IconContainer, Root, Route } from './OrderItem.styled';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/clock.svg';
import { getFormatedHoursMinutes } from 'helpers';
import BaseStatus from 'components/Common/BaseStatus/BaseStatus';
import Status from 'components/Orders/Status/Status';

const OrderItem = ({ current_order, nextOrder = null, unknown = false, duration = null }) => {
  if (!current_order) return null;
  const { hours, minutes } = getFormatedHoursMinutes(current_order.execution_duration);
  const awayTime = current_order.status === 'en_route' ? 'In transit' : `${hours > 0 ? `Time on Order: ${hours} hours` : ''} ${minutes} minutes away`;

  return (
    <Root>
      {nextOrder &&
        <>
          <Typography variant='body2' color='textSecondary' sx={{ fontWeight: 500, mb: '1px' }}>
            Next Job
          </Typography>
          <Header to='/'>
            <Typography variant='title'>403124</Typography>
            <ArrowOutwardIcon />
          </Header>
          <Chips>
            <Status size='small' value='en_route' />
            <BaseStatus size='small' color='primary' label='Tow - Motorcycle' />
          </Chips>
          <Route>
            <Group>
              <IconContainer><IncidentIcon /></IconContainer>
              <Typography variant='subtitle2' color='textPrimary'>295 W 7th St., Southlake, TX, 76092</Typography>
            </Group>
            {duration &&
              <Group>
                <AddressDivider />
                <Typography variant='body2' color='textSecondary'>{awayTime}</Typography>
              </Group>
            }
            <Group sx={{ opacity: unknown ? .25 : 1 }}>
              <IconContainer><DestinationIcon /></IconContainer>
              <Typography variant='subtitle2' color='textPrimary'>575 N Macarthur Blvd, Colleyville, TX, 76034</Typography>
            </Group>
          </Route>
        </>
      }
      <Header to={`/orders/${current_order.id}`}>
        <Typography variant='title'>{current_order.number}</Typography>
        <ArrowOutwardIcon />
      </Header>
      <Chips>
        <Status size='small' value={current_order.status} />
        {current_order.services[0] && <BaseStatus size='small' color='primary' label={current_order.services[0].name} />}
      </Chips>
      <Route>
        <Group>
          <IconContainer><IncidentIcon /></IconContainer>
          <Typography variant='subtitle2' color='textPrimary'>
            {current_order.incident_location?.address ?? 'Unknown'}
          </Typography>
        </Group>
        {!!duration &&
          <Group>
            <AddressDivider />
            <Typography variant='body2' color='textSecondary'>{duration} min away</Typography>
          </Group>
        }
        <Group sx={{ opacity: !current_order.destination_location ? .25 : 1 }}>
          <IconContainer><DestinationIcon /></IconContainer>
          <Typography variant='subtitle2' color='textPrimary'>
            {current_order.destination_location?.address ?? 'Unknown'}
          </Typography>
        </Group>
      </Route>
      <Footer>
        <Group sx={{ color: 'text.secondary' }}>
          <TimeIcon style={{ flexShrink: 0 }} />
          <Typography variant='caption' color='inherit'>{awayTime}</Typography>
        </Group>
        <Group sx={{ color: 'text.secondary' }}>
          {current_order.truck && <CarIcon style={{ flexShrink: 0 }} />}
          <Typography variant='caption' color='inherit'>
            {current_order.truck ? current_order.truck.nickname + ', ' : ''}{_startCase(current_order.class_type)}
          </Typography>
        </Group>
      </Footer>
    </Root>
  );
};

export default memo(OrderItem);
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function PasswordField({ password, confirmPassword, setPassword, setConfirmPassword }) {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onInputChange = (e) => {
      const { name, value } = e.target;
      name === 'password' ? setPassword({ value, error: '' }) : setConfirmPassword({ value, error: '' });
      validateInput(name, value);
    };
    
    const validateInput = (name, value) => {
      switch (name) {
        case 'password':
          if (!value) {
            setPassword((prev) => ({ ...prev, error: 'Please enter Password' }));
          } else if (confirmPassword.value && value !== confirmPassword.value) {
            setConfirmPassword((prev) => ({ ...prev, error: 'Confirm Password does not match Password' }));
          } else {
            setPassword((prev) => ({ ...prev, error: '' }));
            setConfirmPassword((prev) => ({ ...prev, error: '' }));
          }
          break;
    
        case 'confirmPassword':
          if (!value) {
            setConfirmPassword((prev) => ({ ...prev, error: 'Please enter Confirm Password' }));
          } else if (password.value && value !== password.value) {
            setConfirmPassword((prev) => ({ ...prev, error: 'Confirm Password does not match Password' }));
          } else {
            setConfirmPassword((prev) => ({ ...prev, error: '' }));
          }
          break;
    
        default:
          break;
      }
    };
    
  return (
    <>
      <TextField
        fullWidth
        margin="dense"
        type={showPassword ? 'text' : 'password'}
        name="password"
        placeholder="Create Password"
        value={password.value}
        onChange={onInputChange}
        error={!!password.error}
        helperText={password.error}
        InputProps={{
            endAdornment: (
              <IconButton
                aria-label="show password"
                color="primary"
                disableRipple
                onClick={() =>
                  setShowPassword(!showPassword)
                }
                sx={{
                  position: "absolute",
                  right: "8px",
                }}
              >
                {showPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            ),
            sx: {
              pr: 0,
              "& input": { pr: "48px" },
            },
          }}
      />
      <TextField
        fullWidth
        margin="dense"
        type={showConfirmPassword ? 'text' : 'password'}
        name="confirmPassword"
        placeholder="Confirm Password"
        value={confirmPassword.value}
        onChange={onInputChange}
        error={!!confirmPassword.error}
        helperText={confirmPassword.error}
        InputProps={{
            endAdornment: (
              <IconButton
                aria-label="show password"
                color="primary"
                disableRipple
                onClick={() =>
                  setShowConfirmPassword(!showConfirmPassword)
                }
                sx={{
                  position: "absolute",
                  right: "8px",
                }}
              >
                {showConfirmPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            ),
            sx: {
              pr: 0,
              "& input": { pr: "48px" },
            },
          }}
      />
    </>
  );
}

export default PasswordField;

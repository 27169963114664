import { memo, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { WidthProvider, Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
// Local files
import { Root, Title, WidgetConatiner } from './Widgets.styled';
import Widget from './Widget/Widget';
import useCustomSelector from 'hooks/useCustomSelector';
import useWidgets from 'hooks/useWidgets';
import useInvoices from 'hooks/useInvoices';
import useOrders from 'hooks/useOrders';
import useVehicles from 'hooks/useVehicles';
import usePayments from 'hooks/usePayments';
import useUsers from 'hooks/useUsers';
import { widgetTypes } from 'helpers/constants';
import { coequalWidget } from 'helpers';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Widgets = () => {
  const { updateWidgets } = useWidgets();
  const { clearInvoicesStatistics } = useInvoices();
  const { clearOrdersStatistics } = useOrders();
  const { clearVehiclesStatistics } = useVehicles();
  const { clearLocalUsersStatistics } = useUsers();
  const { clearLocalPayments } = usePayments();
  const { widgets } = useCustomSelector(state => state.profile.user);
  const large = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const medium = useMediaQuery(theme => theme.breakpoints.only('md'));
  const small = useMediaQuery(theme => theme.breakpoints.only('sm'));
  const xs = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const [edit, setEdit] = useState(false);
  const [layout, setLayout] = useState([]);
  const [layouts, setLayouts] = useState({});
  const [maxPosition, setMaxPosition] = useState({ x: 0, y: 0, hMax: 0, hMin: 0, overlapWidget: null });
  const size = large ? 'large' : medium ? 'medium' : small ? 'small' : 'xs';
  
  useEffect(() => {
    let layout = _map(widgets, (w) => ({ i: w.id, x: w.position_x, y: w.position_y, w: large ? w.width : medium ? w.width - 1 : w.width, h: w.height, ..._pick(w, ['widget_type']) }));
    layout = widgets.length === widgetTypes?.length ? _filter(layout, (l) => l.i !== 'add') : layout;
    const sortedLayout = _sortBy(_filter(layout, (nW) => nW.i !== 'add'), ['y', 'x']);
    const maxWidget = !!sortedLayout?.length ? sortedLayout[sortedLayout.length - 1] : { x: 0, y: 0, w: 0, h: 0 };
    const sortedH = _sortBy(_filter(sortedLayout, (l) => l.y === maxWidget.y), ['h']);
    const sortedPrevH = _sortBy(_filter(sortedLayout, (l) => l.y === (maxWidget.y - 1)), ['h']);
    const hMax = !!sortedH?.length ? sortedH[sortedH.length - 1] : { x: 0, y: 0, w: 0, h: 0 };
    const hPrevMax = !!sortedPrevH?.length ? sortedPrevH[sortedPrevH.length - 1] : { x: 0, y: 0, w: 0, h: 0 };
    const hMin = !!sortedH?.length ? sortedH[0] : { x: 0, y: 0, w: 0, h: 0 };
    const overlapWidget = hPrevMax?.h + hPrevMax?.y >= maxWidget?.y

    setMaxPosition({ x: maxWidget?.x + maxWidget?.w, y: maxWidget?.y, hMax, hMin, overlapWidget });
    setLayout(layout);
    setLayouts({ ...(large && { lg: layout }), ...(medium && { md: layout }), ...(small && { sm: layout }), ...(xs && { xs: layout }) });
  }, [widgets, large, medium, small, xs, size]);
  useEffect(() => {
    return () => {
      clearInvoicesStatistics();
      clearOrdersStatistics();
      clearVehiclesStatistics();
      clearLocalUsersStatistics();
      clearLocalPayments();
    };
  }, [clearInvoicesStatistics, clearOrdersStatistics, clearVehiclesStatistics, clearLocalUsersStatistics, clearLocalPayments]);

  const handleDrag = (newLayout) => {
    const convertedNewLayout = _map(_filter(newLayout, (nW) => nW.i !== 'add'), (widget) => (coequalWidget({ widget, size })));
    const oldLayout = _map(widgets, (w) => ({..._pick(w, ['id', 'position_x', 'position_y'])}));
    const changedWidgets = _filter(convertedNewLayout, (w) => _find(oldLayout, (ow) => w.id === ow.id && (w.position_x !== ow.position_x || w.position_y !== ow.position_y))).sort();

    if (!!changedWidgets.length && (size === 'large' || size === 'medium')) {
      updateWidgets(changedWidgets);
    }
  };
  const handleEdit = () => {
    const newLayout = edit ? _filter(layout, (l) => l.i !== 'add') : [...layout, ...[{ i: 'add', x: Infinity, y: Infinity, w: large ? 4 : medium ? 3 : 4, h: 2, isDraggable: false }]]
    
    setLayout(newLayout);
    setLayouts({ ...(large && { lg: newLayout }), ...(medium && { md: newLayout }), ...(small && { sm: newLayout }), ...(xs && { xs: newLayout }) });
    setEdit(!edit);
  };

  return (
    <Root>
      <Title>
        <Typography variant={large ? 'h4' : 'h5'} sx={{ mt: 1, fontWeight: 700 }}>Financial Reports</Typography>
        <Button color='primary' onClick={handleEdit}>{edit ? 'Fix' : 'Edit'} widgets</Button>
      </Title>
      <ResponsiveGridLayout
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 900, sm: 600, xs: 400 }}
        cols={{ lg: 12, md: 9, sm: 3, xs: 1 }}
        rowHeight={220}
        isResizable={false}
        isDraggable={edit}
        onDragStop={handleDrag}
      >
        {_map(layout, (widget) => 
          <WidgetConatiner key={widget.i} sx={{ cursor: edit ? 'pointer' : 'initial' }}>
            <Widget {...{ edit, widget, maxPosition, widgets }} onEdit={() => setEdit(!edit)} />
          </WidgetConatiner>
        )}
      </ResponsiveGridLayout>
    </Root>
  );
};

export default memo(Widgets);
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _pick from 'lodash/pick';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
// Local files
import { CardsContainer, DetailsContainer, DetailsWrapper } from 'components/Common/BasePageUi/BasePageUi';
import NotificationsCard from 'components/Notifications/Card/Card';
import CurrentOrderCard from 'components/Orders/Cards/CurrentOrder/CurrentOrder';
import OrdersHistoryCard from 'components/Orders/Cards/OrdersHistory/OrdersHistory';
import NotesCard from 'components/Notes/Card/Card';
import DocumentsCard from 'components/Documents/Card/Card';
import MapCard from 'components/Map/Card/Card';
import Placeholder from 'components/Common/Placeholder/Placeholder';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import Main from '../Cards/Main/Main';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useUsers from 'hooks/useUsers';
import useUrl from 'hooks/useUrl';
import useRole from 'hooks/useRole';

const Details = () => {
  const { id } = useParams();
  const { setFetchingDetails } = useApp();
  const { setError } = useError();
  const { isDriversRoute, isManagersRoute } = useUrl();
  const { isOwner, isManager } = useRole();
  const { getUser } = useUsers();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const { id: my_id, companies } = useCustomSelector(state => state.profile.user);
  const fetchedUser = useCustomSelector(state => state.users.user);
  
  const mainData = { ..._pick(fetchedUser, ['id', 'first_name', 'last_name', 'email', 'phone', 'created_at', 'last_all_orders', 'address', 'second_address', 'city', 'zip', 'state']), role: isDriversRoute ? 'driver' : _includes(fetchedUser.roles, 'owner') ? 'owner' : 'manager' };
  const currentOrderData = { ..._pick(fetchedUser, ['current_order']), isCompanyOrder: !!_find(companies, (company) => company.id === fetchedUser?.current_order?.company_location?.company?.id)};
  const mapData = _pick(fetchedUser, ['address', 'latitude', 'longitude', 'current_order']);
  const ordersHistoryData = _pick(fetchedUser, ['last_all_orders'])
  const notesData = { previous_notes: fetchedUser.notes, id: fetchedUser.id };
  const documentsData = { id: fetchedUser.id, model: 'users' };
  const isShowNotifications = isDriversRoute || (isManagersRoute && (isOwner || (isManager && fetchedUser?.id === my_id)));

  useEffect(() => {
    if (!!id) {
      if (id !== fetchedUser.id) {
        setFetchingDetails(true);
        getUser(id)
        .then(() => setFetchingDetails(false))
        .catch(error => setError(error))
      }
    }
  }, [id, fetchedUser.id, getUser, setFetchingDetails, setError]);


  if (!id) return <Placeholder model='users' />;
  if (!!!fetchedUser.id || isFetching) return <PageLoader />;
  return (
    <DetailsContainer sx={{ pt: '0px !important' }}>
      <DetailsWrapper>
        <Main {...mainData} />
        <CardsContainer>
          {isShowNotifications && <NotificationsCard 
            userNotifications={fetchedUser.notifications}
            sx={{ maxWidth: '100% !important', overflow: 'hidden' }} 
          />}
          { isDriversRoute && <CurrentOrderCard {...currentOrderData} /> }
          <OrdersHistoryCard {...ordersHistoryData} />
          <NotesCard hideTabs edit {...notesData} />
          { isDriversRoute && <MapCard firstLabel={`Driver's current location`} secondaryLabel='Address' {...mapData} /> }
          <DocumentsCard {...documentsData} />
        </CardsContainer>
      </DetailsWrapper>
    </DetailsContainer>
  );
};

export default memo(Details);
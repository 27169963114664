import { memo, useCallback, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/MoreVert';
import _startCase from 'lodash/startCase';
// Local files
import { Head, Canvas } from './LargeWidget.styled';
import Filters from './Filters/Filters';
import useInvoices from 'hooks/useInvoices';
import { dateFilter } from 'helpers/constants';

const LargeWidget = ({ widget, onMenuAnchor }) => {
  const { getInvoicesStatistics } = useInvoices();
  const [chart, setChart] = useState(null);
  const [filterDate, setFilterDate] = useState(dateFilter[dateFilter.length - 1]);
  const [status, setStatus] = useState('all_statuses');
  const [account, setAccount] = useState({ value: 'all_accounts', formattedValue: { id: 'all_accounts', name: 'All Accounts'} });
  const [processing, setProcessing] = useState(false);

  const renderPie = useCallback((data, labels) => {
    const canvas = document.getElementById('statistics_invoices');
    const ctx = canvas?.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        labels,
        datasets: [{
          type: 'bar',
          data,
          backgroundColor: 'rgba(230, 50, 79, 0.2)'
        }, {
          type: 'line',
          data,
          fill: false,
          borderColor: 'rgb(8, 0, 143)'
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins:{
          legend: {
            display: false
          }
        }
      }
    });

    setChart(chart);
    chart.render();
  }, []);
  const fetchInvoicesStatistics = useCallback((filterDate, reason, status, account_id) => {
    setProcessing(true);
    getInvoicesStatistics({ filterDate, reason, status, account_id })
    .then(({ payload: { response: { data: { statistics: { over_time_invoices_data, over_time_invoices_dates } } } } }) => {
      if(!!!chart){
        renderPie(over_time_invoices_data, over_time_invoices_dates);
      } else {
        chart.data.labels.length = 0;
        chart.data.datasets.forEach((dataset) => dataset.data.length = 0);
        chart.data.labels = over_time_invoices_dates;
        chart.data.datasets.forEach((dataset) => dataset.data = over_time_invoices_data);
        chart.update();
      }
    })
    .finally(() => setProcessing(false))
  }, [chart, renderPie, getInvoicesStatistics]);

  useEffect(() => {
    fetchInvoicesStatistics(filterDate, widget.widget_type, status, account.value);
  }, [filterDate, widget.widget_type, status, account.value, fetchInvoicesStatistics]);

  return (
    <>
      <Head>
        <Typography variant='h5' sx={{ fontWeight: 700 }}>{_startCase(widget.widget_type)}</Typography>
        <IconButton 
          color='primary' 
          size='small' 
          sx={{flexShrink: 0}} 
          aria-label='menu' 
          onClick={({ currentTarget }) => onMenuAnchor(currentTarget)}
        >
          <MenuIcon />
        </IconButton>
      </Head>
      <Filters 
        onFilterDate={setFilterDate}
        onStatus={setStatus}
        onAccount={setAccount}
        {...{processing, filterDate, status, account}}
      />
      <Canvas id='statistics_invoices'>Your browser does not support the HTML5 canvas tag</Canvas>
    </>
  );
};

export default memo(LargeWidget);
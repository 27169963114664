import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import _omit from 'lodash/omit';
import {
  getUserOrdersRoutine,
  getOrdersForDashboardRoutine,
  getOrdersRoutine,
  getCustomersOrdersRoutine,
  getInvoicesOrdersRoutine,
  getVehiclesOrdersRoutine,
  getOrderRoutine,
  getOrderForNotificationRoutine,
  createOrderRoutine,
  updateOrderRoutine,
  deleteOrderRoutine,
  supplementOrderRoutine,
  rejectOrderRoutine,
  cancelOrderRoutine,
  renewOrderRoutine,
  startOrderMovementRoutine,
  pickUpOrderRoutine,
  serviceOrderRoutine,
  dropOffOrderRoutine,
  completeOrderRoutine,
  reverseStatusRoutine,
  applyCustomerLocationRoutine,
  rejectCustomerLocationRoutine,
  sendOrderTrackingRoutine,
  changeLocalOrderStatusRoutine,
  clearLocalUserOrdersRoutine,
  clearLocalOrdersWithUserRoutine,
  clearLocalOrdersRoutine,
  clearLocalCustomersOrdersRoutine,
  clearLocalInvoicesOrdersRoutine,
  clearLocalVehiclesOrdersRoutine,
  clearLocalActiveOrderRoutine,
  setLocalOrderFieldsRoutine,
  getOrdersStatisticsRoutine,
  clearOrdersStatisticsRoutine
} from 'actions';
import { orderStatuses } from 'helpers/constants';

const shortAttributes = [
  'id',
  'number',
  'status',
  'previous_status',
  'eta',
  'authorization_number',
  'created_at',
  'deadhead_miles_count', 
  'en_route_miles_count', 
  'towed_miles_count',
  'source',
  'scheduled_at',
  { incident_location: ['longitude', 'latitude', 'address'] },
  { services: ['id', 'name', 'service_type'] }, 
  { account: ['id', 'name', 'account_type', { company: ['id', 'name'] }] },
  { user: ['id', 'first_name', 'last_name', 'phone', 'longitude', 'latitude', { trucks: ['id', 'make', 'model', 'year', 'type'] }] },
  { dispatcher: ['id', 'first_name', 'last_name'] },
  { customer_location: ['longitude', 'latitude', 'address'] },
  { recipients: ['id', 'delivery_method', 'name', 'phone', 'email'] },
  { trucks: ['id', 'make', 'model', 'year', 'type'] },
  { users: ['id', 'first_name', 'last_name', 'phone', 'longitude', 'latitude', { trucks: ['id', 'make', 'model', 'year', 'type'] }] }
];
const customerAttributes = [
  'id',
  'status',
  'previous_status',
  'eta',
  'created_at',
  { services: ['id', 'name', 'service_type'] }, 
  { user: ['id', 'first_name', 'last_name', 'phone'] },
  { customer: ['id', 'name'] },
  { customer_location: ['longitude', 'latitude', 'address'] },
  { incident_location: ['longitude', 'latitude', 'address'] },
  { destination_location: ['longitude', 'latitude', 'address'] },
  { vehicle: [
    'id',
    'status',
    'vin',
    'make',
    'model',
    'year',
    'color',
    'drive_type',
    'license',
    'license_state',
    'odometer'
  ] }
];
const fullAttributes = [
  ...shortAttributes,
  { invoice: [
    'id', 
    'total_amount', 
    'paid_amount', 
    'minimum_amount',
    'status',
    'previous_status',
    { payments: ['id', { card: ['id', 'brand', 'last4'] }, 'payer', 'payment_type', 'amount', 'created_at'] },
    { file: ['url'] }, 
    { expenses: [
      'id', 
      { rate: ['id', { charge: ['id', 'name', 'charge_type'] }, 'value', 'basic_value_type', 'fee_value_type', {variant: ['minimum_duration', 'minimum_amount']}] },
      { default_charge: ['id', { charge: ['name', 'charge_type'] }, 'basic_rate_type'] }, 
      { service: ['name'] },
      'name', 
      'unit_price', 
      'quantity',
      'hourly_charge_duration',
      'updated_at'
    ]}, 
    { recipients: ['id', 'delivery_method', 'name', 'phone', 'email'] },
    'notes'] 
  },
  { vehicle: [
    'id',
    'status',
    'vin',
    'make',
    'model',
    'year',
    'color',
    'drive_type',
    'license',
    'license_state',
    'odometer'
  ] },
  { truck: ['id', 'make', 'model', 'year'] },
  { customer: ['id', 'name', { cards: ['id', 'brand', 'last4'] }, 'email', 'phone'] },
  { company_location: ['id', 'name', 'email', 'phone', 'address', { company: ['id', 'name', 'add_access_token_available'] }] },
  { destination_location: ['longitude', 'latitude', 'address'] },
  'customer_token',
  'number',
  'class_type',
  'description',
  'internal_notes',
  'dispatcher_notes',
  'caller_name',
  'caller_phone',
  'member_first_name',
  'member_last_name',
  'member_phone',
  'authorization_number',
  'keys_availability', 
  'driver_attendance', 
  'insurance_company',
  'priority',
  'tow_reason',
  'service_comments',
  'equipment_type',
  'police_number',
  'police_code',
  'digital_dispatch_webhook',
  'urgently_partner_name',
  'urgently_disablement_reason',
  'urgently_callback'
];
const statisticsAttributes = [
  'new_orders_count',
  'completed_orders_count',
  'rejected_orders_count',
  'expired_orders_count',
  'accepted_orders_count',
  'accepted_orders_percent',
  'eta_accuracy'
];

export const getUserOrders = getThunkActionCreator(
  getUserOrdersRoutine,
  async ({ user_id, limit = 5, offset = 0, orders = { updated_at: 'desc' }, statuses = null }) => {
    getUserOrders.getUserOrdersCancel && getUserOrders.getUserOrdersCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/users/${user_id}/orders`, {
      params: { access_token, limit, offset, orders, attributes: shortAttributes, ...(statuses && { statuses }) },
      cancelToken: new CancelToken(function executor(cancel) { getUserOrders.getUserOrdersCancel = cancel })
    });
  }
);
export const getOrdersForDashboard = getThunkActionCreator(
  getOrdersForDashboardRoutine,
  async ({ offset = 0, limit = 4, statuses = null, with_user = false, without_user = false, isScheduled = false, isCounts = false }) => {
    getOrdersForDashboard.getOrdersForDashboardCancel && getOrdersForDashboard.getOrdersForDashboardCancel();
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.get(`/user/orders`, {
      params: { 
        access_token, 
        offset, 
        limit,
        orders: { created_at: 'desc' }, 
        ...(with_user && { with_user }), 
        ...(without_user && { without_user }),  
        ...(statuses && { statuses }), 
        attributes: shortAttributes 
      },
      cancelToken: new CancelToken(function executor(cancel) { getOrdersForDashboard.getOrdersForDashboardCancel = cancel })
    });

    return { response, with_user, without_user, isScheduled, isCounts };
  }
);
export const getOrders = getThunkActionCreator(
  getOrdersRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, user_id = null, query = null, statuses = [] }) => {
    getOrders.getOrdersCancel && getOrders.getOrdersCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/orders`, {
      params: { 
        access_token, 
        limit, 
        offset, 
        orders, 
        ...(!!user_id && { user_id }), 
        ...(!!query && { query }), 
        ...(!!!query && { statuses: statuses.length ? statuses : Object.values(_omit(orderStatuses, ['STORED', 'LAID', 'FOR_SALE', 'RELEASED', 'CANCELED'])) }), 
        attributes: shortAttributes 
      },
      cancelToken: new CancelToken(function executor(cancel) { getOrders.getOrdersCancel = cancel })
    });
  }
);
export const getCustomersOrders = getThunkActionCreator(
  getCustomersOrdersRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, customer_query = null }) => {
    getCustomersOrders.getCustomersOrdersCancel && getCustomersOrders.getCustomersOrdersCancel();
    const { token: access_token } = await sessionService.loadSession();
    const attributes = ['id', 'number', { customer: ['id', 'name'] }];

    return await axios.get(`/user/orders`, {
      params: { 
        access_token, 
        limit, 
        offset, 
        orders, 
        ...(!!customer_query && { customer_query }), 
        attributes 
      },
      cancelToken: new CancelToken(function executor(cancel) { getCustomersOrders.getCustomersOrdersCancel = cancel })
    });
  }
);
export const getInvoicesOrders = getThunkActionCreator(
  getInvoicesOrdersRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, self_invoice_query = null }) => {
    getInvoicesOrders.getInvoicesOrdersCancel && getInvoicesOrders.getInvoicesOrdersCancel();
    const { token: access_token } = await sessionService.loadSession();
    const attributes = ['id', 'number', { invoice: ['id', 'number'] }];

    return await axios.get(`/user/orders`, {
      params: { 
        access_token, 
        limit, 
        offset, 
        orders, 
        ...(!!self_invoice_query && { self_invoice_query }), 
        attributes 
      },
      cancelToken: new CancelToken(function executor(cancel) { getInvoicesOrders.getInvoicesOrdersCancel = cancel })
    });
  }
);
export const getVehiclesOrders = getThunkActionCreator(
  getVehiclesOrdersRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, vehicle_query = null }) => {
    getVehiclesOrders.getVehiclesOrdersCancel && getVehiclesOrders.getVehiclesOrdersCancel();
    const { token: access_token } = await sessionService.loadSession();
    const attributes = ['id', 'number', { vehicle: ['id', 'vin', 'license'] }];

    return await axios.get(`/user/orders`, {
      params: { 
        access_token, 
        limit, 
        offset, 
        orders, 
        ...(!!vehicle_query && { vehicle_query }), 
        attributes 
      },
      cancelToken: new CancelToken(function executor(cancel) { getVehiclesOrders.getVehiclesOrdersCancel = cancel })
    });
  }
);
export const getOrder = getThunkActionCreator(
  getOrderRoutine,
  async ({ id, side, customer_token = null }) => {
    getOrder.getOrderCancel && getOrder.getOrderCancel();
    let access_token = null;

    if (!!!customer_token) {
      access_token = await sessionService.loadSession();
    }

    return await axios.get(`/${side}/orders/${id}`, {
      params: { ...(!!access_token ? { access_token: access_token?.token } : { customer_token }), attributes: !!access_token ? fullAttributes : customerAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getOrder.getOrderCancel = cancel })
    });
  }
);
export const getOrderForNotification = getThunkActionCreator(
  getOrderForNotificationRoutine,
  async (id) => {
    getOrderForNotification.getOrderForNotificationCancel && getOrderForNotification.getOrderForNotificationCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/orders/${id}`, {
      params: { access_token, attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getOrderForNotification.getOrderForNotificationCancel = cancel })
    });
  }
);
export const createOrder = getThunkActionCreator(
  createOrderRoutine,
  async ({ order, side, company_id = null }) => {
    let access_token = null;
    const customer_token = localStorage.getItem('customer_token');

    if (!!!customer_token) {
      access_token = await sessionService.loadSession();
    }

    return await axios.post(`/${side}/orders`, { 
      ...(!!access_token ? { access_token: access_token?.token } : { customer_token, company_id }),
      order, 
      attributes: !!customer_token ? ['id', 'number'] : fullAttributes
    });
  }
);
export const updateOrder = getThunkActionCreator(
  updateOrderRoutine,
  async ({ id, side, ...fields }) => {
    let access_token = null;
    const customer_token = localStorage.getItem('customer_token');

    if (!!!customer_token) {
      access_token = await sessionService.loadSession();
    }

    const response = await axios.patch(`/${side}/orders/${id}`, { 
      ...(!!access_token ? { access_token: access_token?.token } : { customer_token }), 
      order: _omit(fields, ['localDocuments', 'localUser', 'side']) 
    });
    return { response, order: { id, ..._omit(fields, ['documents_attributes' , 'user_id', 'customer_location_id', 'side']) } };
  }
); 
export const deleteOrder = getThunkActionCreator(
  deleteOrderRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/orders/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const supplementOrder = getThunkActionCreator(
  supplementOrderRoutine,
  async ({ id, user_id, user = null, eta, truck_id = null }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/supplement`, { access_token, user_id, eta, ...(!!truck_id && { truck_id }) });

    return { response, id, user, eta };
  }
);
export const rejectOrder = getThunkActionCreator(
  rejectOrderRoutine,
  async ({ id, reject_description }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/reject`, { access_token, reject_description });

    return { response, id };
  }
);
export const cancelOrder = getThunkActionCreator(
  cancelOrderRoutine,
  async ({ id, reason = null }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/cancel`, { access_token, ...(!!reason && { reason }) });

    return { response, id };
  }
);
export const renewOrder = getThunkActionCreator(
  renewOrderRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/renew`, { access_token });

    return { response, id };
  }
);
export const startOrderMovement = getThunkActionCreator(
  startOrderMovementRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/start_movement`, { access_token });

    return { response, id };
  }
);
export const pickUpOrder = getThunkActionCreator(
  pickUpOrderRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/pick_up`, { access_token });

    return { response, id };
  }
);
export const serviceOrder = getThunkActionCreator(
  serviceOrderRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/service`, { access_token });

    return { response, id };
  }
);
export const dropOffOrder = getThunkActionCreator(
  dropOffOrderRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/drop_off`, { access_token });

    return { response, id };
  }
);
export const completeOrder = getThunkActionCreator(
  completeOrderRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/complete`, { access_token });

    return { response, id };
  }
);
export const reverseStatus = getThunkActionCreator(
  reverseStatusRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/reverse_status`, { access_token });

    return { response, id };
  }
);
export const applyCustomerLocation = getThunkActionCreator(
  applyCustomerLocationRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/apply_customer_location`, { access_token });

    return { response, id };
  }
);
export const rejectCustomerLocation = getThunkActionCreator(
  rejectCustomerLocationRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/orders/${id}/reject_customer_location`, { access_token });

    return { response, id };
  }
);
export const sendOrderTracking = getThunkActionCreator(  
  sendOrderTrackingRoutine,
  async ({ id, recipient_ids }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/orders/${id}/send_tracking`, { access_token, recipient_ids });
  }
);
export const changeLocalOrderStatus = getThunkActionCreator(changeLocalOrderStatusRoutine, async ({ order_id, status, previous_status }) => ({ order_id, status, previous_status }));
export const clearLocalUserOrders = getThunkActionCreator(clearLocalUserOrdersRoutine, async () => {});
export const clearLocalOrdersWithUser = getThunkActionCreator(clearLocalOrdersWithUserRoutine, async () => {});
export const clearLocalOrders = getThunkActionCreator(clearLocalOrdersRoutine, async () => {});
export const clearLocalCustomersOrders = getThunkActionCreator(clearLocalCustomersOrdersRoutine, async () => {});
export const clearLocalInvoicesOrders = getThunkActionCreator(clearLocalInvoicesOrdersRoutine, async () => {});
export const clearLocalVehiclesOrders = getThunkActionCreator(clearLocalVehiclesOrdersRoutine, async () => {});
export const clearLocalActiveOrder = getThunkActionCreator(clearLocalActiveOrderRoutine, async () => {});
export const setLocalOrderFields = getThunkActionCreator(setLocalOrderFieldsRoutine, async (fields) => fields);
export const getOrdersStatistics = getThunkActionCreator(
  getOrdersStatisticsRoutine,
  async ({ start_at = null, end_at = null }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.get(`/user/orders/statistics`,
      {
        params: { access_token, attributes: statisticsAttributes, ...(!!start_at && { start_at, end_at }) },
        cancelToken: new CancelToken(function executor(cancel) { getOrdersStatistics.getOrdersStatisticsCancel = cancel })
      }
    );

    return { response, isEta: !!start_at };
  }
);
export const clearOrdersStatistics = getThunkActionCreator(clearOrdersStatisticsRoutine, async () => {});
import { memo } from 'react';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
// Local files
import { Container, Root, OfflineHeader, OfflineTitle, OfflineStatus, OfflineButton, Header, Heading, Title, StyledControlLabel, SmallButton } from './DriverInfo.styled';
import { ReactComponent as OfflineIcon } from 'assets/icons/offline.svg';
import OrderItem from 'components/Map/Components/DriverInfo/OrderItem/OrderItem';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const isNoSignal = false; // TODO
const DriverInfo = ({ open, activePoint, duration, onClose, onChangeDutyStatus }) => {

  if (!open || activePoint.category !== 'driver') return null;

  return (
    <Container  style={{ zIndex: 20 }}>
      <Root>
        {isNoSignal &&
          <OfflineHeader>
            <OfflineIcon />
            <OfflineTitle>Offline</OfflineTitle>
            <OfflineStatus>Online 5min ago</OfflineStatus>
            <OfflineButton>RELOAD</OfflineButton>
        </OfflineHeader>}
        <Header>
          <Heading>
            <Title>
              {activePoint.data?.first_name ?? '-'} {activePoint.data?.last_name ?? '-'}
            </Title>
            <SmallButton onClick={onClose}>
              <CloseIcon />
            </SmallButton>
          </Heading>
          <StyledControlLabel
            control={
              <Switch
                onChange={() => onChangeDutyStatus(activePoint.id, !!activePoint.data?.duty_duration)}
                color="primary"
                checked={!!activePoint.data?.duty_duration}
              />
            }
            label="On Duty"
            labelPlacement="end"
          />

        </Header>
        <OrderItem {...activePoint.data} duration={duration} />
      </Root>
    </Container>
  );
};

DriverInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  activePoint: PropTypes.shape({
    data: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }),
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <DriverInfo {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  width: '100%',
  maxWidth: '100%',
});

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(42),
  fontWeight: 700,
}));

export const HeaderDetails = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px'
})

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 700,
}));

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  color: '#fff',
  background: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: ' 24px 16px',
  padding: '20px 24px',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px 12px 0 0',
  }
}));

export const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
})

export const Details = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '16px 32px',
  padding: '24px 16px 24px',
  [theme.breakpoints.up('md')]: {
    padding: '24px 30px 24px',
  }
}))

export const Divider = styled(Box)({
  width: '100%',
  borderBottom: '1px dotted #D0CEE4',

})

export const Content = styled(Box)(({ theme }) => ({
  background: '#fff',
    boxShadow: `inset 0px -1px 0px ${theme.palette.divider}`,
  [theme.breakpoints.up('md')]: {
    borderRadius: '0 0 12px 12px',
    boxShadow: '0px 7px 10px 0px #0000001A',
    }
}))

export const TableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '20px 16px 20px',
  [theme.breakpoints.up('md')]: {
    padding: '20px 30px 20px',
  }
}))

export const TableHeading = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '16px 32px',
})

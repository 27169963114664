import { memo, useEffect, useCallback, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowIcon from '@mui/icons-material/ChevronRight';
import _map from 'lodash/map';
//Local files
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import useCustomSelector from 'hooks/useCustomSelector';
import useJobs from 'hooks/useJobs';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';

const ServicesList = ({ jobType }) => {
  const { setAccountServiceData } = useApp();
  const { getJobs, getJob, clearLocalJobs, clearLocalActiveJob } = useJobs();
  const { id: account_id } = useCustomSelector(state => state.accounts.account);
  const { data, pagination } = useCustomSelector(state => state.jobs.all)
  const { id: job_id } = useCustomSelector(state => state.jobs.job);
  const { setError } = useError();
  const [fetching, setFetching] = useState(false);

  const fetchJobs = useCallback((offset, job_type, account_id) => {
    setFetching(true);
    getJobs({ offset, job_type, account_id })
    .catch(error => setError(error))
    .finally(() => setFetching(false));
  }, [getJobs, setError]);
  const fetchJob = (id) => {
    setFetching(true);
    getJob(id)
    .then(({ payload: { data: { job: { variants } } } }) => !!variants?.length && setAccountServiceData({ activeVariant: variants[0] }))
    .catch((error) => setError(error))
    .finally(() => setFetching(false))
  };

  useEffect(() => {
    !!account_id && fetchJobs(0, jobType, account_id);

    return () => {
      clearLocalJobs();
      clearLocalActiveJob();
    }
  }, [fetchJobs, jobType, account_id, clearLocalJobs, clearLocalActiveJob]);

  return (
    <BaseScrollableContainer sx={{ pl: 2 }} {...pagination} onLoad={offset => !fetching && fetchJobs(offset, jobType, account_id)}>
      <List disablePadding>
        {_map(data, ({ id, service }) => (
          <ListItem key={id} disablePadding>
            <ListItemButton selected={id === job_id} disabled={fetching} onClick={() => id !== job_id && fetchJob(id)}>
              <ListItemText primary={service?.name} />
              <ArrowIcon />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </BaseScrollableContainer>
  );
};

export default memo(ServicesList);
import { createThunkRoutine } from 'redux-thunk-routine';
// Accounts
export const getListAccountsRoutine = createThunkRoutine('GET_LIST_ACCOUNTS');
export const getAccountsRoutine = createThunkRoutine('GET_ACCOUNTS');
export const getAccountRoutine = createThunkRoutine('GET_ACCOUNT');
export const createAccountRoutine = createThunkRoutine('CREATE_ACCOUNT');
export const duplicateAccountRoutine = createThunkRoutine('DUPLICATE_ACCOUNT');
export const updateAccountRoutine = createThunkRoutine('UPDATE_ACCOUNT');
export const deleteAccountRoutine = createThunkRoutine('DELETE_ACCOUNT');
export const clearLocalListAccountsRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_ACCOUNTS');
export const clearLocalAccountsRoutine = createThunkRoutine('CLEAR_LOCAL_ACCOUNTS');
export const clearLocalActiveAccountRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_ACCOUNT');
// Adds
export const getAddRoutine = createThunkRoutine('GET_ADD');
export const createAddRoutine = createThunkRoutine('CREATE_ADD');
export const clearLocalActiveAddRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_ADD');
// App
export const openAppDrawerRoutine = createThunkRoutine('OPEN_APP_DRAWER');
export const closeAppDrawerRoutine = createThunkRoutine('CLOSE_APP_DRAWER');
export const setAppFilterRoutine = createThunkRoutine('SET_APP_FILTER');
export const startRemovingRoutine = createThunkRoutine('START_REMOVING');
export const stopRemovingRoutine = createThunkRoutine('STOP_REMOVING');
export const setAccountServiceDataRoutine = createThunkRoutine('SET_ACCOUNT_SERVICE_DATA');
export const filterExpencesDataRoutine = createThunkRoutine('FILTER_EXPENCES_DATA');
export const setFetchingDetailsRoutine = createThunkRoutine('SET_FETCHING_DETAILS');
// Blobs
export const getBlobRoutine = createThunkRoutine('GET_BLOB');
export const createBlobRoutine = createThunkRoutine('CREATE_BLOB');
// Cards
export const createCardRoutine = createThunkRoutine('CREATE_CARD');
export const deleteCardRoutine = createThunkRoutine('DELETE_CARD');
// Categories
export const getListCategoriesRoutine = createThunkRoutine('GET_LIST_CATEGORIES');
export const getCategoriesRoutine = createThunkRoutine('GET_CATEGORIES');
export const getCategoryRoutine = createThunkRoutine('GET_CATEGORY');
export const createCategoryRoutine = createThunkRoutine('CREATE_CATEGORY');
export const clearLocalListCategoriesRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_CATEGORIES');
export const clearLocalCategoriesRoutine = createThunkRoutine('CLEAR_LOCAL_CATEGORIES');
export const clearLocalActiveCategoryRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_CATEGORY');
// Changes
export const getChangesRoutine = createThunkRoutine('GET_CHANGES');
export const getChangeRoutine = createThunkRoutine('GET_CHANGE');
export const clearLocalChangesRoutine = createThunkRoutine('CLEAR_LOCAL_CHANGES');
// Channel Messages
export const getChannelMessagesRoutine = createThunkRoutine('GET_CHANNEL_MESSAGES');
export const getChannelMessageRoutine = createThunkRoutine('GET_CHANNEL_MESSAGES');
export const clearLocalChannelMessagesRoutine = createThunkRoutine('CLEAR_LOCAL_CHANNEL_MESSAGES');
// Charges
export const getCompanyChargesRoutine = createThunkRoutine('GET_COMPANY_CHARGES');
export const getFullListChargesRoutine = createThunkRoutine('GET_FULL_LIST_CHARGES');
export const getListChargesRoutine = createThunkRoutine('GET_LIST_CHARGES');
export const getDefaultChargesRoutine = createThunkRoutine('GET_DEFAULT_CHARGES');
export const getChargeRoutine = createThunkRoutine('GET_CHARGE');
export const createChargeRoutine = createThunkRoutine('CREATE_CHARGE');
export const createCompanyChargeRoutine = createThunkRoutine('CREATE_COMPANY_CHARGE');
export const deleteCompanyChargeRoutine = createThunkRoutine('DELETE_COMPANY_CHARGE');
export const clearLocalListChargesRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_CHARGES');
export const clearLocalCompanyChargesRoutine = createThunkRoutine('CLEAR_LOCAL_COMPANY_CHARGES');
export const clearLocalActiveChargeRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_CHARGE');
// Companies
export const getCompaniesRoutine = createThunkRoutine('GET_COMPANIES');
export const updateCompanyRoutine = createThunkRoutine('UPDATE_COMPANY');
export const clearLocalCompaniesRoutine = createThunkRoutine('CLEAR_LOCAL_COMPANIES');
// Compensations
export const compensationsReportRoutine = createThunkRoutine('COMPENSATIONS_REPORT');
// Confirmations
export const sendConfirmationCodeRoutine = createThunkRoutine('SEND_CONFIRMATION_CODE');
export const validateConfirmationCodeRoutine = createThunkRoutine('VALIDATE_CONFIRMATION_CODE');
// Customers
export const getCustomersRoutine = createThunkRoutine('GET_CUSTOMERS');
export const getCustomerRoutine = createThunkRoutine('GET_CUSTOMER');
export const createCustomerRoutine = createThunkRoutine('CREATE_CUSTOMER');
export const updateCustomerRoutine = createThunkRoutine('UPDATE_CUSTOMER');
export const clearLocalCustomersRoutine = createThunkRoutine('CLEAR_LOCAL_CUSTOMERS');
export const clearLocalActiveCustomerRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_CUSTOMER');
// Dialogs
export const openAssignDialogRoutine = createThunkRoutine('OPEN_ASSIGN_DIALOG');
export const closeAssignDialogRoutine = createThunkRoutine('CLOSE_ASSIGN_DIALOG');
export const openCancelOrderDialogRoutine = createThunkRoutine('OPEN_CANCEL_ORDER_DIALOG');
export const closeCancelOrderDialogRoutine = createThunkRoutine('CLOSE_CANCEL_ORDER_DIALOG');
export const openConfirmationDialogRoutine = createThunkRoutine('OPEN_CONFIRMATION_DIALOG');
export const closeConfirmationDialogRoutine = createThunkRoutine('CLOSE_CONFIRMATION_DIALOG');
export const openDocumentsDialogRoutine = createThunkRoutine('OPEN_DOCUMENTS_DIALOG');
export const closeDocumentsDialogRoutine = createThunkRoutine('CLOSE_DOCUMENTS_DIALOG');
export const openFilePreviewDialogRoutine = createThunkRoutine('OPEN_FILE_PREVIEW_DIALOG');
export const closeFilePreviewDialogRoutine = createThunkRoutine('CLOSE_FILE_PREVIEW_DIALOG');
export const openInvoiceDialogRoutine = createThunkRoutine('OPEN_INVOICE_DIALOG');
export const closeInvoiceDialogRoutine = createThunkRoutine('CLOSE_INVOICE_DIALOG');
export const openLocationDialogRoutine = createThunkRoutine('OPEN_LOCATION_DIALOG');
export const closeLocationDialogRoutine = createThunkRoutine('CLOSE_LOCATION_DIALOG');
export const openMapDialogRoutine = createThunkRoutine('OPEN_MAP_DIALOG');
export const closeMapDialogRoutine = createThunkRoutine('CLOSE_MAP_DIALOG');
export const openPasswordDialogRoutine = createThunkRoutine('OPEN_PASSWORD_DIALOG');
export const closePasswordDialogRoutine = createThunkRoutine('CLOSE_PASSWORD_DIALOG');
export const openRejectOrderDialogRoutine = createThunkRoutine('OPEN_REJECT_ORDER_DIALOG');
export const closeRejectOrderDialogRoutine = createThunkRoutine('CLOSE_REJECT_ORDER_DIALOG');
export const openSignatureDialogRoutine = createThunkRoutine('OPEN_SIGNATURE_DIALOG');
export const closeSignatureDialogRoutine = createThunkRoutine('CLOSE_SIGNATURE_DIALOG');
export const openTowVehicleDialogRoutine = createThunkRoutine('OPEN_TOW_VEHICLE_DIALOG');
export const closeTowVehicleDialogRoutine = createThunkRoutine('CLOSE_TOW_VEHICLE_DIALOG');
export const openTakeOutVehicleDialogRoutine = createThunkRoutine('OPEN_TAKE_OUT_VEHICLE_DIALOG');
export const closeTakeOutVehicleDialogRoutine = createThunkRoutine('CLOSE_TAKE_OUT_VEHICLE_DIALOG');
// Documents
export const getDocumentsRoutine = createThunkRoutine('GET_DOCUMENTS');
export const getDocumentRoutine = createThunkRoutine('GET_DOCUMENT');
export const createDocumentsRoutine = createThunkRoutine('CREATE_DOCUMENTS');
export const deleteDocumentRoutine = createThunkRoutine('DELETE_DOCUMENT');
export const clearLocalDocumentsRoutine = createThunkRoutine('CLEAR_LOCAL_DOCUMENTS');
export const clearLocalActiveDocumentRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_DOCUMENT');
// Errors
export const setErrorRoutine = createThunkRoutine('SET_ERROR');
export const hideErrorRoutine = createThunkRoutine('HIDE_ERROR');
// Expenses
export const createExpenseRoutine = createThunkRoutine('CREATE_EXPENSE');
export const updateExpenseRoutine = createThunkRoutine('UPDATE_EXPENSE');
export const deleteExpenseRoutine = createThunkRoutine('DELETE_EXPENSE');
export const suggestInvoiceExpensesRoutine = createThunkRoutine('FORM_INVOICE_EXPENSES');
// InsuranceAccounts
export const getListInsuranceAccountsRoutine = createThunkRoutine('GET_LIST_INSURANCE_ACCOUNTS');
export const clearLocalListInsuranceAccountsRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_INSURANCE_ACCOUNTS');
// Invoices
export const getInvoicesRoutine = createThunkRoutine('GET_INVOICES');
export const getInvoiceRoutine = createThunkRoutine('GET_INVOICE');
export const getInvoiceForNotificationRoutine = createThunkRoutine('GET_INVOICE_FOR_NOTIFICATION');
export const createInvoiceSignatureRoutine = createThunkRoutine('CREATE_INVOICE_SIGNATURE');
export const updateInvoiceRoutine = createThunkRoutine('UPDATE_INVOICE');
export const approveInvoiceRoutine = createThunkRoutine('APPROVE_INVOICE');
export const unApproveInvoiceRoutine = createThunkRoutine('UN_APPROVE_INVOICE');
export const rejectInvoiceRoutine = createThunkRoutine('REJECT_INVOICE');
export const sendInvoiceDetailsRoutine = createThunkRoutine('SEND_INVOICE_DETAILS');
export const clearLocalInvoicesRoutine = createThunkRoutine('CLEAR_LOCAL_INVOICES');
export const clearLocalActiveInvoiceRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_INVOICE');
export const getInvoicesStatisticsRoutine = createThunkRoutine('GET_INVOICES_STATISTICS');
export const clearInvoicesStatisticsRoutine = createThunkRoutine('CLEAR_INVOICES_STATISTICS');
// Jobs
export const getJobsRoutine = createThunkRoutine('GET_JOBS');
export const getJobRoutine = createThunkRoutine('GET_JOB');
export const createJobRoutine = createThunkRoutine('CREATE_JOB');
export const deleteJobRoutine = createThunkRoutine('DELETE_JOB');
export const clearLocalJobsRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_JOBS');
export const clearLocalActiveJobRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_JOB');
// Locations
export const getListLocationsRoutine = createThunkRoutine('GET_LIST_LOCATIONS');
export const getLocationsRoutine = createThunkRoutine('GET_LOCATIONS');
export const getLocationRoutine = createThunkRoutine('GET_LOCATION');
export const createLocationRoutine = createThunkRoutine('CREATE_LOCATION');
export const updateLocationRoutine = createThunkRoutine('UPDATE_LOCATION');
export const deleteLocationRoutine = createThunkRoutine('DELETE_LOCATION');
export const clearLocalListLocationsRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_LOCATIONS');
export const clearLocalLocationsRoutine = createThunkRoutine('CLEAR_LOCAL_LOCATIONS');
export const clearLocalActiveLocationRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_LOCATION');
// Map
export const getMapDriversRoutine = createThunkRoutine('GET_MAP_DRIVERS');
export const getUrgentlyMapDriversRoutine = createThunkRoutine('GET_URGENTLY_MAP_DRIVERS');
export const getMapOrdersRoutine = createThunkRoutine('GET_MAP_ORDERS');
export const setActualDriverDataRoutine = createThunkRoutine('SET_ACTUAL_DRIVER_DATA');
export const getDirectionOnMapRoutine = createThunkRoutine('GET_DIRECTION_ON_MAP');
export const clearLocalMapDataRoutine = createThunkRoutine('CLEAR_LOCAL_MAP_DATA');
export const setMapConfigsRoutine = createThunkRoutine('SET_MAP_CONFIGS');
// Notifications
export const updateNotificationRoutine = createThunkRoutine('UPDATE_NOTIFICATION');
// Orders
export const getUserOrdersRoutine = createThunkRoutine('GET_USER_ORDERS');
export const getOrdersForDashboardRoutine = createThunkRoutine('GET_ORDERS_WITH_USER');
export const getOrdersRoutine = createThunkRoutine('GET_ORDERS');
export const getCustomersOrdersRoutine = createThunkRoutine('GET_CUSTOMERS_ORDERS');
export const getInvoicesOrdersRoutine = createThunkRoutine('GET_INVOICES_ORDERS');
export const getVehiclesOrdersRoutine = createThunkRoutine('GET_VEHICLES_ORDERS');
export const getOrderRoutine = createThunkRoutine('GET_ORDER');
export const getOrderForNotificationRoutine = createThunkRoutine('GET_ORDER_FOR_NOTIFICATION');
export const createOrderRoutine = createThunkRoutine('CREATE_ORDER');
export const updateOrderRoutine = createThunkRoutine('UPDATE_ORDER');
export const deleteOrderRoutine = createThunkRoutine('DELETE_ORDER');
export const supplementOrderRoutine = createThunkRoutine('SUPPLEMENT_ORDER');
export const rejectOrderRoutine = createThunkRoutine('REJECT_ORDER');
export const cancelOrderRoutine = createThunkRoutine('CANCEL_ORDER');
export const renewOrderRoutine = createThunkRoutine('RENEW_ORDER');
export const startOrderMovementRoutine = createThunkRoutine('START_ORDER_MOVEMENT');
export const pickUpOrderRoutine = createThunkRoutine('PICK_UP_ORDER');
export const serviceOrderRoutine = createThunkRoutine('SERVICE_ORDER');
export const dropOffOrderRoutine = createThunkRoutine('DPOP_OFF_ORDER');
export const completeOrderRoutine = createThunkRoutine('COMPLETE_ORDER');
export const reverseStatusRoutine = createThunkRoutine('REVERSE_STATUS');
export const applyCustomerLocationRoutine = createThunkRoutine('APPLY_CUSTOMER_LOCATION');
export const rejectCustomerLocationRoutine = createThunkRoutine('REJECT_CUSTOMER_LOCATION');
export const sendOrderTrackingRoutine = createThunkRoutine('SEND_ORDER_TRACKING');
export const changeLocalOrderStatusRoutine = createThunkRoutine('CHANGE_LOCAL_ORDER_STATUS');
export const clearLocalUserOrdersRoutine = createThunkRoutine('CLEAR_LOCAL_USER_ORDERS');
export const clearLocalOrdersWithUserRoutine = createThunkRoutine('CLEAR_ORDERS_WITH_USER');
export const clearLocalOrdersRoutine = createThunkRoutine('CLEAR_LOCAL_ORDERS');
export const clearLocalCustomersOrdersRoutine = createThunkRoutine('CLEAR_LOCAL_CUSTOMERS_ORDERS');
export const clearLocalInvoicesOrdersRoutine = createThunkRoutine('CLEAR_LOCAL_INVOICES_ORDERS');
export const clearLocalVehiclesOrdersRoutine = createThunkRoutine('CLEAR_LOCAL_VEHICLES_ORDERS');
export const clearLocalActiveOrderRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_ORDER');
export const getOrdersStatisticsRoutine = createThunkRoutine('GET_ORDERS_STATISTICS');
export const clearOrdersStatisticsRoutine = createThunkRoutine('CLEAR_ORDERS_STATISTICS');
export const setLocalOrderFieldsRoutine = createThunkRoutine('SET_LOCAL_ORDER_DOCUMENTS');
// Payments
export const getPaymentsRoutine = createThunkRoutine('GET_PAYMENTS')
export const createPaymentRoutine = createThunkRoutine('CREATE_PAYMENT');
export const deletePaymentRoutine = createThunkRoutine('DELETE_PAYMENT');
export const clearLocalPaymentsRoutine = createThunkRoutine('CLEAR_LOCAL_PAYMENTS');
// Profile
export const getProfileRoutine = createThunkRoutine('GET_PROFILE');
export const updateProfileRoutine = createThunkRoutine('UPDATE_PROFILE');
export const changePasswordRoutine = createThunkRoutine('CHANGE_PASSWORD');
export const startDutyRoutine = createThunkRoutine('START_DUTY');
export const stopDutyRoutine = createThunkRoutine('STOP_DUTY');
export const startUserDutyRoutine = createThunkRoutine('START_USER_DUTY');
export const stopUserDutyRoutine = createThunkRoutine('STOP_USER_DUTY');
// Rates
export const getRateRoutine = createThunkRoutine('GET_RATE');
export const createRateRoutine = createThunkRoutine('CREATE_RATE');
export const updateRateRoutine = createThunkRoutine('UPDATE_RATE');
export const deleteRateRoutine = createThunkRoutine('DELETE_RATE');
export const clearLocalRatesRoutine = createThunkRoutine('CLEAR_LOCAL_RATES');
export const clearLocalActiveRateRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_RATE');
// Recipients
export const createRecipientsRoutine = createThunkRoutine('CREATE_RECIPIENT');
export const updateRecipientsRoutine = createThunkRoutine('UPDATE_RECIPIENT');
export const deleteRecipientRoutine = createThunkRoutine('DELETE_RECIPIENT');
// Requests
export const getRequestsRoutine = createThunkRoutine('GET_REQUESTS');
export const getRequestRoutine = createThunkRoutine('GET_REQUEST');
export const createRequestRoutine = createThunkRoutine('CREATE_REQUEST');
export const answerRequestRoutine = createThunkRoutine('ANSWER_REQUEST');
export const clearLocalRequestsRoutine = createThunkRoutine('CLEAR_LOCAL_REQUESTS');
// Services
export const getCompanyServicesRoutine = createThunkRoutine('GET_COMPANY_SERVICES');
export const getFullListServicesRoutine = createThunkRoutine('GET_FULL_LIST_SERVICES');
export const getListServicesRoutine = createThunkRoutine('GET_LIST_SERVICES');
export const getServicesRoutine = createThunkRoutine('GET_SERVICES');
export const getServiceRoutine = createThunkRoutine('GET_SERVICE');
export const createServiceRoutine = createThunkRoutine('CREATE_SERVICE');
export const createCompanyServiceRoutine = createThunkRoutine('CREATE_COMPANY_SERVICE');
export const deleteCompanyServiceRoutine = createThunkRoutine('DELETE_COMPANY_SERVICE');
export const clearLocalListServicesRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_SERVICES');
export const clearLocalServicesRoutine = createThunkRoutine('CLEAR_LOCAL_SERVICES');
export const clearLocalCompanyServicesRoutine = createThunkRoutine('CLEAR_LOCAL_COMPANY_SERVICES');
export const clearLocalActiveServiceRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_SERVICE');
// Sessions
export const setLocalTokenRoutine = createThunkRoutine('SET_LOCAL_TOKEN');
export const getUserSessionsRoutine = createThunkRoutine('GET_USER_SESSIONS');
export const getSessionsRoutine = createThunkRoutine('GET_SESSIONS');
export const getSessionRoutine = createThunkRoutine('GET_SESSION');
export const createSessionRoutine = createThunkRoutine('CREATE_SESSION');
export const deleteActiveSessionRoutine = createThunkRoutine('DELETE_ACTIVE_SESSION');
export const deleteSessionRoutine = createThunkRoutine('DELETE_SESSION');
export const setRecoveryEmailRoutine = createThunkRoutine('SET_RECOVERY_EMAIL');
export const setConfirmationCodeRoutine = createThunkRoutine('SET_CONFIRMATION_CODE');
export const clearLocalRecoveryDataRoutine = createThunkRoutine('CLEAR_LOCAL_RECOVERY_DATA');
export const clearStoreRoutine = createThunkRoutine('CLEAR_STORE');
// Stripes
export const createCompanyStripeUrlRoutine = createThunkRoutine('CREATE_ACCOUNT_STRIPE_URL');
export const createStripeBillingPortalRoutine = createThunkRoutine('CREATE_STRIPE_BILLING_PORTAL');
export const createStripeCheckoutRoutine = createThunkRoutine('CREATE_STRIPE_CHECHOUT');
// Success
export const setSuccessRoutine = createThunkRoutine('SET_SUCCESS');
export const hideSuccessRoutine = createThunkRoutine('HIDE_SUCCESS');
// Trucks
export const getListTrucksRoutine = createThunkRoutine('GET_LIST_TRUCKS');
export const getTrucksRoutine = createThunkRoutine('GET_TRUCKS');
export const getTruckRoutine = createThunkRoutine('GET_TRUCK');
export const createTruckRoutine = createThunkRoutine('CREATE_TRUCK');
export const updateTruckRoutine = createThunkRoutine('UPDATE_TRUCK');
export const deleteTruckRoutine = createThunkRoutine('DELETE_TRUCK');
export const clearLocalListTrucksRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_TRUCKS');
export const clearLocalTrucksRoutine = createThunkRoutine('CLEAR_LOCAL_TRUCKS');
export const clearLocalActiveTruckRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_TRUCK');
// Types
export const getListTypesRoutine = createThunkRoutine('GET_LIST_TYPES');
export const clearLocalListTypesRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_TYPES');
// Users
export const getDriversListRoutine = createThunkRoutine('GET_DRIVERS_LIST');
export const getManagersListRoutine = createThunkRoutine('GET_MANAGERS_LIST');
export const getUsersRoutine = createThunkRoutine('GET_USERS');
export const getUsersStatisticsRoutine = createThunkRoutine('GET_USERS_STATISTICS');
export const getUserRoutine = createThunkRoutine('GET_USER');
export const createUserRoutine = createThunkRoutine('CREATE_USER');
export const updateUserRoutine = createThunkRoutine('UPDATE_USER');
export const deleteUserRoutine = createThunkRoutine('DELETE_USER');
export const changeUserPasswordRoutine = createThunkRoutine('CHANGE_USER_PASSWORD');
export const clearLocalDriversListRoutine = createThunkRoutine('CLEAR_LOCAL_DRIVERS_LIST');
export const clearLocalManagersListRoutine = createThunkRoutine('CLEAR_LOCAL_MANAGERS_LIST');
export const clearLocalUsersRoutine = createThunkRoutine('CLEAR_LOCAL_USERS');
export const clearLocalUsersStatisticsRoutine = createThunkRoutine('CLEAR_LOCAL_USERS_STATISTICS');
export const clearLocalActiveUserRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_USER');
// Variants
export const createVariantRoutine = createThunkRoutine('CREATE_VARIANT');
export const updateVariantRoutine = createThunkRoutine('UPDATE_VARIANT');
export const deleteVariantRoutine = createThunkRoutine('DELETE_VARIANT');
// Vehicles
export const getVehiclesRoutine = createThunkRoutine('GET_VECHICLES');
export const getVehicleRoutine = createThunkRoutine('GET_VECHICLE');
export const createVehicleRoutine = createThunkRoutine('CREATE_VEHICLE');
export const updateVehicleRoutine = createThunkRoutine('UPDATE_VEHICLE');
export const storeVehicleRoutine = createThunkRoutine('STORE_VEHICLE');
export const takeOutVehicleRoutine = createThunkRoutine('TAKE_OUT_VEHICLE');
export const releaseVehicleRoutine = createThunkRoutine('RELEASE_VEHICLE');
export const clearLocalVehiclesRoutine = createThunkRoutine('CLEAR_LOCAL_VECHICLES');
export const clearLocalActiveVehicleRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_VECHICLE');
export const getFromVinVehicleInfoRoutine = createThunkRoutine('GET_FROM_VIN_VEHICLE_INFO');
export const getFromPlateVehicleInfoRoutine = createThunkRoutine('GET_FROM_PLATE_VEHICLE_INFO');
export const getVehiclesStatisticsRoutine = createThunkRoutine('GET_VEHICLES_STATISTICS');
export const clearVehiclesStatisticsRoutine = createThunkRoutine('CLEAR_VEHICLES_STATISTICS');
// Widgets
export const getWidgetsRoutine = createThunkRoutine('GET_WIDGETS');
export const getWidgetRoutine = createThunkRoutine('GET_WIDGET');
export const createWidgetRoutine = createThunkRoutine('CREATE_WIDGET');
export const updateWidgetRoutine = createThunkRoutine('UPDATE_WIDGET');
export const updateWidgetsRoutine = createThunkRoutine('UPDATE_WIDGETS');
export const deleteWidgetRoutine = createThunkRoutine('DELETE_WIDGET');
export const clearLocalWidgetsRoutine = createThunkRoutine('CLEAR_LOCAL_WIDGETS');
export const clearLocalActiveWidgetRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_WIDGET');
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 16px 4px 16px'
});

export const Header = styled(Box)({
  width: '100%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
  '& button': {
    flexShrink: 0,
    width: 40,
    height: 40
  }
})
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { 
  createCompanyStripeUrl as createCompanyStripeUrlAction,
  createStripeBillingPortal as createStripeBillingPortalAction,
  createStripeCheckout as createStripeCheckoutAction
} from 'actions/stripes';

const useStripes = () => {
  const dispatch = useDispatch();
  const createCompanyStripeUrl = useCallback(company_id => dispatch(createCompanyStripeUrlAction(company_id)), [dispatch]);
  const createStripeBillingPortal = useCallback(() => dispatch(createStripeBillingPortalAction()), [dispatch]);
  const createStripeCheckout = useCallback(company_id => dispatch(createStripeCheckoutAction(company_id)), [dispatch]);

  return { createCompanyStripeUrl, createStripeBillingPortal, createStripeCheckout };
};

export default useStripes;
import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import _pick from 'lodash/pick';
//Local files
import BaseAddressAutocomplete from 'components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete';
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import { reasons, sides } from 'helpers/constants';
import { DELETE_LOCATION } from 'helpers/confirmations';
import useCustomSelector from 'hooks/useCustomSelector';
import useLocations from 'hooks/useLocations';
import useDialogs from 'hooks/useDialogs';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import { mapboxAccessToken } from 'apis';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Location = () => {
  const { openConfirmationDialog, closeLocationDialog } = useDialogs();
  const { createLocation, updateLocation, clearLocalActiveLocation } = useLocations();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open, company_id } = useCustomSelector(state => state.dialogs.location);
  const uploadedLocation = useCustomSelector(state => state.locations.location);
  const [name, setName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [address, setAddress] = useState({ value: '', error: '', latitude: 0, longitude: 0, draggedMarker: '' });
  const [viewState, setViewState] = useState({ latitude: address?.latitude, longitude: address?.longitude, zoom: 14 });
  const [moving, setMoving] = useState(false);
  const [processing, setProcessing] = useState(false);
  const edit = !!uploadedLocation.id;

  const handleMoveStart = () => setMoving(true);
  const handleMoveEnd = e => {
    setMoving(false);
    setViewState(_pick(e.viewState, ['latitude', 'longitude', 'zoom']));
  };
  const needToUpdate = (a,b) => a.name !== b.name || a.email !== b.email || a.phone !== b.phone || a.company.id !== b.company_id || a.address !== b.address;
  const handleDelete = () => {
    openConfirmationDialog({
      id: uploadedLocation.id,
      content: 'Are you sure you want to delete this location?',
      reason: reasons.LOCATION,
      type: DELETE_LOCATION
    });
  };
  const handleExited = () => {
    setName({ value: '', error: '' });
    setEmail({ value: '', error: '' });
    setPhone({ value: '', formattedValue: '', error: '' });
    setAddress({ value: '', error: '', latitude: 0, longitude: 0, draggedMarker: '' });
    setViewState({ latitude: address?.latitude, longitude: address?.longitude, zoom: 14 });
    clearLocalActiveLocation();
  };
  const handleEntering = () => {
    if (edit) {
      setName({ value: uploadedLocation.name, error: '' });
      setEmail({ value: uploadedLocation.email || '', error: '' });
      setPhone({ value: uploadedLocation.phone || '', formattedValue: uploadedLocation.phone || '', error: '' });
      !!uploadedLocation.address && setAddress({
        value: uploadedLocation.address,
        formattedValue: uploadedLocation.address,
        error: '',
        draggedMarker: '',
        latitude: uploadedLocation.latitude, 
        longitude: uploadedLocation.longitude
      });
      !!uploadedLocation.latitude && setViewState({ latitude: uploadedLocation.latitude, longitude: uploadedLocation.longitude, zoom: 14 });
    }
  };
  const handleSubmitClick = () => {
    let hasError = false;

    if (!!!name.value) {
      setName(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (!!!address.value) {
      setAddress(prev => ({ ...prev, error: `Value can't be empty` }));
      hasError = true;
    }
    if (hasError) return;

    const location = {
      ...(uploadedLocation && { id: uploadedLocation.id }),
      company_id,
      name: name.value,
      email: email.value || null,
      phone: phone.value || null,
      address: address.value,
      longitude: address.longitude,
      latitude: address.latitude,
      side: sides.USER
    };

    setProcessing(true);
    if (edit) {
      if (needToUpdate(uploadedLocation, location)) {
        updateLocation(location)
        .then(() => setSuccess('Location updated').then(closeLocationDialog))
        .catch((error) => setError(error))
        .finally(() => setProcessing(false));
      } else {
        closeLocationDialog();
      }
    } else {
      createLocation({ location, side: sides.USER })
      .then(() => setSuccess('Location created').then(closeLocationDialog))
      .catch((error) => setError(error))
      .finally(() => setProcessing(false));
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeLocationDialog}
      title={`${edit ? 'UPDATE' : 'CREATE'} LOCATION`}
      maxWidth='sm'
      fullWidth
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
    >
      <DialogContent>
        <TextField 
          margin='dense'
          fullWidth
          label='Name' 
          value={name.value} 
          helperText={name.error} 
          error={!!name.error} 
          onChange={({ target: { value } }) => setName({ value, error: '' })} 
        />
        <TextField 
          margin='dense'
          fullWidth
          label='Email' 
          value={email.value} 
          helperText={email.error} 
          error={!!email.error} 
          onChange={({ target: { value } }) => setEmail({ value, error: '' })} 
        />
        <PhoneField
          fullWidth
          margin='dense'
          label='Phone'
          formattedValue={phone.formattedValue}
          error={!!phone.error}
          helperText={phone.error}
          onChange={({ value, formattedValue, error }) => setPhone({ value, formattedValue, error })}
        />
        <BaseAddressAutocomplete
          id='location'
          margin='dense'
          label='Address'
          value={address.formattedValue}
          error={!!address.error}
          helperText={address.error}
          onChange={({ value, formattedValue, error, latitude, longitude }) => { 
            !!latitude && setViewState({ latitude, longitude, zoom: 14 });
            setAddress({ value, formattedValue, error, latitude, longitude });
          }}
        />
        <Box sx={{ width: '100%', height: '400px', borderRadius: '8px', overflow: 'hidden', mt: 2 }}>
          <Box sx={{ width: '100%', height: '100%', background: '#badbad'}}>
            <Map
              mapboxAccessToken={mapboxAccessToken}
              viewState={moving ? null : viewState}
              onMoveStart={handleMoveStart}
              onMoveEnd={handleMoveEnd}
              mapStyle="mapbox://styles/mapbox/streets-v9"
            >
              {!!address.latitude && <Marker
                color='#2418D3'
                latitude={address.latitude}
                longitude={address.longitude}
                draggable
                onDragEnd={({ lngLat }) => setAddress(prev => ({...prev, draggedMarker: `${lngLat.lng},${lngLat.lat}` }))}
              />}
              <NavigationControl />
            </Map>
          </Box>
        </Box> 
        <Box sx={{ width: '100%', display: 'flex', justifyContent: edit ? 'space-between' : 'center', pt: {xs: '32px', lg: '56px'} }}>
          { edit && <Button disabled={processing} variant='outlined' color='error' onClick={handleDelete}>DELETE LOCATION</Button> }
          <Button disabled={processing} variant='contained' color='primary' onClick={handleSubmitClick}>{edit ? 'UPDATE' : 'CREATE'} LOCATION</Button>
        </Box>
        </DialogContent>
      </BaseDialog>
  );
};

export default memo(Location);
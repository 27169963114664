import { memo } from 'react';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import _map from 'lodash/map';
import _slice from 'lodash/slice';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import { Chips, Content, Header, OrderItem, StyledListItemButton, Title } from './DriverListItem.styled';
import { ReactComponent as OfflineIcon } from 'assets/icons/offline.svg';
import BaseStatus from 'components/Common/BaseStatus/BaseStatus';
import Status from 'components/Orders/Status/Status';

const DriverListItem = ({ title, selected, to, status, service, disabled, lastOrders, onDuty, onClick = null, isNoSignal, isModal = false, onAssign = null, onChangeDutyStatus = null }) => {
  return (
    <ListItem disablePadding>
      <StyledListItemButton
        component={!!onClick ? 'button': NavLink}
        to={!!!onClick ? to : ''}
        selected={selected}
        disabled={disabled}
        onClick={onClick}
      >
        <Header>
          {isModal && <Radio checked={selected} />}
          <Title>{title}</Title>
          {!!onClick && isNoSignal && <Box sx={{ color: 'text.secondary' }}><OfflineIcon /></Box>}
          <Switch onChange={onChangeDutyStatus} checked={onDuty} sx={{ ml: 'auto', mr: '-12px' }} />

        </Header>
        <Content>
          <Chips>
            {!status && onDuty && <Status size='small' value='available' />}
            {status && <Status size='small' value={status} />}
            {status && service && <BaseStatus size='small' color='primary' label={service} />}
          </Chips>
          {_map(_slice(lastOrders, 0, 2), o =>
            <OrderItem key={o.id}>
              <Link color="inherit" underline="hover">#{o.number}</Link>&nbsp;&nbsp;‧&nbsp;&nbsp;ETA {o.eta} min&nbsp;&nbsp;‧&nbsp;&nbsp;{_startCase(o.class_type)}
            </OrderItem>
          )}
          {isModal && selected && <Button variant='contained' onClick={onAssign}>Assign Driver</Button>}
        </Content>
      </StyledListItemButton>
    </ListItem>
  );
};

DriverListItem.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  selected: PropTypes.bool,
  to: PropTypes.string,
};

export default memo(DriverListItem);
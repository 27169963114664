import { memo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/MoreVert';
import AddTabIcon from '@mui/icons-material/ControlPoint';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import _find from 'lodash/find';
//Local files
import { Root, StyledTab } from './DetailsHeader.styled';
import { variantTypes, reasons } from 'helpers/constants';
import { DELETE_VARIANT, DELETE_JOB } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useVariants from 'hooks/useVariants';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useDialogs from 'hooks/useDialogs';
import useCustomSelector from 'hooks/useCustomSelector';

const DetailsHeader = ({ job_id, variants }) => {
  const { setAccountServiceData } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createVariant } = useVariants();
  const { openConfirmationDialog } = useDialogs();
  const { activeVariant } = useCustomSelector(state => state.app.accountServiceData);
  const [ menuAnchor, setMenuAnchor ] = useState(null);
  const [ addTabMenuAnchor, setAddTabMenuAnchor ] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleDeleteVariant = () => {
    openConfirmationDialog({
      id: activeVariant?.id,
      content: 'Are you sure you want to delete this variant?',
      type: DELETE_VARIANT,
      reason: reasons.VARIANT
    });
    setAccountServiceData({ activeVariant: variants[0] });
    setMenuAnchor(null);
  };
  const handleDeleteJob = () => {
    openConfirmationDialog({
      id: job_id,
      content: 'Are you sure you want to delete this service?',
      type: DELETE_JOB,
      reason: reasons.JOB
    });
    setMenuAnchor(null);
  };
  const handleAddType = (variant_type) => {
    createVariant({ job_id, variant: { variant_type } })
    .then(() => setSuccess('Rate successfully created').then(() => setAddTabMenuAnchor(null)))
    .catch((error) => setError(error))
    .finally(() => setProcessing(false));
  };
  const handleChangeTab = (_, newValue) => setAccountServiceData({ activeVariant: _find(variants, ({ variant_type }) => variant_type === newValue) });

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ sx: { mt: '2px', width: '220px' } }}
        keepMounted
      >
        { activeVariant?.variant_type !== 'default' && <MenuItem color='primary' disabled={processing} onClick={handleDeleteVariant}>
          Delete {_startCase(activeVariant?.variant_type)}
        </MenuItem> }
        <MenuItem color='primary' disabled={processing} onClick={handleDeleteJob}>Delete service</MenuItem>
      </Menu>
      <Menu
        anchorEl={addTabMenuAnchor}
        open={!!addTabMenuAnchor}
        onClose={() => setAddTabMenuAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{ sx: { mt: '2px', width: '180px' } }}
        keepMounted
      >
        {_map(variantTypes, ({ value, label }) => (
          <MenuItem key={value} color='primary' disabled={processing} value={value} onClick={() => handleAddType(value)}>{label}</MenuItem>
        ))}
      </Menu>
      <Root>
        <Tabs
          variant='scrollable'
          value={activeVariant.variant_type}
          onChange={handleChangeTab}
        >
          {_map(variants, (variant) => <StyledTab key={variant?.id} value={variant.variant_type} disabled={processing} label={_startCase(variant?.variant_type)} />)}
        </Tabs>
        <IconButton color='primary' size='small' sx={{flexShrink: 0}} aria-label='add tab' disabled={processing} onClick={({ currentTarget }) => setAddTabMenuAnchor(currentTarget)}><AddTabIcon /></IconButton>
        <div style={{ flexGrow: 1 }} />
        <IconButton color='primary' size='small' sx={{flexShrink: 0}} aria-label='menu' disabled={processing} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}><MenuIcon /></IconButton>
      </Root>
    </>
  );
};

export default memo(DetailsHeader);
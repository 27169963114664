import { memo } from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Title = styled((props) => (
  <Typography variant='body2' {...props} />
))(() => ({
  flexShrink: 0,
  flexBasis: '20%'
}));


const BaseItem = ({ title, content, location = null }) => {
  return (
   <Box sx={{ display: 'flex', margin: '8px 0' }}>
     <Title variant='body2'>{title}</Title>
     <Typography variant='body2' color='textSecondary' sx={{ flexBasis: !!location ? '30%' : '80%', ml: 1 }}>{content ?? '-'}</Typography>
     { !!location && <Typography variant='body2' color='textSecondary' noWrap sx={{ flexBasis: '50%' }}>{location ?? '-'}</Typography> }
   </Box>
  );
};

export default memo(BaseItem);
import { memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';

const BaseDrawer = ({ open, edit, onClose, disabled, headContent, onDelete, onSave, title, content, grey = false, paperSxProps, ...otherProps }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const removing = useCustomSelector(state => state.app.removing);

  if (grey) {
    return (
      <Drawer
        anchor={!mediaDesktop ? 'bottom' : 'right'}
        open={open}
        onClose={onClose}
        ModalProps={{ sx: { zIndex: 1300 } }}
        PaperProps={{ sx: { height: '100%', width: { xs: '100%', md: '600px'}, background: '#FBFBFB', ...paperSxProps } }}
        {...otherProps}
      >
        <Box sx={{ minHeight: '100px', width :'100%', position: 'sticky', top: '0', background: '#FBFBFB', zIndex: 2, p: 1 }}>
          <IconButton aria-label='close' onClick={onClose}><CloseIcon /></IconButton>
          <Typography variant='title' sx={{ textAlign: 'center', width: '100%'}}>{title}</Typography>
        </Box>
        <Box sx={{ padding: {xs: '32px 16px 32px', md: '24px 70px 60px'} }}>
          {content}
        </Box>
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor={!mediaDesktop ? 'bottom' : 'right'}
      open={open}
      onClose={onClose}
      ModalProps={{ sx: { zIndex: 1300 } }}
      PaperProps={{
        sx: {
          height: '100%',
          width: { xs: '100%', md: '600px'}
        }
      }}
      {...otherProps}
    >
      <Box display='flex' alignItems='center' gap={1} p={{ xs: '4px 16px', md: '12px 16px' }} sx={{ minHeight: '64px', position: 'sticky', top: '0', bgcolor: '#fff', zIndex: 100 }}>
        <Box flexGrow={1}>{headContent}</Box>
        {!!onDelete && edit && <Button color='error' size='large' disabled={disabled || removing} onClick={onDelete}>Delete</Button>}
        {!!onSave && (<Button color='primary' size='large' disabled={disabled} variant='contained' onClick={onSave}>{edit ? 'Save' : 'Create'}</Button>)}
        <IconButton edge='end' aria-label='close' onClick={onClose}><CloseIcon /></IconButton>
      </Box>
      <Box p='8px 16px 32px'>
        {content}
      </Box>
    </Drawer>
  );
};

BaseDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  headContent: PropTypes.node,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  content: PropTypes.node.isRequired,
  onExiting: PropTypes.func
};

export default memo(BaseDrawer);
import { memo, useEffect, useCallback, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import Check from '@mui/icons-material/Check';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';
// Local files
import { Root } from './Filters.styled';
import { StyledSelect, StyledMenuItem } from 'components/Dashboard/Common/StyledSelect.styled';
import { dateFilter, invoiceStatuses } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useAccounts from 'hooks/useAccounts';

const Filters = ({ processing, filterDate, status, account, onFilterDate, onStatus, onAccount }) => {
  const { getListAccounts, clearLocalListAccounts } = useAccounts();
  const { setError } = useError();
  const { data } = useCustomSelector(state => state.accounts.list);
  const [loading, setLoading] = useState(false);

  const fetchAccounts = useCallback(offset => {
    setLoading(true);
    getListAccounts({ offset })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListAccounts, setError]);

  useEffect(() => {
    fetchAccounts(0);

    return clearLocalListAccounts;
  }, [fetchAccounts, clearLocalListAccounts]);

  return (
    <Root>
      <StyledSelect 
        variant='standard' 
        value={filterDate} 
        onChange={({ target: { value } }) => onFilterDate(value)}
        disabled={processing || loading}
        IconComponent={DownIcon}
        renderValue={(selected) => selected.label}
        sx={{ color: '#000000', backgroundColor: '#EBEBEB', borderRadius: 20, pl: 2, pt: '4px', pb: '4px' }}
      >
        { _map(dateFilter, (item) => <StyledMenuItem key={item.label} value={item}>
          { filterDate?.label === item.label && <ListItemIcon>
            <Check color='primary' sx={{ width: 18, height: 'auto' }} />
          </ListItemIcon> }
          {item.label}
        </StyledMenuItem> )}
      </StyledSelect>
      <StyledSelect 
        variant='standard' 
        value={status} 
        onChange={({ target: { value } }) => onStatus(value)}
        disabled={processing || loading}
        IconComponent={DownIcon}
        renderValue={(selected) => _startCase(selected)}
        sx={{ color: '#000000', backgroundColor: '#EBEBEB', borderRadius: 20, pl: 2, pt: '4px', pb: '4px' }}
      >
        { _map(['all_statuses', ..._filter(invoiceStatuses, (s) => s !== invoiceStatuses.NEW)], (item) => <StyledMenuItem key={item} value={item}>
          { status === item && <ListItemIcon>
            <Check color='primary' sx={{ width: 18, height: 'auto' }} />
          </ListItemIcon> }
          {_startCase(item)}
        </StyledMenuItem> )}
      </StyledSelect>
      <StyledSelect 
        variant='standard' 
        value={account.formattedValue} 
        onChange={({ target: { value: formattedValue } }) => onAccount({ value: formattedValue.id, formattedValue })}
        disabled={processing || loading}
        IconComponent={DownIcon}
        renderValue={(selected) => selected.id === 'all_accounts' ? 'All Accounts' : `${selected.name} - ${selected.company?.name}`}
        sx={{ color: '#000000', backgroundColor: '#EBEBEB', borderRadius: 20, pl: 2, pt: '4px', pb: '4px' }}
      >
        { _map([...[{ id: 'all_accounts', name: 'All Accounts'}], ...data], (item) => <StyledMenuItem key={item?.id} value={item}>
          { account.value === item.id && <ListItemIcon>
            <Check color='primary' sx={{ width: 18, height: 'auto' }} />
          </ListItemIcon> }
          {item.id === 'all_accounts' ? item.name : `${item.name} - ${item.company?.name}`}
        </StyledMenuItem> )}
      </StyledSelect>
    </Root>
  );
};

export default memo(Filters);
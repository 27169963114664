import { memo, useEffect, useState } from 'react';
import { sessionService } from 'redux-react-session';
import { Outlet, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import _includes from 'lodash/includes';
// Components
import AppBar from 'components/AppBar/AppBar';
import PageLoader from 'components/Common/PageLoader/PageLoader';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
// Controllers
import CableController from 'components/Controllers/Cable/Cable';
import ConfirmationController from 'components/Controllers/Confirmation/Confirmation';
// Dialogs
import Assign from 'components/Dialogs/Assign/Assign';
import CancelOrder from 'components/Dialogs/CancelOrder/CancelOrder';
import Confirmation from 'components/Dialogs/Confirmation/Confirmation';
import FilePreview from 'components/Dialogs/FilePreview/FilePreview';
import DocumentsDialog from 'components/Dialogs/Documents/Documents';
import Invoice from 'components/Dialogs/Invoice/Invoice';
import MapDialog from 'components/Dialogs/Map/Map';
import RejectOrder from 'components/Dialogs/RejectOrder/RejectOrder';
import Signature from 'components/Dialogs/Signature/Signature';
import TowVehicle from 'components/Dialogs/TowVehicle/TowVehicle';
import TakeOutVehicle from 'components/Dialogs/TakeOutVehicle/TakeOutVehicle';
// Helpers
import { models, sides } from 'helpers/constants';
// Hooks
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useRole from 'hooks/useRole';
import useSession from 'hooks/useSession';
import useUrl from 'hooks/useUrl';
import useProfile from 'hooks/useProfile';
import useOrders from 'hooks/useOrders';
import useSessions from 'hooks/useSessions';
import useAdds from 'hooks/useAdds';

const { ORDERS, ACCOUNTS, TRUCKS, VEHICLES } = models;

const Layout = () => {
  const navigate = useNavigate();
  const { id: paramsId, customer_token, company_id, order_id: orderId } = useParams();
  const { isAccountsRoute, isDriversRoute, isManagersRoute, isOrdersRoute, isInvoicesRoute, isTrucksRoute, isVehiclesRoute } = useUrl();
  const { setError } = useError();
  const { getProfile } = useProfile();
  const { getOrder } = useOrders();
  const { createAdd } = useAdds();
  const { deleteActiveSession, clearStore } = useSessions();
  const profileFetched = useCustomSelector(state => !!state.profile.user.id);
  const { id: order_id } = useCustomSelector(state => state.invoices.invoice.order);
  const order = useCustomSelector(state => state.vehicles.vehicle.order);
  const { authenticated, checked } = useSession();
  const { pathname, search } = useLocation();
  const { isManager, isOwner } = useRole();
  const [token, setToken] = useState(null);
  const redirectUrl = (isManager || isOwner) ? '/dashboard' : '/orders';
  const model = (isOrdersRoute || isInvoicesRoute || isVehiclesRoute) ? ORDERS : (isDriversRoute || isManagersRoute) ? 'users' : isAccountsRoute ? ACCOUNTS : isTrucksRoute ? TRUCKS : isVehiclesRoute ? VEHICLES : null;
  const id = isInvoicesRoute ? order_id : (isVehiclesRoute ? order.id : paramsId);
  const isCustomer = !!customer_token || !!company_id;

  useEffect(() => {
    const checkSession = () => sessionService.loadSession().then(({ token: t }) => setToken(t));

    if (authenticated && checked && !profileFetched) {
      if (!!!customer_token && !!!company_id) {
        getProfile()
        .then(() => {
          !token && checkSession();
          localStorage.removeItem('customer_token');
        })
        .catch(error => setError(error));
      } else {
        deleteActiveSession()
        .then(() => clearStore())
        .catch(error => setError(error));
      }
    }
  }, [authenticated, checked, token, customer_token, profileFetched, company_id, getProfile, setError, deleteActiveSession, clearStore]);
  useEffect(() => {
    if (checked && !authenticated && !!customer_token) {
      getOrder({ id: orderId, side: sides.CUSTOMER, customer_token })
      .then(() => { 
        localStorage.setItem('customer_token', customer_token);
        setToken(customer_token);
      })
      .catch(error => setError(error).then(() => {
        localStorage.removeItem('customer_token');
        navigate('/');
      }))
    }
  }, [orderId, getOrder, checked, authenticated, customer_token]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    return () => {
      !!token && setToken(null);
    }
  }, [authenticated, checked, token]);
  useEffect(() => {
    if (checked && authenticated && _includes(pathname, 'add/redirect')) {
      const urlParams = new URLSearchParams(search);
      const code = urlParams.get('code');
      const state = new URLSearchParams(urlParams.get('state'));
      const company_id = state.get('company_id');
      const order_id = state.get('order_id');

      createAdd({ company_id, code })
      .then(({ payload: { status } }) => status === 204 ? navigate(`/orders/${order_id}`) : setError('Error with connection to ADD').then(() => navigate(`/`)))
      .catch((error) => setError(error))
    }
  }, [authenticated, checked, pathname, search, createAdd, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  if (checked) {
    if (authenticated && !profileFetched) return <PageLoader />;
    if (pathname === '/' || (pathname === '/dashboard' && !isOwner && !isManager)) return <Navigate to={redirectUrl} replace />;
    return (
      <>
        { authenticated && !isCustomer && <>
        <ComponentErrorWrapper children={<AppBar />} />
        <ComponentErrorWrapper children={<Assign />} />
        <ComponentErrorWrapper children={<CancelOrder />} />
        <ComponentErrorWrapper children={<Confirmation />} />
        { !!model && <ComponentErrorWrapper children={<DocumentsDialog id={id} model={model} /> } /> }
        <ComponentErrorWrapper children={<FilePreview />} />
        <ComponentErrorWrapper children={<Invoice />} />
        <ComponentErrorWrapper children={<MapDialog />} />
        <ComponentErrorWrapper children={<RejectOrder />} />
        <ComponentErrorWrapper children={<Signature />} />
        <ComponentErrorWrapper children={<TowVehicle />} />
        <ComponentErrorWrapper children={<TakeOutVehicle />} />
        <ComponentErrorWrapper children={<ConfirmationController />} />
        </> }
        {token && <CableController token={token} />}
        <Outlet />
      </>
    );
  }
  return <PageLoader />;
};

export default memo(Layout);
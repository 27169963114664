import { memo, useEffect, useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import _omit from 'lodash/omit';
import _includes from 'lodash/includes';
import _pick from 'lodash/pick';
// Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import BaseFilter from 'components/Common/BaseFilter/BaseFilter';
import UsersAutocomplete from 'components/Users/Autocomplete/Autocomplete';
import BaseListPage from 'components/Common/BaseListPage/BaseListPage';
import List from 'components/Orders/List/List';
import Details from 'components/Orders/Details/Details';
import MainDrawer from 'components/Orders/Drawers/Main/Main';
import Filter from 'components/Common/Filter/Filter';
import Stats from 'components/Orders/Stats/Stats';
import useApp from 'hooks/useApp';
import useOrders from 'hooks/useOrders';
import useCustomSelector from 'hooks/useCustomSelector';
import useRole from 'hooks/useRole';
import useError from 'hooks/useError';
import useProfile from 'hooks/useProfile';
import { orderStatuses, reasons } from 'helpers/constants';

const Orders = () => {
  const { openDrawer, setFilter } = useApp();
  const { getOrdersStatistics, clearLocalActiveOrder, clearOrdersStatistics } = useOrders();
  const { updateProfile } = useProfile();
  const { setError } = useError();
  const navigate = useNavigate();
  const { isManager, isOwner } = useRole();
  const { open } = useCustomSelector(state => state.app.filter);
  const filteredStatuses = useCustomSelector(state => state.profile.user.filtered_order_statuses);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [query, setQuery] = useState('');
  const [statuses, setStatuses] = useState(filteredStatuses);
  const [userId, setUserId] = useState({ value: '', formattedValue: null });
  const [fetching, setFetching] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleQueryChange = (value) => setQuery(value);
  const handleFilterChange = () => { 
    setFilter(!open);
    setUserId({ value: '', formattedValue: null });
  };
  const handleAddClick = () => { 
    navigate('/orders'); 
    clearLocalActiveOrder().then(() => openDrawer({ reason: reasons.ORDER, edit: false }));
  };
  const handleChangeStatuses = (value) => {
    setProcessing(true);
    updateProfile({ 
      filtered_order_statuses:  _includes(value, 'all_orders') ? 
      Object.values(_omit(orderStatuses, ['STORED', 'LAID', 'FOR_SALE', 'RELEASED'])) : _includes(value, 'active_only') ? 
      Object.values(_pick(orderStatuses, ['NEW', 'MANAGER_ACCEPTED', 'COMPANY_ACCEPTED', 'EN_ROUTE', 'PICK_UP', 'SERVICING', 'DROP_OFF'])) : value
    })
    .then(() => setStatuses(value))
    .catch(error => setError(error))
    .finally(() => setProcessing(false));
  };
  const fetchStatistics = useCallback(() => {
    setProcessing(true);
    getOrdersStatistics({})
    .catch((error) => setError(error))
    .finally(() => setProcessing(false))
  }, [getOrdersStatistics, setError]);

  useEffect(() => {
    (isManager || isOwner) && mediaDesktop && fetchStatistics();

    return () => {
      clearLocalActiveOrder();
      clearOrdersStatistics()
    };
  }, [isManager, isOwner, mediaDesktop, fetchStatistics, clearOrdersStatistics, clearLocalActiveOrder]);

  return (
    <>
      { (isManager || isOwner) && mediaDesktop &&  <ComponentErrorWrapper children={<Stats onStatuses={setStatuses} />} /> }
      <BaseListPage
        renderFilter={
          <Filter
            query={query}
            onQueryChange={handleQueryChange}
            onAddClick={(isManager || isOwner) && handleAddClick}
            onFilterClick={handleFilterChange}
            statusesCount={filteredStatuses.length} 
            filterContent={
              <>
                <BaseFilter 
                  label='Statuses'
                  value={filteredStatuses} 
                  onChange={handleChangeStatuses} 
                  data={{ ALL_ORDERS: 'all_orders', ACTIVE_ONLY: 'active_only', ..._omit(orderStatuses, ['STORED', 'LAID', 'FOR_SALE', 'RELEASED']) }} 
                />
                <UsersAutocomplete
                  id='driver'
                  role='driver'
                  margin='dense'
                  fullWidth
                  label='Driver'
                  value={userId.formattedValue}
                  onChange={({ value, formattedValue }) => setUserId({ value, formattedValue })}
                />
              </>
            }
          />
        }
        listLoading={fetching || processing}
        listComponent={
          <ComponentErrorWrapper 
            children={
              <List
                query={query}
                statuses={statuses}
                user_id={userId.value}
                fetching={fetching}
                onFetching={v => setFetching(v)}
              />
            } 
          />
        }
        renderMainAtDesktop={<ComponentErrorWrapper children={<Details />} />}
      />
      <ComponentErrorWrapper children={<MainDrawer />} />
      <Outlet />
    </>
  );
};

export default memo(Orders);

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getRequests as getRequestsAction,
  getRequest as getRequestAction,
  createRequest as createRequestAction,
  answerRequest as answerRequestAction,
  clearLocalRequests as clearLocalRequestsAction
} from 'actions/requests';

const useRequests = () => {
  const dispatch = useDispatch();
  const getRequests = useCallback(({ offset, order_id }) => dispatch(getRequestsAction({ offset, order_id })), [dispatch]);
  const getRequest = useCallback(id => dispatch(getRequestAction(id)), [dispatch]);
  const createRequest = useCallback(({ request, order_id }) => dispatch(createRequestAction({ request, order_id })), [dispatch]);
  const answerRequest = useCallback(({ request_id, response }) => dispatch(answerRequestAction({ request_id, response })), [dispatch]);
  const clearLocalRequests = useCallback(() => dispatch(clearLocalRequestsAction()), [dispatch]);

  return { getRequests, getRequest, createRequest, answerRequest, clearLocalRequests };
};

export default useRequests;
import {
  getCompaniesRoutine,
  updateCompanyRoutine,
  clearLocalCompaniesRoutine
} from 'actions';
import _map from 'lodash/map';

export const initialState = {
  data: [],
  pagination: { limit: 0, offset: 0, count: 0, total_count: 0 }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getCompaniesRoutine.SUCCESS: {
      const { data: { companies, pagination } } = action.payload;

      return { ...state, data: [...state.data, ...companies], pagination };
    }
    case updateCompanyRoutine.SUCCESS: {
      const { response: { status }, company } = action.payload;
      const data = _map(state.data, (item) => item.id === company.id ? { ...item, ...company } : item);

      return { ...state, ...(status === 204 && { data }) };
    }
    case clearLocalCompaniesRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
}

export default reducer;
import { memo, useState } from 'react';
import Tabs from '@mui/material/Tabs';
//Local files
import { Card } from 'components/Common/BasePageUi/BasePageUi';
import { AddButton, Container, DetailsContainer, DetailsPlaceholder, ListContainer, ListHeader, StyledTab } from './Services.styled';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import List from './List/List';
import DetailsHeader from './DetailsHeader/DetailsHeader';
import Details from './Details/Details';
import ServicesList from './ServicesList/ServicesList';
import useCustomSelector from 'hooks/useCustomSelector';
import useJobs from 'hooks/useJobs';
import useApp from 'hooks/useApp';

const ServicesCard = () => {
  const { setAccountServiceData } = useApp();
  const { clearLocalJobs, clearLocalActiveJob } = useJobs();
  const [listTab, setListTab] = useState('basic');
  const { id: job_id, variants } = useCustomSelector(state => state.jobs.job);
  const { activeVariant } = useCustomSelector(state => state.app.accountServiceData);
  const [servicesListAnchor, setServicesListAnchor] = useState(null);


  const handleChangeTab = (_, newValue) => {
    setListTab(newValue);
    setAccountServiceData({});
    clearLocalJobs();
    clearLocalActiveJob();
  };
  const handleAddService = ({ currentTarget }) => {
    setServicesListAnchor(currentTarget);
    setAccountServiceData({});
    clearLocalActiveJob();
  };

  return (
    <Card sx={{ p: 0 }}>
      <ComponentErrorWrapper children={<ServicesList serviceType={listTab} anchorEl={servicesListAnchor} onClose={() => setServicesListAnchor(null)} />} />
      <Container>
        <ListContainer>
          <ListHeader>
            <Tabs
              variant='fullWidth'
              value={listTab}
              onChange={handleChangeTab}
            >
              <StyledTab value='basic' label='Services' />
              <StyledTab value='additional' label='Additionals' />
            </Tabs>
            <AddButton onClick={handleAddService}>Add service</AddButton>
          </ListHeader>
          <List jobType={listTab} />
        </ListContainer>
        <DetailsContainer>
          {!!job_id && !!activeVariant ? 
            <>
              <ComponentErrorWrapper children={<DetailsHeader job_id={job_id} variants={variants} />} />
              <ComponentErrorWrapper children={<Details />} />
            </> : <DetailsPlaceholder>Please select a service</DetailsPlaceholder>
          }
        </DetailsContainer>
      </Container>
    </Card>
  );
};

export default memo(ServicesCard);
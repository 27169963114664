export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_TRUCK = 'DELETE_TRUCK';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_RATE = 'DELETE_RATE';
export const DELETE_VARIANT = 'DELETE_VARIANT';
export const DELETE_JOB = 'DELETE_JOB';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const SUPPLEMENT_ORDER = 'SUPPLEMENT_ORDER';
export const START_ORDER_MOVEMENT = 'START_ORDER_MOVEMENT';
export const PICK_UP_ORDER = 'PICK_UP_ORDER';
export const SERVICE_ORDER = 'SERVICE_ORDER';
export const DPOP_OFF_ORDER = 'DPOP_OFF_ORDER';
export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const RENEW_ORDER = 'RENEW_ORDER';
export const REVERSE_STATUS = 'REVERSE_STATUS';
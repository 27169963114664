import { memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
// Local files
import StepLayout from '../StepLayout/StepLayout';
import { recoverySteps } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useUsers from 'hooks/useUsers';
import useSessions from 'hooks/useSessions';

const { VERIFICATION } = recoverySteps;

const ResetStep = ({ changeStep }) => {
  const navigate = useNavigate();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { changeUserPassword } = useUsers();
  const { clearLocalRecoveryData } = useSessions();
  const { recoveryEmail, confirmationCode } = useCustomSelector(state => state.sessions);
  const [password, setPassword] = useState({ value: '', error: '' });
  const [repeatPassword, setRepeatPassword] = useState({ value: '', error: '' });
  const [showPasswordSymbols, setShowPasswordSymbols] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangePasswordClick = () => {
    let hasError = false;

    if (password.value.length < 6) {
      setPassword(prev => ({ ...prev, error: 'Password is too short' }));
      hasError = true;
    }
    if (repeatPassword.value.length < 6) {
      setRepeatPassword(prev => ({ ...prev, error: 'Password is too short' }));
      hasError = true;
    }
    if (!!password.value && !!repeatPassword.value && password.value !== repeatPassword.value) {
      setPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      setRepeatPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      hasError = true;
    }
    if (hasError) return;

    setLoading(true);
    changeUserPassword({ email: recoveryEmail, password: password.value, confirmation_code: confirmationCode })
    .then(() => clearLocalRecoveryData().then(() => navigate('/sign_in')).then(() => setSuccess('Password successfully changed')))
    .catch((error) => setError(error))
    .finally(() => setLoading(false));
  }

  const handleKeyDown = ({ key }) => key === 'Enter' && handleChangePasswordClick();

  return (
    <StepLayout
      backStep={() => changeStep(VERIFICATION)}
      title='New Password'
      description='Please enter and confirm new password'
      content={
        <>
          <TextField
            fullWidth
            placeholder='Password'
            type={showPasswordSymbols ? 'text' : 'password'}
            value={password.value}
            onChange={({ target: { value } }) => setPassword({ value, error: '' })}
            error={!!password.error}
            helperText={password.error}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="show password"
                  color='primary'
                  disableRipple
                  onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                >
                  {showPasswordSymbols ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                </IconButton>
              )
            }}
          />
          <TextField
            fullWidth
            margin='normal'
            placeholder='Re-enter password'
            type={showPasswordSymbols ? 'text' : 'password'}
            value={repeatPassword.value}
            onChange={({ target: { value } }) => setRepeatPassword({ value, error: '' })}
            error={!!repeatPassword.error}
            helperText={repeatPassword.error}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="show password"
                  color='primary'
                  disableRipple
                  onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                >
                  {showPasswordSymbols ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                </IconButton>
              )
            }}
          />
        </>
      }
      actions={
        <Container maxWidth='xs' sx={{pt: 2}}>
          <Button variant='contained' color='primary' fullWidth size='xlarge' disabled={loading} onClick={handleChangePasswordClick}>Confirm</Button>
        </Container>
      }
    />
  );
};

export default memo(ResetStep);
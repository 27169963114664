import { memo, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import AddIcon from '@mui/icons-material/Add';
import _map from 'lodash/map';
//Local files
import Form from '../Form/Form';
import { rateValueType } from 'helpers/constants';
import { getRandomString } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useChargeTypes from 'hooks/useChargeTypes';
import useApp from 'hooks/useApp';

const RatesTable = ({ type, rates }) => {
  const { setAccountServiceData } = useApp();
  const { isBasic } = useChargeTypes(type);
  const { activeVariant, activeRate, newRate } = useCustomSelector(state => state.app.accountServiceData);
  const [newR, setNewR] = useState(false);

  const handleNewRate = () => { 
    setNewR(true);
    setAccountServiceData({ activeVariant, newRate: true }) 
  };
  const handleActiveRate = (rate) => setAccountServiceData({ activeVariant, activeRate: rate });
  const handleCancel = (rate = null) => { 
    setNewR(false);
    setAccountServiceData({ activeVariant: { ...activeVariant, rates: _map(activeVariant?.rates, (item) => item.id === rate?.id ? { ...item, ...rate } : item) } });
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {_map(rates, (rate) => {
              if (activeRate?.id === rate?.id) return <Form key={rate?.id} activeRate={activeRate} chargeType={type} onCancel={handleCancel} />
              return (
                <TableRow key={rate?.id} disabled={!!activeRate || newRate} onClick={() => !newRate && !!!activeRate && handleActiveRate(rate)}>
                  <TableCell variant='tiny' align='left' sx={{ width: '80%' }}>{rate?.charge?.name}</TableCell>
                  <TableCell variant='tiny' align='left' sx={{ width: '7%' }}>{rate?.fee_value_type !== 'percent' && '$'}{rate?.value}{rate?.fee_value_type === 'percent' && '%'}</TableCell>
                  <TableCell variant='tiny' align='left' sx={{ width: '13%' }}>
                    {rateValueType[isBasic ? rate?.basic_value_type : rate?.fee_value_type]}
                  </TableCell>
                </TableRow>
              )
            })}
            {newR && newRate && <Form key={getRandomString(24)} chargeType={type} onCancel={handleCancel} />}
          </TableBody>
        </Table>
      </TableContainer>
      <Button color='primary' startIcon={<AddIcon />} disabled={!!activeRate || newRate} onClick={handleNewRate}>Add Charge</Button>
    </>
  );
};

export default memo(RatesTable);
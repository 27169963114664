import { memo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
// Local files
import { Information, Root, Stats } from './Head.styled';
import { StyledSelect, StyledIcon, StyledMenuItem } from 'components/Dashboard/Common/StyledSelect.styled';
import StatsItem from './StatsItem/StatsItem';
import useCustomSelector from 'hooks/useCustomSelector';
import useInvoices from 'hooks/useInvoices';
import useError from 'hooks/useError';
import { dateFilter } from 'helpers/constants';

const Head = () => {
  const { getInvoicesStatistics } = useInvoices();
  const { setError } = useError();
  const { first_name } = useCustomSelector(state => state.profile.user);
  const {
    total_invoices_count,
    total_invoices_amount
  } = useCustomSelector(state => state.invoices.statistics);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [filterDate, setFilterDate] = useState(dateFilter[dateFilter.length - 1]);

  useEffect(() => {
    getInvoicesStatistics({ filterDate, reason: 'invoice_overview' }).catch((error) => setError(error));
  }, [filterDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Root>
      <Information>
        <Typography variant={mediaDesktop ? 'body1' : 'body2'}>👋 Hello {first_name},</Typography>
        <Typography variant={mediaDesktop ? 'h4' : 'body1'} sx={{ mt: 1 }}>Here is what's happening at Roadsider</Typography>
        <Typography variant={mediaDesktop ? 'body1' : 'body2'} color='#C4C4C4' sx={{ mt: 1 }}>You have earned 40% more this month and accepted 20% more cal |</Typography>
      </Information>
      <Stats>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <StatsItem value={total_invoices_count} title='Total Invoices' />
            <StatsItem value={`$${total_invoices_amount}`} title='Total $ Earned' />
        </Box>
        <StyledSelect 
        variant='standard' 
        value={filterDate} 
        onChange={({ target: { value } }) => setFilterDate(value)}
        IconComponent={StyledIcon}
        renderValue={(selected) => selected.label}
      >
        { _map(dateFilter, (item) => <StyledMenuItem key={item.label} value={item}>
          { filterDate?.label === item.label && <ListItemIcon>
            <Check color='primary' sx={{ width: 18, height: 'auto' }} />
          </ListItemIcon> }
          {item.label}
        </StyledMenuItem> )}
      </StyledSelect>
      </Stats>
    </Root>
  );
};

export default memo(Head);
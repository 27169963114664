import {
  getPaymentsRoutine,
  clearLocalPaymentsRoutine
} from 'actions';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getPaymentsRoutine.SUCCESS: {
      const { data: { payments, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...payments], pagination } };
    }
    case clearLocalPaymentsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
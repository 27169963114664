import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useUrl from 'hooks/useUrl';

const Placeholder = ({ model }) => {
  const { isOrdersRoute, isInvoicesRoute, isAccountsRoute } = useUrl();
  const isShowAn = isOrdersRoute || isInvoicesRoute || isAccountsRoute;

  return (
    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant='h4'>{ `Please select ${isShowAn ? 'an' : 'a'} ${model.slice(0, model.length - 1)}`}</Typography>
    </Box>
  );
};

export default memo(Placeholder);
import _find from 'lodash/find';
// local files
import useCustomSelector from './useCustomSelector';

const useTimezones = (company_id) => {
  const companies = useCustomSelector(state => state.profile.user.companies);

  return { timeZone: _find(companies, (c) => c.id === company_id)?.time_zone || 'US/Pacific' };
};

export default useTimezones;
import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
//Local files
import { Card, CardHeader, CardTitle, ItemsContainer, Item, ItemLabel, ItemText, StyledLink, MoreLink } from 'components/Common/BasePageUi/BasePageUi';
import { getFormattedPhone } from 'helpers';
import { orderStatuses } from 'helpers/constants';
import useUrl from 'hooks/useUrl';
import useDialogs from 'hooks/useDialogs';
import useRole from 'hooks/useRole';
import useMap from 'hooks/useMap';
import useOrderSources from 'hooks/useOrderSources';

const DriverCard = ({ id, user, source, dispatcher, truck, incident_location, status, eta, trucks, users, sx, onShowDriver = null, showDriverCall = false }) => {
  const { isOrdersRoute } = useUrl();
  const { isManager, isOwner } = useRole();
  const { openMapDialog } = useDialogs();
  const { setMapConfigs } = useMap();
  const { isISSC, isUrgently } = useOrderSources(source);
  const [changeDriver, setChangeDriver] = useState(false);
  const isOrderDetails = isOrdersRoute && status !== orderStatuses.CANCELED
    && status !== orderStatuses.COMPLETED
    && status !== orderStatuses.REJECTED
    && status !== orderStatuses.EXPIRED
    && status !== orderStatuses.STORED;
  const showChangeDriver = (isManager || isOwner) && !!user && isOrderDetails && !isUrgently;
  const showDriverLink = (isManager || isOwner) && !!user && !showChangeDriver && !isUrgently;
  const showDriverFields = (!!user && !changeDriver) || !isOrdersRoute;

  const handleMapDialog = () => 
  setMapConfigs({ localIncident: { value: incident_location.address, latitude: incident_location.latitude, longitude: incident_location.longitude } })
  .then(() => openMapDialog({ order: { id, eta, status, trucks, users }, isISSC, isUrgently }).then(() => setChangeDriver(false)));

  return (
    <Card sx={sx}>
      <CardHeader>
        <CardTitle>Driver</CardTitle>
        { showChangeDriver && <Button onClick={() => setChangeDriver(!changeDriver)}>{changeDriver ? 'Close' : 'CHANGE DRIVER'}</Button>}
        { showDriverLink && <MoreLink to={`/company/drivers/${user?.id}`} />}
        { showDriverCall && !!user?.phone && <Button component='a' href={`tel:${user?.phone}`}>CALL DRIVER</Button> }
      </CardHeader>
      { showDriverFields ? <ItemsContainer sx={{ justifyContent: 'center' }}>
        <Item halfWidth>
          <ItemLabel>Name</ItemLabel>
          <ItemText>{user?.first_name} {user?.last_name}</ItemText>
        </Item>
        { !showDriverCall && <Item halfWidth>
          <ItemLabel>Truck</ItemLabel>
          <ItemText>{truck?.make}-{truck?.model}</ItemText>
        </Item> }
        <Item halfWidth>
          <ItemLabel>Phone #</ItemLabel>
          <ItemText><StyledLink href={`tel:${user?.phone}`}>{getFormattedPhone(user?.phone) ?? '-'}</StyledLink></ItemText>
        </Item>
        { !showDriverCall && <Item halfWidth>
          <ItemLabel>Dispatcher</ItemLabel>
          <ItemText>{dispatcher?.first_name} {dispatcher?.last_name}</ItemText>
        </Item> }
        { isOrderDetails && <Button onClick={() => onShowDriver(true)}>Show on map</Button> }
      </ItemsContainer> :
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <TextField 
          id='driver'
          fullWidth
          select
          margin='dense'
          label='Select a driver'
          defaultValue=''
          SelectProps={{ open: false, onOpen: handleMapDialog }}
        >
          {`${user?.first_name} ${user?.last_name}`}
        </TextField>
      </Box> }
    </Card>
  );
};

export default memo(DriverCard);
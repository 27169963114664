import { memo } from 'react';
// Local files
import BaseModalDetails from 'components/Common/BaseModalDetails/BaseModalDetails';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import Details from 'components/Users/Details/Details';

const ManagerDetails = () => {

  return (
    <BaseModalDetails>
      <ComponentErrorWrapper children={<Details />} />
    </BaseModalDetails>
  );
};

export default memo(ManagerDetails);
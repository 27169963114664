import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  createExpenseRoutine,
  updateExpenseRoutine,
  deleteExpenseRoutine,
  suggestInvoiceExpensesRoutine
} from 'actions';

const attributes = [
  'id',
  'name',
  'unit_price',
  'hourly_charge_duration',
  'quantity',
  { rate: ['id', { charge: ['id', 'name', 'charge_type'] }, 'basic_value_type', 'fee_value_type', 'value'] },
  { default_charge: ['id', { charge: ['name', 'charge_type'] }, 'basic_rate_type'] }
];

export const createExpense = getThunkActionCreator(
  createExpenseRoutine,
  async ({ expense, invoice_id  }) => {
    const { token: access_token } = await sessionService.loadSession();

    const response = await axios.post(`/user/invoices/${invoice_id}/expenses`, { access_token, expense, attributes });

    return { response, invoice_id };
  }
);
export const updateExpense = getThunkActionCreator(
  updateExpenseRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/expenses/${id}`, { access_token, expense: fields });

    return { response, expense: { id, ...fields } };
  }
);
export const deleteExpense = getThunkActionCreator(
  deleteExpenseRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/expenses/${id}`, { params: { access_token } });

    return { response, id };
  }
);
export const suggestInvoiceExpenses = getThunkActionCreator(
  suggestInvoiceExpensesRoutine,
  async ({ invoice_id, service_id, expense_name }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.post(`/user/invoices/${invoice_id}/expenses/suggest`, { access_token, service_id, attributes });
    
    return { response, expense_name };
  }
);
import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MenuItem from '@mui/material/MenuItem';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _trim from 'lodash/trim';
import { useParams } from 'react-router-dom';
// Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import PhoneField from 'components/Common/PhoneField/PhoneField';
import BaseDatePicker from 'components/Common/BaseDatePicker/BaseDatePicker';
import BaseAddressAutocomplete from 'components/Common/BaseAddressAutocomplete/BaseAddressAutocomplete';
import CompaniesAutocomplete from 'components/Companies/Autocomplete/Autocomplete';
import RolesSelect from '../RolesSelect/RolesSelect';
import Avatar from '../Avatar/Avatar';
import { checkEmptyString, checkValidEmail, getRepresentation } from 'helpers';
import { userFieldsTypes, states } from 'helpers/constants';
import { DELETE_USER } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useDialogs from 'hooks/useDialogs';
import useUsers from 'hooks/useUsers';
import useUrl from 'hooks/useUrl';

const today = new Date();
const { FULL, PERSONAL_INFO, LOCATION } = userFieldsTypes;

const Drawer = () => {
  const { id } = useParams();
  const { createUser, updateUser } = useUsers();
  const { openConfirmationDialog } = useDialogs();
  const { open, edit, reason, fieldsType } = useCustomSelector(state => state.app.user);
  const uploadedUser = useCustomSelector(state => state.users.user);
  const { companies } = useCustomSelector(state => state.profile.user);
  const { closeDrawer } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { isManagersRoute } = useUrl();
  const [roles, setRoles] = useState({ value: [], error: '' });
  const [photo, setPhoto] = useState({ value: '', formattedValue: '' });
  const [firstName, setFirstName] = useState({ value: '', error: '' });
  const [lastName, setLastName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [secondPhone, setSecondPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [emergencyPhone, setEmergencyPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [secondEmergencyPhone, setSecondEmergencyPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [birthAt, setBirthAt] = useState({ value: '', formattedValue: null, error: '' });
  const [address, setAddress] = useState({ value: '', error: '' });
  const [secondAddress, setSecondAddress] = useState({ value: '', error: '' });
  const [city, setCity] = useState({ value: '', error: '' });
  const [zip, setZip] = useState({ value: '', error: '' });
  const [state, setState] = useState({ value: '', error: '' });
  const [companyIds, setCompanyIds] = useState({ value: [], formattedValue: [], error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [repeatPassword, setRepeatPassword] = useState({ value: '', error: '' });
  const [showPasswordSymbols, setShowPasswordSymbols] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDelete = () => {
    openConfirmationDialog({
      id: uploadedUser.id,
      content: 'Are you sure you want to delete this user?',
      type: DELETE_USER,
      reason
    });
  };
  const handleSave = () => {
    let hasError = false;

    if (!roles.value.length) {
      setRoles(prev => ({ ...prev, error: 'Roles can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(firstName.value)) {
      setFirstName(prev => ({ ...prev, error: 'First Name can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(lastName.value)) {
      setLastName(prev => ({ ...prev, error: 'Last Name can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(phone.value)) {
      setPhone(prev => ({ ...prev, error: 'Phone can\'t be empty' }));
      hasError = true;
    }
    if (!checkValidEmail(email.value)) {
      setEmail(prev => ({ ...prev, error: 'Email is invalid' }));
      hasError = true;
    }
    if (!companyIds.value.length) {
      setCompanyIds(prev => ({ ...prev, error: 'Companies can\'t be empty' }));
      hasError = true;
    }
    if (!edit && password.value.length < 6) {
      setPassword(prev => ({ ...prev, error: 'Password is too short' }));
      hasError = true;
    }
    if (!edit && repeatPassword.value.length < 6) {
      setRepeatPassword(prev => ({ ...prev, error: 'Password is too short' }));
      hasError = true;
    }
    if (!!password.value && !!repeatPassword.value && password.value !== repeatPassword.value) {
      setPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      setRepeatPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      hasError = true;
    }
    if (!!phone.error) hasError = true;
    if (!!secondPhone.error) hasError = true;
    if (!!emergencyPhone.error) hasError = true;
    if (!!secondEmergencyPhone.error) hasError = true;
    if (!!birthAt.error) hasError = true;
    if (hasError) return;

    const processableUser = {
      ...(edit && { id: uploadedUser.id }),
      roles: roles.value,
      first_name: firstName.value,
      last_name: lastName.value,
      email: _trim(email.value),
      company_ids: companyIds.value,
      password: password.value,
      ...(!!photo.value ? {
        photo: photo.value,
        localPhoto: photo.formattedValue
      } : { photo: null }),
      ...(!!phone.value && { phone: phone.value }),
      ...(!!secondPhone.value && { second_phone: secondPhone.value }),
      ...(!!emergencyPhone.value && { emergency_phone: emergencyPhone.value }),
      ...(!!secondEmergencyPhone.value && { second_emergency_phone: secondEmergencyPhone.value }),
      ...(!!birthAt.value && { birth_at: birthAt.value }),
      ...(!!address.value && { address: address.value }),
      ...(!!secondAddress.value && { second_address: secondAddress.value }),
      state: state.value || null,
      city: city.value || null,
      zip: zip.value || null
    };

    setProcessing(true);
    if (edit) {
      if (needToUpdate(uploadedUser, processableUser)) {
        updateUser(processableUser)
        .then(() => setSuccess('User successfully updated').then(() => closeDrawer(reason)))
        .catch(error => setError(error))
        .finally(() => setProcessing(false));
      } else {
        closeDrawer(reason);
      }
    } else {
      createUser(processableUser)
      .then(() => setSuccess(`User successfully created`).then(() => closeDrawer(reason)))
      .catch(error => setError(error))
      .finally(() => setProcessing(false));
    }
  };
  const handleEntering = () => {
    if (edit && !!id) {
      setRoles({ value: uploadedUser.roles, error: '' });
      setFirstName({ value: uploadedUser.first_name, error: '' });
      setLastName({ value: uploadedUser.last_name, error: '' });
      setEmail({ value: uploadedUser.email, error: '' });
      !!uploadedUser.photo && setPhoto({ value: uploadedUser.photo.id, formattedValue: getRepresentation(uploadedUser.photo?.representations, 'tiny') });
      !!uploadedUser.phone && setPhone({ value: uploadedUser.phone, formattedValue: uploadedUser.phone, error: '' });
      !!uploadedUser.second_phone && setSecondPhone({ value: uploadedUser.phone, formattedValue: uploadedUser.second_phone, error: '' });
      !!uploadedUser.emergency_phone && setEmergencyPhone({ value: uploadedUser.phone, formattedValue: uploadedUser.emergency_phone, error: '' });
      !!uploadedUser.second_emergency_phone && setSecondEmergencyPhone({ value: uploadedUser.phone, formattedValue: uploadedUser.second_emergency_phone, error: '' });
      !!uploadedUser.birth_at && setBirthAt({ value: uploadedUser.birth_at, formattedValue: new Date(uploadedUser.birth_at), error: '' });
      !!uploadedUser.companies.length && setCompanyIds({ value: _map(uploadedUser.companies, ({ id }) => id), formattedValue: uploadedUser.companies, error: '' });
      !!uploadedUser.address && setAddress({ value: uploadedUser.address, error: '' });
      !!uploadedUser.second_address && setSecondAddress({ value: uploadedUser.second_address, error: '' });
      !!uploadedUser.state && setState({ value: uploadedUser.state, error: '' });
      !!uploadedUser.city && setCity({ value: uploadedUser.city, error: '' });
      !!uploadedUser.zip && setZip({ value: uploadedUser.zip, error: '' });
    } else {
      setRoles({ value: isManagersRoute ? ['manager'] : ['driver'], error: '' });
      companies.length === 1 && setCompanyIds({ value: [companies[0]?.id], formattedValue: companies, error: '' });
    }
  };
  const handleExiting = () => {
    setRoles({ value: [], error: '' });
    setPhoto({ value: '', formattedValue: '' });
    setFirstName({ value: '', error: '' });
    setLastName({ value: '', error: '' });
    setEmail({ value: '', error: '' });
    setPhone({ value: '', formattedValue: '', error: '' });
    setSecondPhone({ value: '', formattedValue: '', error: '' });
    setEmergencyPhone({ value: '', formattedValue: '', error: '' });
    setSecondEmergencyPhone({ value: '', formattedValue: '', error: '' });
    setBirthAt({ value: '', formattedValue: null, error: '' });
    setAddress({ value: '', error: '' });
    setSecondAddress({ value: '', error: '' });
    setState({ value: '', error: '' });
    setCity({ value: '', error: '' });
    setZip({ value: '', error: '' });
    setCompanyIds({ value: [], formattedValue: [], error: '' });
    setPassword({ value: '', error: '' });
    setRepeatPassword({ value: '', error: '' });
    setShowPasswordSymbols(false);
    setProcessing(false);
  };
  const needToUpdate = (a, b) => {
    return !_isEqual(a.roles.sort(), b.roles.sort())
      || (!!b.address && a.address !== b.address)
      || (!!b.second_address && a.second_address !== b.second_address)
      || (!!b.birth_at && a.birth_at !== b.birth_at)
      || !_isEqual(_map(a.companies, ({ id }) => id).sort(), b.company_ids.sort())
      || a.email !== b.email
      || a.first_name !== b.first_name
      || a.last_name !== b.last_name
      || (!!b.phone && a.phone !== b.phone)
      || (!!b.second_phone && a.second_phone !== b.second_phone)
      || (!!b.emergency_phone && a.emergency_phone !== b.emergency_phone)
      || (!!b.second_emergency_phone && a.second_emergency_phone !== b.second_emergency_phone)
      || a.state !== b.state
      || a.city !== b.city
      || a.zip !== b.zip
      || (a.photo?.id !== b.photo)
      || !!b.password;
  };

  return (
    <BaseDrawer
      open={open}
      edit={edit}
      onClose={() => closeDrawer(reason)}
      headContent={<Typography variant='title' sx={{color: 'action.active'}}>{edit ? 'Edit' : 'Create'} User</Typography>}
      disabled={processing}
      onDelete={handleDelete}
      onSave={handleSave}
      SlideProps={{ onEntering: handleEntering, onExited: handleExiting }}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          { fieldsType === FULL && <Box order={1}>
            <Typography variant='title' sx={{ p: '0px 0 8px'  }}>Main {!edit && ' *'}</Typography>
            <RolesSelect
              fullWidth
              margin='dense'
              value={roles.value}
              error={!!roles.error}
              helperText={roles.error}
              onChange={({ value, error }) => setRoles({ value, error })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='First Name'
              value={firstName.value}
              error={!!firstName.error}
              helperText={firstName.error}
              onChange={({ target: { value } }) => setFirstName({ value, error: '' })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='Last Name'
              value={lastName.value}
              error={!!lastName.error}
              helperText={lastName.error}
              onChange={({ target: { value } }) => setLastName({ value, error: '' })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='Email'
              type='email'
              value={email.value}
              error={!!email.error}
              helperText={email.error}
              onChange={({ target: { value } }) => setEmail({ value, error: '' })}
            />
            { companies.length > 1 && <CompaniesAutocomplete
              fullWidth
              margin='dense'
              multiple
              value={companyIds.formattedValue}
              error={!!companyIds.error}
              helperText={companyIds.error}
              onChange={({ value, formattedValue }) => setCompanyIds({ value, formattedValue })}
            /> }
          </Box>}
          {(fieldsType === FULL || fieldsType === PERSONAL_INFO) && <Box order={!!edit ? 2 : 3}>
            <Typography variant='title' sx={{  p: '32px 0 8px' }}>Personal Info</Typography>
            <Avatar
              edit
              src={photo.formattedValue}
              firstName={firstName.value}
              lastName={lastName.value}
              disabled={processing}
              onChange={({ value, formattedValue }) => setPhoto(prev => ({ ...prev, value, formattedValue }))}
            />
            <PhoneField
              fullWidth
              margin='dense'
              label='Phone'
              formattedValue={phone.formattedValue}
              error={!!phone.error}
              helperText={phone.error}
              onChange={({ value, formattedValue, error }) => setPhone({ value, formattedValue, error })}
            />
            <PhoneField
              fullWidth
              margin='dense'
              label='Phone #2'
              formattedValue={secondPhone.formattedValue}
              error={!!secondPhone.error}
              helperText={secondPhone.error}
              onChange={({ value, formattedValue, error }) => setSecondPhone({ value, formattedValue, error })}
            />
            <PhoneField
              fullWidth
              margin='dense'
              label='Emergency Phone'
              formattedValue={emergencyPhone.formattedValue}
              error={!!emergencyPhone.error}
              helperText={emergencyPhone.error}
              onChange={({ value, formattedValue, error }) => setEmergencyPhone({ value, formattedValue, error })}
            />
            <PhoneField
              fullWidth
              margin='dense'
              label='Emergency Phone #2'
              formattedValue={secondEmergencyPhone.formattedValue}
              error={!!secondEmergencyPhone.error}
              helperText={secondEmergencyPhone.error}
              onChange={({ value, formattedValue, error }) => setSecondEmergencyPhone({ value, formattedValue, error })}
            />
            <BaseDatePicker
              fullWidth
              margin='dense'
              label='Birth Date'
              maxDate={today}
              value={birthAt.formattedValue}
              error={!!birthAt.error}
              helperText={birthAt.error}
              onChange={({ value, formattedValue, error }) => setBirthAt({ value, formattedValue, error })}
            />
          </Box>}
          {(fieldsType === FULL || fieldsType === LOCATION) && <Box order={!!edit ? 3 : 4}>
            <Typography variant='title' sx={{  p: '32px 0 8px' }}>Location</Typography>
            <BaseAddressAutocomplete
              id='user-location'
              margin='dense'
              label='Address'
              value={address.value}
              error={!!address.error}
              helperText={address.error}
              onChange={({ value, error = '' }) => setAddress({ value, error })}
            />
            <BaseAddressAutocomplete
              id='user-location'
              margin='dense'
              label='Second Address'
              value={secondAddress.value}
              error={!!secondAddress.error}
              helperText={secondAddress.error}
              onChange={({ value, error = '' }) => setSecondAddress({ value, error })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='City'
              value={city.value}
              error={!!city.error}
              helperText={city.error}
              onChange={({ target: { value } }) => setCity({ value, error: '' })}
            />
            <TextField
              fullWidth
              margin='dense'
              label='Zip'
              value={zip.value}
              error={!!zip.error}
              helperText={zip.error}
              onChange={({ target: { value } }) => setZip({ value, error: '' })}
              InputProps={{ inputProps: { maxLength: 5 } }}
            />
            <TextField 
              fullWidth
              margin='dense'
              label='State' 
              value={state.value} 
              select
              helperText={state.error} 
              error={!!state.error} 
              onChange={({ target: { value } }) => setState({ value, error: '' })} 
              SelectProps={{ MenuProps: { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, sx: { height: '40%' } } }}
            >
              { _map(states, (state) => <MenuItem key={state} value={state}>{state}</MenuItem>) }
            </TextField>
          </Box>}
          {fieldsType === FULL && (
            <Box order={!!edit ? 5 : 2}>
              <Typography variant='title' sx={{  p: !!edit ? '32px 0 8px': '32px 0 8px'  }}>Password {!edit && ' *'}</Typography>
              {!!edit && (
                <Typography variant='caption' color='textSecondary' component={'div'} pb={.5}>
                  Fill in the fields only if you want to change current password
                </Typography>
              )}
              <TextField
                fullWidth
                margin='dense'
                label='Enter password'
                type={showPasswordSymbols ? 'text' : 'password'}
                value={password.value}
                onChange={({ target: { value } }) => { setPassword({ value, error: '' }); setRepeatPassword(prev => ({ ...prev, error: '' })) }}
                error={!!password.error}
                helperText={password.error}
                autoComplete='new-password'
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="show password"
                      disableRipple
                      onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                      sx={{ position: 'absolute', right: '8px' }}
                    >
                      {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  )
                }}
              />
              <TextField
                fullWidth
                margin='dense'
                label='Repeat password'
                type={showPasswordSymbols ? 'text' : 'password'}
                value={repeatPassword.value}
                onChange={({ target: { value } }) => { setRepeatPassword({ value, error: '' }); setPassword(prev => ({ ...prev, error: '' })) }}
                error={!!repeatPassword.error}
                helperText={repeatPassword.error}
                autoComplete='new-password'
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="show password"
                      disableRipple
                      onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                      sx={{ position: 'absolute', right: '8px' }}
                    >
                      {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  )
                }}
              />
            </Box>
          )}
      </Box>
      }
    />
  );
};

export default memo(Drawer);
import { memo } from 'react';
//Local files
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import Form from 'components/Widget/Form/Form';
import { Root } from './Widget.styled';

const Widget = () => 
  <Root>
    <ComponentErrorWrapper><Form /></ComponentErrorWrapper>
  </Root>;

export default memo(Widget);
import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getBlobRoutine,
  createBlobRoutine
} from '.';

const attributes = ['id', 'content_type', 'filename'];

export const getBlob = getThunkActionCreator(
  getBlobRoutine,
  async (id) => await axios.get(`/user/blobs/${id}`, { params: { attributes } })
);
export const createBlob = getThunkActionCreator(
  createBlobRoutine,
  async ({ name, size, type, checksum, file }) => {
    const response = await axios.post(`/user/blobs/`, { blob: { filename: name, byte_size: size, content_type: type, checksum }, attributes: [...attributes, 'direct_upload_url', 'direct_upload_headers'] });
    await axios.put(response.data.blob.direct_upload_url, file, { headers: response.data.blob.direct_upload_headers });

    return response;
  }
);
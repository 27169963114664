import {
  getListLocationsRoutine, 
  getLocationsRoutine,
  getLocationRoutine,
  clearLocalListLocationsRoutine,
  clearLocalLocationsRoutine,
  clearLocalActiveLocationRoutine
} from 'actions';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  location: { 
    id: '', 
    name: '',
    company: { id: '', name: '' }, 
    address: '', 
    latitude: '', 
    longitude: '', 
    created_at: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListLocationsRoutine.SUCCESS: {
      const { data: { locations, pagination } } = action.payload;

      return { ...state, list: { data: [...state.all.data, ...locations], pagination } };
    }
    case getLocationsRoutine.SUCCESS: {
      const { data: { locations, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...locations], pagination } };
    }
    case getLocationRoutine.SUCCESS: {
      const { data: { location } } = action.payload;

      return { ...state, location };
    }
    case clearLocalListLocationsRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalLocationsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveLocationRoutine.SUCCESS: {
      return { ...state, location: initialState.location }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
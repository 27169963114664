import { memo, useEffect, useCallback } from 'react';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _startCase from 'lodash/startCase';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _pick from 'lodash/pick';
// Local files
import BaseListItem from 'components/Common/BaseListItem/BaseListItem';
import BaseScrollableContainer from 'components/Common/BaseScrollableContainer/BaseScrollableContainer';
import Status from '../Status/Status';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOrders from 'hooks/useOrders';
import { orderStatuses } from 'helpers/constants';
import { formatCreationDate } from 'helpers';

const OrderList = ({ user_id, query, statuses, fetching, onFetching }) => {
  const { id: orderId } = useParams();
  const { getOrders, clearLocalOrders } = useOrders();
  const isFetching = useCustomSelector(state => state.app.isFetching);
  const { data, pagination } = useCustomSelector(state => state.orders.all)
  const { setError } = useError();

  const fetchOrders = useCallback((offset, user_id, query, statuses) => {
    onFetching(true);
    getOrders({ 
      offset, 
      user_id, 
      query, 
      statuses: _includes(statuses, 'all_orders') ? 
      Object.values(orderStatuses) : _includes(statuses, 'active_only') ? 
      Object.values(_pick(orderStatuses, ['NEW', 'MANAGER_ACCEPTED', 'COMPANY_ACCEPTED', 'EN_ROUTE', 'PICK_UP', 'SERVICING', 'DROP_OFF'])) : statuses 
    })
    .catch(error => !axios.isCancel(error) && setError(error))
    .finally(() => onFetching(false));
  }, [getOrders, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalOrders().then(() => fetchOrders(0, user_id, query, statuses)), !!query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [fetchOrders, clearLocalOrders, user_id, query, statuses]);

  return (
    <BaseScrollableContainer {...pagination} onLoad={offset => !fetching && fetchOrders(offset, user_id, query, statuses)}>
      <List>
        {_map(data, ({ id, number, account, services, status, created_at, scheduled_at }) => (
          <BaseListItem
            key={id}
            title={number}
            content={<Status size='small' value={status} />}
            createdAt={formatCreationDate(created_at)}
            description={`${account?.name} — ${_startCase(services[0]?.name)}`}
            selected={id === orderId}
            to={`/orders/${id}`}
            disabled={isFetching}
            scheduledAt={ !!scheduled_at && formatCreationDate(scheduled_at)}
          />
        ))}
      </List>
    </BaseScrollableContainer>
  );
};

OrderList.propTypes = {
  fetching: PropTypes.bool.isRequired,
  onFetching: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  statuses: PropTypes.array.isRequired
};

export default memo(OrderList);
import { memo, useRef, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import SignatureCanvas from 'react-signature-canvas';
// Local files
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useInvoices from 'hooks/useInvoices';

const Signature = () => {
  const sigPad = useRef(null);
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { closeSignatureDialog } = useDialogs();
  const { createInvoiceSignature } = useInvoices();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open, id } = useCustomSelector(state => state.dialogs.signature);
  const [processing, setProcessing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleClear = () => {
    setIsEmpty(true);
    sigPad.current.clear();
  };
  const handleClose = () => {
    setIsEmpty(true);
    closeSignatureDialog();
  };
  const handleGetSignature = () => {
    setProcessing(true);
    sigPad.current.getCanvas().toBlob((blob) => 
      processFiles([new File([blob], `signature_${blob?.id}.png`, { type: 'image/png' })], ({ file, checksum }) => 
      processBlob({ file, checksum }, blob => 
        createInvoiceSignature({ id, blob_id: blob.id })
        .then(() => setSuccess('Customer signature successfully uploaded').then(handleClose))
        .catch((error) => setError(error))
        .finally(() => setProcessing(false))
      ))
    , 'image/png')
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title='Customer Signature'
      fullWidth
      otherPaperStyle={{ maxWidth: 360 }}
    >
      <DialogContent sx={{ p: 0 }}>
        <SignatureCanvas
          penColor='blue'
          canvasProps={{ width: 360, height: 200 }}
          ref={sigPad}
          onEnd={() => setIsEmpty(false)}
        />
        <ButtonBase
          sx={{
            width: '100%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderTop: '1px solid #F1F0FC',
            padding: '15px 24px',
          }}
          disabled={processing}
          onClick={handleClear}
        >
          Clear
        </ButtonBase>
      </DialogContent>
      <Box sx={{ width: '100%', borderTop: '1px solid #F1F0FC' }}>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          disabled={processing}
          onClick={handleClose}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          disabled={processing || isEmpty}
          onClick={handleGetSignature}
        >
          Save
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(Signature);
import {
  getCompanyServicesRoutine,
  getFullListServicesRoutine,
  getListServicesRoutine,
  getServicesRoutine,
  getServiceRoutine,
  createServiceRoutine,
  createCompanyServiceRoutine,
  deleteCompanyServiceRoutine,
  clearLocalListServicesRoutine,
  clearLocalServicesRoutine,
  clearLocalCompanyServicesRoutine,
  clearLocalActiveServiceRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _uniqBy from 'lodash/uniqBy';
import { serviceTypes } from 'helpers/constants';

export const initialState = {
  list: {
    basic: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
    additional: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
    all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } }
  },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  company: { list: { basic: [], additional: [] }, data: { basic: [], additional: [] } },
  service: { id: '', name: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getCompanyServicesRoutine.SUCCESS: {
      const { services, service_type } = action.payload;

      return { ...state, company: { ...state.company, data: { 
        ...state.company.data, 
        ...(service_type === serviceTypes.BASIC && { basic: services }),
        ...(service_type === serviceTypes.ADDITIONAL && { additional: services })
      } } };
    }
    case getFullListServicesRoutine.SUCCESS: {
      const { services, service_type } = action.payload;

      return { ...state, company: { ...state.company, list: { 
        ...state.company.list, 
        ...(service_type === serviceTypes.BASIC && { basic: services }),
        ...(service_type === serviceTypes.ADDITIONAL && { additional: services })
      } } };
    }
    case getListServicesRoutine.SUCCESS: {
      const { response: { data: { services, pagination } }, service_type } = action.payload;

      return { ...state, 
        list: { ...state.list,
          ...(service_type === null && { 
            all: { data: _uniqBy([...state.list.all.data, ...services], 'id'), pagination: pagination.total_count === 0 ? { ...pagination, total_count: -1 } : pagination }
          }),
          ...(service_type === serviceTypes.BASIC && { 
            basic: { data: _uniqBy([...state.list.basic.data, ...services], 'id'), pagination: pagination.total_count === 0 ? { ...pagination, total_count: -1 } : pagination }
          }),
          ...(service_type === serviceTypes.ADDITIONAL && { 
            additional: { data: _uniqBy([...state.list.additional.data, ...services], 'id'), pagination: pagination.total_count === 0 ? { ...pagination, total_count: -1 } : pagination }
          }),
        } 
      };
    }
    case getServicesRoutine.SUCCESS: {
      const { data: { services, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...services], pagination } };
    }
    case getServiceRoutine.SUCCESS: {
      const { data: { service } } = action.payload;
      const updatedService = { ...state.service, ...service };
      const data = _map(state.all.data, (item) => item.id === service.id ? { ...item, ...service } : item)

      return { ...state, service: updatedService, all: { ...state.all, data } };
    }
    case createServiceRoutine.SUCCESS: {
      const { data: { service } } = action.payload;
      const oldAllData = service.service_type === serviceTypes.BASIC ? 
        (state.list.basic.pagination.total_count > 25 ? _dropRight(state.list.basic.data) : state.list.basic.data) : 
        (state.list.additional.pagination.total_count > 25 ? _dropRight(state.list.additional.data) : state.list.additional.data);
      const data = [...[service], ...oldAllData];
      const pagination = service.service_type === serviceTypes.BASIC ? 
        { ...state.list.basic.pagination, count: state.list.basic.pagination.count + 1, total_count: state.list.basic.pagination.total_count + 1 } :
        { ...state.list.additional.pagination, count: state.list.additional.pagination.count + 1, total_count: state.list.additional.pagination.total_count + 1 };

      return { ...state, list: { 
        ...state.list, 
        ...(service.service_type === serviceTypes.BASIC && { basic: { data, pagination } }),
        ...(service.service_type === serviceTypes.ADDITIONAL && { additional: { data, pagination } })
        } 
      };
    }
    case createCompanyServiceRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const newService = _find(state.company.list.basic, (s) => s.id === id) || _find(state.company.list.additional, (s) => s.id === id);

      return { ...state, ...(status === 204 && {
        company: { ...state.company, data: { 
          ...state.company.data, 
          ...(newService.service_type === serviceTypes.BASIC && { basic: [...[newService], ...state.company.data.basic] }),
          ...(newService.service_type === serviceTypes.ADDITIONAL && { additional: [...[newService], ...state.company.data.additional] })
        } }
      }) };
    }
    case deleteCompanyServiceRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const deletedService = _find(state.company.list.basic, (s) => s.id === id) || _find(state.company.list.additional, (s) => s.id === id);

      return { ...state, ...(status === 204 && {
        company: { ...state.company, data: { 
          ...state.company.data, 
          ...(deletedService.service_type === serviceTypes.BASIC && { basic: _filter(state.company.data.basic, (s) => s.id !== deletedService.id) }),
          ...(deletedService.service_type === serviceTypes.ADDITIONAL && { additional: _filter(state.company.data.additional, (s) => s.id !== deletedService.id) })
        } }
      }) };
    }
    case clearLocalListServicesRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalServicesRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalCompanyServicesRoutine.SUCCESS: {
      return { ...state, company: initialState.company }
    }
    case clearLocalActiveServiceRoutine.SUCCESS: {
      return { ...state, service: initialState.service }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
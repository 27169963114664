import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getRequestsRoutine,
  getRequestRoutine, 
  createRequestRoutine,
  answerRequestRoutine,
  clearLocalRequestsRoutine
} from 'actions';

const attributes = ['id', 'channel_message', 'parameter_values', 'source', 'status', 'text', 'response', 'created_at'];

export const getRequests = getThunkActionCreator(
  getRequestsRoutine,
  async ({ offset = 0, order_id }) => {
    getRequests.getRequestsCancel && getRequests.getRequestsCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/orders/${order_id}/requests`, {
      params: { 
        access_token, 
        offset, 
        limit: 10, 
        orders: { created_at: 'desc' }, 
        attributes
      },
      cancelToken: new CancelToken(function executor(cancel) { getRequests.getRequestsCancel = cancel })
    });
  }
);
export const getRequest = getThunkActionCreator(
  getRequestRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/requests/${id}`,
      {
        params: { access_token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getRequest.getRequestCancel = cancel })
      }
    );
  }
);
export const createRequest = getThunkActionCreator(
  createRequestRoutine,
  async ({ request, order_id }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/orders/${order_id}/requests`, { access_token, request, attributes });
  }
);
export const answerRequest = getThunkActionCreator(
  answerRequestRoutine,
  async ({ request_id, response }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/requests/${request_id}/answer`, { access_token, response });
  }
);
export const clearLocalRequests = getThunkActionCreator(clearLocalRequestsRoutine, async () => {});
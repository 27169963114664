import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// Local files
import Form from 'components/SignIn/Form/Form';

const SignIn = () => {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'background.paper' }}>
      <Container maxWidth='xs'>
        <Typography
          sx={{
              fontSize: '2.25rem',
              fontWeight: '500',
              textAlign: 'center',
          }}
        >
            Welcome to Roadsider
        </Typography>
        <Form />
      </Container>
    </Box>
  );
};

export default memo(SignIn);
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  createRecipients as createRecipientsAction,
  updateRecipients as updateRecipientsAction,
  deleteRecipient as deleteRecipientAction
} from 'actions/recipients';

const useRecipients = () => {
  const dispatch = useDispatch();
  const createRecipients = useCallback(({ id, model, recipients }) => dispatch(createRecipientsAction({ id, model, recipients })), [dispatch]);
  const updateRecipients = useCallback(recipients => dispatch(updateRecipientsAction(recipients)), [dispatch]);
  const deleteRecipient = useCallback(id => dispatch(deleteRecipientAction(id)), [dispatch]);

  return { createRecipients, updateRecipients, deleteRecipient };
};

export default useRecipients;
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: 16,
  paddingRight: 16,
  marginBottom: 16
}));
export const Head = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  width: '100%'
}));
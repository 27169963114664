import { memo } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import PropTypes from 'prop-types';

const BaseDatePicker = ({ disabled = false, type = 'date', label, margin = 'none', fullWidth = false, value, error = false, helperText = '', maxDate = null, minDate = null, minTime = null, maxTime = null, onChange }) => {
  const isDate = type === 'date';
  const Picker = isDate ? DatePicker : TimePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Picker
        label={label}
        value={value}
        disabled={disabled}
        onChange={newValue =>
          onChange({
            value: !!newValue && newValue?.toString() !== 'Invalid Date' ? newValue.toISOString() : '',
            formattedValue: newValue,
            error: newValue?.toString() === 'Invalid Date' ? 'Date is invalid' : ''
          })
        }
        renderInput={params =>
          <TextField
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            margin={margin}
            {...params}
          />
        }
        {...isDate ? { maxDate, minDate } : { minTime, maxTime, ampm: false }}
      />
    </LocalizationProvider>
  );
};

BaseDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default memo(BaseDatePicker);
import { memo } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
// Common
import Layout from 'components/Common/Layout/Layout';
import CheckAuth from 'components/Common/CheckAuth/CheckAuth';
import CheckSession from 'components/Common/CheckSession/CheckSession';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
// Views
import Accounts from 'views/Accounts/Accounts';
import AccountDetails from 'views/AccountDetails/AccountDetails';
import Customer from 'views/Customer/Customer';
import Company from 'views/Company/Company';
import DriverDetails from 'views/DriverDetails/DriverDetails';
import Drivers from 'views/Drivers/Drivers';
import ManagerDetails from 'views/ManagerDetails/ManagerDetails';
import Managers from 'views/Managers/Managers';
import TruckDetails from 'views/TruckDetails/TruckDetails';
import Trucks from 'views/Trucks/Trucks';
import Orders from 'views/Orders/Orders';
import OrderDetails from 'views/OrderDetails/OrderDetails';
import Invoices from 'views/Invoices/Invoices';
import InvoiceDetails from 'views/InvoiceDetails/InvoiceDetails';
import Vehicles from 'views/Vehicles/Vehicles';
import VehicleDetails from 'views/VehicleDetails/VehicleDetails';
import Dashboard from 'views/Dashboard/Dashboard';
import Map from 'views/Map/Map';
import NotFound from 'views/NotFound/NotFound';
import Recovery from 'views/Recovery/Recovery';
import Settings from 'views/Settings/Settings';
import DutyStatus from 'views/DutyStatus/DutyStatus';
import Resources from 'views/Resources/Resources';
import SignIn from 'views/SignIn/SignIn';
import SignUp from 'views/SignUp/SignUp';
import Widget from 'views/Widget/Widget';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ComponentErrorWrapper children={<Layout />} />}>
          <Route path='duty_status' element={<CheckAuth><DutyStatus /></CheckAuth>}/>
          <Route path='dashboard' element={<CheckAuth><Dashboard /></CheckAuth>}/>
          <Route path='invoices' element={<CheckAuth><ComponentErrorWrapper children={<Invoices />} /></CheckAuth>}>
            <Route path=':id' element={<CheckAuth><InvoiceDetails /></CheckAuth>}/>
          </Route>
          <Route path='vehicles' element={<CheckAuth><ComponentErrorWrapper children={<Vehicles />} /></CheckAuth>}>
            <Route path=':id' element={<CheckAuth><VehicleDetails /></CheckAuth>} />
          </Route>
          <Route path='accounts' element={<CheckAuth><ComponentErrorWrapper children={<Accounts />} /></CheckAuth>}>
            <Route path=':id' element={<CheckAuth><AccountDetails /></CheckAuth>} />
          </Route>
          <Route path='company' element={<CheckAuth><Company /></CheckAuth>}>
            <Route path='drivers' element={<CheckAuth><Drivers /></CheckAuth>}>
              <Route path=':id' element={<CheckAuth><DriverDetails /></CheckAuth>} />
            </Route>
            <Route path='managers' element={<CheckAuth><Managers /></CheckAuth>}>
              <Route path=':id' element={<CheckAuth><ManagerDetails /></CheckAuth>} />
            </Route>
            <Route path='trucks' element={<CheckAuth><Trucks /></CheckAuth>}>
              <Route path=':id' element={<CheckAuth><TruckDetails /></CheckAuth>} />
            </Route>
          </Route>
          <Route path='orders' element={<CheckAuth><ComponentErrorWrapper children={<Orders />} /></CheckAuth>}>
            <Route path=':id' element={<CheckAuth><OrderDetails /></CheckAuth>} />
          </Route>
          <Route path='map' element={<CheckAuth><ComponentErrorWrapper children={<Map />} /></CheckAuth>} />
          <Route path='settings' element={<CheckAuth><Settings /></CheckAuth>}>
            <Route path=':id' element={<Outlet />}>
              <Route path='stripe-connect/success' element={<Outlet />} />
              <Route path='stripe-connect/reload' element={<Outlet />} />
              <Route path='stripe-subscription/success' element={<Outlet />} />
              <Route path='stripe-subscription/cancel' element={<Outlet />} />
            </Route>
          </Route>
          <Route path='resources' element={<CheckAuth><Resources /></CheckAuth>} />
          <Route path='recovery' element={<CheckSession><Recovery /></CheckSession>} />
          <Route path='sign_in' element={<CheckSession><SignIn /></CheckSession>} />
          <Route path='sign_up' element={<CheckSession><SignUp /></CheckSession>} />
          <Route path='c/:customer_token/o/:order_id' element={<ComponentErrorWrapper children={<Customer />} />}/>
          <Route path='c/:company_id/widget' element={<ComponentErrorWrapper children={<Widget />} />} />
          <Route path='add/*' element={<Outlet/>} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default memo(App);
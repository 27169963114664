import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
//Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import Table from 'components/Requests/Table/Table';
import Form from 'components/Requests/Form/Form';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useRequests from 'hooks/useRequests';
import useError from 'hooks/useError';
import { reasons } from 'helpers/constants';

const RequestAdditional = ({
  id,
  number,
  company_location
}) => {
  const { closeDrawer } = useApp();
  const { setError } = useError();
  const { getRequests, clearLocalRequests } = useRequests();

  const { open, requests, isShowMore, nextOffset } = useCustomSelector(state => ({
    open: state.app.requestAdditional.open,
    requests: state.requests.all.data,
    isShowMore: state.requests.all.pagination.count + state.requests.all.pagination.offset < state.requests.all.pagination.total_count,
    nextOffset: state.requests.all.pagination.limit + state.requests.all.pagination.offset
  }));
  const [processing, setProcessing] = useState(false);
  const [fetching, setFetching] = useState(false);
  const disabled = processing || fetching;

  const fetchRequests = (offset) => {
    setFetching(true);
    getRequests({ order_id: id, offset })
    .catch((error) => setError(error))
    .finally(() => setFetching(false))
  };
  const handleEntering = () => fetchRequests(0);
  const handleExiting = () => clearLocalRequests();
  const onNextOffset = () => fetchRequests(nextOffset);

  return (
    <BaseDrawer
      grey
      open={open}
      disabled={!open}
      onClose={() => closeDrawer(reasons.REQUEST_ADDITIONAL)}
      paperSxProps={{ width: { xs: '100%', lg: '640px' } }}
      SlideProps={{ onEntering: handleEntering, onExited: handleExiting }}
      title='Manage Additionals'
      content={
        <>
          <Card sx={{ p: '24px' }}>
            <Typography variant='title'>{`Order ${number}`}</Typography>
            <Form { ...{id, disabled, processing }} onProcessing={setProcessing} />
          </Card>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pt: '40px' }}>
            <Button
              color='primary'
              variant='contained'
              endIcon={<ArrowOutwardIcon />}
              disabled={disabled}
              onClick={() => setProcessing(true)}
              sx={{ mb: 3 }}
            >
              Request
            </Button>
            {!!requests.length && <Table { ...{requests, disabled, company_location, isShowMore, onNextOffset}} />}
          </Box>
        </>
      }
    />
  );
};

export default memo(RequestAdditional);
import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _uniqBy from 'lodash/uniqBy';
import axios from 'axios';
import PropTypes from 'prop-types';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useUsers from 'hooks/useUsers';
import { userRoles } from 'helpers/constants';

const { DRIVER } = userRoles;

const UsersAutocomplete = ({ id, role, companyId = '', fullWidth = false, margin = 'none', multiple = false, includeMe = false, label, value, error = false, helperText = '', onChange, onOpen = null, size = null }) => {
  const { getDriversList, getManagersList, clearLocalDriversList, clearLocalManagersList } = useUsers();
  const { setError } = useError();
  const drivers = useCustomSelector(state => state.users.drivers);
  const managers = useCustomSelector(state => state.users.managers);
  const me = useCustomSelector(state => _pick(state.profile.user, ['id', 'first_name', 'last_name']));
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchUsers = useCallback(offset => {
    setLoading(true);

    if (role === DRIVER) {
      getDriversList({ offset, query, company_id: companyId })
      .catch(error => !axios.isCancel(error) && setError(error))
      .finally(() => setLoading(false))
    } else {
      getManagersList({ offset, query })
      .catch(error => !axios.isCancel(error) && setError(error))
      .finally(() => setLoading(false))
    }
  }, [query, role, companyId, getDriversList, getManagersList, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, offsetHeight } }) => {
    if (scrollHeight <= scrollTop + offsetHeight + 100) {
      if (role === DRIVER) {
        const { limit, offset, count, total_count } = drivers.pagination;

        total_count > count + offset && fetchUsers(limit + offset);
      } else {
        const { limit, offset, count, total_count } = managers.pagination;

        total_count > count + offset && fetchUsers(limit + offset);
      }
    }
  };

  useEffect(() => {
    fetchUsers();

    return role === DRIVER ? clearLocalDriversList : clearLocalManagersList;
  }, [fetchUsers, role, clearLocalDriversList, clearLocalManagersList]); 

  return (
    <Autocomplete
      //disablePortal
      multiple={multiple}
      fullWidth={fullWidth}
      id={id}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={o => `${o.first_name} ${o.last_name}`}
      options={role === DRIVER ? drivers.data : (includeMe ? _uniqBy([me, ...managers.data], 'id') : managers.data)}
      value={value}
      onChange={(_, formattedValue) => onChange({ value: multiple ? _map(formattedValue, ({ id }) => id) : formattedValue?.id, formattedValue, error: '' })}
      loading={loading}
      inputValue={query}
      onInputChange={(_, value) => setQuery(value)}
      onOpen={() => !!onOpen && onOpen()}
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      renderInput={params =>
        <TextField
          {...params}
          {...!!size && {size}}
          margin={margin}
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>
              {loading && <CircularProgress color="inherit" size={20} />}
              {params.InputProps.endAdornment}
            </>
          }}
        />
      }
    />
  );
};

UsersAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  multiple: PropTypes.bool,
  includeMe: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default memo(UsersAutocomplete);
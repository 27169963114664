import { memo } from 'react';
// Local files
import BaseModalDetails from 'components/Common/BaseModalDetails/BaseModalDetails';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import Details from 'components/Trucks/Details/Details';

const TruckDetails = () => {

  return (
    <BaseModalDetails>
      <ComponentErrorWrapper children={<Details />} />
    </BaseModalDetails>
  )
};

export default memo(TruckDetails);
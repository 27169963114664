import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TableCell from '@mui/material/TableCell';

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: '16px 8px 16px 8px',
  [theme.breakpoints.up('md')]: {
    padding: '56px 32px 56px 32px'
  }
}));
export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 700,
  textTransform: 'none',
  [theme.breakpoints.up('md')]: {
    minWidth: '320px',
    fontSize: theme.typography.pxToRem(24)
  }
}));
export const NavigationContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(16)
}));
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getListLocations as getListLocationsAction,
  getLocations as getLocationsAction,
  getLocation as getLocationAction,
  createLocation as createLocationAction,
  updateLocation as updateLocationAction,
  deleteLocation as deleteLocationAction,
  clearLocalListLocations as clearLocalListLocationsAction,
  clearLocalLocations as clearLocalLocationsAction,
  clearLocalActiveLocation as clearLocalActiveLocationAction
} from 'actions/locations';

const useLocations = () => {
  const dispatch = useDispatch();
  const getListLocations = useCallback(({ offset, orders, query }) => dispatch(getListLocationsAction({ offset, orders, query })), [dispatch]);
  const getLocations = useCallback(({ offset, orders }) => dispatch(getLocationsAction({ offset, orders })), [dispatch]);
  const getLocation = useCallback(id => dispatch(getLocationAction(id)), [dispatch]);
  const createLocation = useCallback(({ location, side }) => dispatch(createLocationAction({ location, side })), [dispatch]);
  const updateLocation = useCallback((location) => dispatch(updateLocationAction(location)), [dispatch]);
  const deleteLocation = useCallback(id => dispatch(deleteLocationAction(id)), [dispatch]);
  const clearLocalListLocations = useCallback(() => dispatch(clearLocalListLocationsAction()), [dispatch]);
  const clearLocalLocations = useCallback(() => dispatch(clearLocalLocationsAction()), [dispatch]);
  const clearLocalActiveLocation = useCallback(() => dispatch(clearLocalActiveLocationAction()), [dispatch]);

  return { 
    getListLocations, 
    getLocations,
    getLocation,
    createLocation,
    updateLocation,
    deleteLocation,
    clearLocalListLocations,
    clearLocalLocations,
    clearLocalActiveLocation
  };
};

export default useLocations;
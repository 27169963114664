import React from 'react'
import _map from 'lodash/map';
import videos from './Info';
import { Grid } from './Guides.styled';
import Card from "./Card/Card";

const Guides = () => {
  return (
    <Grid>
    {_map(videos, (video, index) => (
      <Card key={index} {...video} />
    ))}
    </Grid>
  )
}

export default Guides;
import { memo } from 'react';
import { Box } from '@mui/material';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import _filter from 'lodash/filter';
// Local files
import BaseItem from '../BaseItem/BaseItem';

const VehicleInfo = (props) => {

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      { _map(_filter(Object.entries(props), (f) => !!f[1]), (f) =>
      <BaseItem 
        key={f[0]} 
        title={_startCase(f[0])} 
        content={f[1]}
      />)}
    </Box>
  );
};

export default memo(VehicleInfo);
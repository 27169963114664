import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import _startCase from 'lodash/startCase';
//Local files
import BaseDrawer from 'components/Common/BaseDrawer/BaseDrawer';
import DeliveryForm from 'components/Common/DeliveryForm/DeliveryForm';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import { accountTypes, reasons } from 'helpers/constants';
import useTimezones from 'hooks/useTimezones';
import useSuccess from 'hooks/useSuccess';

const SendTracking = ({ 
  id, 
  account, 
  authorization_number, 
  customer, 
  customer_token, 
  number, 
  status, 
  company_location, 
  police_number, 
  police_code, 
  recipients, 
  created_at 
}) => {
  const { closeDrawer } = useApp();
  const { setSuccess } = useSuccess();
  const { open } = useCustomSelector(state => state.app.sendOrderTracking);
  const { timeZone } = useTimezones(company_location?.company?.id);
  const [textChecked, setTextChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [processing, setProcessing] = useState(false);

  const showPONumber = account?.account_type !== accountTypes.POLICE;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(`${window.location.origin}/c/${customer_token}/o/${id}`);
    setSuccess('Copied');
  };

  return (
    <BaseDrawer
      grey
      open={open}
      disabled={!open}
      onClose={() => closeDrawer(reasons.SEND_ORDER_TRACKING)}
      title='Send Order Tracking to Customer'
      content={
        <>
          <Card sx={{ p: '24px' }}>
            <Typography variant='title'>{`Order ${number}`}</Typography>
            <Box sx={{  display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '24px 32px', pt: '24px' }}>
              <Box>
                <Typography variant='body2' color='textSecondary'>Created Date</Typography>
                <Typography variant='subtitle2'>{new Date(created_at).toLocaleDateString('en-US', { timeZone })}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='textSecondary'>{showPONumber ? 'PO' : 'Log'} Number</Typography>
                <Typography variant='subtitle2'>{showPONumber ? authorization_number || '-' : police_number || '-'}</Typography>
              </Box>
              { !showPONumber && <Box>
                <Typography variant='body2' color='textSecondary'>Police Code</Typography>
                <Typography variant='subtitle2'>{police_code}</Typography>
              </Box> }
              <Box>
                <Typography variant='body2' color='textSecondary'>Job Status</Typography>
                <Typography variant='subtitle2'>{_startCase(status)}</Typography>
              </Box>
            </Box>
            <DeliveryForm 
              {...{ id, processing, customer, textChecked, emailChecked }}
              title='Order Tracking Delivery Method' 
              model='order'
              oldRecipients={recipients} 
              onProcessing={setProcessing} 
              onTextChecked={setTextChecked}
              onEmailChecked={setEmailChecked}
              onCopyLink={handleCopyCode}
              sx={{ pt: '24px'}}
            />
          </Card>
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: '40px'}}>
            <Button
              color='primary'
              variant='contained'
              endIcon={<ArrowOutwardIcon />}
              disabled={processing || (!textChecked && !emailChecked)}
              onClick={() => setProcessing(true)}
            >
              SEND
            </Button>
          </Box>
        </>
      }
    />
  );
};

export default memo(SendTracking);
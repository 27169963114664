import styled from '@mui/material/styles/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Container = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 16px)',
  //maxHeight: 'calc(100% - 40% - 8px - 8px - 110px - 24px)',//list, margin, *Container*, margin, Settings, margin
  maxHeight: 'calc(100% - 40% - 8px - 8px)',//list, margin. DriverInfo overlaps the Settings
  position: 'absolute',
  top: 'calc(40% + 8px)',//list, margin
  left: 8,
  right: 8,
  borderRadius: '8px',
  overflow: 'auto',
  [theme.breakpoints.up('md')]: {
    width: 340,
    maxHeight: 'calc(100% - 16px - 220px - 74px)',//map, margin, settings, bottom
    top: 0,
    left: 'unset',
    right: 0,
    borderRadius: '0 0 4px 4px',
  }
}));

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0 0 4px 4px',
}));

export const Content = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '16px',
}));

export const SmallButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: '4px',
  padding: '2px',
  marginRight: '-2px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

export const StyledControlLabel = styled(FormControlLabel)(() => ({
  margin: '0 0 0 -12px',
}));

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '16px',
}));
export const Heading = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
}));
export const Title = styled(Typography)(() => ({
  fontSize: '1.5rem',
  fontWeight: 700,
}));

export const OfflineHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.text.secondary,
  padding: '5px 16px',
}));

export const OfflineTitle = styled(Typography)(() => ({
  color: 'inherit',
  fontSize: '.875rem',
}));

export const OfflineStatus = styled(Typography)(() => ({
  color: 'inherit',
  fontSize: '.75rem',
  padding: '0 12px',
}));

export const OfflineButton = styled(Button)({
  color: 'inherit',
  lineHeight: 1,
  marginLeft: 'auto',
  marginRight: '-8px',
})
import { memo, useEffect, useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useInsuranceAccounts from 'hooks/useInsuranceAccounts';

import InsuranceDialog from './Dialog/Dialog';

const InsuranceAccountsAutocomplete = ({ value, error, helperText, onChange }) => {
  const { getListInsuranceAccounts, clearLocalListInsuranceAccounts } = useInsuranceAccounts();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.insuranceAccounts.list);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true)
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitClick = () => {
    handleCloseModal();
  };

  const handleChange = (_, formattedValue) => onChange({ value: _map(formattedValue, ({ id }) => id), formattedValue, error: '' });;
  const isOptionEqualToValue = (o, v) => o.id === v.id;

  const fetchInsuranceAccounts = useCallback(offset => {
    setLoading(true);
    getListInsuranceAccounts({ offset })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListInsuranceAccounts, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchInsuranceAccounts(limit + offset);
    }
  };

  useEffect(() => {
    fetchInsuranceAccounts(0);

    return clearLocalListInsuranceAccounts;
  }, [fetchInsuranceAccounts, clearLocalListInsuranceAccounts]);

  return (
  <>
    <InsuranceDialog
      open={modalOpen}
      onClose={handleCloseModal}
      onSubmit={handleSubmitClick}
    />
    <Autocomplete
      value={value}
      multiple
      onChange={handleChange}
      id="insuranceAccountsList"
      options={[...data, {id: 'insuranceAccounts', name: 'addCredentials'}]}
      getOptionLabel={(o) => o.name}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      fullWidth
      renderInput={(params) => (
      <TextField {...params} label="Insurance Accounts" error={error} helperText={helperText} />
      )}
      renderOption={(props, option) => (
        option?.name === 'addCredentials' ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ px: '5%' }} variant="text" color="primary" onClick={handleOpenModal}>
              Add Insurance Credentials
            </Button>
          </Box>
        ) : (<li {...props}>{option.name}</li>)
      )}
    />
  </>
  );
};

export default memo(InsuranceAccountsAutocomplete);
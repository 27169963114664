import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  createVariant as createVariantAction,
  updateVariant as updateVariantAction,
  deleteVariant as deleteVariantAction
} from 'actions/variants';

const useVariants = () => {
  const dispatch = useDispatch();
  const createVariant = useCallback(({ variant, job_id }) => dispatch(createVariantAction({ variant, job_id })), [dispatch]);
  const updateVariant = useCallback(variant => dispatch(updateVariantAction(variant)), [dispatch]);
  const deleteVariant = useCallback(id => dispatch(deleteVariantAction(id)), [dispatch]);

  return {
    createVariant,
    updateVariant,
    deleteVariant
  };
};

export default useVariants;
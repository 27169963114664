import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import _omit from 'lodash/omit';
import {
  getDriversListRoutine,
  getManagersListRoutine,
  getUsersRoutine,
  getUsersStatisticsRoutine,
  getUserRoutine,
  createUserRoutine,
  updateUserRoutine,
  deleteUserRoutine,
  changeUserPasswordRoutine,
  clearLocalDriversListRoutine,
  clearLocalManagersListRoutine,
  clearLocalUsersRoutine,
  clearLocalUsersStatisticsRoutine,
  clearLocalActiveUserRoutine,
  startUserDutyRoutine,
  stopUserDutyRoutine
} from 'actions';

const shortAttributes = [
  'id',
  'first_name',
  'last_name',
  'roles',
  { trucks: ['id', 'make', 'model', 'year', 'type'] }
];

const fullAttributes = [
  ...shortAttributes,
  'previous_names',
  'roles',
  'birth_at',
  'email',
  'phone',
  'second_phone',
  'emergency_phone',
  'second_emergency_phone',
  'address',
  'second_address',
  'state',
  'city',
  'zip',
  'notifications',
  'notes',
  'created_at',
  'latitude',
  'longitude',
  { companies: ['id', 'name'] },
  { current_order: ['id', 'services', 'number', 'status', { company_location: [{ company: ['id'] }] }] },
  { last_all_orders: ['id', 'number', 'created_at', { company_location: [{ company: ['id', 'time_zone'] }] }] },
  { photo: ['id', 'representations']}
];
const statisticsAttributes = [
  'id',
  'first_name',
  'last_name',
  'payout'
];

export const getDriversList = getThunkActionCreator(
  getDriversListRoutine,
  async ({ offset, query, company_id }) => {
    getDriversList.getDriversListCancel && getDriversList.getDriversListCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/users`, {
      params: { access_token, limit: 25, offset, orders: { name: 'asc' }, roles: ['driver'], ...(!!query && { query }), ...(!!company_id && { company_id }), attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getDriversList.getDriversListCancel = cancel })
    });
  }
);
export const getManagersList = getThunkActionCreator(
  getManagersListRoutine,
  async ({ offset, query }) => {
    getManagersList.getManagersListCancel && getManagersList.getManagersListCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/users`, {
      params: { access_token, limit: 25, offset, orders: { name: 'asc' }, roles: ['manager', 'owner'], ...(!!query && { query }), attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getManagersList.getManagersListCancel = cancel })
    });
  }
);
export const getUsers = getThunkActionCreator(
  getUsersRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, query = null, roles }) => {
    getUsers.getUsersCancel && getUsers.getUsersCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/users`, {
      params: { access_token, limit, offset, roles, orders, ...(!!query && { query }), attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getUsers.getUsersCancel = cancel })
    });
  }
);
export const getUsersStatistics = getThunkActionCreator(
  getUsersStatisticsRoutine,
  async ({ offset = 0, company_id, start_at = null, end_at = null, user_ids = null }) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/users/statistics`,
      {
        params: { 
          access_token, 
          limit: 25, 
          offset, 
          orders: { created_at: 'desc' }, 
          attributes: statisticsAttributes, 
          company_id,
          ...(!!start_at && { start_at, end_at }),
          ...(!!user_ids && { user_ids })
        },
        cancelToken: new CancelToken(function executor(cancel) { getUsersStatistics.getUsersStatisticsCancel = cancel })
      }
    );
  }
);
export const getUser = getThunkActionCreator(
  getUserRoutine,
  async (id) => {
    getUser.getUserCancel && getUser.getUserCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/users/${id}`, {
      params: { access_token, attributes: fullAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getUser.getUserCancel = cancel })
    });
  }
);
export const signUpUser = getThunkActionCreator(
  createUserRoutine,
  async (user) => {
    return await axios.post(`/user/users`, { user });
  }
);
export const createUser = getThunkActionCreator(
  createUserRoutine,
  async (user) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/users`, { access_token, user, attributes: [...shortAttributes, 'roles'] });
  }
);
export const updateUser = getThunkActionCreator(
  updateUserRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/users/${id}`, { access_token, user: _omit(fields, ['localPhoto']) });

    return { response, user: { id, ...fields } };
  }
);
export const deleteUser = getThunkActionCreator(
  deleteUserRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/user/users/${id}`, { params: { access_token }});

    return { response, id };
  }
);
export const changeUserPassword = getThunkActionCreator(
  changeUserPasswordRoutine,
  async ({ email, password, confirmation_code }) =>
    await axios.post(`/user/users/change_password`, { email, password, confirmation_code })
);

export const startUserDuty = getThunkActionCreator(
  startUserDutyRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    return await axios.post(`/user/users/${id}/start_duty`, { access_token });
  }
);

export const stopUserDuty = getThunkActionCreator(
  stopUserDutyRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/user/users/${id}/stop_duty`, { access_token });
  }
);

export const clearLocalDriversList = getThunkActionCreator(clearLocalDriversListRoutine, async () => {});
export const clearLocalManagersList = getThunkActionCreator(clearLocalManagersListRoutine, async () => {});
export const clearLocalUsers = getThunkActionCreator(clearLocalUsersRoutine, async () => {});
export const clearLocalUsersStatistics = getThunkActionCreator(clearLocalUsersStatisticsRoutine, async () => {});
export const clearLocalActiveUser = getThunkActionCreator(clearLocalActiveUserRoutine, async () => {});
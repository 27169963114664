import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  sendConfirmationCode as sendConfirmationCodeAction,
  validateConfirmationCode as validateConfirmationCodeAction
} from 'actions/confirmations';

const useConfirmations = () => {
  const dispatch = useDispatch();
  const sendConfirmationCode = useCallback((email) => dispatch(sendConfirmationCodeAction(email)), [dispatch]);
  const validateConfirmationCode = useCallback(({ email, confirmation_code }) => dispatch(validateConfirmationCodeAction({ email, confirmation_code })), [dispatch]);

  return {
    sendConfirmationCode,
    validateConfirmationCode
  };
};

export default useConfirmations;
import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useTrucks from 'hooks/useTrucks';

const TrucksAutocomplete = ({ value, error, helperText, onChange }) => {
  const { getListTrucks, clearLocalListTrucks } = useTrucks();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.trucks.list);
  const [loading, setLoading] = useState(false);

  const handleChange = (_, formattedValue) => onChange({ value: formattedValue?.id, formattedValue, error: '' });
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchTrucks = useCallback(offset => {
    setLoading(true);
    getListTrucks({ offset })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getListTrucks, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const { limit, offset, count, total_count } = pagination;

    if (scrollTop + clientHeight + 65 >= scrollHeight && offset + count < total_count && !loading) {
      fetchTrucks(limit + offset);
    }
  };

  useEffect(() => {
    fetchTrucks(0);

    return clearLocalListTrucks;
  }, [fetchTrucks, clearLocalListTrucks]);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      id="trucksList"
      options={data}
      getOptionLabel={o => `${o.make} ${o.model} (${o.year})`}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      ListboxProps={{ onScroll: handleScroll, role: 'list-box' }}
      fullWidth
      renderInput={params => <TextField {...params} label='Truck Assigned' error={error} helperText={helperText} />}
    />
  );
};

export default memo(TrucksAutocomplete);
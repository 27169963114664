import { memo } from 'react';
import Typography from '@mui/material/Typography';
// Local files
import { Root } from './StatsItem.styled';
import { ValueText } from 'components/Common/BaseStatsCard/BaseStatsCard.styled';


const StatsItem = ({ title, value }) => {

  return (
    <Root>
      <Typography variant='body1' color='#C4C4C4'>{title}</Typography>
      <ValueText sx={{ mt: 1 }}>{value}</ValueText>
    </Root>
  );
};

export default memo(StatsItem);
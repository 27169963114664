import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getDocuments as getDocumentsAction,
  getDocument as getDocumentAction,
  createDocuments as createDocumentsAction,
  deleteDocument as deleteDocumentAction,
  clearLocalDocuments as clearLocalDocumentsAction,
  clearLocalActiveDocument as clearLocalActiveDocumentAction
} from 'actions/documents';

const useDocuments = () => {
  const dispatch = useDispatch();
  const getDocuments = useCallback(({ id, model, offset, orders, query, category_ids }) => dispatch(getDocumentsAction({ id, model, offset, orders, query, category_ids })), [dispatch]);
  const getDocument = useCallback(id => dispatch(getDocumentAction(id)), [dispatch]);
  const createDocuments = useCallback(({ id, model, documents, newDocuments }) => dispatch(createDocumentsAction({ id, model, documents, newDocuments })), [dispatch]);
  const deleteDocument = useCallback(({ id, documents }) => dispatch(deleteDocumentAction({ id, documents })), [dispatch]);
  const clearLocalDocuments = useCallback(() => dispatch(clearLocalDocumentsAction()), [dispatch]);
  const clearLocalActiveDocument = useCallback(() => dispatch(clearLocalActiveDocumentAction()), [dispatch]);

  return {
    getDocuments,
    getDocument,
    createDocuments,
    deleteDocument,
    clearLocalDocuments,
    clearLocalActiveDocument
  };
};

export default useDocuments;
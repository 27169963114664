import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCompanyCharges as getCompanyChargesAction,
  getFullListCharges as getFullListChargesAction,
  getListCharges as getListChargesAction,
  getDefaultCharges as getDefaultChargesAction,
  getCharge as getChargeAction,
  createCharge as createChargeAction,
  createCompanyCharge as createCompanyChargeAction,
  deleteCompanyCharge as deleteCompanyChargeAction,
  clearLocalListCharges as clearLocalListChargesAction,
  clearLocalCompanyCharges as clearLocalCompanyChargesAction,
  clearLocalActiveCharge as clearLocalActiveChargeAction
} from 'actions/charges';

const useCharges = () => {
  const dispatch = useDispatch();
  const getCompanyCharges = useCallback(({ company_id }) => dispatch(getCompanyChargesAction({ company_id })), [dispatch]);
  const getFullListCharges = useCallback(() => dispatch(getFullListChargesAction()), [dispatch]);
  const getListCharges = useCallback(({ offset, charge_type }) => dispatch(getListChargesAction({ offset, charge_type })), [dispatch]);
  const getDefaultCharges = useCallback(({ offset, service_id, order_id, expense_name }) => dispatch(getDefaultChargesAction({ offset, service_id, order_id, expense_name })), [dispatch]);
  const getCharge = useCallback(id => dispatch(getChargeAction(id)), [dispatch]);
  const createCharge = useCallback(charge => dispatch(createChargeAction(charge)), [dispatch]);
  const createCompanyCharge = useCallback(({ company_id, id }) => dispatch(createCompanyChargeAction({ company_id, id })), [dispatch]);
  const deleteCompanyCharge = useCallback(({ company_id, id }) => dispatch(deleteCompanyChargeAction({ company_id, id })), [dispatch]);
  const clearLocalListCharges = useCallback(() => dispatch(clearLocalListChargesAction()), [dispatch]);
  const clearLocalCompanyCharges = useCallback(() => dispatch(clearLocalCompanyChargesAction()), [dispatch]);
  const clearLocalActiveCharge = useCallback(() => dispatch(clearLocalActiveChargeAction()), [dispatch]);

  return {
    getCompanyCharges,
    getFullListCharges,
    getListCharges,
    getDefaultCharges,
    getCharge,
    createCharge,
    createCompanyCharge,
    deleteCompanyCharge,
    clearLocalListCharges,
    clearLocalCompanyCharges,
    clearLocalActiveCharge
  };
};

export default useCharges;
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

export const Root = styled(Box)(() => ({
  width: '100%',
  maxWidth: '100%',
}));

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  color: '#fff',
  background: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: ' 24px 16px',
  padding: '20px 24px',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px 12px 0 0',
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  fontSize: theme.typography.pxToRem(42),
  fontWeight: 700,
}));

export const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
})

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '16px 32px',
  background: '#fff',
  padding: '20px 16px',
  boxShadow: `inset 0px -1px 0px ${theme.palette.divider}`,
  '& > *': {
    width: 'calc(50% - 16px)',
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: '0 0 12px 12px',
    boxShadow: '0px 7px 10px 0px #0000001A',
    padding: '20px 24px',
  },
  [theme.breakpoints.up('lg')]: {
    '& > *': {
      width: 180,
      flexBasis: 180,
      flexShrink: 0,
    }
  }
}));
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.primary.main,
  fontWeight: 500
}));
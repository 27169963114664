import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getCompaniesRoutine,
  updateCompanyRoutine,
  clearLocalCompaniesRoutine
} from 'actions';

const attributes = ['id', 'name', 'stripe_account_id', 'stripe_charges_enabled'];

export const getCompanies = getThunkActionCreator(
  getCompaniesRoutine,
  async ({ offset, query }) => {
    getCompanies.getCompaniesCancel && getCompanies.getCompaniesCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/user/companies`, {
      params: { access_token, limit: 25, offset, orders: { name: 'asc' }, ...(!!query && { query }), attributes },
      cancelToken: new CancelToken(function executor(cancel) { getCompanies.getCompaniesCancel = cancel })
    });
  }
);
export const updateCompany = getThunkActionCreator(
  updateCompanyRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/user/companies/${id}`, { access_token, company: fields });

    return { response, company: { id, ...fields } };
  }
);
export const clearLocalCompanies = getThunkActionCreator(clearLocalCompaniesRoutine, async () => {});
import { memo, useMemo, useState, useEffect, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// Local files
import { Root, TabsContainer, StyledTabs, StyledTab, StyledTabPanel } from './Settings.styled';
import ComponentErrorWrapper from 'components/Common/ComponentErrorWraper/ComponentErrorWrapper';
import General from 'components/Settings/General/General';
import Company from 'components/Settings/Company/Company';
import Personal from 'components/Settings/Personal/Personal';
import Notifications from 'components/Notifications/Notifications';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useRole from 'hooks/useRole';

const TabPanel = ({ children, value, index, ...otherProps }) => (
  <StyledTabPanel
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...otherProps}
  >
    {value === index && children}
  </StyledTabPanel>
);

const Settings = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { isManager, isOwner } = useRole();
  const [value, setValue] = useState(0);
  const userNotifications = useCustomSelector(state => state.profile.user.notifications);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const labels = useMemo(() => ['General Settings', 'Company Settings', `Personal ${mediaDesktop ? 'Info' : ''}`, 'Notifications'], [mediaDesktop]);
  const filteredLabels = (isManager || isOwner) ? labels : _filter(labels, (_, index) => index !== 1);

  const handleStripeConection = useCallback((success) => {
    (success ? setSuccess('Stripe connection success') : setError('Stripe connection unsuccessful'))
    .then(() => {
      setValue(3);
      navigate('/settings');
    })
  }, [setSuccess, setError]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleStripeSubscription = useCallback((success) => {
    (success ? setSuccess('Stripe subcription success') : setError('Stripe subcription unsuccessful'))
    .then(() => {
      setValue(3);
      navigate('/settings');
    })
  }, [setSuccess, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    pathname === `/settings/${id}/stripe-connect/success` && handleStripeConection(true);
    pathname === `/settings/${id}/stripe-connect/reload` && handleStripeConection(false);
    pathname === `/settings/${id}/stripe-subscription/success` && handleStripeSubscription(true);
    pathname === `/settings/${id}/stripe-subscriptiont/cancel` && handleStripeSubscription(false);
  }, [id, pathname, handleStripeConection, handleStripeSubscription]);

  return (
    <Root>
      <TabsContainer>
        <StyledTabs
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          aria-label='navigation'
          variant='fullWidth'
          orientation={mediaDesktop ? 'vertical' : 'horizontal'}
        >
          {_map(filteredLabels, (item, i) => <StyledTab label={item} index={i} key={i} />)}
        </StyledTabs>
      </TabsContainer>
      <TabPanel value={value} index={0}>
        <ComponentErrorWrapper children={<General />} />
      </TabPanel>
      { (isManager || isOwner) && <TabPanel value={value} index={1}>
        <ComponentErrorWrapper children={<Company />} />
      </TabPanel> }
      <TabPanel value={value} index={filteredLabels.length - 2}>
        <ComponentErrorWrapper children={<Personal />} />
      </TabPanel>
      <TabPanel value={value} index={filteredLabels.length - 1}>
        <ComponentErrorWrapper children={<Notifications {...{ userNotifications }} />} />
      </TabPanel>
    </Root>
  );
};

export default memo(Settings);
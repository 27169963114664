import { memo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
// Local files
import BaseDialog from '../../Common/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';
import useVehicles from 'hooks/useVehicles';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const TakeOutVehicle = (props) => {
  const navigate = useNavigate();
  const { closeTakeOutVehicleDialog } = useDialogs();
  const { takeOutVehicle } = useVehicles();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const vehicle_id = useCustomSelector(state => state.vehicles.vehicle.id);
  const { open } = useCustomSelector(state => state.dialogs.takeOutVehicle);

  const handleTakeOutVehicle = () => {
    takeOutVehicle(vehicle_id)
    .then(() => setSuccess('Storage “undo” successfully completed').then(() => {
      closeTakeOutVehicleDialog();
      navigate('/vehicles');
    }))
    .catch((error) => setError(error));
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeTakeOutVehicleDialog}
      title='Confirm “Undo storage”'
      PaperProps={{ sx: { width: 600,  borderRadius: '16px' } }}
      {...props}
    >
      <DialogContent>
        <Typography variant='body1' align='left' p='16px 0px 8px'>
          This will remove the vehicle from the storage and cancel all of the charges
        </Typography>
      </DialogContent>
      <Box
        sx={{
          width: '100%',
          borderTop: '1px solid #F1F0FC',
        }}
      >
        <ButtonBase
          sx={{
            width: '50%',
            color: 'text.primary',
            fontSize: '1rem',
            fontWeight: 700,
            borderRight: '1px solid #F1F0FC',
            padding: '20px 24px'
          }}
          onClick={closeTakeOutVehicleDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            width: '50%',
            color: 'primary.main',
            fontSize: '1rem',
            fontWeight: 700,
            padding: '20px 24px'
          }}
          onClick={handleTakeOutVehicle}
        >
          Confirm
        </ButtonBase>
      </Box>
    </BaseDialog>
  );
};

export default memo(TakeOutVehicle);

import { memo } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
// Local files
import { userRoles } from 'helpers/constants';

const RolesSelect = ({ fullWidth = false, margin = 'none', sx, value, error, helperText, onChange }) => {
  return (
    <FormControl fullWidth={fullWidth} margin={margin} sx={sx} error={error}>
      <InputLabel id="role-label">Select roles</InputLabel>
      <Select
        labelId="role-name-label"
        id="role-multiple-name"
        multiple
        error={error}
        value={value}
        onChange={({ target: { value } }) => onChange({ value, error: '' })}
        input={<OutlinedInput label="Select Role" />}
      >
        {Object.keys(userRoles).map(key => ({ id: key.toLowerCase(), label: _capitalize(key) })).map(r => (
          <MenuItem key={r.id} value={r.id}>
            {r.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

RolesSelect.propTypes = {
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.array.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(RolesSelect);
import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import _get from 'lodash/get';
import { mapboxDirectionUrl, mapboxAccessToken } from 'apis';
import {
  getMapDriversRoutine,
  getMapOrdersRoutine,
  setActualDriverDataRoutine,
  getDirectionOnMapRoutine,
  getUrgentlyMapDriversRoutine,
  clearLocalMapDataRoutine,
  setMapConfigsRoutine
} from 'actions';

const driversAttributes = [
  'id',
  'first_name',
  'last_name',
  'phone',
  'latitude',
  'longitude',
  'heading',
  'duty_duration',
  { photo: ['representations'] },
  {
    current_order: [
      'id',
      'number',
      'status',
      'eta',
      'class_type',
      'execution_duration',
      { incident_location: ['longitude', 'latitude', 'address'] },
      { destination_location: ['longitude', 'latitude', 'address'] },
      { company_location: [{ company: ['id'] }] },
      { services: ['id', 'name'] },
      { truck: ['id', 'nickname'] }
    ]
  },
  { last_user_orders: ['id', 'eta', 'number', 'class_type', 'created_at', { company_location: [{ company: ['time_zone'] }] }] },
  { trucks: ['id', 'make', 'model', 'year', { type: ['id', 'name'] }] }
];
const ordersAttributes = [
  'id',
  'number',
  'eta',
  'status',
  'company',
  { incident_location: ['longitude', 'latitude', 'address'] },
  { user: ['id', 'first_name', 'last_name'] },
  { dispatcher: ['id', 'first_name', 'last_name'] },
  'created_at'
];

export const getMapDrivers = getThunkActionCreator(
  getMapDriversRoutine,
  async ({ query = null, current_order_statuses = [], without_current_order = false }) => {
    const { token: access_token } = await sessionService.loadSession();
    let offset = 0;
    let driversReady = false;
    let drivers = [];
    let cancelRequest = function executor(c) { getMapDrivers.getMapDriversCancel = c };
    
    while (!driversReady) {
      getMapDrivers.getMapDriversCancel && getMapDrivers.getMapDriversCancel();
      const response = await axios.get(`/user/users`,
        {
          params: { 
            access_token, 
            limit: 25, 
            offset, 
            roles: ['driver'],
            ...(!!query && { query }), 
            ...(!!current_order_statuses.length && { current_order_statuses }), 
            attributes: driversAttributes,
            ...(without_current_order && { without_current_order })
          },
          cancelToken: new CancelToken(cancelRequest)
        }
      )
      const pagination = _get(response, 'data.pagination');
      const uploadedDrivers = _get(response, 'data.users');

      if (pagination.count + pagination.offset < pagination.total_count) {
        drivers = [...drivers, ...uploadedDrivers];
        offset += 25;
      } else {
        drivers = [...drivers, ...uploadedDrivers];
        driversReady = true;
      }
    }

    return { drivers };
  }
);
export const getUrgentlyMapDrivers = getThunkActionCreator(getUrgentlyMapDriversRoutine, async ({ drivers }) => ({ drivers }));
export const getMapOrders = getThunkActionCreator(
  getMapOrdersRoutine,
  async ({ orders = { created_at: 'desc' }, latitude_gt = null, latitude_lt = null, longitude_gt = null, longitude_lt = null, query = null, statuses = ['new', 'manager_accepted', 'en_route', 'pick_up', 'drop_off'] }) => {
    const { token: access_token } = await sessionService.loadSession();
    let offset = 0;
    let ordersReady = false;
    let ordersMap = [];
    let cancelRequest = function executor(c) { getMapOrders.getMapOrdersCancel = c };
    
    while (!ordersReady) {
      getMapOrders.getMapOrdersCancel && getMapOrders.getMapOrdersCancel();
      const response = await axios.get(`/user/orders`,
        {
          params: { 
            access_token, 
            limit: 25, 
            offset, 
            orders,
            ...(!!latitude_gt && { latitude_gt }), 
            ...(!!latitude_lt && { latitude_lt }), 
            ...(!!longitude_gt && { longitude_gt }), 
            ...(!!longitude_lt && { longitude_lt }), 
            ...(!!query && { query }), 
            statuses, 
            attributes: ordersAttributes 
          },
          cancelToken: new CancelToken(cancelRequest)
        }
      )
      const pagination = _get(response, 'data.pagination');
      const uploadedOrders = _get(response, 'data.orders');

      if (pagination.count + pagination.offset < pagination.total_count) {
        ordersMap = [...ordersMap, ...uploadedOrders];
        offset += 25;
      } else {
        ordersMap = [...ordersMap, ...uploadedOrders];
        ordersReady = true;
      }
    }
    return { orders: ordersMap };
  }
);
export const setActualDriverData = getThunkActionCreator(
  setActualDriverDataRoutine,
  async ({ id, latitude, longitude, heading, duty_duration }) => ({ id, latitude, longitude, heading, duty_duration })
);
export const getDirectionOnMap = getThunkActionCreator(
  getDirectionOnMapRoutine,
  async ({ start, end }) => await mapboxDirectionUrl.get(`/${start};${end}?geometries=geojson&access_token=${mapboxAccessToken}`)
);
export const clearLocalMapData = getThunkActionCreator(
  clearLocalMapDataRoutine,
  async (type = null) => ({ type })
);
export const setMapConfigs = getThunkActionCreator(
  setMapConfigsRoutine,
  async ({ bounds = null, viewState = null, popup = null, localIncident = null }) => ({ bounds, viewState, popup, localIncident })
);
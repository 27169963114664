import { memo, useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Tab from '@mui/material/Tab';
// Local files
import { StyledTabs } from './Company.styled';
import useRole from 'hooks/useRole';
import useUrl from 'hooks/useUrl';

const Company = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isManager, isOwner } = useRole();
  const { isDriversRoute, isManagersRoute } = useUrl();
  const [tab, setTab] = useState('trucks');

  useEffect(() => {
    setTab(isDriversRoute ? 'drivers' : isManagersRoute ? 'managers' : 'trucks');
  }, [isDriversRoute, isManagersRoute]);
  useEffect(() => {
    pathname === '/company' && navigate('/company/trucks');
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (_, value) => navigate(`/company/${value}`);

  return (
    <>
      <StyledTabs value={tab} onChange={handleTabChange}>
        {(isManager || isOwner) && <Tab value='drivers' label='Drivers' /> }
        {(isManager || isOwner) && <Tab value='managers' label='Managers' />}
        <Tab value='trucks' label='Trucks' />
      </StyledTabs>
      <Outlet />
    </>
  );
};

export default memo(Company);
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAdd as getAddAction,
  createAdd as createAddAction,
  clearLocalActiveAdd as clearLocalActiveAddAction
} from 'actions/adds';

const useAdds = () => {
  const dispatch = useDispatch();
  const getAdd = useCallback(id => dispatch(getAddAction(id)), [dispatch]);
  const createAdd = useCallback(({ company_id, code }) => dispatch(createAddAction({ company_id, code })), [dispatch]);
  const clearLocalActiveAdd = useCallback(() => dispatch(clearLocalActiveAddAction()), [dispatch]);

  return {
    getAdd,
    createAdd,
    clearLocalActiveAdd
  };
};

export default useAdds;
import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import _startCase from 'lodash/startCase';
import { useNavigate } from 'react-router-dom';
import { parse, toSeconds } from 'iso8601-duration';
//Local files
import { Actions, Content, Header, Root, Title, Details, Divider, TableHeading, TableContainer, HeaderDetails, Subtitle } from './Main.styled';
import ServicesTable from 'components/Services/Table/Table';
import Status from '../../Status/Status';
import useApp from 'hooks/useApp';
import useDialogs from 'hooks/useDialogs';
import useRole from 'hooks/useRole';
import { accountTypes, invoiceStatuses, orderStatuses, vehicleStatuses, reasons } from 'helpers/constants';
import useTimezones from 'hooks/useTimezones';
import { dayDifferenceFromToday } from "../../../../helpers";

const Main = ({ id, file, expenses, payments, order, status, vehicle_status, total_amount, paid_amount, storage_duration, created_at, stored_at }) => {
  const { openDrawer } = useApp();
  const { openInvoiceDialog, openTowVehicleDialog, openTakeOutVehicleDialog } = useDialogs();
  const { isManager, isOwner } = useRole();
  const { timeZone } = useTimezones(order?.company_location?.company?.id);
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [newExpense, setNewExpense] = useState(false);
  const [processing] = useState(false);
  const showReleaseVehicle = (isManager || isOwner) && vehicle_status !== vehicleStatuses.RELEASED && vehicle_status !== vehicleStatuses.TAKEN_OUT;
  const showTakeOutVehicle = (isManager || isOwner) && vehicle_status !== vehicleStatuses.RELEASED && vehicle_status !== vehicleStatuses.TAKEN_OUT && status !== invoiceStatuses.PAID && status !== invoiceStatuses.PARTIALLY_PAID;
  
  const { 
    account,
    authorization_number, 
    number: order_number,
    status: job_status,
    services,
    police_number,
    police_code
  } = order;
  const showAddService = (isManager || isOwner) 
    && job_status !== orderStatuses.CANCELED
    && job_status !== orderStatuses.REJECTED
    && (status === invoiceStatuses.NOT_SUBMITTED || status === invoiceStatuses.NEW);
  const showViewInvoice = (isManager || isOwner) && order.invoice.status !== invoiceStatuses.NEW;
  const showPONumber = account?.account_type !== accountTypes.POLICE;
  const servicesData = { invoice_id: id, expenses, total_amount, paid_amount, editExpense: showAddService };

  const handleReleaseVehicle = () => {
    setMenuAnchor(null);
    openDrawer({ reason: reasons.RELEASE_VEHICLE }) 
  };
  const handleSendInvoice = () => { 
    setMenuAnchor(null);
    openDrawer({ reason: reasons.SEND_INVOICE }) 
  };
  const handleTowVehicle = () => { 
    setMenuAnchor(null);
    openTowVehicleDialog();
  };
  const handleTakeOutVehicle = () => { 
    setMenuAnchor(null);
    openTakeOutVehicleDialog();
  };

  return (
    <Root>
      <Header>
        <Box>
          <Title>{order_number}</Title>
          <HeaderDetails>
            <Status size='large' darkBackground value={vehicle_status} />
            <Subtitle>{ `${dayDifferenceFromToday(stored_at)} ${dayDifferenceFromToday(stored_at) === 1 ? 'day' : 'days'}` } </Subtitle>
          </HeaderDetails>
        </Box>
        <Box flexGrow={1} />
        <Actions>
          {!!file && <Button color='inherit' disabled={processing} onClick={() => openInvoiceDialog(file.url)} endIcon={<ArrowOutwardIcon />}>VIEW INVOICE</Button>}
          <Button color='inherit' disabled={processing} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)} endIcon={<MoreVertIcon />} sx={{ lineHeight: '1.0' }}>
            More
          </Button>
        </Actions>
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{ sx: { mt: '2px', width: '200px' } }}
          keepMounted
        >
          <MenuItem color='primary' onClick={() => navigate(`/orders/${order.id}`)}>View Order</MenuItem>
          { showViewInvoice && <MenuItem color='primary' onClick={() => navigate(`/invoices/${id}`)}>View Invoice</MenuItem> }
          <MenuItem color='primary' onClick={handleSendInvoice}>Email invoice details</MenuItem>
          <MenuItem color='primary' onClick={handleTowVehicle}>Tow Vehicle</MenuItem>
          {showReleaseVehicle && <MenuItem color='primary' onClick={handleReleaseVehicle}>Release vehicle</MenuItem>}
          {showTakeOutVehicle && <MenuItem color='primary' onClick={handleTakeOutVehicle}>Undo storage</MenuItem>}
        </Menu>
      </Header>
      <Content>
        <Details>
          <Box>
            <Typography variant='body2' color='textSecondary'>Created Date</Typography>
            <Typography variant='subtitle2'>{new Date(created_at).toLocaleDateString('en-US', { timeZone })}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textSecondary'>Account</Typography>
            <Typography variant='subtitle2'>{account?.name ?? '-'}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textSecondary'>{showPONumber ? 'PO' : 'Log'} Number</Typography>
            <Typography variant='subtitle2'>{showPONumber ? authorization_number || '-' : police_number || '-'}</Typography>
          </Box>
          { account?.account_type === accountTypes.POLICE && <Box>
            <Typography variant='body2' color='textSecondary'>Police Code</Typography>
            <Typography variant='subtitle2'>{police_code}</Typography>
          </Box> }
          <Box>
            <Typography variant='body2' color='textSecondary'>Job Type</Typography>
            <Typography variant='subtitle2'>{_startCase(services[0]?.name) || '-'}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textSecondary'>Job Status</Typography>
            <Typography variant='subtitle2'>{_startCase(job_status)}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textSecondary'>Payment Type</Typography>
            <Typography variant='subtitle2'>{_startCase(payments[payments?.length - 1]?.payment_type) || '-'}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textSecondary'>Invoice Amount</Typography>
            <Typography variant='subtitle2'>${total_amount?.toFixed(2)}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textSecondary'>Order No.</Typography>
            <Typography variant='subtitle2'>{order_number}</Typography>
          </Box>
        </Details>
        <Divider />
        <TableContainer>
          <TableHeading>
            <Typography variant='title'>Services</Typography>
            { showAddService && <Button startIcon={<AddIcon /> } onClick={() => setNewExpense(true)}>Add service / charge</Button>}
          </TableHeading>
          { !!id && <ServicesTable highlightStorage newExpense={newExpense} onNewExpense={setNewExpense} {...servicesData} /> }
        </TableContainer>
      </Content>
    </Root>
  );
};


export default memo(Main);
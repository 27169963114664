import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  createCard as createCardAction,
  deleteCard as deleteCardAction
} from 'actions/cards';

const useCards = () => {
  const dispatch = useDispatch();
  const createCard = useCallback(({ stripe_token, customer_id }) => dispatch(createCardAction({ stripe_token, customer_id })), [dispatch]);
  const deleteCard = useCallback(id => dispatch(deleteCardAction(id)), [dispatch]);

  return { createCard, deleteCard };
};

export default useCards;
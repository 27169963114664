import React from 'react'
import { Box, Typography }from '@mui/material';
import info from 'assets/images/info.png';
import {  FirstRow, FirstRowItem, SecondRow, SecondRowItem, ThirdRow, ThirdRowItem,GridContainer } from './WhatsNew.styled';

const WhatsNew = () => {
  return (
    <GridContainer>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: "bold", margin: "2rem 0"}}>
        Feel the best experience with our <span style={{ color: '#08008F' }}>new features</span>
      </Typography>

      <FirstRow>
        <FirstRowItem>
          <Typography variant="h4" gutterBottom sx={{fontWeight: "bold"}}>Far far away, behind the word mountains</Typography>
          <Typography variant="body1" gutterBottom>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typography>
          <Box sx={{display:"flex", justifyContent:"center"}}><img style={{maxWidth:"300px"}} src={info} alt=''></img></Box>
        </FirstRowItem>

        <FirstRowItem>
          <Typography variant="h4" gutterBottom sx={{fontWeight: "bold"}}>A small river named Duden flows</Typography>
          <Typography variant="body1" gutterBottom >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typography>
          <Box sx={{display:"flex", justifyContent:"center"}}><img src={info} style={{maxWidth:"300px"}} alt=''></img></Box>
        </FirstRowItem>
      </FirstRow>

      <SecondRow>
        <SecondRowItem>
          <Box>
            <Typography variant="h4" gutterBottom sx={{fontWeight: "bold"}}>It is a paradisematic country</Typography>
            <Typography variant="body1" gutterBottom sx={{maxWidth:"650px"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typography>
          </Box>
          <Box sx={{display:"flex", justifyContent:"center"}}><img src={info} style={{maxWidth:"300px"}} alt=''></img></Box>
        </SecondRowItem>
      </SecondRow>

      <ThirdRow>
        <ThirdRowItem>
          <Typography variant="h5" gutterBottom sx={{fontWeight: "bold"}}>Far far away, behind the word mountains</Typography>
          <Box sx={{display:"flex", justifyContent:"center"}}><img src={info} style={{maxWidth:"200px"}} alt=''></img></Box>
        </ThirdRowItem>

        <ThirdRowItem>
          <Typography variant="h5" gutterBottom sx={{fontWeight: "bold"}}>A small river named Duden flows</Typography>
          <Box sx={{display:"flex", justifyContent:"center"}}><img src={info} style={{maxWidth:"200px"}} alt=''></img></Box>
        </ThirdRowItem>

        <ThirdRowItem>
          <Typography variant="h5" gutterBottom sx={{fontWeight: "bold"}}>Far far away, behind the word mountains</Typography>
          <Box sx={{display:"flex", justifyContent:"center"}}><img src={info} style={{maxWidth:"200px"}} alt=''></img></Box>
        </ThirdRowItem>
      </ThirdRow>
    </GridContainer>
  )
}

export default WhatsNew;
import {
  getCustomersRoutine,
  getCustomerRoutine,
  createCustomerRoutine,
  clearLocalCustomersRoutine,
  clearLocalActiveCustomerRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  customer: { id: '', name: '', email: '', phone: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getCustomersRoutine.SUCCESS: {
      const { data: { customers: data, pagination } } = action.payload;

      return { ...state, all: { data, pagination } };
    }
    case getCustomerRoutine.SUCCESS: {
      const { data: { customer } } = action.payload;
      const updatedCustomer = { ...state.customer, ...customer };
      const data = _map(state.all.data, (item) => item.id === customer.id ? { ...item, ...customer } : item)

      return { ...state, customer: updatedCustomer, all: { ...state.all, data } };
    }
    case createCustomerRoutine.SUCCESS: {
      const { data: { customer } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 4 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[customer], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case clearLocalCustomersRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveCustomerRoutine.SUCCESS: {
      return { ...state, customer: initialState.customer }
    }
    default: {
      return state;
    }
  };
};

export default reducer;
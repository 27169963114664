import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
//Local files
import { Root, Header, Title, Actions, Content } from './Main.styled';
import { reasons } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useRole from 'hooks/useRole';
import useTimezones from 'hooks/useTimezones';

const Main = ({ make, model, year, type, created_at, last_orders }) => {
  const { openDrawer } = useApp();
  const { isManager, isOwner } = useRole();
  const { timeZone } = useTimezones(last_orders[0]?.company_location?.company?.id);

  return (
    <Root>
      <Header>
        <Title>{make} {model}</Title>
        { (isManager || isOwner) && <Actions>
          <Button variant='outlined' color='tertiary' onClick={() => openDrawer({ reason: reasons.TRUCK, edit: true })}>
            EDIT
          </Button>
        </Actions>}
      </Header>
      <Content>
        <Box>
          <Typography variant='body2' color='textSecondary' sx={{whiteSpace: 'nowrap'}}>Created Date</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{new Date(created_at).toLocaleDateString('en-US', { timeZone })}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Type</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{type?.name || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Year</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{year}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Make</Typography>
          <Typography variant='subtitle2' sx={{wordBreak: 'break-all'}}>{make || '-'}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' color='textSecondary'>Model</Typography>
          <Typography variant='subtitle2' sx={{whiteSpace: 'nowrap'}}>{model || '-'}</Typography>
        </Box>
      </Content>
    </Root>
  );
};

export default memo(Main);